import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from '@mui/material';
import { styled } from '@mui/styles';
import DOMPurify from 'dompurify';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '20px',
    maxWidth: '600px',
    width: '90%',
    padding: theme.spacing(2),
  },
}));

const StyledDialogTitle = styled(DialogTitle)({
  fontFamily: "'Fira Sans', sans-serif",
  fontSize: '20px',
  fontWeight: 600,
  color: '#2D2D2D',
  padding: '16px 24px',
});

const ContentSection = styled(Box)({
  backgroundColor: '#F8F9FA',
  borderRadius: '12px',
  padding: '16px',
  marginBottom: '16px',
});

const SectionTitle = styled(Typography)({
  fontFamily: "'Fira Sans', sans-serif",
  fontSize: '14px',
  fontWeight: 600,
  color: '#666666',
  marginBottom: '8px',
});

const SectionContent = styled(Typography)({
  fontFamily: "'Inter', sans-serif",
  fontSize: '14px',
  color: '#2D2D2D',
  whiteSpace: 'pre-wrap',
});

const ExplicacionFlashcard = ({
  open,
  onClose,
  question,
  correctAnswer,
  userAnswer,
  explanationText,
  isLoading
}) => {
  const [displayText, setDisplayText] = useState('');
  const lastChunkRef = useRef('');

  // Reset when dialog closes
  useEffect(() => {
    if (!open) {
      setDisplayText('');
      lastChunkRef.current = '';
    }
  }, [open]);

  // Handle streaming text chunks
  useEffect(() => {
    if (explanationText && explanationText !== '[DONE]') {
      const newText = explanationText.replace(lastChunkRef.current, '');
      lastChunkRef.current = explanationText;

      const formattedText = newText.replace(/<br>/g, '\n');
      setDisplayText(prev => prev + formattedText);
    }
  }, [explanationText]);

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-labelledby="explanation-dialog-title"
    >
      <StyledDialogTitle id="explanation-dialog-title">
        Explicación
      </StyledDialogTitle>

      <DialogContent>
        <ContentSection>
          <SectionContent
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(displayText) || (isLoading ?
                'Generando explicación...' :
                'No hay explicación disponible.'
              )
            }}
          />
        </ContentSection>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
          sx={{
            color: '#666666',
            textTransform: 'none',
            fontFamily: "'Fira Sans', sans-serif",
            fontSize: '14px',
            fontWeight: 500,
            '&:hover': {
              backgroundColor: 'rgba(102, 102, 102, 0.08)',
            },
          }}
        >
          Cerrar
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default ExplicacionFlashcard; 