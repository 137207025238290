import React, { useState } from 'react';
import {
  Button,
  Typography,
  Box,
  Slider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
  Tooltip,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Premium from '../PopUpsAlerts/Premium/Premium';
import PremiumPopup from '../Stripe/PricingComponents/PremiumPopup';
import { useSubscription } from "../Context/SubscriptionContext";
import CloseIcon from '@mui/icons-material/Close';

const CustomizeFlashcards = ({ onClose, onSave, documentStats, documentName, isPendingTask = false }) => {
  const { userData } = useSubscription();
  const isPremium = userData?.plan_name !== "Free";
  const [cardCount, setCardCount] = useState(15);
  const [selectedTypes, setSelectedTypes] = useState({
    easy: false,
    normal: false,
    hard: false,
    veryHard: false,
    not_started: false,
    mix: true
  });
  const [showPremium, setShowPremium] = useState(false);
  const [premiumTrigger, setPremiumTrigger] = useState('');
  const [showInfoBox, setShowInfoBox] = useState(true);

  const handleCardCountChange = (event, newValue) => {
    if (!isPremium && newValue > 15) {
      setShowPremium(true);
      setPremiumTrigger('flashcards');
      return;
    }
    setCardCount(newValue);
  };

  const handleTypeChange = (type) => {
    if (!isPremium && type !== 'mix') {
      setShowPremium(true);
      setPremiumTrigger('topics');
      return;
    }

    if (type === 'mix') {
      setSelectedTypes({
        easy: !selectedTypes.mix,
        normal: !selectedTypes.mix,
        hard: !selectedTypes.mix,
        veryHard: !selectedTypes.mix,
        not_started: !selectedTypes.mix,
        mix: !selectedTypes.mix
      });
    } else {
      const newTypes = {
        ...selectedTypes,
        mix: false,
        [type]: !selectedTypes[type]
      };
      setSelectedTypes(newTypes);
    }
  };

  const isSelectionValid = () => {
    if (selectedTypes.mix) return true;

    return Object.entries(selectedTypes).some(([type, isSelected]) => {
      if (!isSelected || type === 'mix') return false;

      // Map the type to the correct statsKey
      const statsKey = {
        easy: 'flashcards_easy',
        normal: 'flashcards_normal',
        hard: 'flashcards_hard',
        veryHard: 'flashcards_very_hard',
        not_started: 'flashcards_not_started'
      }[type];

      // Use the statsKey directly on documentStats
      const count = documentStats?.[statsKey] || 0;
      return count > 0;
    });
  };

  const handleStartStudy = () => {
    if (!isPendingTask && !isSelectionValid()) return;

    if (isPendingTask) {
      onSave({ studyMode: 'typing' });
    } else {
      onSave({
        cardCount: cardCount,
        cardTypes: selectedTypes,
        studyMode: 'typing'
      });
    }
  };

  const difficultyConfig = [
    { type: 'easy', label: 'Fácil', color: '#4CAF50', statsKey: 'flashcards_easy' },
    { type: 'normal', label: 'Normal', color: '#2196F3', statsKey: 'flashcards_normal' },
    { type: 'hard', label: 'Difícil', color: '#FFC107', statsKey: 'flashcards_hard' },
    { type: 'veryHard', label: 'Muy difícil', color: '#FF5722', statsKey: 'flashcards_very_hard' },
    { type: 'not_started', label: 'No empezado', color: '#9E9E9E', statsKey: 'flashcards_not_started' }
  ];

  const getEstimatedTimeText = (cards) => {
    const seconds = cards * 15;
    if (seconds < 60) {
      return `${seconds} segundos`;
    }
    const minutes = Math.ceil(seconds / 60);
    return `${minutes} ${minutes === 1 ? 'minuto' : 'minutos'}`;
  };

  return (
    <Box sx={{ p: 2, margin: '0 auto' }}>
      {showPremium && (
        <Premium
          onClose={() => setShowPremium(false)}
          titleText={premiumTrigger}
        />
      )}

      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 4
      }}>
        <Button
          onClick={onClose}
          startIcon={<span>←</span>}
          sx={{
            color: '#666',
            textTransform: 'none',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            fontSize: '16px',
            '&:hover': {
              backgroundColor: 'transparent',
            }
          }}
        >
          Volver
        </Button>
        <Button
          onClick={handleStartStudy}
          variant="contained"
          disabled={!isSelectionValid()}
          sx={{
            bgcolor: '#6BBDBD',
            color: 'white',
            textTransform: 'none',
            fontSize: "14px",
            borderRadius: "20px",
            padding: "6px 22px",
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            '&:hover': {
              bgcolor: '#5AACAC',
            },
            '&.Mui-disabled': {
              bgcolor: '#cccccc',
              color: '#666666'
            }
          }}
        >
          ¡Empezar!
        </Button>
      </Box>

      <Typography
        variant="h6"
        sx={{
          fontWeight: 600,
          fontFamily: 'Fira Sans, sans-serif',
          color: 'black',
          textAlign: 'center',
          fontSize: '20px',
          marginBottom: '40px'
        }}
      >
        Personalizar sesión de flashcards
      </Typography>

      <Box sx={{ mb: 4 }}>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            fontFamily: 'Fira Sans, sans-serif',
            textAlign: 'left',
            marginBottom: '20px'
          }}
        >
          1. Número de flashcards
        </Typography>
        <Slider
          value={cardCount}
          onChange={handleCardCountChange}
          min={1}
          max={200}
          valueLabelDisplay="auto"
          sx={{
            color: '#6BBDBD',
            '& .MuiSlider-thumb': {
              backgroundColor: '#fff',
              border: '2px solid #6BBDBD',
            },
          }}
        />
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 1
        }}>
          <Typography sx={{
            color: 'text.secondary',
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif'
          }}>
            Selecciona entre 1 y 200 flashcards
          </Typography>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            bgcolor: '#F8F9FA',
            px: 2,
            py: 1,
            borderRadius: '8px'
          }}>
            <AccessTimeIcon sx={{ fontSize: 16, color: '#6BBDBD' }} />
            <Typography sx={{
              fontSize: '14px',
              fontFamily: 'Fira Sans',
              color: '#6BBDBD',
              fontWeight: 'bold'
            }}>
              {getEstimatedTimeText(cardCount)}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Box>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            fontFamily: 'Fira Sans, sans-serif',
            textAlign: 'left',
            marginBottom: '20px'
          }}
        >
          2. Elige el nivel de dificultad {!isPremium && '(Premium)'}
        </Typography>

        {showInfoBox && (
          <Box sx={{ 
            backgroundColor: '#F8F9FA', 
            borderRadius: '8px', 
            p: 2, 
            mb: 2,
            border: '1px solid #E0E0E0',
            position: 'relative'
          }}>
            <CloseIcon 
              sx={{ 
                position: 'absolute',
                right: 8,
                top: 8,
                cursor: 'pointer',
                fontSize: 20,
                color: '#666',
                '&:hover': {
                  color: '#333'
                }
              }}
              onClick={() => setShowInfoBox(false)}
            />
            <Typography sx={{ 
              fontFamily: 'Inter, sans-serif', 
              fontSize: '14px',
              color: '#666',
              pr: 4
            }}>
              Las tarjetas se clasifican según los intentos necesarios para responderlas correctamente
            </Typography>
          </Box>
        )}

        <Box sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: 1,
        }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedTypes.mix}
                onChange={() => handleTypeChange('mix')}
                sx={{
                  color: '#6BBDBD',
                  '&.Mui-checked': {
                    color: '#6BBDBD',
                  },
                }}
              />
            }
            label={
              <Typography sx={{ fontFamily: 'Inter, sans-serif', fontSize: '14px' }}>
                Mezcla de todas las dificultades
              </Typography>
            }
          />
          {difficultyConfig.map(({ type, label, color, statsKey }) => (
            <FormControlLabel
              key={type}
              disabled={!isPremium || (documentStats?.[statsKey] || 0) === 0}
              control={
                <Checkbox
                  checked={selectedTypes[type]}
                  onChange={() => handleTypeChange(type)}
                  sx={{
                    color: color,
                    '&.Mui-checked': {
                      color: color,
                    },
                  }}
                />
              }
              label={
                <Typography sx={{ fontFamily: 'Inter, sans-serif', fontSize: '14px' }}>
                  {`${label} (${documentStats?.[statsKey] || 0})`}
                </Typography>
              }
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default CustomizeFlashcards;