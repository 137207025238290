import { getCsrfToken } from './TokenApi'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export async function fetchDailyStats(token, method = "GET", requestBody = null, user) {
    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/daily_stats/?user_id=${user_id}`;

    try {
        const csrfToken = method !== "GET" ? getCsrfToken() : null;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };

        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: method,
            headers: headers,
        };

        if (method === "POST" && requestBody !== null) {
            requestOptions.body = JSON.stringify(requestBody);
        }

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching daily stats:', error);
        throw error;
    }
}