import React, { useCallback } from 'react';
import { useReactFlow } from 'react-flow-renderer';
import { styled } from '@mui/material/styles';
import { Menu, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledMenuItem = styled(MenuItem)(({ theme, danger }) => ({
  padding: '8px 16px',
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  fontFamily: '"Fira Sans", sans-serif',
  fontSize: '0.875rem',
  color: danger ? '#AA161D' : '#2c3e50',
  '&:hover': {
    backgroundColor: danger ? 'rgba(170, 22, 29, 0.04)' : 'rgba(0, 0, 0, 0.04)',
  },
  '& svg': {
    fontSize: '1.1rem',
    color: danger ? '#AA161D' : '#64748b',
  },
}));

export default function ContextMenu({
  id,
  top,
  left,
  onClose,
  onEdit,
  open,
  anchorReference = 'anchorPosition',
  anchorPosition,
}) {
  const { setNodes, setEdges } = useReactFlow();

  const deleteNode = useCallback(() => {
    setNodes((nodes) => nodes.filter((node) => node.id !== id));
    setEdges((edges) => edges.filter((edge) => edge.source !== id && edge.target !== id));
    onClose();
  }, [id, setNodes, setEdges, onClose]);

  const handleEdit = useCallback(() => {
    onEdit();
    onClose();
  }, [onEdit, onClose]);

  return (
    <Menu
      open={open}
      onClose={onClose}
      anchorReference={anchorReference}
      anchorPosition={anchorPosition ? anchorPosition : { top, left }}
      PaperProps={{
        elevation: 3,
        style: {
          borderRadius: '12px',
          minWidth: '180px',
          backgroundColor: '#E2F1F1',
          border: '1px solid rgba(107, 189, 189, 0.5)',
          boxShadow: '0 0 0 1px rgba(107, 189, 189, 0.3)',
        },
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <StyledMenuItem onClick={handleEdit}>
        <EditIcon />
        Editar
      </StyledMenuItem>
      <StyledMenuItem onClick={deleteNode} danger={1}>
        <DeleteIcon />
        Borrar
      </StyledMenuItem>
    </Menu>
  );
} 