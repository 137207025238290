import React, { useCallback, useEffect, useState, useRef } from 'react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Table from '@editorjs/table';
import debounce from 'lodash/debounce';
import edjsHTML from 'editorjs-html';
import parse from 'html-react-parser';
import './editor.css';
import { Button, CircularProgress } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

const EDITOR_JS_TOOLS = {
  header: Header,
  list: List,
  table: Table,
};

const EditorText = ({ value, onChange, readOnly, onSave }) => {
  const editorInstance = useRef(null);
  const edjsParser = useRef(edjsHTML());
  const initialRenderComplete = useRef(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentMatchIndex, setCurrentMatchIndex] = useState(-1);
  const [matches, setMatches] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const initialContent = useRef(null);

  const convertHtmlToBlocks = (html) => {
    if (typeof html !== "string") return html;

    try {
      const parsed = JSON.parse(html);
      if (parsed.blocks) {
        return parsed;
      }
    } catch (e) {
      // Continue with HTML parsing if JSON parse fails
    }

    const div = document.createElement("div");
    div.innerHTML = html;

    const blocks = [];

    div.childNodes.forEach((node) => {
      if (node.nodeName.match(/^H[1-6]$/i)) {
        let level = parseInt(node.nodeName.charAt(1));
        if (level === 5) level = 2;
        if (level === 6) level = 3;
        blocks.push({ type: "header", data: { text: node.innerText, level } });
      }
      else if (node.nodeName === "P") {
        blocks.push({ type: "paragraph", data: { text: node.innerText } });
      }
      else if (node.nodeName === "UL" || node.nodeName === "OL") {
        const items = [];
        node.childNodes.forEach((li) => {
          if (li.nodeName === "LI") {
            items.push(li.innerText);
          }
        });
        blocks.push({
          type: "list",
          data: {
            style: node.nodeName === "UL" ? "unordered" : "ordered",
            items
          }
        });
      }
      else if (node.nodeName === "TABLE") {
        const content = [];
        const rows = node.querySelectorAll('tr');
        rows.forEach(row => {
          const rowData = [];
          const cells = row.querySelectorAll('td, th');
          cells.forEach(cell => {
            rowData.push(cell.textContent || '');
          });
          if (rowData.length > 0) {
            content.push(rowData);
          }
        });
        if (content.length > 0) {
          blocks.push({
            type: "table",
            data: {
              withHeadings: false,
              content: content
            }
          });
        }
      }
    });

    return { blocks };
  };

  // Initial editor setup
  useEffect(() => {
    const initEditor = async () => {
      if (!editorInstance.current && value && !initialRenderComplete.current) {
        let initialData;
        try {
          const parsed = JSON.parse(value);
          initialData = parsed;
        } catch (e) {
          initialData = convertHtmlToBlocks(value);
        }

        initialContent.current = JSON.stringify(initialData);

        editorInstance.current = new EditorJS({
          holder: "editorjs",
          tools: EDITOR_JS_TOOLS,
          readOnly: readOnly,
          data: initialData,
          autofocus: true,
          onChange: async () => {
            const currentContent = await editorInstance.current.save();
            const currentContentStr = JSON.stringify(currentContent);
            setHasChanges(currentContentStr !== initialContent.current);
          }
        });

        await editorInstance.current.isReady;
        initialRenderComplete.current = true;
      }
    };

    initEditor();

    return () => {
      if (editorInstance.current && typeof editorInstance.current.destroy === "function") {
        editorInstance.current.destroy();
        editorInstance.current = null;
      }
    };
  }, []); // Empty dependency array to prevent re-renders

  // Separate effect for content updates
  useEffect(() => {
    const updateContent = async () => {
      if (editorInstance.current && value && initialRenderComplete.current) {
        try {
          const currentData = await editorInstance.current.save();
          const newData = typeof value === 'string' ? JSON.parse(value) : value;

          if (JSON.stringify(currentData) !== JSON.stringify(newData)) {
            await editorInstance.current.render(newData);
          }
        } catch (err) {
          console.error('Error updating editor content:', err);
        }
      }
    };

    updateContent();
  }, [value]);

  const navigateToMatch = (direction) => {
    if (matches.length === 0) return;

    // Remove current highlight
    matches[currentMatchIndex]?.classList.remove('current-match');

    // Calculate new index
    let newIndex;
    if (direction === 'next') {
      newIndex = (currentMatchIndex + 1) % matches.length;
    } else {
      newIndex = (currentMatchIndex - 1 + matches.length) % matches.length;
    }

    // Highlight new match
    matches[newIndex].classList.add('current-match');

    // Get the editor container
    const editorContainer = document.getElementById('editorjs');

    // Calculate scroll position
    const matchElement = matches[newIndex];
    const containerRect = editorContainer.getBoundingClientRect();
    const matchRect = matchElement.getBoundingClientRect();

    // Calculate the scroll position to center the match
    const scrollTop = editorContainer.scrollTop + (matchRect.top - containerRect.top) -
      (containerRect.height / 2) + (matchRect.height / 2);

    // Smooth scroll to the match within the container
    editorContainer.scrollTo({
      top: scrollTop,
      behavior: 'smooth'
    });

    setCurrentMatchIndex(newIndex);
  };

  const handleSearch = (e) => {
    e.preventDefault();

    if (matches.length > 0 && currentMatchIndex !== -1) {
      // If we already have matches, navigate to next match
      navigateToMatch('next');
      return;
    }

    if (!searchTerm) {
      setMatches([]);
      setCurrentMatchIndex(-1);

      // Clear existing highlights
      const editorContent = document.getElementById('editorjs');
      const existingHighlights = editorContent.getElementsByClassName('highlight');
      while (existingHighlights.length > 0) {
        const parent = existingHighlights[0].parentNode;
        parent.replaceChild(document.createTextNode(existingHighlights[0].textContent), existingHighlights[0]);
      }
      return;
    }

    const editorContent = document.getElementById('editorjs');
    if (!editorContent) return;

    // Remove existing highlights before searching again
    const existingHighlights = editorContent.getElementsByClassName('highlight');
    while (existingHighlights.length > 0) {
      const parent = existingHighlights[0].parentNode;
      parent.replaceChild(document.createTextNode(existingHighlights[0].textContent), existingHighlights[0]);
    }

    try {
      const regex = new RegExp(searchTerm, 'gi');
      const textNodes = [];
      const walker = document.createTreeWalker(
        editorContent,
        NodeFilter.SHOW_TEXT,
        {
          acceptNode: (node) => {
            // Skip text nodes that are already inside highlight spans
            if (node.parentNode.classList?.contains('highlight')) {
              return NodeFilter.FILTER_REJECT;
            }
            return NodeFilter.FILTER_ACCEPT;
          }
        }
      );

      let node;
      while ((node = walker.nextNode())) {
        textNodes.push(node);
      }

      const newMatches = [];
      textNodes.forEach((textNode) => {
        const nodeText = textNode.textContent;
        const matches = [...nodeText.matchAll(regex)];

        if (matches.length > 0) {
          const fragment = document.createDocumentFragment();
          let lastIndex = 0;

          matches.forEach((match) => {
            // Add text before the match
            if (match.index > lastIndex) {
              fragment.appendChild(
                document.createTextNode(nodeText.slice(lastIndex, match.index))
              );
            }

            // Create highlight span
            const highlightSpan = document.createElement('span');
            highlightSpan.className = 'highlight';
            highlightSpan.textContent = match[0];
            fragment.appendChild(highlightSpan);
            newMatches.push(highlightSpan);

            lastIndex = match.index + match[0].length;
          });

          // Add remaining text
          if (lastIndex < nodeText.length) {
            fragment.appendChild(
              document.createTextNode(nodeText.slice(lastIndex))
            );
          }

          textNode.parentNode.replaceChild(fragment, textNode);
        }
      });

      setMatches(newMatches);

      if (newMatches.length > 0) {
        setCurrentMatchIndex(0);
        const firstMatch = newMatches[0];
        firstMatch.classList.add('current-match');
        firstMatch.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      } else {
        setCurrentMatchIndex(-1);
      }
    } catch (error) {
      console.error('Search error:', error);
    }
  };

  // Add this useEffect to clear highlights when search term changes
  useEffect(() => {
    if (!searchTerm) {
      const editorContent = document.getElementById('editorjs');
      if (editorContent) {
        const existingHighlights = editorContent.getElementsByClassName('highlight');
        while (existingHighlights.length > 0) {
          const parent = existingHighlights[0].parentNode;
          parent.replaceChild(document.createTextNode(existingHighlights[0].textContent), existingHighlights[0]);
        }
      }
      setMatches([]);
      setCurrentMatchIndex(-1);
    }
  }, [searchTerm]);

  // Add this function to expose the save functionality
  const handleSaveSummary = async () => {
    if (editorInstance.current && hasChanges) {
      setIsSaving(true);
      const content = await editorInstance.current.save();

      if (onSave) {
        onSave(content);
      }

      // Update initial content after saving
      initialContent.current = JSON.stringify(content);
      setHasChanges(false);

      // Ensure the spinner shows for at least 2.5 seconds
      await new Promise(resolve => setTimeout(resolve, 3000));
      setIsSaving(false);
      setHasChanges(false);
    }
  };

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '1rem',
        gap: '10px'
      }}>
        <form onSubmit={handleSearch} style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          flex: 1
        }}>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Busca dentro del resumen..."
            style={{
              width: '70%',
              padding: '0.5rem',
              fontSize: '1rem',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }}
          />
          {matches.length > 0 && (
            <>
              <button
                type="button"
                onClick={() => navigateToMatch('prev')}
                style={{
                  padding: '8px',
                  cursor: 'pointer',
                  backgroundColor: 'transparent',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  transition: 'all 0.2s ease',
                  width: '32px',
                  height: '32px'
                }}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f0f0f0'}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M18 15l-6-6-6 6" />
                </svg>
              </button>
              <button
                type="button"
                onClick={() => navigateToMatch('next')}
                style={{
                  padding: '8px',
                  cursor: 'pointer',
                  backgroundColor: 'transparent',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  transition: 'all 0.2s ease',
                  width: '32px',
                  height: '32px'
                }}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#f0f0f0'}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M6 9l6 6 6-6" />
                </svg>
              </button>
              <span style={{
                fontSize: '0.9rem',
                color: '#666',
                minWidth: '60px',
                padding: '4px 8px',
                backgroundColor: '#f5f5f5',
                borderRadius: '4px',
                textAlign: 'center'
              }}>
                {currentMatchIndex + 1}/{matches.length}
              </span>
            </>
          )}
        </form>

        <Button
          onClick={handleSaveSummary}
          variant="contained"
          disabled={isSaving || !hasChanges}
          startIcon={isSaving ? (
            <CircularProgress
              size={20}
              sx={{
                color: '#6BBDBD'
              }}
            />
          ) : (
            <SaveIcon sx={{ color: hasChanges ? 'white' : '#6BBDBD' }} />
          )}
          sx={{
            backgroundColor: 'white',
            '&:hover': {
              backgroundColor: '#f8fafa',
              transform: 'translateY(-1px)',
              boxShadow: '0 4px 8px rgba(106, 189, 189, 0.2)'
            },
            '&.Mui-disabled': {
              backgroundColor: 'white',
              opacity: 0.5,
              color: '#ccc',
              border: '2px solid #ccc'
            },
            fontFamily: "'Fira Sans', sans-serif",
            textTransform: 'none',
            fontWeight: 600,
            fontSize: '0.9rem',
            padding: '10px 20px',
            minWidth: '120px',
            borderRadius: '8px',
            transition: 'all 0.2s ease-in-out',
            boxShadow: '0 2px 4px rgba(106, 189, 189, 0.1)',
            color: '#6BBDBD',
            border: '2px solid #6BBDBD',
            '&:active': {
              transform: 'translateY(1px)',
              boxShadow: '0 2px 4px rgba(106, 189, 189, 0.1)',
              backgroundColor: '#f0f7f7'
            }
          }}
        >
          {isSaving ? 'Guardando...' : 'Guardar'}
        </Button>

      </div>
      <div id="editorjs" className="editor-js-wrapper" style={{ height: 'calc(100% - 60px)' }}></div>
    </div>
  );
};

export default EditorText;
