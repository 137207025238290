import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Button
} from '@mui/material';

const DeleteQuestionDialog = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "20px",
          padding: "24px",
          maxWidth: "400px",
          width: "100%"
        }
      }}
    >
      <DialogTitle sx={{
        padding: "0",
        fontFamily: 'Fira Sans',
      }}>
        <Typography sx={{
          fontSize: '16px',
          fontWeight: 'bold',
          fontFamily: 'Fira Sans',
          color: '#000',
          mb: 1
        }}>
          Confirmar eliminación
        </Typography>
        <Typography sx={{
          fontSize: '14px',
          color: '#A0A0A0',
          fontFamily: 'Fira Sans',
          mb: 2
        }}>
          ¿Estás seguro de que quieres eliminar esta pregunta?
        </Typography>
      </DialogTitle>
      <DialogActions sx={{
        padding: "16px 0 0 0",
        marginTop: 'auto'
      }}>
        <Button
          onClick={onClose}
          sx={{
            color: '#A0A0A0',
            fontFamily: 'Fira Sans',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: 'transparent',
              color: '#666'
            }
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={onConfirm}
          sx={{
            backgroundColor: '#AA161D',
            color: 'white',
            fontFamily: 'Fira Sans',
            textTransform: 'none',
            borderRadius: '20px',
            padding: '6px 16px',
            '&:hover': {
              backgroundColor: '#8A1219'
            },
            minWidth: '100px'
          }}
        >
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteQuestionDialog; 