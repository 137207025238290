import React, { useState } from 'react';
import { Box, Typography, Grid, Paper, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import EditObjetivosPopup from '../EditObjetivosPopup';
import Diana from '../Diana.svg';
import { useUserInfo } from '../../Context/UserContext';

const Objetivos = ({ allPointsData, userData, todayExams }) => {
  const emoji = ['🎉', '😀', '😥', '😭'];
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [dailyGoal, setDailyGoal] = useState(userData?.objective_questions || 0);
  const { updateUserInfo } = useUserInfo();

  React.useEffect(() => {
    if (userData?.objective_questions !== undefined) {
      setDailyGoal(userData.objective_questions);
    }
  }, [userData]);

  const getEmoji = (score, dayIndex) => {
    const today = new Date().getDay();
    const currentDayIndex = today === 0 ? 6 : today - 1;
    if (dayIndex > currentDayIndex) return '';
    if (dayIndex === currentDayIndex && score === 0) return '';
    if (score / dailyGoal >= 1) return emoji[0];
    if (score / dailyGoal >= 0.75) return emoji[1];
    if (score / dailyGoal >= 0.50) return emoji[2];
    return emoji[3];
  };

  const getPointDisplay = (points, index) => {
    const today = new Date().getDay();
    const currentDayIndex = today === 0 ? 6 : today - 1;
    return index > currentDayIndex ? "-" : points;
  };

  const handleSaveGoal = async (newGoal) => {
    try {
      await updateUserInfo({ "objective_questions": newGoal });
      setDailyGoal(newGoal);
    } catch (error) {
      console.error('Error updating goal:', error);
    }
  };

  const getBackgroundColor = (points, index) => {
    const today = new Date().getDay();
    const currentDayIndex = today === 0 ? 6 : today - 1;

    if (index > currentDayIndex) {
      return {
        backgroundColor: 'white',
        border: '2px solid #f8d7da'
      };
    }

    return {
      backgroundColor: points >= 25 ? '#d1e7dd' :
        points >= 15 ? '#fff3e0' :
          points >= 5 ? '#fde2e2' :
            '#f8d7da',
      border: 'none'
    };
  };

  return (
    <Box sx={{ 
      display: 'none',
      '@media (min-width: 1100px)': {
        display: 'block'
      }
    }}>
      <Paper elevation={0} sx={{ 
        p: 1.5, 
        borderRadius: "12px", 
        border: '1px solid #E7E7E7',
        height: '100%',
        background: 'linear-gradient(to right, #FFFFFF, #F4FAFA)',
        boxShadow: '0px 4px 12px rgba(107, 189, 189, 0.15)'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, height: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, minWidth: 'fit-content' }}>
            <Typography sx={{ 
              fontWeight: '600', 
              fontSize: '13px', 
              fontFamily: 'Fira Sans', 
              whiteSpace: 'nowrap',
              color: '#6BBDBD',
              minWidth: '60px'
            }}>
              Objetivo de estudio
            </Typography>
            <IconButton onClick={() => setIsEditOpen(true)} sx={{ 
              padding: '2px',
              '&:hover': {
                backgroundColor: 'rgba(107, 189, 189, 0.08)'
              }
            }}>
              <EditIcon sx={{ fontSize: 12, color: '#6BBDBD' }} />
            </IconButton>
          </Box>

          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1.5,
            width: '100%',
            justifyContent: 'flex-end'
          }}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1, 
              minWidth: 'fit-content',
              backgroundColor: '#F4FAFA',
              padding: '6px 12px',
              borderRadius: '8px',
              border: '1px solid rgba(107, 189, 189, 0.2)'
            }}>
              <img src={Diana} alt="edit" height="32px" width="32px" />
              <Box>
                <Typography sx={{ fontSize: '15px', fontFamily: 'Fira Sans', color: '#6BBDBD', lineHeight: 1, fontWeight: '600', marginBottom: '3px' }}>
                  {todayExams}/{dailyGoal}
                </Typography>
                <Typography sx={{ fontSize: '11px', fontFamily: 'Fira Sans', color: '#666666', lineHeight: 1.2 }}>
                  Preguntas diarias
                </Typography>
              </Box>
            </Box>

            {/* <Grid container spacing={0.5} sx={{ width: 'auto', maxWidth: '280px' }}>
              {Array.isArray(allPointsData) && allPointsData.map((dayData, index) => (
                <Grid item key={index} sx={{ width: '32px' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}>
                    <Typography sx={{ fontSize: '11px', fontFamily: 'Fira Sans', color: '#666666', fontWeight: '500' }}>
                      {dayData.date.substring(0, 1)}
                    </Typography>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 26,
                      height: 26,
                      borderRadius: '50%',
                      transition: 'transform 0.2s ease',
                      '&:hover': {
                        transform: 'scale(1.1)'
                      },
                      ...getBackgroundColor(dayData.points, index)
                    }}>
                      <Typography sx={{ fontSize: '15px' }}>
                        {getEmoji(dayData.points, index)}
                      </Typography>
                    </Box>
                    <Typography sx={{ fontSize: '13px', fontFamily: 'Fira Sans', color: '#6BBDBD', fontWeight: '600' }}>
                      {getPointDisplay(dayData.points, index)}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid> */}
          </Box>
        </Box>

        <EditObjetivosPopup
          open={isEditOpen}
          onClose={() => setIsEditOpen(false)}
          currentGoal={dailyGoal}
          onSave={handleSaveGoal}
        />
      </Paper>
    </Box>
  );
};

export default Objetivos;
