import React, { useState } from 'react';
import UploadFilesFolder from "../NewComponents/Upload/UploadFileFolder";
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import {
    Button,
    Box,
    FormControl,
    Typography,
    Stepper,
    Step,
    StepLabel,
    FormControlLabel,
    Switch,
    Select,
    MenuItem,
    Checkbox,
    Slider
} from '@mui/material';
import ShowFolders from '../NewComponents/Upload/ShowFolders';
import { useNavigate, useParams } from 'react-router-dom';
import SubscriptionPopup from './SubscriptionPopup';
import { useSubscription } from "../Context/SubscriptionContext";

const QUESTIONS_PER_PAGE = 8;

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '12px',
        fontWeight: '600',
        lineHeight: '26px'
    },
    buttonSubir: {
        backgroundColor: "#6BBDBD",
        color: "white",
        textTransform: "none",
        borderRadius: "20px",
        fontSize: "14px",
        padding: "8px 16px",
        fontWeight: 600,
        '&:disabled': {
            backgroundColor: '#B0B0B0',
            color: '#FFFFFF'
        },
        '&:hover': {
            backgroundColor: '#6BBDBD',
            color: "white",
        }
    },
    buttonSubirAtras: {
        color: "#6BBDBD",
        backgroundColor: "white",
        borderRadius: "20px",
        border: "1px solid #6BBDBD",
        textTransform: "none",
        fontSize: "14px",
        fontWeight: 600,
    },
    stepper: {
        '& .MuiStepLabel-label': {
            fontSize: '14px',
            fontFamily: 'Poppins'
        },
        '& .MuiStepIcon-root.MuiStepIcon-active': {
            color: '#6BBDBD'
        },
        '& .MuiStepIcon-root.MuiStepIcon-completed': {
            color: '#6BBDBD'
        }
    },
    contentContainer: {
        height: '100%',
        marginTop: "30px",
        display: 'flex',
        flexDirection: 'column'
    },
    languageToggle: {
        marginTop: '20px',
        marginBottom: '10px',
        '& .MuiFormControlLabel-label': {
            fontFamily: 'Fira Sans',
            fontSize: '14px',
            color: '#505050'
        },
        '& .MuiSwitch-colorPrimary.Mui-checked': {
            color: '#6BBDBD'
        },
        '& .MuiSwitch-track': {
            backgroundColor: '#B0B0B0'
        },
        '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#6BBDBD'
        }
    },
    checkboxContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: '12px',
        marginBottom: '30px',
        flexWrap: 'wrap',
        width: '100%',
        maxWidth: '600px'
    },
    checkboxItem: {
        background: 'white',
        border: '1px solid #E0E0E0',
        borderRadius: '8px',
        padding: '8px 16px',
        transition: 'all 0.2s ease',
        cursor: 'pointer',
        minWidth: '120px',
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            borderColor: '#6BBDBD',
            transform: 'translateY(-1px)',
            boxShadow: '0 2px 8px rgba(107, 189, 189, 0.08)'
        }
    },
    checkboxSelected: {
        background: 'rgba(107, 189, 189, 0.03)',
        border: '1px solid #6BBDBD',
        boxShadow: '0 2px 8px rgba(107, 189, 189, 0.08)'
    },
    sliderContainer: {
        width: '80%',
        maxWidth: '400px',
        marginBottom: 4,
        padding: '20px',
        background: 'white',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.03)',
        border: '1px solid #E0E0E0',
        transition: 'all 0.2s ease'
    },
    questionTitle: {
        fontSize: '16px',
        fontWeight: '500',
        marginBottom: '20px',
        color: '#505050',
        fontFamily: 'Fira Sans',
        textAlign: 'left',
        '&:after': {
            display: 'none'
        }
    }
}));

const ModalUploadFiles = ({
    handleButtonClick,
    setShowSpinner,
    setShowAlert,
    setMessage,
    setProgressBar,
    setShowUpgrade,
    user,
    token,
    onClose,
    updateListDocuments,
    setCredits,
    currentCredits,
}) => {
    const classes = useStyles();
    const { folder_id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userData } = useSubscription();
    const [activeStep, setActiveStep] = useState(0);
    const [files, setFiles] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState("");
    const [newFolderName, setNewFolderName] = useState("");
    const [pages, setPages] = useState("");
    const [numDocuments, setNumDocuments] = useState("");
    const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('español');
    
    // New state variables for checkboxes and slider
    const [checkboxes, setCheckboxes] = useState({
        preguntas: true,
        tarjetas: false,
        resumen: false,
        esquemas: false
    });
    const [numPreguntas, setNumPreguntas] = useState(20);
    const [showEsquemasMessage, setShowEsquemasMessage] = useState(false);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(0);
        // Reset both the selected folder and folder name
        setSelectedFolder("");
        handleNewFolder.reset?.();
    };

    const handleSetValue = (value) => {
        setSelectedFolder(value);
    };

    const handleNewFolder = (folderName) => {
        setNewFolderName(folderName);
    };

    const handleUploadClick = () => {
        const hasLargeFiles = files.some(file => file.original_pages > 40);
        if (hasLargeFiles && userData?.plan_name === "Free") {
            setShowSubscriptionPopup(true);
        } else {
            handleUpload();
        }
    };

    const handleUpload = () => {
        handleButtonClick({
            typed: "upload",
            file: files,
            value: folder_id || selectedFolder || "Nueva Carpeta",
            newFolderName,
            setShowSpinner,
            setShowAlert,
            setMessage,
            setProgressBar,
            setShowUpgrade,
            user: user,
            token: token,
            onClose: onClose,
            navigate: navigate,
            updateListDocuments: updateListDocuments,
            language: selectedLanguage,
            numPreguntas: numPreguntas,
            isGenerateQuestion: checkboxes.preguntas,
            isGenerateFlashcards: checkboxes.tarjetas,
          
        });
    };

    const handleCheckboxChange = (name) => {
        setCheckboxes(prev => {
            const updatedCheckboxes = {
                ...prev,
                [name]: !prev[name]
            };
            if (name === 'esquemas' && !prev['esquemas']) {
                updatedCheckboxes['resumen'] = true;
                setShowEsquemasMessage(true);
            } else if (name === 'esquemas' && prev['esquemas']) {
                setShowEsquemasMessage(false);
            }
            return updatedCheckboxes;
        });
    };

    const steps = [
        {
            content: (
                <div className={classes.contentContainer}>
                    <div style={{ flex: 1 }}>
                        <UploadFilesFolder
                            files={files}
                            setFiles={setFiles}
                            is_multiple={true}
                            setPages={setPages}
                            setNumDocuments={setNumDocuments}
                            customTitle="Elige qué material generar"
                        />
                        {files.length > 0 && (
                            <FormControl 
                                variant="outlined" 
                                size="small" 
                                style={{ marginTop: '20px', marginBottom: '10px' }}
                            >
                                <Typography 
                                    variant="body2" 
                                    style={{ 
                                        marginBottom: '8px',
                                        fontFamily: 'Fira Sans',
                                        fontSize: '14px',
                                        color: '#505050'
                                    }}
                                >
                                    Idioma del documento:
                                </Typography>
                                <Select
                                    value={selectedLanguage}
                                    onChange={(e) => {
                                        setSelectedLanguage(e.target.value);
                                    }}
                                    style={{
                                        minWidth: '200px',
                                        fontFamily: 'Fira Sans',
                                        fontSize: '14px'
                                    }}
                                >
                                    <MenuItem value="español">Español</MenuItem>
                                    <MenuItem value="catalán">Catalán</MenuItem>
                                    <MenuItem value="inglés">Inglés</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    </div>
                    <Box display="flex" justifyContent="flex-end" marginTop="20px">
                        <Button
                            style={{
                                backgroundColor: files.length === 0 ? "#B0B0B0" : "#6BBDBD",
                                color: "white",
                                textTransform: "none",
                                borderRadius: "20px",
                                fontSize: "14px",
                                fontWeight: 600,
                                padding: "8px 24px",
                                marginBottom: "10px"
                            }}
                            onClick={handleNext}
                            disabled={files.length === 0}
                        >
                            Siguiente
                        </Button>
                    </Box>
                </div>
            )
        },
        {
            content: (
                <div className={classes.contentContainer}>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
                        <Typography 
                            className={classes.questionTitle}
                        >
                            ¿Qué quieres generar?
                        </Typography>
                        
                        <Box className={classes.checkboxContainer}>
                            {[
                                { key: 'preguntas', label: 'Preguntas' },
                                { key: 'tarjetas', label: 'Tarjetas de memoria' },
                              
                            ].map((item) => (
                                <Box
                                    key={item.key}
                                    className={`${classes.checkboxItem} ${checkboxes[item.key] ? classes.checkboxSelected : ''}`}
                                    onClick={() => handleCheckboxChange(item.key)}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checkboxes[item.key]}
                                                onChange={(e) => {
                                                    e.stopPropagation();
                                                    handleCheckboxChange(item.key);
                                                }}
                                                style={{ 
                                                    color: '#6BBDBD',
                                                    padding: '4px'
                                                }}
                                                size="small"
                                            />
                                        }
                                        onClick={(e) => e.stopPropagation()}
                                        label={item.label}
                                        style={{ 
                                            fontFamily: 'Fira Sans',
                                            margin: 0,
                                            fontSize: '14px',
                                            fontWeight: checkboxes[item.key] ? 500 : 400
                                        }}
                                    />
                                </Box>
                            ))}
                        </Box>
                        {console.log("files", files)}
                        {(checkboxes.preguntas || checkboxes.tarjetas) && (
                            <Box className={classes.sliderContainer}>
                                <Typography
                                    style={{
                                        marginBottom: '20px',
                                        fontFamily: 'Fira Sans',
                                        color: '#2C3E50',
                                        textAlign: 'center',
                                        fontSize: '14px',
                                        fontWeight: 500
                                    }}
                                >
                                    {checkboxes.preguntas && checkboxes.tarjetas
                                        ? '¿Cuántas preguntas y tarjetas quieres generar?'
                                        : checkboxes.preguntas
                                            ? '¿Cuántas preguntas quieres generar?'
                                            : '¿Cuántas tarjetas quieres generar?'}
                                </Typography>
                                <Slider
                                    value={numPreguntas}
                                    onChange={(_, newValue) => setNumPreguntas(newValue)}
                                    min={0}
                                    max={Math.min(500, files[0]?.pages ? Math.ceil((files[0].pages * QUESTIONS_PER_PAGE) / 20)*20:100)}
                                    step={20}
                                    marks={false}
                                    valueLabelDisplay="auto"
                                    style={{ color: '#6BBDBD' }}
                                    size="small"
                                />
                                <Typography
                                    style={{
                                        marginTop: '12px',
                                        fontFamily: 'Fira Sans',
                                        color: '#6BBDBD',
                                        textAlign: 'center',
                                        fontSize: '16px',
                                        fontWeight: 500
                                    }}
                                >
                                    {numPreguntas} {
                                        checkboxes.preguntas && checkboxes.tarjetas
                                            ? numPreguntas === 1 
                                                ? 'elemento' 
                                                : 'elementos de cada'
                                            : checkboxes.preguntas
                                                ? numPreguntas === 1 
                                                    ? 'pregunta' 
                                                    : 'preguntas'
                                                : numPreguntas === 1 
                                                    ? 'tarjeta' 
                                                    : 'tarjetas'
                                    }
                                </Typography>
                                <Typography
                                    style={{
                                        marginTop: '8px',
                                        fontFamily: 'Fira Sans',
                                        color: '#808080',
                                        textAlign: 'center',
                                        fontSize: '13px',
                                        fontStyle: 'italic'
                                    }}
                                >
                                    Podrás añadir más material posteriormente
                                </Typography>
                            </Box>
                        )}

                        {showEsquemasMessage && (
                            <Typography
                                style={{
                                    marginTop: '10px',
                                    fontFamily: 'Fira Sans',
                                    color: '#A0A0A0',
                                    textAlign: 'center',
                                    fontSize: '14px'
                                }}
                            >
                                Para generar esquemas es necesario también el resumen.
                            </Typography>
                        )}

                        <Box display="flex" justifyContent="center" marginTop="30px" gap={2}>
                            <Button
                                className={classes.buttonSubirAtras}
                                onClick={handleBack}
                                style={{ 
                                    padding: '6px 20px',
                                    fontSize: '14px'
                                }}
                            >
                                Atrás
                            </Button>
                            <Button
                                className={classes.buttonSubir}
                                onClick={handleNext}
                                disabled={numPreguntas === 0}
                                style={{ 
                                    padding: '6px 20px',
                                    fontSize: '14px'
                                }}
                            >
                                Continuar
                            </Button>
                        </Box>
                    </div>
                </div>
            )
        },
        {
            content: (
                <div className={classes.contentContainer}>
                    <div style={{ flex: 1 }}>
                        <FormControl fullWidth>
                            <ShowFolders
                                value={selectedFolder}
                                setValue={handleSetValue}
                                handleNewFolder={handleNewFolder}
                                margin="dense"
                            />
                        </FormControl>
                    </div>
                    <Box display="flex" justifyContent="flex-end" marginTop="20px" marginBottom="10px">
                        <Button
                            className={classes.buttonSubirAtras}
                            onClick={handleBack}
                            style={{ marginRight: "10px" }}
                        >
                            Atrás
                        </Button>
                        <Button
                            className={classes.buttonSubir}
                            onClick={handleUploadClick}
                            disabled={!selectedFolder && (newFolderName.trim() === '' || newFolderName === null)}
                        >
                            Subir
                        </Button>
                    </Box>
                </div>
            )
        }
    ];

    return (
        <div>
            {steps[activeStep].content}
            <SubscriptionPopup
                open={showSubscriptionPopup}
                onClose={() => setShowSubscriptionPopup(false)}
                onUpload={handleUpload}
            />
        </div>
    );
};

export default ModalUploadFiles;
