import { Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSession, useSupabaseClient } from '@supabase/auth-helpers-react'
import Spinner from "../NewComponents/Spinner";
import { getCsrfToken } from '../Api/TokenApi'

// const BACKEND_URL = "localhost";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const REACT_FRONT_END = process.env.REACT_APP_FRONT_END;

export default function LoginSuccess() {
  const [loading, setLoading] = useState(true);
  const [newuser, setNewuser] = useState(false);
  const session = useSession()
  const supabase = useSupabaseClient()
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to login if no session
    if (!session) {
      navigate('/login');
      return;
    }

    const handleAuth = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      
      // Check if user exists and email is confirmed
      if (!user || !user.email_confirmed_at) {
        // Redirect to verification page if email is not confirmed
        window.location.href = `https://${REACT_FRONT_END}/verify`;
        return;
      }

      // If email is confirmed, proceed with user registration
      const userInfo = {
        sub: user.id,
        email: user.email,
        email_verified: true,
        given_name: user.user_metadata?.name,
        family_name: user.user_metadata?.surname,
        picture: user.user_metadata?.avatar_url
      };

      try {
        await registerUser(userInfo);
      } catch (error) {
        console.error('Error during registration:', error);
        window.location.href = `https://${REACT_FRONT_END}/innactive`;
      }
    };

    handleAuth();
  }, [session, supabase]);

  useEffect(() => {
    if (!loading) {
      if (newuser) {
        navigate("/welcome");
      } else {
        navigate("/");
      }
    }
  }, [loading, newuser, navigate]);

  const registerUser = async (userInfo) => {
    try {
      if (!session) throw new Error('No active session');
      const token = session.access_token;
      if (!token) throw new Error('Not authenticated');

      // Log all cookies to see what's available
      
      // Try to get CSRF token from cookies
      const cookies = document.cookie.split(';');
      let csrfToken = '';
      for (const cookie of cookies) {
        const [name, value] = cookie.trim().split('=');
        if (name === 'csrftoken') {
          csrfToken = value;
          break;
        }
      }
      

      // If CSRF token is empty, try to fetch it from the server
      if (!csrfToken) {
        try {
          // Make a GET request to your API to get a CSRF token
          const csrfResponse = await fetch(`${BACKEND_URL}/api_v1/get-csrf-token/`, {
            method: 'GET',
            credentials: 'include',
          });
          
          // After this request, try to get the CSRF token again
          csrfToken = getCsrfToken();

          console.log('CSRF Token after fetch:', csrfToken);
        } catch (error) {
          console.error('Error fetching CSRF token:', error);
        }
      }

      // Proceed with registration request
      const response = await fetch(`${BACKEND_URL}/api_v1/registration/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify(userInfo),
        credentials: 'include',
      });
      // Handle different response status codes
      switch (response.status) {
        case 200: // Existing user
          console.log('User already registered');
          navigate('/');
          return;
        case 201: // New user created
         console.log("Setting new user")

          setNewuser(true);
          return;
        case 401: // Unauthorized
          console.error('Unauthorized access');
          navigate('/login');
          return;
        default:
          // For other status codes, try to get error details
          const responseText = await response.text();
          console.error('Registration failed:', response.status, responseText);
          if (response.status === 400) {
            navigate('/inactive');
          } else {
            throw new Error(`Registration failed: ${response.statusText}`);
          }
      }
    } catch (error) {
      console.error("Error in registration process:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return null;
}
