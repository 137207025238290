import { makeStyles } from '@mui/styles';

const useStylesPopupGettingStarted = makeStyles({
  '@global': {
    '@import': "url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap')",
  },
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: 16,
      padding: '24px 24px 24px 24px', // Reduced left padding
      maxWidth: 550,
      fontFamily: "'Inter', sans-serif",
    },
  },
  title: {
    '& .MuiDialogTitle-root': {
      fontWeight: 700,
      marginBottom: 0, // Reduced from 16px to 8px


    },
    '& .MuiTypography-root': {
      fontSize: '28px',
      lineHeight: '32px',
      fontWeight: 700,
      fontFamily: "'Inter', sans-serif",
      textAlign: 'center',
    },
  },
  content: {
    textAlign: 'center',
    '& .MuiDialogContent-root': {
      padding: 0, // Remove default padding
    },
    '& .MuiTypography-root': {
      fontFamily: "'Inter', sans-serif",
    },
  },
  creditsNumber: {
    color: '#4CAF50', // MUI green color
    fontSize: '4rem',
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif",
    textAlign: 'center',
    marginBottom: '0px',

  },
  creditsTextContainer: {
    marginBottom: "0px",
    color: "gray",
    fontSize: '0.9rem',
    fontWeight: "bold",
    fontFamily: "'Inter', sans-serif",
    textAlign: 'center',
    width: '80%',
    display: 'flex',
    margin: '0px auto',
  },
  button: {
    backgroundColor: '#6BBDBD', // Light blue color
    color: 'white',
    borderRadius: 20,
    padding: '8px 24px',
    textTransform: 'none',
    fontWeight: 600,
    fontFamily: "'Inter', sans-serif",
    '&:hover': {
      backgroundColor: '#4FC3F7', // Slightly darker blue on hover
    },
  },

});

export default useStylesPopupGettingStarted;
