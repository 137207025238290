import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  CircularProgress
} from '@mui/material';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { styled } from '@mui/material/styles';
import { FiberManualRecord as BulletIcon } from '@mui/icons-material';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import SimulacroSummary from './SimulacroSummary';
import { useAuth } from '../Auth/Authhandle';
import { fetchQuestionAnswer, fetchExamId } from '../Api/QuestionsApi';
import { fetchExamId as fetchExamIdApi } from '../Api/ExamsApi';

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #026277 10%, #7CC8FB 90%)',
  color: 'white',
  fontWeight: 600,
  textTransform: "none",
  borderRadius: '7px',
  ':hover': {
    background: 'linear-gradient(45deg, #024E5A 30%, #6AB2E7 90%)',
  },
  padding: "7px",
  paddingLeft: "25px",
  paddingRight: "25px",
}));

const StyledCheckbox = styled(Checkbox)({
  '&.MuiCheckbox-root': {
    width: 30,
    height: 30,
    padding: 0,
    marginRight: 8,
    color: '#026277'
  },

  '&.Mui-checked': {
    color: '#026277'
  },

  '&:hover': {
    backgroundColor: 'rgba(2, 98, 119, 0.1)'
  },

  '& .MuiSvgIcon-root': {
    fontSize: 20
  }
});

const SimulacroPage = () => {
  const { token, user, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { exam_id: urlExamId } = useParams(); // Get exam_id from URL params
  const [examConfig, setExamConfig] = useState({
    correctPoints: 1,
    incorrectPoints: -0.25,
    questionCount: 10,
    timeLimit: 30
  });
  const [questions, setQuestions] = useState([]);
  const [exam_id, setExamId] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  // Fetch exam data if not provided in location state
  useEffect(() => {
    const fetchExamData = async () => {
      try {
        // If we have data in location state, use that
        if (location.state?.questions && location.state?.exam_id) {
          setQuestions(location.state.questions);
          setExamId(location.state.exam_id);
          setExamConfig(location.state.examConfig || examConfig);
          setIsLoading(false);
          return;
        }

        // Otherwise fetch from API using URL param
        if (urlExamId && token) {
          const examData = await fetchExamIdApi(token, "GET", urlExamId, user);
          if (examData.exam_answered_date) {
            const results = {
              guid: examData.guid,
              title: examData.title,
              final_score: examData.final_score,
              timeSpent: examData.time_spent || "30:01", // Format shown in the image
              examConfig: {
                correctPoints: examData.correct_punctuation,
                incorrectPoints: examData.incorrect_punctuation,
                questionCount: examData.num_questions,
                timeLimit: examData.time_limit
              },
              questions: examData.questions.map((question, index) => {
                const answer = question.question_answers[0]; // Just take the first element since there's only one

                return {
                  number: index + 1,
                  guid: question.guid,
                  text: question.question_statement,
                  userAnswer: answer?.question_answer || 'Sin respuesta',
                  correctAnswer: question.question_correct_answer,
                  explanation: question.question_explanation,
                  options: question.question_options.map(opt => opt.text),
                  isCorrect: answer?.question_answer === question.question_correct_answer,
                  sum_points: answer?.punctuation || 0
                };
              }),
              stats: {
                correctCount: examData.questions.filter(q =>
                  examData.question_answers.find(a =>
                    a.guid === q.guid && a.question_answer === q.question_correct_answer
                  )
                ).length,
                incorrectCount: examData.questions.filter(q =>
                  examData.question_answers.find(a =>
                    a.guid === q.guid && a.question_answer !== q.question_correct_answer
                  )
                ).length
              }
            };

            setExamResults(results);
            setShowSummary(true);
            setIsLoading(false)
          }
          setQuestions(examData.questions || []);
          setExamId(urlExamId);
          setIsLoading(false)


        }
      } catch (error) {
        console.error('Error fetching exam data:', error);
        // Handle error - maybe redirect to error page or show error message
      } 
    };

    fetchExamData();
  }, [token, user, urlExamId, location.state]);

  const [timeLeft, setTimeLeft] = useState(examConfig.timeLimit * 60); // Convert minutes to seconds
  const [answers, setAnswers] = useState({});

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  // Add new state for showing summary
  const [showSummary, setShowSummary] = useState(false);
  const [examResults, setExamResults] = useState(null);

  // Add this state at the top of your component
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Add useEffect to update timeLeft when examConfig changes
  useEffect(() => {
    setTimeLeft(examConfig.timeLimit * 60);
  }, [examConfig]);

  const calculateScore = () => {
    const correctAnswers = questions.filter(q =>
      answers[q.guid] === q.correct_answer
    ).length;

    const incorrectAnswers = questions.filter(q =>
      answers[q.guid] && answers[q.guid] !== q.correct_answer
    ).length;

    // Calculate total points
    const pointsFromCorrect = correctAnswers * examConfig.correctPoints;
    const pointsFromIncorrect = incorrectAnswers * examConfig.incorrectPoints;
    const totalPoints = pointsFromCorrect + pointsFromIncorrect;

    // Convert to scale of 10
    const maxPossiblePoints = questions.length * examConfig.correctPoints;
    const scoreOutOf10 = (totalPoints / maxPossiblePoints) * 10;

    // Return score out of 10 with 2 decimal places
    return Math.max(0, Math.min(10, scoreOutOf10)).toFixed(2);
  };

  // Modify your handleFinishExam function
  const handleFinishExam = async () => {
    setIsSubmitting(true);
    try {
      const processedAnswers = Object.keys(answers).map(questionId => {
        return { [questionId]: answers[questionId] || 'Sin respuesta' };
      });

      const saveResults = await fetchQuestionAnswer(token, "POST", exam_id, user, {
        questions_answers: processedAnswers,
        type_exam: "simulacro",
        final_score: calculateScore(),
        time_spent: (examConfig.timeLimit * 60) - timeLeft,
      });


      const results = {
        timeSpent: (examConfig.timeLimit * 60) - timeLeft,
        examConfig: examConfig,
        questions: questions.map(question => {
          const userAns = answers[question.guid] || 'Sin respuesta';
          return {
            text: question.question_statement,
            userAnswer: userAns,
            correctAnswer: question.correct_answer,
            explanation: question.explanation,
            options: question.options.map(opt => opt.text),
            document_title: question.document_title,
            folder_name: question.folder_name,
            isCorrect: userAns === question.correct_answer
          };
        })
      };

      setExamResults(results);
      setShowSummary(true);
      setShowConfirmDialog(false);
    } catch (error) {
      console.error('Error saving simulacro results:', error);
      // Still show results even if saving fails
      const results = {
        timeSpent: (60 * 60) - timeLeft,
        examConfig: examConfig,
        questions: questions.map(question => {
          const userAns = answers[question.guid] || 'Sin respuesta';
          return {
            text: question.question_statement,
            userAnswer: userAns,
            correctAnswer: question.correct_answer,
            explanation: question.explanation,
            options: question.options.map(opt => opt.text),
            document_title: question.document_title,
            folder_name: question.folder_name,
            isCorrect: userAns === question.correct_answer
          };
        })
      };
      setExamResults(results);
      setShowSummary(true);
      setShowConfirmDialog(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Then modify the useEffect
  useEffect(() => {
    if (showSummary) {
      // Don't continue timer if we're showing summary
      return;
    }

    if (timeLeft <= 0) {
      handleFinishExam();
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft, showSummary, handleFinishExam]); // Add proper dependencies

  // Format time for display
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleAnswerChange = (questionId, value) => {
    setAnswers(prev => ({
      ...prev,
      [questionId]: value
    }));
  };

  // Add conditional rendering at the top of the return statement
  if (showSummary && examResults) {
    return <SimulacroSummary user={user} token={token} examResults={examResults} />;
  }

  if (isLoading) {
    return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
        gap: 2
      }}>
        <CircularProgress sx={{ color: '#026277' }} />
        <Typography sx={{
          fontFamily: "'Fira Sans', sans-serif",
          color: '#026277'
        }}>
          Cargando tu simulacro...
        </Typography>
      </Box>
    );
  }
  if (!isLoading) {
    return (
      <Box sx={{
        maxWidth: '800px',
        margin: '0 auto',
        padding: '24px',
        height: 'calc(100vh - 48px)',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
      }}>
        {/* Timer and Progress Header */}
        <Paper elevation={0} sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1000,
          padding: '20px 24px',
          marginBottom: '24px',
          backgroundColor: '#fff',
          borderRadius: '12px',
          border: '1px solid #F0F4F4',
          flexShrink: 0
        }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <Typography variant="h6" sx={{
              fontFamily: "'Fira Sans', sans-serif",
              fontWeight: 600
            }}>
              Simulacro
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                fontFamily: "'Fira Sans', sans-serif",
                fontWeight: 600
              }}>
                <TimerOutlinedIcon sx={{ color: timeLeft < 300 ? '#ff4444' : '#6BBDBD' }} />
                {formatTime(timeLeft)}
              </Typography>
              <Button
                onClick={() => setShowConfirmDialog(true)}
                variant="contained"
                sx={{
                  backgroundColor: '#6BBDBD',
                  boxShadow: 'none',
                  fontWeight: "bold",
                  borderRadius: '20px',
                  textTransform: 'none',
                  fontFamily: "'Inter', sans-serif",
                  '&:hover': {
                    backgroundColor: '#5AACAC'
                  }
                }}
              >
                Finalizar
              </Button>
            </Box>
          </Box>
        </Paper>

        {/* Questions List */}
        <Box className="custom-scrollbar" sx={{
          flex: 1,
          overflowY: 'auto',
          paddingRight: '12px',
          marginRight: '-12px',
        }}>
          {questions.map((question, index) => (
            <Paper
              key={question.guid}
              elevation={0}
              sx={{
                padding: '24px',
                marginBottom: '16px',
                borderRadius: '12px',
                border: '1px solid #F0F4F4'
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontFamily: 'Inter, sans-serif',
                    fontSize: "14px",
                    userSelect: 'none',
                    WebkitUserSelect: 'none',
                    MozUserSelect: 'none',
                    msUserSelect: 'none',
                    marginBottom: '15px'
                  }}
                >
                  {index + 1}. {question.question_statement}
                </Typography>

                <RadioGroup
                  name={`question_${question.guid}`}
                  value={answers[question.guid] || ''}
                  onChange={(e) => handleAnswerChange(question.guid, e.target.value)}
                  sx={{ marginLeft: '15px' }}
                >
                  {question.options.map((option, optIndex) => (
                    <FormControlLabel
                      key={option.guid}
                      value={option.text}
                      control={
                        <StyledCheckbox
                          checked={answers[question.guid] === option.text}
                          onChange={(e) => handleAnswerChange(question.guid, option.text)}
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            fontSize: '14px',
                            fontFamily: 'Inter, sans-serif',
                            userSelect: 'none',
                            WebkitUserSelect: 'none',
                            MozUserSelect: 'none',
                            msUserSelect: 'none'
                          }}
                        >
                          {option.text}
                        </Typography>
                      }
                      sx={{
                        marginBottom: '8px',
                        '& .MuiFormControlLabel-label': {
                          fontFamily: "'Inter', sans-serif",
                          fontSize: '14px'
                        }
                      }}
                    />
                  ))}
                </RadioGroup>
              </Box>
            </Paper>
          ))}
        </Box>

        {/* Confirmation Dialog */}
        <Dialog
          open={showConfirmDialog}
          onClose={() => setShowConfirmDialog(false)}
          PaperProps={{
            sx: {
              borderRadius: '20px',
              minWidth: '400px',
              padding: '0px'
            }
          }}
        >
          <DialogTitle sx={{
            fontFamily: "'Fira Sans', sans-serif",
            textAlign: 'left',
            fontSize: '16px'
          }}>
            ¿Deseas finalizar el simulacro?
          </DialogTitle>
          <DialogContent sx={{ padding: '24px 24px 16px 24px' }}>
            <Typography sx={{
              fontFamily: "'Inter', sans-serif",
              fontSize: '14px'
            }}>
              Has respondido {Object.keys(answers).length} de {questions.length} preguntas.
            </Typography>
          </DialogContent>
          <DialogActions sx={{ padding: '0 24px 16px 24px' }}>
            <Button
              onClick={() => setShowConfirmDialog(false)}
              sx={{
                color: '#666666',
                textTransform: 'none',
                fontFamily: "'Inter', sans-serif"
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={handleFinishExam}
              variant="contained"
              disabled={isSubmitting}
              sx={{
                backgroundColor: '#6BBDBD',
                textTransform: 'none',
                borderRadius: '20px',
                boxShadow: 'none',
                fontFamily: "'Inter', sans-serif",
                minWidth: '150px', // Ensure button doesn't shrink when showing loading state
                '&:hover': {
                  backgroundColor: '#5AACAC',
                }
              }}
            >
              {isSubmitting ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CircularProgress size={20} sx={{ color: 'white' }} />
                  <span>Corrigiendo examen...</span>
                </Box>
              ) : (
                'Finalizar'
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
};

export default SimulacroPage; 