import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    IconButton,
    FormControl,
    Typography,
    FormHelperText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';

import ShowFolders from "../../NewComponents/Upload/ShowFolders";
import "../../NewComponents/Modal/modal.css";
import * as handle from "../../Documents/handleDoc"
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => {
    return {
        noMargin: {
            margin: "0px !important",
        },
    };
});
function ApproveSelection({
    open, onClose, onFolderSelect, token, user, document, selectedFolder, handleMoveSuccess

}) {

    const { t } = useTranslation();

    const classes = useStyles();

    // Invitar Adding email
    const [value, setValue] = useState(null);



    useEffect(() => {
        const handleEsc = (e) => {
            if (e.key === "Escape") onClose();
        };

        window.addEventListener("keydown", handleEsc);

        return () => {
            window.removeEventListener("keydown", handleEsc);
        };
    }, [onClose]);




    return (
        <>


            <Dialog open={open} onClose={onClose}>
                <DialogTitle
                    style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        paddingBottom: 0,
                    }}
                >
                    {t("move_archivo")}
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                        style={{ position: "absolute", top: 8, right: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ width: "400px" }}>
                    <Typography
                        style={{
                            whiteSpace: "pre-line",
                            fontSize: "14px",
                            fontWeight: 400,
                        }}
                    >
                        {t('move_file_confirmation', {
                            documentTitle: document && document.title ? document.title : "this document",
                            folderName: selectedFolder && selectedFolder.name ? selectedFolder.name : "this folder"
                        })}                </Typography>


                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={async () => {
                            if (token && user) {

                                handle.updateDocumentFileLocation(
                                    token, document.guid, "nullFolder", null, selectedFolder, user
                                );
                                handleMoveSuccess(document, selectedFolder);
                                onClose()

                            }
                        }}
                        style={{ textTransform: "none", fontWeight: "bold" }}

                    >
                        {t("move")}
                    </Button>
                </DialogActions>
            </Dialog >
        </>
    );
}

export default ApproveSelection;
