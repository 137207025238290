import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Box, Grid, Button, Tooltip } from '@mui/material';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Calendar.css';
import { useNavigate } from 'react-router-dom';
import { Flame, CheckCircle, FileText, Brain } from 'lucide-react';
import Objetivos from './Objetivos';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 24px',
        flexGrow: 1,
        [theme.breakpoints.down('sm')]: {
            padding: '0 16px',
        },
    },
    contentBox: {
        backgroundColor: '#FFFFFF',
        borderRadius: 12,
        boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.08)',
        border: '1px solid #E7E7E7',
        padding: '24px',
        marginBottom: '50px',
        [theme.breakpoints.down('sm')]: {
            padding: '16px',
            marginBottom: '30px'
        }
    },
    calendarWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .react-calendar': {
            border: 'none',
            backgroundColor: 'transparent',
            width: '100%',
            maxWidth: '340px',
            padding: 0,
            fontFamily: 'Fira Sans, sans-serif',
            fontSize: '0.9rem'
        },
        '& .react-calendar__tile': {
            aspectRatio: '1',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '4px',
            transition: 'transform 0.2s',
            position: 'relative',
            height: '40px',
            padding: '2px',
            // margin: '1px',
            backgroundColor: '#fff',
            '&:hover': {
                transform: 'scale(1.1)',
                backgroundColor: 'transparent',
                zIndex: 1
            },
            '&:enabled:hover': {
                backgroundColor: 'transparent'
            },
            '& abbr': {
                position: 'relative',
                zIndex: 1,
                fontSize: '13px',
                fontFamily: 'Fira Sans, sans-serif'
            },
            '&.hide-number abbr': {
                display: 'none'
            }
        },
        '& .react-calendar__tile.active-day abbr': {
            color: '#fff'
        },
        '& .react-calendar__month-view__days__day': {
            color: '#666'
        },
        '& .react-calendar__navigation': {
            marginBottom: '0.75rem'
        },
        '& .react-calendar__navigation button': {
            minWidth: '44px',
            background: 'none',
            fontSize: '1rem',
            color: '#000',
            fontFamily: 'Fira Sans, sans-serif'
        },
        '& .react-calendar__month-view__weekdays': {
            color: '#666',
            fontSize: '0.8rem',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontFamily: 'Fira Sans, sans-serif',
            marginBottom: '0.5rem',
            '& abbr': {
                textDecoration: 'none'
            }
        },
        '& .react-calendar__month-view__days__day--weekend': {
            color: '#666'
        },
        '& .react-calendar__tile--now': {
            backgroundColor: 'transparent'
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '24px',
            '& .react-calendar': {
                maxWidth: '100%',
            }
        }
    },
    taskDate: {
        fontSize: '15px',
        color: '#666',
        fontFamily: 'Fira Sans',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    statsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '24px',
        padding: '16px',
        backgroundColor: '#FAFBFC',
        borderRadius: '8px',
        border: '1px solid #E7E7E7',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
}));

const CalendarHeatmap = (activityLevels) => {
    const classes = useStyles();
    const [currentDate] = useState(new Date()); // Current date    // Activity levels (0-4) for each day
    let activities = [];
    if (activityLevels != [] && activityLevels != undefined) {
        activities = activityLevels['activityLevels'];
    }
    // const activityLevels = {
    //     '2025-01-07': 3,  // January 7
    //     '2025-01-08': 2,  // January 8
    //     '2025-01-09': 2,  // January 9
    //     '2025-01-10': 1,  // January 10
    //     '2025-01-11': 1,  // January 11
    //     '2025-01-16': 2,  // January 16
    //     '2025-01-27': 3,  // January 27
    //     '2025-01-30': 2,  // January 30

    // };


    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const getActivityStyle = (date) => {
        const dateStr = formatDate(date);  // Use our new formatDate function instead of toISOString
        const level = activities?.[dateStr] || 0;
        const styles = [
            { color: '#ffffff' }, // no activity
            { color: '#9be9a8' }, // light activity
            { color: '#40c463' }, // medium activity
            { color: '#30a14e' }, // high activity
            { color: '#216e39' }, // very high activity
        ];
        return styles[level];
    };

    const tileContent = ({ date, view }) => {
        if (view === 'month') {
            const dateStr = formatDate(date);  // Use formatDate here too
            // Check both current month AND year
            const currentMonth = currentDate.getMonth();
            const currentYear = currentDate.getFullYear();

            if (date.getMonth() !== currentMonth || date.getFullYear() !== currentYear) {
                return null;
            }

            const level = activities?.[dateStr] || 0;
            if (level > 0) {
                const style = getActivityStyle(date);
                return (
                    // <Tooltip 
                    //     title={level === 0 ? 'No hay preguntas respondidas' : 
                    //            level === 1 ? '-50 preguntas respondidas' :
                    //            level === 2 ? '+50 preguntas respondidas' :
                    //            level === 3 ? '+150 preguntas respondidas' :
                    //            level === 4 ? '+200 preguntas respondidas' :
                    //            '+500 preguntas respondidas'} 
                    //     arrow 
                    //     placement="top"
                    // >
                    <div style={{
                        position: 'absolute',
                        top: '2px',
                        left: '2px',
                        width: 'calc(100% - 4px)',
                        height: 'calc(100% - 4px)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: style.color,
                        borderRadius: '4px',
                        color: 'white',
                        fontSize: '12px'
                    }}>
                        {date.getDate()}
                    </div>
                    // </Tooltip>
                );
            }
        }
        return null;
    };

    const tileClassName = ({ date, view }) => {
        if (view === 'month') {
            const dateStr = formatDate(date);  // And here
            const hasActivity = activities?.[dateStr] > 0;
            return hasActivity ? 'calendar-tile active-day hide-number' : 'calendar-tile';
        }
    };

    return (
        <div className={classes.calendarWrapper}>
            <Calendar
                value={currentDate}
                maxDetail="month"
                minDetail="month"
                navigationLabel={({ date }) =>
                    date.toLocaleString('default', { month: 'long', year: 'numeric' })
                }
                tileClassName={tileClassName}
                tileContent={tileContent}
                showNeighboringMonth={false}
            />
        </div>
    );
};

const getTextWidth = (text, font) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;
    return context.measureText(text).width;
};

const truncateText = (text, maxWidth) => {
    const font = '500 18px "Fira Sans"';
    if (getTextWidth(text, font) <= maxWidth) return text;

    let truncated = text;
    while (getTextWidth(truncated + '...', font) > maxWidth && truncated.length > 0) {
        truncated = truncated.slice(0, -1);
    }
    return truncated + '...';
};

const ActividadReciente = ({ activityLevels, allExams, streak, statsData, token, user, userData, todayExams }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const tareas = Object.entries(allExams || {}).map(([id, exam]) => ({
        nombre: exam.doc_name,
        fecha: new Date(exam.date).toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        }),
        tipo: "Examen",
        progreso: ((exam.score).toFixed(1) || 0)
    }))
        .sort((a, b) => new Date(b.fecha) - new Date(a.fecha))
        .slice(0, 3);

    const handleStartExam = () => {
        navigate('/new-exam');
    };


    return (
        <div className={classes.root}>
            <Box className={classes.contentBox}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '24px',
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px'
                    }}>
                        <Typography style={{
                            color: '#000000',
                            fontSize: '24px',
                            fontStyle: 'normal',
                            fontFamily: 'Fira Sans',
                            fontWeight: "bold",
                            letterSpacing: '-0.225px',
                        }}>
                            Actividad Reciente
                        </Typography>
                        <Typography style={{
                            color: '#666666',
                            fontSize: '14px',
                            fontFamily: 'Fira Sans',
                        }}>
                            Sigue tu progreso y consigue tus objetivos de estudio
                        </Typography>
                    </Box>
                    <Box sx={{ width: '33.33%' }}>
                        <Objetivos
                            token={token}
                            user={user}
                            allPointsData={activityLevels}
                            userData={userData}
                            todayExams={todayExams}
                        />
                    </Box>
                </div>


                <Grid container spacing={3}>
                    {/* Left column - Task Cards */}
                    <Grid item xs={12} md={8}>
                        <Box sx={{ width: '100%' }}>
                            {tareas.length > 0 ? (
                                <Grid container spacing={2}>
                                    {tareas.map((tarea, index) => (
                                        <Grid item xs={12} sm={6} md={4} key={index}>
                                            <Box sx={{
                                                padding: '24px',
                                                backgroundColor: '#FFFFFF',
                                                borderRadius: '8px',
                                                border: '1px solid #E7E7E7',
                                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between'
                                            }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    marginBottom: '16px'
                                                }}>
                                                    <Typography style={{
                                                        fontSize: '15px',
                                                        fontWeight: '500',
                                                        color: '#333333',
                                                        fontFamily: 'Fira Sans',
                                                        width: '100%',
                                                        wordBreak: 'break-word'
                                                    }}>
                                                        {tarea.nombre}
                                                    </Typography>
                                                </Box>
                                                <Typography style={{
                                                    fontSize: '15px',
                                                    color: '#666',
                                                    fontFamily: 'Fira Sans',
                                                    marginBottom: '12px'
                                                }}>
                                                    {tarea.tipo}
                                                </Typography>
                                                <Box sx={{ position: 'relative', height: '6px', backgroundColor: '#F0F0F0', borderRadius: '3px' }}>
                                                    <Box sx={{
                                                        position: 'absolute',
                                                        left: 0,
                                                        top: 0,
                                                        height: '100%',
                                                        width: `${tarea.progreso}%`,
                                                        backgroundColor: '#2196F3',
                                                        borderRadius: '3px'
                                                    }} />
                                                </Box>
                                                <Typography style={{
                                                    fontSize: '13px',
                                                    color: '#666',
                                                    fontFamily: 'Fira Sans',
                                                    textAlign: 'right',
                                                    marginTop: '8px'
                                                }}>
                                                    {tarea.progreso}%
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : (
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '40px 20px',
                                    backgroundColor: '#FAFBFC',
                                    borderRadius: '8px',
                                    border: '1px solid #E7E7E7',
                                }}>
                                    <Typography style={{
                                        fontSize: '18px',
                                        color: '#666',
                                        fontFamily: 'Fira Sans',
                                        marginBottom: '20px',
                                        textAlign: 'center'
                                    }}>
                                        Aún no has hecho ningún examen
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleStartExam}
                                        sx={{
                                            textTransform: 'none',
                                            fontFamily: 'Fira Sans',
                                            padding: '8px 24px',
                                            borderRadius: '8px',
                                            backgroundColor: '#6BBDBD',
                                            color: '#fff',
                                            '&:hover': {
                                                textTransform: 'none',
                                            fontFamily: 'Fira Sans',
                                            padding: '8px 24px',
                                            borderRadius: '8px',
                                            backgroundColor: '#6BBDBD',
                                            color: '#fff',
                                            }
                                        }}
                                    >
                                        Empieza ahora
                                    </Button>
                                </Box>
                            )}


                            <Box className={classes.statsContainer}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '8px',
                                        marginBottom: '4px'
                                    }}>
                                        <Typography style={{
                                            fontSize: '24px',
                                            fontWeight: 'bold',
                                            color: '#333333',
                                            fontFamily: 'Fira Sans',
                                        }}>
                                            {streak}
                                        </Typography>
                                        <Flame size={24} color="#FF6B6B" />
                                    </Box>
                                    <Typography style={{
                                        fontSize: '12px',
                                        color: '#666',
                                        fontFamily: 'Fira Sans'
                                    }}>
                                        Días seguidos estudiando
                                    </Typography>
                                </Box>

                                <Box sx={{ textAlign: 'center' }}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '8px',
                                        marginBottom: '4px'
                                    }}>
                                        <Typography style={{
                                            fontSize: '24px',
                                            fontWeight: 'bold',
                                            color: '#333333',
                                            fontFamily: 'Fira Sans',
                                        }}>
                                            {statsData?.correct_answers || 0}
                                        </Typography>
                                        <CheckCircle size={24} color="#4CAF50" />
                                    </Box>
                                    <Typography style={{
                                        fontSize: '12px',
                                        color: '#666',
                                        fontFamily: 'Fira Sans'
                                    }}>
                                        Preguntas Correctas
                                    </Typography>
                                </Box>

                                <Box sx={{ textAlign: 'center' }}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '8px',
                                        marginBottom: '4px'
                                    }}>
                                        <Typography style={{
                                            fontSize: '24px',
                                            fontWeight: 'bold',
                                            color: '#333333',
                                            fontFamily: 'Fira Sans',
                                        }}>
                                            {statsData?.total_exams || 0}
                                        </Typography>
                                        <FileText size={24} color="#2196F3" />
                                    </Box>
                                    <Typography style={{
                                        fontSize: '12px',
                                        color: '#666',
                                        fontFamily: 'Fira Sans'
                                    }}>
                                        Exámenes
                                    </Typography>
                                </Box>

                                <Box sx={{ textAlign: 'center' }}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '8px',
                                        marginBottom: '4px'
                                    }}>
                                        <Typography style={{
                                            fontSize: '24px',
                                            fontWeight: 'bold',
                                            color: '#333333',
                                            fontFamily: 'Fira Sans',
                                        }}>
                                            {statsData?.flashcards_memorized || 0}
                                        </Typography>
                                        <Brain size={24} color="#9C27B0" />
                                    </Box>
                                    <Typography style={{
                                        fontSize: '12px',
                                        color: '#666',
                                        fontFamily: 'Fira Sans'
                                    }}>
                                        Tarjetas memorizadas
                                    </Typography>
                                </Box>
                            </Box>

                        </Box>
                    </Grid>

                    {/* Right column - Calendar Heatmap */}
                    <Grid item xs={12} md={4}>
                        <CalendarHeatmap activityLevels={activityLevels} />
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

export default ActividadReciente;
