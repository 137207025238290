import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, TextField, InputAdornment, CircularProgress, Button, Skeleton, Tabs, Tab, Select, MenuItem, FormControl } from '@mui/material';
import { Subtitle } from './useStyleNewExam';
import DocumentStatsCard from '../Documents/DocumentStatsCard';
import SearchIcon from '@mui/icons-material/Search';

const SelectDocument = ({
  listDocuments,
  isLoading,
  selectedDocument,
  selectedDocuments,
  onDocumentSelect,
  setSelectedDocuments,
  isPremium
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredDocuments, setFilteredDocuments] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState('all');

  // Get unique folders from documents
  const getFolders = () => {
    if (!listDocuments) return ['all'];
    const folders = [...new Set(listDocuments.map(doc => {
      return doc.folder || 'uncategorized'
    }))];
    return ['all', ...folders];
  };

  useEffect(() => {
    if (listDocuments && listDocuments.length > 0 && !selectedDocument) {
      onDocumentSelect(listDocuments[0]);
    }
  }, [listDocuments, selectedDocument, onDocumentSelect]);

  useEffect(() => {
    if (!listDocuments) {
      setFilteredDocuments(null);
      return;
    }

    let filtered = listDocuments;

    // First filter by folder
    if (selectedFolder !== 'all') {
      filtered = filtered.filter(doc =>
        (doc.folder || 'Sin carpeta') === selectedFolder
      );
    }

    // Then filter by search query
    if (searchQuery) {
      filtered = filtered.filter(doc =>
        doc.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredDocuments(filtered);
  }, [searchQuery, listDocuments, selectedFolder]);

  const handleFolderChange = (event, newValue) => {
    setSelectedFolder(newValue);
  };

  const handleDocumentSelect = (document) => {
    if (!isPremium) {
      // For non-premium users, always replace the selection with the new document
      setSelectedDocuments([document]);
      return;
    }

    // For premium users, allow multiple selection
    if (selectedDocuments.some(doc => doc.guid === document.guid)) {
      setSelectedDocuments(prevDocs =>
        prevDocs.filter(doc => doc.guid !== document.guid)
      );
    } else {
      if (selectedDocuments.length >= 15) return;
      const documentWithStats = {
        ...document,
        stats: {
          status: {
            memorized: document.status?.memorized || 0,
            learning: document.status?.learning || 0,
            notKnown: document.status?.notKnown || 0,
            notStarted: document.status?.notStarted || 0
          }
        }
      };
      setSelectedDocuments(prevDocs => [...prevDocs, documentWithStats]);
    }
  };

  const renderContent = () => {
    if (filteredDocuments === null) {
      return (
        <Box
          className="custom-scrollbar"
          sx={{
            pr: 2
          }}
        >
          <Grid container spacing={2}>
            {[1, 2, 3, 4, 5, 6].map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item}>
                <Box sx={{
                  bgcolor: 'white',
                  borderRadius: '12px',
                  p: 2,
                  height: '180px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1
                }}>
                  <Skeleton variant="text" width="70%" height={24} />
                  <Skeleton variant="text" width="40%" height={20} />
                  <Box sx={{ mt: 2 }}>
                    <Skeleton variant="text" width="60%" height={16} />
                    <Skeleton variant="text" width="40%" height={16} />
                    <Skeleton variant="text" width="50%" height={16} />
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      );
    }

    if (filteredDocuments.length === 0) {
      return (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '24px',
          minHeight: '200px'
        }}>
          <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/Vacio.svg" alt="No documents" width="100" height="100" />
          <Typography sx={{ mt: 1, color: '#666', fontSize: '14px' }}>
            No hay un documento con este nombre
          </Typography>
        </Box>
      );
    }

    if (!listDocuments || listDocuments.length === 0) {
      return (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '24px',
          minHeight: '200px'
        }}>
          <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/Vacio.svg" alt="No documents" width="100" height="100" />
          <Typography sx={{ mt: 1, color: '#666', fontSize: '14px' }}>
            Debes generar preguntas para hacer un test
          </Typography>
        </Box>
      );
    }

    return (
      <Box
        className="custom-scrollbar"
        sx={{
          pr: 2
        }}
      >
        <Grid container spacing={2}>
          {filteredDocuments.map((document) => (
            <Grid item xs={12} sm={6} md={4} key={document.guid}>
              <DocumentStatsCard
                document={document}
                selected={selectedDocuments.some(doc => doc.guid === document.guid)}
                onClick={handleDocumentSelect}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };


  const selectedDocumentsSection = selectedDocuments.length > 0 && (
    <Box sx={{ mb: 2 }}>
      <Typography sx={{ mb: 1, color: '#666' }}>
        Documentos seleccionados ({selectedDocuments.length}/15):
      </Typography>
      <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
        {selectedDocuments.map((doc) => (
          <Box
            key={doc.guid}
            sx={{
              backgroundColor: '#E2F1F1',
              borderRadius: '8px',
              padding: '6px 12px',
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            <Typography sx={{ fontSize: '14px' }}>{doc.title}</Typography>
            <Button
              size="small"
              onClick={() => handleDocumentSelect(doc)}
              sx={{
                minWidth: 'auto',
                p: '2px',
                color: '#666',
                '&:hover': { backgroundColor: 'rgba(0,0,0,0.1)' }
              }}
            >
              ×
            </Button>
          </Box>
        ))}

      </Box>
    </Box>
  );


  return (
    <Box sx={{ marginBottom: '40px' }}>
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        {/* Folder Select */}
        <FormControl sx={{ minWidth: 200 }}>
          <Select
            value={selectedFolder}
            onChange={(event) => setSelectedFolder(event.target.value)}
            sx={{
              backgroundColor: 'white',
              height: '40px',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#E7E7E7',
                borderRadius: '12px',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#6BBDBD',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#6BBDBD',
              },
              '& .MuiSelect-select': {
                color: '#666',
              }
            }}
          >
            {getFolders().map((folder) => (
              <MenuItem key={folder} value={folder}>
                {folder === 'all' ? 'Todas las carpetas' :
                  (listDocuments.find(doc => doc.folder === folder)?.folder_name || 'Sin carpeta')}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Search Bar */}

        <TextField
          fullWidth
          placeholder="Buscar documentos..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{
            backgroundColor: 'white',
            '& .MuiOutlinedInput-root': {
              height: '40px',
              '& fieldset': {
                borderColor: '#E7E7E7',
                borderRadius: '12px',
              },
              '&:hover fieldset': {
                borderColor: '#6BBDBD',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#6BBDBD',
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ color: '#666' }} />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {selectedDocumentsSection}

      {renderContent()}
    </Box>
  );
};

export default SelectDocument;
