import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import UploadModal from './ModalUpload'; // Stage 1 Component
import StageTwoContent from './ModalQuestions'; // Stage 2 Component, adjust with your actual component
import ChapterSelectionComponent from './ModalChapters'; // Stage 2 Component, adjust with your actual component
import StageThreeContent from './ModalNumberQuest'; // Stage 3 Component, adjust with your actual component
import { makeStyles } from '@mui/styles';
import { useSearchParams, useParams } from "react-router-dom";
import { fetchFolderExams } from '../Api/ExamsApi';
import { t } from 'i18next';

const useStyles = makeStyles((theme) => ({
    dialogWrapper: {
        // Define the modal size here to keep it consistent
        width: '400px',
    },
    titleModal: {
        color: "var(--slate-900, #0F172A) !important",
        fontStyle: "normal !important",
        fontSize: "30px !important",
        fontWeight: "700 !important",
        paddingBottom: 0,
        '& .MuiTypography-root': {
            color: "var(--slate-900, #0F172A) !important",
            fontStyle: "normal !important",
            fontSize: "30px !important",
            fontWeight: "700 !important",
            paddingBottom: 0
        }
    }
}));

function MultiStageModal({ open, onClose, listDocuments, token, user, addNewExam, setShowUpgrade, setMessage, setShowAlert }) {
    const [currentStage, setCurrentStage] = useState(1);
    const classes = useStyles();
    const [files, setFiles] = useState([]);
    const [stageOneSelection, setStageOneSelection] = useState('');
    const [stageTwoSelection, setStageTwoSelection] = useState('');
    const [stageThreeSelection, setStageThreeSelection] = useState('');
    const { folder_id, selectedFolder } = useParams();
    const { document_id, selectedDocument } = useParams();
    const { chapter_id, selectedChapter } = useParams();
    const [includeChapterSelection, setIncludeChapterSelection] = useState(false);
    const [stageChapterSelection, setStageChapterSelection] = useState("");

    const canProceedToNextStage = () => {
        //     // Add condition checks for each stage
        if (currentStage === 1) {
            if (files.length > 0 && !stageOneSelection) return true
            if (files.length > 0 && stageOneSelection) return false
            if (files.length == 0 && stageOneSelection) return true
            if (files.length == 0 && !stageOneSelection) return false

        }
        if (currentStage === 1.5 && !stageChapterSelection) return false;

        if (currentStage === 2 && !stageTwoSelection) return false;
        if (currentStage === 3 && !stageThreeSelection) return false;
        return true;
    };

    const handleNext = () => {
        if (!canProceedToNextStage()) {
            alert('Please make a selection to proceed.');

            return;
        }
        // Logic to determine if the chapter selection step should be included
        if (currentStage === 1 && !files.length && stageOneSelection) {
            setIncludeChapterSelection(true);
            setCurrentStage(1.5); // Move to the chapter selection step
            return;
        } else if (currentStage === 1.5 || (currentStage === 1 && files.length)) {
            setIncludeChapterSelection(false); // Proceed beyond chapter selection
            setCurrentStage(2); // Move to the chapter selection step
            return;

        }

        setCurrentStage((prevStage) => (prevStage === 1.5 ? 2 : prevStage + 1));
    };


    const handleBack = () => {
        if (currentStage === 1.5) {
            setCurrentStage((prevStage) => prevStage - 0.5);

        } else if (currentStage > 1) {
            setCurrentStage((prevStage) => prevStage - 1);
        } else {
            onClose();
        }
    };

    const handleFinalizeAndClose = async () => {
        try {
            await handlePostExam(files, stageOneSelection, stageTwoSelection, stageThreeSelection, stageChapterSelection);
            onClose(); // Only close the modal if the operation is successful
        } catch (error) {
            console.error("Failed to post exam:", error);
            // Here, you might want to handle errors, such as displaying an error message to the user.
        }
    };

    const handlePostExam = async (file, stageOneSelection, stageTwoSelection, stageThreeSelection, stageChapterSelection) => {
        const formData = new FormData();


        // Append document_id, folder_id, and type_exam to the formData
        if (stageOneSelection) {
            formData.append('type[Document]', stageOneSelection);
            formData.append('type[Chapter]', stageChapterSelection)
        }
        formData.append('type[Folder]', folder_id);
        formData.append('type[type_exam]', stageTwoSelection);

        // Append number of questions
        formData.append('number_questions', stageThreeSelection);

        // Append file data if available
        if (file && file.length > 0) {
            // Directly append the file and its metadata to formData
            formData.append('file', file[0].file);
            formData.append('file_size', file[0].size);
            formData.append('file_duration', 0); // Assuming duration is not applicable or 0

        } else {
            formData.append('type[type_exam]', stageTwoSelection);

        }
        setShowAlert(true)
        setMessage("Tu examen se está creando")
        let exam_creation = await fetchFolderExams(token, "POST", null, user, formData) // Folder id to null to trigger second apiurl
        if ("messages_error" in exam_creation) {
            if (exam_creation['messages_error']) {
                setShowUpgrade(false)
                setShowAlert(true)
                setMessage(exam_creation['reason'])
                return;

            }
            throw new Error("Error creating exam.");

        } else {
            if (addNewExam) {
                addNewExam(exam_creation);
            }
        }
    };


    const renderContent = () => {
        if (!listDocuments || listDocuments.length === 0) {
            return (
                <UploadModal
                    files={files}
                    setFiles={setFiles}
                    setSelectedOption={setStageOneSelection}
                    selectedOption={stageOneSelection}
                    listDocuments={listDocuments}
                />
            );
        }
        switch (currentStage) {
            case 1:
                return <UploadModal files={files} setFiles={setFiles} setSelectedOption={setStageOneSelection} selectedOption={stageOneSelection} listDocuments={listDocuments} />;
            case 1.5: // Assuming currentStage can be a float to represent the in-between state
                return includeChapterSelection ? <ChapterSelectionComponent document_id={stageOneSelection} setSelectedOption={setStageChapterSelection} selectedOption={stageChapterSelection} token={token} user={user} /> : null;

            case 2:
                return <StageTwoContent setSelectedOption={setStageTwoSelection} selectedOption={stageTwoSelection} />;
            case 3:
                return <StageThreeContent setSelectedOption={setStageThreeSelection} selectedOption={stageThreeSelection} />;
            default:
                return null;
        }
    };

    return (
        <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialogWrapper }}>
            <DialogTitle
                className={classes.titleModal}
            >
                {t("create_exam")}
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={onClose}
                    aria-label="close"
                    style={{ position: 'absolute', top: 8, right: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent >
                {renderContent()}
            </DialogContent>
            <DialogActions>
                {currentStage > 1 && (
                    <Button onClick={handleBack} color="primary" style={{
                        backgroundColor: "#026277",
                        color: "white",
                        padding: "10px 20px",
                        borderRadius: "8px",
                        fontSize: "14px",
                        cursor: "default",
                        textTransform: "none"
                    }}>
                        {t("back")}
                    </Button>
                )}
                {currentStage < 3 && listDocuments && listDocuments.length > 0 && (
                    <Button onClick={handleNext} color="primary" style={{
                        backgroundColor: "#026277",
                        color: "white",
                        padding: "10px 20px",
                        borderRadius: "8px",
                        fontSize: "14px",
                        cursor: "default",
                        textTransform: "none"
                    }}>
                        {t("next")}

                    </Button>
                )}
                {currentStage === 3 && (
                    <Button onClick={handleFinalizeAndClose} color="primary" style={{
                        backgroundColor: "#026277",
                        color: "white",
                        padding: "10px 20px",
                        borderRadius: "8px",
                        fontSize: "14px",
                        cursor: "default",
                        textTransform: "none"
                    }}>
                        {t("create")}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default MultiStageModal;
