import { makeStyles } from '@mui/styles';

const useStylesGettingStarted = makeStyles({
  containerStarted: {
    padding: 24,
    backgroundColor: 'white',
    border: '1px solid #E2F1F1',
    borderRadius: '20px',
  },
  titleStarted: {
    marginBottom: 16,
    fontWeight: "bold",
    color: '#6BBDBD',

  },
  actionItemStarted: {
    padding: 16,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },

  actionTextStarted: {
    paddingLeft: 24,
  },
  actionTitleStarted: {
    fontWeight: 'bold',
    fontSize: '16px',
    color: '#333',
  },
  actionSubtextStarted: {
    fontSize: '14px',
    fontWeight: 300,
    color: 'rgba(0, 0, 0, 0.6)',
  },
});

export default useStylesGettingStarted;
