import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import * as Sentry from "@sentry/react";
import { createClient } from '@supabase/supabase-js'
import { SessionContextProvider } from '@supabase/auth-helpers-react'

import reportWebVitals from './reportWebVitals';

// Initialize Supabase client
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY
const supabase = createClient(supabaseUrl, supabaseAnonKey)

// import * as Sentry from "@sentry/react";

// Sentry.init({
//     dsn: "https://a0e0c40a2eae4ee7922ef8b10d91c812@o4504966200492032.ingest.sentry.io/4504966206980096",
//     integrations: [new Sentry.BrowserTracing()],
//     tracesSampleRate: 1.0,
//   });


// Sentry.init({
//     dsn: "https://a0a6dc6273e0e54b17de25011cfe355f@o4507616975847424.ingest.de.sentry.io/4508544815398992",
//     integrations: [
//         Sentry.browserTracingIntegration(),
//         Sentry.replayIntegration(),
//     ],
//     // Tracing
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ["localhost", "app.typedai.com", "dev-app.typedai.com", /^https:\/\/yourserver\.io\/api/],
//     // Session Replay
//     replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });
const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
    // shouldRenderApp ? (

    <SessionContextProvider supabaseClient={supabase}>
        <App />
    </SessionContextProvider>


);


