import React from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  Card,
  CardContent,
  Grid
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import QuizIcon from '@mui/icons-material/Quiz';
import TimerIcon from '@mui/icons-material/Timer';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: 12,
      padding: theme.spacing(2),
      maxWidth: '600px',
      width: '100%'
    }
  },
  card: {
    height: '100%',
    cursor: 'pointer',
    borderRadius: 12,
    border: '1px solid #e0e0e0',
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'translateY(-4px)',
      border: '1px solid #6BBDBD'
    }
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(3)
  },
  icon: {
    fontSize: 48,
    color: '#6BBDBD',
    marginBottom: theme.spacing(2)
  },
  title: {
    fontFamily: 'Fira Sans',
    fontWeight: 600,
    fontSize: '18px',
    marginBottom: theme.spacing(1)
  },
  subtitle: {
    fontFamily: 'Fira Sans',
    color: '#666',
    fontSize: '14px'
  },
  modalTitle: {
    fontFamily: 'Fira Sans',
    fontWeight: 600,
    fontSize: '24px',
    marginBottom: theme.spacing(3),
    textAlign: 'center'
  }
}));

const TestOptionsModal = ({ open, onClose, document_id=null }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.dialog}
      maxWidth="md"
    >
      <DialogContent>
        <Typography className={classes.modalTitle}>
          Selecciona el tipo de test
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Card 
              className={classes.card} 
              onClick={() => {
                console.log("this is my document_id", document_id)
                if (document_id) {
                  console.log("Adding document_id to the url")
                  navigate(`/new-exam?document=${document_id}`);
                } else {
                  navigate('/new-exam');
                }
                onClose();
              }}
            >
              <CardContent className={classes.cardContent}>
                <QuizIcon className={classes.icon} />
                <Typography className={classes.title}>
                  Practicar
                </Typography>
                <Typography className={classes.subtitle}>
                  Practica con preguntas aleatorias de tus documentos
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card 
              className={classes.card}
              onClick={() => {
                navigate('/simulacro-config');
                onClose();
              }}
            >
              <CardContent className={classes.cardContent}>
                <TimerIcon className={classes.icon} />
                <Typography className={classes.title}>
                  Hacer Simulacro
                </Typography>
                <Typography className={classes.subtitle}>
                  Realiza un examen completo con tiempo limitado
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default TestOptionsModal; 