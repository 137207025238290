import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const HomePopupOnboarding = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          p: 2,
        },
      }}
    >
      <DialogTitle sx={{ p: 0, mb: 1 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" sx={{ fontFamily: 'Fira Sans' }}>¡Bienvenido a Typed!</Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: 'grey.500',
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <Typography 
          variant="body1" 
          color="text.secondary" 
          gutterBottom 
          sx={{ fontFamily: 'Fira Sans' }}
        >
          Mira este breve vídeo para entender como funciona Typed.
        </Typography>
        
        <Box sx={{ 
          width: '100%', 
          borderRadius: 1, 
          overflow: 'hidden',
          my: 2 
        }}>
          <div style={{
            position: 'relative',
            paddingBottom: '62.5%',
            height: 0
          }}>
            <iframe 
              src="https://www.loom.com/embed/66704c08bcdf49cd9d2e29d4f7deb459?sid=b15b1664-0248-4755-b10e-81c603df2177"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
              }}
            />
          </div>
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: 0 }}>
        <Button
          onClick={onClose}
          variant="contained"
          sx={{
            color: 'white',
            boxShadow: 'none',
            textTransform: 'none',
            fontFamily: 'Fira Sans',
            fontWeight: 'bold',
            borderRadius: '20px',
            bgcolor: '#6BBDBD',
            '&:hover': {
              bgcolor: '#6BBDBD',
            },
          }}
        >
          Ir a Typed
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HomePopupOnboarding;
