import { getCsrfToken } from './TokenApi'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export async function fetchFlashcard(token, document_id, user, method = 'GET') {
    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/flashcards/${document_id}/?user_id=${user_id}`;
    try {
        const csrfToken = method !== "GET" ? getCsrfToken() : null; // Get CSRF token for non-GET requests

        const headers = {
            'Authorization': `Bearer ${token}`,
        };

        if (method === 'POST') {
            headers['Content-Type'] = 'application/json';
        }
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: method,
            headers: headers,
            ...(method === 'POST' && { body: JSON.stringify({}) })
        };

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error(`Error ${method === 'POST' ? 'generating' : 'fetching'} flashcard:`, error);
        throw error;
    }
}

export async function createSetFlashcards(token, document_id, flashcardData, user) {

    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/create-set-flashcards/${document_id}/?user_id=${user_id}`;
    try {
        const csrfToken = getCsrfToken();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };

        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(flashcardData),
        };

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error creating flashcard:', error);
        throw error;
    }
}

export async function createFlashcards(token, document_id, flashcardData, user) {
    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/flashcards/${document_id}/?user_id=${user_id}`;
    try {
        const csrfToken = getCsrfToken();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };

        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(flashcardData),
        };

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error creating flashcard:', error);
        throw error;
    }
}

export async function fetchFlashcardsFolders(token, user) {
    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/flashcards-folders/?user_id=${user_id}`;
    try {
        const headers = {
            'Authorization': `Bearer ${token}`,
        };

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching flashcard stats:', error);
        throw error;
    }
}


export async function fetchFlashcardsTasks(token, user) {
    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/tasks_flashcards/?user_id=${user_id}`;
    try {
        const headers = {
            'Authorization': `Bearer ${token}`,
        };

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching flashcard stats:', error);
        throw error;
    }
}

export async function updateFlashcardAppearances(token, document_id, flashcardUpdates, user) {
    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/flashcards/${document_id}/?user_id=${user_id}`;
    try {
        const csrfToken = getCsrfToken();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };

        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(flashcardUpdates)
        };

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error updating flashcard appearances:', error);
        throw error;
    }
}

export async function updateSetFlashcardCompletion(token, setFlashcardId, timeSpent, user) {
    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/set-flashcards/${setFlashcardId}/?user_id=${user_id}`;
    try {
        const csrfToken = getCsrfToken();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };

        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify({
                is_completed: true,
                time_seconds: timeSpent
            })
        };

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error updating set flashcard completion:', error);
        throw error;
    }
}

export async function deleteFlashcard(token, flashcardGuid, user) {
    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/flashcard-delete/${flashcardGuid}/?user_id=${user_id}`;
    try {
        const csrfToken = getCsrfToken();
        const headers = {
            'Authorization': `Bearer ${token}`,
        };

        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: 'DELETE',
            headers: headers,
        };

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error deleting flashcard:', error);
        throw error;
    }
}
