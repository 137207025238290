import React, { useEffect, createContext } from 'react';
import { useAuth } from "../Auth/Authhandle";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const REACT_FRONT_END = process.env.REACT_APP_FRONT_END;


export const ActiveSubscriptionContext = createContext();

const ActiveSubscriptionProvider = ({ children, location }) => {

    const { token, user, isAuthenticated } = useAuth(); // Use the hook at the top level

    useEffect(() => {
        const fetchData = async () => {
            try {

                if (token && user) {
                    const isEmailVerified = user && user.email_verified;
                    if (isEmailVerified) {
                        const requestOptions = {
                            method: "GET",
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                            },
                        };


                        const response = await fetch(`${BACKEND_URL}/api_v1/active_subscription/?user_id=${user['id']}`, requestOptions);

                        if (response.status === 401) {
                            // Redirect to Google if status is 400
                            window.location.href = `https://${REACT_FRONT_END}/innactive`;
                        }
                        if (response.status === 404) {
                            window.location.href = `https://${REACT_FRONT_END}/login`;

                        }
                        if (response.status === 403) {
                            console.log("User doesnt have a subscription")
                            window.location.href = `https://${REACT_FRONT_END}/login`;

                        }

                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }

        };
        if (window.location.pathname !== '/innactive' && window.location.pathname != '/login' && window.location.pathname != '/verify') {
            fetchData();
        }

    }, [token, user, window.location]);

    return <ActiveSubscriptionContext.Provider value={{}}>{children}</ActiveSubscriptionContext.Provider>;
};

export default ActiveSubscriptionProvider;
