import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, List, ListItem, ListItemIcon, ListItemText, useTheme, Grid } from '@mui/material';
import useStylePremium from './useStylePremium';

const Premium = ({ onClose, titleText }) => {
  const classes = useStylePremium();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleSubscribe = () => {
    onClose(); // Close the popup
    navigate('/plans'); // Navigate to the PlanSelection screen
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const premiumFeatures = [
    'Tarjetas de memoria para estudiar',
    'Todas las preguntas desbloqueadas',
    'Sube más de 20 documentos',
    'Tests de más de un documento',
    'Generación de preguntas extras',
    '200 páginas por documento',
    'Créditos ilimitados',
    'Descarga resúmenes',
    'Y mucho más'
  ];

  const leftFeatures = premiumFeatures.slice(0, Math.ceil(premiumFeatures.length / 2));
  const rightFeatures = premiumFeatures.slice(Math.ceil(premiumFeatures.length / 2));

  return (
    <Box
      className={classes.popupOverlay}
      onClick={handleOverlayClick}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1300
      }}
    >
      <Box
        className={classes.popupContainer}
        onClick={(e) => e.stopPropagation()}
        sx={{
          backgroundColor: 'white',
          padding: { xs: '24px', sm: '32px' },
          borderRadius: '35px',
          maxWidth: { xs: '90%', sm: '600px' },
          margin: 'auto',
          overflow: 'auto'
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontSize: '1.5rem',
            fontWeight: '900',
            textAlign: 'center',
            fontFamily: '"Fira Sans", sans-serif',
            color: '#6BBDBD',
            mb: 1
          }}
        >
          Funcionalidad Premium 
        </Typography>
        
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: '1rem',
            textAlign: 'center',
            fontFamily: '"Fira Sans", sans-serif',
            color: '#666',
            mb: 3
          }}
        >
          Desbloquea todo el potencial de Typed con nuestro plan Premium
        </Typography>

        <Box sx={{ mb: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
          <Typography
            sx={{
              fontSize: '2rem',
              fontWeight: 'bold',
              fontFamily: '"Fira Sans", sans-serif',
              color: '#6BBDBD',
            }}
          >
            €7.2<span style={{ fontSize: '1rem' }}>/mes</span>
          </Typography>
          <Typography
            sx={{
              fontSize: '0.9rem',
              fontWeight: 'bold',
              textAlign: 'center',
              backgroundColor: '#E2F1F1',
              color: '#6BBDBD',
              py: 1,
              px: 2,
              borderRadius: '20px',
              display: 'inline-block',
              fontFamily: '"Fira Sans", sans-serif',
            }}
          >
            Plan anual (ahorra un 40%)
          </Typography>
          <Typography
            sx={{
              fontSize: '0.85rem',
              color: '#666',
              fontFamily: '"Fira Sans", sans-serif',
            }}
          >
            También disponible mensual (€11.99) o semestral (€9.6)
          </Typography>
        </Box>

        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={12} sm={6}>
            <List>
              {leftFeatures.map((feature, index) => (
                <ListItem key={index} sx={{ py: 1 }}>
                  <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
                    <svg width="18" height="18" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.8119 0C5.53644 0 0.449219 4.47715 0.449219 10C0.449219 15.5228 5.53644 20 11.8119 20C18.0873 20 23.1745 15.5228 23.1745 10C23.1672 4.47982 18.0842 0.00642897 11.8119 0Z" fill="#E2F1F1" />
                      <path d="M18.37 6.83337L11.8886 14.5742C11.734 14.7547 11.5035 14.873 11.2489 14.9024C10.9943 14.9319 10.7369 14.8701 10.5346 14.7309L5.90618 11.4742C5.49775 11.1866 5.43162 10.662 5.75846 10.3025C6.08531 9.94309 6.68136 9.88489 7.08978 10.1725L10.9493 12.89L16.8456 5.84754C17.0389 5.5922 17.376 5.45103 17.7231 5.48007C18.0702 5.50912 18.3711 5.70368 18.5063 5.98652C18.6415 6.26935 18.5892 6.59469 18.37 6.83337Z" fill="#6BBDBD" />
                    </svg>
                  </ListItemIcon>
                  <ListItemText 
                    primary={feature}
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: '0.9rem',
                        fontFamily: '"Fira Sans", sans-serif'
                      }
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} sm={6}>
            <List>
              {rightFeatures.map((feature, index) => (
                <ListItem key={index} sx={{ py: 1 }}>
                  <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
                    <svg width="18" height="18" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.8119 0C5.53644 0 0.449219 4.47715 0.449219 10C0.449219 15.5228 5.53644 20 11.8119 20C18.0873 20 23.1745 15.5228 23.1745 10C23.1672 4.47982 18.0842 0.00642897 11.8119 0Z" fill="#E2F1F1" />
                      <path d="M18.37 6.83337L11.8886 14.5742C11.734 14.7547 11.5035 14.873 11.2489 14.9024C10.9943 14.9319 10.7369 14.8701 10.5346 14.7309L5.90618 11.4742C5.49775 11.1866 5.43162 10.662 5.75846 10.3025C6.08531 9.94309 6.68136 9.88489 7.08978 10.1725L10.9493 12.89L16.8456 5.84754C17.0389 5.5922 17.376 5.45103 17.7231 5.48007C18.0702 5.50912 18.3711 5.70368 18.5063 5.98652C18.6415 6.26935 18.5892 6.59469 18.37 6.83337Z" fill="#6BBDBD" />
                    </svg>
                  </ListItemIcon>
                  <ListItemText 
                    primary={feature}
                    sx={{
                      '& .MuiTypography-root': {
                        fontSize: '0.9rem',
                        fontFamily: '"Fira Sans", sans-serif'
                      }
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>

        <Button
          variant="contained"
          className={classes.subscribeButton}
          onClick={handleSubscribe}
          sx={{
            backgroundColor: '#6BBDBD',
            color: 'white',
            width: '100%',
            py: 1.5,
            borderRadius: '25px',
            textTransform: 'none',
            fontSize: '1.1rem',
            fontWeight: 'bold',
            fontFamily: '"Fira Sans", sans-serif',
            '&:hover': {
              backgroundColor: '#5aa9a9',
            }
          }}
        >
          Actualizar a Premium
        </Button>
      </Box>
    </Box>
  );
};

export default Premium;
