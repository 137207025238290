import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, Paper, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import UploadFilesWelcome from '../Welcome/UploadFileWelcome';
import Questionnaire from './Questionnaire';
import './style.css'; // Add this import at the top of the file
import '../Main/style.css'; // Add this import at the top of the file
import * as handle from "../AllModalsActions/handleModal";
import { useAuth } from '../Auth/Authhandle';
import { fetchUserFiles } from '../Api/FilesApi';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSubscription } from '../Context/SubscriptionContext';

// Styled components
const StyledFullScreenBanner = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('700')]: {
    display: "none"
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100vh',
  backgroundColor: "white",
  textAlign: 'center',
  position: 'relative',
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '22px',
  fontFamily: 'Inter',
  marginTop: "50px",
  marginBottom: "5px",
  color: '#6BBDBD',
}));

const StyledLeftColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(2),
  height: '100vh',
  [theme.breakpoints.down('700')]: {
    display: 'none',
  },
}));

const StyledStepsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
}));

const StyledStepIcon = styled(Box)(({ color }) => ({
  backgroundColor: color,
  borderRadius: '50%',
  padding: '8px',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  marginBottom: '16px',
}));

const StyledUploadButton = styled(Button)(({ theme, disabled }) => ({
  backgroundColor: disabled ? '#CCCCCC' : '#6BBDBD',
  color: 'white',
  width: '120px',
  fontWeight: 'bold',
  fontSize: "14px",
  textTransform: 'none',
  borderRadius: '20px',
  boxShadow: 'none',
  marginTop: '20px',
  '&:hover': {
    backgroundColor: disabled ? '#CCCCCC' : '#6BBDBD',
  },
}));

const NewOnboarding = () => {
  const [files, setFiles] = useState([]);
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const { user, token } = useAuth();
  const { userData } = useSubscription();
  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (userData.done_onboarding) {
      navigate("/")
    }
  }, [userData, location]);

  const steps = [
    {
      icon: <InsertDriveFileIcon fontSize="small" />,
      title: 'Sube un documento a estudiar',
      description: 'Puedes subir cualquier PDF, incluso escaneados',
      color: '#FFBF60',
    },
    {
      icon: <AccessTimeIcon fontSize="small" />,
      title: 'Deja que Typed prepare el material',
      description: 'Elaboraremos preguntas tipo test y resúmenes basados en tu documento',
      color: '#5243C2',
    },
    {
      icon: <EditIcon fontSize="small" />,
      title: '¡Empieza a estudiar!',
      description: 'Empieza a responder preguntas, estudiar tu resumen o resolver tus dudas',
      color: '#6BBDBD',
    },
  ];

  // Add this function to check if files are empty
  const isFilesEmpty = () => {
    return files.length === 0;
  };
  const handleUploadSkip = async () => {
    setShowQuestionnaire(true);
  }
  // Add this new function
  const handleUpload = async () => {
    setShowQuestionnaire(true);

    const formData = new FormData();
    let filesToUpload;
    if (files && files.length > 0) {
      filesToUpload = files.map((fileItem) => fileItem.file);

      // Create the data object and append it as JSON string
    
      // Existing file metadata loading
      const loadMetadata = (file, index) => {
        return new Promise((resolve) => {
          formData.append(`file_${index}`, file);
          formData.append(`file_${index}_size`, file.size);
          formData.append(`file_${index}_duration`, 0);
          resolve();
        });
      };
      let language = "Español";
      let isGenerateQuestion = true;
      let isGenerateFlashcards = true;
      let numPreguntas = 40;
      const dataObject = {
        language,
        isGenerateQuestion,
        isGenerateFlashcards,
        numPreguntas
      };
      formData.append('data', JSON.stringify(dataObject));

      const metadataPromises = filesToUpload.map((file, index) => loadMetadata(file, index));
      await Promise.all(metadataPromises);
    }
    fetchUserFiles(token, "POST", "Nueva carpeta", formData, user);
  };

  // Add this function to handle file updates
  const handleSetFiles = (newFiles) => {
    // If there are any files, only keep the last one
    if (newFiles.length > 0) {
      setFiles([newFiles[newFiles.length - 1]]);
    } else {
      setFiles([]);
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://widget.senja.io/widget/3bd958a6-7beb-4d50-acc4-6e5730b43bd9/platform.js";
    script.async = true;
    script.type = "text/javascript";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      {/* <div className={classes.fullScreenBanner}>
        <div>

          <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/iconName.svg" alt="Typed Icon" className={classes.typedIconImg} />
        </div>
        <Typography className={classes.bannerText}>
          Typed solo está disponible en ordenadores

        </Typography>
        <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/PhoneImage.svg" alt="Typed only available on computers" className={classes.bannerImage} />
      </div> */}

      {isMobile ? (
        <Grid container sx={{ flexGrow: 1, height: '100vh', display: 'flex' }}>
          <Grid item xs={12} sx={{
            overflowY: 'auto',
            height: '100vh',
          }}>
            <Box p={2} sx={{ bgcolor: "#F8FFFF", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Box sx={{ textAlign: 'center', mb: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: "60px" }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Inter' }}>
                  Sube tu primer documento
                </Typography>
                <Typography variant="h5" sx={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "400", color: "#666666", marginTop: "10px", width: "80%" }}>
                  Genera preguntas test y resúmenes completos de cualquier documento con nuestra IA
                </Typography>
              </Box>

              {showQuestionnaire ? (
                <Paper sx={{
                  padding: 3,
                  width: '100%',
                  maxWidth: "700px",
                  borderRadius: "20px",
                  boxShadow: '0 0 10px rgba(107, 189, 189, 0.8)',
                  backgroundColor: 'white',
                  height: '380px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}>
                  <Questionnaire token={token} user={user} />
                </Paper>
              ) : (
                <Paper sx={{
                  padding: 3,
                  width: '100%',
                  maxWidth: "600px",
                  borderRadius: "20px",
                  boxShadow: '0 0 10px rgba(107, 189, 189, 0.8)',
                  backgroundColor: 'white',
                  height: '380px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}>
                  <UploadFilesWelcome files={files} setFiles={handleSetFiles} is_multiple={false} is_onboarding={true} />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 'auto' }}>
                    <StyledUploadButton
                      variant="contained"
                      onClick={handleUpload}
                      disabled={files.length === 0}
                    >
                      ¡Continuar!
                    </StyledUploadButton>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 'auto', marginTop: "-20px" }}>
                    <Typography 
                      onClick={handleUploadSkip} 
                      sx={{ 
                        cursor: 'pointer', 
                        color: "gray", 
                        display: 'flex', 
                        alignItems: 'center', 
                        textDecoration: 'underline', 
                        fontFamily: "Inter", 
                        fontSize: "14px", 
                        fontWeight: "400" 
                      }}
                    >
                      Seguir sin subir un documento
                    </Typography>
                  </Box>
                </Paper>
              )}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container sx={{ flexGrow: 1, height: '100vh' }}>
          <Grid item xs={12} md={3}>
            <StyledLeftColumn>
              <Box sx={{ textAlign: 'center', mb: 4 }}>
                <StyledTitle variant="h4">
                  ¿Cómo funciona Typed?
                </StyledTitle>
              </Box>
              <StyledStepsContainer>
                {steps.map((step, index) => (
                  <Box key={index} sx={{ textAlign: 'center' }}>
                    <StyledStepIcon color={step.color}>
                      {step.icon}
                    </StyledStepIcon>
                    <Typography sx={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: '600', mb: 0.5 }}>
                      {step.title}
                    </Typography>
                    <Typography sx={{ fontFamily: 'Inter', fontSize: '14px', fontWeight: '400', color: '#666666', maxWidth: '80%', margin: '0 auto' }}>
                      {step.description}
                    </Typography>
                  </Box>
                ))}
              </StyledStepsContainer>
            </StyledLeftColumn>
          </Grid>

          <Grid item xs={12} md={9} sx={{ overflowY: 'auto', height: '100vh' }}>
            <Box p={2} sx={{ bgcolor: "#F8FFFF", height: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Box sx={{ textAlign: 'center', mb: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: "60px" }}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Inter' }}>
                  Sube tu primer documento
                </Typography>
                <Typography variant="h5" sx={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "400", color: "#666666", marginTop: "10px", width: "80%" }}>
                  Genera preguntas test y resúmenes completos de cualquier documento con nuestra IA
                </Typography>
              </Box>

              {showQuestionnaire ? (
                <Paper sx={{
                  padding: 3,
                  width: '100%',
                  maxWidth: "700px",
                  borderRadius: "20px",
                  boxShadow: '0 0 10px rgba(107, 189, 189, 0.8)',
                  backgroundColor: 'white',
                  height: '380px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}>
                  <Questionnaire token={token} user={user} />
                </Paper>
              ) : (
                <Paper sx={{
                  padding: 3,
                  width: '100%',
                  maxWidth: "600px",
                  borderRadius: "20px",
                  boxShadow: '0 0 10px rgba(107, 189, 189, 0.8)',
                  backgroundColor: 'white',
                  height: '380px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}>
                  <UploadFilesWelcome files={files} setFiles={handleSetFiles} is_multiple={false} is_onboarding={true} />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 'auto' }}>
                    <StyledUploadButton
                      variant="contained"
                      onClick={handleUpload}
                      disabled={files.length === 0}
                    >
                      ¡Continuar!
                    </StyledUploadButton>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 'auto', marginTop: "-20px" }}>
                    <Typography 
                      onClick={handleUploadSkip} 
                      sx={{ 
                        cursor: 'pointer', 
                        color: "gray", 
                        display: 'flex', 
                        alignItems: 'center', 
                        textDecoration: 'underline', 
                        fontFamily: "Inter", 
                        fontSize: "14px", 
                        fontWeight: "400" 
                      }}
                    >
                      Seguir sin subir un documento
                    </Typography>
                  </Box>
                </Paper>
              )}

              <Box sx={{ marginTop: "60px", width: "750px", height: "100px" }}>
                <div className="senja-embed" data-id="3bd958a6-7beb-4d50-acc4-6e5730b43bd9" data-mode="shadow" data-lazyload="false" sx={{ display: 'block' }}></div>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default NewOnboarding;
