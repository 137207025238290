import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  Typography,
  Box,
  Container,
  Paper,
  Divider,
  Grid
} from '@mui/material';

import { fetchUserFolders } from '../Api/FolderApi'; // Import the fetchUserInfo function
import { useAuth } from "../Auth/Authhandle";
import { Skeleton } from '@mui/lab'; // Import the Skeleton component
import { useTranslation } from 'react-i18next';
import SmallAlert from "../NewComponents/Modal/smallAlert";
import useHomeStyles from "./useHomeStyles"; // Adjust the import path as necessary
import NeedCredits from "../NoCreditsModal/NeedCredits";
import posthog from 'posthog-js'
import FolderList from "./FolderList";
import ModalParent from "../AllModalsActions/ParentModal";
import { useNavigation } from "../NavigationContext";
import ModalNotReady from "../NewComponents/ModalNotReady";
import { WebSocketContext } from '../WebSocketProvider';
import FullScreenLoader from "../NewComponents/LoadingExam";
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useSubscription } from "../Context/SubscriptionContext";
import Premium from "../PopUpsAlerts/Premium/Premium";
import { usePoints } from '../Context/PointsContext';
import { useLocation } from 'react-router-dom';


const REACT_FRONT_END = process.env.REACT_APP_FRONT_END;

const styles = {
  folder: {
    position: "relative",
    width: "33.33%",
    boxSizing: "border-box",
    padding: "10px",
    textAlign: "center",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
};

function Home() {
  const { t } = useTranslation();
  const classes = useHomeStyles();
  const location = useLocation(); // Hook to get the current location
  // Modal parameters
  const [modalOpen, setModalOpen] = useState(false);
  const { userData, fetchData } = useSubscription();

  const [titleOption, setTitle] = useState("");
  const [descriptionOption, setDescription] = useState("");
  const [buttonOption, setButton] = useState("");
  const [placeholderMessage, setPlaceholderMessage] = useState("");
  const [type, setType] = useState("");
  const [activateFunction, setActivateFunction] = useState(null);
  const [connectedCalendar, setConnectCalendar] = useState(false);
  const [folders, setFolders] = useState([]);
  const { token, user, isAuthenticated } = useAuth(); // Use the hook at the top level
  const [isLoading, setIsLoading] = useState(true); // Add this state
  const [listMeetings, setListMeetings] = useState(true); // Add this state
  const [transcripts, setTranscripts] = useState([]); // Add this state
  const [loading, setLoading] = useState(true); // Add this state
  const [isListView, setIsListView] = useState(false); // Add this state to manage the toggle
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [typeAlert, setTypeAlert] = useState("");
  const [linkinMessage, setLinkinMessage] = useState("");
  const [widthModal, setWidthModal] = useState("");
  const [disableClose, setDisableClose] = useState(false);
  const theme = useTheme();
  const { previousPath, currentPath } = useNavigation();

  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [showLimitPopup] = useState(true); // Set showLimitPopup to true by default

  async function fetchDataAndUpdate() {
    if (token && user) {
      fetchUserFolders(token, "GET", null, user).then(fetchFolders => {
        setFolders(fetchFolders);
        setIsLoading(false);
      }).catch(error => {
        console.error("Error fetching folders: ", error);
      });
    }
  }

  useEffect(() => {
    fetchDataAndUpdate(); // Set loading to false once data is fetched
  }, [token, user]);

  const handleFolderCreated = () => {
    fetchDataAndUpdate();
  };
  useEffect(() => {
    if (fetchData) {
      fetchData(); // Fetch the latest user data when the component mounts
    }
  }, [location.pathname]);
  const handleCreateExamClick = () => {
    setModalOpen(true);
    setTitle("📝 " + t("upload_exam_modal"));
    setDescription(t("what_to_study_option"));
    setButton(t("create"));
    setPlaceholderMessage("");
    setType("layout_exam");
    setWidthModal("800px");
  };

  const handleCreateFolderClick = () => {
    setModalOpen(true);
    setTitle(t('create_folder'));
    setDescription(t("organize_subjects"));
    setButton(t('create'));
    setPlaceholderMessage(t('create_folder_desc'));
    setType("carpeta");
    setWidthModal("350px");
  };

  const handleUploadFile = () => {
    setModalOpen(true);
    setTitle("📄 " + t("upload_file_sidebar"));
    setDescription(t("sube_material"));
    setButton(t("upload_button"));
    setPlaceholderMessage("");
    setType("upload");
    setWidthModal("600px");
  };

  const handleWelcome = () => {
    setTitle("👋 " + t("welcome_typed"));
    setDescription(t("intro_welcome"));
    setPlaceholderMessage("");
    setType("welcome_onboard");
    setDisableClose(true);

    setWidthModal("400px");
  };

  const [showSpinner, setShowSpinner] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]); // Move messages state up

  const [showAlert, setShowAlert] = useState(false);
  const [progressBar, setProgressBar] = useState(false);
  const [openNotReady, setOpenNotReady] = useState(false);
  const [folderSelectedGuid, setFolderSelectedGuid] = useState("");
  const [documentSelectedGuid, setDocumentSelectedGuid] = useState("");
  const [isPreparingExam, setIsPreparingExam] = useState(false);

  const [examRedirectId, setExamRedirectId] = useState("");
  const navigate = useNavigate();

  const handleClickOpenNotReady = () => {
    setOpenNotReady(true);
  };

  const handleCloseNotReady = () => {
    setOpenNotReady(false);
  };

  useEffect(() => {
    if (isPreparingExam) {
      const timer = setTimeout(() => {
        setIsPreparingExam(false);
      }, 4000);
      return () => clearTimeout(timer); // Cleanup the timer if the component unmounts or isPreparingExam changes
    }
  }, [isPreparingExam]);

  // Early return to show WelcomeOposicion

  const { messageCallback, setMessageCallback } = useContext(WebSocketContext);

  useEffect(() => {
    if (messageCallback) {
      switch (messageCallback.type) {

        case "Error":
          setShowSpinner(false);
          setShowAlert(false)
          break;
        case "popups_getting_started":
          fetchData()
          break;

        default:
          console.log("Unhandled message type:", messageCallback.document);
      }
      setMessageCallback(null);

    }
  }, [messageCallback]);

  const [showPremium, setShowPremium] = useState(false);
  const [premiumTitleText, setPremiumTitleText] = useState('');

  const handleOpenPremium = (titleText) => {
    setPremiumTitleText(titleText);
    setShowPremium(true);
  };

  const handleClosePremium = () => {
    setShowPremium(false);
  };

  const [showGettingStartedPopup, setShowGettingStartedPopup] = useState(true);

  const handleCloseGettingStartedPopup = () => {
    setShowGettingStartedPopup(false);
  };

  const [showPreguntasListas, setShowPreguntasListas] = useState(true);

  const handleShowPreguntasListas = () => {
    setShowPreguntasListas(true);
  };

  const handleClosePreguntasListas = () => {
    setShowPreguntasListas(false);
  };
  const { allPointsData, fetchAllPointsData } = usePoints();

  useEffect(() => {
    if (fetchAllPointsData) {
      fetchAllPointsData();

    } else {
    }
  }, [fetchAllPointsData]);

  return (
    <>

      {/* <PopupsGettingStarted 
        open={showGettingStartedPopup} 
        onClose={handleCloseGettingStartedPopup} 
        action = "upload"
      /> */}
      {showAlert &&
        <SmallAlert
          message={message}
          onClose={() => setShowAlert(false)}
          progressBar={true}
          linkinMessage={linkinMessage}
          typeAlert={typeAlert}
        />
      }
      {
        <NeedCredits open={showUpgrade} message={message}
          onClose={() => setShowUpgrade(false)} />
      }

      {modalOpen && (
        <ModalParent
          open={modalOpen}
          title={titleOption}
          description={descriptionOption}
          sendButton={buttonOption}
          placeholdMessage={placeholderMessage}
          setShowSpinner={setShowSpinner}
          setMessage={setMessage}
          setShowAlert={setShowAlert}
          type={type}
          onClose={() => {
            setModalOpen(false);
            fetchDataAndUpdate(); // Refresh data when modal closes
          }}
          callFunction={activateFunction}
          setProgressBar={setProgressBar}
          setShowUpgrade={setShowUpgrade}
          width={widthModal}
          onFolderCreated={handleFolderCreated}
          disableClose={disableClose}
          setIsPreparingExam={setIsPreparingExam}
          setFolderSelectedGuid={setFolderSelectedGuid}
          setDocumentSelectedGuid={setDocumentSelectedGuid}
        />
      )}

      {openNotReady && <ModalNotReady handleClose={handleCloseNotReady} />}

      {isPreparingExam && <FullScreenLoader
        imageUrl="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/ExamGettingReady.svg"
        title={t('fullScreenLoader.examPreparingTitle')}
        message={t('fullScreenLoader.examPreparingMessage')}
        buttonText={t('fullScreenLoader.examPreparingButton')}
        onClose={() => { setIsPreparingExam(false); }}
        onButtonClick={() => { setIsPreparingExam(false); if (folderSelectedGuid && documentSelectedGuid) { navigate(`/${folderSelectedGuid}/${documentSelectedGuid}`) } }}
      />}

      <Container style={{
        display: "flex",
        flexDirection: "row",
        gap: "50px",
        background: "#F9FFFF",
        maxWidth: "1000px",
        width: "100%",
        position: 'relative',
        zIndex: 1,
        overflowY: "hidden"
      }}>

        <Grid
          container
          spacing={0}
          className={classes.paperLeft}
          style={{ background: "transparent" }}
        >
          {/* Add spacing between grid items */}

          {<FolderList
            isLoading={isLoading}
            initialFolders={folders}
            handleCreateFolderClick={handleCreateFolderClick}
            fetchDataAndUpdate={fetchDataAndUpdate}
            token={token}
            user={user}
            transcripts={transcripts}
            handleUploadFile={handleUploadFile}
            handleUploadExam={handleCreateExamClick}
            handleClickOpenNotReady={handleClickOpenNotReady}
            userData={userData}
            allPointsData={allPointsData}
          />}



        </Grid>

        {showPremium && (
          <Premium
            onClose={handleClosePremium}
            titleText={premiumTitleText}
          />
        )}

      </Container>

    </>
  );
}

export default Home;
