import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import Spinner from "../NewComponents/Spinner";

export default function AuthCallback() {
    const navigate = useNavigate();
    const supabase = useSupabaseClient();
    const [error, setError] = useState(null);

    useEffect(() => {
        const handleAuthCallback = async () => {
            try {
                // Get the hash from the URL
                const hashParams = new URLSearchParams(
                    window.location.hash.substring(1) // remove the # symbol
                );

                const access_token = hashParams.get('access_token');
                const refresh_token = hashParams.get('refresh_token');
                
                if (!access_token) {
                    throw new Error('No access token found');
                }

                // Set the session with the tokens
                const { error: sessionError } = await supabase.auth.setSession({
                    access_token,
                    refresh_token
                });

                if (sessionError) {
                    throw sessionError;
                }

                // Get the current user to verify the session is working
                const { data: { user }, error: userError } = await supabase.auth.getUser();
                
                if (userError) {
                    throw userError;
                }

                if (user) {
                    // Successfully verified and logged in
                    navigate('/login-success');
                } else {
                    throw new Error('No user found after setting session');
                }
            } catch (error) {
                console.error('Auth callback error:', error);
                setError(error.message);
                // Redirect to login on error
                navigate('/login');
            }
        };

        handleAuthCallback();
    }, [navigate, supabase.auth]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    // Show loading spinner while processing
    return <Spinner />;
} 