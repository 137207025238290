import React from 'react';
import { Typography } from '@mui/material';

const Flashcards = ({ currentCard, state, studyMode, answeredBlanks }) => {
  const renderText = () => {
    const parts = currentCard.text.split(/\[(.*?)\]/);
    let clozeIndex = 0;

    // Find the first unanswered word position
    const findNextUnansweredPosition = () => {
      for (let blankIndex = 0; blankIndex < currentCard.answers.length; blankIndex++) {
        const answerWords = currentCard.answers[blankIndex];
        for (let wordIndex = 0; wordIndex < answerWords.length; wordIndex++) {
          if (!answeredBlanks[`${blankIndex}-${wordIndex}`]) {
            return { blankIndex, wordIndex };
          }
        }
      }
      return null;
    };

    const nextPosition = findNextUnansweredPosition();

    return parts.map((part, index) => {
      if (index % 2 === 1) {
        const isCurrentCloze = clozeIndex === currentCard.currentClozeIndex;
        const wasAnsweredCorrectly = currentCard.answeredCorrectly?.includes(clozeIndex);
        const shouldShowAnswer = wasAnsweredCorrectly || currentCard.revealed;
        const words = part.split(/\s+/);
        
        const result = (
          <Typography
            key={index}
            component="span"
            sx={{
              display: 'inline-flex',
              gap: '4px',
              alignItems: 'center'
            }}
          >
            {words.map((word, wordIndex) => {
              const isWordAnswered = answeredBlanks[`${clozeIndex}-${wordIndex}`];
              const isNextWord = nextPosition && nextPosition.blankIndex === clozeIndex && nextPosition.wordIndex === wordIndex;
              
              if (shouldShowAnswer || isWordAnswered) {
                return (
                  <Typography
                    key={wordIndex}
                    component="span"
                    sx={{
                      color: studyMode === 'flashcard' && currentCard.revealed ? '#00B564' :
                        (wasAnsweredCorrectly || isWordAnswered ? '#00B564' : '#FF4444'),
                      fontWeight: 'bold',
                      fontFamily: "'Inter', sans-serif",
                      fontSize: '16px',
                      backgroundColor: isWordAnswered ? '#E8F5E9' : 'transparent',
                      padding: '0 4px',
                      borderRadius: '4px'
                    }}
                  >
                    {word}
                  </Typography>
                );
              }
              return (
                <Typography
                  key={wordIndex}
                  component="span"
                  sx={{
                    backgroundColor: isNextWord ? '#E2F1F1' : '#F5F5F5',
                    padding: '0 8px',
                    borderRadius: '4px',
                    minWidth: `${word.length * 10}px`,
                    position: 'relative',
                    display: 'inline-block',
                    textAlign: 'left'
                  }}
                >
                  {state.answeredBlanks[`hint-${clozeIndex}-${wordIndex}`] || '_'.repeat(word.length)}
                </Typography>
              );
            })}
          </Typography>
        );

        clozeIndex++;
        return result;
      }
      return part;
    });
  };

  return (
    <Typography
      variant="h6"
      sx={{
        textAlign: 'center',
        fontFamily: "'Inter', sans-serif",
        fontSize: '18px',
        lineHeight: 1.6
      }}
    >
      {renderText()}
    </Typography>
  );
};

export default Flashcards;
