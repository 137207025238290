import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Typography,
  Box
} from '@mui/material';
import { Close } from '@mui/icons-material';

const CrearCard = ({ open, onClose, onSave }) => {
  const [newCard, setNewCard] = useState({ question: '', answer: '' });

  const hasSquareBrackets = (text) => /\[.*?\]/.test(text);
  const isFormValid = newCard.question.trim() !== '' && 
                     newCard.answer.trim() !== '' && 
                     hasSquareBrackets(newCard.answer);

  const handleSave = () => {
    if (isFormValid) {
      setNewCard({ question: newCard.question, answer: newCard.answer });
      onSave(newCard);
      onClose();
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '24px',
          width: '100%',
          maxWidth: '600px',
          m: 2,
          height: 'auto',
          maxHeight: '90vh',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <DialogTitle sx={{ 
        p: 2, 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center'
      }}>
        <Typography variant="h6" sx={{ 
          fontSize: '18px',
          fontWeight: 600,
          fontFamily: 'Fira Sans'
        }}>
          Crear tarjeta de memoria
        </Typography>
        <IconButton onClick={onClose} size="small">
          <Close fontSize="small" />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ 
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1
      }}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          marginTop: '10px',
          gap: 3,
          width: '100%'
        }}>
          <TextField
            fullWidth
            label="Pregunta"
            value={newCard.question}
            onChange={(e) => setNewCard({ ...newCard, question: e.target.value })}
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                fontFamily: 'Fira Sans',
              },
              '& .MuiInputLabel-root': {
                fontFamily: 'Fira Sans',
              }
            }}
          />
          <TextField
            fullWidth
            label="Respuesta"
            value={newCard.answer}
            onChange={(e) => setNewCard({ ...newCard, answer: e.target.value })}
            variant="outlined"
            multiline
            rows={4}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                fontFamily: 'Fira Sans',
              },
              '& .MuiInputLabel-root': {
                fontFamily: 'Fira Sans',
              }
            }}
          />
          <Typography
            variant="caption"
            sx={{
              color: '#666',
              fontFamily: 'Fira Sans',
              mt: -2
            }}
          >
            Para ocultar una palabra/s debes ponerlas entre []. Debe haber al menos una palabra oculta.
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions sx={{ paddingBottom: 2, paddingRight: 1, paddingLeft: 1 }}>
        <Button 
          onClick={onClose}
          sx={{
            color: '#666',
            textTransform: 'none',
            fontFamily: 'Fira Sans',
            '&:hover': {
              backgroundColor: 'transparent',
              color: '#333'
            }
          }}
        >
          Cancelar
        </Button>
        <Button 
          onClick={handleSave}
          variant="contained"
          disabled={!isFormValid}
          sx={{
            bgcolor: '#6BBDBD',
            color: 'white',
            textTransform: 'none',
            fontFamily: 'Fira Sans',
            borderRadius: '20px',
            px: 3,
            '&:hover': {
              bgcolor: '#5AACAC'
            },
            '&.Mui-disabled': {
              bgcolor: '#E0E0E0',
              color: '#A0A0A0'
            }
          }}
        >
          Crear
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CrearCard;
