import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { FormControl, InputLabel, MenuItem, Select, Typography, Button, Box, TextField } from '@mui/material';
import ShowOptionsInline from '../../../Welcome/ShowOptionsInline';
import { fetchUserFolders } from '../../../Api/FolderApi';
import { fetchUserFile, fetchUserFiles } from '../../../Api/FilesApi';
import { fetchFolderExams } from '../../../Api/ExamsApi';
import { ClipLoader } from "react-spinners";
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { useCredits } from '../../../Context/CreditsContext';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
        marginTop: "12px",
    },
    formControl: {
        minWidth: 120,
        width: '90%',
        marginBottom: "0px",
        marginTop: "10px",
    },
    title: {
        fontSize: '12px',
        fontWeight: '600',
        lineHeight: '26px'
    },
    menuItem: {
        fontSize: '10px',
        borderRadius: '8px',
    },
    select: {
        height: '30px',
        '& .MuiSelect-selectMenu': {
            fontSize: "10px"
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '8px',
        },
    },
    inputLabel: {
        fontSize: '10px',
        transform: 'translate(10px, 10px) scale(1)',
    },
    shrinkLabel: {
        transform: 'translate(10px, -6px) scale(0.75)',
    },
    buttonUpload: {
        backgroundColor: "#026277",
        color: "white",
        textTransform: "none",
        borderRadius: "6px",
        fontSize: "10px",
        display: "flex",
        alignItems: "flex-end",
        gap: "2px",
        width: "30px",
        padding: "6px 12px",
        '&:disabled': {
            backgroundColor: '#B0B0B0', // Gray color for the disabled state
            color: '#FFFFFF'           // White text for the disabled state
        },
        '&:hover': {
            backgroundColor: '#026277', // Gray color for the disabled state
            color: "white",


        }
    },
}));

const ModalLayoutExam = ({ onClose, token, user, onExamCreated, setCredits, setIsPreparingExam, currentCredits, setShowUpgrade, setFolderSelectedGuid, setDocumentSelectedGuid }) => {
    const classes = useStyles();
    const { folder_id, selectedFolderParam } = useParams();
    const { document_id, selectedDocumentParam } = useParams();
    const { credits } = useCredits()
    const [firstOption, setFirstOption] = useState('');
    const [secondOption, setSecondOption] = useState('');
    const [objectDocument, setObjectDocument] = useState({});
    const [selectedOption, setSelectedOption] = useState('');
    const [typeExamOption, setTypeExamOption] = useState('');
    const [difficultyExamOption, setDifficultyExamOption] = useState('difficult');
    const [listFolders, setListFolders] = useState([]);
    const [documents, setDocuments] = useState([]);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [requiredCredits, setRequiredCredits] = useState(40); // State to store required credits
    const [initPage, setInitPage] = useState(1); // State to store required credits
    const [finalPage, setFinalPage] = useState(20); // State to store required credits
    const [error, setError] = useState(false); // State to store required credits
    const [errorMessagePages, setErrorMessagePages] = useState(false); // State to store required credits
    const [errorPages, setErrorPages] = useState(false); // State to store required credits
    const [totalAllowedPages, setTotalAllowedPages] = useState(0); // State to store required credits


    const navigate = useNavigate();
    const fetchDocuments = async (folder_id) => {
        if (folder_id) {
            try {
                const fetchedFiles = await fetchUserFiles(token, "GET", folder_id, null, user);
                setDocuments(fetchedFiles);
            } catch (error) {
                console.error('Error fetching files:', error);
            }
        }
    };
    useEffect(() => {

        fetchDocuments(folder_id);
    }, [folder_id]);
    const handleStartPageChange = (event) => {
        setInitPage(event.target.value);
        validatePages(event.target.value, finalPage);
    };

    const handleEndPageChange = (event) => {
        setFinalPage(event.target.value);
        validatePages(initPage, event.target.value);
    };
    const validatePages = (start, end) => {
        const startPageNum = parseInt(start, 10);
        const endPageNum = parseInt(end, 10);

        if (isNaN(startPageNum) || isNaN(endPageNum)) {
            setErrorPages(true);
            setErrorMessagePages("Los números de página deben ser números válidos.");
        } else if (startPageNum === 0) {
            setErrorPages(true);
            setErrorMessagePages("El número de la página de inicio no puede ser 0.");
        } else if (startPageNum > endPageNum) {
            setErrorPages(true);
            setErrorMessagePages("El número de la página de inicio debe ser menor que el número de la página final.");
        } else if (endPageNum > totalAllowedPages) {
            setFinalPage(totalAllowedPages); // Adjust final page to total pages
            setErrorPages(false);
            setErrorMessagePages("");
        } else {
            setErrorPages(false);
            setErrorMessagePages("");
        }
    };

    useEffect(() => {
        if (document_id != "") {
            const fetchDocument = async () => {
                if (folder_id) {
                    try {
                        const response = await fetchUserFile(token, "GET", folder_id, document_id, null, user)
                        setObjectDocument(response);
                    } catch (error) {
                        console.error('Error fetching files:', error);
                    }
                }
            };

            fetchDocument()
        }
    }, [document_id]);
    const handleFirstChange = async (event) => {

        const selectedFolder = event.target.value;
        setFirstOption(selectedFolder);


        fetchDocuments(selectedFolder.guid)
    };
    // useEffect(() => {

    //     const hasInfoDoc = 'info_doc' in objectDocument;

    //     if (hasInfoDoc) {
    //         const hasPages = 'pages' in objectDocument.info_doc;

    //         if (hasPages) {
    //             let pagesTotal = finalPage - initPage;
    //             let creditsNeeded = Math.max(1, Math.round(pagesTotal * 0.375));
    //             setRequiredCredits(creditsNeeded); // Set required credits

    //             setCredits(creditsNeeded);
    //         }
    //     }

    // }, [objectDocument, finalPage, initPage]);

    useEffect(() => {

        const hasInfoDoc = 'info_doc' in objectDocument;

        if (hasInfoDoc) {
            const hasPages = 'pages' in objectDocument.info_doc;
            if (hasPages) {
                let pages = objectDocument.info_doc.pages;

                setTotalAllowedPages(pages)
                setFinalPage(pages)
                setCredits(40);
                setRequiredCredits(40); // Set required credits

            }
        }

    }, [objectDocument]);

    const handleSecondChange = (event) => {
        setSecondOption(event.target.value);
        setObjectDocument(event.target.value)
    };

    const handleOptionChange = (optionValue) => {
        setSelectedOption(optionValue);
    };
    const handleOptionFormatChange = (optionValue) => {
        setTypeExamOption(optionValue);
    };
    const handleOptionDifficultyChange = (optionValue) => {
        setDifficultyExamOption(optionValue);
    };

    const options = [
        { value: '10', label: `10 ${t("preguntas")}` },
        { value: '15', label: `15 ${t("preguntas")}` },
        { value: '20', label: `20 ${t("preguntas")}` },
    ];
    const optionsDificulty = [
        { value: 'easy', label: `${t("dificultad_facil")}` },
        { value: 'medium', label: `${t("dificultad_media")}` },
        { value: 'hard', label: `${t("dificultad_dificil")}` },
    ]

    const optionsFormat = [
        { value: 'bullet_points', label: `${t("multiple_choice")}` },
        { value: 'open_question', label: `${t("open_question")}` },
    ]

    useEffect(() => {
        const fetchFolders = async () => {
            try {
                const folders = await fetchUserFolders(token, "GET", null, user);
                setListFolders(folders);

            } catch (error) {
                console.error('Error fetching folders:', error);
            }
        };

        fetchFolders();
    }, [token, user]);
    const handleKeyPress = (event) => {
        // Allow only numbers
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
        }
    };
    const handleGenerateExam = async () => {
        // Function to execute asynchronous operations
        if (credits === -100 || credits >= requiredCredits) {

            setIsLoading(true); // Start loading

            const executeAsyncOperations = async () => {
                try {
                    setIsPreparingExam(true);
                    setFolderSelectedGuid(folder_id || firstOption.guid)
                    setDocumentSelectedGuid(document_id || secondOption.guid)

                    const formData = new FormData();
                    formData.append('type[type_exam]', typeExamOption);
                    formData.append('type[difficulty]', difficultyExamOption);
                    formData.append('type[Folder]', folder_id || firstOption.guid); // Conditionally append folder_id or firstOption.guid
                    formData.append('type[Document]', document_id || secondOption.guid); // Conditionally append document_id or secondOption.guid
                    formData.append('number_questions', selectedOption);
                    formData.append('init_page', initPage);
                    formData.append('final_page', finalPage);
                    let data = await fetchFolderExams(token, "POST", firstOption.guid, user, formData);

                    if (onExamCreated) {
                        onExamCreated(); // Invoke the callback to refresh the list of exams
                    }

                    // Commented out code to wait for the response and navigate
                    // let key_redirect = Object.keys(data)[0];
                    // navigate(`/exam/${key_redirect}`);
                    // console.log("RESPONSE", key_redirect);
                    setIsLoading(false)
                } catch (error) {
                    console.error('Error generating exam:', error);
                } finally {
                    setIsLoading(false); // End loading
                }
            }
            // Close the modal first
            onClose();

            // Execute the rest of the operations
            executeAsyncOperations();
        }
        else {
            setShowUpgrade(true)
            onClose();
        }
    };


    return (
        <div className={classes.container}>
            {!folder_id && (
                <div>
                    <Typography className={classes.title}>Elige la asignatura</Typography>
                    <FormControl className={classes.formControl} variant="outlined">
                        <InputLabel
                            className={classes.inputLabel}
                            classes={{ shrink: classes.shrinkLabel }}
                            id="first-dropdown-label"
                        >
                            {t("select_subject")}
                        </InputLabel>
                        <Select
                            labelId="first-dropdown-label"
                            id="first-dropdown"
                            value={firstOption}
                            onChange={handleFirstChange}
                            className={classes.select}
                            label={t("select_subject")}
                            style={{ fontSize: "12px" }}
                        >
                            {listFolders.map((item, i) => (
                                <MenuItem className={classes.menuItem} value={item} key={i}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            )}
            {!document_id && (
                <div>
                    <Typography className={classes.title}>Elige los apuntes</Typography>
                    <FormControl className={classes.formControl} variant="outlined">
                        <InputLabel
                            className={classes.inputLabel}
                            classes={{ shrink: classes.shrinkLabel }}
                            id="second-dropdown-label"
                        >
                            {t("select_your_document")}
                        </InputLabel>
                        <Select
                            labelId="second-dropdown-label"
                            id="second-dropdown"
                            value={secondOption}
                            onChange={handleSecondChange}
                            className={classes.select}
                            label={t("select_your_document")}
                            style={{ fontSize: "12px" }}

                        >
                            {documents.map((item, i) => (
                                <MenuItem value={item} key={i} className={classes.menuItem}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            )}
            <div>
                <Typography className={classes.title}>{t("number_questions")}</Typography>
                <div style={{ marginTop: "12px" }}>
                    <ShowOptionsInline
                        options={options}
                        value={selectedOption}
                        handleChange={handleOptionChange}
                    />
                </div>
                <Typography style={{ textAlign: "left", fontSize: 12, fontWeight: "bold", marginTop: "15px" }} >{t("choose_study")}</Typography>
                {/* <Typography style={{ textAlign: "left", fontSize: 12, fontWeight: "normal", marginTop: "2px", color: "gray" }} >{t("max_pages_20")}</Typography> */}
                <Box display="flex" flexDirection="row" style={{ gap: "20px", marginTop: "10px" }} >
                    <div style={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: "center" }}>
                        <Typography style={{ textAlign: "left", fontSize: 12 }}>{t("initial_page")}</Typography>
                        <TextField

                            value={initPage}
                            onChange={handleStartPageChange}
                            onKeyPress={handleKeyPress}
                            error={errorPages}

                            // helperText={error ? 'El rango no puede ser mayor a 20 páginas' : ''}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', style: { padding: '5px', width: '30px', fontSize: 12 } }}
                            variant="outlined"
                            size="small"
                            style={{ marginTop: '5px' }}
                        />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: "center" }}>
                        <Typography style={{ textAlign: "left", fontSize: 12 }}>{t("final_page")}</Typography>

                        <TextField
                            value={finalPage}
                            onChange={handleEndPageChange}
                            onKeyPress={handleKeyPress}
                            error={errorPages}

                            // helperText={error ? 'El rango no puede ser mayor a 20 páginas' : ''}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', style: { padding: '5px', width: '30px', fontSize: 12 } }}
                            variant="outlined"
                            size="small"
                            style={{ marginTop: '5px' }}
                        />
                    </div>
                </Box>
                {errorPages && (<Typography style={{ color: "red", fontSize: 12 }}>{errorMessagePages}</Typography>)}

                {/* <Typography className={classes.title} style={{ marginTop: "5px" }}>{t("choose_difficulty")}</Typography>
                <div style={{ marginTop: "12px" }}>
                    <ShowOptionsInline
                        options={optionsDificulty}
                        value={difficultyExamOption}
                        handleChange={handleOptionDifficultyChange}
                    />
                </div> */}
                <Typography className={classes.title} style={{ marginTop: "5px" }}>{t("format_exam")}</Typography>
                <div style={{ marginTop: "12px" }}>
                    <ShowOptionsInline
                        options={optionsFormat}
                        value={typeExamOption}
                        handleChange={handleOptionFormatChange}
                    />
                </div>
            </div>
            <Box display="flex" justifyContent="flex-end">
                <Button disabled={isLoading || selectedOption === "" || (firstOption === "" && folder_id === "") || (secondOption === "" && document_id === "") || typeExamOption === ""} className={classes.buttonUpload} onClick={handleGenerateExam}>{isLoading ? <ClipLoader size={12} color={"#026277"} /> : t("create")}</Button>
            </Box>
        </div >
    );
};

export default ModalLayoutExam;
