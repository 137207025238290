import React, { useState, useEffect } from "react";
import { Button, TextField, Typography, Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from "react-spinners";
import { fetchUserFolder, fetchUserFolders } from "../Api/FolderApi";
import { useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import clsx from 'clsx';


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
        marginTop: "12px",
    },
    formControl: {
        minWidth: 120,
        width: '90%',
        marginBottom: "0px",
        marginTop: "10px",
    },
    title: {
        fontSize: '12px',
        fontWeight: '600',
        lineHeight: '26px'
    },
    menuItem: {
        fontSize: '10px',
        borderRadius: '8px',

    },
    select: {
        height: '30px', // Set the desired height for the Select component
        '& .MuiSelect-selectMenu': {
            fontSize: "10px"
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '8px',
        },
    },
    inputLabel: {
        fontSize: '10px', // Set the font size for the label
        transform: 'translate(10px, 10px) scale(1)', // Adjust the position of the label
    },
    shrinkLabel: {
        transform: 'translate(10px, -6px) scale(0.75)', // Position of the label when shrunk
    },
    datePicker: {
        "& .css-i4bv87-MuiSvgIcon-root": {
            color: "#026277"
        },
        marginRight: "10px"

    },
    buttonUpload: {
        backgroundColor: "#026277",
        color: "white",
        textTransform: "none",
        borderRadius: "6px",
        fontSize: "10px",
        display: "flex",
        alignItems: "flex-end",
        gap: "2px",
        width: "30px",

        padding: "6px 12px", // Added padding for better appearance
        '&:disabled': {
            backgroundColor: '#B0B0B0', // Gray color for the disabled state
            color: '#FFFFFF'           // White text for the disabled state
        },
        '&:hover': {
            backgroundColor: '#026277', // Gray color for the disabled state
            color: "white",


        }
    },
    textField: {
        '& .MuiInputBase-input::placeholder': {
            color: 'black',
        },
        fontSize: "12px",
        fontWeight: "bold",
        width: '45%',
    },
    buttonUpload: {
        backgroundColor: "#026277",
        color: "white",
        textTransform: "none",
        borderRadius: "6px",
        fontSize: "10px",
        display: "flex",
        alignItems: "flex-end",
        gap: "2px",
        width: "80px",

        padding: "6px 12px", // Added padding for better appearance
        '&:disabled': {
            backgroundColor: '#B0B0B0', // Gray color for the disabled state
            color: '#FFFFFF'           // White text for the disabled state
        },
        '&:hover': {
            backgroundColor: '#026277', // Gray color for the disabled state
            color: "white",


        }

    },
    textField: {
        marginRight: '10px',
        width: '45%',
        '& .MuiOutlinedInput-root': {
            '& .MuiInputBase-input': {
                padding: '8px 10px',
                fontSize: '10px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.23)',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.87)',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.main,
            },
        },
        '& .MuiFormLabel-root': {
            fontSize: '10px',
        },
    },

    text: {
        fontSize: "10px",
    },
    textTitle: {
        fontSize: "12px",
        fontWeight: "bold",
        width: '45%',

    },
    addSubject: {
        backgroundColor: "white",
        color: "black",
        textTransform: "none",
        borderRadius: "6px",
        fontSize: "10px",
        display: "flex",
        alignItems: "flex-end",
        gap: "2px",
        width: "120px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        padding: "6px 12px", // Added padding for better appearance
    },
    eliminarSubject: {
        backgroundColor: "red",
        color: "white",
        textTransform: "none",
        borderRadius: "6px",
        fontSize: "10px",
        display: "flex",
        alignItems: "flex-end",
        gap: "2px",
        width: "120px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        padding: "6px 12px", // Added padding for better appearance
        '&:hover': {
            backgroundColor: "white",
            color: "red",
        }
    },
    datePicker: {
        "& .css-i4bv87-MuiSvgIcon-root": {
            color: "#026277"
        },
        marginRight: "10px"

    },
    subjectsContainer: {
        maxHeight: '200px', // Adjust the height as needed
        overflowY: 'auto',
    },
    subjectsContainer2: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        overflow: 'hidden',
        width: '100%',
    },
    subjectButton: {
        border: '1px solid #D9D9D9',
        borderRadius: '12px',
        backgroundColor: '#FFFDFD',
        padding: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),

        minWidth: '100px',
        textAlign: 'center',
        margin: theme.spacing(1),
        '&:hover': {
            borderColor: theme.palette.primary.main,
            cursor: 'pointer',
        },
        fontSize: "10px"
    },
    selectedSubjectButton: {
        borderColor: '#026277',
        backgroundColor: '#E2F1F1',
    },

}));
const ModalNewFolder = ({ onClose, token, user, setMessage, setShowAlert, onFolderCreated }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [subjects, setSubjects] = useState([{ name: '', examDate: null }]);
    const [selectedSubject, setSelectedSubject] = useState({});
    const [files, setFiles] = useState([]);
    const [visibleIndex, setVisibleIndex] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    const [dbSubjects, setDbSubjects] = useState([]);
    const [isFetchingFolders, setIsFetchingFolders] = useState(false);

    const [newFolderName, setNewFolderName] = useState("");
    const navigate = useNavigate();

    const handleNewFolder = (event) => {
        setNewFolderName(event.target.value);
    };
    const isValidName = (name) => {
        const regex = /^(?=(?:.*[a-zA-Z]){2})[a-zA-Z\s]*$/;
        return regex.test(name.trim());
    };
    const handleSubjectChange = (index, field, value) => {
        const newSubjects = [...subjects];
        newSubjects[index][field] = value;
        setSubjects(newSubjects);
    };

    const handleEnd = async () => {
        // Perform action based on selected folder
        try {
            const filteredSubjects = subjects.filter(subject => subject.name.trim() !== "");

            setIsLoading(true)
            if (filteredSubjects.length > 0) {
                const fetchFolders = await fetchUserFolders(token, "POST", {
                    list_folders: filteredSubjects, // Pass the filtered list here
                }, user);
                if (onFolderCreated) {
                    onFolderCreated(); // Invoke the callback to refresh the list of exams
                }
                navigate(`/${fetchFolders[0].guid}`)
            }
            onClose();
            setIsLoading(false)
        } catch (error) {
            setMessage("Couldn't create the folder");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);
        };
    }

    const handleAddSubject = () => {
        setSubjects([{ name: '', examDate: null }, ...subjects]);
    };
    const handleSubjectClick = (subject) => {
        setSelectedSubject(subject);
    };

    const handlePrevClick = () => {
        setVisibleIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    const handleNextClick = () => {
        setVisibleIndex((prevIndex) => Math.min(prevIndex + 1, subjects.length - 4));
    };
    const handleRemoveLastSubject = () => {
        if (subjects.length > 1) {
            const updatedSubjects = subjects.slice(1);
            setSubjects(updatedSubjects); // Update the state with the new array
        }
    };
    useEffect(() => {
        const areAllNamesEmpty = subjects.every(subject => !isValidName(subject.name));
        setIsButtonDisabled(areAllNamesEmpty);
    }, [subjects]);


    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Box>

                    <Box display="flex" mt={2} >
                        <Typography className={classes.textTitle}
                        >{t("name")}</Typography>
                    </Box>
                    <Box className={`${classes.subjectsContainer} `} >

                        {subjects.map((subject, index) => (
                            <Box key={index} display="flex" mt={2}>
                                <TextField
                                    value={subject.name}
                                    onChange={(e) => handleSubjectChange(index, 'name', e.target.value)}
                                    variant="outlined"
                                    placeholder={t("name")}
                                    style={{ marginRight: '10px', width: '95%' }}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: { fontSize: "10px" }
                                    }}
                                    InputLabelProps={{
                                        style: { fontSize: "10px" }
                                    }}
                                    className={classes.textField}
                                />

                            </Box>
                        ))}
                    </Box>
                    {/* {subjects.length < 10 && (

                        <Box display="flex" justifyContent="center" marginTop="16px" style={{ gap: "10px" }}>

                            <Button onClick={handleAddSubject} className={classes.addSubject}>
                                {t("add_subject")}
                            </Button>

                            <Button onClick={handleRemoveLastSubject} className={classes.eliminarSubject}>
                                {t("remove_subject")}

                            </Button>
                        </Box>
                    )} */}

                </Box>
            </LocalizationProvider>

            <Box display="flex" justifyContent="flex-end" marginTop="10px">
                <Button disabled={isLoading} className={classes.buttonUpload} onClick={handleEnd}>{isLoading ? <ClipLoader size={12} color={"#026277"} /> : t("!estudiar!")}</Button>
            </Box>
        </div>
    );
};

export default ModalNewFolder;
