import { getCsrfToken } from './TokenApi'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export async function uploadExam(token, formData, user) {
    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/upload_exam/?user_id=${user_id}`;

    try {
        const csrfToken = getCsrfToken();
        const headers = {
            'Authorization': `Bearer ${token}`,
        };

        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: formData
        };

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error uploading exam:', error);
        throw error;
    }
}

export async function createExamDocument(token, documentData, user) {
    const formData = new FormData();

    // Add each field individually to the FormData
    formData.append('document_name', documentData.document_name);
    formData.append('folder_id', documentData.folder_id);
    formData.append('enunciado', documentData.enunciado);
    formData.append('questions', JSON.stringify(documentData.questions));
    formData.append('file', documentData.file);


    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/create-exam-document/?user_id=${user_id}`;

    try {
        const csrfToken = getCsrfToken();
        const headers = {
            'Authorization': `Bearer ${token}`,
        };

        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: formData
        };

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error creating exam document:', error);
        throw error;
    }
}


