import React, { useState } from 'react';
import { Handle } from 'react-flow-renderer';
import { styled } from '@mui/material/styles';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNodeDialog from './EditNodeDialog';
import { updateNode, editNodeContent } from '../Api/mentalMapService';
import { useSession, useUser } from '@supabase/auth-helpers-react';
import { useParams } from 'react-router-dom';
import { useReactFlow } from 'react-flow-renderer';
import ReactMarkdown from 'react-markdown';

const NodeContainer = styled('div')(({ theme, isRoot, selected }) => ({
  borderRadius: '8px',
  backgroundColor: '#fff',
  border: `2px solid ${selected ? '#6BBDBD' : '#e2e8f0'}`,
  width: '300px',
  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
  position: 'relative',
  overflow: 'hidden',
  transition: 'border-color 0.2s ease',
  ...(isRoot && {
    '& .header': {
      backgroundColor: '#6BBDBD',
    },
    '& .headerIcon, & .headerTitle': {
      color: 'white',
    },
    '& .MuiIconButton-root svg': {
      color: 'white',
    },
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  }),
}));

const Header = styled('div')({
  padding: '8px 12px',
  backgroundColor: '#fff',
  borderBottom: '1px solid #e2e8f0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8px',
});

const HeaderLeft = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const HeaderIcon = styled(AccountTreeIcon)({
  color: '#6BBDBD',
  fontSize: '1.2rem',
});

const HeaderTitle = styled('span')({
  fontFamily: '"Fira Sans", sans-serif',
  fontWeight: 600,
  fontSize: '0.9rem',
  color: '#2c3e50',
});

const Content = styled('div')({
  padding: '12px',
});

const Description = styled('div')({
  fontFamily: '"Fira Sans", sans-serif',
  fontSize: '0.875rem',
  color: '#64748b',
  lineHeight: 1.5,
  '& p': {
    margin: 0,
  },
  '& strong': {
    color: '#2c3e50',
    fontWeight: 600,
  }
});

const StyledIconButton = styled(IconButton)({
  padding: '4px',
  '& svg': {
    fontSize: '1.1rem',
    color: '#64748b',
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    '& svg': {
      color: '#6BBDBD',
    },
  },
});

const StyledMenuItem = styled(MenuItem)({
  fontFamily: '"Fira Sans", sans-serif',
  fontSize: '0.875rem',
  minHeight: '36px',
  '& svg': {
    fontSize: '1.1rem',
    marginRight: '8px',
    color: '#64748b',
  },
});

function CustomNode({ data, id, selected }) {
  const session = useSession();
  const user = useUser();
  const { schema_id } = useParams();
  const { setNodes } = useReactFlow();
  const isRootNode = id === '1';
  const [anchorEl, setAnchorEl] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [error, setError] = useState(null);

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setEditDialogOpen(true);
    handleMenuClose();
  };

  const handleDelete = () => {
    if (data.onDelete) {
      data.onDelete();
    }
    handleMenuClose();
  };

  const handleEditSubmit = async (formData) => {
    try {
      console.log('Submitting edit with:', formData);
      
      const updatedNode = await editNodeContent(
        session?.access_token,
        user,
        schema_id,
        id,
        formData
      );

      // Update the node in the flow
      setNodes(nodes => 
        nodes.map(node => {
          if (node.id === id) {
            return {
              ...node,
              data: {
                ...node.data,
                label: updatedNode.label,
                description: updatedNode.description
              }
            };
          }
          return node;
        })
      );

      setEditDialogOpen(false);
    } catch (error) {
      console.error('Error in handleEditSubmit:', error);
      setError('Failed to update node. Please try again.');
    }
  };

  return (
    <>
      <NodeContainer isRoot={isRootNode} selected={selected}>
        <Handle type="target" position="top" />
        <Header className="header">
          <HeaderLeft>
            <HeaderIcon className="headerIcon" />
            <HeaderTitle className="headerTitle">{data.label}</HeaderTitle>
          </HeaderLeft>
          <StyledIconButton 
            size="small"
            onClick={handleMenuOpen}
          >
            <MoreVertIcon />
          </StyledIconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              style: {
                borderRadius: '8px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              },
            }}
          >
            <StyledMenuItem onClick={handleEdit}>
              <EditIcon />
              Editar
            </StyledMenuItem>
            <StyledMenuItem onClick={handleDelete}>
              <DeleteIcon />
              Eliminar
            </StyledMenuItem>
          </Menu>
        </Header>
        <Content>
          <Description>
            <ReactMarkdown>{data.description}</ReactMarkdown>
          </Description>
        </Content>
        <Handle type="source" position="bottom" />
      </NodeContainer>
      
      <EditNodeDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        onSubmit={handleEditSubmit}
        nodeData={{
          label: data.label,
          description: data.description
        }}
        error={error}
      />
    </>
  );
}

export default CustomNode; 