import React, { useState, useEffect } from 'react';
import UploadFilesFolder from "../NewComponents/Upload/UploadFileFolder";
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    TextField,
    Typography, Box, InputLabel, MenuItem, Select
} from '@mui/material';
import ShowFolders from '../NewComponents/Upload/ShowFolders';
import { fetchUserFiles } from '../Api/FilesApi';
import { useNavigate } from 'react-router-dom';
import { fetchUserFolders } from '../Api/FolderApi';

const useStyles = makeStyles((theme) => {
    return {

        title: {
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '26px'
        },
        buttonUpload: {
            backgroundColor: "#026277",
            color: "white",
            textTransform: "none",
            borderRadius: "6px",
            fontSize: "14px",
            display: "flex",
            alignItems: "flex-end",
            gap: "2px",

            '&:disabled': {
                backgroundColor: '#B0B0B0', // Gray color for the disabled state
                color: '#FFFFFF'           // White text for the disabled state
            },
            '&:hover': {
                backgroundColor: '#026277', // Gray color for the disabled state
                color: "white",
                // padding: "8px 14px", // Added padding for better appearance

            }
        },
        menuItem: {
            fontSize: '14px',
            borderRadius: '8px',

        },
        formControl: {
            minWidth: 120,
            width: '300px',
            marginBottom: "0px",
            marginTop: "10px",
        },
        select: {
            height: '30px', // Set the desired height for the Select component
            '& .MuiSelect-selectMenu': {
                fontSize: "14px",

            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: '8px',
            },
        },
        inputLabel: {
            fontSize: '14px', // Set the font size for the label
            transform: 'translate(10px, 10px) scale(1)', // Adjust the position of the label
        },
        shrinkLabel: {
            transform: 'translate(10px, -6px) scale(0.75)', // Position of the label when shrunk
            // width: "50px"
        },
        errorMessage: {
            color: 'red',
            fontSize: '12px',
            marginTop: '10px',
        },
    };

});
const AIChatSubject = ({ user, token, setCredits, setFolder, onFoldersUpdate }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [files, setFiles] = useState([]);
    const [error, setError] = useState(null);
    const [value, setValue] = useState("");
    const [listFolders, setListFolders] = useState([])
    const [firstOption, setFirstOption] = useState('');

    const [newFolderName, setNewFolderName] = useState("");
    const [selectedFolder, setSelectedFolder] = useState("");
    const [pages, setPages] = useState("")
    const [numDocuments, setNumDocuments] = useState("")
    useEffect(() => {
        setCredits(numDocuments * 5);
    }, [numDocuments]);
    const handleSetValue = (value) => {
        setSelectedFolder(value);
        // Perform action based on selected folder
    };

    const handleNewFolder = (folderName) => {
        setNewFolderName(folderName);
        // Perform action based on new folder name
    };
    const navigate = useNavigate();

    const handleUploadClick = () => {
        if (selectedFolder) {
            setFolder(selectedFolder)
        } else { setFolder(firstOption) }
    }
    const handleFirstChange = async (event) => {
        const selectedFolder = event.target.value;
        setFirstOption(selectedFolder)
    }
    const handleFoldersFetched = (folders) => {
        setListFolders(folders);
        onFoldersUpdate(folders); // Update parent component with the new folders list

    };

    async function fetchDataAndUpdate() {
        if (token && user) {
            try {
                const fetchFolders = await fetchUserFolders(token, "GET", null, user);
                setListFolders(fetchFolders);
                onFoldersUpdate(fetchFolders); // Update parent component with the new folders list


            } catch (error) {
                console.error("Error fetching folders: ", error);
            }
        }
    }
    useEffect(() => {
        fetchDataAndUpdate();
    }, [token, user]);
    return (
        <div style={{ marginTop: "10px", textAlign: "left" }}>
            <Typography className={classes.title}>{t("choose_subject")}</Typography>

            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                    className={classes.inputLabel}
                    classes={{ shrink: classes.shrinkLabel }}
                    id="first-dropdown-label"
                >
                    {t("select_subject")}
                </InputLabel>
                <Select
                    labelId="first-dropdown-label"
                    id="first-dropdown"
                    value={firstOption}
                    onChange={handleFirstChange}
                    className={classes.select}
                    label={t("select_subject")}
                    style={{ fontSize: "14px" }}
                >
                    {listFolders.map((item, i) => (
                        <MenuItem className={classes.menuItem} value={item} key={i}>
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
                {listFolders.length === 0 && (
                    <Typography className={classes.errorMessage}>{t("no_folders_desc")}</Typography>
                )}
            </FormControl>

            {/* <Typography style={{ fontSize: 14, textAlign: "center", marginTop: "15px", marginBottom: "15px" }}>{t("or")}</Typography>
            <Typography className={classes.title} style={{ marginBottom: "10px" }}>{t("load_your_notes_desc")}</Typography>

            <UploadFilesFolder files={files} setFiles={setFiles} is_multiple={true} setPages={setPages} setNumDocuments={setNumDocuments} maxWidthError={300} />
            
            {/* <div style={{ marginTop: "14px" }}> 

                <FormControl required error={error}>

                    <ShowFolders
                        value={selectedFolder}
                        setValue={handleSetValue}
                        handleNewFolder={handleNewFolder}
                        margin="dense"
                        sizeTextFolder={14}
                        fontSizeItems={14}
                        onFoldersFetched={handleFoldersFetched}

                    />
                </FormControl>


            </div> */}
            <Box display="flex" justifyContent="flex-end" marginTop="30px">
                <Button disabled={firstOption == ""} className={classes.buttonUpload} onClick={handleUploadClick} >{t("Start")}</Button>
            </Box>
        </div>

    );
};

export default AIChatSubject;
