import React from 'react';
import {
  Dialog,
  DialogContent,
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Chip
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import DescriptionIcon from '@mui/icons-material/Description';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: 12,
      padding: theme.spacing(2),
      maxWidth: '600px',
      width: '100%'
    }
  },
  card: {
    height: '100%',
    cursor: 'pointer',
    borderRadius: 12,
    border: '1px solid #e0e0e0',
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'translateY(-4px)',
      border: '1px solid #6BBDBD'
    }
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(3),
    position: 'relative'
  },
  betaChip: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: '#E2F1F1',
    color: '#026277',
    fontFamily: 'Fira Sans',
    fontSize: '11px',
    height: '20px',
    fontWeight: 500,
    border: '1px solid #6BBDBD',
    '& .MuiChip-label': {
      padding: '0 8px'
    }
  },
  icon: {
    fontSize: 48,
    color: '#6BBDBD',
    marginBottom: theme.spacing(2)
  },
  title: {
    fontFamily: 'Fira Sans',
    fontWeight: 600,
    fontSize: '18px',
    marginBottom: theme.spacing(1)
  },
  subtitle: {
    fontFamily: 'Fira Sans',
    color: '#666',
    fontSize: '14px'
  },
  modalTitle: {
    fontFamily: 'Fira Sans',
    fontWeight: 600,
    fontSize: '24px',
    marginBottom: theme.spacing(3),
    textAlign: 'center'
  }
}));

const UploadOptionsModal = ({ open, onClose, onUploadDocument }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleDocumentUpload = () => {
    onClose();
    onUploadDocument();
  };

  const handleExamUpload = () => {
    onClose();
    navigate('/subir-examen');
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.dialog}
      maxWidth="md"
    >
      <DialogContent>
        <Typography className={classes.modalTitle}>
          ¿Qué deseas subir?
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Card className={classes.card} onClick={handleDocumentUpload}>
              <CardContent className={classes.cardContent}>
                <DescriptionIcon className={classes.icon} />
                <Typography className={classes.title}>
                  Subir documento
                </Typography>
                <Typography className={classes.subtitle}>
                  Sube un nuevo documento a estudiar, resumir o crear esquemas
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card className={classes.card} onClick={handleExamUpload}>
              <CardContent className={classes.cardContent}>
                <Chip 
                  label="Beta" 
                  size="small"
                  className={classes.betaChip}
                />
                <AssignmentIcon className={classes.icon} />
                <Typography className={classes.title}>
                  Subir un examen
                </Typography>
                <Typography className={classes.subtitle}>
                  Sube tus propias preguntas de examen y estudialas en Typed 
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UploadOptionsModal;
