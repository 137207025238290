import { getCsrfToken } from './TokenApi'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export async function fetchQuestionAnswer(token, method = "GET", exam_id, user, formData) {
    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/answerQuestion/${exam_id}/?user_id=${user_id}`;
    try {
        const csrfToken = method !== "GET" ? getCsrfToken() : null; // Get CSRF token for non-GET requests
        const headers = {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            'Content-Type': "application/json"
        };

        // If the method requires CSRF protection, add the CSRF token to the headers
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: method,
            headers: headers,
        };

        if (formData) {

            requestOptions.body = JSON.stringify(formData);
        }

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching/updating user information:', error);
        throw error;
    }
}
export async function fetchQuestionAnswerId(token, method = "GET", question_id, user, formData) {
    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/question/${question_id}/?user_id=${user_id}`;
    try {
        const csrfToken = method !== "GET" ? getCsrfToken() : null; // Get CSRF token for non-GET requests
        const headers = {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            'Content-Type': "application/json"
        };

        // If the method requires CSRF protection, add the CSRF token to the headers
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: method,
            headers: headers,
        };

        if (formData) {

            requestOptions.body = JSON.stringify(formData);
        }

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching/updating user information:', error);
        throw error;
    }
}

export async function generateQuestionsFromText(token, documentId, user, selectedText, type_question) {
    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/generate-question/${documentId}/?user_id=${user_id}`;
    
    try {
        const csrfToken = getCsrfToken();
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken
        };

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ text: selectedText , type_question:type_question})
        };

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error generating questions:', error);
        throw error;
    }
}



