import { getCsrfToken } from './TokenApi'
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


export const fetchUserSchemas = async (token, user) => {
    const user_id = user['id'];

    try {
        const response = await fetch(`${BACKEND_URL}/api_v1/schemas/?user_id=${user_id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            params: {
                user_id: user?.sub
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch schemas');
        }

        const fetchedSchemas = await response.json();

        // Transform the data to match the component's needs
        const transformedSchemas = fetchedSchemas.map(schema => ({
            id: schema.guid,
            title: schema.name,
            createdAt: schema.date_creation,
            folder: schema.folder_name,
            folder_id: schema.folder_id,
            graph: schema.graph,
            document_name: schema.document_name,
            excalidraw: schema.excalidraw,
            document_id: schema.document_id
        }));

        // Extract unique folders
        const uniqueFolders = [...new Set(fetchedSchemas
            .filter(schema => schema.folder_id)
            .map(schema => JSON.stringify({ id: schema.folder_id, name: schema.folder_name })))
        ].map(folder => JSON.parse(folder));

        const folderOptions = [
            { value: 'all', label: 'Todas las carpetas' },
            ...uniqueFolders.map(folder => ({
                value: folder.id,
                label: folder.name
            }))
        ];

        return {
            schemas: transformedSchemas,
            folders: folderOptions
        };

    } catch (error) {
        console.error("Error in fetchUserSchemas:", error);
        throw error;
    }
};

export const fetchSchemaById = async (token, user, schemaId) => {
    const user_id = user['id'];

    try {
        const response = await fetch(`${BACKEND_URL}/api_v1/schemas/${schemaId}/?user_id=${user_id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch schema');
        }

        const schema = await response.json();

        return {
            id: schema.guid,
            title: schema.name,
            elements: schema.elements, // Excalidraw data
            createdAt: schema.date_creation,
            folder: schema.folder_name,
            folder_id: schema.folder_id,
            document_name: schema.document_name,
            document_id: schema.document_id,
            excalidraw: schema.excalidraw
        };

    } catch (error) {
        console.error("Error in fetchSchemaById:", error);
        throw error;
    }
};

export const updateSchema = async (token, user, schemaId, schemaData) => {
    const user_id = user['id'];

    try {
        const response = await fetch(`${BACKEND_URL}/api_v1/schemas/${schemaId}/?user_id=${user_id}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: schemaData.name,
                elements: schemaData.elements,
                is_locked: schemaData.is_locked
            })
        });

        if (!response.ok) {
            throw new Error('Failed to update schema');
        }

        const updatedSchema = await response.json();

        return {
            id: updatedSchema.guid,
            title: updatedSchema.name,
            elements: updatedSchema.elements,
            createdAt: updatedSchema.date_creation,
            folder: updatedSchema.folder_name,
            folder_id: updatedSchema.folder_id,
            document_name: updatedSchema.document_name,
            document_id: updatedSchema.document_id
        };

    } catch (error) {
        console.error("Error in updateSchema:", error);
        throw error;
    }
};

export const createSchema = async (token, user, documentId, schemaData) => {
    const user_id = user['id'];

    try {
        const response = await fetch(`${BACKEND_URL}/api_v1/documents/${documentId}/schemas/?user_id=${user_id}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(schemaData)
        });

        if (!response.ok) {
            throw new Error('Failed to create schema');
        }

        const createdSchema = await response.json();

        // Transform the data to match the component's needs
        return {
            id: createdSchema.guid,
            title: createdSchema.name,
            createdAt: createdSchema.date_creation,
            folder: createdSchema.folder_name,
            folder_id: createdSchema.folder_id,
            graph: createdSchema.graph,
            document_name: createdSchema.document_name,
            excalidraw: createdSchema.excalidraw,
            document_id: createdSchema.document_id
        };

    } catch (error) {
        console.error("Error in createSchema:", error);
        throw error;
    }
};

export const deleteSchema = async (token, user, schemaId, documentId) => {
    const user_id = user['id'];

    try {
        const response = await fetch(`${BACKEND_URL}/api_v1/documents/${documentId}/schemas/${schemaId}/?user_id=${user_id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to delete schema');
        }

        return true;

    } catch (error) {
        console.error("Error in deleteSchema:", error);
        throw error;
    }
};

