import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Spinner from "./Spinner";
import {
  FormControl,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  FormHelperText,
} from '@mui/material';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    margin: theme.spacing(2),
  },
  customSkeletonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "30vh",
  },
  customSkeleton: {
    backgroundColor: "#026277",
    animation: "$waveAnimation 1.5s ease-in-out infinite",
    width: "50%",
    marginTop: "40px",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  "@keyframes waveAnimation": {
    "0%": {
      backgroundColor: "#026277",
    },
    "50%": {
      backgroundColor: "#03BFBF",
    },
    "100%": {
      backgroundColor: "#026277",
    },
  },
}));

const RetryButton = ({ type, transcriptId, retryButton }) => {
  const classes = useStyles();
  let token = localStorage.getItem("outseta_token");
  const [showAnotherComponent, setShowAnotherComponent] = useState(false);
  const [openSubDialog, setOpenSubDialog] = useState(false);

  const [retrying, setRetrying] = useState();
  const handleRetry = () => {
    // Handle retry logic here, e.g., call an API endpoint with transcriptId

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        T_id: transcriptId,
        is_update: true,
      }),
    };
    const response = fetch(
      `${BACKEND_URL}/api_v1/upload_meeting/?access_token=${token}`,
      requestOptions
    ).then((response) => response.json());

    // Set the state to show the another component
    setShowAnotherComponent(true);
    setOpenSubDialog(true);
  };
  useEffect(() => {
    setRetrying(retryButton);
  }, []);
  const handleSubDialogClose = () => {
    setOpenSubDialog(false);
    window.location.reload(true);
  };

  return (
    <>
      {retryButton === true ? (
        <div className={classes.customSkeletonContainer}>
          <Spinner height="50%" />
        </div>
      ) : (
        <div className={classes.container}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRetry}
            className={classes.button}
          >
            Reintentar
          </Button>
          <Typography variant="body1">
            El transcript no ha podido ser procesado. Inténtalo de nuevo
          </Typography>
        </div>
      )}
      <Dialog open={openSubDialog} onClose={handleSubDialogClose}>
        <DialogTitle>¡Proceso ejecutandose!🎉</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Estamos procesando el video, te enviaremos un correo cuando la
            transcripción este lista.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RetryButton;
