import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import "./csv.css"
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;



const styles = {
    header: {
        color: "#333", // Darker color for better readability
        borderBottom: "2px solid #ccc", // Solid border for distinction
        position: "sticky",
        zIndex: 10,
        top: 0,
        backgroundColor: "#FAFAFA",
        fontWeight: "bold", // Make header text bold
    },
    stickyHeader: {
        position: "sticky !important",
        top: 0,
        zIndex: 10,
        backgroundColor: "white",
    },
    tableWrapper: {
        width: "800px",
        borderRadius: "20px",
        marginBottom: "50px",
        overflowY: "auto", // Changed from scroll to auto for cleaner look
        overflowX: "auto",
        height: 650,
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
    },
    tableCell: {
        padding: "10px 15px", // Increased padding for better spacing
        borderRight: "1px solid #ddd", // Border for each cell
    },
}


function CSVViewerFile({ docInfo }) {
    const [tableRows, setTableRows] = useState([]);
    const [values, setValues] = useState([]);

    useEffect(() => {
        // Function to fetch and decrypt the CSV file
        const fetchCSV = async () => {
            try {
                const response = await fetch(`${BACKEND_URL}/api_v1/decrypt-pdf/`, {
                    method: 'POST',
                    body: JSON.stringify({ fileUrl: docInfo.url_stored }),
                    headers: { 'Content-Type': 'application/json' }
                });
                const text = await response.text();
                parseCSV(text);
            } catch (error) {
                console.error('Error fetching decrypted CSV:', error);
            }
        };

        const parseCSV = (csvText) => {
            Papa.parse(csvText, {
                header: true,
                skipEmptyLines: true,
                complete: (results) => {
                    const rowsArray = results.data.length > 0 ? Object.keys(results.data[0]) : [];
                    const valuesArray = results.data.map(Object.values);

                    setTableRows(rowsArray);
                    setValues(valuesArray);
                },
            });
        };

        fetchCSV();
    }, [docInfo]);

    return (
        <TableContainer component={Paper} style={styles.tableWrapper}>
            <Table>
                <TableHead style={styles.stickyHeader}>
                    <TableRow>
                        {tableRows.map((row, index) => (
                            <TableCell key={index} style={styles.header}>{row}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {values.map((value, index) => (
                        <TableRow key={index}>
                            {value.map((val, i) => (
                                <TableCell style={styles.tableCell} key={i} >{val}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default CSVViewerFile;
