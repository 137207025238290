import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, FormControl, InputLabel, Select, MenuItem, Box, Button, Tabs, Tab } from '@mui/material';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import ShowFolders from '../NewComponents/Upload/ShowFolders';
import { fetchUserFolders } from '../Api/FolderApi';
import { useAuth } from '../Auth/Authhandle';
import { fetchUserFiles } from '../Api/FilesApi';
import DragAndDrop from '../NewComponents/Upload/UploadFileFolder';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        width: '90% !important',
        marginBottom: "0px !important",
        marginTop: "5px !important",
    },
    title: {
        fontSize: '12px !important',
        fontWeight: '400 !important',
        lineHeight: '26px !important'
    },
    menuItem: {
        fontSize: '10px !important',
        borderRadius: '8px !important',
    },
    select: {
        height: '30px !important',
        '& .MuiSelect-selectMenu': {
            fontSize: "10px !important"
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '8px !important',
        },
    },
    inputLabel: {
        fontSize: '10px !important',
        transform: 'translate(10px, 10px) scale(1) !important',
    },
    shrinkLabel: {
        transform: 'translate(10px, -6px) scale(0.75) !important',
    },
    containerSelectors: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#f5f7fa',
        borderRadius: '10px',
        padding: '3px',
        gap: "3px",
        width: "fit-content",
        marginBottom: '15px',
        marginTop: '10px',
        paddingRight: "5px",
        margin: "0 auto"
    },
    button: {
        borderRadius: "10px  ",
        fontSize: "10px ",
        textTransform: "none ",
    },
    selectedButton: {
        backgroundColor: 'white',
        color: '#000  ',
        border: 'none ',
    },
    unselectedButton: {
        backgroundColor: 'transparent ',
        color: '#000 ',
        border: 'none  ',
    },
}));

const ColumnContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
});

const ColumnOne = ({ setPossibleToSend, token, user, translation, firstOption, setFirstOption, secondOption, setSecondOption, listFolders, setListFolders, documents, setDocuments, setFiles, files, setValue, setNewFolderName, setTotalPages }) => {
    const classes = useStyles();

    const handleFirstChange = async (event) => {
        const selectedFolder = event.target.value;
        setFirstOption(selectedFolder);
        fetchDocuments(selectedFolder.guid);
        if (files.length === 0) {
            setSecondOption('');
            setFiles([]);
            setValue('');
            setNewFolderName('');
        }
    };

    const fetchDocuments = async (folder_id) => {
        if (folder_id) {
            try {
                const fetchedFiles = await fetchUserFiles(token, "GET", folder_id, null, user);
                setDocuments(fetchedFiles);

            } catch (error) {
                console.error('Error fetching files:', error);
            }
        }
    };

    useEffect(() => {
        if (files.length > 0) {
            setPossibleToSend(false)
        } else {
            if (firstOption && secondOption) {
                setPossibleToSend(true)
                setTotalPages(secondOption.info_doc.pages)

            }
        }
    }, [firstOption, secondOption, files]);

    useEffect(() => {
        if (token && user) {
            const fetchFolders = async () => {
                try {
                    const folders = await fetchUserFolders(token, "GET", null, user);
                    setListFolders(folders);
                } catch (error) {
                    console.error('Error fetching folders:', error);
                }
            };
            fetchFolders();
        }
    }, [token, user]);

    return (
        <ColumnContainer>
            <div>
                <Typography className={classes.title}>{translation("choose_subject")}</Typography>
                <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel
                        className={classes.inputLabel}
                        classes={{ shrink: classes.shrinkLabel }}
                        id="first-dropdown-label"
                    >
                        {translation("select_subject")}
                    </InputLabel>
                    <Select
                        labelId="first-dropdown-label"
                        id="first-dropdown"
                        value={firstOption}
                        onChange={handleFirstChange}
                        className={classes.select}
                        label={translation("select_subject")}
                        style={{ fontSize: "12px" }}
                    >
                        {listFolders.map((item, i) => (
                            <MenuItem className={classes.menuItem} value={item} key={i}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div>
                <Typography className={classes.title}>{translation("choose_notes")}</Typography>
                <FormControl className={classes.formControl} variant="outlined">
                    <InputLabel
                        className={classes.inputLabel}
                        classes={{ shrink: classes.shrinkLabel }}
                        id="second-dropdown-label"
                    >
                        {translation("select_your_document")}
                    </InputLabel>
                    <Select
                        labelId="second-dropdown-label"
                        id="second-dropdown"
                        value={secondOption}
                        onChange={(event) => setSecondOption(event.target.value)}
                        className={classes.select}
                        label={translation("select_your_document")}
                        style={{ fontSize: "12px" }}
                    >
                        {documents.map((item, i) => (
                            <MenuItem value={item} key={i} className={classes.menuItem}>
                                {item.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {/* {files.length > 0 && firstOption && (<Typography style={{ fontSize: "10px", color: "red" }}>{translation("fileWarning")}</Typography>)} */}
            </div>
        </ColumnContainer>
    );
};

const ColumnTwo = ({ setPossibleToSend, translation, files, setFiles, value, setValue, newFolderName, setNewFolderName, setFirstOption, setSecondOption, setTotalPages }) => {
    const classes = useStyles();

    const handleFolderChange = (value) => {
        setValue(value);
        if (value) {
            setNewFolderName("");
        }
        setFirstOption('');
        setSecondOption('');
    };

    const handleDeleteFile = () => {
        setFiles([]);
        setPossibleToSend(false);
    };

    useEffect(() => {
        if (files.length > 0 && ((value) || newFolderName.length > 0)) {
            setPossibleToSend(true)
            setTotalPages(files[0].pages)
        }
    }, [files, value, newFolderName, setNewFolderName]);

    return (
        <div style={{ marginTop: "-10px" }}>
            <Typography className={classes.title} style={{ marginTop: "10px" }}>{translation("load_your_notes_desc")}</Typography>
            <DragAndDrop files={files} setFiles={setFiles} is_multiple={false} limit={1} setPages={() => { }} handleDeleteFileLoad={handleDeleteFile} />
            <FormControl required style={{ marginTop: "10px", width: "100%" }}>
                <ShowFolders
                    value={value}
                    setValue={handleFolderChange}
                    handleNewFolder={(folderName) => setNewFolderName(folderName)}
                    margin="dense"
                    fontWeightNormal={true}
                />
            </FormControl>
        </div>
    );
};

const LoadDocuments = ({ setPossibleToSend, translation, token, user, firstOption, setFirstOption, secondOption, setSecondOption, listFolders, setListFolders, documents, setDocuments, files, setFiles, value, setValue, newFolderName, setNewFolderName, setTotalPages }) => {
    const [selectedTab, setSelectedTab] = useState('chooseFile');
    const classes = useStyles();

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Container container>
            {/* <Box className={classes.containerSelectors}>
                <Tabs value={selectedTab} onChange={handleTabChange} centered>
                    <Tab value="chooseFile" label={"ChooseFile"} />
                    <Tab value="uploadFile" label={"uploadFile"} />
                </Tabs>
            </Box> */}
            <Box className={classes.containerSelectors}>
                <Button
                    className={`${classes.button} ${selectedTab === 'chooseFile' ? classes.selectedButton : classes.unselectedButton}`}
                    onClick={() => { setSelectedTab('chooseFile'); setPossibleToSend(false) }}
                >
                    Escoger fichero
                </Button>
                <Button
                    className={`${classes.button} ${selectedTab === 'uploadFile' ? classes.selectedButton : classes.unselectedButton}`}
                    onClick={() => { setSelectedTab('uploadFile'); setPossibleToSend(false) }}
                >
                    Subir fichero
                </Button>

            </Box>
            <Grid container spacing={2} >

                {selectedTab === 'chooseFile' && (
                    <>
                        <Grid item xs={12} md={5} style={{ paddingLeft: "9px", margin: "0 auto" }}>
                            <ColumnOne
                                translation={translation}
                                token={token}
                                user={user}
                                firstOption={firstOption}
                                setFirstOption={setFirstOption}
                                secondOption={secondOption}
                                setSecondOption={setSecondOption}
                                listFolders={listFolders}
                                setListFolders={setListFolders}
                                documents={documents}
                                setDocuments={setDocuments}
                                setFiles={setFiles}
                                files={files}
                                setValue={setValue}
                                setNewFolderName={setNewFolderName}
                                setPossibleToSend={setPossibleToSend}
                                setTotalPages={setTotalPages}
                            />
                        </Grid>

                    </>
                )}
                {selectedTab === 'uploadFile' && (<>
                    <Grid item xs={12} md={5} style={{ paddingLeft: "9px", margin: "0 auto", width: "35vh" }}>
                        <ColumnTwo
                            translation={translation}
                            files={files}
                            setFiles={setFiles}
                            value={value}
                            setValue={setValue}
                            newFolderName={newFolderName}
                            setNewFolderName={setNewFolderName}
                            setFirstOption={setFirstOption}
                            setSecondOption={setSecondOption}
                            setPossibleToSend={setPossibleToSend}
                            setTotalPages={setTotalPages}
                        />
                    </Grid>
                </>
                )}
            </Grid>
        </Container>
    );
};

export default LoadDocuments;
