import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  Card, CardContent, Typography, Box, Grid, Container,
  Accordion, AccordionSummary, AccordionDetails, Button, Skeleton, Chip, CircularProgress,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, IconButton,
  Snackbar, Alert, Select, MenuItem, FormControl
} from '@mui/material';

import { Tooltip } from '@mui/material';
import { useSubscription } from "../Context/SubscriptionContext";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useStylesPreguntasDocument from './Styles/useStylePreguntasDocument';
import PreguntasDocumentPopup from './PreguntasDocumentPopup';
import Premium from '../PopUpsAlerts/Premium/Premium';
import LinearProgress from '@mui/material/LinearProgress';

import DeleteIcon from '@mui/icons-material/Delete';
import { deleteTopic } from '../Api/ChaptersApi';
import preguntasbloquedas from './preguntasbloquedas.svg';
import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Slider from '@mui/material/Slider';
import RefreshIcon from '@mui/icons-material/Refresh';
import { fetchRegenerateQuestions, fetchGenerateQuestionsCount } from '../Api/FilesApi';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import EditIcon from '@mui/icons-material/Edit';
import { deleteQuestion } from '../Api/QuestionApi';
import { updateQuestion } from '../Api/QuestionApi';
import EditQuestionPopup from './EditQuestionPopup';
const useStylesDeleteChapter = makeStyles((theme) => ({
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.2) !important',
  },
  dialog: {
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
  },
  content: {
    borderRadius: '20px !important',
    fontFamily: 'Inter, sans-serif !important',
  },
  actions: {
    justifyContent: 'space-between !important',
    fontFamily: 'Inter, sans-serif !important',
    marginTop: '15px !important',
    marginBottom: '10px !important',
  },
  dialogTitle: {
    '& .MuiTypography-root': {
      fontFamily: 'Inter, sans-serif !important',
      fontSize: '18px !important',
      fontWeight: 600,
      color: '#333 !important',
    },
  },
  deleteButton: {
    backgroundColor: '#D32F2F !important',
    color: 'white !important',
    fontFamily: 'Inter, sans-serif !important',
    fontSize: '16px !important',
    fontWeight: 600,
    textTransform: 'none !important',
    borderRadius: '20px !important',
    padding: '6px 16px !important',
    '&:hover': {
      backgroundColor: '#B71C1C !important',
    },
  },
  cancelButton: {
    backgroundColor: '#6BBDBD !important',
    color: 'white !important',
    fontFamily: 'Inter, sans-serif !important',
    fontSize: '16px !important',
    fontWeight: 600,
    textTransform: 'none !important',
    borderRadius: '20px !important',
    padding: '6px 16px !important',
    '&:hover': {
      backgroundColor: '#5AACAC !important',
    },
  },
}));

const SkeletonLoader = () => {
  const classes = useStylesPreguntasDocument();

  return (
    <Container className={classes.scrollableContainer}>
      {[1, 2, 3].map((_, index) => (
        <Accordion key={index} className={classes.accordionPreguntas}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
            className={classes.accordionSummaryPreguntas}
          >
            <Skeleton variant="text" width="80%" height={30} />
          </AccordionSummary>
          <AccordionDetails>
            <Skeleton variant="text" width="60%" />
            <Skeleton variant="text" width="40%" />
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
}
const NewPreguntasDocument = ({ saving_questions = false, chapters_generating = false, loading, chapters = [], error, setQuestions, user, token, max_pages, docInfo, regeneratingQuestions, setRegeneratingQuestions }) => {
  const classes = useStylesPreguntasDocument({ saving_questions });
  const deleteClasses = useStylesDeleteChapter();
  const { userData } = useSubscription();
  const { document_id, selectedDocumentParam } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [infoQuestion, setInfoQuestion] = useState(null);
  const [chapterTitle, setChapterTitle] = useState(null);
  const [showPremium, setShowPremium] = useState(false);
  const [premiumTitleText, setPremiumTitleText] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [expandedChapter, setExpandedChapter] = useState(null);
  const [visibleQuestions, setVisibleQuestions] = useState(4);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [progress, setProgress] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState('New');
  const [openGenerateModal, setOpenGenerateModal] = useState(false);
  const [numberOfQuestions, setNumberOfQuestions] = useState(10);
  const [isGenerating, setIsGenerating] = useState(false);
  const [openRegenerateModal, setOpenRegenerateModal] = useState(false);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState(null);
  const [openOptionsDialog, setOpenOptionsDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [wasRegenerating, setWasRegenerating] = useState(false);

  useEffect(() => {
    const calculatedTotalQuestions = chapters.reduce((sum, chapter) =>
      sum + (chapter.questions ? chapter.questions.length : 0), 0
    );

    setTotalQuestions(calculatedTotalQuestions);
  }, [chapters]);

  useEffect(() => {
    if (wasRegenerating && !regeneratingQuestions) {
      setSnackbarOpen(true);
    }
    setWasRegenerating(regeneratingQuestions);
  }, [regeneratingQuestions]);

  const getPointsTextColor = (points) => {
    if (points === 0) return classes.sinEmpezarText;
    if (points >= 1 && points <= 39) return classes.wrongText;
    if (points >= 40 && points <= 79) return classes.learningText;
    return classes.memorizedText;
  };

  const getCircleColor = (points) => {
    if (points === 0) return '#A0A0A0';
    if (points >= 5) return '#00B564';
    if (points <= -3) return '#AA161D';
    if (points > -3 && points < 5) return '#FCAB32';
    return '#00B564';
  };
  const handleEditClick = (question) => {
    setQuestionToEdit(question);
    setOpenEditDialog(true);
  };
  const handleSaveEdit = async (updatedQuestion) => {
    try {
      // First update the API
      await updateQuestion(token, updatedQuestion.id, updatedQuestion, user);


      // Then update the local state
      setQuestions(prevState => {

        const newState = {
          ...prevState,
          topics: prevState.topics.map(topic => ({
            ...topic,
            questions: topic.questions.map(q => {
              if (q.id === updatedQuestion.id) {
                // Map the options to include is_correct based on correctOption
                const question_options = updatedQuestion.options.map((text, index) => ({
                  id: q.question_options[index].id, // Preserve existing IDs
                  text,
                  is_correct: index === updatedQuestion.correctAnswer
                }));

                return {
                  ...q,  // Keep all existing properties
                  text: updatedQuestion.question,
                  correctOption: updatedQuestion.correctOption,
                  question_options
                };
              }
              return q;
            })
          }))
        };

        return newState;
      });

      setOpenEditDialog(false);
      setQuestionToEdit(null);
    } catch (error) {
      console.error('Error updating question:', error);
    }
  };
  const handleOpen = (question, chapterTitle) => {
    setOpen(true);
    setInfoQuestion(question);
    setChapterTitle(chapterTitle);
  };
  useEffect(() => {
    if (chapters_generating && saving_questions) {
      const interval = setInterval(() => {
        setProgress((oldProgress) => {
          const baseIncrement = 1.11; // Base increment per 500ms
          const randomVariation = 0.2; // Small random variation for natural movement
          const increment = baseIncrement + (Math.random() * randomVariation - randomVariation / 2);

          const newProgress = oldProgress + increment;
          if (newProgress >= 100) {
            return 0; // Reset to 0 when reaching 100%
          }
          return newProgress;
        });
      }, 500);

      return () => clearInterval(interval);
    }
  }, [saving_questions, chapters_generating]);

  const handleClose = () => setOpen(false);

  const handleShowPremium = (titleText) => {
    setShowPremium(true);
    setPremiumTitleText(titleText);
  };

  const handleUnlockQuestions = () => {
    handleShowPremium('questions');
  };

  const noShadowStyle = {
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  };

  if (error) return <Typography color="error">{error}</Typography>;

  const cardStyle = {
    boxShadow: 'none',
    border: 'none',
    marginBottom: '8px', // Add some space between cards
  };

  const getChipClass = (points, max_points) => {
    if ((points / max_points) * 100 >= 80) return classes.memorizedChipPreguntas;
    if ((points / max_points) * 100 >= 40) return classes.learningChipPreguntas;
    if ((points / max_points) * 100 > 0) return classes.wrongChipPreguntas;
    if (points < 0) return classes.wrongChipPreguntas;
    return classes.sinEmpezarChipPreguntas;
  };

  const getChipLabel = (points, max_points) => {
    if ((points / max_points) * 100 >= 80) return " Memorizado";
    if ((points / max_points) * 100 >= 40) return "Aprendiendo";
    if ((points / max_points) * 100 > 0) return "Incorrectas";
    if (points < 0) return "Incorrectas";
    return "Sin Empezar";
  };

  // const getChapterPoints = (chapter) => {
  //   return chapter.subChapters.reduce((total, subChapter) => total + subChapter.points, 0);
  // };

  // const getChapterMaxPoints = (chapter) => {
  //   return chapter.subChapters.reduce((total, subChapter) => total + subChapter.max_points, 0);
  // };




  // Helper function to get point color
  const getPointColor = (points, maxPoints) => {
    const percentage = (points / maxPoints) * 100;
    if (percentage >= 80) return '#00B564';
    if (percentage >= 40) return '#FCAB32';
    if (percentage > 0) return '#AA161D';
    return points < 0 ? '#AA161D' : '#A0A0A0';
  };

  const LoadingMoreChapters = () => (
    <Box display="flex" justifyContent="center" alignItems="center" py={2}>
      <CircularProgress size={24} style={{ marginRight: 16 }} />
      <Typography variant="body2">Loading more chapters...</Typography>
    </Box>
  );

  const handleDeleteClick = (question) => {
    setQuestionToDelete(question);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    if (questionToDelete) {
      setIsDeleting(true);
      try {
        // TODO: Replace with your actual delete question API call
        // await deleteQuestion(token, questionToDelete.id, user);
        setQuestions(prevState => ({
          ...prevState,
          topics: prevState.topics.map(topic => ({
            ...topic,
            questions: topic.questions.filter(q => q.id !== questionToDelete.id)
          }))
        }));
        await deleteQuestion(token, questionToDelete.id, user);
      } catch (error) {
        console.error('Error deleting question:', error);
      } finally {
        setIsDeleting(false);
        setOpenDeleteDialog(false);
        setQuestionToDelete(null);
      }
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
    setQuestionToDelete(null);
  };

  const handleAccordionChange = (chapterIndex) => (event, isExpanded) => {
    setExpandedChapter(isExpanded ? chapterIndex : null);
    setVisibleQuestions(6)
    if (!isExpanded) {
      setVisibleQuestions(6);
    }
  };

  const handleShowMoreQuestions = () => {
    setVisibleQuestions(prev => prev + 5);
  };

  // Instead, sort the chapters directly in the render method
  const sortedChapters = [...chapters].sort((a, b) => a.order - b.order);

  const getFilteredQuestions = () => {
    if (!chapters || chapters.length === 0) return [];

    // Flatten all questions from all chapters into a single array
    const allQuestions = chapters.reduce((acc, chapter) => {
      return [...acc, ...(chapter.questions || []).map(q => ({
        ...q,
        chapterTitle: chapter.title
      }))];
    }, []);

    // First apply the difficulty filter
    let filteredByType = [];
    switch (selectedFilter) {
      case 'New':
        filteredByType = allQuestions.filter(q => q.points === 0 && q.question_answered === false);
        break;
      case 'Learning':
        filteredByType = allQuestions.filter(q => q.points > 0 && q.points < 5 && q.question_answered === true);
        break;
      case 'Incorrect':
        filteredByType = allQuestions.filter(q => q.points <= 0 && q.question_answered === true);
        break;
      case 'Correct':
        filteredByType = allQuestions.filter(q => q.points >= 5 && q.question_answered === true);
        break;
      default:
        filteredByType = allQuestions;
    }

    // Then apply the search filter
    return filteredByType.filter(question =>
      searchQuery === '' ||
      question.text?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const filteredQuestions = getFilteredQuestions();

  const getQuestionsCount = () => {
    if (!chapters || chapters.length === 0) return {
      new: 0,
      learning: 0,
      correct: 0,
      incorrect: 0
    };

    const allQuestions = chapters.reduce((acc, chapter) =>
      [...acc, ...(chapter.questions || [])], []
    );

    return {
      new: allQuestions.filter(q => q.points === 0 && q.question_answered === false).length,
      learning: allQuestions.filter(q => q.points > 0 && q.points < 5).length,
      correct: allQuestions.filter(q => q.points >= 5 && q.question_answered === true).length,
      incorrect: allQuestions.filter(q => q.points <= 0 && q.question_answered === true).length
    };
  };

  const questionCounts = getQuestionsCount();

  const filters = [
    { value: 'New', label: `Sin empezar (${questionCounts.new})`, color: '#A0A0A0' },
    { value: 'Learning', label: `Aprendiendo (${questionCounts.learning})`, color: '#FCAB32' },
    { value: 'Correct', label: `Memorizadas (${questionCounts.correct})`, color: '#00B564' },
    { value: 'Incorrect', label: `Incorrectas (${questionCounts.incorrect})`, color: '#AA161D' }
  ];

  const getBlockedQuestionsCount = () => {
    const totalQuestions = chapters.reduce((sum, chapter) =>
      sum + (chapter.questions ? chapter.questions.length : 0), 0
    );

    if (!totalQuestions) return 0;

    if (max_pages * 12 > totalQuestions && userData.plan_name === "Free")
      return max_pages * 12;

    return 0;
  };

  const blockedQuestionsCount = getBlockedQuestionsCount();

  const handleOpenGenerateModal = () => {
    setOpenGenerateModal(true);
  };

  const handleCloseGenerateModal = () => {
    setOpenGenerateModal(false);
    setNumberOfQuestions(10);
  };

  const handleGenerateMore = async () => {
    setIsGenerating(true);
    setRegeneratingQuestions(true);
    docInfo.added_more_questions_done = true;

    try {
      await fetchGenerateQuestionsCount(token, document_id, numberOfQuestions, user);
    } catch (error) {
      console.error('Error generating questions:', error);
    } finally {
      setIsGenerating(false);
      handleCloseGenerateModal();
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleOpenRegenerateModal = () => {
    setOpenRegenerateModal(true);
  };



  const handleCloseRegenerateModal = () => {
    setOpenRegenerateModal(false);
  };



  const handleRegenerate = () => {
    setIsRegenerating(true);
    fetchRegenerateQuestions(token, document_id, user)
    // Here you would add the API call to regenerate questions
    // For now, just closing the modal
    handleCloseRegenerateModal();
    setIsRegenerating(false);
    docInfo.regenerate_questions_done = true;
  };

  // const handleRegenerateFlashcards = () => {
  //   setIsRegenerating(true);
  //   fetchRegenerateFlashcards(token, document_id, user)
  //   // Here you would add the API call to regenerate flashcards
  //   // For now, just closing the modal
  //   handleCloseRegenerateFlashcardsModal();
  //   setIsRegenerating(false);
  //   docInfo.regenerate_flashcards_done = true;
  // };

  const handleOpenOptionsDialog = () => {
    setOpenOptionsDialog(true);
  };

  const handleCloseOptionsDialog = () => {
    setOpenOptionsDialog(false);
  };

  const handleGenerateWithAI = () => {
    setOpenOptionsDialog(false);
    handleOpenGenerateModal();
  };

  const handleDeterminedQuestions = () => {
    setOpenOptionsDialog(false);
    navigate(`/edit/${document_id}`, { 
      state: { 
        document_id: document_id,
        docInfo: docInfo
      } 
    });
  };

  return (
    <Box sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    }}>
      {showPremium && (
        <Premium
          onClose={() => setShowPremium(false)}
          titleText={premiumTitleText}
        />
      )}

      {open && <PreguntasDocumentPopup
        open={open}
        onClose={() => setOpen(false)}
        infoQuestion={infoQuestion}
        chapterTitle={chapterTitle}
      />}
      {saving_questions && (
        <Box
          sx={{
            backgroundColor: '#F5F5F5',
            border: '1px dashed #E0E0E0',
            borderRadius: '10px',
            // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            padding: '24px',
            marginBottom: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: "0px 30px",
            // maxWidth: '500px',
            width: '90%',
            flexShrink: 0
          }}
        >

          {!chapters_generating ? (
            <>
              <Typography
                variant="h6"
                sx={{
                  color: '#333',
                  marginBottom: '8px',
                  fontWeight: 'bold',
                  fontFamily: "Inter"
                }}
              >
                Generando preguntas
              </Typography>
              <Typography
                sx={{
                  color: '#666',
                  marginBottom: '16px',
                  textAlign: 'center',
                  fontFamily: "Inter"
                }}
              >
                Las preguntas irán apareciendo a medida que estén listas
              </Typography>
              <Box sx={{ width: '50%', marginTop: '8px' }}>
                <LinearProgress
                  variant="determinate"
                  value={totalQuestions / (max_pages * (user.plan_name === "Free" ? 2 : 10)) * 100}



                  sx={{
                    height: '8px',
                    borderRadius: '4px',
                    backgroundColor: '#E0E0E0',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: '#4A90E2'
                    }
                  }}
                />
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>

                <Typography
                  variant="h6"
                  sx={{
                    color: '#333',
                    marginBottom: '8px',
                    fontWeight: 'bold',
                    fontFamily: "Inter"
                  }}
                >
                  Estamos detectando el texto
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: '#666',
                    marginBottom: '16px',
                    textAlign: 'center',
                    fontFamily: "Inter"
                  }}
                >
                  Puede tardar unos minutos si es un documento largo o escaneado
                </Typography>
                <Box sx={{
                  width: '50%',
                  height: '10px',
                  bgcolor: '#8db8c2',
                  borderRadius: '6px',
                  overflow: 'hidden'
                }}>
                  <Box sx={{
                    width: `${progress}%`,
                    height: '100%',
                    bgcolor: '#026277',
                    transition: 'width 0.5s ease'
                  }} />
                </Box>

              </Box>
            </>
          )}
        </Box>
      )}

      {regeneratingQuestions && (
        <Box sx={{
          backgroundColor: '#FFF9F0',
          border: '1px dashed #FFBF60',
          borderRadius: '10px',
          padding: '24px',
          marginBottom: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          margin: "0px 30px",
          width: '90%',
          flexShrink: 0
        }}>
          <Typography
            variant="h6"
            sx={{
              color: '#333',
              marginBottom: '8px',
              fontWeight: 'bold',
              fontFamily: "Inter"
            }}
          >
            Añadiendo preguntas
          </Typography>
          <Typography
            sx={{
              color: '#666',
              marginBottom: '16px',
              textAlign: 'center',
              fontFamily: "Inter"
            }}
          >
            Estamos añadiendo tus preguntas, aparecerán en breve
          </Typography>

        </Box>
      )}

      <Container className={`${classes.scrollableContainer}`}>
        {blockedQuestionsCount > 0 && (
          <Typography
            sx={{
              textDecoration: 'none',
              color: '#AA161D',
              fontFamily: 'Inter, sans-serif',
              fontWeight: 600,
              paddingLeft: '25px',
              marginBottom: '16px',
              fontSize: '14px'
            }}
          >
            Suscríbete para desbloquear {blockedQuestionsCount} preguntas
          </Typography>
        )}

        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 2,
          paddingRight: '25px',
          paddingLeft: '25px',
          marginBottom: '16px'
        }}>
          {!docInfo.regenerate_questions_done && !saving_questions && !chapters_generating && !regeneratingQuestions && (
            <Button
              onClick={handleOpenRegenerateModal}
              startIcon={<RefreshIcon />}
              sx={{
                backgroundColor: '#6BBDBD',
                color: 'white',
                borderRadius: '20px',
                padding: '6px 16px',
                textTransform: 'none',
                fontFamily: 'Fira Sans',
                fontWeight: 600,
                fontSize: '14px',
                '&:hover': {
                  backgroundColor: '#5aa9a9',
                },
              }}
            >
              Regenerar Preguntas
            </Button>
          )}

        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: "10px" }}>
          <Box sx={{
            display: 'flex',
            gap: 2,
            paddingLeft: '25px',
            paddingRight: '25px',
            width: '100%',
            alignItems: 'center'
          }}>
            <TextField
              placeholder="Buscar en las preguntas..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              variant="outlined"
              sx={{
                flex: 1,
                '& .MuiOutlinedInput-root': {
                  '& input': {
                    padding: '8px 12px',
                    fontSize: '14px',
                    fontFamily: 'Inter, sans-serif',
                  }
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: '#666' }} />
                  </InputAdornment>
                ),
                sx: {
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  '& fieldset': {
                    borderColor: '#E2F1F1',
                  },
                  '&:hover fieldset': {
                    borderColor: '#6BBDBD !important',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#6BBDBD !important',
                  },
                  height: '40px',
                }
              }}
            />
            <FormControl sx={{ width: 180, flexShrink: 0 }}>
              <Select
                value={selectedFilter}
                onChange={(e) => setSelectedFilter(e.target.value)}
                displayEmpty
                renderValue={(value) => {
                  const filter = filters.find(f => f.value === value);
                  return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: 8,
                          height: 8,
                          borderRadius: '50%',
                          backgroundColor: filter ? filter.color : '#A0A0A0'
                        }}
                      />
                      <Typography sx={{ 
                        fontSize: '14px',
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 600,
                        color: '#2D2D2D'
                      }}>
                        {filter ? filter.label : 'Filtrar preguntas'}
                      </Typography>
                    </Box>
                  );
                }}
                sx={{
                  height: '40px',
                  backgroundColor: 'white',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E2F1F1',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#6BBDBD',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#6BBDBD',
                  },
                  '& .MuiSelect-select': {
                    paddingY: '8px',
                    display: 'flex',
                    alignItems: 'center',
                  },
                  '& .MuiSvgIcon-root': {
                    color: '#6BBDBD',
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      mt: 1,
                      borderRadius: '8px',
                      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                      '& .MuiMenuItem-root': {
                        padding: '12px 16px',
                        '&:hover': {
                          backgroundColor: '#F7FAFA',
                        },
                        '&.Mui-selected': {
                          backgroundColor: '#E2F1F1',
                          '&:hover': {
                            backgroundColor: '#E2F1F1',
                          },
                        },
                      },
                    },
                  },
                }}
              >
                {filters.map((filter) => (
                  <MenuItem 
                    key={filter.value} 
                    value={filter.value}
                    sx={{
                      fontFamily: 'Inter, sans-serif',
                      fontWeight: 600,
                      fontSize: '14px',
                      color: '#2D2D2D',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: 8,
                          height: 8,
                          borderRadius: '50%',
                          backgroundColor: filter.color,
                        }}
                      />
                      {filter.label}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>


        <Box style={{ height: "100%", overflowY: "auto", overflowX: "hidden", display: 'flex', flexDirection: 'column', marginBottom: "20px" }} className='custom-scrollbar'>
          <Box style={{ flexGrow: 1, paddingBottom: '20px' }}>
            {filteredQuestions.slice(0, visibleQuestions).map((question) => (
              <Card
                variant="outlined"
                className={classes.cardPreguntas}
                key={question.id}
                style={{
                  marginBottom: '16px',
                  border: '1px solid #E2F1F1',
                  borderRadius: '8px',
                  boxShadow: 'none',
                  position: 'relative',
                  backgroundColor: 'white',
                  filter: !question.questionactive && userData.plan_name === "Free" ? 'blur(3px)' : 'none',
                  transition: 'filter 0.3s ease-in-out',
                  marginLeft: '16px',
                  marginRight: '16px',
                  width: 'calc(100% - 32px)'
                }}
              >
                <CardContent sx={{
                  p: 4,
                  '&:last-child': {
                    pb: 4
                  }
                }}>
                  <Typography sx={{
                    fontFamily: 'Fira Sans',
                    fontWeight: 500,
                    color: '#2D2D2D',
                    mb: 2,
                    pr: 8,
                    whiteSpace: 'pre-line'
                  }}>
                    {question.text}
                  </Typography>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: 2
                  }}>
                    <Button
                      onClick={() => handleOpen(question, question.chapterTitle)}
                      disabled={!question.questionactive && userData.plan_name === "Free"}
                      sx={{
                        textTransform: 'none',
                        fontSize: '14px',
                        fontFamily: 'Fira Sans',
                        color: '#6BBDBD',
                        padding: '0',
                        minWidth: 'auto',
                        '&:hover': {
                          backgroundColor: 'transparent',
                          textDecoration: 'underline'
                        }
                      }}
                    >
                      Ver opciones
                    </Button>
                  </Box>
                  <Box sx={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    display: 'flex',
                    gap: 1
                  }}>
                    <IconButton
                      size="small"
                      sx={{
                        color: '#666',
                        '&:hover': { color: '#6BBDBD' },
                        padding: '4px',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditClick(question);
                      }}
                    >
                      <EditIcon sx={{ fontSize: '16px' }} />
                    </IconButton>
                    <IconButton
                      size="small"
                      sx={{
                        color: '#666',
                        '&:hover': { color: '#AA161D' },
                        padding: '4px',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteClick(question);
                      }}
                    >
                      <DeleteIcon sx={{ fontSize: '16px' }} />
                    </IconButton>
                  </Box>
                </CardContent>

                {userData.plan_name === "Free" && !question.questionactive && (
                  <Box
                    position="absolute"
                    top="0"
                    left="0"
                    right="0"
                    bottom="0"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    zIndex="1"
                  >
                    <Button
                      onClick={() => handleShowPremium('questions')}
                      style={{
                        textTransform: 'none',
                        fontSize: '14px',
                        fontWeight: '600',
                        fontFamily: "'Inter', sans-serif",
                        color: 'white',
                        backgroundColor: '#AA161D',
                        padding: '4px 16px',
                        borderRadius: '24px',
                        boxShadow: 'none',
                        width: 'auto'
                      }}
                    >
                      <img
                        src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/LockWhite.svg"
                        alt="Lock"
                        style={{ width: 20, height: 20, marginRight: '8px' }}
                      />
                      Desbloquear
                    </Button>
                  </Box>
                )}
              </Card>
            ))}

          </Box>
          <Box flexGrow={1}>
            {visibleQuestions < filteredQuestions.length && (
              <Button
                onClick={handleShowMoreQuestions}
                style={{
                  textTransform: 'none',
                  fontSize: '14px',
                  fontWeight: '600',
                  fontFamily: "'Inter', sans-serif",
                  color: 'white',
                  backgroundColor: '#6BBDBD',
                  padding: '8px 16px',
                  borderRadius: '24px',
                  boxShadow: 'none',
                  marginTop: '16px',
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
              >
                Mostrar más
              </Button>
            )}
          </Box>


          {!loading && filteredQuestions.length === 0 && (
            <Typography
              variant="body1"
              align="center"
              sx={{ mt: 4, color: '#666' }}
            >
              No hay preguntas que coincidan con el filtro seleccionado
            </Typography>
          )}
        </Box>
      </Container>

      <Dialog
        open={openDeleteDialog}
        onClose={handleDeleteCancel}
        PaperProps={{
          sx: {
            borderRadius: '20px',
            padding: '24px',
            maxWidth: '400px',
            width: '100%'
          }
        }}
      >
        <DialogTitle sx={{
          padding: "0",
          fontFamily: 'Fira Sans',
        }}>
          <Typography sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: 'Fira Sans',
            color: '#000',
            mb: 1
          }}>
            Confirmar eliminación
          </Typography>
          <Typography sx={{
            fontSize: '14px',
            color: '#A0A0A0',
            fontFamily: 'Fira Sans',
            mb: 2
          }}>
            ¿Estás seguro de que quieres eliminar esta pregunta?
          </Typography>
        </DialogTitle>
        <DialogActions sx={{
          padding: "16px 0 0 0",
          marginTop: 'auto'
        }}>
          <Button
            onClick={handleDeleteCancel}
            disabled={isDeleting}
            sx={{
              color: '#A0A0A0',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#666'
              }
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            disabled={isDeleting}
            sx={{
              backgroundColor: '#AA161D',
              color: 'white',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              borderRadius: '20px',
              padding: '6px 16px',
              '&:hover': {
                backgroundColor: '#8A1219'
              },
              minWidth: '100px'
            }}
          >
            {isDeleting ? (
              <CircularProgress size={20} sx={{ color: 'white' }} />
            ) : (
              'Eliminar'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openGenerateModal}
        onClose={handleCloseGenerateModal}
        PaperProps={{
          sx: {
            borderRadius: '20px',
            padding: '24px',
            maxWidth: '500px'
          }
        }}
      >
        <DialogTitle sx={{
          padding: "0",
          fontFamily: 'Fira Sans',
        }}>
          <Typography sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: 'Fira Sans',
            color: '#000',
            mb: 1
          }}>
            Generar más preguntas
          </Typography>
          <Typography sx={{
            fontSize: '14px',
            color: '#A0A0A0',
            fontFamily: 'Fira Sans',
            mb: 2
          }}>
            Selecciona el número de preguntas adicionales que quieres generar
          </Typography>
        </DialogTitle>

        <DialogContent sx={{
          padding: "0",
          '&.MuiDialogContent-root': {
            paddingTop: '10px !important'
          }
        }}>
          <Box sx={{
            width: '100%',
            paddingRight: '8px'
          }}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 1
            }}>
              <Typography sx={{
                fontSize: '14px',
                fontFamily: 'Fira Sans',
                color: '#A0A0A0'
              }}>
                Número de preguntas: {numberOfQuestions}
              </Typography>
            </Box>
            <Slider
              value={numberOfQuestions}
              onChange={(_, newValue) => setNumberOfQuestions(newValue)}
              min={20}
              max={60}
              sx={{
                color: '#6BBDBD',
                '& .MuiSlider-thumb': {
                  height: 24,
                  width: 24,
                  backgroundColor: '#fff',
                  border: '2px solid #6BBDBD',
                  '&:hover, &.Mui-focusVisible': {
                    boxShadow: '0 0 0 8px rgba(107, 189, 189, 0.16)',
                  },
                },
                '& .MuiSlider-rail': {
                  backgroundColor: '#F0F4F4',
                  opacity: 1,
                },
                '& .MuiSlider-track': {
                  height: 4,
                },
                '& .MuiSlider-mark': {
                  backgroundColor: '#F0F4F4',
                  height: 8,
                  width: 1,
                  '&.MuiSlider-markActive': {
                    backgroundColor: '#6BBDBD',
                  },
                },
              }}
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{
          padding: "16px 0 0 0",
          marginTop: 'auto'
        }}>
          <Button
            onClick={handleCloseGenerateModal}
            sx={{
              color: '#A0A0A0',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#666'
              }
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleGenerateMore}
            disabled={isGenerating}
            sx={{
              backgroundColor: '#6BBDBD',
              color: 'white',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              borderRadius: '20px',
              padding: '6px 16px',
              '&:hover': {
                backgroundColor: '#5aa9a9'
              }
            }}
          >
            {isGenerating ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              'Generar'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openRegenerateModal}
        onClose={handleCloseRegenerateModal}
        PaperProps={{
          sx: {
            borderRadius: '20px',
            padding: '24px',
            maxWidth: '500px'
          }
        }}
      >
        <DialogTitle sx={{
          padding: "0",
          fontFamily: 'Fira Sans',
        }}>
          <Typography sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: 'Fira Sans',
            color: '#000',
            mb: 1
          }}>
            Regenerar preguntas
          </Typography>
          <Typography sx={{
            fontSize: '14px',
            color: '#666666',
            fontFamily: 'Fira Sans',
            mb: 2
          }}>
            ¿Estás seguro que quieres regenerar las preguntas? Las preguntas actuales se perderán.
          </Typography>
        </DialogTitle>

        <DialogActions sx={{
          padding: "16px 0 0 0",
          marginTop: 'auto'
        }}>
          <Button
            onClick={handleCloseRegenerateModal}
            sx={{
              color: '#A0A0A0',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#666'
              }
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleRegenerate}
            disabled={isRegenerating}
            sx={{
              backgroundColor: '#FFBF60',
              color: 'white',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              borderRadius: '20px',
              padding: '6px 16px',
              '&:hover': {
                backgroundColor: '#FFBF60'
              }
            }}
          >
            {isRegenerating ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              'Regenerar'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      {openEditDialog && questionToEdit && (
        <EditQuestionPopup
          open={openEditDialog}
          onClose={() => setOpenEditDialog(false)}
          question={questionToEdit}
          onSave={handleSaveEdit}
        />
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity="success" 
          sx={{ 
            width: '100%',
            fontFamily: 'Fira Sans',
            backgroundColor: '#6BBDBD',
            color: 'white',
            '& .MuiAlert-icon': {
              color: 'white'
            }
          }}
        >
          Preguntas generadas
        </Alert>
      </Snackbar>
      <Dialog
        open={openOptionsDialog}
        onClose={handleCloseOptionsDialog}
        PaperProps={{
          sx: {
            borderRadius: '16px',
            padding: '20px',
            maxWidth: '400px',
            width: '100%',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <DialogTitle sx={{
          padding: "0",
          fontFamily: 'Fira Sans',
          marginBottom: '16px'
        }}>
          <Typography sx={{
            fontSize: '18px',
            fontWeight: 600,
            fontFamily: 'Fira Sans',
            color: '#2D2D2D',
          }}>
            Añadir preguntas
          </Typography>
        </DialogTitle>

        <DialogContent sx={{
          padding: 0,
          '&.MuiDialogContent-root': {
            paddingTop: '0 !important',
            overflow: 'hidden'
          }
        }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Button
              onClick={handleGenerateWithAI}
              sx={{
                backgroundColor: '#F7FAFA',
                color: '#2D2D2D',
                fontFamily: 'Fira Sans',
                textTransform: 'none',
                borderRadius: '12px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                border: '2px solid #E2F1F1',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  backgroundColor: '#E2F1F1',
                  transform: 'translateY(-2px)',
                  boxShadow: '0px 4px 12px rgba(107, 189, 189, 0.2)'
                }
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Box sx={{
                  backgroundColor: '#6BBDBD',
                  borderRadius: '8px',
                  width: '40px',
                  height: '40px',
                  minWidth: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '12px'
                }}>
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2L2 7L12 12L22 7L12 2Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M2 17L12 22L22 17" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M2 12L12 17L22 12" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </Box>
                <Typography sx={{ 
                  fontWeight: 600, 
                  fontSize: '16px',
                  fontFamily: 'Fira Sans'
                }}>
                  Todo el documento
                </Typography>
              </Box>
              <Typography sx={{ 
                fontSize: '14px', 
                color: '#666666', 
                textAlign: 'left',
                pl: '52px',
                fontFamily: 'Fira Sans'
              }}>
                Genera centena de preguntas automáticamente de todo el documento 
              </Typography>
            </Button>

            <Button
              onClick={handleDeterminedQuestions}
              sx={{
                backgroundColor: '#FFF9F0',
                color: '#2D2D2D',
                fontFamily: 'Fira Sans',
                textTransform: 'none',
                borderRadius: '12px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                border: '2px solid #FFE5B3',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  backgroundColor: '#FFE5B3',
                  transform: 'translateY(-2px)',
                  boxShadow: '0px 4px 12px rgba(255, 191, 96, 0.2)'
                }
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <Box sx={{
                  backgroundColor: '#FFBF60',
                  borderRadius: '8px',
                  width: '40px',
                  height: '40px',
                  minWidth: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '12px'
                }}>
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M18.5 2.50023C18.8978 2.1024 19.4374 1.87891 20 1.87891C20.5626 1.87891 21.1022 2.1024 21.5 2.50023C21.8978 2.89805 22.1213 3.43762 22.1213 4.00023C22.1213 4.56284 21.8978 5.1024 21.5 5.50023L12 15.0002L8 16.0002L9 12.0002L18.5 2.50023Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </Box>
                <Typography sx={{ 
                  fontWeight: 600, 
                  fontSize: '16px',
                  fontFamily: 'Fira Sans'
                }}>
                  Tema específico
                </Typography>
              </Box>
              <Typography sx={{ 
                fontSize: '14px', 
                color: '#666666', 
                textAlign: 'left',
                pl: '52px',
                fontFamily: 'Fira Sans'
              }}>
                Crea preguntas de secciones específicas del documento
              </Typography>
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default NewPreguntasDocument;