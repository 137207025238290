import { getCsrfToken } from './TokenApi'
import { getAuthToken } from './SupabaseAuth'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export async function fetchUserAllFiles(token, method = "GET", user) {
    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/all_files/?user_id=${user_id}`;
    try {
        const csrfToken = method !== "GET" ? getCsrfToken() : null;
        const headers = {
            'Authorization': `Bearer ${token}`,
        };

        const requestOptions = {
            method: method,
            headers: headers,
        };

        const response = await fetch(apiUrl, requestOptions);
        return await response.json();
    } catch (error) {
        console.error('Error fetching/updating user information:', error);
        throw error;
    }
}

export async function fetchUserFiles(token,method="GET", folder_id, formData, user, detailed = true) {
    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/files/${folder_id}/?detail_view=${detailed}&user_id=${user_id}`;
    try {
        const csrfToken = getCsrfToken();
        const headers = {
            'Authorization': `Bearer ${token}`,
        };

        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: method,
            headers: headers,
        };

        if (formData) {
            if (formData instanceof FormData) {
                requestOptions.body = formData;
            } else {
                headers['Content-Type'] = 'application/json';
                requestOptions.body = JSON.stringify(formData);
            }
        }

        const response = await fetch(apiUrl, requestOptions);
        return await response.json();
    } catch (error) {
        console.error('Error fetching/updating user information:', error);
        throw error;
    }
}

export async function fetchUserFile(token, method = "GET", folder_id, document_id, requestBody = null, user) {
    // Replace with your Django API endpoint URL
    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/file/${folder_id}/${document_id}/?user_id=${user_id}`; // Update with your actual API endpoint
    try {
        const csrfToken = method !== "GET" ? getCsrfToken() : null; // Get CSRF token for non-GET requests
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        };

        // If the method requires CSRF protection, add the CSRF token to the headers
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: method,
            headers: headers,
        };

        if (method === "POST" || method === "PUT" && requestBody !== null) {
            requestOptions.body = JSON.stringify(requestBody);
        }


        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        if (method === "DELETE") {
            return true
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching/updating user information:', error);
        throw error;
    }
}

export async function fetchGenerateSummary(token, method = "POST", document_id, requestBody = null, user) {
    // Replace with your Django API endpoint URL
    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/summary/${document_id}/?user_id=${user_id}`; // Update with your actual API endpoint
    try {
        const csrfToken = method !== "GET" ? getCsrfToken() : null; // Get CSRF token for non-GET requests
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        };

        // If the method requires CSRF protection, add the CSRF token to the headers
        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: method,
            headers: headers,
        };

        if (method === "POST" || method === "PUT" && requestBody !== null) {
            requestOptions.body = JSON.stringify(requestBody);
        }


        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        if (method === "DELETE") {
            return true
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching/updating user information:', error);
        throw error;
    }
}

export async function fetchRegenerateQuestions(token, document_id, user) {
    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/regenerate_questions/${document_id}/?user_id=${user_id}`;
    try {
        const csrfToken = getCsrfToken(); // Get CSRF token since this is a POST request
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };

        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: 'POST',
            headers: headers,
        };

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error regenerating questions:', error);
        throw error;
    }
}
export async function fetchRegenerateFlashcards(token, document_id, user) {
    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/regenerate_flashcards/${document_id}/?user_id=${user_id}`;
    try {
        const csrfToken = getCsrfToken(); // Get CSRF token since this is a POST request
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };

        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: 'POST',
            headers: headers,
        };

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error regenerating flashcards:', error);
        throw error;
    }
}

export async function fetchGenerateQuestionsCount(token, document_id, numQuestions, user) {
    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/generation_questions_with_count/${document_id}/?user_id=${user_id}`;
    try {
        const csrfToken = getCsrfToken(); // Get CSRF token since this is a POST request
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };

        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                num_questions: numQuestions
            })
        };

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error generating questions:', error);
        throw error;
    }
}

export async function updateDocumentSummary(token, document_id, context, user) {
    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/summary/${document_id}/?user_id=${user_id}`;
    try {
        const csrfToken = getCsrfToken(); // Get CSRF token since this is a PUT request
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };

        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify({
                context: context
            })
        };

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error updating document summary:', error);
        throw error;
    }
}

export async function fetchGenerateFlashcardsCount(token, document_id, numFlashcards, user) {
    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/generation_flashcards_with_count/${document_id}/?user_id=${user_id}`;
    try {
        const csrfToken = getCsrfToken(); // Get CSRF token since this is a POST request
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };

        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                num_flashcards: numFlashcards
            })
        };

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error generating flashcards:', error);
        throw error;
    }
}
