import React from 'react';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: 139, // initial width
        height: 140, // initial height
        borderRadius: 16,
        backgroundColor: "#02627733",
        [theme.breakpoints.down('xs')]: {
            width: '100%', // full width on extra small devices
            height: 'auto', // auto height on extra small devices
            padding: theme.spacing(2),
        },
    },
    icon: {
        // Styling for the icon can be adjusted here if needed
    },
    text: {
        marginTop: theme.spacing(1),
    },
}));

const CreateFolderButton = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Paper className={classes.root} elevation={3}>
            <AddIcon className={classes.icon} />
            <Typography variant="body2" className={classes.text}>
                {t("new_folder")}
            </Typography>
        </Paper>
    );
};

export default CreateFolderButton;
