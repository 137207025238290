import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  IconButton,
  Card,
  CardContent,
  Checkbox,
  TextField,
  Divider
} from '@mui/material';
import { Close, Edit, Save } from '@mui/icons-material';

const FlashcardsDialog = ({
  open,
  onClose,
  flashcards,
  selectedFlashcards,
  onFlashcardSelect,
  editingIndex,
  editedFlashcard,
  onEditStart,
  onEditCancel,
  onEditSave,
  onEditChange,
  formatTextWithSolutions,
  onAdd
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "20px",
        }
      }}
    >
      <DialogTitle sx={{
        padding: "16px 24px 0",
        fontFamily: 'Fira Sans',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
      }}>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 600, fontFamily: 'Fira Sans' }}>
            Tarjetas Generadas
          </Typography>
          <Typography 
            variant="subtitle1" 
            sx={{ 
              color: '#666',
              fontSize: '14px',
              marginTop: '4px',
              marginBottom: '8px',
              fontFamily: 'Fira Sans'
            }}
          >
            Selecciona las tarjetas que quieras añadir
          </Typography>
        </Box>
        <IconButton
          onClick={onClose}
          sx={{
            marginRight: -1,
            marginTop: -1,
            color: '#666666'
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "16px 24px" }}>
        {flashcards.map((flashcard, index) => (
          <Card key={index} sx={{ 
            marginBottom: '12px',
            border: '1px solid #E2F1F1',
            borderRadius: '8px',
            boxShadow: 'none',
            '&:last-child': {
              marginBottom: 0
            }
          }}>
            <CardContent sx={{ padding: '16px !important' }}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1.5 }}>
                <Checkbox
                  checked={selectedFlashcards.includes(index)}
                  onChange={() => onFlashcardSelect(index)}
                  sx={{
                    color: '#6BBDBD',
                    '&.Mui-checked': {
                      color: '#6BBDBD',
                    },
                    padding: '4px',
                  }}
                />
                <Box sx={{ flex: 1 }}>
                  {editingIndex === index ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                      {flashcard.type === 'cloze' ? (
                        <TextField
                          fullWidth
                          value={editedFlashcard.text}
                          onChange={(e) => onEditChange({
                            ...editedFlashcard,
                            text: e.target.value
                          })}
                          variant="standard"
                          multiline
                          sx={{
                            '& .MuiInput-underline:before': { borderBottom: 'none' },
                            '& .MuiInput-underline:hover:before': { borderBottom: 'none' },
                            '& .MuiInput-underline:after': { borderBottomColor: '#E2F1F1' },
                            fontFamily: 'Fira Sans',
                          }}
                        />
                      ) : (
                        <>
                          <TextField
                            fullWidth
                            value={editedFlashcard.question}
                            onChange={(e) => onEditChange({
                              ...editedFlashcard,
                              question: e.target.value
                            })}
                            variant="standard"
                            sx={{
                              '& .MuiInput-underline:before': { borderBottom: 'none' },
                              '& .MuiInput-underline:hover:before': { borderBottom: 'none' },
                              '& .MuiInput-underline:after': { borderBottomColor: '#E2F1F1' },
                              fontFamily: 'Fira Sans',
                            }}
                          />
                          <Divider sx={{ margin: '12px 0' }} />
                          <TextField
                            fullWidth
                            value={editedFlashcard.answer}
                            onChange={(e) => onEditChange({
                              ...editedFlashcard,
                              answer: e.target.value
                            })}
                            variant="standard"
                            multiline
                            sx={{
                              '& .MuiInput-underline:before': { borderBottom: 'none' },
                              '& .MuiInput-underline:hover:before': { borderBottom: 'none' },
                              '& .MuiInput-underline:after': { borderBottomColor: '#E2F1F1' },
                              fontFamily: 'Fira Sans',
                            }}
                          />
                        </>
                      )}
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 1 }}>
                        <IconButton
                          size="small"
                          onClick={onEditCancel}
                          sx={{ color: '#666' }}
                        >
                          <Close fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => onEditSave(index)}
                          sx={{ color: '#66BB6A' }}
                        >
                          <Save fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        {flashcard.type === 'cloze' ? (
                          <Typography sx={{ 
                            fontFamily: 'Fira Sans',
                            fontWeight: 500,
                            marginBottom: '12px',
                            flex: 1
                          }}>
                            {formatTextWithSolutions(flashcard.text)}
                          </Typography>
                        ) : (
                          <>
                            <Box sx={{ flex: 1 }}>
                              <Typography sx={{ 
                                fontFamily: 'Fira Sans',
                                fontWeight: 500,
                                marginBottom: '12px'
                              }}>
                                {flashcard.question}
                              </Typography>
                              <Divider sx={{ margin: '12px 0' }} />
                              <Typography sx={{ 
                                fontFamily: 'Fira Sans',
                                color: '#666'
                              }}>
                                {formatTextWithSolutions(flashcard.answer)}
                              </Typography>
                            </Box>
                          </>
                        )}
                        <IconButton
                          size="small"
                          onClick={() => onEditStart(index, flashcard)}
                          sx={{ 
                            color: '#666',
                            '&:hover': { color: '#6BBDBD' },
                            padding: '4px',
                            ml: 1
                          }}
                        >
                          <Edit fontSize="small" />
                        </IconButton>
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        ))}
      </DialogContent>
      <DialogActions sx={{ padding: "12px 24px 16px", gap: 2 }}>
        <Button
          onClick={onClose}
          sx={{
            color: '#666',
            textTransform: 'none',
            fontFamily: 'Fira Sans'
          }}
        >
          Cerrar
        </Button>
        <Button
          variant="contained"
          onClick={onAdd}
          disabled={selectedFlashcards.length === 0}
          sx={{
            backgroundColor: '#6BBDBD',
            color: 'white',
            borderRadius: '24px',
            textTransform: 'none',
            fontFamily: 'Fira Sans',
            '&:hover': {
              backgroundColor: '#5AACAC'
            }
          }}
        >
          Añadir tarjetas
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FlashcardsDialog; 