import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  text: {
    fontFamily: 'Inter, sans-serif',
  },
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: '20px',

    },
  },
});

const PopupFinishExam = ({ open, onClose, onConfirm }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog}>
      <DialogTitle>
        <Typography variant="h6" style={{ fontFamily: 'Inter, sans-serif', fontWeight: 'bold' }}>¿Terminar examen? 🤔</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography style={{ fontFamily: 'Inter, sans-serif' }}>
          ¿Estás seguro que quieres corregir el examen? Las preguntas no respondidas <strong>se considerarán incorrectas</strong>.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'center', marginBottom: "10px" }}>
        <Button variant="contained" color="info" onClick={onClose} sx={{
          backgroundColor: '#6BBDBD',
          color: 'white',
          textTransform: 'none',
          fontWeight: '600',
          fontFamily: "'Inter', sans-serif",
          borderRadius: '24px',
          padding: '8px 24px',
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '8px',
          boxShadow: 'none',

          '&:hover': {
            backgroundColor: '#6BBDBD',
          },
        }}>
          Seguir estudiando
        </Button>
        <Button variant="contained" color="error" onClick={onConfirm} sx={{
          backgroundColor: '#FCAB32 !important',
          color: 'white',
          textTransform: 'none',
          fontWeight: '600',
          fontFamily: "'Inter', sans-serif",
          borderRadius: '24px',
          padding: '8px 24px',
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '8px',
          boxShadow: 'none',

          '&:hover': {
            backgroundColor: '#FCAB32 !important',
          },
        }}>
          Corregir
        </Button>


      </DialogActions>
    </Dialog>
  );
};

export default PopupFinishExam;
