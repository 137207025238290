import React, { useState, useEffect } from 'react';
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { ProgressBar } from '@react-pdf-viewer/core';
import { Typography } from '@mui/material';

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "./pdf.css"


import { getCsrfToken } from '../../Api/TokenApi'
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// ...import CSS...

const PDFViewerComponent = ({ docInfo, token, small = false }) => {
    const [pdfFile, setPdfFile] = useState(null);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    useEffect(() => {
        // Function to fetch and decrypt the PDF file
        const fetchPDF = async () => {
            try {
                const csrfToken = getCsrfToken()
                const response = await fetch(`${BACKEND_URL}/api_v1/decrypt-pdf/`, {
                    method: 'POST',
                    body: JSON.stringify({ fileUrl: docInfo.url_stored }),
                    headers: { 'Content-Type': 'application/json', 'X-CSRFToken': csrfToken, 'Authorization': `Bearer ${token}` }
                });

                const data = await response.blob();
                setPdfFile(URL.createObjectURL(data));
            } catch (error) {
                console.error('Error fetching decrypted PDF:', error);
            }
        };
        if (docInfo.url_stored) {
            fetchPDF();
        }
    }, [docInfo.url_stored]);

    useEffect(() => {
        // Reset the PDF file when the URL stored changes
        setPdfFile(null);
    }, [docInfo.url_stored]);

    return (
        // <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.js">
        //     <div style={{ height: "60vw", paddingBottom: "40px" }}>
        //         {pdfFile && <Viewer fileUrl={pdfFile} plugins={[defaultLayoutPluginInstance]} renderLoader={(percentages) => (
        //             <div style={{ width: '240px' }}>
        //                 <ProgressBar progress={Math.round(percentages)} />
        //             </div>
        //         )} />}
        //     </div>
        // </Worker>

        pdfFile ? (
            small ? (
                <iframe
                    src={`${pdfFile}#navpanes=0&scrollbar=1&zoom=50`} // 100% zoom
                    type="application/pdf"
                    title="PDF Viewer"
                    style={{
                        width: "100%",
                        height: "100%",
                        border: 'none',
                        overflow: 'auto'
                    }}
                    frameBorder="0"
                    loading="lazy"
                />
            ) : (
                <iframe
                    src={`${pdfFile}#navpanes=0&scrollbar=0`}
                    type="application/pdf"
                    title="PDF Viewer"
                    style={{ width: "100%", height: "100%", border: 'none' }}
                    frameBorder="0"
                    loading="lazy"
                />


            )) : <Typography>Cargando el documento ...</Typography>



    );
};


export default PDFViewerComponent;

