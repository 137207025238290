
import { getCsrfToken } from '../../Api/TokenApi'
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export async function createMemorise(token, requestBody, user) {
    const user_id = user['id'];

    const apiUrl = `${BACKEND_URL}/api_v1/memorise/?user_id=${user_id}`;
    try {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            "X-CSRFToken": getCsrfToken()
        };

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(requestBody),
        };

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error creating memorise:', error);
        throw error;
    }
}

export async function fetchMemorise(token, guid, user) {
    const user_id = user['id'];

    const apiUrl = `${BACKEND_URL}/api_v1/memorise/${guid}/?user_id=${user_id}`;
    try {
        const headers = {
            'Authorization': `Bearer ${token}`,
        };

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching memorise:', error);
        throw error;
    }
}

export async function updateMemorise(token, guid, requestBody, user) {
    const user_id = user['id'];

    const apiUrl = `${BACKEND_URL}/api_v1/memorise/${guid}/?user_id=${user_id}`;
    try {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            "X-CSRFToken": getCsrfToken()

        };

        const requestOptions = {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(requestBody),
        };

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error updating memorise:', error);
        throw error;
    }
}

export async function fetchAllMemorises(token, user) {
    const user_id = user['id'];

    const apiUrl = `${BACKEND_URL}/api_v1/memorise/?user_id=${user_id}`;
    try {
        const headers = {
            'Authorization': `Bearer ${token}`,
        };

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching memorises:', error);
        throw error;
    }
}
