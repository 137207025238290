import React from 'react';
import { Grid, Container, Paper, Typography } from '@mui/material';
import { Skeleton } from '@mui/lab';

function ConfigSkeleton() {
    return (
        <Container style={{ marginLeft: 400, marginTop: 40, height: 'calc(90vh - 40px)', overflowY: 'auto' }}>
            <Grid container spacing={3} style={{ height: '100%', flexWrap: 'nowrap', display: 'flex' }}>
                {/* User Info Section */}
                <Grid item style={{ paddingRight: '60px', flexGrow: 2, flexBasis: 0, maxWidth: 'initial' }}>
                    <Paper elevation={3} style={{ marginTop: '20px', background: 'white', borderRadius: '16px', padding: '16px', boxShadow: 'none' }}>
                        {/* User Details */}
                        <Skeleton variant="circle" width={56} height={56} style={{ marginBottom: '10px' }} />
                        <Skeleton variant="text" style={{ marginBottom: '20px', width: '60%' }} />
                        <Skeleton variant="text" style={{ marginBottom: '20px', width: '80%' }} />
                        <Skeleton variant="text" style={{ marginBottom: '20px', width: '90%' }} />

                        {/* Event Settings */}
                        <Skeleton variant="rectangular" height={150} style={{ marginBottom: '30px' }} />

                        {/* Sharing Settings */}
                        <Skeleton variant="rectangular" height={150} style={{ marginBottom: '30px' }} />
                    </Paper>
                </Grid>

                {/* Plan and Usage Section */}
                <Grid item style={{ flexGrow: 3, flexBasis: 0, maxWidth: 'initial' }}>
                    <Paper elevation={3} style={{ marginTop: '150px', marginRight: '200px', background: 'white', borderRadius: '16px', padding: '16px', boxShadow: 'none' }}>
                        {/* Plan Details */}
                        <Skeleton variant="text" style={{ marginBottom: '20px', width: '50%' }} />
                        <Skeleton variant="text" style={{ marginBottom: '30px', width: '70%' }} />

                        {/* Usage Stats */}
                        <Skeleton variant="rectangular" height={100} style={{ marginBottom: '20px' }} />
                        <Skeleton variant="rectangular" height={100} style={{ marginBottom: '20px' }} />

                        {/* Buttons */}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '200px', marginTop: '240px' }}>
                            <Skeleton variant="rectangular" width={120} height={50} style={{ marginRight: '20px' }} />
                            <Skeleton variant="rectangular" width={120} height={50} />
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default ConfigSkeleton;
