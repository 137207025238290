import { makeStyles } from '@mui/styles';

const cardsStyles = makeStyles((theme) => ({

    card: {
        borderRadius: 20,
        backgroundColor: '#E2E8F0',
        boxShadow: 'none',
        cursor: "pointer",
        width: "256px"

    },

    cardVideo: {
        borderRadius: 20,
        backgroundColor: '#E2E8F0',
        boxShadow: 'none',
        cursor: 'pointer',
        width: '256px',
        height: '151px',
        overflow: 'hidden',
        border: "2px solid #026277"
    },

    mediaVideo: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',

    },
    media: {
        height: 100,
        backgroundColor: props => props.color || '#E2E8F0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px 8px 0 0',
    },
    icon: {
        width: '60%',
        height: '60%',
    },
    content: {
        backgroundColor: '#F1F5F9',
        padding: '8px 14px !important',
    },
    title: {
        fontWeight: 600,
        fontSize: '14px',
    },
    description: {
        color: '#4A5568',
        fontSize: '10px',
    },

}));

export default cardsStyles;
