import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { 
  Modal, 
  Button, 
  Box, 
  IconButton, 
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  Divider,
  Checkbox
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './PDFUploader.css';

// Function to format text with solutions
const formatTextWithSolutions = (text) => {
  if (!text) return '';
  
  // Match text within square brackets
  const regex = /\[(.*?)\]/g;
  const parts = text.split(regex);
  
  return parts.map((part, index) => {
    // Every odd index is a solution (was inside brackets)
    if (index % 2 === 1) {
      return (
        <span
          key={index}
          style={{
            backgroundColor: '#E2F1F1',
            color: '#6BBDBD',
            padding: '0 4px',
            borderRadius: '4px',
            fontWeight: 500
          }}
        >
          {part}
        </span>
      );
    }
    return part;
  });
};

// Set up the worker for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const options = {
  cMapUrl: 'cmaps/',
  standardFontDataUrl: 'standard_fonts/',
};

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: '12px',
    maxWidth: '900px',
    width: '100%'
  },
  dialogTitle: {
    color: '#000000',
    fontSize: '24px',
    fontStyle: 'normal',
    fontFamily: 'Fira Sans',
    fontWeight: 600,
    padding: '24px 32px 0 32px'
  },
  dialogContent: {
    padding: '24px 32px',
    maxHeight: '60vh',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#6BBDBD',
      borderRadius: '4px',
      '&:hover': {
        background: '#5AACAC',
      },
    },
  },
  selectedText: {
    color: '#4A5568',
    fontSize: '16px',
    fontFamily: 'Fira Sans',
    fontWeight: 400,
    marginBottom: '8px',
    lineHeight: '1.5',
    padding: '16px',
    backgroundColor: '#F8F9FA',
    borderRadius: '8px',
    border: '1px solid #E7E7E7'
  },
  characterLimit: {
    color: '#6B7280',
    fontSize: '14px',
    fontFamily: 'Fira Sans',
    marginTop: '8px',
    fontStyle: 'italic'
  },
  dialogActions: {
    padding: '16px 32px 24px 32px',
    display: 'flex',
    gap: '16px'
  },
  primaryButton: {
    width: '100%',
    borderRadius: '24px',
    textTransform: 'none',
    boxShadow: "none",
    fontWeight: 500,
    fontSize: '16px',
    padding: '12px 24px',
    backgroundColor: '#6BBDBD',
    color: 'white',
    fontFamily: 'Fira Sans',
    '&:hover': {
      backgroundColor: '#5AACAC'
    }
  },
  secondaryButton: {
    borderRadius: '24px',
    textTransform: 'none',
    boxShadow: "none",
    fontWeight: 500,
    fontSize: '16px',
    padding: '12px 24px',
    backgroundColor: 'transparent',
    color: '#666666',
    fontFamily: 'Fira Sans',
    '&:hover': {
      backgroundColor: 'rgba(102, 102, 102, 0.1)'
    }
  },
  dummyCard: {
    marginBottom: '16px',
    border: '1px solid #E2F1F1',
    borderRadius: '8px',
    boxShadow: 'none',
    backgroundColor: 'white',
  },
  dummyCardContent: {
    padding: '24px !important',
  },
  dummyText: {
    fontFamily: 'Fira Sans',
    fontWeight: 500,
    color: '#2D2D2D',
    marginBottom: '16px',
  },
  dummyAnswer: {
    fontFamily: 'Fira Sans',
    color: '#666',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  correctAnswer: {
    color: '#66BB6A',
    fontWeight: 700,
  },
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '32px',
    gap: '16px',
  },
  loadingText: {
    fontFamily: 'Fira Sans',
    color: '#666',
    fontSize: '14px',
  }
}));

const PDFUploader = () => {
  const classes = useStyles();
  const [pdfFile, setPdfFile] = useState(null);
  const [selectedText, setSelectedText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [scale, setScale] = useState(1.0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectionError, setSelectionError] = useState('');
  const [isTextTruncated, setIsTextTruncated] = useState(false);
  
  // New state variables for dummy data dialogs
  const [showQuestionsDialog, setShowQuestionsDialog] = useState(false);
  const [showFlashcardsDialog, setShowFlashcardsDialog] = useState(false);
  const [isGeneratingContent, setIsGeneratingContent] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [selectedFlashcards, setSelectedFlashcards] = useState([]);

  // Dummy data for questions
  const dummyQuestions = [
    {
      text: "¿Cuál es el principal objetivo del texto seleccionado?",
      options: [
        "Analizar el impacto económico",
        "Describir el proceso histórico",
        "Explicar las consecuencias sociales",
        "Presentar datos estadísticos"
      ],
      correctAnswer: 1
    },
    {
      text: "Según el texto, ¿qué factor fue más determinante?",
      options: [
        "El desarrollo tecnológico",
        "La influencia política",
        "Los cambios sociales",
        "Las condiciones económicas"
      ],
      correctAnswer: 2
    },
    {
      text: "¿Qué conclusión se puede extraer del fragmento?",
      options: [
        "La necesidad de más investigación",
        "La importancia de la planificación",
        "El impacto a largo plazo",
        "La relevancia histórica"
      ],
      correctAnswer: 3
    }
  ];

  // Dummy data for flashcards
  const dummyFlashcards = [
    {
      text: "Complete la siguiente afirmación: El proceso descrito en el texto se caracteriza principalmente por [su complejidad y múltiples etapas]",
      type: "cloze"
    },
    {
      question: "¿Cuál es el concepto principal discutido en el texto?",
      answer: "El texto discute la evolución histórica y su impacto en la sociedad moderna",
      type: "question"
    },
    {
      text: "Los factores más importantes mencionados son [económicos] y [sociales]",
      type: "cloze"
    }
  ];

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === 'application/pdf') {
      setIsLoading(true);
      setError(null);
      const fileUrl = URL.createObjectURL(file);
      setPdfFile(fileUrl);
    } else {
      alert('Please select a valid PDF file');
    }
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setCurrentPage(1);
    setIsLoading(false);
  };

  const handleDocumentLoadError = (error) => {
    console.error('Error loading PDF:', error);
    setError('Error loading PDF. Please try again.');
    setIsLoading(false);
  };

  const handleTextSelection = (event) => {
    event.preventDefault();
    const selection = window.getSelection();
    let selectedText = selection.toString().trim();
    
    if (selectedText) {
      setIsTextTruncated(selectedText.length > 10000);
      if (selectedText.length > 10000) {
        selectedText = selectedText.substring(0, 10000);
      }
      
      setSelectedText(selectedText);
      setIsModalOpen(true);
      selection.removeAllRanges();
    }
  };

  const handleGenerateQuestions = () => {
    setIsModalOpen(false);
    setIsGeneratingContent(true);
    // Simulate API call
    setTimeout(() => {
      setIsGeneratingContent(false);
      setShowQuestionsDialog(true);
    }, 2000);
  };

  const handleGenerateCards = () => {
    setIsModalOpen(false);
    setIsGeneratingContent(true);
    // Simulate API call
    setTimeout(() => {
      setIsGeneratingContent(false);
      setShowFlashcardsDialog(true);
    }, 2000);
  };

  const zoomIn = () => {
    setScale(prevScale => Math.min(prevScale + 0.1, 2.0));
  };

  const zoomOut = () => {
    setScale(prevScale => Math.max(prevScale - 0.1, 0.5));
  };

  const nextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, numPages));
  };

  const previousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const renderLoading = () => (
    <div className="loading-container">
      <CircularProgress />
      <p>Loading PDF...</p>
    </div>
  );

  const renderError = () => (
    <div className="error-container">
      <p className="error-message">{error}</p>
      <Button variant="contained" onClick={() => setPdfFile(null)}>
        Try Again
      </Button>
    </div>
  );

  const handleQuestionSelect = (index) => {
    setSelectedQuestions(prev => {
      if (prev.includes(index)) {
        return prev.filter(i => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  return (
    <div className="pdf-uploader-container">
      <input
        type="file"
        accept=".pdf"
        onChange={handleFileChange}
        className="file-input"
      />
      
      <div 
        className="pdf-viewer-container" 
        onMouseUp={handleTextSelection}
      >
        {isLoading && renderLoading()}
        {error && renderError()}
        {!error && (
          <>
            <div className="pdf-content">
              <div className="controls-container">
                <div className="zoom-controls">
                  <IconButton onClick={zoomOut} size="small">
                    <ZoomOutIcon />
                  </IconButton>
                  <span>{Math.round(scale * 100)}%</span>
                  <IconButton onClick={zoomIn} size="small">
                    <ZoomInIcon />
                  </IconButton>
                </div>
              </div>
              <Document
                file={pdfFile}
                onLoadSuccess={handleDocumentLoadSuccess}
                onLoadError={handleDocumentLoadError}
                loading={renderLoading}
                className="pdf-document"
                options={options}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}_${scale}`}
                    pageNumber={index + 1}
                    scale={scale}
                    className="pdf-page"
                    renderTextLayer={true}
                    renderAnnotationLayer={true}
                    onLoadSuccess={() => {
                      const textLayer = document.querySelector(`.react-pdf__Page__textContent[data-page-number="${index + 1}"]`);
                      if (textLayer) {
                        textLayer.style.color = 'transparent';
                        textLayer.style.userSelect = 'text';
                        textLayer.addEventListener('mouseup', handleTextSelection);
                      }
                    }}
                    onUnloadSuccess={() => {
                      const textLayer = document.querySelector(`.react-pdf__Page__textContent[data-page-number="${index + 1}"]`);
                      if (textLayer) {
                        textLayer.removeEventListener('mouseup', handleTextSelection);
                      }
                    }}
                  />
                ))}
              </Document>
            </div>
            {selectionError && (
              <Typography className={classes.errorMessage}>
                {selectionError}
              </Typography>
            )}
          </>
        )}
      </div>

      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="text-selection-dialog"
      >
        <DialogTitle 
          id="text-selection-dialog"
          className={classes.dialogTitle}
          disableTypography
        >
          Texto seleccionado
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.selectedText}>
            {selectedText}
          </Typography>
          {isTextTruncated && (
            <Typography className={classes.characterLimit}>
              * Se ha limitado la selección a 10000 caracteres
            </Typography>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            variant="contained"
            className={classes.primaryButton}
            onClick={handleGenerateQuestions}
            style={{ width: '100%' }}
          >
            Generar Preguntas
          </Button>
          <Button
            variant="contained"
            className={classes.secondaryButton}
            onClick={handleGenerateCards}
            style={{ 
              width: '100%',
              backgroundColor: '#E2F1F1',
              color: '#6BBDBD'
            }}
          >
            Generar Tarjetas
          </Button>
        </DialogActions>
      </Dialog>

      {/* Loading Dialog */}
      <Dialog
        open={isGeneratingContent}
        classes={{ paper: classes.dialogPaper }}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent className={classes.loadingContainer}>
          <CircularProgress size={40} sx={{ color: '#6BBDBD' }} />
          <Typography className={classes.loadingText}>
            Generando contenido...
          </Typography>
        </DialogContent>
      </Dialog>

      {/* Questions Dialog */}
      <Dialog
        open={showQuestionsDialog}
        onClose={() => {
          setShowQuestionsDialog(false);
          setSelectedQuestions([]);
        }}
        classes={{ paper: classes.dialogPaper }}
        maxWidth="md"
        fullWidth
        scroll="paper"
      >
        <DialogTitle 
          className={classes.dialogTitle}
          disableTypography
        >
          <Typography variant="h6" style={{ fontFamily: 'Fira Sans', fontWeight: 600 }}>
            Preguntas Generadas
          </Typography>
          <Typography 
            variant="subtitle1" 
            style={{ 
              fontFamily: 'Fira Sans', 
              color: '#666', 
              fontSize: '14px',
              marginTop: '8px'
            }}
          >
            Selecciona las preguntas que quieras añadir
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {dummyQuestions.map((question, index) => (
            <Card key={index} className={classes.dummyCard}>
              <CardContent className={classes.dummyCardContent}>
                <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                  <Checkbox
                    checked={selectedQuestions.includes(index)}
                    onChange={() => handleQuestionSelect(index)}
                    sx={{
                      color: '#6BBDBD',
                      '&.Mui-checked': {
                        color: '#6BBDBD',
                      },
                    }}
                  />
                  <Box sx={{ flex: 1 }}>
                    <Typography className={classes.dummyText}>
                      {question.text}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                      {question.options.map((option, optIndex) => (
                        <Box
                          key={optIndex}
                          className={`${classes.dummyAnswer} ${optIndex === question.correctAnswer ? classes.correctAnswer : ''}`}
                        >
                          <Checkbox
                            checked={optIndex === question.correctAnswer}
                            disabled
                            sx={{
                              color: optIndex === question.correctAnswer ? '#66BB6A' : '#666',
                              '&.Mui-checked': {
                                color: '#66BB6A',
                              },
                            }}
                          />
                          <Typography
                            component="span"
                            className={optIndex === question.correctAnswer ? classes.correctAnswer : classes.dummyAnswer}
                          >
                            {String.fromCharCode(65 + optIndex)}. {option}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          ))}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Box sx={{ display: 'flex', gap: '16px', justifyContent: 'flex-end', width: '100%' }}>
            <Button
              variant="contained"
              className={classes.secondaryButton}
              onClick={() => {
                setShowQuestionsDialog(false);
                setSelectedQuestions([]);
              }}
              style={{ 
                backgroundColor: '#E2F1F1',
                color: '#6BBDBD'
              }}
            >
              Cerrar
            </Button>
            <Button
              variant="contained"
              className={classes.primaryButton}
              onClick={() => {
                // Handle adding selected questions here
                setShowQuestionsDialog(false);
                setSelectedQuestions([]);
              }}
              disabled={selectedQuestions.length === 0}
              style={{ width: 'auto' }}
            >
              Añadir preguntas
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      {/* Flashcards Dialog */}
      <Dialog
        open={showFlashcardsDialog}
        onClose={() => {
          setShowFlashcardsDialog(false);
          setSelectedFlashcards([]);
        }}
        classes={{ paper: classes.dialogPaper }}
        maxWidth="md"
        fullWidth
        scroll="paper"
      >
        <DialogTitle 
          className={classes.dialogTitle}
          disableTypography
        >
          <Typography variant="h6" style={{ fontFamily: 'Fira Sans', fontWeight: 600 }}>
            Tarjetas Generadas
          </Typography>
          <Typography 
            variant="subtitle1" 
            style={{ 
              fontFamily: 'Fira Sans', 
              color: '#666', 
              fontSize: '14px',
              marginTop: '8px'
            }}
          >
            Selecciona las tarjetas que quieras añadir
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {dummyFlashcards.map((flashcard, index) => (
            <Card key={index} className={classes.dummyCard}>
              <CardContent className={classes.dummyCardContent}>
                <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                  <Checkbox
                    checked={selectedFlashcards.includes(index)}
                    onChange={() => {
                      setSelectedFlashcards(prev => {
                        if (prev.includes(index)) {
                          return prev.filter(i => i !== index);
                        } else {
                          return [...prev, index];
                        }
                      });
                    }}
                    sx={{
                      color: '#6BBDBD',
                      '&.Mui-checked': {
                        color: '#6BBDBD',
                      },
                    }}
                  />
                  <Box sx={{ flex: 1 }}>
                    {flashcard.type === 'cloze' ? (
                      <Typography className={classes.dummyText}>
                        {formatTextWithSolutions(flashcard.text)}
                      </Typography>
                    ) : (
                      <>
                        <Typography className={classes.dummyText}>
                          {flashcard.question}
                        </Typography>
                        <Divider style={{ margin: '16px 0' }} />
                        <Typography className={classes.dummyAnswer}>
                          {formatTextWithSolutions(flashcard.answer)}
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>
              </CardContent>
            </Card>
          ))}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Box sx={{ display: 'flex', gap: '16px', justifyContent: 'flex-end', width: '100%' }}>
            <Button
              variant="contained"
              className={classes.secondaryButton}
              onClick={() => {
                setShowFlashcardsDialog(false);
                setSelectedFlashcards([]);
              }}
              style={{ 
                backgroundColor: '#E2F1F1',
                color: '#6BBDBD'
              }}
            >
              Cerrar
            </Button>
            <Button
              variant="contained"
              className={classes.primaryButton}
              onClick={() => {
                // Handle adding selected flashcards here
                setShowFlashcardsDialog(false);
                setSelectedFlashcards([]);
              }}
              disabled={selectedFlashcards.length === 0}
              style={{ width: 'auto' }}
            >
              Añadir tarjetas
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PDFUploader; 