import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
} from '@mui/material';

const DeleteSchemaModal = ({ open, onClose, onConfirm, schemaName }) => {
    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle sx={{ 
                fontFamily: "'Fira Sans', sans-serif",
                fontWeight: 600
            }}>
                Confirmar eliminación
            </DialogTitle>
            <DialogContent>
                <Typography>
                    ¿Estás seguro que deseas eliminar el esquema "{schemaName}"? Esta acción no se puede deshacer.
                </Typography>
            </DialogContent>
            <DialogActions sx={{ padding: '16px' }}>
                <Button 
                    onClick={onClose}
                    sx={{
                        color: '#666666',
                        '&:hover': {
                            backgroundColor: '#f5f5f5'
                        }
                    }}
                >
                    Cancelar
                </Button>
                <Button 
                    onClick={onConfirm}
                    variant="contained" 
                    color="error"
                    sx={{
                        backgroundColor: '#d32f2f !important',
                        '&:hover': {
                            backgroundColor: '#9a0007 !important'
                        }
                    }}
                >
                    Eliminar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteSchemaModal; 