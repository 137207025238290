import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  Slider,
  CircularProgress
} from '@mui/material';

const GenerateQuestionsDialog = ({
  open,
  onClose,
  numberOfQuestions,
  setNumberOfQuestions,
  onGenerate,
  isGenerating
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '20px',
          padding: '24px',
          maxWidth: '500px'
        }
      }}
    >
      <DialogTitle sx={{
        padding: "0",
        fontFamily: 'Fira Sans',
      }}>
        <Typography sx={{
          fontSize: '16px',
          fontWeight: 'bold',
          fontFamily: 'Fira Sans',
          color: '#000',
          mb: 1
        }}>
          Generar más preguntas
        </Typography>
        <Typography sx={{
          fontSize: '14px',
          color: '#A0A0A0',
          fontFamily: 'Fira Sans',
          mb: 2
        }}>
          Selecciona el número de preguntas adicionales que quieres generar
        </Typography>
      </DialogTitle>

      <DialogContent sx={{
        padding: "0",
        '&.MuiDialogContent-root': {
          paddingTop: '10px !important'
        }
      }}>
        <Box sx={{
          width: '100%',
          paddingRight: '8px'
        }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 1
          }}>
            <Typography sx={{
              fontSize: '14px',
              fontFamily: 'Fira Sans',
              color: '#A0A0A0'
            }}>
              Número de preguntas: {numberOfQuestions}
            </Typography>
          </Box>
          <Slider
            value={numberOfQuestions}
            onChange={(_, newValue) => setNumberOfQuestions(newValue)}
            min={0}
            max={100}
            step={20}
            marks={false}
            valueLabelDisplay="auto"
            style={{ color: '#6BBDBD' }}
            size="small"
            sx={{
              color: '#6BBDBD',
              '& .MuiSlider-thumb': {
                height: 24,
                width: 24,
                backgroundColor: '#fff',
                border: '2px solid #6BBDBD',
                '&:hover, &.Mui-focusVisible': {
                  boxShadow: '0 0 0 8px rgba(107, 189, 189, 0.16)',
                },
              },
              '& .MuiSlider-rail': {
                backgroundColor: '#F0F4F4',
                opacity: 1,
              },
              '& .MuiSlider-track': {
                height: 4,
              },
              '& .MuiSlider-mark': {
                backgroundColor: '#F0F4F4',
                height: 8,
                width: 1,
                '&.MuiSlider-markActive': {
                  backgroundColor: '#6BBDBD',
                },
              },
            }}
          />
        </Box>
      </DialogContent>

      <DialogActions sx={{
        padding: "16px 0 0 0",
        marginTop: 'auto'
      }}>
        <Button
          onClick={onClose}
          disabled={isGenerating}
          sx={{
            color: '#A0A0A0',
            fontFamily: 'Fira Sans',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: 'transparent',
              color: '#666'
            }
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={onGenerate}
          disabled={isGenerating}
          sx={{
            backgroundColor: '#6BBDBD',
            color: 'white',
            fontFamily: 'Fira Sans',
            textTransform: 'none',
            borderRadius: '20px',
            padding: '6px 16px',
            '&:hover': {
              backgroundColor: '#5aa9a9'
            },
            minWidth: '100px'
          }}
        >
          {isGenerating ? (
            <CircularProgress size={24} sx={{ color: 'white' }} />
          ) : (
            'Generar'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenerateQuestionsDialog; 