import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Grid,
  Box,
  IconButton
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import TestOptionsModal from '../../Documents/TestOptionsModal';

import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import StyleIcon from '@mui/icons-material/Style';
import HomePopupOnboarding from './HomePopupOnboarding';
import ModalParent from "../../AllModalsActions/ParentModal";
import { useNavigate } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 24px',
    flexGrow: 1
  },

  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.08)',
    border: '1px solid #E7E7E7',
    cursor: 'pointer',
    overflow: 'hidden',
    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0px 8px 25px 0px rgba(0, 0, 0, 0.1)'
    }
  },
  cardHeader: {
    padding: '20px 20px 0 20px'
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    '& h6': {
      fontWeight: 600,
      fontSize: '16px',
      fontFamily: 'Fira Sans'
    }
  },
  cardDescription: {
    color: '#4A5568',
    fontSize: '14px',
    fontFamily: 'Fira Sans',
    fontWeight: 400,
    marginTop: '5px',
    marginBottom: '10px'
  },
  cardContent: {
    padding: '10px 20px 20px 20px',
    marginTop: 'auto'
  },
  button: {
    fontFamily: 'Fira Sans',
    width: '100%',
    borderRadius: '24px',
    textTransform: 'none',
    boxShadow: "none",
    fontWeight: 500,
    fontSize: '14px',
    backgroundColor: '#6BBDBD',
    color: 'white',
    '&:hover': {
      backgroundColor: '#5AACAC !important'
    }
  },
  secondaryButton: {
    width: '100%',
    fontFamily: 'Fira Sans',
    boxShadow: "none",
    borderRadius: '24px',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '14px',
    backgroundColor: '#E2F1F1 !important',
    color: '#6BBDBD !important',
    '&:hover': {
      backgroundColor: '#D1E5E5 !important'
    }
  }
}));

const MainActions = () => {
  const classes = useStyles();
  const [openTutorial, setOpenTutorial] = React.useState(false);
  const [openTestOptions, setOpenTestOptions] = React.useState(false);
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [descriptionOption, setDescription] = React.useState("");
  const [buttonOption, setButton] = React.useState("");
  const [placeholderMessage, setPlaceholderMessage] = React.useState("");
  const [widthModal, setWidthModal] = React.useState("600px");
  const [typeModal, setTypeModal] = React.useState("upload");
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [progressBar, setProgressBar] = React.useState(0);
  const [showUpgrade, setShowUpgrade] = React.useState(false);
  const [titleOption, setTitle] = useState("");
  const navigate = useNavigate();
  const handleTutorialClick = () => {
    setOpenTutorial(true);
  };

  const handleCloseTutorial = () => {
    setOpenTutorial(false);
  };

  const handleUploadFile = () => {
    setModalOpen(true);
    setTitle("Sube tus documentos");
    setDescription("Genera material de estudio con tus documentos");
    setButton(t("upload_button"));
    setPlaceholderMessage("");
    setWidthModal("600px")
    setTypeModal("upload");
  };

  return (
    <>
      {modalOpen && (
        <ModalParent
          open={modalOpen}
          title={titleOption}
          description={descriptionOption}
          sendButton={buttonOption}
          placeholdMessage={placeholderMessage}
          type={typeModal}
          onClose={() => setModalOpen(false)}
          setShowSpinner={setShowSpinner}
          setShowAlert={setShowAlert}
          setMessage={setMessage}
          setProgressBar={setProgressBar}
          setShowUpgrade={setShowUpgrade}
          width={widthModal}
        />
      )}
      <TestOptionsModal
        open={openTestOptions}
        onClose={() => setOpenTestOptions(false)}
      />
      <Box className={classes.root}>
        {/* Welcome Banner */}

        {/* Main Actions */}
        <Box mb={6}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
            <Typography variant="h5" gutterBottom style={{
              color: '#000000',
              fontSize: '24px',
              fontStyle: 'normal',
              fontFamily: 'Fira Sans',
              fontWeight: "bold",
              letterSpacing: '-0.225px',
              margin: 0
            }}>
              ¿Qué quieres hacer hoy?
            </Typography>
            <Typography
              onClick={handleTutorialClick}
              style={{
                color: '#6BBDBD',
                fontSize: '14px',
                fontFamily: 'Fira Sans',
                cursor: 'pointer'
              }}
            >
              Ver tutorial
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <Card className={classes.card}>
                <CardHeader
                  className={classes.cardHeader}
                  title={
                    <Box className={classes.cardTitle}>
                      <CloudUploadIcon style={{ color: '#6BBDBD' }} />
                      <Typography variant="h6" style={{ fontFamily: 'Fira Sans' }}>Subir documento</Typography>
                    </Box>
                  }
                  subheader={
                    <Typography className={classes.cardDescription}>
                      Sube un nuevo documento a estudiar, resumir o crear esquemas
                    </Typography>
                  }
                />
                <CardContent className={classes.cardContent}>
                  <Button
                    variant="contained"
                    className={classes.button}
                    startIcon={<CloudUploadIcon />}
                    onClick={handleUploadFile}
                  >
                    Subir documento
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Card className={classes.card}>
                <CardHeader
                  className={classes.cardHeader}
                  title={
                    <Box className={classes.cardTitle}>
                      <StyleIcon style={{ color: '#6BBDBD' }} />
                      <Typography variant="h6" style={{ fontFamily: 'Fira Sans' }}>Estudiar tarjetas de memoria</Typography>
                    </Box>
                  }
                  subheader={
                    <Typography className={classes.cardDescription}>
                     Estudia con tarjetas de memoria para retener la información de forma activa
                    </Typography>
                  }
                />
                <CardContent className={classes.cardContent}>
                  <Button
                    variant="contained"
                    className={classes.secondaryButton}
                    onClick={() => navigate("/new-flashcards")}
                  >
                    Estudiar tarjetas
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Card className={classes.card}>
                <CardHeader
                  className={classes.cardHeader}
                  title={
                    <Box className={classes.cardTitle}>
                      <MenuBookIcon style={{ color: '#6BBDBD' }} />
                      <Typography variant="h6" style={{ fontFamily: 'Fira Sans' }}>Hacer test</Typography>
                    </Box>
                  }
                  subheader={
                    <Typography className={classes.cardDescription}>
                      Pon a prueba tus conocimientos con preguntas test de tu temario
                    </Typography>
                  }
                />
                <CardContent className={classes.cardContent}>
                  <Button
                    variant="contained"
                    className={classes.secondaryButton}
                    onClick={() => setOpenTestOptions(true)}
                  >
                    Comenzar examen
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>

        <HomePopupOnboarding
          open={openTutorial}
          onClose={handleCloseTutorial}
        />
      </Box>
    </>
  );
};

export default MainActions;
