// ProtectedRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSession, useSupabaseClient } from '@supabase/auth-helpers-react';
import Spinner from './NewComponents/Spinner';

const ProtectedRoute = ({ children }) => {
    const session = useSession();
    const supabase = useSupabaseClient();
    const [isLoading, setIsLoading] = useState(true);
    const [hasSession, setHasSession] = useState(false);

    useEffect(() => {
        const checkSession = async () => {
            try {
                const { data: { session: localSession } } = await supabase.auth.getSession();
                setHasSession(!!localSession);
            } catch (error) {
                console.error('Error checking session:', error);
                setHasSession(false);
            } finally {
                setIsLoading(false);
            }
        };

        checkSession();
    }, [supabase.auth]);

    if (isLoading) {
        return <Spinner />;
    }

    if (!hasSession && !session) {
        return <Navigate to="/login" />;
    }

    return children;
};

export default ProtectedRoute;
