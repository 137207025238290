import {
    Box,
    Grid,
    IconButton,
    Paper,
    TextField,
    Typography,
    Button,
    Container,
    LinearProgress,
    Chip,


} from '@mui/material';
import studentSvg from '../Documents/preguntasbloquedas.svg';
import React, { useEffect, useState, useCallback } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'; // Add these imports for arrow icons
import PopupFinishExam from '../Exam/Styles/PopupFinishExam';
import useStyles from '../Documents/Styles/useStylePreguntasDocument';

import Skeleton from '@mui/material/Skeleton';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from "react-router-dom";
import { useAuth } from "../Auth/Authhandle";
import SmallAlert from "../NewComponents/Modal/smallAlert";
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { fetchAllCorrectAIRemarks, fetchExamId, fetchRestartExam } from "../Api/ExamsApi";
import Document from "../Documents/Document";
import Questions from "./Questions/QuestionsView";
import "./Styles/style.css";
import useExamViewStyles from "./Styles/useExamViewStyles";
import CorrectInfo from "./CorrectInfo";
import { fetchQuestionAnswer, fetchQuestionAnswerId } from "../Api/QuestionsApi";
import ModalParent from "../AllModalsActions/ParentModal";
import * as handle from "../Main/handle";
import FullScreenLoader from "../NewComponents/LoadingExam";
import NeedCredits from "../NoCreditsModal/NeedCredits";
import PopUpCorrection from "./PopUpCorrection";
import animationData from '../Images/singing-contract.json';
import Lottie from 'lottie-react';
import { set } from "date-fns";
import { useCredits } from "../Context/CreditsContext";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import JustificationPopup from './JustificationPopup';
import { streamJustification } from './QdrantJustification';
import { useSubscription } from "../Context/SubscriptionContext";
import ExamCorrection from './ExamCorrection';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;




function ExamView() {
    const classes = useExamViewStyles();
    const classes2 = useStyles();


    const { updateCredits, credits } = useCredits();

    const { token, user, isAuthenticated } = useAuth(); // Use the hook at the top level

    const [messages, setMessages] = useState([]); // Move messages state up
    const { userData } = useSubscription();

    // Add state for exam scoring settings
    const [scoringSettings, setScoringSettings] = useState({
        incorrectPoints: 0,
        unansweredDeducts: false
    });
    
    //Alert
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [folderSelectedGuid, setFolderSelectedGuid] = useState("");
    const [documentSelectedGuid, setDocumentSelectedGuid] = useState("");
    const { t } = useTranslation();
    const [examData, setExamData] = useState({ questions: [] });
    const [pdfData, setPDFData] = useState({ questions: [] });
    const [answers, setAnswers] = useState([]);
    const [isPdfExam, setIsPdfExam] = useState(false)
    const { exam_id } = useParams();
    const [corrected, setCorrected] = useState(false)
    const [allCorrected, setAllCorrected] = useState(false)
    const [needReset, setNeedReset] = useState(false)
    const [improve, setImprove] = useState([])
    const [finalScore, setFinalScore] = useState("")
    const [listScore, setListScore] = useState([])
    // Managing state of quesiton 
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Add this state

    const [validatedQuestions, setValidatedQuestions] = useState({});
    const navigate = useNavigate();

    const [showResultsPopUp, setShowResultsPopUp] = useState(false);
    const [isLoadingResults, setIsLoadingResults] = useState(false);
    // Assuming modalOpenUpload is a boolean to control the visibility of a modal
    const [modalOpenUpload, setModalOpenUpload] = useState(false);
    const [titleOptionUpload, setTitleOptionUpload] = useState('');
    const [descriptionOptionUpload, setDescriptionOptionUpload] = useState('');
    const [buttonOptionUpload, setButtonOptionUpload] = useState('');
    const [placeholderMessageUpload, setPlaceholderMessageUpload] = useState('');
    const [typeUpload, setTypeUpload] = useState('');
    const [showSpinnerUpload, setShowSpinnerUpload] = useState(false);
    const [showAlertUpload, setShowAlertUpload] = useState(false);
    const [progressBar, setProgressBar] = useState(false);
    const [showUpgrade, setShowUpgrade] = useState(false);
    const [messageUpload, setMessageUpload] = useState('');
    const [correctingExam, setCorrectingExam] = useState(false);
    const [width, setWidthModal] = useState("");
    const [isPreparingExam, setIsPreparingExam] = useState(false);
    const [examQuestionFlag, setExamQuestionFlag] = useState(false);
    // const [questionStatus, setQuestionStatus] = useState(null);

    // Add a new state to track initial load
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    async function fetchExam() {
        if (token && user) {
            try {

                const examDataFetch = await fetchExamId(token, "GET", exam_id, user);
                if (examDataFetch['is_pdf_exam']) {

                    setIsPdfExam(examDataFetch['is_pdf_exam'])

                }
                
                // Load scoring settings from localStorage if they exist
                const storedSettings = localStorage.getItem(`exam_${exam_id}_scoring`);
                if (storedSettings) {
                    setScoringSettings(JSON.parse(storedSettings));
                }
                
                setImprove(examDataFetch['notes'])
                setPDFData(examDataFetch)
                setEditedTitle(examDataFetch['title'])
                const scores_ = examDataFetch.questions.map(question =>
                    question.question_answers[0]?.punctuation || 0
                );
                const totalScore = scores_.reduce((acc, score) => acc + score, 0);

                setListScore(scores_)
                setExamData(examDataFetch)
                setFinalScore(Math.round(examDataFetch['final_score']))
                const initialAnswers = {};
                const initialValidated = {};
                examDataFetch.questions.forEach(question => {
                    initialAnswers[question.guid] = {
                        answer: question.question_answers[0].question_answer || '',
                    };
                    initialValidated[question.guid] = question.question_answers[0].punctuation_active || false;
                });

                setAnswers(initialAnswers);
                setValidatedQuestions(initialValidated);




            } catch (error) {
                console.error(error);
            }
        }
    }
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            await fetchExam();
            
            // Check for temporary scoring settings and save with proper exam_id
            const tempScoringSettings = localStorage.getItem('temp_exam_scoring');
            if (tempScoringSettings) {
                localStorage.setItem(`exam_${exam_id}_scoring`, tempScoringSettings);
                localStorage.removeItem('temp_exam_scoring');
                
                // Update state with these settings
                setScoringSettings(JSON.parse(tempScoringSettings));
            }
            
            setIsLoading(false);
        }
        fetchData();
    }, [exam_id, token, user]);

    const handleCorrectCurrentQuestion = async () => {

        const question = examData.questions[currentQuestionIndex];
        const answer = answers.find(a => a.hasOwnProperty(question.guid)) || {};
        const payload = {
            answer: answer[question.guid]
        };

        try {
            // Make your API call with the payload containing all question IDs and answers
            let corrected_answer = await fetchQuestionAnswerId(token, "POST", question.guid, user, payload);

        } catch (error) {
            console.error('Failed to submit answers:', error);
            // Handle error
        }

    };

    const [justificationEnabled, setJustificationEnabled] = useState({});

    const handleQuestionCorrection = async (questionId) => {
        setValidatedQuestions((prevValidated) => {
            const newValidated = { ...prevValidated, [questionId]: true };
            return newValidated;
        });

        // Enable justification for this question
        setJustificationEnabled(prev => ({
            ...prev,
            [questionId]: true
        }));

        // Check if all questions are validated after the state update
        const allQuestionsValidated = examData.questions.every(question =>
            validatedQuestions[question.guid] || question.guid === questionId
        );

        if (allQuestionsValidated) {
            // All questions are validated, set correctingExam to true
            setCorrectingExam(true)

            // Call the API since all questions are now validated
            try {
                let improveCorrection = await fetchAllCorrectAIRemarks(token, "POST", exam_id, user);
                setImprove(improveCorrection['notes']);
                
                // Get updated exam data
                const examDataFetch = await fetchExamId(token, "GET", exam_id, user);
                console.log("MY examDataFetch", examDataFetch)
                // Calculate custom score instead of using the one from the backend
                const customFinalScore = calculateCustomScore(examDataFetch.questions);
                setExamData(examDataFetch)
                const scores_ = examDataFetch.question_answers.map(question => question.punctuation);
                const recalculatedPoints = scores_.reduce((total, score) => {
                    if (score > 0) {
                        return total + 5;
                    } else if (score === 0) {
                        return total - 3;
                    }
                    return total;
                }, 0);
                setPoints(recalculatedPoints);
                
                setFinalScore(Math.round(customFinalScore));
                setCorrectingExam(false)
                fetchExam(); // Call the fetchData function

                setListScore(scores_)

                setAllCorrected(true);
                setShowResultsPopUp(true)
                // setIsLoadingResults(true)

            } catch (error) {
                console.error("Failed to fetch AI correction remarks:", error);
            }
        }
    };

    // Add a function to calculate custom score based on scoring settings
    const calculateCustomScore = (questions) => {
        const totalQuestions = questions.length;
        let totalScore = 0;
        
        questions.forEach(question => {
            const answer = question.question_answers[0];
            if (answer.punctuation > 0) {
                // Correct answer
                totalScore += 1;
            } else if (answer.question_answer && answer.question_answer.trim() !== '') {
                // Incorrect answer with an attempt
                totalScore += scoringSettings.incorrectPoints;
            } else if (scoringSettings.unansweredDeducts) {
                // Unanswered question, deduct points if setting enabled
                totalScore += scoringSettings.incorrectPoints;
            }
        });
        
        // Calculate percentage score (0-10 scale)
        return Math.max(0, (totalScore / totalQuestions) * 10);
    };

    useEffect(() => {
        // Only check for validated questions during initial load
        if (isInitialLoad && examData.questions && examData.questions.length > 0) {
            const allQuestionsValidated = examData.questions.every(
                question => validatedQuestions[question.guid]
            );
            setAllCorrected(allQuestionsValidated);
            setIsInitialLoad(false); // Mark initial load as complete
        }
    }, [validatedQuestions, examData.questions, isInitialLoad]);


    // const handleAnswerChange = (questionId, answer_enter) => {
    //     console.log("answer enter ", answer_enter)
    //     console.log("answer ", answers)
    //     setAnswers((prevAnswers) => ({
    //         ...prevAnswers,
    //         [questionId]: answer_enter,
    //     }));
    // };
    const handleAnswerChange = (questionId, answer_enter) => {
        setNeedReset(false)
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: {
                ...prevAnswers[questionId],
                answer: answer_enter,
            },
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Submit logic goes here
    };


    //TODO, QUE SURTI EL POP UP QUAN NO TENIM RESPOSTES

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleAnswers = async () => {
        const processedAnswers = Object.keys(answers).map(questionId => {
            return { [questionId]: answers[questionId].answer || '' };
        });

        const hasEmptyAnswer = processedAnswers.some(answer =>
            Object.values(answer)[0] === ''
        );

        if (hasEmptyAnswer) {
            setIsPopupOpen(true);
        } else {
            proceedWithCorrection(processedAnswers);
        }
    };



    const proceedWithCorrection = async (processedAnswers) => {
        setCorrectingExam(true);
        setNeedReset(false);

        const payload = {
            questions_answers: processedAnswers,
            questions_substraction: scoringSettings.incorrectPoints,
            question_not_answered_substraction: scoringSettings.unansweredDeducts
        };

        try {
            // Make your API call with the payload containing all question IDs and answers
            const examCorrection = await fetchQuestionAnswer(token, "POST", exam_id, user, payload);

            const examDataFetchProcessed = examCorrection['data_exam']
            setExamData(examDataFetchProcessed)
            console.log("2. MY examDataFetchProcessed", examDataFetchProcessed)
            const customFinalScore = calculateCustomScore(examDataFetchProcessed.questions);

            // Calculate custom score based on scoring settings
            
            // setImprove(examCorrection['correction_notes'])
            const scores_ = examDataFetchProcessed.question_answers.map(question => question.punctuation);
            const totalScore = scores_.reduce((acc, score) => acc + score, 0);
            // Recalculate points based on scores
            const recalculatedPoints = scores_.reduce((total, score) => {
                if (score > 0) {
                    return total + 5;
                } else if (score === 0) {
                    return total - 3;
                }
                return total;
            }, 0);

            setPoints(recalculatedPoints);
            setListScore(scores_)
            setFinalScore(Math.round(customFinalScore));

            const correctedData = examDataFetchProcessed.questions.reduce((acc, question) => {
                acc[question.guid] = {
                    correctAnswer: question.question_answer,
                    punctuation: question.question_answers[0].punctuation
                };
                return acc;
            }, {});
            fetchExam(); // Call the fetchData function
            setAllCorrected(true);
            setCorrectingExam(false);
            setShowResultsPopUp(true)


        } catch (error) {
            console.error('Failed to submit answers:', error);
            // Handle error
        }
    };



    const handleConfirmCorrection = () => {
        setIsPopupOpen(false);
        proceedWithCorrection(Object.keys(answers).map(questionId => {
            return { [questionId]: answers[questionId].answer || '' };
        }));
    };

    const handleNextQuestion = () => {
        setCurrentQuestionIndex((prevIndex) => Math.min(prevIndex + 1, examData.questions.length - 1));
    };

    const handlePreviousQuestion = () => {
        setCurrentQuestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    // Title editing
    const [editMode, setEditMode] = useState(false);
    const [editedTitle, setEditedTitle] = useState(""); // Local state for edited title

    useEffect(() => {
        setEditedTitle(examData.title); // Update local state when examData changes
    }, [exam_id]);

    const handleTitleChange = (event) => {
        setEditedTitle(event.target.value);
    };

    const handleBlur = async () => {
        setEditMode(false);
        // Placeholder for update logic
        // Update examData.title or perform API call to save the updated title
        let payload = { "title": editedTitle }
        const response = fetchExamId(token, "PUT", exam_id, user, payload)
        setExamData(prevExamData => ({
            ...prevExamData,
            title: editedTitle
        }));
        // Assuming updateExamTitle is a function to update the title
        // updateExamTitle(editedTitle);
    };
    const [isLoadingReset, setIsLoadingReset] = useState(false);
    const [showCredits, setShowCredits] = useState(false);
    const resetExam = async () => {
        if (credits === -100 || credits >= 40) {
            try {
                setIsLoadingReset(true);

                const data = await fetchRestartExam(token, "POST", exam_id, user)

                window.location.href = `/exam/${data["new_exam_id"]}`;
                // setIsLoadingReset(false) is no longer needed as the page will reload
            } catch (error) {
                console.error('Failed to reset exam:', error);
                // Handle error
                setIsLoadingReset(false);
            }
        } else {
            setShowCredits(true)
        }
    };
    const docInfo = React.useMemo(() => ({
        url_stored: pdfData['pdf_link'] // or any dynamic value
    }), [pdfData['pdf_link']]);
    const handleCreateExam = () => {
        navigate('/new-exam');
    };
    const truncateText = (text, maxLength) => {
        if (!text) {
            return ""; // Return an empty string if text is null, undefined, or empty
        }
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        }
        return text;
    };
    useEffect(() => {
        if (isPreparingExam) {
            const timer = setTimeout(() => {
                setIsPreparingExam(false);
            }, 4000);
            return () => clearTimeout(timer); // Cleanup the timer if the component unmounts or isPreparingExam changes
        }
    }, [isPreparingExam]);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (correctingExam) {



            const interval = setInterval(() => {
                setProgress((oldProgress) => {
                    const increment = Math.random() * 4; // This determines the speed and variability of the progress
                    const newProgress = oldProgress + increment;
                    if (newProgress >= 100) {
                        // When progress exceeds 100, restart from 0 immediately
                        return 0;
                    } else {
                        return newProgress;
                    }
                });
            }, 300);

            return () => clearInterval(interval); // Clean up the interval when the component unmounts or dependencies change

        }

    }, [correctingExam]);

    const updateQuestionData = (questionGuid, updatedData) => {
        setExamData((prevExamData) => {
            const updatedQuestions = prevExamData.questions.map((question) => {
                if (question.guid === questionGuid) {
                    // Merge the updated data with the existing question data
                    return {
                        ...question,
                        ...updatedData,
                        flag: updatedData.flag, // Explicitly update the flag
                        question_answers: question.question_answers.map((answer, index) => {
                            if (index === 0) {
                                return {
                                    ...answer,
                                    ...updatedData.question_answers?.[0],
                                    punctuation: updatedData.punctuation,
                                    punctuation_active: updatedData.punctuation_active,
                                };
                            }
                            return answer;
                        }),
                    };
                }
                return question;
            });

            return {
                ...prevExamData,
                questions: updatedQuestions,
            };
        });

        // Update scores after question data is updated
        updateExamScores();
    };

    const getChipClass = (points) => {
        if (points === 0) return classes.sinEmpezarChip;
        if (points >= 1 && points <= 39) return classes.wrongChip;
        if (points >= 40 && points <= 79) return classes.learningChip;
        return classes.memorizedChip;
    };

    // Calculate progress


    const [points, setPoints] = useState(0);
    const [totalPoints, setTotalPoints] = useState(0);
    const [initialPointsCalculated, setInitialPointsCalculated] = useState(false);

    const calculateInitialPoints = useCallback(() => {
        if (!initialPointsCalculated && examData.questions && examData.questions.length > 0) {
            let calculatedPoints = 0;
            examData.questions.forEach(question => {
                if (question.question_answers && question.question_answers[0].question_answer != "") {
                    const punctuation = question.question_answers[0].punctuation || 0;
                    calculatedPoints += punctuation > 0 ? 5 : -3;
                }
            });
            setPoints(calculatedPoints);
            setInitialPointsCalculated(true);
        }
    }, [examData, initialPointsCalculated]);

    const calculatePoints = useCallback((punctuation) => {
        setPoints(prevPoints => {
            const pointChange = punctuation > 0 ? 5 : -3;
            const newPoints = prevPoints + pointChange;
            return newPoints;
        });
    }, []);

    useEffect(() => {
        const totalPoints = examData.questions ? examData.questions.length * 5 : 0;
        setTotalPoints(totalPoints);
        calculateInitialPoints();
    }, [examData, calculateInitialPoints]);

    useEffect(() => {
    }, [examData]);

    // Add this near your other state declarations
    const [correctedQuestionsCount, setCorrectedQuestionsCount] = useState(0);

    // Update this useEffect to calculate corrected questions instead of points
    useEffect(() => {
        if (examData.questions && examData.questions.length > 0) {
            const correctedCount = examData.questions.reduce((count, question) => {
                return count + (question.question_answers?.[0]?.punctuation_active ? 1 : 0);
            }, 0);
            setCorrectedQuestionsCount(correctedCount);
        }
    }, [examData]);

    const questionStatus = "memorized"
    const getQuestionStatus = () => {
        if (!questionStatus) {
            return {
                label: 'Sin empezar',
                color: '#E7E7E7',
                textColor: '#D9D9D9'
            };
        }

        const { status } = questionStatus;

        switch (status) {
            case 'memorized':
                return {
                    label: 'Memorizada',
                    color: '#00B564',
                    textColor: 'white'
                };
            case 'learning':
                return {
                    label: 'Aprendiendo',
                    color: '#FFA726',
                    textColor: 'white'
                };
            case 'incorrect':
                return {
                    label: 'Incorrecta',
                    color: '#AA161D',
                    textColor: 'white'
                };
            default:
                return {
                    label: 'Sin empezar',
                    color: '#D9D9D9',
                    textColor: 'white'
                };
        }
    };

    const [isJustificationOpen, setIsJustificationOpen] = useState(false);
    const [justificationText, setJustificationText] = useState("");
    const [isLoadingJustification, setIsLoadingJustification] = useState(false);

    const handleJustificationOpen = async () => {
        setIsJustificationOpen(true);
        const currentQuestion = examData.questions[currentQuestionIndex];

        // Check if there's an existing justification
        const existingJustification = currentQuestion.question_answers[0].justification;

        if (existingJustification) {
            setJustificationText(existingJustification);
            setIsLoadingJustification(false);
            return;
        }

        // If no existing justification, proceed with API call
        setIsLoadingJustification(true);
        setJustificationText("");

        const userAnswer = answers[currentQuestion.guid]?.answer || "";

        try {
            await streamJustification(
                {
                    question: currentQuestion.question_statement,
                    correctAnswer: currentQuestion.question_correct_answer,
                    userAnswer: userAnswer,
                    isCorrect: validatedQuestions[currentQuestion.guid],
                    documentId: examData.document_guid,
                    userId: user['id'],
                    questionAnswerId: currentQuestion.question_answers[0].guid,
                    typeJustification: "exam",
                },
                (word) => {
                    setJustificationText(prev => prev + word);
                },
                user,
                token
            );
            updateCredits()
        } catch (error) {
            console.error("Error fetching justification:", error);
            setJustificationText("Lo sentimos, no pudimos generar la justificación en este momento.");
        } finally {
            setIsLoadingJustification(false);
        }
    };

    const handleJustificationClose = () => {
        setIsJustificationOpen(false);
    };

    // Add a new function to properly update scores
    const updateExamScores = useCallback(() => {
        if (examData.questions) {
            const scores = examData.questions.map(question =>
                question.question_answers[0]?.punctuation || 0
            );
            setListScore(scores);

            // Calculate final score (average * 10)
            const totalScore = scores.reduce((acc, score) => acc + score, 0);
            const finalScore = Math.round((totalScore / scores.length) * 10);

            setExamData(prev => ({
                ...prev,
                final_score: finalScore
            }));
        }
    }, [examData.questions]);

    if (isLoadingReset) {
        return (
            <div className={classes.loadingContainer}>
                <Lottie
                    animationData={animationData}
                    style={{ width: 300, height: 300 }}
                />
                <Typography sx={{ fontFamily: "Inter" }}>Estamos preparando tu examen</Typography>
            </div>
        );
    }
    const ExamSkeleton = () => (
        <Container className={classes.gridContainer}>
            <Grid item className={classes.leftPart}>
                <Paper elevation={3} className={classes.paperLeft} style={{ height: "100%" }}>
                    <Box margin="20px 0px">
                        <Skeleton variant="text" width="60%" height={40} />
                    </Box>
                    <Skeleton variant="rectangular" width="80%" height={20} style={{ marginBottom: '20px' }} />
                    <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: '20px' }} />
                    <Skeleton variant="text" width="40%" height={30} style={{ marginBottom: '10px' }} />
                    <Skeleton variant="rectangular" width="100%" height={200} style={{ marginBottom: '20px' }} />
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Skeleton variant="circular" width={40} height={40} style={{ marginRight: '10px' }} />
                        <Skeleton variant="text" width={100} height={30} />
                        <Skeleton variant="circular" width={40} height={40} style={{ marginLeft: '10px' }} />
                    </Box>
                </Paper>
            </Grid>
            <Grid item className={classes.rightPart}>
                <Paper elevation={3} className={classes.paperright}>
                    <Skeleton variant="rectangular" width="100%" height="100%" />
                </Paper>
            </Grid>
        </Container>
    );
    if (isLoading) {
        return <ExamSkeleton />;
    }


    return (
        <>
            {showAlert && (
                <SmallAlert
                    message={message}
                    onClose={() => setShowAlert(false)}
                />
            )}

            {correctingExam && (
                <div className={classes.step3Container}>
                    <div className={classes.step3Content}>
                        <Lottie
                            animationData={animationData}
                            style={{ width: 300, height: 300 }}
                        />
                        <Typography style={{ fontWeight: "bold", fontSize: "16px", color: "#026277" }}>
                            Estamos analizando tus respuestas
                        </Typography>
                        <Box sx={{ width: '100%', my: 2 }}>
                            <Box sx={{
                                width: '100%',
                                height: '10px',
                                bgcolor: '#8db8c2',
                                borderRadius: '6px',
                                overflow: 'hidden' // Ensure the inner box doesn't overflow the rounded corners
                            }}>
                                <Box sx={{
                                    width: `${progress}%`, // This represents the progress
                                    height: '100%',
                                    bgcolor: '#026277', // Color of the progress indicator
                                }} />
                            </Box>
                            <Typography style={{ fontWeight: 500, color: "gray", fontSize: "12px" }}>{t("process_take_time")}</Typography>
                        </Box>
                    </div>
                </div>
            )}

            {showUpgrade || showCredits && (
                <NeedCredits
                    initialOpen={true}
                    message={message}
                    onClose={() => { setShowUpgrade(false); setShowCredits(false) }}
                />
            )}

            {modalOpenUpload && (
                <ModalParent
                    open={modalOpenUpload}
                    title={titleOptionUpload}
                    description={descriptionOptionUpload}
                    sendButton={buttonOptionUpload}
                    placeholdMessage={placeholderMessageUpload}
                    type={typeUpload}
                    onClose={() => setModalOpenUpload(false)}
                    setShowSpinner={setShowSpinnerUpload}
                    setShowAlert={setShowAlertUpload}
                    setMessage={setMessageUpload}
                    setProgressBar={setProgressBar}
                    setShowUpgrade={setShowUpgrade}
                    width={width}
                    setIsPreparingExam={setIsPreparingExam}
                    setFolderSelectedGuid={setFolderSelectedGuid}
                    setDocumentSelectedGuid={setDocumentSelectedGuid}
                />
            )}

            {isPreparingExam && (
                <FullScreenLoader
                    imageUrl="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/ExamGettingReady.svg"
                    title={t('fullScreenLoader.examPreparingTitle')}
                    message={t('fullScreenLoader.examPreparingMessage')}
                    buttonText={t('fullScreenLoader.examPreparingButton')}
                    onClose={() => { setIsPreparingExam(false); }}
                    onButtonClick={() => { setIsPreparingExam(false); if (folderSelectedGuid && documentSelectedGuid) { navigate(`/${folderSelectedGuid}/${documentSelectedGuid}`) } }}
                />
            )}

            {/* Main content - Show either ExamCorrection or Exam interface */}
            {allCorrected ? (
                <ExamCorrection
                    examData={examData}
                    listScore={listScore}
                    scoringSettings={scoringSettings}
                />
            ) : (
                <Container container spacing={3} className={classes.gridContainer}>
                    <Grid item style={{
                        maxWidth: '800px',
                        margin: '0 auto',
                        width: '100%'
                    }}>
                        <Paper elevation={3} className={classes.paperLeft} style={{
                            height: "100%",
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                            {/* Title section */}
                            <Box display="flex" alignItems="center" justifyContent="space-between" margin="20px 0px">
                                <div style={{ display: 'flex', flexDirection: "row" }}>
                                    {editMode ? (
                                        <TextField
                                            autoFocus
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Enter title here"
                                            value={editedTitle}
                                            onChange={handleTitleChange}
                                            onBlur={handleBlur}
                                        />
                                    ) : (
                                        <>
                                            <Typography
                                                style={{
                                                    fontWeight: 600,
                                                    fontFamily: 'Fira Sans, sans-serif',
                                                    marginRight: 20,
                                                    fontSize: "18px",
                                                    marginLeft: "0px"
                                                }}
                                            >
                                                {truncateText(examData.title, 40)}
                                            </Typography>
                                            <IconButton style={{ padding: "0px" }} onClick={() => setEditMode(true)}>
                                                <EditIcon />
                                            </IconButton>
                                        </>
                                    )}
                                </div>
                                <Button
                                    style={{
                                        backgroundColor: "#6BBDBD",
                                        color: "white",
                                        borderRadius: "20px",
                                        textTransform: "none",
                                        fontFamily: "Inter, sans-serif",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        padding: "6px 22px"
                                    }}
                                    onClick={handleAnswers}
                                >
                                    Finalizar
                                </Button>
                            </Box>

                            {/* Progress bar and Questions */}
                            <div style={{ flex: 1, overflowY: 'auto', paddingTop: '15px' }}>
                                {/* Progress bar */}
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
                                    <div style={{ width: "80%" }}>
                                        <LinearProgress
                                            variant="determinate"
                                            value={(correctedQuestionsCount / examData.questions.length) * 100}
                                            max={100}
                                            sx={{
                                                height: '18px',
                                                borderRadius: '24px',
                                                backgroundColor: '#E2F1F1',
                                                flexGrow: 1,
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: '#6BBDBD'  // Change this to your desired green color
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                {/* Questions */}
                                {examData.questions.length > 0 && (
                                    <Questions
                                        calculatePoints={calculatePoints}
                                        key={examData.questions[currentQuestionIndex].guid}
                                        questionNumber={currentQuestionIndex + 1}
                                        question={examData.questions[currentQuestionIndex]}
                                        answer={answers[examData.questions[currentQuestionIndex].guid]?.answer || ""}
                                        onAnswerChange={handleAnswerChange}
                                        onQuestionCorrection={handleQuestionCorrection}
                                        token={token}
                                        user={user}
                                        corrected={corrected}
                                        setCorrected={setCorrected}
                                        valueAnswer={examData.questions[currentQuestionIndex].punctuation}
                                        reset={needReset}
                                        validated={validatedQuestions[examData.questions[currentQuestionIndex].guid] || false}
                                        updateQuestionData={updateQuestionData}
                                        exam_id={exam_id}
                                        points={points}
                                        setExamQuestionFlag={setExamQuestionFlag}
                                        isFlagged={examData.questions[currentQuestionIndex].flag}
                                        onNextQuestion={handleNextQuestion}
                                        setExamData={setExamData}
                                        scoringSettings={scoringSettings}
                                    />
                                )}
                            </div>

                            {/* Navigation arrows */}
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <IconButton
                                    onClick={handlePreviousQuestion}
                                    disabled={currentQuestionIndex === 0}
                                    style={{ color: '#6BBDBD' }}
                                >
                                    <ArrowBackIos style={{ fontSize: 'small' }} />
                                </IconButton>
                                <Typography variant="body1" style={{ color: '#6BBDBD', fontSize: '14px' }}>
                                    {currentQuestionIndex + 1} de {examData.questions.length}
                                </Typography>
                                <IconButton
                                    onClick={handleNextQuestion}
                                    disabled={currentQuestionIndex === examData.questions.length - 1}
                                    style={{ color: '#6BBDBD' }}
                                >
                                    <ArrowForwardIos style={{ fontSize: 'small' }} />
                                </IconButton>
                            </Box>

                            {/* Justification button */}
                            <Box
                                display="flex"
                                justifyContent="center"
                                gap={20}
                                padding={2}
                                marginTop="auto"
                                borderTop="1px solid #eee"
                            >
                                {examData.questions && examData.questions[currentQuestionIndex] && (
                                    <Button
                                        variant="contained"
                                        startIcon={<DescriptionOutlinedIcon />}
                                        style={{
                                            backgroundColor: !examData.questions[currentQuestionIndex].question_answers[0].punctuation_active
                                                ? "#D3D3D3"
                                                : "#6BBDBD",
                                            color: "white",
                                            borderRadius: "20px",
                                            boxShadow: "none",
                                            textTransform: "none",
                                            fontFamily: "Inter, sans-serif",
                                            fontWeight: "600",
                                            fontSize: "14px",
                                            padding: "8px 24px",
                                        }}
                                        disabled={!examData.questions[currentQuestionIndex].question_answers[0].punctuation_active}
                                        onClick={handleJustificationOpen}
                                    >
                                        Justificación
                                    </Button>
                                )}
                            </Box>
                        </Paper>
                    </Grid>
                </Container>
            )}

            <PopupFinishExam
                open={isPopupOpen}
                onClose={() => setIsPopupOpen(false)}
                onConfirm={handleConfirmCorrection}
            />

            {examData.questions && examData.questions[currentQuestionIndex] && (
                <JustificationPopup
                    open={isJustificationOpen}
                    onClose={handleJustificationClose}
                    question={examData.questions[currentQuestionIndex].question_statement}
                    correctAnswer={examData.questions[currentQuestionIndex].question_correct_answer}
                    justificationText={justificationText}
                    isLoading={isLoadingJustification}
                    document_id={examData.document_guid}
                    user={user}
                    userAnswer={answers[examData.questions[currentQuestionIndex].guid]?.answer || ""}
                    token={token}
                    questionAnswerId={examData.questions[currentQuestionIndex].question_answers[0].guid}    
                />
            )}
        </>
    );
}

export default ExamView;