import React, { createContext, useState, useContext, useEffect } from 'react';
import { fetchUserInfo } from '../Api/UserApi';
import { useLocation } from 'react-router-dom';

const UserContext = createContext();

export function UserProvider({ children, token, user }) {
    const [userInfo, setUserInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const location = useLocation();

    const loadUserInfo = async () => {
        try {
            const data = await fetchUserInfo(token, "GET", null, user);
            setUserInfo(data);
            setLoading(false);
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    };

    useEffect(() => {
        // Only fetch user info if we have token and user, and we're not in the registration process
        if (token && user && location.pathname !== '/login-success') {
            loadUserInfo();
        }
    }, [token, user, location.pathname]);

    const updateUserInfo = async (newData) => {
        try {
            const data = await fetchUserInfo(token, "PUT", newData, user);
            setUserInfo(data);
            return data;
        } catch (err) {
            setError(err);
            throw err;
        }
    };

    const deleteUserInfo = async () => {
        try {
            await fetchUserInfo(token, "DELETE", {}, user);
            setUserInfo(null);
        } catch (err) {
            setError(err);
            throw err;
        }
    };

    return (
        <UserContext.Provider value={{ 
            userInfo, 
            loading, 
            error, 
            updateUserInfo,
            deleteUserInfo,
            refreshUserInfo: loadUserInfo
        }}>
            {children}
        </UserContext.Provider>
    );
}

export function useUserInfo() {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUserInfo must be used within a UserProvider');
    }
    return context;
} 