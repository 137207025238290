import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, Grid, IconButton, Button, Tooltip, Checkbox, ListItemIcon } from '@mui/material';
import Taks from '../Taks.png';
import SchoolIcon from '@mui/icons-material/School';
import { useNavigate } from 'react-router-dom';
import { fetchFolderExams, fetchExamId } from '../../Api/ExamsApi';
import { useCredits } from "../../Context/CreditsContext";
import NeedCredits from '../../NoCreditsModal/NeedCredits';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import QuizIcon from '@mui/icons-material/Quiz';
import PsychologyIcon from '@mui/icons-material/Psychology';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SettingsIcon from '@mui/icons-material/Settings';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import styled from '@emotion/styled';
import Select from 'react-select';
import { fetchFlashcardsTasks } from '../../Api/FlashcardsApi';
import { fetchUserFolders } from '../../Api/FolderApi';
import { createSetFlashcards } from '../../Api/FlashcardsApi';
import Switch from '@mui/material/Switch';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import MicIcon from '@mui/icons-material/Mic';
import { useUserInfo } from '../../Context/UserContext';
const DIFFICULTY_COLORS = {
  'Sin responder': '#E0E0E0',
  'Fácil': '#4CAF50',
  'Normal': '#2196F3',
  'Difícil': '#FFC107',
  'Muy difícil': '#FF5722'
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 3,
};

const CustomCheckbox = styled(Checkbox)({
  color: '#CCCCCC',
  '&.Mui-checked': {
    color: '#6BBDBD',
  },
  '&:hover': {
    backgroundColor: 'rgba(107, 189, 189, 0.04)',
  },
});

const Tasks = ({ token, user, userData }) => {
  const [showMore, setShowMore] = React.useState(false);
  const [flashcardSets, setFlashcardSets] = React.useState([]);
  const [totalTasks, setTotalTasks] = React.useState(0);
  const [completedTasks, setCompletedTasks] = React.useState(0);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSettings, setOpenSettings] = useState(false);
  const [cardsPerTask, setCardsPerTask] = useState(() => {
    const saved = localStorage.getItem('cardsPerTask');
    return saved ? parseInt(saved) : 10; // Default to 10 cards
  });
  const [openTasksModal, setOpenTasksModal] = useState(false);
  const [selectedFlashcardSet, setSelectedFlashcardSet] = useState(null);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [folders, setFolders] = useState([]);
  const { updateUserInfo } = useUserInfo();
  const [flashcardSettings, setFlashcardSettings] = useState(() => {
    return userData ? {
      typing: userData.type_flashcards === 'typing',
      voice: userData.type_flashcards === 'voice'
    } : {
      typing: true,
      voice: false
    };
  });

  // Handle switch change to ensure only one is selected
  const handleModeChange = (mode) => {
    setFlashcardSettings(prev => ({
      ...prev,
      typing: mode === 'typing',
      voice: mode === 'voice'
    }));
  };

  useEffect(() => {
    const fetchFlashcardSets = async () => {
      if (token && user) {
        try {
          const response = await fetchFlashcardsTasks(token, user);
          const allFlashcardSets = Object.entries(response).map(([id, set]) => ({
            id,
            ...set,
            folder_name: set.folder_name
          }));

          setTotalTasks(allFlashcardSets.length);

          // Filter for incomplete sets
          const incompleteSets = allFlashcardSets.filter(set => !set.is_completed);
          setFlashcardSets(incompleteSets);
        } catch (error) {
          console.error("Error fetching flashcard sets:", error);
          setFlashcardSets([]);
          setTotalTasks(0);
          setCompletedTasks(0);
        }
      }
    };

    fetchFlashcardSets();
  }, [token, user]);

  useEffect(() => {
    const loadFolders = async () => {
      if (token && user) {
        try {
          const foldersData = await fetchUserFolders(token, "GET", null, user);
          setFolders(foldersData);
        } catch (error) {
          console.error("Error fetching folders:", error);
          setFolders([]);
        }
      }
    };

    loadFolders();
  }, [token, user]);

  const visibleTasks = showMore ? flashcardSets : flashcardSets.slice(0, 6);
  const { updateCredits, credits } = useCredits();

  const calculateEstimatedTime = (flashcardCount) => {
    const minutes = Math.ceil((flashcardCount * 15) / 60);
    return `${minutes} min`;
  };

  const handleOpenSettings = () => setOpenSettings(true);
  const handleCloseSettings = () => setOpenSettings(false);

  const handleSaveSettings = async () => {
    try {
      // Determine which mode is active
      const type = flashcardSettings.typing ? 'typing' : 'voice';

      // Update user settings through context
      await updateUserInfo({
        type_flashcards: type
      });

      handleCloseSettings();
    } catch (error) {
      console.error("Error saving flashcard settings:", error);
      // Optionally add error handling UI feedback here
    }
  };

  const getEstimatedTimeText = (cards) => {
    const seconds = cards * 15;
    if (seconds < 60) {
      return `${seconds} segundos`;
    }
    const minutes = Math.ceil(seconds / 60);
    return `${minutes} ${minutes === 1 ? 'minuto' : 'minutos'}`;
  };

  const getDummyDistribution = (totalCards) => {
    return {
      'Sin responder': Math.floor(totalCards * 0.4),
      'Fácil': Math.floor(totalCards * 0.2),
      'Normal': Math.floor(totalCards * 0.15),
      'Difícil': Math.floor(totalCards * 0.15),
      'Muy difícil': Math.floor(totalCards * 0.1)
    };
  };

  const getUniqueFolders = (exams) => {
    const folders = new Set();
    exams.forEach(exam => {
      if (exam.folder_name) {
        folders.add(exam.folder_name);
      }
    });
    return Array.from(folders);
  };

  const getFilteredFlashcardSets = () => {

    const filtered = flashcardSets.filter(set => {


      if (selectedFolders.length === 0) {
        return true;
      }

      const isIncluded = selectedFolders.includes(set.folder_name);
      return isIncluded;
    });

    return filtered;
  };

  const calculateCompletionPercentage = (set) => {
    const completedCards = (set.flashcards_easy || 0) + (set.flashcards_normal || 0);
    const totalCards = completedCards +
      (set.flashcards_hard || 0) +
      (set.flashcards_very_hard || 0) +
      (set.flashcards_not_started || 0);

    if (totalCards === 0) return 0;
    return Math.round((completedCards / totalCards) * 100);
  };

  const calculateTotalFlashcards = (doc) => {
    // Initial slots for new and very hard flashcards
    let total = Math.min(20, doc.flashcards_not_started || 0); // new flashcards (max 20)
    total += Math.min(50, doc.flashcards_very_hard || 0); // very hard flashcards (max 50)

    // Calculate remaining slots (70 - new - veryhard)
    let remainingSlots = 70 - total;

    // Add hard flashcards
    if (remainingSlots > 0) {
      total += Math.min(remainingSlots, doc.flashcards_hard || 0);
      remainingSlots -= Math.min(remainingSlots, doc.flashcards_hard || 0);
    }

    // Add normal flashcards (10% of total normal cards)
    if (remainingSlots > 0 && doc.flashcards_normal > 0) {
      const normalCount = doc.flashcards_normal;
      const normalLimit = Math.round(normalCount * 0.1);
      const normalToAdd = Math.min(remainingSlots, normalLimit);
      total += normalToAdd;
      remainingSlots -= normalToAdd;
    }

    // Add easy flashcards (10% of total easy cards)
    if (remainingSlots > 0 && doc.flashcards_easy > 0) {
      const easyCount = doc.flashcards_easy;
      const easyLimit = Math.round(easyCount * 0.1);
      const easyToAdd = Math.min(remainingSlots, easyLimit);
      total += easyToAdd;
      remainingSlots -= easyToAdd;
    }

    // Add any remaining not started flashcards if there are still slots
    // if (remainingSlots > 0) {
    //   total += Math.min(remainingSlots, Math.max(0, (doc.flashcards_not_started || 0) - 20));
    // }

    return total;
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* Header Section */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: '24px', fontFamily: 'Fira Sans' }}>
          Tus flashcards de hoy
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography sx={{
            bgcolor: '#e8f4f4',
            px: 2,
            py: 0.5,
            borderRadius: '20px',
            fontFamily: 'Fira Sans',
            fontWeight: 'bold',
            color: '#6BBDBD'
          }}>
            {`${completedTasks}/${totalTasks}`}
          </Typography>
          <img
            src={Taks}
            alt="Notebook"
            style={{ width: '24px', height: '24px' }}
          />
          <IconButton onClick={handleOpenSettings}>
            <SettingsIcon />
          </IconButton>
        </Box>
      </Box>

      <Typography sx={{ color: '#7c8a97', mb: 3, fontSize: '16px', fontFamily: 'Inter' }}>
        Memoriza estas flashcards para combatir la curva del olvido </Typography>

      {flashcardSets.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: "50px",
            textAlign: 'center'
          }}
        >
          <img
            src={"https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/Podium+2.png"}
            alt="Podium"
            style={{
              width: '200px',
              height: 'auto',
              marginBottom: '24px'
            }}
          />


          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '20px',
              fontFamily: 'Fira Sans',
              color: '#6BBDBD'
            }}
          >
            No tienes flashcards pendientes 🎉
          </Typography>
          <Typography
            sx={{
              color: '#7c8a97',
              fontSize: '16px',
              fontFamily: 'Inter',
              mb: 4
            }}
          >
            Estudia ahora con tests y flashcards
          </Typography>
          <Box>
            <Button
              startIcon={<SchoolIcon />}
              onClick={(event) => setAnchorEl(event.currentTarget)}
              aria-controls="study-menu"
              aria-haspopup="true"
              sx={{
                color: 'white',
                backgroundColor: '#6BBDBD',
                border: 'none',
                borderRadius: '20px',
                padding: '8px 24px',
                cursor: 'pointer',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: 'bold',
                textTransform: 'none',
                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                '&:hover': {
                  backgroundColor: '#6BBDBD',
                  transform: 'translateY(-2px)',
                  boxShadow: '0px 8px 25px 0px rgba(0, 0, 0, 0.1)',
                }
              }}
              endIcon={
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 7.5L10 12.5L15 7.5"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
            >
              Estudiar
            </Button>

            <Menu
              id="study-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              PaperProps={{
                style: {
                  marginTop: '8px',
                  borderRadius: '12px',
                  minWidth: '200px',
                  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                },
              }}
              style={{
                marginTop: '8px'
              }}
            >
              <MenuItem
                onClick={() => {
                  navigate('/new-exam');
                  setAnchorEl(null);
                }}
                style={{
                  padding: '12px 16px',
                  fontSize: '14px',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                  <QuizIcon style={{ color: '#666' }} />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontWeight: 500, fontFamily: "Fira Sans" }}>Hacer test</span>
                    <span style={{ color: '#666', fontSize: '12px', fontFamily: "Fira Sans" }}>Ponte a prueba con un test</span>
                  </div>
                </div>
              </MenuItem>
              <Divider style={{ margin: '4px 0' }} />
              <MenuItem
                onClick={() => {
                  navigate('/new-flashcards', { state: { reset: true }, replace: true });
                  setAnchorEl(null);
                }}
                style={{
                  padding: '12px 16px',
                  fontSize: '14px',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                  <PsychologyIcon style={{ color: '#666' }} />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontWeight: 500, fontFamily: "Fira Sans" }}>Tarjetas de memoria</span>
                    <span style={{ color: '#666', fontSize: '12px', fontFamily: "Fira Sans" }}>Responde a flashcards</span>
                  </div>
                </div>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      ) : (
        <>
          {/* Cards Grid */}
          <Grid container spacing={1.5}>
            {visibleTasks.map((set) => (
              <Grid item xs={12} sm={6} md={6} lg={4} key={set.id}>
                <Card
                  onClick={async () => {
                    try {
                      const flashcardData = {
                        num_flashcards: set.num_flashcards,

                        set_flashcard_id: set.guid
                      };

                      const response = await createSetFlashcards(
                        token,
                        set.document_guid,
                        flashcardData,
                        user
                      );

                      navigate('/flashcards', {
                        state: {
                          documentGuid: set.document_guid,
                          studyMode: flashcardSettings.typing ? 'typing' : 'flashcard',
                          customization: {
                            cardCount: set.num_flashcards,
                            cardTypes: set.card_types,
                            documentTitle: set.title,
                            folderName: set.folder_name,
                            folderColor: set.folder_color
                          },
                          flashcardsSet: response
                        }
                      });
                    } catch (error) {
                      console.error("Error creating flashcards set:", error);
                    }

                  }}
                  sx={{
                    padding: "20px",
                    height: "100px",
                    width: "100%",
                    borderRadius: "20px",
                    position: 'relative',
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    border: "1px solid #E7E7E7",
                    overflow: 'visible',
                    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                    cursor: 'pointer',
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: '0px 8px 25px 0px rgba(0, 0, 0, 0.1)',
                    },
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      bottom: 0,
                      right: 0,
                      width: "12px",
                      borderRadius: '20px 0 0 20px',
                      backgroundColor: set.folder_color || '#6BBDBD',
                    }
                  }}
                >

                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%'  // Ensure the Box takes full width
                  }}>
                    <Box sx={{
                      flexGrow: 1,  // Allow this Box to grow and take available space
                      minWidth: 0   // Allow text truncation to work properly
                    }}>
                      <Tooltip title={set.title || 'Untitled Flashcard Set'}>
                        <Typography sx={{
                          fontWeight: 'bold',
                          mb: 1,
                          fontSize: '14px',
                          fontFamily: 'Fira Sans',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}>
                          {set.title || 'Untitled Flashcard Set'}


                        </Typography>
                      </Tooltip>
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mt: 0.5,
                        flexDirection: 'column',
                        width: '100%'
                      }}>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          width: '100%'
                        }}>
                          <Typography sx={{
                            color: 'text.secondary',
                            fontSize: '14px',
                            fontFamily: "'Fira Sans', sans-serif"
                          }}>
                            {`${calculateTotalFlashcards(set)} flashcards`}
                          </Typography>
                          <Tooltip title="Tiempo aproximado">
                            <Typography sx={{
                              color: 'text.secondary',
                              fontSize: '14px',
                              fontFamily: "'Fira Sans', sans-serif"
                            }}>
                              {calculateEstimatedTime(calculateTotalFlashcards(set) || 0)}
                            </Typography>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                      width: '50px',
                      height: '50px',
                    }}>
                      <Tooltip title="Grado de dominio">
                        <Typography sx={{
                          color: '#6BBDBD',
                          fontWeight: 'bold',
                          fontSize: '16px',
                          fontFamily: 'Fira Sans'
                        }}>
                          {`${calculateCompletionPercentage(set)}%`}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>

          {flashcardSets.length > 6 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
              <Button
                onClick={() => setOpenTasksModal(true)}
                sx={{
                  color: 'white',
                  backgroundColor: '#6BBDBD',
                  border: 'none',
                  borderRadius: '20px',
                  padding: '8px 24px',
                  cursor: 'pointer',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  textTransform: 'none',
                  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                  '&:hover': {
                    backgroundColor: '#6BBDBD',
                    transform: 'translateY(-2px)',
                    boxShadow: '0px 8px 25px 0px rgba(0, 0, 0, 0.1)',
                  }
                }}
              >
                {`Ver todas (${flashcardSets.length})`}
              </Button>
            </Box>
          )}
        </>
      )}

      <Dialog
        open={openSettings}
        onClose={handleCloseSettings}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            padding: "24px",
            maxWidth: "400px",
            width: "100%",
            minHeight: "300px"
          }
        }}
      >
        <DialogTitle sx={{
          padding: "0",
          fontFamily: 'Fira Sans',
        }}>
          <Typography sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: 'Fira Sans',
            color: '#000',
            mb: 1
          }}>
            Configuración de tareas
          </Typography>
          <Typography sx={{
            fontSize: '14px',
            color: '#A0A0A0',
            fontFamily: 'Fira Sans',
            mb: 2
          }}>
            Personaliza tus preferencias de estudio
          </Typography>
        </DialogTitle>
        <DialogContent sx={{
          padding: "0",
          '&.MuiDialogContent-root': {
            paddingTop: '10px !important'
          }
        }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            {/* Flashcard Type Toggles */}
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              backgroundColor: '#F8F9FA',
              borderRadius: '10px',
              padding: '16px'
            }}>
              <Typography sx={{
                fontSize: '14px',
                fontFamily: 'Fira Sans',
                color: '#666',
                fontWeight: 'bold'
              }}>
                Tipo de flashcards
              </Typography>

              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <KeyboardIcon sx={{ color: '#6BBDBD' }} />
                  <Typography sx={{
                    fontSize: '14px',
                    fontFamily: 'Fira Sans',
                    color: '#666'
                  }}>
                    Escritura
                  </Typography>
                </Box>
                <Switch
                  checked={flashcardSettings.typing}
                  onChange={(e) => handleModeChange('typing')}
                  sx={{
                    '& .MuiSwitch-switchBase.Mui-checked': {
                      color: '#6BBDBD',
                      '&:hover': {
                        backgroundColor: 'rgba(107, 189, 189, 0.08)'
                      }
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                      backgroundColor: '#6BBDBD'
                    }
                  }}
                />
              </Box>

              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <MicIcon sx={{ color: '#6BBDBD' }} />
                  <Typography sx={{
                    fontSize: '14px',
                    fontFamily: 'Fira Sans',
                    color: '#666'
                  }}>
                    Voz
                  </Typography>
                </Box>
                <Switch
                  checked={flashcardSettings.voice}
                  onChange={(e) => handleModeChange('voice')}
                  sx={{
                    '& .MuiSwitch-switchBase.Mui-checked': {
                      color: '#6BBDBD',
                      '&:hover': {
                        backgroundColor: 'rgba(107, 189, 189, 0.08)'
                      }
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                      backgroundColor: '#6BBDBD'
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{
          padding: "16px 0 0 0",
          marginTop: 'auto'
        }}>
          <Button
            onClick={handleCloseSettings}
            sx={{
              color: '#A0A0A0',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#666'
              }
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleSaveSettings}
            sx={{
              backgroundColor: '#6BBDBD',
              color: 'white',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              borderRadius: '20px',
              padding: '6px 16px',
              '&:hover': {
                backgroundColor: '#5aa9a9'
              }
            }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        open={openTasksModal}
        onClose={() => setOpenTasksModal(false)}
        aria-labelledby="tasks-modal"
        onKeyDown={(e) => e.key === 'Escape' && setOpenTasksModal(false)}
      >
        <Box sx={modalStyle}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Box>
              <Typography
                id="tasks-modal"
                variant="h6"
                sx={{
                  fontFamily: "'Fira Sans', sans-serif",
                  fontWeight: 600
                }}
              >
                Todas tus tareas pendientes
              </Typography>
              <Typography
                sx={{
                  fontFamily: "'Fira Sans', sans-serif",
                  color: 'text.secondary',
                  fontSize: '0.875rem',
                  mt: 0.5
                }}
              >
                Selecciona qué tarea quieres estudiar
              </Typography>
            </Box>
            <IconButton onClick={() => setOpenTasksModal(false)} size="small">
              <CloseIcon />
            </IconButton>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Select
              value={selectedFolders.map(folder => ({
                value: folder,
                label: folder
              }))}
              onChange={(selected) => {
                const newSelectedFolders = selected ? selected.map(option => option.value) : [];
                setSelectedFolders(newSelectedFolders);
              }}
              options={folders.map(folder => {
                return {
                  value: folder.name,
                  label: folder.name,
                  color: folder.color
                };
              })}
              isMulti
              placeholder="Selecciona las carpetas..."
              noOptionsMessage={() => "No hay carpetas disponibles"}
              styles={{
                control: (base, state) => ({
                  ...base,
                  borderRadius: '10px',
                  borderColor: state.isFocused ? '#6BBDBD' : '#E0E0E0',
                  boxShadow: state.isFocused ? '0 0 0 1px #6BBDBD' : 'none',
                  '&:hover': {
                    borderColor: '#6BBDBD'
                  },
                  minHeight: '45px'
                }),
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: '#e8f4f4',
                  borderRadius: '15px',
                  padding: '2px'
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  color: '#6BBDBD',
                  fontFamily: 'Fira Sans',
                  fontSize: '14px',
                  padding: '2px 8px'
                }),
                multiValueRemove: (base) => ({
                  ...base,
                  color: '#6BBDBD',
                  ':hover': {
                    backgroundColor: '#d1e7e7',
                    color: '#5aa9a9'
                  },
                  borderRadius: '0 15px 15px 0'
                }),
                placeholder: (base) => ({
                  ...base,
                  color: '#A0A0A0',
                  fontFamily: 'Fira Sans',
                  fontSize: '14px'
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? '#6BBDBD'
                    : state.isFocused
                      ? '#e8f4f4'
                      : 'white',
                  color: state.isSelected ? 'white' : '#666',
                  fontFamily: 'Fira Sans',
                  fontSize: '14px',
                  ':active': {
                    backgroundColor: '#6BBDBD'
                  },
                  '::before': {
                    content: '""',
                    display: 'inline-block',
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    backgroundColor: state.data.color || '#6BBDBD',
                    marginRight: '8px'
                  }
                }),
                menu: (base) => ({
                  ...base,
                  borderRadius: '10px',
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                })
              }}
            />
          </Box>

          <List
            className="custom-scrollbar"
            sx={{
              height: 400,
              overflowY: 'auto',
              overflowX: 'hidden',
              mb: 2,
              pr: 2,
              '& .MuiListItem-root': {
                borderRadius: 1,
                mb: 1,
                bgcolor: 'background.paper',
                border: '1px solid',
                borderColor: 'divider',
                width: 'auto',
                minWidth: 0
              }
            }}
          >
            {getFilteredFlashcardSets().map((set) => (
              <ListItem
                key={set.id}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: '#f5f5f5'
                  }
                }}
                onClick={() => setSelectedFlashcardSet(set.id)}
              >
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  pr: 2
                }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <ListItemIcon>
                      <CustomCheckbox
                        edge="start"
                        checked={selectedFlashcardSet === set.id}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Box
                            sx={{
                              width: 16,
                              height: 16,
                              borderRadius: 1,
                              bgcolor: set.folder_color || '#6BBDBD',
                              flexShrink: 0
                            }}
                          />
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontFamily: "'Fira Sans', sans-serif",
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              maxWidth: '300px'
                            }}
                          >
                            {set.title || 'Untitled Flashcard Set'}
                          </Typography>
                        </Box>
                      }
                      secondary={
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                          mt: 0.5,
                          width: '100%'
                        }}>
                          <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                          }}>
                            <Typography sx={{
                              color: 'text.secondary',
                              fontSize: '14px',
                              fontFamily: "'Fira Sans', sans-serif",
                              flexShrink: 0
                            }}>
                              {`${set.num_flashcards || 0} flashcards`}
                            </Typography>
                            <Tooltip title="Tiempo aproximado">
                              <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                color: 'text.secondary',
                                fontSize: '0.875rem',
                                fontFamily: "'Fira Sans', sans-serif",
                                flexShrink: 0
                              }}>
                                <AccessTimeIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                                {calculateEstimatedTime(set.num_flashcards || 0)}
                              </Box>
                            </Tooltip>
                          </Box>
                          <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            flexWrap: 'nowrap',
                            width: '100%'
                          }}>
                            {Object.entries(getDummyDistribution(set.num_flashcards || 0)).map(([difficulty, count]) => (
                              count > 0 && (
                                <Tooltip
                                  key={difficulty}
                                  title={`${difficulty}: ${count} flashcards`}
                                  placement="top"
                                >
                                  <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 0.5
                                  }}>
                                    <Box sx={{
                                      width: '8px',
                                      height: '8px',
                                      borderRadius: '50%',
                                      bgcolor: DIFFICULTY_COLORS[difficulty],
                                      flexShrink: 0
                                    }} />
                                    <Typography sx={{
                                      fontSize: '12px',
                                      color: 'text.secondary',
                                      fontFamily: 'Fira Sans',
                                      flexShrink: 0
                                    }}>
                                      {count}
                                    </Typography>
                                  </Box>
                                </Tooltip>
                              )
                            ))}
                          </Box>
                        </Box>
                      }
                    />
                    <Tooltip title="Grado de dominio">
                      <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        width: '45px',
                        height: '45px',
                        backgroundColor: '#e8f4f4',
                        flexShrink: 0
                      }}>
                        <Typography sx={{
                          color: '#6BBDBD',
                          fontWeight: 'bold',
                          fontSize: '16px',
                          fontFamily: 'Fira Sans'
                        }}>
                          {`${calculateCompletionPercentage(set)}%`}
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Box>
                </Box>
              </ListItem>
            ))}
          </List>

          {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button
              onClick={() => setOpenTasksModal(false)}
              sx={{
                textTransform: 'none',
                color: 'text.secondary',
                fontFamily: "'Fira Sans', sans-serif",
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              disabled={selectedFlashcardSet === null}
              onClick={async () => {
                const selectedSet = flashcardSets.find(set => set.id === selectedFlashcardSet);
                if (selectedSet && credits >= 40) {
                  if (!selectedSet.credits_deducted) {
                    let payload = { "credits_deducted": true }
                    try {
                      navigate('/flashcards', {
                        state: {
                          documentGuid: selectedSet.document_guid,
                          studyMode: 'review',
                          customization: {
                            cardCount: selectedSet.num_flashcards,
                            cardTypes: selectedSet.card_types,
                            documentTitle: selectedSet.title,
                            folderName: selectedSet.folder_name,
                            folderColor: selectedSet.folder_color
                          },
                          flashcardsSet: selectedSet
                        }
                      });
                      setOpenTasksModal(false);
                      fetchExamId(token, "PUT", selectedSet.id, user, payload)
                        .then(() => updateCredits())
                        .catch(error => {
                          console.error("Error updating exam or credits:", error);
                        });
                    } catch (error) {
                      console.error("Error navigating to exam:", error);
                    }
                  } else {
                    navigate('/flashcards', {
                      state: {
                        documentGuid: selectedSet.document_guid,
                        studyMode: 'review',
                        customization: {
                          cardCount: selectedSet.num_flashcards,
                          cardTypes: selectedSet.card_types,
                          documentTitle: selectedSet.title,
                          folderName: selectedSet.folder_name,
                          folderColor: selectedSet.folder_color
                        },
                        flashcardsSet: selectedSet
                      }
                    });
                    setOpenTasksModal(false);
                  }
                } else if (credits < 40) {
                  <NeedCredits />
                }
                setSelectedFlashcardSet(null);
              }}
              sx={{
                textTransform: 'none',
                fontFamily: "'Fira Sans', sans-serif",
                fontSize: '14px',
                borderRadius: '20px',
                boxShadow: 'none',
                bgcolor: '#6BBDBD',
                '&:hover': {
                  bgcolor: '#5AACAC',
                },
                '&.Mui-disabled': {
                  backgroundColor: '#E0E0E0',
                  color: '#A0A0A0'
                }
              }}
            >
              Comenzar estudio
            </Button>
          </Box> */}
        </Box>
      </Modal>
    </Box>
  );
};

export default Tasks;
