import React, { useState, useContext, useEffect, useCallback } from "react";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  IconButton,
  Typography,
  CircularProgress,
  TextField,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip
} from "@mui/material";
import { ArrowBack, Add, Edit, Delete, Save, Close, Quiz, Info } from "@mui/icons-material";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../Auth/Authhandle";
import PDFViewerComponent from "./Viewers/PdfViewer";
import Select from 'react-select';
import { fetchUserFolders } from '../Api/FolderApi';
import { uploadExam } from '../Api/UploadExamApi';
import { PDFDocument } from 'pdf-lib';
import { v4 as uuidv4 } from 'uuid';
import { createExamDocument } from "../Api/UploadExamApi";
import { WebSocketContext } from '../WebSocketProvider';
const SubirExamen = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [isCreatingQuestion, setIsCreatingQuestion] = useState(false);
  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showQuestionsDetectedModal, setShowQuestionsDetectedModal] = useState(false);
  const [formErrors, setFormErrors] = useState({
    question: false,
    options: [false, false, false, false]
  });
  const [newQuestion, setNewQuestion] = useState({
    question: '',
    options: ['', '', '', ''],
    correctAnswer: 0
  });
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [examName, setExamName] = useState('');
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [folders, setFolders] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({
    current: 0,
    total: 0
  });
  const [isEnunciadoChecked, setIsEnunciadoChecked] = useState(false);
  const [enunciadoText, setEnunciadoText] = useState('');
  const [enunciadoError, setEnunciadoError] = useState(false);
  const MAX_RETRIES = 3;
  const navigate = useNavigate();
  const { token, user, isAuthenticated } = useAuth();

  const fetchDataAndUpdate = async () => {
    if (token && user) {
      try {
        const fetchedFolders = await fetchUserFolders(token, "GET", null, user);
        const folderOptions = fetchedFolders.map(folder => ({
          value: folder.guid,
          label: folder.name || folder.title
        }));
        setFolders(folderOptions);
      } catch (error) {
        console.error("Error fetching folders:", error);
      }
    }
  };

  useEffect(() => {
    fetchDataAndUpdate();
  }, [token, user]);

  useEffect(() => {
    // Load PDF.js if it's not already loaded
    if (!window.pdfjsLib) {
      const script = document.createElement('script');
      script.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.min.js';
      script.onload = () => {
        window.pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js';
      };
      document.body.appendChild(script);
    }
  }, []);
  const { messageCallback } = useContext(WebSocketContext);

  useEffect(() => {
    if (messageCallback) {
      if (messageCallback.type == "exam_progress") {
        setUploadProgress({
          current: messageCallback.current_page,
          total: messageCallback.total_pages
        });
      }
    }
  }, [messageCallback]);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0 && files[0].type === "application/pdf") {
      checkPdfPages(files[0]);
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      checkPdfPages(file);
    }
  };

  const checkPdfPages = (file) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        const typedarray = new Uint8Array(event.target.result);
        const pdfDoc = await PDFDocument.load(typedarray);
        const numPages = pdfDoc.getPageCount();

        if (numPages > 15) {
          alert('El PDF tiene más de 15 páginas. Solo se utilizarán las primeras 15 páginas.');

        }

        setSelectedFile(file);
        setFileUrl(URL.createObjectURL(file));
        simulateQuestions(file);
      } catch (error) {
        console.error('Error checking PDF pages:', error);
        alert('Error al procesar el PDF');
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const simulateQuestions = async (file) => {
    setIsUploading(true);
    setHasError(false);

    try {
      const formData = new FormData();
      formData.append('file', file);

      if (!file || formData.get('file').size === 0) {
        alert('Por favor, selecciona un archivo PDF válido');
        setIsUploading(false);
        return;
      }

      const response = await uploadExam(token, formData, user);

      // Add UUID to each question
      const questionsWithIds = response.questions.map(question => ({
        ...question,
        id: uuidv4()  // Add unique UUID to each question
      }));

      setQuestions(questionsWithIds);
      setRetryCount(0); // Reset retry count on success
      setShowQuestionsDetectedModal(true); // Show the modal after successful extraction

    } catch (error) {
      console.error('Error uploading exam:', error);

      if (retryCount < MAX_RETRIES) {
        setRetryCount(prev => prev + 1);
        setHasError(true);
      } else {
        setHasError(true);
      }
    } finally {
      setIsUploading(false);
    }
  };

  // Add retry handler
  const handleRetry = () => {
    if (selectedFile && retryCount < MAX_RETRIES) {
      simulateQuestions(selectedFile);
    }
  };

  const handleCreateTestQuestion = () => {
    const newErrors = {
      question_text: newQuestion.question_text.trim() === '',
      options: newQuestion.options.map(opt => opt.trim() === '')
    };
    setFormErrors(newErrors);

    if (!newErrors.question && !newErrors.options.some(error => error)) {
      // Generate a new UUID for the question if it doesn't have an ID
      const questionId = newQuestion.id || uuidv4();

      setQuestions(prev => [{
        ...newQuestion,
        id: questionId
      }, ...prev]);

      setNewQuestion({
        question: '',
        options: ['', '', '', ''],
        correctAnswer: 0
      });
      setFormErrors({
        question: false,
        options: [false, false, false, false]
      });
      setIsCreatingQuestion(false);
    }
  };

  const handleDeleteClick = (question) => {
    setQuestionToDelete(question);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = () => {
    setIsDeleting(true);
    setQuestions(questions.filter(q => q.id !== questionToDelete.id));
    setIsDeleting(false);
    setDeleteDialogOpen(false);
    setQuestionToDelete(null);
  };

  const handleSaveClick = () => {
    setSaveDialogOpen(true);
  };

  const handleSaveConfirm = async () => {
    if (isEnunciadoChecked && !enunciadoText.trim()) {
      setEnunciadoError(true);
      return;
    }
    setEnunciadoError(false);

    if (!examName.trim() || !selectedFolder) {
      return;
    }
    setIsSaving(true);

    try {
      const documentData = {
        document_name: examName,
        folder_id: selectedFolder.value,
        questions: questions.map(q => ({
          question_text: q.question_text,
          options: q.options,
          answer: q.options[q.correctAnswer]
        })),
        enunciado: isEnunciadoChecked ? enunciadoText : null,
        file: selectedFile
      };


      const result = await createExamDocument(token, documentData, user);

      navigate(`/${result.folder_id}/${result.document_id}`);
      setIsSaving(false);
      setSaveDialogOpen(false);
    } catch (error) {
      console.error('Error saving exam:', error);
      alert('Error al guardar el examen');
      setIsSaving(false);
    }
  };

  // Replace the useBeforeUnload hook with useEffect
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isUploading) {
        event.preventDefault();
        event.returnValue = '¿Estás seguro de que quieres salir? Se perderá el progreso de la extracción de preguntas.';
        return event.returnValue;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isUploading]);

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', bgcolor: '#F8FFFF' }}>
      <AppBar position="static" elevation={0} sx={{ bgcolor: 'white', borderBottom: '1px solid #E2F1F1' }}>
        <Container>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '64px', gap: 2, justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBack />
              </IconButton>
              <Typography variant="body2" sx={{ color: '#666', fontFamily: 'Fira Sans' }}>
                Subir examen
              </Typography>
            </Box>
            <Tooltip
              title={
                isEnunciadoChecked && !enunciadoText.trim()
                  ? 'El enunciado no puede estar vacío'
                  : questions.some(q => q.correctAnswer === undefined || q.correctAnswer === null)
                    ? 'Todas las preguntas deben tener una respuesta marcada'
                    : ''
              }
              arrow
            >
              <span>
                <Button
                  onClick={handleSaveClick}
                  disabled={
                    !selectedFile ||
                    questions.length === 0 ||
                    questions.some(q => q.correctAnswer === undefined || q.correctAnswer === null) ||
                    (isEnunciadoChecked && !enunciadoText.trim())
                  }
                  sx={{
                    backgroundColor: '#6BBDBD',
                    color: 'white',
                    textTransform: 'none',
                    fontWeight: '600',
                    fontFamily: "'Fira Sans', sans-serif",
                    borderRadius: '24px',
                    padding: '8px 24px',
                    '&:hover': {
                      backgroundColor: '#5AA9A9'
                    },
                    '&.Mui-disabled': {
                      backgroundColor: '#E0E0E0',
                      color: '#A0A0A0'
                    }
                  }}
                >
                  Guardar examen
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Container>
      </AppBar>

      {/* Informative Box */}
      {selectedFile && questions.length > 0 && (
        <Box
          sx={{
            backgroundColor: '#E2F1F1',
            padding: '12px 24px',
            margin: '16px 24px 0 24px',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Info sx={{ color: '#026277', fontSize: 20 }} />
          <Typography
            sx={{
              color: '#026277',
              fontFamily: 'Fira Sans',
              fontSize: '14px',
              fontWeight: 500
            }}
          >
            Debes marcar la opción correcta a cada pregunta antes de poder guardar el examen
          </Typography>
        </Box>
      )}

      <Box sx={{
        display: 'flex',
        flex: 1,
        gap: 3,
        p: 3,
        height: 'calc(100vh - 64px)',
        overflow: 'hidden'
      }}>
        {/* Left Grid - Upload Area or PDF Viewer */}
        <Box sx={{
          flex: 1,
          bgcolor: 'white',
          borderRadius: 2,
          border: '1px solid #E2F1F1',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          {selectedFile ? (
            <Box sx={{ width: '100%', height: '100%' }}>
              {fileUrl && (
                <iframe
                  src={`${fileUrl}#navpanes=0&scrollbar=0`}
                  type="application/pdf"
                  title="PDF Viewer"
                  style={{ width: "100%", height: "100%", border: 'none' }}
                  frameBorder="0"
                  loading="lazy"
                />
              )}
            </Box>
          ) : (
            <div
              style={{
                border: "2px dashed #D9D9D9",
                borderRadius: "10px",
                padding: "50px",
                cursor: "pointer",
                width: "80%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "20px"
              }}
              onClick={() => document.getElementById('fileInput').click()}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 24 24"
                fill="none"
                style={{ marginBottom: "16px" }}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.0615 2.7866L19.8314 7.80335C19.9576 7.93647 20.0282 8.11334 20.0282 8.2978V17.0353C20.0282 19.4258 18.0971 21.4444 15.81 21.4444H8.28879C5.86803 21.4444 3.97266 19.5075 3.97266 17.0353V14.6524C3.97266 14.2588 4.28902 13.9393 4.67883 13.9393C5.06864 13.9393 5.385 14.2588 5.385 14.6524V17.0353C5.385 18.7354 6.63258 20.0181 8.28879 20.0181H15.81C17.3316 20.0181 18.6159 18.6517 18.6159 17.0353V8.58401L15.0106 4.79197V6.0357C15.0106 6.99037 15.7799 7.76912 16.7252 7.77102C17.115 7.77198 17.4304 8.09147 17.4295 8.48607C17.4286 8.87878 17.1131 9.19732 16.7233 9.19732H16.7224C15.0003 9.19352 13.5983 7.77578 13.5983 6.0357V3.99325H8.28879C6.71449 3.99325 5.385 5.29022 5.385 6.82587V10.7758C5.385 11.1694 5.06864 11.4889 4.67883 11.4889C4.28902 11.4889 3.97266 11.1694 3.97266 10.7758V6.82587C3.97266 4.51717 5.949 2.56695 8.28879 2.56695H14.2498C14.2589 2.56608 14.2671 2.56362 14.2752 2.5612C14.2848 2.55834 14.2942 2.55554 14.3044 2.55554C14.3155 2.55554 14.3253 2.55849 14.3351 2.56144C14.3429 2.56378 14.3507 2.56611 14.3591 2.56695H14.5521C14.7442 2.56695 14.9278 2.64587 15.0615 2.7866Z"
                  fill="#026277"
                />
              </svg>
              <Typography sx={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "26px",
                fontFamily: "Inter",
                color: "#505050",
                marginBottom: "8px"
              }}>
                Haz click para subir tu PDF
              </Typography>
              <Typography sx={{
                fontSize: "12px",
                color: "#A0A0A0",
                fontFamily: "Inter"
              }}>
                o arrastra y suelta aquí
              </Typography>
            </div>
          )}
          <input
            type="file"
            id="fileInput"
            accept=".pdf"
            style={{ display: "none" }}
            onChange={handleFileSelect}
          />
        </Box>

        {/* Right Grid - Questions */}
        <Box sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          overflow: 'hidden'
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
            <Box>
              <Typography variant="h6" sx={{
                fontFamily: 'Fira Sans',
                color: '#2D2D2D'
              }}>
                Preguntas del examen
              </Typography>
              <Typography sx={{
                fontFamily: 'Fira Sans',
                color: '#666',
                fontSize: '14px',
                mt: 0.5
              }}>
                Crea y edita las preguntas del examen que has subido
              </Typography>
            </Box>
            <Button
              startIcon={<Add />}
              onClick={() => setIsCreatingQuestion(true)}
              sx={{
                backgroundColor: 'white',
                color: '#6BBDBD',
                border: '1px solid #6BBDBD',
                textTransform: 'none',
                fontWeight: '500',
                fontFamily: "'Fira Sans', sans-serif",
                borderRadius: '24px',
                padding: '8px 24px',
                fontSize: '14px',
              }}
            >
              Crear pregunta
            </Button>
          </Box>

          <Box sx={{
            overflowY: 'auto',
            flex: 1,
            pr: 2,
            '&::-webkit-scrollbar': {
              width: '8px',
              backgroundColor: '#F5F5F5',
              borderRadius: '4px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#E2F1F1',
              borderRadius: '4px',
              '&:hover': {
                backgroundColor: '#6BBDBD'
              }
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#F5F5F5',
              borderRadius: '4px'
            }
          }}>
            {!selectedFile ? (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                color: '#666',
                textAlign: 'center',
                p: 3
              }}>
                <Quiz sx={{ fontSize: 48, color: '#E2F1F1', mb: 2 }} />
                <Typography sx={{ fontFamily: 'Fira Sans', fontWeight: 500, mb: 1 }}>
                  No hay preguntas de examen
                </Typography>
                <Typography sx={{ fontFamily: 'Fira Sans', color: '#666', fontSize: '14px' }}>
                  Sube un documento PDF para empezar a crear preguntas
                </Typography>
              </Box>
            ) : isLoading ? (
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1
              }}>
                <CircularProgress size={24} sx={{ color: '#6BBDBD' }} />
              </Box>
            ) : isUploading ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#F5F5F5',
                  borderRadius: '12px',
                  padding: '48px',
                  textAlign: 'center',
                  margin: '24px'
                }}
              >
                <CircularProgress
                  size={40}
                  thickness={4}
                  sx={{
                    color: '#6BBDBD',
                    mb: 3
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: 'Fira Sans',
                    fontSize: '18px',
                    fontWeight: 500,
                    color: '#2D2D2D',
                    mb: 1
                  }}
                >
                  Extrayendo preguntas y opciones de respuesta de tu examen
                </Typography>
                {uploadProgress.current > 0 && (
                  <Typography
                    sx={{
                      fontFamily: 'Fira Sans',
                      fontSize: '14px',
                      color: '#666',
                      mt: 1
                    }}
                  >
                    Página {uploadProgress.current} de {uploadProgress.total}
                  </Typography>
                )}
              </Box>
            ) : hasError ? (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                p={5}
                sx={{
                  textAlign: 'center',
                  color: '#666'
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    mb: 2,
                    fontFamily: 'Fira Sans',
                    color: '#AA161D'
                  }}
                >
                  Ha habido un error al extraer las preguntas
                </Typography>
                {retryCount < MAX_RETRIES ? (
                  <>
                    <Typography
                      sx={{
                        mb: 2,
                        fontFamily: 'Fira Sans'
                      }}
                    >
                      Intento {retryCount} de {MAX_RETRIES}
                    </Typography>
                    <Button
                      onClick={handleRetry}
                      variant="contained"
                      sx={{
                        backgroundColor: '#6BBDBD',
                        color: 'white',
                        textTransform: 'none',
                        fontFamily: 'Fira Sans',
                        borderRadius: '20px',
                        '&:hover': {
                          backgroundColor: '#5AA9A9'
                        }
                      }}
                    >
                      Reintentar
                    </Button>
                  </>
                ) : (
                  <Typography
                    sx={{
                      fontFamily: 'Fira Sans',
                      color: '#666'
                    }}
                  >
                    Se ha excedido el número máximo de intentos. Por favor, inténtalo de nuevo más tarde.
                  </Typography>
                )}
              </Box>
            ) : (
              <>
                {selectedFile && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                    <Checkbox
                      checked={isEnunciadoChecked}
                      onChange={(e) => setIsEnunciadoChecked(e.target.checked)}
                      sx={{ color: '#6BBDBD', '&.Mui-checked': { color: '#6BBDBD' } }}
                    />
                    <Typography sx={{ fontFamily: 'Fira Sans', color: '#2D2D2D' }}>
                      Enunciado examen
                    </Typography>
                  </Box>
                )}

                {isEnunciadoChecked && (
                  <Box sx={{ mb: 2 }}>
                    <TextField
                      fullWidth
                      value={enunciadoText}
                      onChange={(e) => {
                        setEnunciadoText(e.target.value);
                        if (enunciadoError) {
                          setEnunciadoError(false);
                        }
                      }}
                      placeholder="Escribe el enunciado del examen"
                      variant="outlined"
                      size="medium"
                      multiline
                      minRows={3}
                      error={enunciadoError}
                      helperText={enunciadoError ? 'El enunciado no puede estar vacío' : ''}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '8px',
                          backgroundColor: 'white',
                          minHeight: '56px'
                        }
                      }}
                    />
                  </Box>
                )}

                {isCreatingQuestion && (
                  <Card
                    sx={{
                      mb: 2,
                      border: '1px solid #E2F1F1',
                      borderRadius: 1,
                      boxShadow: 'none',
                      position: 'relative'
                    }}
                  >
                    <CardContent sx={{ p: 3, position: 'relative' }}>
                      <Box sx={{
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        display: 'flex',
                        gap: 1,
                        zIndex: 2
                      }}>
                        <IconButton
                          size="small"
                          sx={{
                            color: '#66BB6A',
                            '&:hover': { color: '#45a049' },
                            padding: '4px',
                            backgroundColor: 'white'
                          }}
                          onClick={handleCreateTestQuestion}
                        >
                          <Save sx={{ fontSize: '16px' }} />
                        </IconButton>
                        <IconButton
                          size="small"
                          sx={{
                            color: '#666',
                            '&:hover': { color: '#f44336' },
                            padding: '4px',
                            backgroundColor: 'white'
                          }}
                          onClick={() => {
                            setIsCreatingQuestion(false);
                            setNewQuestion({
                              question_text: '',
                              options: ['', '', '', ''],
                              correctAnswer: 0
                            });
                          }}
                        >
                          <Close sx={{ fontSize: '16px' }} />
                        </IconButton>
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                        <Box>
                          <TextField
                            fullWidth
                            value={newQuestion.question_text}
                            onChange={(e) => {
                              setNewQuestion({ ...newQuestion, question_text: e.target.value });
                              if (formErrors.question_text) {
                                setFormErrors(prev => ({ ...prev, question: false }));
                              }
                            }}
                            variant="standard"
                            placeholder="Escribe la pregunta"
                            sx={{
                              '& .MuiInput-underline:before': {
                                borderBottom: 'none'
                              },
                              '& .MuiInput-underline:hover:before': {
                                borderBottom: 'none'
                              },
                              '& .MuiInput-underline:after': {
                                borderBottomColor: '#E2F1F1'
                              },
                              fontFamily: 'Fira Sans',
                            }}
                          />
                          {formErrors.question_text && (
                            <Typography
                              sx={{
                                color: '#d32f2f',
                                fontSize: '0.75rem',
                                mt: 0.5,
                                fontFamily: 'Fira Sans',
                              }}
                            >
                              La pregunta no puede estar vacía
                            </Typography>
                          )}
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          {newQuestion.options.map((option, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column'
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                  mb: formErrors.options[index] ? 0.5 : 1
                                }}
                              >
                                <Checkbox
                                  checked={index === newQuestion.correctAnswer}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setNewQuestion({ ...newQuestion, correctAnswer: index });
                                    }
                                  }}
                                  sx={{
                                    color: '#6BBDBD',
                                    '&.Mui-checked': {
                                      color: '#6BBDBD',
                                    },
                                  }}
                                />
                                <TextField
                                  fullWidth
                                  value={option}
                                  onChange={(e) => {
                                    const newOptions = [...newQuestion.options];
                                    newOptions[index] = e.target.value;
                                    setNewQuestion({ ...newQuestion, options: newOptions });
                                    if (formErrors.options[index]) {
                                      const newOptionErrors = [...formErrors.options];
                                      newOptionErrors[index] = false;
                                      setFormErrors(prev => ({ ...prev, options: newOptionErrors }));
                                    }
                                  }}
                                  variant="standard"
                                  placeholder={`Opción ${index + 1}`}
                                  sx={{
                                    '& .MuiInput-underline:before': {
                                      borderBottom: 'none'
                                    },
                                    '& .MuiInput-underline:hover:before': {
                                      borderBottom: 'none'
                                    },
                                    '& .MuiInput-underline:after': {
                                      borderBottomColor: '#E2F1F1'
                                    },
                                    fontFamily: 'Fira Sans',
                                  }}
                                />
                              </Box>
                              {formErrors.options[index] && (
                                <Typography
                                  sx={{
                                    color: '#d32f2f',
                                    fontSize: '0.75rem',
                                    ml: 6,
                                    fontFamily: 'Fira Sans',
                                  }}
                                >
                                  La opción {index + 1} no puede estar vacía
                                </Typography>
                              )}
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                )}

                {questions.length > 0 && (
                  <div className="questions-container">
                    {questions.map((question, index) => (
                      <Card
                        key={question.id}
                        sx={{
                          mb: 2,
                          border: '1px solid #E2F1F1',
                          borderRadius: 1,
                          boxShadow: 'none',
                          position: 'relative'
                        }}
                      >
                        <Box sx={{
                          position: 'absolute',
                          top: 16,
                          right: 16,
                          display: 'flex',
                          gap: 1,
                          zIndex: 2
                        }}>
                          {editingQuestionId === question.id ? (
                            <IconButton
                              size="small"
                              sx={{
                                color: '#66BB6A',
                                '&:hover': { color: '#45a049' },
                                padding: '4px',
                                backgroundColor: 'white'
                              }}
                              onClick={() => setEditingQuestionId(null)}
                            >
                              <Save sx={{ fontSize: '16px' }} />
                            </IconButton>
                          ) : (
                            <IconButton
                              size="small"
                              sx={{
                                color: '#666',
                                '&:hover': { color: '#6BBDBD' },
                                padding: '4px',
                                backgroundColor: 'white'
                              }}
                              onClick={() => setEditingQuestionId(question.id)}
                            >
                              <Edit sx={{ fontSize: '16px' }} />
                            </IconButton>
                          )}
                          <IconButton
                            size="small"
                            sx={{
                              color: '#666',
                              '&:hover': { color: '#f44336' },
                              padding: '4px',
                              backgroundColor: 'white'
                            }}
                            onClick={() => handleDeleteClick(question)}
                          >
                            <Delete sx={{ fontSize: '16px' }} />
                          </IconButton>
                        </Box>
                        <CardContent sx={{ p: 3, position: 'relative', marginRight: '45px' }}>
                          {editingQuestionId === question.id ? (
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                              <TextField
                                fullWidth
                                value={question.question_text}
                                onChange={(e) => {
                                  setQuestions(prev => prev.map(q =>
                                    q.id === question.id
                                      ? { ...q, question_text: e.target.value }
                                      : q
                                  ));
                                }}
                                variant="standard"
                                sx={{
                                  '& .MuiInput-underline:before': {
                                    borderBottom: 'none'
                                  },
                                  '& .MuiInput-underline:hover:before': {
                                    borderBottom: 'none'
                                  },
                                  '& .MuiInput-underline:after': {
                                    borderBottomColor: '#E2F1F1'
                                  },
                                  fontFamily: 'Fira Sans',
                                }}
                              />
                              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                {question.options.map((option, optIndex) => (
                                  <Box
                                    key={optIndex}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 1
                                    }}
                                  >
                                    <Checkbox
                                      checked={optIndex === question.correctAnswer}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setQuestions(prev => prev.map(q =>
                                            q.id === question.id
                                              ? { ...q, correctAnswer: optIndex }
                                              : q
                                          ));
                                        }
                                      }}
                                      sx={{
                                        color: '#6BBDBD',
                                        '&.Mui-checked': {
                                          color: '#66BB6A'
                                        }
                                      }}
                                    />
                                    <TextField
                                      fullWidth
                                      value={option}
                                      onChange={(e) => {
                                        setQuestions(prev => prev.map(q =>
                                          q.id === question.id
                                            ? {
                                              ...q,
                                              options: q.options.map((opt, idx) =>
                                                idx === optIndex ? e.target.value : opt
                                              )
                                            }
                                            : q
                                        ));
                                      }}
                                      variant="standard"
                                      placeholder={`Opción ${optIndex + 1}`}
                                      sx={{
                                        '& .MuiInput-underline:before': {
                                          borderBottom: 'none'
                                        },
                                        '& .MuiInput-underline:hover:before': {
                                          borderBottom: 'none'
                                        },
                                        '& .MuiInput-underline:after': {
                                          borderBottomColor: '#E2F1F1'
                                        },
                                        fontFamily: 'Fira Sans',
                                      }}
                                    />
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          ) : (
                            <>
                              <Typography sx={{
                                fontFamily: 'Fira Sans',
                                fontWeight: 500,
                                color: '#2D2D2D',
                                mb: 2
                              }}>
                                {index + 1}. {question.question_text}
                              </Typography>
                              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                {question.options.map((option, optIndex) => (
                                  <Box
                                    key={optIndex}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 1
                                    }}
                                  >
                                    <Checkbox
                                      checked={optIndex === question.correctAnswer}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setQuestions(prev => prev.map(q =>
                                            q.id === question.id
                                              ? { ...q, correctAnswer: optIndex }
                                              : q
                                          ));
                                        }
                                      }}
                                      sx={{
                                        color: '#6BBDBD',
                                        '&.Mui-checked': {
                                          color: '#66BB6A'
                                        }
                                      }}
                                    />
                                    <Typography
                                      sx={{
                                        fontFamily: 'Fira Sans',
                                        color: optIndex === question.correctAnswer ? '#66BB6A' : '#666',
                                        '&::before': {
                                          content: `"${String.fromCharCode(65 + optIndex)}."`,
                                          marginRight: '8px',
                                          fontWeight: 500
                                        }
                                      }}
                                    >
                                      {option}
                                    </Typography>
                                  </Box>
                                ))}
                              </Box>
                            </>
                          )}
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            padding: "24px",
            maxWidth: "400px",
            width: "100%"
          }
        }}
      >
        <DialogTitle sx={{
          padding: "0",
          fontFamily: 'Fira Sans',
        }}>
          <Typography sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: 'Fira Sans',
            color: '#000',
            mb: 1
          }}>
            Confirmar eliminación
          </Typography>
          <Typography sx={{
            fontSize: '14px',
            color: '#A0A0A0',
            fontFamily: 'Fira Sans',
            mb: 2
          }}>
            ¿Estás seguro de que quieres eliminar esta pregunta?
          </Typography>
        </DialogTitle>
        <DialogActions sx={{
          padding: "16px 0 0 0",
          marginTop: 'auto'
        }}>
          <Button
            onClick={() => setDeleteDialogOpen(false)}
            disabled={isDeleting}
            sx={{
              color: '#A0A0A0',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#666'
              }
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            disabled={isDeleting}
            sx={{
              backgroundColor: '#AA161D',
              color: 'white',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              borderRadius: '20px',
              padding: '6px 16px',
              '&:hover': {
                backgroundColor: '#8A1219'
              },
              minWidth: '100px'
            }}
          >
            {isDeleting ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              'Eliminar'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Save Dialog */}
      <Dialog
        open={saveDialogOpen}
        onClose={() => setSaveDialogOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            padding: "24px",
            maxWidth: "500px",
            width: "100%"
          }
        }}
      >
        <DialogTitle sx={{
          padding: "0",
          fontFamily: 'Fira Sans',
          mb: 2
        }}>
          <Typography sx={{
            fontSize: '18px',
            fontWeight: 'bold',
            fontFamily: 'Fira Sans',
            color: '#2D2D2D',
          }}>
            Guardar examen
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ padding: "0" }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box>
              <Typography sx={{
                fontFamily: 'Fira Sans',
                color: '#666',
                fontSize: '14px',
                mb: 1
              }}>
                Nombre del examen
              </Typography>
              <TextField
                fullWidth
                value={examName}
                onChange={(e) => setExamName(e.target.value)}
                placeholder="Escribe un nombre para el examen"
                variant="outlined"
                size="small"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    backgroundColor: '#F8FFFF'
                  }
                }}
              />
            </Box>
            <Box>
              <Typography sx={{
                fontFamily: 'Fira Sans',
                color: '#666',
                fontSize: '14px',
                mb: 1
              }}>
                Selecciona una carpeta
              </Typography>
              <Select
                value={selectedFolder}
                onChange={setSelectedFolder}
                options={folders}
                placeholder="Selecciona una carpeta"
                styles={{
                  control: (base) => ({
                    ...base,
                    borderRadius: '8px',
                    backgroundColor: '#F8FFFF',
                    minHeight: '40px'
                  }),
                  menu: (base) => ({
                    ...base,
                    borderRadius: '8px',
                    marginTop: '4px',
                    zIndex: 9999
                  }),
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isSelected ? '#6BBDBD' : state.isFocused ? '#E2F1F1' : 'white',
                    color: state.isSelected ? 'white' : '#2D2D2D',
                    ':active': {
                      backgroundColor: '#6BBDBD',
                      color: 'white'
                    }
                  })
                }}
                menuPortalTarget={document.body}
                menuPosition="fixed"
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{
          padding: "24px 0 0 0"
        }}>
          <Button
            onClick={() => setSaveDialogOpen(false)}
            sx={{
              color: '#A0A0A0',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#666'
              }
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleSaveConfirm}
            disabled={!examName.trim() || !selectedFolder || isSaving}
            sx={{
              backgroundColor: '#6BBDBD',
              color: 'white',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              borderRadius: '20px',
              padding: '6px 24px',
              '&:hover': {
                backgroundColor: '#5AA9A9'
              },
              '&.Mui-disabled': {
                backgroundColor: '#E0E0E0',
                color: '#A0A0A0'
              }
            }}
          >
            {isSaving ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              'Guardar'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Questions Detected Modal */}
      <Dialog
        open={showQuestionsDetectedModal}
        onClose={() => setShowQuestionsDetectedModal(false)}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            padding: "24px",
            maxWidth: "400px",
            width: "100%"
          }
        }}
      >
        <Box sx={{ textAlign: 'center', py: 2 }}>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 'bold',
              fontFamily: 'Fira Sans',
              color: '#2D2D2D',
              mb: 1
            }}
          >
            ¡Extracción completada!
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontFamily: 'Fira Sans',
              color: '#666',
              mb: 3
            }}
          >
            Se han detectado {questions.length} preguntas en tu examen
          </Typography>
          <Button
            onClick={() => setShowQuestionsDetectedModal(false)}
            sx={{
              backgroundColor: '#6BBDBD',
              color: 'white',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              borderRadius: '20px',
              padding: '8px 32px',
              '&:hover': {
                backgroundColor: '#5AA9A9'
              }
            }}
          >
            Continuar
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default SubirExamen;
