import React, { useState, useEffect } from 'react';
import { Container, Title, Subtitle } from './useStyleNewExam';
import { Box, Button, Paper, Tabs, Tab, Typography, Grid, ListItemIcon, ListItemText, Tooltip, Checkbox, ToggleButtonGroup, ToggleButton, Skeleton, TextField, InputAdornment, FormControl, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../Auth/Authhandle';
import * as handle from "../Main/handle";
import '../Main/style.css';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SelectDocument from './SelectDocument';
import styled from '@emotion/styled';
import Select from 'react-select';
import Dialog from '@mui/material/Dialog';
import { useSubscription } from '../Context/SubscriptionContext';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import PopupTutorialFlashcard from './PopupTutorialFlashcard';
import CustomizeFlashcards from './CustomizeFlashcards';
import { fetchFlashcardsFolders, createSetFlashcards } from '../Api/FlashcardsApi';
import CustomizeTask from './CustomizeTask';
import { useCredits } from '../Context/CreditsContext';
import NeedCredits from '../NoCreditsModal/NeedCredits';
import SearchIcon from '@mui/icons-material/Search';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

const CustomCheckbox = styled(Checkbox)({
  color: '#CCCCCC',
  '&.Mui-checked': {
    color: '#6BBDBD',
  },
  '&:hover': {
    backgroundColor: 'rgba(107, 189, 189, 0.04)',
  },
});

const DIFFICULTY_COLORS = {
  'Sin responder': '#E0E0E0',
  'Fácil': '#4CAF50',
  'Normal': '#2196F3',
  'Difícil': '#FFC107',
  'Muy difícil': '#FF5722'
};

const useStyles = makeStyles(() => ({
  paperLeft: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
    flexGrow: 1,
    height: "100%",
    overflowY: "auto",
    backgroundColor: '#F8FFFF !important',
  },
  tabs: {
    '& .MuiTab-root': {
      textTransform: 'none',
      fontFamily: 'Fira Sans',
      fontSize: '16px',
      fontWeight: 500,
      color: '#666',
      '&.Mui-selected': {
        color: '#6BBDBD',
      },
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#6BBDBD',
    },
  },
}));

const NewFlashcards = () => {
  const { token, user, isAuthenticated } = useAuth();
  const { userData } = useSubscription();
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedDocumentObject, setSelectedDocumentObject] = useState(null);
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [listDocuments, setListDocuments] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState({ value: 'all', label: 'Todas las carpetas' });
  const [showCustomization, setShowCustomization] = useState(false);
  const [showCustomizeTask, setShowCustomizeTask] = useState(false);
  const [showNeedCredits, setShowNeedCredits] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const [showTutorial, setShowTutorial] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredDocuments, setFilteredDocuments] = useState(null);

  const { credits } = useCredits();

  useEffect(() => {
    if (token && user) {
      async function fetchData() {
        setIsLoading(true);
        try {
          const response = await fetchFlashcardsFolders(token, user);
          const data = response?.data || response;
          const transformedDocs = {
            pending_flashcards: data.pending_flashcards || [],
            all_flashcards: data.all_flashcards || []
          };

          setListDocuments(transformedDocs);

          // Get document ID from URL
          const params = new URLSearchParams(location.search);
          const documentParam = params.get('document');

          // If URL has document parameter, find and select that document
          if (documentParam) {
            const documentFromUrl = transformedDocs.all_flashcards.find(
              doc => doc.document_guid === documentParam
            );
            if (documentFromUrl) {
              setSelectedDocument(documentFromUrl.document_guid);
              setSelectedDocumentObject(documentFromUrl);
              setSelectedTask(documentFromUrl.document_guid);
              return;
            }
          }

          // Default selection if no URL parameter or document not found
          if (transformedDocs.all_flashcards.length > 0 && !selectedDocument) {
            setSelectedDocument(transformedDocs.all_flashcards[0].document_guid);
            setSelectedDocumentObject(transformedDocs.all_flashcards[0]);
          }

        } catch (error) {
          console.error('Error fetching flashcard folders:', error);
        } finally {
          setIsLoading(false);
        }
      }
      fetchData();
    }
  }, [token, user, location]);

  useEffect(() => {
    if (selectedDocument && listDocuments?.length > 0) {
      const foundDocument = listDocuments.find(doc => doc.guid === selectedDocument);
      setSelectedDocumentObject(foundDocument);
    } else {
      setSelectedDocumentObject(null);
    }
  }, [listDocuments]);

  useEffect(() => {
    if (!listDocuments?.all_flashcards) {
      setFilteredDocuments(null);
      return;
    }

    let filtered = listDocuments.all_flashcards;

    // Filter by folder
    if (selectedFolder.value !== 'all') {
      filtered = filtered.filter(doc =>
        doc.folder_name === selectedFolder.value
      );
    }

    // Filter by search query
    if (searchQuery) {
      filtered = filtered.filter(doc =>
        doc.document_title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredDocuments(filtered);
  }, [searchQuery, listDocuments, selectedFolder]);

  const handleDocumentSelect = (document) => {
    setSelectedDocument(document.document_guid);
    setSelectedDocumentObject(document);
  };

  const handleCustomizationSave = async (customization) => {
    if (credits === -100 || credits >= 40) {

      if (activeTab === 1) {
        try {
          const flashcardData = {
            set_flashcard_id: selectedDocumentObject?.set_flashcard_id,
            // Add any other necessary data for pending flashcards
          };

          const response = await createSetFlashcards(
            token,
            selectedDocument,
            flashcardData,
            user
          );

          navigate('/flashcards', {
            state: {
              documentGuid: selectedDocument,
              studyMode: customization.studyMode,
              customization: {
                documentTitle: selectedDocumentObject?.document_title,
                folderName: selectedDocumentObject?.folder_name,
                folderColor: selectedDocumentObject?.folder_color,
              },
              language: response?.language,
              flashcardsSet: response
            }
          });
        } catch (error) {
          console.error('Error creating flashcards set:', error);
        }
      }
      if (activeTab === 0) {
        try {
          const flashcardData = {
            num_flashcards: customization.cardCount,
            difficulty: customization.cardTypes,
          };

          const response = await createSetFlashcards(
            token,
            selectedDocument,
            flashcardData,
            user
          );
          navigate('/flashcards', {
            state: {
              documentGuid: selectedDocument,
              studyMode: customization.studyMode,
              customization: {
                cardCount: customization.cardCount,
                documentTitle: selectedDocumentObject?.document_title,
                folderName: selectedDocumentObject?.folder_name,
                folderColor: selectedDocumentObject?.folder_color,
                difficulty: customization.difficulty
              },
              language: response?.language,
              flashcardsSet: response
            }
          });
        } catch (error) {
          console.error('Error creating flashcards set:', error);
        }
      }
    } else {
      setShowNeedCredits(true);
    }
  };

  const calculateEstimatedTime = (flashcardCount) => {
    const minutes = Math.ceil((flashcardCount * 15) / 60);
    return `${minutes} min`;
  };

  const getDummyDistribution = (totalCards) => {
    return {
      'Sin responder': Math.floor(totalCards * 0.4),
      'Fácil': Math.floor(totalCards * 0.2),
      'Normal': Math.floor(totalCards * 0.15),
      'Difícil': Math.floor(totalCards * 0.15),
      'Muy difícil': Math.floor(totalCards * 0.1)
    };
  };

  const getFolderOptions = () => {
    if (!listDocuments?.all_flashcards) return [];
    const folders = new Set(listDocuments.all_flashcards.map(doc => doc.folder_name || 'Sin carpeta'));
    return [
      { value: 'all', label: 'Todas las carpetas' },
      ...Array.from(folders).map(folder => ({
        value: folder,
        label: folder
      }))
    ];
  };

  const renderLoadingSkeleton = () => (
    <Grid container spacing={2}>
      {[1, 2, 3, 4, 5, 6].map((item) => (
        <Grid item xs={12} sm={6} md={4} key={item}>
          <Box
            sx={{
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
              p: 1.5,
              height: '100%',
              backgroundColor: 'background.paper',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Skeleton variant="circular" width={32} height={32} sx={{ mr: 2 }} />
              <Box sx={{ width: '100%' }}>
                <Skeleton variant="text" width="80%" height={24} />
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
                  <Skeleton variant="text" width={80} height={20} />
                  <Skeleton variant="text" width={60} height={20} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  );

  const handleSiguiente = () => {
    setShowCustomization(true);
    // Here will go the logic for showing customization
  };

  const handleTabChange = (e, newValue) => {
    setActiveTab(newValue);

    setSelectedTask(null);
  };

  const handleButtonClick = () => {
    if (activeTab === 1) {
      // For pending flashcards, show CustomizeTask
      setShowCustomizeTask(true);
    } else {
      // For regular flashcards, show customization dialog
      handleSiguiente();
    }
  };

  const calculateCompletionPercentage = (doc) => {
    const completedCards = (doc.flashcards_easy || 0) + (doc.flashcards_normal || 0);
    const totalCards = completedCards + (doc.flashcards_hard || 0) + (doc.flashcards_veryhard || 0) + (doc.flashcards_not_started || 0);

    if (totalCards === 0) return 0;
    return Math.round((completedCards / totalCards) * 100);
  };

  const calculateTotalFlashcards = (doc) => {
    // Initial slots for new and very hard flashcards
    let total = Math.min(20, doc.flashcards_not_started || 0); // new flashcards (max 20)
    total += Math.min(50, doc.flashcards_very_hard || 0); // very hard flashcards (max 50)

    // Calculate remaining slots (70 - new - veryhard)
    let remainingSlots = 70 - total;

    // Add hard flashcards
    if (remainingSlots > 0) {
      total += Math.min(remainingSlots, doc.flashcards_hard || 0);
      remainingSlots -= Math.min(remainingSlots, doc.flashcards_hard || 0);
    }

    // Add normal flashcards
    if (remainingSlots > 0) {
      total += Math.min(remainingSlots, doc.flashcards_normal || 0);
      remainingSlots -= Math.min(remainingSlots, doc.flashcards_normal || 0);
    }

    // Add easy flashcards
    if (remainingSlots > 0) {
      total += Math.min(remainingSlots, doc.flashcards_easy || 0);
      remainingSlots -= Math.min(remainingSlots, doc.flashcards_easy || 0);
    }

    // Add any remaining not started flashcards if there are still slots
    if (remainingSlots > 0) {
      total += Math.min(remainingSlots, Math.max(0, (doc.flashcards_not_started || 0) - 20));
    }

    return total;
  };
  if (userData?.plan_name?.startsWith("Basic")) {
    return (
      <Container style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "1000px",
        width: "100%",
        position: 'relative',
        zIndex: 1,
        margin: "0 auto",
        paddingBottom: "0px"
      }}>
        <Typography variant="h6" color="textSecondary" align="center" sx={{ mt: 4 }}>
          Solo puedes realizar tarjetas de memoria con el plan premium
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: "20px" }}>
          <Button variant="contained" color="primary" sx={{
            bgcolor: '#6BBDBD',
            width: "150px",
            color: 'white',
            textTransform: 'none',
            fontSize: "14px",
            borderRadius: "20px",
            padding: "6px 22px",
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            '&:hover': {
              bgcolor: '#5AACAC',
            },
            '&.Mui-disabled': {
              bgcolor: '#cccccc',
              color: '#666666'
            }
          }}
            onClick={() => navigate('/plans')}>Elegir plan</Button>
        </Box>
      </Container>
    );
  }
  return (
    <Container style={{
      display: "flex",
      flexDirection: "column",
      maxWidth: "1000px",
      width: "100%",
      position: 'relative',
      zIndex: 1,
      margin: "0 auto",
      paddingBottom: "0px"
    }}>
      <PopupTutorialFlashcard
        open={showTutorial}
        onClose={() => setShowTutorial(false)}
      />
      {
        showNeedCredits && (
          <NeedCredits
            initialOpen={showNeedCredits}
            onClose={() => setShowNeedCredits(false)}
          />
        )
      }
      <Paper elevation={0} className={classes.paperLeft}>
        {!showCustomization && !showCustomizeTask ? (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '30px' }}>
              <Title>Memoriza con tarjetas de memoria</Title>
              <Subtitle sx={{ marginTop: '8px', marginBottom: '12px' }}>
                Retén toda la información de tus documentos de forma activa 
              </Subtitle>
            </Box>

            {showBanner && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  backgroundColor: '#F0F9F9',
                  padding: '12px 20px',
                  borderRadius: '8px',
                  marginBottom: '60px',
                  border: '1px dashed #6BBDBD',
                  position: 'relative'
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <InfoOutlinedIcon sx={{ color: '#6BBDBD' }} />
                  <Typography
                    sx={{
                      color: '#333',
                      fontFamily: "'Fira Sans', sans-serif",
                      fontSize: '14px'
                    }}
                  >
                    ¿Primera vez usando tarjetas de memoria? Aprende cómo sacarles el máximo partido
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Button
                    variant="contained"
                    onClick={() => setShowTutorial(true)}
                    sx={{
                      color: '#fff',
                      backgroundColor: '#6BBDBD',
                      textTransform: 'none',
                      fontFamily: "'Inter', sans-serif",
                      fontWeight: 600,
                      fontSize: '14px',
                      borderRadius: '20px',
                      padding: '6px 16px',
                      boxShadow: 'none',
                      '&:hover': {
                        backgroundColor: '#5AACAC',
                        boxShadow: 'none',
                      }
                    }}
                  >
                    Ver tutorial
                  </Button>
                  <CloseIcon
                    sx={{
                      color: '#666',
                      cursor: 'pointer',
                      fontSize: '20px',
                      '&:hover': {
                        color: '#333'
                      }
                    }}
                    onClick={() => setShowBanner(false)}
                  />
                </Box>
              </Box>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography
                sx={{
                  fontFamily: 'Fira Sans',
                  fontSize: '16px',
                  fontWeight: 500,
                  color: '#666'
                }}
              >
                Selecciona el documento
              </Typography>

              {selectedDocument && !showCustomization && (
                <Button
                  variant="contained"
                  onClick={handleButtonClick}
                  disabled={!selectedTask}
                  sx={{
                    backgroundColor: '#6BBDBD',
                    color: 'white',
                    textTransform: 'none',
                    fontWeight: '600',
                    fontFamily: "'Inter', sans-serif",
                    borderRadius: '24px',
                    padding: '8px 32px',
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    '&:hover': {
                      backgroundColor: '#5AACAC',
                    },
                    '&.Mui-disabled': {
                      backgroundColor: '#cccccc',
                      color: '#ffffff',
                    },
                  }}
                >
                  Siguiente
                </Button>
              )}
            </Box>

            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 3 }}>
              <FormControl sx={{ minWidth: 200 }}>
                <Select
                  value={selectedFolder}
                  onChange={(option) => setSelectedFolder(option)}
                  options={getFolderOptions()}
                  placeholder="Filtrar por carpeta"
                  isDisabled={isLoading}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      height: '40px',
                      borderRadius: '12px',
                      borderColor: state.isFocused ? '#6BBDBD' : '#E7E7E7',
                      boxShadow: 'none',
                      '&:hover': {
                        borderColor: '#6BBDBD'
                      }
                    }),
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: state.isSelected ? '#6BBDBD' : state.isFocused ? '#e8f4f4' : 'white',
                      color: state.isSelected ? 'white' : '#333',
                      fontFamily: 'Inter',
                      '&:active': {
                        backgroundColor: '#6BBDBD'
                      }
                    }),
                    singleValue: (baseStyles) => ({
                      ...baseStyles,
                      fontFamily: 'Inter'
                    }),
                    placeholder: (baseStyles) => ({
                      ...baseStyles,
                      fontFamily: 'Inter',
                      color: '#666'
                    })
                  }}
                />
              </FormControl>
              <TextField
                placeholder="Buscar documentos..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                sx={{
                  flex: 1,
                  backgroundColor: 'white',
                  '& .MuiOutlinedInput-root': {
                    height: '40px',
                    '& fieldset': {
                      borderColor: '#E7E7E7',
                      borderRadius: '12px',
                    },
                    '&:hover fieldset': {
                      borderColor: '#6BBDBD',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#6BBDBD',
                    },
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: '#666' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box
              className="custom-scrollbar"
              sx={{
                height: 400,
                overflowY: 'auto',
                overflowX: 'hidden',
                mb: 2,
                pr: 2,
              }}
            >
              {isLoading ? (
                renderLoadingSkeleton()
              ) : (filteredDocuments || listDocuments?.all_flashcards)?.filter(doc => doc.total_flashcards > 0).length === 0 ? (
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  width: '100%',
                  mt: 4,
                  gap: 2
                }}>
                  <DescriptionOutlinedIcon 
                    sx={{ 
                      fontSize: '64px',
                      color: '#CCCCCC'
                    }}
                  />
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      color: '#666',
                      fontFamily: "'Fira Sans', sans-serif",
                      fontSize: '16px',
                      textAlign: 'center'
                    }}
                  >
                    Para generar flashcards debes subir primero un documento
                  </Typography>
                </Box>
              ) : (
                <Grid container spacing={2}>
                  {(filteredDocuments || listDocuments?.all_flashcards)
                    ?.filter(doc => doc.total_flashcards > 0)
                    .map((doc) => (
                      <Grid item xs={12} sm={6} md={4} key={doc.document_guid}>
                        <Box
                          onClick={() => {
                            setSelectedTask(selectedTask === doc.document_guid ? null : doc.document_guid);
                            handleDocumentSelect(doc);
                          }}
                          sx={{
                            cursor: 'pointer',
                            border: '1px solid',
                            borderColor: 'divider',
                            borderRadius: 1,
                            p: 1.5,
                            height: '100%',
                            backgroundColor: 'background.paper',
                            '&:hover': {
                              backgroundColor: '#f5f5f5'
                            }
                          }}
                        >
                          <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                            pr: 0
                          }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                              <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
                                <CustomCheckbox
                                  edge="start"
                                  checked={selectedTask === doc.document_guid}
                                  tabIndex={-1}
                                  disableRipple
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Box
                                      sx={{
                                        width: 16,
                                        height: 16,
                                        borderRadius: 1,
                                        bgcolor: doc.folder_color || '#6BBDBD',
                                        flexShrink: 0
                                      }}
                                    />
                                    <Tooltip title={doc.document_title || 'Untitled Document'}>
                                      <Typography
                                        sx={{
                                          fontWeight: 500,
                                          fontFamily: "'Fira Sans', sans-serif",
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                          maxWidth: '300px'
                                        }}
                                      >
                                        {doc.document_title || 'Untitled Document'}
                                      </Typography>
                                    </Tooltip>
                                  </Box>
                                }
                                secondary={
                                  <Box>
                                    <Typography sx={{

                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      maxWidth: '300px',
                                      textAlign: 'left'
                                    }} >
                                      {`${doc.total_flashcards} flashcards`}
                                    </Typography>
                                  </Box>
                                }
                              />
                              <Typography sx={{
                                color: '#6BBDBD',
                                fontSize: '20px',
                                fontWeight: 500,
                                fontFamily: "'Fira Sans', sans-serif",
                                marginRight: "10px"
                              }}>
                                {`${calculateCompletionPercentage(doc)}%`}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              )}
            </Box>
          </>
        ) : showCustomizeTask ? (
          <Box sx={{ width: '100%', pt: 3 }}>
            <CustomizeTask
              onClose={() => setShowCustomizeTask(false)}
              onSave={handleCustomizationSave}
              documentStats={selectedDocumentObject}
              documentName={selectedDocumentObject?.title}
            />
          </Box>
        ) : (
          <>
            <Box sx={{ width: '100%', pt: 3 }}>
              <CustomizeFlashcards
                onClose={() => setShowCustomization(false)}
                onSave={handleCustomizationSave}
                documentStats={selectedDocumentObject}
                documentName={selectedDocumentObject?.title}
              />
            </Box>
          </>
        )}
      </Paper>
    </Container>
  );
};

export default NewFlashcards; 