import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useAuth } from "../Auth/Authhandle";

const PointsContext = createContext();

export const usePoints = () => useContext(PointsContext);

export const PointsProvider = ({ children }) => {
    const [allPointsData, setAllPointsData] = useState(null);
    const [todayExams, setTodayExams] = useState(0);
    const { token, user, isAuthenticated } = useAuth();
    const [allExams, setAllExams] = useState(null);  // Add this new state

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

    const fetchAllPointsData = useCallback(async () => {
        let isToday = false;
        if (token && user) {
            try {
                const response = await fetch(`${BACKEND_URL}/api_v1/exams/?user_id=${user['id']}&objective=true`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setAllExams(data);  // Store all exams data

                    const today = new Date();
                    const answeredExams = Object.values(data).filter(exam => exam.exam_answered_date);

                    // Calculate points only for today
                    const todayPoints = answeredExams
                        .filter(exam => new Date(exam.exam_answered_date).toDateString() === today.toDateString())
                        .reduce((sum, exam) => sum + exam.num_questions, 0);

                    // Create activity levels object
                    const activityData = {};

                    // Process all answered exams
                    answeredExams.forEach(exam => {
                        const examDate = new Date(exam.exam_answered_date);
                        const dateKey = examDate.toISOString().split('T')[0]; // Format: YYYY-MM-DD

                        // Add points for this date
                        activityData[dateKey] = (activityData[dateKey] || 0) + exam.num_questions;
                    });

                    // Convert points to activity levels (0-4)
                    const convertedActivityData = {};
                    Object.entries(activityData).forEach(([date, points]) => {
                        let level;
                        if (points === 0) level = 0;
                        else if (points > 200) level = 4;
                        else if (points > 150) level = 3;
                        else if (points > 50) level = 2;
                        else if (points > 0) level = 1;
                        else level = 0;

                        convertedActivityData[date] = level;
                    });

                    setAllPointsData(convertedActivityData);
                    setTodayExams(todayPoints);  // You might need to add this state if not already present
                } else {
                    console.error('Failed to fetch exams data');
                }
            } catch (error) {
                console.error('Error fetching exams data:', error);
            }
        }
    }, [token, user, BACKEND_URL]);

    return (
        <PointsContext.Provider value={{ allPointsData, fetchAllPointsData, allExams, todayExams }}>
            {children}
        </PointsContext.Provider>
    );
};