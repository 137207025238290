import React, { useCallback, useState, useEffect } from 'react';
import ReactFlow, {
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  MarkerType,
  useReactFlow,
  Controls
} from 'react-flow-renderer';
import 'react-flow-renderer/dist/style.css';
import {
  Paper,
  Typography,
  Divider,
  Button,
  IconButton,
  List,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
  Snackbar,
  CircularProgress,
  Skeleton,
  Box
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import RemoveIcon from '@mui/icons-material/Remove';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import CreateIcon from '@mui/icons-material/Create';
import QuizIcon from '@mui/icons-material/Help';
import ChatIcon from '@mui/icons-material/Chat';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import DownloadIcon from '@mui/icons-material/Download';
import { postKeyConcepts, editNode } from '../../Api/SchemaFlowApi';

import CustomNode from '../CustomNode';
import ContextMenu from '../ContextMenu';
import Transition from './Transition';
import { updateNode, deleteNode, fetchSchemaById } from '../../Api/mentalMapService';
import { useSession, useUser } from '@supabase/auth-helpers-react';
import { useParams } from 'react-router-dom';
import {
  Root,
  ContentWrapper,
  Sidebar,
  FlowWrapper,
  AddButton,
  MinimizeButton,
  RestoreButton,
  HeaderContainer,
  Title,
  StyledContent,
  ElementItem,
  ElementHeader,
  ElementTitle,
  ElementDescription,
  MenuButton,
  StyledMenuItem,
  StyledDialog,
  GlobalStyles
} from '../styles/StyledComponents';
import { WebSocketContext } from "../../WebSocketProvider";
import { useContext } from 'react';
import MarkdownRender from "../../NewComponents/MarkdownAnswer";
import { useCredits } from "../../Context/CreditsContext";
import { createGlobalStyle } from 'styled-components';
import PopupGenerarEsquema from '../PopupGenerarEsquema';
import html2canvas from 'html2canvas';

const AnimationStyles = createGlobalStyle`
  .dot {
    width: 8px;
    height: 8px;
    margin: 0 4px;
    border-radius: 50%;
    background-color: #2c3e50;
    display: inline-block;
    animation: bounce 1.4s infinite ease-in-out both;
  }
  
  .dot1 {
    animation-delay: -0.32s;
  }
  
  .dot2 {
    animation-delay: -0.16s;
  }
  
  @keyframes bounce {
    0%, 80%, 100% {
      transform: scale(0);
    } 
    40% {
      transform: scale(1);
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

// Add LoadingAnimation component
const LoadingAnimation = () => {
  return (
    <div style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 999,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px'
    }}>
      <CircularProgress
        size={60}
        thickness={4}
        style={{
          color: '#6BBDBD',
          animation: 'rotate 1.5s linear infinite'
        }}
      />
      <Typography
        style={{
          fontFamily: '"Fira Sans", sans-serif',
          color: '#2c3e50',
          fontSize: '1.1rem'
        }}
      >
        Cargando esquema...
      </Typography>
    </div>
  );
};

function Flow({ docInfo, schema_list }) {
  const navigate = useNavigate();
  const session = useSession();
  const user = useUser();
  const { schema_id } = useParams(); // Get schema_id from URL

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [sidebarHidden, setSidebarHidden] = useState(false);
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [dialogOpenKeyConcepts, setDialogOpenKeyConcepts] = useState(false);
  const [chatOpen, setChatOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [termsList, setTermsList] = useState([]);
  const [selectedTerms, setSelectedTerms] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { fitView, setViewport } = useReactFlow();
  const [isLoadingKeyConcepts, setIsLoadingKeyConcepts] = useState(false);
  const { wsChat, chatMessageCallback } = useContext(WebSocketContext);
  const { updateCredits } = useCredits();
  const [ongoingStream, setOngoingStream] = useState({ id: null, content: '' });
  const [openGeneratePopup, setOpenGeneratePopup] = useState(false);
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [exportWidth, setExportWidth] = useState(1920);
  const [exportHeight, setExportHeight] = useState(1080);
  const [exportZoom, setExportZoom] = useState(1);
  const [useAutoSize, setUseAutoSize] = useState(true);

  // Memoize nodeTypes to prevent recreation on every render
  const nodeTypes = React.useMemo(() => ({ custom: CustomNode }), []);

  const handleMinimize = () => {
    setSidebarHidden(true);
  };

  const handleRestore = () => {
    setSidebarHidden(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const handleDialogOpenKeyConcepts = async () => {
    const selected = nodes.find(node => node.selected);
    if (!selected) {
      setSnackbarMessage('Por favor, selecciona un nodo primero');
      setSnackbarOpen(true);
      return;
    }

    setDialogOpenKeyConcepts(true);
    setIsLoadingKeyConcepts(true);
    setSelectedNode(selected);

    try {
      const text = "Label: " + selected.data.label + " Description: " + selected.data.description;

      const response = await postKeyConcepts(session?.access_token, user, text);
      setTermsList(response);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoadingKeyConcepts(false);
    }
  };

  const handleDialogCloseKeyConcepts = () => {
    setDialogOpenKeyConcepts(false);
  };

  const handleTermSelect = (term) => {
    setSelectedTerms(prev => ({
      ...prev,
      [term]: !prev[term]
    }));
  };

  const saveNodeToBackend = async (nodeData) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api_v1/mental-map/${schema_id}/node/${nodeData.id}/?user_id=${user?.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session?.access_token}`,
        },
        body: JSON.stringify({
          node: nodeData,
          parent_node: selectedNode.id,  // Include parent node ID
          edges: nodeData.edges,
          user_id: user?.id
        })
      });

      if (!response.ok) {
        throw new Error('Failed to save node');
      }

      return await response.json();
    } catch (error) {
      console.error('Error saving node:', error);
      throw error;
    }
  };

  const createNodesFromTerms = async () => {

    if (!selectedNode || !Object.keys(selectedTerms).some(term => selectedTerms[term])) {
      setSnackbarMessage('Por favor, selecciona un nodo');
      setSnackbarOpen(true);
      return;
    }

    try {
      const selectedTermsList = Object.entries(selectedTerms)
        .filter(([_, isSelected]) => isSelected)
        .map(([term]) => term);

      // Enhanced horizontal positioning parameters for better spacing
      const HORIZONTAL_DISTANCE = 550;  // Adjusted from 650 to 550
      const VERTICAL_SPACING = 240;     // Adjusted from 280 to 240
      
      // Calculate total height needed for all new nodes
      const totalHeight = selectedTermsList.length * VERTICAL_SPACING;
      // Calculate the starting Y position to center the group around parent
      const startY = selectedNode.position.y - (totalHeight / 2) + (VERTICAL_SPACING / 2);
      
      const newNodes = selectedTermsList.map((term, index) => {
        const nodeId = `${selectedNode.id}-${Date.now()}-${index}`;
        
        // Position new nodes in a vertical column to the right of parent
        const x = selectedNode.position.x + HORIZONTAL_DISTANCE;
        const y = startY + (index * VERTICAL_SPACING);

        const termDef = termsList.find(t => t.term === term);

        return {
          id: nodeId,
          type: 'custom',
          draggable: true,
          connectable: true,
          selectable: true,
          selected: false,
          position: { x, y },
          data: {
            label: term,
            description: termDef ? termDef.definition : '',
            onEdit: () => handleEditar({ id: nodeId, data: { label: term, description: termDef ? termDef.definition : '' } }),
            onDelete: () => handleBorrar({ id: nodeId, data: { label: term, description: termDef ? termDef.definition : '' } }),
          },
          parentNode: selectedNode.id, // Add parent node reference
        };
      });

      const newEdges = newNodes.map(node => ({
        id: `e${selectedNode.id}-${node.id}`,
        source: selectedNode.id,
        target: node.id,
        animated: false,  // Change to false to remove dashed animation
        type: 'bezier',  // Use bezier for smoother horizontal curves
        style: { 
          stroke: '#6BBDBD',
          strokeWidth: 2,
        },
        markerEnd: {
          type: MarkerType.ArrowClosed,
          color: '#6BBDBD',
          width: 20,
          height: 20,
        },
      }));

      // Save each new node to the backend
      await Promise.all(newNodes.map(async (node) => {
        try {
          await saveNodeToBackend({
            ...node,
            edges: newEdges.filter(edge => edge.target === node.id)
          });
        } catch (error) {
          console.error(`Error saving node ${node.id}:`, error);
          throw error;
        }
      }));

      // Update local state after successful save
      setNodes(prevNodes => [...prevNodes, ...newNodes]);
      setEdges(prevEdges => [...prevEdges, ...newEdges]);
      setSelectedTerms({});
      setDialogOpenKeyConcepts(false);

      // Re-arrange the entire tree for optimal layout after adding new nodes
      setTimeout(() => {
        // Get the current state of nodes and edges after the update
        const updatedNodes = [...nodes, ...newNodes];
        const updatedEdges = [...edges, ...newEdges]; 
        
        // Re-arrange all nodes for optimal layout
        const rearrangedNodes = arrangeNodesInTree(updatedNodes, updatedEdges);
        setNodes(rearrangedNodes);
        
        // Fit view after rearrangement
        setTimeout(() => {
          fitView({ 
            padding: 0.4, 
            includeHiddenNodes: false 
          });
        }, 100);
      }, 200);

      // Show success message
      setSnackbarMessage('Nodos guardados correctamente');
      setSnackbarOpen(true);

    } catch (error) {
      console.error('Error creating nodes:', error);
      setSnackbarMessage('Error al guardar los nodos');
      setSnackbarOpen(true);
    }
  };

  const handleTerminosClaveClick = () => {
    const selected = nodes.find(node => node.selected);
    if (!selected) {
      setSnackbarMessage('Please select a node first');
      setSnackbarOpen(true);
      return;
    }
    setSelectedNode(selected);
    setDialogOpenKeyConcepts(true);
  };

  const handlePreguntarIAClick = () => {
    const selected = nodes.find(node => node.selected);
    if (!selected) {
      setSnackbarMessage('Por favor, selecciona un nodo primero');
      setSnackbarOpen(true);
      return;
    }

    setChatMessages([
      {
        type: 'system',
        content: selected.data.description
      }
    ]);
    setSelectedNode(selected);
    setChatOpen(true);
  };

  const handleChatClose = () => {
    setChatOpen(false);
    setMessage('');
  };

  const handleSendMessage = () => {
    if (!message.trim()) return;

    setChatMessages(prev => [...prev, {
      type: 'user',
      content: message
    }]);

    setIsLoading(true);

    // Send message through WebSocket
    wsChat.current.send(JSON.stringify({
      message: message,
      folder_id: docInfo?.folder,
      document_id: docInfo?.guid,
      user_id: user?.id,
      schema: true,
      node: selectedNode?.data.label + " " + selectedNode?.data.description
    }));

    setMessage('');
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Add this new function to arrange nodes in a tree layout
  const arrangeNodesInTree = (nodes, edges) => {
    const BASE_LEVEL_WIDTH = 550;  // Adjusted from 650 to 550
    const NODE_HEIGHT = 200;       // Adjusted from 250 to 200
    const ROOT_SPACING = 400;      // Reverted from 500 to 400
    const MIN_HORIZONTAL_GAP = 350; // Adjusted from 450 to 350
    const NODE_PADDING = 60;       // Adjusted from 80 to 60

    // Create a map of nodes by their IDs for quick lookup
    const nodeMap = new Map(nodes.map(node => [node.id, {
      ...node,
      children: [],
      level: 0,
      // Estimate node width based on content length with more buffer space
      estimatedWidth: Math.max(
        350, // Adjusted from 400 to 350
        Math.ceil((node.data.label.length + (node.data.description?.length || 0)) / 45) * 35 // Adjusted calculation
      ),
      // Estimate node height for collision detection with more buffer space
      estimatedNodeHeight: Math.max(
        140, // Adjusted from 150 to 140
        Math.ceil((node.data.description?.length || 0) / 90) * 45 + 90 // Adjusted calculation
      )
    }]));

    // Build parent-child relationships
    edges.forEach(edge => {
      const parent = nodeMap.get(edge.source);
      const child = nodeMap.get(edge.target);
      if (parent && child) {
        parent.children.push(child);
      }
    });

    // Find root nodes (nodes with no incoming edges)
    const rootNodes = nodes.filter(node =>
      !edges.some(edge => edge.target === node.id)
    );

    // Calculate the total height needed for each subtree with improved spacing
    const calculateSubtreeHeight = (node) => {
      const nodeData = nodeMap.get(node.id);
      
      // If no children, return the node's height plus padding
      if (nodeData.children.length === 0) {
        return nodeData.estimatedNodeHeight + NODE_PADDING;
      }
      
      // Sort children by complexity for consistent calculation
      const sortedChildren = sortChildrenByComplexity(nodeData.children);
      
      // Calculate total height required by children
      let totalChildrenHeight = 0;
      sortedChildren.forEach(child => {
        totalChildrenHeight += calculateSubtreeHeight(child) + NODE_PADDING;
      });
      
      // Subtract extra padding from the last child
      if (sortedChildren.length > 0) {
        totalChildrenHeight -= NODE_PADDING;
      }
      
      // Return max of children height and the node's own height requirement
      return Math.max(nodeData.estimatedNodeHeight, totalChildrenHeight);
    };

    // Calculate the maximum width needed for each level
    const calculateLevelWidths = () => {
      const levelWidths = new Map();

      const processNode = (node, level) => {
        const nodeData = nodeMap.get(node.id);
        const currentWidth = levelWidths.get(level) || 0;
        levelWidths.set(level, Math.max(currentWidth, nodeData.estimatedWidth));

        nodeData.children.forEach(child => processNode(child, level + 1));
      };

      rootNodes.forEach(node => processNode(node, 0));
      return levelWidths;
    };

    const levelWidths = calculateLevelWidths();

    // Calculate cumulative width for a given level (horizontal distance from root)
    const getCumulativeWidth = (level) => {
      let width = 0;
      for (let i = 0; i < level; i++) {
        width += (levelWidths.get(i) || 0) + MIN_HORIZONTAL_GAP;
      }
      return width;
    };

    // Helper function to sort children by their complexity
    const sortChildrenByComplexity = (children) => {
      return [...children].sort((a, b) => {
        const aData = nodeMap.get(a.id);
        const bData = nodeMap.get(b.id);
        // Sort by number of descendants (more complex trees first)
        return bData.children.length - aData.children.length;
      });
    };

    // Recursive function to assign levels and positions with improved spacing
    const assignPositions = (node, level = 0, startY = 0) => {
      const nodeData = nodeMap.get(node.id);
      nodeData.level = level;

      // Calculate X position based on cumulative widths (level depth from left to right)
      const xPosition = getCumulativeWidth(level);

      // Sort children by complexity to optimize positioning
      const sortedChildren = sortChildrenByComplexity(nodeData.children);

      // First, calculate the total height of all children and their subtrees
      if (sortedChildren.length === 0) {
        // If no children, just position at the given Y
        nodeData.position = {
          x: xPosition,
          y: startY + (nodeData.estimatedNodeHeight / 2)
        };
        return;
      }

      // Position children first to determine their Y positions
      let currentY = startY;
      const childPositions = [];

      // First pass: position all children and collect their positions
      sortedChildren.forEach(child => {
        const childData = nodeMap.get(child.id);
        const childHeight = calculateSubtreeHeight(child);
        
        assignPositions(child, level + 1, currentY);
        
        // Store the child's vertical center position
        childPositions.push({
          y: childData.position.y,
          height: childHeight
        });
        
        currentY += childHeight + NODE_PADDING;
      });

      // Calculate the center point between the first and last child
      if (childPositions.length > 0) {
        const firstChildY = childPositions[0].y;
        const lastChildY = childPositions[childPositions.length - 1].y;
        
        // Position parent node vertically centered between its children
        nodeData.position = {
          x: xPosition,
          y: (firstChildY + lastChildY) / 2
        };
      } else {
        // Fallback (shouldn't happen due to the check above)
        nodeData.position = {
          x: xPosition,
          y: startY + (nodeData.estimatedNodeHeight / 2)
        };
      }
    };

    // Process each root node with improved spacing
    let currentRootY = 50; // Start with some padding from the top
    rootNodes.forEach(rootNode => {
      const rootHeight = calculateSubtreeHeight(rootNode);
      assignPositions(rootNode, 0, currentRootY);
      currentRootY += rootHeight + ROOT_SPACING;
    });

    // Create new array of positioned nodes
    const positionedNodes = nodes.map(node => ({
      ...node,
      position: nodeMap.get(node.id).position
    }));

    return positionedNodes;
  };

  // Modify the loadMentalMapData function
  const loadMentalMapData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await fetchSchemaById(session?.access_token, user, schema_id);
      if (data) {
        // Arrange nodes in a horizontal tree layout (left-to-right progression)
        const arrangedNodes = arrangeNodesInTree(data.nodes || [], data.edges || []);
        setNodes(arrangedNodes);
        setEdges(data.edges || []);

        // Fit view after nodes are positioned
        setTimeout(() => {
          fitView({ padding: 0.2 });
        }, 100);
      }
    } catch (error) {
      console.error('Error loading mental map data:', error);
      setSnackbarMessage('Error loading mental map data');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  }, [session?.access_token, user, schema_id]);

  useEffect(() => {
    if (session?.access_token && user) {
      loadMentalMapData();
    }
  }, [session?.access_token, user, loadMentalMapData]);

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );

  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const handleEditar = async (nodeData) => {
    try {
      // Find the existing node in the current nodes state
      const originalNode = nodes.find(n => n.id === nodeData.id);

      if (!originalNode) {
        console.error('Node not found:', nodeData.id);
        return;
      }

      // Create the updated node with all required properties
      const updatedNode = {
        ...originalNode,
        data: {
          ...originalNode.data,
          label: nodeData.data.label,
          description: nodeData.data.description,
        },
        id: nodeData.id,
        type: 'custom',
        position: originalNode.position,
        edges: edges.filter(edge => edge.source === nodeData.id || edge.target === nodeData.id)
      };

      // Immediately update the UI optimistically
      setNodes(nds =>
        nds.map(node => node.id === nodeData.id ? updatedNode : node)
      );

      // Make the API call in the background
      await editNode(
        session?.access_token,
        user,
        schema_id,
        nodeData.id,
        updatedNode
      );

    } catch (error) {
      // If the API call fails, revert to the original node
      // setNodes(nds => 
      //   nds.map(node => 
      //     node.id === nodeData.id ? originalNode : node
      //   )
      // );

      console.error('Error updating node:', error);
    }
  };

  const handleBorrar = async (node) => {
    try {
      await deleteNode(session?.access_token, user, node.id);
      setNodes(prevNodes => prevNodes.filter(n => n.id !== node.id));
      setEdges(prevEdges => prevEdges.filter(e => e.source !== node.id && e.target !== node.id));
      setSnackbarMessage('Node deleted successfully');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error deleting node:', error);
      setSnackbarMessage('Error deleting node');
      setSnackbarOpen(true);
    }
  };

  // Add this effect to handle WebSocket messages
  useEffect(() => {
    if (chatMessageCallback) {
      setIsLoading(false);
      let sender = chatMessageCallback.name;

      switch (chatMessageCallback.event) {
        case 'on_parser_start':
          setOngoingStream({ id: chatMessageCallback.run_id, content: '' });
          setChatMessages(prev => [...prev, { type: 'assistant', content: '', id: chatMessageCallback.run_id }]);
          break;
        case 'on_parser_stream':
          if (ongoingStream.id === chatMessageCallback.run_id) {
            const newContent = ongoingStream.content + chatMessageCallback.data.chunk;
            setOngoingStream(prev => ({ ...prev, content: newContent }));
            setChatMessages(prev => {
              const newMessages = [...prev];
              const index = newMessages.findIndex(msg => msg.id === chatMessageCallback.run_id);
              if (index !== -1) {
                newMessages[index] = { ...newMessages[index], content: newContent };
              }
              return newMessages;
            });
          }
          break;
        case 'on_parser_end':
          updateCredits();
          break;
        case 'Error':
          setChatMessages(prev => [...prev, { type: 'assistant', content: "No tienes créditos suficientes" }]);
          break;
        default:
          console.log("Received unknown event", chatMessageCallback.event);
      }
    }
  }, [chatMessageCallback]);

  // Add this component after the Flow function declaration and before other functions
  const TypingAnimation = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginBottom: "10px",
        }}
      >
        <div style={{
          padding: "12px 16px",
          maxWidth: "70%",
          borderRadius: "12px",
          backgroundColor: "#E2F1F1",
          color: "#2c3e50",
          display: "flex",
          alignItems: "center",
          gap: "4px"
        }}>
          <span className="dot dot1"></span>
          <span className="dot dot2"></span>
          <span className="dot dot3"></span>
        </div>
      </div>
    );
  };

  // Add handler functions
  const handleOpenGeneratePopup = () => {
    setOpenGeneratePopup(true);
  };

  const handleCloseGeneratePopup = () => {
    setOpenGeneratePopup(false);
  };

  const handleSchemaCreated = (schemaId) => {
    loadMentalMapData();
    handleCloseGeneratePopup();
  };

  const handleExportDialogOpen = () => {
    // Calculate optimal dimensions based on mindmap size when dialog opens
    calculateOptimalExportSize();
    setExportDialogOpen(true);
  };

  const handleExportDialogClose = () => {
    setExportDialogOpen(false);
  };

  const calculateOptimalExportSize = () => {
    // Get all nodes to calculate dimensions
    const nodeElements = document.querySelectorAll('.react-flow__node');
    if (nodeElements.length === 0) return;
    
    // Calculate bounding box of all nodes
    let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
    
    nodeElements.forEach(node => {
      const rect = node.getBoundingClientRect();
      minX = Math.min(minX, rect.left);
      minY = Math.min(minY, rect.top);
      maxX = Math.max(maxX, rect.right);
      maxY = Math.max(maxY, rect.bottom);
    });
    
    // Calculate dimensions with some padding
    const padding = 100; // 50px padding on each side
    const width = Math.ceil(maxX - minX) + (padding * 2);
    const height = Math.ceil(maxY - minY) + (padding * 2);
    
    // Ensure minimum sizes for very small mindmaps
    const minWidth = 800;
    const minHeight = 600;
    
    // Update state with calculated dimensions
    setExportWidth(Math.max(width, minWidth));
    setExportHeight(Math.max(height, minHeight));
  };

  const handleExportAsImage = async () => {
    const flowElement = document.querySelector('.react-flow__renderer');
    const reactFlowViewport = document.querySelector('.react-flow__viewport');

    if (!flowElement || !reactFlowViewport) {
      console.error('React Flow elements not found');
      return;
    }

    try {
      // Save all original states
      const originalWidth = flowElement.style.width;
      const originalHeight = flowElement.style.height;
      const originalPosition = flowElement.style.position;
      const originalViewportTransform = reactFlowViewport.style.transform;
      
      // If auto-sizing is enabled and we haven't calculated yet, do it now
      if (useAutoSize) {
        calculateOptimalExportSize();
      }
      
      // Calculate dynamic scale factor based on export dimensions
      // Larger dimensions get higher scale for better quality
      const dynamicScale = (() => {
        const refSize = 1000;          // Reference size for scaling
        const maxFactor = 10;          // Maximum scale factor
        const minFactor = 4;           // Minimum scale factor
        
        // Use the larger dimension to determine scale
        const largerDimension = Math.max(exportWidth, exportHeight);
        
        // Calculate scale factor: increases with size but caps at max
        // Steeper increase for ultra-high quality at all sizes
        let scaleFactor = minFactor + ((largerDimension - refSize) / refSize) * 4;
        
        // Ensure scale stays within reasonable bounds
        scaleFactor = Math.max(minFactor, Math.min(maxFactor, scaleFactor));
        
        // Round to one decimal place for consistency
        return Math.round(scaleFactor * 10) / 10;
      })();
      
      console.log(`Using dynamic scale factor: ${dynamicScale}x for dimensions ${exportWidth}x${exportHeight}`);
      
      // Step 1: Set the container to the exact dimensions
      flowElement.style.width = `${exportWidth}px`;
      flowElement.style.height = `${exportHeight}px`;
      flowElement.style.position = 'relative';
      
      // Step 2: First center the content by using fitView
      fitView({ 
        padding: 0.2,
        duration: 0, // Instant transition
        maxZoom: 1.0 // Prevent zooming
      });
      
      // Wait for fitView to take effect
      await new Promise(resolve => setTimeout(resolve, 300));
      
      // Step 3: Apply additional manual centering to ensure it's perfectly centered
      // Get all nodes to calculate bounding box
      const nodeElements = document.querySelectorAll('.react-flow__node');
      if (nodeElements.length > 0) {
        // Calculate bounding box of all nodes
        let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
        
        nodeElements.forEach(node => {
          const rect = node.getBoundingClientRect();
          minX = Math.min(minX, rect.left);
          minY = Math.min(minY, rect.top);
          maxX = Math.max(maxX, rect.right);
          maxY = Math.max(maxY, rect.bottom);
        });
        
        const flowRect = flowElement.getBoundingClientRect();
        
        // Calculate center position of nodes
        const nodesWidth = maxX - minX;
        const nodesHeight = maxY - minY;
        
        // Calculate required translate amounts to center
        const translateX = (flowRect.width - nodesWidth) / 2 - (minX - flowRect.left);
        const translateY = (flowRect.height - nodesHeight) / 2 - (minY - flowRect.top);
        
        // Parse current transform and adjust it
        let currentTransform = reactFlowViewport.style.transform || '';
        const translateMatch = currentTransform.match(/translate\(([^,]+),\s*([^)]+)\)/);
        const scaleMatch = currentTransform.match(/scale\(([^)]+)\)/);
        
        const currentTranslateX = translateMatch ? parseFloat(translateMatch[1]) : 0;
        const currentTranslateY = translateMatch ? parseFloat(translateMatch[2]) : 0;
        const currentScale = scaleMatch ? parseFloat(scaleMatch[1]) : 1;
        
        // Apply adjusted transform to center everything perfectly
        reactFlowViewport.style.transform = `translate(${currentTranslateX + translateX}px, ${currentTranslateY + translateY}px) scale(${currentScale})`;
        
        // Wait for transform to apply
        await new Promise(resolve => setTimeout(resolve, 200));
      }
      
      // Remove any existing custom edge overlay if present
      const existingOverlay = document.getElementById('edge-export-overlay');
      if (existingOverlay) {
        existingOverlay.remove();
      }
      
      // MANUAL EDGES RENDERING - Create an overlay to draw edges
      const overlay = document.createElement('div');
      overlay.id = 'edge-export-overlay';
      overlay.style.position = 'absolute';
      overlay.style.top = '0';
      overlay.style.left = '0';
      overlay.style.width = '100%';
      overlay.style.height = '100%';
      overlay.style.pointerEvents = 'none';
      overlay.style.zIndex = '999'; // High z-index to ensure visibility

      // Create an SVG element to draw the edges
      const svgNS = "http://www.w3.org/2000/svg";
      const svg = document.createElementNS(svgNS, "svg");
      svg.setAttribute("width", "100%");
      svg.setAttribute("height", "100%");
      svg.style.position = 'absolute';
      svg.style.top = '0';
      svg.style.left = '0';
      svg.style.overflow = 'visible';
      
      // Get node positions and draw edges
      const nodePositions = new Map();
      
      // First collect all node positions
      nodeElements.forEach(nodeEl => {
        const nodeId = nodeEl.getAttribute('data-id');
        if (nodeId) {
          const rect = nodeEl.getBoundingClientRect();
          const nodeRect = flowElement.getBoundingClientRect();
          
          // Calculate position relative to the flow container
          nodePositions.set(nodeId, {
            id: nodeId,
            x: rect.left - nodeRect.left + rect.width / 2,
            y: rect.top - nodeRect.top + rect.height / 2,
            width: rect.width,
            height: rect.height
          });
        }
      });
      
      // Draw each edge using the current edges state
      edges.forEach(edge => {
        const source = nodePositions.get(edge.source);
        const target = nodePositions.get(edge.target);
        
        if (source && target) {
          // Create the path element for the edge
          const path = document.createElementNS(svgNS, "path");
          
          // Calculate path points for a bezier curve
          // Starting from source node center right
          const sourceX = source.x + source.width / 2;
          const sourceY = source.y;
          
          // Target node center left
          const targetX = target.x - target.width / 2;
          const targetY = target.y;
          
          // Control points for the bezier curve - make gentler curves
          const dx = Math.abs(targetX - sourceX);
          // Reduce control point distance for more subtle curves
          const controlOffset = Math.min(dx * 0.3, 80); 
          
          // Define bezier curve path
          const pathD = `M ${sourceX},${sourceY} 
                         C ${sourceX + controlOffset},${sourceY} 
                           ${targetX - controlOffset},${targetY} 
                           ${targetX},${targetY}`;
          
          path.setAttribute("d", pathD);
          path.setAttribute("stroke", "#6BBDBD");
          path.setAttribute("stroke-width", "1.5"); // Thinner line
          path.setAttribute("fill", "none");
          path.setAttribute("stroke-opacity", "0.85"); // Slightly transparent
          
          // Add arrow marker - smaller and more subtle
          const markerId = `arrow-${edge.source}-${edge.target}`;
          const marker = document.createElementNS(svgNS, "marker");
          marker.setAttribute("id", markerId);
          marker.setAttribute("viewBox", "0 0 10 10");
          marker.setAttribute("refX", "8"); // Adjust position to not overlap with node
          marker.setAttribute("refY", "5");
          marker.setAttribute("markerWidth", "4"); // Smaller marker
          marker.setAttribute("markerHeight", "4"); // Smaller marker
          marker.setAttribute("orient", "auto-start-reverse");
          
          const arrow = document.createElementNS(svgNS, "path");
          arrow.setAttribute("d", "M 0 0 L 10 5 L 0 10 z");
          arrow.setAttribute("fill", "#6BBDBD");
          
          marker.appendChild(arrow);
          svg.appendChild(marker);
          
          path.setAttribute("marker-end", `url(#${markerId})`);
          svg.appendChild(path);
        }
      });
      
      overlay.appendChild(svg);
      flowElement.appendChild(overlay);
      
      // Create the canvas with optimal settings for export
      let exportCanvas;
      try {
        exportCanvas = await html2canvas(flowElement, {
          scale: dynamicScale,
          width: Number(exportWidth),
          height: Number(exportHeight),
          useCORS: true,
          logging: false,
          backgroundColor: '#ffffff',
          x: 0,
          y: 0,
          scrollX: 0,
          scrollY: 0
        });
      } catch (canvasError) {
        console.error('Error creating canvas:', canvasError);
        throw canvasError;
      }

      // Remove the custom overlay after capture
      const overlayElement = document.getElementById('edge-export-overlay');
      if (overlayElement) {
        overlayElement.remove();
      }

      // Create download link and trigger download
      const image = exportCanvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = image;
      link.download = 'mental-map-export.png';
      link.click();
      
      // Step 6: Restore all original states
      reactFlowViewport.style.transform = originalViewportTransform;
      flowElement.style.width = originalWidth;
      flowElement.style.height = originalHeight;
      flowElement.style.position = originalPosition;
      
      // Final step: Re-fit view to original state with a smooth transition
      setTimeout(() => {
        fitView({ 
          padding: 0.4,
          duration: 300
        });
      }, 100);
      
    } catch (error) {
      console.error('Error exporting as image:', error);
      
      // Reset the flow element dimensions and view in case of error
      const flowElement = document.querySelector('.react-flow__renderer');
      const reactFlowViewport = document.querySelector('.react-flow__viewport');
      
      if (flowElement) {
        flowElement.style.width = '100%';
        flowElement.style.height = '100%';
      }
      
      fitView({ padding: 0.4 });
    }

    handleExportDialogClose();
  };

  return (
    <>
      <AnimationStyles />
      <Root>
        <ContentWrapper>
          {!sidebarHidden && (
            <Sidebar hidden={sidebarHidden}>
              <HeaderContainer>
                <Title>Tus esquemas</Title>
                <MinimizeButton onClick={handleMinimize}>
                  <RemoveIcon />
                </MinimizeButton>
              </HeaderContainer>
              <StyledContent>
                {schema_list && schema_list.length > 0 ? (
                  schema_list.map((element) => (
                    <ElementItem
                      key={element.guid}
                      onClick={(e) => navigate(`/schema/${element.guid}`, { state: { docInfo: docInfo, schema_list: schema_list } })}
                      style={{ cursor: 'pointer' }}
                    >
                      <div style={{ width: '100%' }}>
                        <ElementHeader>
                          <ElementTitle>
                            {element.name}
                          </ElementTitle>
                        </ElementHeader>
                        <ElementDescription>
                          {element.description}
                        </ElementDescription>
                      </div>
                    </ElementItem>
                  ))
                ) : (
                  <ElementItem>
                    <ElementTitle>No hay esquemas</ElementTitle>
                  </ElementItem>
                )}
              </StyledContent>
            </Sidebar>
          )}
          {sidebarHidden && (

            <RestoreButton
              onClick={handleRestore}
              style={{

                position: 'absolute',

                // transform: 'scale(0.8)',
                zIndex: 10
              }}
            >
              <MenuIcon />
            </RestoreButton>

          )}
          <FlowWrapper>
            {loading && <LoadingAnimation />}
            <div style={{
              position: 'absolute',
              top: '20px',
              right: '50%',
              transform: 'translateX(50%)',
              zIndex: 10,
              display: 'flex',
              gap: '10px',
              backgroundColor: 'white',
              padding: '4px',
              borderRadius: '20px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
              opacity: loading ? 0.5 : 1,
              pointerEvents: loading ? 'none' : 'auto'
            }}>
              <Button
                variant="contained"
                style={{
                  borderRadius: '16px',
                  textTransform: 'none',
                  backgroundColor: '#6BBDBD',
                  color: 'white',
                  boxShadow: 'none',
                  padding: '6px 16px',
                  fontFamily: '"Fira Sans", sans-serif',
                  '&:hover': {
                    backgroundColor: '#5aa9a9',
                    boxShadow: 'none',
                  }
                }}
                disableElevation
                onClick={handleOpenGeneratePopup}
              >
                Añadir esquema
              </Button>
              <Button
                variant="outlined"
                style={{
                  borderRadius: '16px',
                  textTransform: 'none',
                  color: '#6BBDBD',
                  border: '1px solid #6BBDBD',
                  fontFamily: '"Fira Sans", sans-serif',
                  padding: '6px 16px',
                  minWidth: 'auto',
                  '&:hover': {
                    backgroundColor: 'rgba(107, 189, 189, 0.08)',
                  }
                }}
                onClick={handleExportDialogOpen}
              >
                <DownloadIcon />
              </Button>
            </div>

            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              nodeTypes={nodeTypes}
              fitView
              style={{ 
                opacity: loading ? 0.5 : 1,
                height: '100%',
                width: '100%',
              }}
              defaultEdgeOptions={{
                type: 'bezier',  // Use bezier for smoother horizontal curves
                animated: false,  // Change to false to remove dashed animation
                style: { 
                  stroke: '#6BBDBD',
                  strokeWidth: 2,
                },
                markerEnd: {
                  type: MarkerType.ArrowClosed,
                  color: '#6BBDBD',
                  width: 20,
                  height: 20,
                }
              }}
              connectionLineType="bezier"
              direction="LR"
              fitViewOptions={{
                padding: 0.4,  // More padding for better visibility of the whole graph
                includeHiddenNodes: false,
                duration: 500, // Smoother animation
              }}
              minZoom={0.2}
              maxZoom={2}
              defaultZoom={0.6}  // Lower default zoom to see more of the graph
              nodesDraggable={true}
              panOnScroll={true}
              panOnDrag={true}
              zoomOnScroll={true}
              zoomOnPinch={true}
              preventScrolling={false}
            >
              <Background
                variant="dots"
                gap={24}
                size={1}
                color="#91A7FF"
                style={{ backgroundColor: '#ffffff' }}
              />
              <Controls
                showZoom={true}
                showFitView={true}
                showInteractive={true}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  gap: '6px',
                  padding: '4px',
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                  position: 'absolute',
                  bottom: '80px',
                  right: '20px',
                  width: 'fit-content',
                  transform: 'scale(0.8)'
                }}
              />
              <div style={{
                position: 'absolute',
                bottom: '20px',
                left: '50%',
                transform: 'translateX(-50%)',
                display: 'flex',
                gap: '12px',
                backgroundColor: 'white',
                padding: '8px 16px',
                borderRadius: '20px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                zIndex: 10,
              }}>
                <Button
                  variant="outlined"
                  startIcon={<SearchIcon />}
                  onClick={handleDialogOpenKeyConcepts}
                  disabled={!nodes.some(node => node.selected)}
                  style={{
                    borderRadius: '16px',
                    textTransform: 'none',
                    color: '#6BBDBD',
                    border: '1px solid #6BBDBD',
                    fontFamily: '"Fira Sans", sans-serif',
                    padding: '6px 16px',
                    minWidth: '140px',
                    opacity: nodes.some(node => node.selected) ? 1 : 0.6,
                    '&:hover': {
                      backgroundColor: 'rgba(107, 189, 189, 0.08)',
                    }
                  }}
                >
                  Términos clave
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<ChatIcon />}
                  onClick={handlePreguntarIAClick}
                  disabled={!nodes.some(node => node.selected)}
                  style={{
                    borderRadius: '16px',
                    textTransform: 'none',
                    color: '#6BBDBD',
                    border: '1px solid #6BBDBD',
                    fontFamily: '"Fira Sans", sans-serif',
                    padding: '6px 16px',
                    minWidth: '140px',
                    opacity: nodes.some(node => node.selected) ? 1 : 0.6,
                    '&:hover': {
                      backgroundColor: 'rgba(107, 189, 189, 0.08)',
                    }
                  }}
                >
                  Preguntar a la IA
                </Button>
              </div>
            </ReactFlow>
          </FlowWrapper>
        </ContentWrapper>
      </Root>

      {/* Key Concepts Dialog */}
      <Dialog
        open={dialogOpenKeyConcepts}
        onClose={handleDialogCloseKeyConcepts}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: '16px',
            padding: '8px',
          }
        }}
      >
        <DialogTitle style={{
          fontFamily: '"Fira Sans", sans-serif',
          fontWeight: 700,
          fontSize: '1.2rem',
          color: '#2c3e50',
          marginBottom: '16px',
        }}>
          <Typography style={{
            fontFamily: '"Fira Sans", sans-serif',
            fontWeight: 700,
            fontSize: '1.2rem',
            color: '#2c3e50',
          }}>
            Términos Clave
          </Typography>
        </DialogTitle>
        <DialogContent style={{
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          fontFamily: '"Fira Sans", sans-serif',
        }}>
          {isLoadingKeyConcepts ? (
            <Box sx={{ p: 2 }}>
              <Typography sx={{ mb: 2, color: '#666' }}>
                Estamos buscando términos claves de este concepto...
              </Typography>
              <Skeleton variant="rectangular" height={60} sx={{ mb: 1, borderRadius: 1 }} />
              <Skeleton variant="rectangular" height={60} sx={{ mb: 1, borderRadius: 1 }} />
              <Skeleton variant="rectangular" height={60} sx={{ borderRadius: 1 }} />
            </Box>
          ) : (
            termsList.map((item, index) => (
              <div key={index} style={{
                display: 'flex',
                alignItems: 'flex-start',
                padding: '12px',
                border: '1px solid #6BBDBD',
                borderRadius: '8px',
                backgroundColor: selectedTerms[item.term] ? '#E2F1F1' : 'transparent',
                transition: 'background-color 0.2s ease',
                fontFamily: '"Fira Sans", sans-serif',
              }}>
                <div style={{
                  flex: 1,
                  fontFamily: '"Fira Sans", sans-serif',
                }}>
                  <Typography style={{
                    fontFamily: '"Fira Sans", sans-serif',
                    fontWeight: 500,
                    fontSize: '1rem',
                    color: '#2c3e50',
                    marginBottom: '4px',
                  }}>
                    {item.term}
                  </Typography>
                  <Typography style={{
                    fontFamily: '"Fira Sans", sans-serif',
                    fontSize: '0.875rem',
                    color: '#64748b',
                    lineHeight: 1.4,
                  }}>
                    {item.definition}
                  </Typography>
                </div>
                <Checkbox
                  checked={selectedTerms[item.term] || false}
                  onChange={() => handleTermSelect(item.term)}
                  style={{
                    color: '#6BBDBD',
                    padding: '6px',
                  }}
                />
              </div>
            ))
          )}
        </DialogContent>
        <DialogActions style={{
          padding: '16px',
          borderTop: '1px solid #e2e8f0',
        }}>
          <Button
            onClick={createNodesFromTerms}
            variant="contained"
            style={{
              backgroundColor: '#6BBDBD',
              color: 'white',
              textTransform: 'none',
              fontFamily: '"Fira Sans", sans-serif',
              padding: '8px 24px',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: '#5aa9a9',
              }
            }}
          >
            Añadir al esquema
          </Button>
        </DialogActions>
      </Dialog>

      {/* AI Chat Dialog */}
      <Dialog
        open={chatOpen}
        onClose={handleChatClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            height: '80vh',
            maxHeight: '80vh',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '16px',
            margin: '20px',
          }
        }}
      >
        <DialogTitle style={{
          padding: '24px',
          paddingBottom: '12px',
          borderBottom: '1px solid #e2e8f0',
          fontFamily: '"Fira Sans", sans-serif',
        }}>
          <Typography style={{
            fontFamily: '"Fira Sans", sans-serif',
            fontWeight: 700,
            fontSize: '1.5rem',
            color: '#2c3e50',
          }}>
            {selectedNode?.data.label || 'Chat'}
          </Typography>
        </DialogTitle>
        <DialogContent style={{
          padding: '24px',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          flex: 1,
          overflow: 'auto',
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            flex: 1,
          }}>
            {chatMessages.map((message, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: message.type === 'user' ? 'flex-end' : 'flex-start',
                  marginBottom: '8px',
                }}
              >
                <div style={{
                  maxWidth: '70%',
                  padding: '12px 16px',
                  borderRadius: '12px',
                  backgroundColor: message.type === 'user' ? '#6BBDBD' : '#E2F1F1',
                  color: message.type === 'user' ? 'white' : '#2c3e50',
                  fontFamily: '"Fira Sans", sans-serif',
                  fontSize: '0.95rem',
                  lineHeight: '1.5',
                  whiteSpace: 'pre-wrap',
                }}>
                  {message.type === 'user' ? (
                    message.content
                  ) : (
                    <MarkdownRender response={{ message: message.content }} />
                  )}
                </div>
              </div>
            ))}
            {isLoading && <TypingAnimation />}
          </div>
        </DialogContent>
        <DialogActions style={{
          padding: '16px 24px',
          borderTop: '1px solid #e2e8f0',
        }}>
          <div style={{
            display: 'flex',
            width: '100%',
            gap: '12px',
          }}>
            <TextField
              fullWidth
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
              placeholder="Escribe tu pregunta..."
              variant="outlined"
              multiline
              maxRows={4}
              InputProps={{
                style: {
                  fontFamily: '"Fira Sans", sans-serif',
                  fontSize: '0.95rem',
                  borderRadius: '12px',
                  backgroundColor: '#f8f9fa',
                }
              }}
            />
            <Button
              onClick={handleSendMessage}
              color="primary"
              style={{
                minWidth: 'auto',
                padding: '8px',
                marginLeft: '10px'
              }}
            >
              <SendIcon />
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      {/* Add PopupGenerarEsquema component */}
      <PopupGenerarEsquema
        open={openGeneratePopup}
        onClose={handleCloseGeneratePopup}
        onSchemaCreated={handleSchemaCreated}
        documentId={docInfo?.guid}
        docInfo={docInfo}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        ContentProps={{
          style: {
            backgroundColor: '#2c3e50',
            fontFamily: '"Fira Sans", sans-serif',
            borderRadius: '8px',
          }
        }}
      />

      <Dialog
        open={exportDialogOpen}
        onClose={handleExportDialogClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: '16px',
            padding: '16px',
          }
        }}
      >
        <DialogTitle style={{
          fontFamily: '"Fira Sans", sans-serif',
          fontWeight: 700,
          fontSize: '1.2rem',
          color: '#2c3e50',
        }}>
          Exportar como imagen
        </DialogTitle>

        <DialogContent style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          paddingTop: '16px'
        }}>
          <div style={{ 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'space-between' 
          }}>
            <Typography>
              Dimensiones de la imagen
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                checked={useAutoSize}
                onChange={(e) => setUseAutoSize(e.target.checked)}
                color="primary"
                style={{ color: '#6BBDBD' }}
              />
              <Typography variant="body2">
                Ajustar tamaño automáticamente
              </Typography>
            </div>
          </div>
          
          <div style={{
            display: 'flex',
            gap: '16px',
            paddingTop: '8px',
            opacity: useAutoSize ? 0.6 : 1,
          }}>
            <TextField
              label="Ancho (px)"
              type="number"
              value={exportWidth}
              onChange={(e) => setExportWidth(e.target.value)}
              fullWidth
              disabled={useAutoSize}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: '0.9rem',
                }
              }}
            />
            <TextField
              label="Alto (px)"
              type="number"
              value={exportHeight}
              onChange={(e) => setExportHeight(e.target.value)}
              fullWidth
              disabled={useAutoSize}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: '0.9rem',
                }
              }}
            />
          </div>
          
          <Typography variant="caption" style={{ color: '#666' }}>
            {useAutoSize 
              ? `Dimensiones calculadas: ${exportWidth}×${exportHeight}px` 
              : "El mapa se centrará automáticamente en la imagen."}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleExportAsImage}
            variant="contained"
            style={{
              backgroundColor: '#6BBDBD',
              color: 'white',
              textTransform: 'none',
              fontFamily: '"Fira Sans", sans-serif',
              padding: '8px 24px',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: '#5aa9a9',
              }
            }}
          >
            Exportar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Flow; 