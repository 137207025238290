import React, { useState } from 'react';
import { Box, Typography, Button, LinearProgress } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1300,
  },
  modalContent: {
    backgroundColor: 'white',
    padding: theme.spacing(4),
    borderRadius: '16px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    width: '600px',
    maxHeight: '80vh',
    overflow: 'auto',
    fontFamily: 'Fira Sans, sans-serif',
    '&::-webkit-scrollbar': {
      width: '8px',
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#D1D5DB',
      borderRadius: '4px',
      '&:hover': {
        background: '#9CA3AF',
      },
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
  },
  headerContainer: {
    marginBottom: theme.spacing(3),
  },
  title: {
    fontWeight: 'bold',
    fontSize: '22px',
    fontFamily: 'Fira Sans, sans-serif',
    marginBottom: theme.spacing(1),
  },
  progressContainer: {
    marginBottom: theme.spacing(4),
  },
  progress: {
    height: 6,
    borderRadius: 3,
    backgroundColor: '#E5E7EB',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#6BBDBD',
    },
  },
  stepCount: {
    color: '#6B7280',
    fontSize: '14px',
    marginTop: theme.spacing(1),
    textAlign: 'right',
    fontFamily: 'Fira Sans, sans-serif',
  },
  improvementContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(4),
    minHeight: '120px',
  },
  icon: {
    color: '#6BBDBD',
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.5),
  },
  contentContainer: {
    flex: 1,
  },
  improvementTitle: {
    fontWeight: 600,
    fontSize: '18px',
    marginBottom: theme.spacing(1),
    fontFamily: 'Fira Sans, sans-serif',
  },
  improvementDescription: {
    fontSize: '16px',
    color: '#4B5563',
    lineHeight: 1.5,
    fontFamily: 'Fira Sans, sans-serif',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  button: {
    fontSize: '15px',
    fontWeight: 600,
    padding: '8px 24px',
    borderRadius: '24px',
    textTransform: 'none',
    fontFamily: 'Fira Sans, sans-serif',
  },
  backButton: {
    color: '#6BBDBD',
    borderColor: '#6BBDBD',
    '&:hover': {
      borderColor: '#5AACAC',
      backgroundColor: 'rgba(107, 189, 189, 0.04)',
    },
  },
  nextButton: {
    backgroundColor: '#6BBDBD',
    color: 'white',
    '&:hover': {
      backgroundColor: '#5AACAC',
    },
  },
}));

const Improvements = ({ open, onClose }) => {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState(0);

  const improvements = [
    {
      title: "¡Esquemas Mejorados!",
      description: "Hemos renovado completamente la experiencia de esquemas con un diseño más intuitivo y mayor información en cada nodo. Ahora los esquemas se regeneran automáticamente sin necesidad de incluir texto adicional. Selecciona cualquier nodo para extraer términos clave o realiza preguntas a la IA para profundizar en el contenido."
    },
    {
      title: "Exportación de Conversaciones",
      description: "Ahora puedes copiar fácilmente los mensajes del chat y las justificaciones de las preguntas para guardarlos o compartirlos donde necesites."
    },
    {
      title: "Mayor Capacidad para Exámenes",
      description: "Hemos aumentado el límite de páginas de 10 a 15, permitiéndote subir exámenes más completos y trabajar con mayor cantidad de preguntas en una sola sesión."
    },
    {
      title: "Soporte para Enunciados en Exámenes",
      description: "Muchos exámenes incluyen un enunciado general al que hacen referencia varias preguntas. Ahora puedes incluir estos enunciados al subir tus exámenes para un contexto completo."
    },
    {
      title: "Flashcards Más Inteligentes",
      description: "Hemos mejorado el sistema de evaluación en las tarjetas de memoria. Cuando un término tiene múltiples espacios en blanco (_ _ _), cada uno se evalúa por separado, reconociendo tus aciertos parciales."
    },
    {
      title: "Más Documentos para tus Tests",
      description: "Triplicamos el límite de documentos que puedes seleccionar para crear tests, pasando de 5 a 15, para que puedas preparar evaluaciones más completas y variadas."
    },
    {
      title: "Descargar esquemas",
      description: "Ahora puedes descargar los esquemas en formato PNG para usarlos en tus documentos."
    },
    {
      title: "¡Flashcards con Voz!",
      description: "Hemos mejorado tu experiencia de estudio añadiendo voz a las flashcards. Ahora puedes escuchar el contenido en lugar de solo leerlo, lo que facilita el aprendizaje y la retención. Usa esta función para reforzar tu memoria auditiva y estudiar de manera más dinámica."
    },
    

  ];

  const handleNext = () => {
    if (currentStep < improvements.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      onClose();
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const progress = ((currentStep + 1) / improvements.length) * 100;

  return open && (
    <div className={classes.modalOverlay}>
      <Box className={classes.modalContent}>
        <Box className={classes.headerContainer}>
          <Typography className={classes.title}>
            ⚡ ¿Qué hemos mejorado?
          </Typography>
        </Box>

        <Box className={classes.progressContainer}>
          <LinearProgress 
            variant="determinate" 
            value={progress} 
            className={classes.progress}
          />
          <Typography className={classes.stepCount}>
            {currentStep + 1} de {improvements.length}
          </Typography>
        </Box>

        <Box className={classes.improvementContainer}>
          <CheckCircleOutlineIcon className={classes.icon} />
          <Box className={classes.contentContainer}>
            <Typography className={classes.improvementTitle}>
              {improvements[currentStep].title}
            </Typography>
            <Typography className={classes.improvementDescription}>
              {improvements[currentStep].description}
            </Typography>
          </Box>
        </Box>

        <Box className={classes.buttonContainer}>
          <Button
            variant="outlined"
            onClick={handleBack}
            disabled={currentStep === 0}
            className={`${classes.button} ${classes.backButton}`}
          >
            Atrás
          </Button>
          <Button
            variant="contained"
            onClick={handleNext}
            className={`${classes.button} ${classes.nextButton}`}
          >
            {currentStep === improvements.length - 1 ? 'Continuar' : 'Siguiente'}
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default Improvements;
