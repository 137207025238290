import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  IconButton
} from '@mui/material';
import { Close } from '@mui/icons-material';

const TextSelectionDialog = ({
  open,
  onClose,
  selectedText,
  isTextTruncated,
  onGenerateQuestions,
  onGenerateCards
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "20px",
        }
      }}
    >
      <DialogTitle sx={{
        padding: "24px 32px 0",
        fontFamily: 'Fira Sans',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
      }}>
        <Box>
          Texto seleccionado
          <Typography variant="subtitle1" sx={{
            padding: "4px 0 0",
            fontFamily: 'Fira Sans',
            color: '#666666',
            fontSize: '14px'
          }}>
            Se generaran preguntas y tarjetas con el texto seleccionado
          </Typography>
        </Box>
        <IconButton
          onClick={onClose}
          sx={{
            marginRight: -1,
            marginTop: -1,
            color: '#666666'
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "24px 32px" }}>
        <Typography sx={{ 
          fontFamily: 'Fira Sans', 
          color: '#4A5568',
          backgroundColor: '#F8F9FA',
          padding: '16px',
          borderRadius: '8px',
          border: '1px solid #E7E7E7'
        }}>
          {selectedText}
        </Typography>
        {isTextTruncated && (
          <Typography sx={{ 
            color: '#6B7280',
            fontSize: '14px',
            fontFamily: 'Fira Sans',
            marginTop: '8px',
            fontStyle: 'italic'
          }}>
            * Se ha limitado la selección a 10000 caracteres
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: "16px 32px 24px", gap: 2 }}>
        <Button
          variant="contained"
          onClick={onGenerateQuestions}
          sx={{
            flex: 1,
            backgroundColor: '#6BBDBD',
            color: 'white',
            borderRadius: '24px',
            textTransform: 'none',
            fontFamily: 'Fira Sans',
            '&:hover': {
              backgroundColor: '#5AACAC'
            }
          }}
        >
          Generar Preguntas
        </Button>
        <Button
          variant="contained"
          onClick={onGenerateCards}
          sx={{
            flex: 1,
            backgroundColor: '#E2F1F1',
            color: '#6BBDBD',
            borderRadius: '24px',
            textTransform: 'none',
            fontFamily: 'Fira Sans',
            '&:hover': {
              backgroundColor: '#D1E0E0'
            }
          }}
        >
          Generar Tarjetas
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TextSelectionDialog; 