import { makeStyles } from '@mui/styles';
const drawerWidth = 224;

const useLayoutStyles = makeStyles(theme => ({
    // textStyle: {
    //   color: "var(--gray-shades-dark-gray, #09090A)",
    //   
    //   fontSize: "16px",
    //   fontStyle: "normal",
    //   fontWeight: 500,
    //   lineHeight: "140%",
    // },
    text: {
        color: "gray",
        fontFamily: "Fira Sans, sans-serif",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "140%", // this results in 22.4px
        // [theme.breakpoints.down('1700')]: {
        //     fontSize: '12px', // Smaller font size for screens smaller than 1300px
        // },
        // [theme.breakpoints.down('1200')]: {
        //     display: 'None',

        // },

        // [theme.breakpoints.up('2000')]: {
        //     fontSize: '18px', // Smaller font size for screens smaller than 1300px


        // },

    },
    boxCredits: {
        backgroundColor: "#F3F3F5",
        borderRadius: "8px",
        padding: "12px",
        marginBottom: "20px"
    },
    boxCredits2: {
        
        borderRadius: "8px",
        padding: "12px",
        marginBottom: "10px"
    },
    creditsText: {
        fontSize: 12,
        color: "#9C9DA1",
    },
    buttonPlan: {
        textTransform: "none",
        borderRadius: "100px",
        color: "white",
        gap: "10px",
        alignSelf: "stretch",
        padding: "5px 32px",
        backgroundColor: "#6BBDBD",
        fontWeight: "bold !important",
        fontSize: "14px",
        width: "100%",
        marginBottom: "14px",
        '&:hover': {
            textTransform: "none",
            borderRadius: "100px",
            color: "white",
            gap: "10px",
            alignSelf: "stretch",
            padding: "5px 32px",
            backgroundColor: "#6BBDBD",
            fontWeight: "bold !important",
            fontSize: "14px",
            width: "100%",
            marginBottom: "14px",
        }

    },

    drawer: {
        width: drawerWidth, // Default width
        '& .MuiDrawer-paper': {
            width: '224px', // Ensures the paper element also gets the width
            boxSizing: 'border-box', // Ensures padding and border are included in the width
            borderTopRightRadius: 16,
            borderBottomRightRadius: 16,
        },
        backgroundColor: 'rgb(248, 255, 255)',


    },

    drawerPaper: {
        width: 10,
        width: 'fit-content'



    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',

    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        // width: "72px !important",
        width: "72px !important"

    },
    listItem: {
        gap: "10px",
        paddingLeft: "5px",
        color: "gray",
        '&:hover': {
            backgroundColor: 'rgba(107, 189, 189, 0.20)',
            borderRadius: "6px",
            '& $text': {
                color: '#6BBDBD',
            },
            '& $listItemIcon': {
                color: '#6BBDBD',

            },
            '& $svgIcon': {
                stroke: "#6BBDBD"

            },
            '& $svgIconFlashcards': {
                stroke: "#6BBDBD",
                fill: "#6BBDBD"

            },
        },
    },
    listItem2: {
        gap: "10px",
        color: "gray",

        '&:hover': {
            backgroundColor: 'rgba(107, 189, 189, 0.20)',
            borderRadius: "6px",
            '& $text': {
                color: '#6BBDBD',
            },
            '& $listItemIcon': {
                color: '#6BBDBD',

            },

            '& $svgIcon': {
                stroke: "#6BBDBD",


            },
        },
        [theme.breakpoints.up('1000')]: {
            paddingLeft: "5px !important",



        },
        [theme.breakpoints.down('1000')]: {
            display: "flex",
            justifyContent: "center",


        },


    },
    svgIcon: {
        stroke: '#A0A0A0', // default color
        fill: 'none', // default fill
    },
    svgIconFlashcards: {
        stroke: '#A0A0A0', // default color
        fill: '#A0A0A0', // default fill
    },
    listItemIcon: {
        color: '#A0A0A0', // default color

        minWidth: 'auto', // Remove default min width
        '& svg': {
            width: '20px', // Adjust the size as needed
            height: '20px', // Adjust the size as needed
        },
    },
    listItemIconUpgrade: {
        color: '#A0A0A0', // default color
        cursor: "pointer",
        minWidth: 'auto', // Remove default min width
        '& svg': {
            width: '30px', // Adjust the size as needed
            height: '30px', // Adjust the size as needed
        },
    },

    selected: {
        backgroundColor: 'rgba(107, 189, 189, 0.20)',
        borderRadius: "6px",
        '& $text': {
            color: '#6BBDBD',
        },
        '& $listItemIcon': {
            color: '#6BBDBD',
        },
        '& $svgIcon': {
            stroke: "#6BBDBD",
        },
    },

    listItemimportant: {
        marginBottom: "14px",
        // border: "1px solid #026277"
        gap: "10px",
        paddingLeft: "5px"


    },

    header: {
        display: "flex",
        flexDirection: "row", // Changed to row to place items side by side
        alignItems: "flex-start", // Align items to the top
    },
    userImage: {
        width: "26px",
        height: "26px",
        borderRadius: "14px",
        marginRight: "12px", // Spacing between image and text
        objectFit: "cover", // To ensure the image covers the dimensions
    },
    userInfo: {
        color: "#09090A",
        fontFamily: "Open Sans",
        fontSize: "14px !important",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        "& .MuiListItemText-primary": {
            fontSize: "14px", // Custom font size for primary text
            fontWeight: 700, // Custom font weight for primary text
        },
        "& .MuiListItemText-secondary": {
            fontSize: "14px", // Custom font size for secondary text
            fontWeight: 400, // Custom font weight for secondary text
        },
        [theme.breakpoints.down('800')]: {
            display: 'None'
        },

    },
    top: {
        display: "flex",
        flexDirection: "column",
        height: "100%", // make it span the entire height
        padding: "30px 20px 32px 20px",
        gap: "24px",
    },
    bottom: {
        display: "flex",
        flexDirection: "column",

        marginTop: "auto",
    },
    skeleton: {
        backgroundColor: "#e0e0e0",
        borderRadius: "8px",
        animation: `$shimmer 1.5s infinite ease-in-out`,
    },
    "@keyframes shimmer": {
        "0%": {
            backgroundColor: "#f0f0f0",
        },
        "50%": {
            backgroundColor: "#e0e0e0",
        },
        "100%": {
            backgroundColor: "#f0f0f0",
        },
    },
    // Define specific sizes for your image and text placeholders
    imageSkeleton: {
        width: "26px",
        height: "26px",
    },
    textSkeleton: {
        width: "100px",
        height: "20px",
        margin: "4px 4px", // Adjust margin as needed
    },


}));

export default useLayoutStyles
