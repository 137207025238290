import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography, Button, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Container, Box, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditorText from "../NewComponents/TextEditor";
import useChapterStyles from "./Styles/useChapterStyles";
import { getCsrfToken } from '../Api/TokenApi';
import { fetchGenerateSummary } from "../Api/FilesApi";
import { WebSocketContext } from "../WebSocketProvider";
import { useCredits } from "../Context/CreditsContext";
import NeedCredits from "../NoCreditsModal/NeedCredits";
import PopupDiagram from '../MentalMap/PopupDiagram';
import { styled } from '@mui/styles';
import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import Tooltip from '@mui/material/Tooltip';
import PopUpSeeDiagrams from '../MentalMap/PopUpSeeDiagrams';
import DownloadIcon from '@mui/icons-material/Download';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import DOMPurify from 'dompurify';
import "./Styles/style.css";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { updateDocumentSummary } from "../Api/FilesApi";
import RefreshIcon from '@mui/icons-material/Refresh';
import { TramRounded } from "@mui/icons-material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import SaveIcon from '@mui/icons-material/Save';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
try {
    // Try the development path first
    if (pdfFonts.pdfMake && pdfFonts.pdfMake.vfs) {
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
    } 
    // Fall back to production path
    else if (pdfFonts.vfs) {
      pdfMake.vfs = pdfFonts.vfs;
    }
    else {
      console.warn('PDF fonts not loaded correctly');
    }
  } catch (error) {
    console.error('Error loading PDF fonts:', error);
  }
  

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const PAGE_CREDIT_PRICE = 0.375

const NoSeparatorAccordion = styled(Accordion)(({ theme }) => ({
    '&:before': {
        display: 'none',
    },
    boxShadow: 'none',
    borderBottom: 'none',
}));

const Header1Group = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    backgroundColor: '#F8FFFF',
    '& .header1-title': {
        fontFamily: "'Fira Sans', sans-serif",
        fontWeight: 500,
        fontSize: '18px',
        color: 'black',
    },
    '& .nested-accordion': {
        marginLeft: theme.spacing(1),
    },
}));

const useStyles = makeStyles((theme) => ({
    accordionSummary: {
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIcon': {
            marginRight: theme.spacing(1),
        },
        paddingLeft: 0, // Add this line to remove left padding
    },
}));

const CustomContainer = styled(Container)(({ theme }) => ({
    height: '82vh',
    overflow: 'hidden',
    marginTop: '12px',
    padding: '0 !important',
    overflowX: 'hidden',
    paddingBottom: '100px !important',
}));

const ContentWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    backgroundColor: '#F8FFFF',
    padding: '0 16px',
    fontFamily: "'Inter', sans-serif",
    fontSize: '14px',
    '& p': {
        marginBottom: '12px',
        lineHeight: '1.6',
    },
    '& ul, & ol': {
        paddingLeft: '24px',
        marginBottom: '12px',
    },
    '& li': {
        marginBottom: '8px',
    },
    '& ul ul, & ol ol, & ul ol, & ol ul': {
        marginTop: '8px',
        marginBottom: '8px',
    },

}));

const StyledMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: '12px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        marginTop: '8px',
        minWidth: '200px',
    },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    padding: '12px 24px',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    fontSize: '14px',
    fontFamily: "'Fira Sans', sans-serif",
    color: '#026277',
    '&:hover': {
        backgroundColor: '#E2F1F1',
    },
    '& .MuiSvgIcon-root': {
        fontSize: '20px',
        color: '#6BBDBD',
    },
}));

function Summary({ userData, docInfo, setSummary, token, user, summary, isGeneratingSummary, setIsGeneratingSummary, newGeneratingSummary, setNewGeneratingSummary }) {

    const { document_id, selectedTranscript } = useParams();
    const { folder_id, selectedFolder } = useParams();
    const { t } = useTranslation();
    const classes = useChapterStyles();
    const { messageCallback } = useContext(WebSocketContext);


    const [editorValue, setEditorValue] = useState('');
    const { updateCredits, credits } = useCredits();

    const [creditsNeeded, setCreditsNeeded] = useState(0);

    const [currentLoadingPage, setCurrentLoadingPage] = useState('0');
    const [showUpgrade, setShowUpgrade] = useState(false);
    const [expandedSections, setExpandedSections] = useState({});
    const customClasses = useStyles();

    const [openDiagramPopup, setOpenDiagramPopup] = useState(false);
    const [currentSectionContent, setCurrentSectionContent] = useState('');
    const [currentSectionTitle, setCurrentSectionTitle] = useState('');
    const [openSeeDiagramsPopup, setOpenSeeDiagramsPopup] = useState(false);
    const [selectedSectionDiagrams, setSelectedSectionDiagrams] = useState([]);
    const [selectedSectionTitle, setSelectedSectionTitle] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [isSpinning, setIsSpinning] = useState(false);
    const [canFetch, setCanFetch] = useState(true); // State to manage fetch availability
    const [notification, setNotification] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    // Add this ref to store the save function
    const saveEditorRef = useRef(null);

    useEffect(() => {
        if (messageCallback) {

            switch (messageCallback.type) {

                case "PageSummary":
                    if (messageCallback.document_id === document_id) {
                        setCurrentLoadingPage(messageCallback.page);
                        setIsGeneratingSummary(true)
                    }
                    break;
                // case "Patata":
                //     console.log("Patata in summary.js", messageCallback)
                //     break;

                case "Summary":
                    if (messageCallback.document_id === document_id) {
                        setSummary(messageCallback.summary)
                        setIsGeneratingSummary(false)
                        docInfo.summary_active = true;
                        docInfo.version = 2;
                        setEditorValue(Object.values(messageCallback.summary)[0])
                    }
                    break;
                case "SummaryGenerating":
                    if (messageCallback.document_id === document_id) {
                        setIsGeneratingSummary(true)
                    }
                    break;

                // case "SummaryUnblocked":
                //     setIsUnblurred(true)
                //     setIsGeneratingSummary(false)

                //     setNewGeneratingSummary(false)
                //     return
            }

        }
    }, [messageCallback]);

    useEffect(() => {

        if (summary && typeof summary === 'object') {
            const summaryContent = Object.values(summary)[0];
            setEditorValue(summaryContent);
        }
    }, [docInfo, summary]);
    useEffect(() => {
        if (docInfo) {
            let _creditsPage = Math.max(1, Math.round(docInfo.info_doc.pages * PAGE_CREDIT_PRICE));

            setCreditsNeeded(_creditsPage)
        }
    }, [document_id, docInfo])
    useEffect(() => {
        const adjustEditorHeight = () => {
            const editorElement = document.getElementById('editor-container');
            if (editorElement) {
                const viewportHeight = window.innerHeight;
                const editorOffsetTop = editorElement.getBoundingClientRect().top;
                const distanceToBottom = 20;
                const editorHeight = viewportHeight - editorOffsetTop - distanceToBottom;

                editorElement.style.height = `${editorHeight}px`;
            }
        };

        adjustEditorHeight();
        window.addEventListener('resize', adjustEditorHeight);

        return () => window.removeEventListener('resize', adjustEditorHeight);
    }, []);
    // Function to convert HTML to EditorJS format
    const convertHtmlToEditorJs = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;

        const blocks = [];

        // Process each element in the HTML
        tempDiv.childNodes.forEach(node => {
            if (node.nodeType === Node.ELEMENT_NODE) {
                switch (node.tagName.toLowerCase()) {
                    case 'h1':
                    case 'h2':
                    case 'h3':
                        blocks.push({
                            type: 'header',
                            data: {
                                text: node.textContent,
                                level: parseInt(node.tagName[1])
                            }
                        });
                        break;
                    case 'p':
                        blocks.push({
                            type: 'paragraph',
                            data: {
                                text: node.innerHTML
                            }
                        });
                        break;
                    case 'ul':
                    case 'ol':
                        const items = Array.from(node.children).map(li => li.innerHTML);
                        blocks.push({
                            type: node.tagName.toLowerCase() === 'ul' ? 'list' : 'numbered-list',
                            data: {
                                items: items
                            }
                        });
                        break;
                    case 'table':
                        const rows = Array.from(node.rows).map(row =>
                            Array.from(row.cells).map(cell => cell.innerHTML)
                        );
                        blocks.push({
                            type: 'table',
                            data: {
                                content: rows
                            }
                        });
                        break;
                }
            }
        });

        return {
            blocks: blocks
        };
    };

    const handleDownloadSummary = async (isOld = false) => {
        if (!summary || !docInfo) {
            return;
        }
        // Function to convert image URL to base64
        const getBase64FromUrl = async (url) => {
            const response = await fetch(url);
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        };

        // Only get logo if user is External
        let logoBase64;
        if (userData?.plan_name === 'External') {
            logoBase64 = await getBase64FromUrl('https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/UGT.png');
        }

        // Convert HTML to EditorJS format
        let formattedContent;
        if (docInfo?.version === 2) {
            // If version is 2, use the first value of the summary
            formattedContent = Object.values(summary)[0]; // Extract the first value of the first key in the JSON
            let content = '';
            if (typeof formattedContent === 'string') {
                try {
                    formattedContent = JSON.parse(formattedContent);
                } catch (e) {
                    formattedContent = convertHtmlToEditorJs(formattedContent);
                }
            }

            // Check if formattedContent has blocks property and it's an array
            if (formattedContent && formattedContent.blocks && Array.isArray(formattedContent.blocks)) {
                content = formattedContent.blocks.map(block => {
                    switch (block.type) {
                        case 'header':
                            return `<h${block.data.level} style="font-size: 20px; margin-top: 20px;">${block.data.text}</h${block.data.level}>`;
                        case 'paragraph':
                            return `<p style="font-size: 16px;">${block.data.text}</p>`;
                        case 'list':
                        case 'numbered-list':
                            const listType = block.type === 'list' ? 'ul' : 'ol';
                            return `<${listType} style="font-size: 16px;">${block.data.items.map(item =>
                                `<li style="font-size: 16px;">${item}</li>`).join('')}</${listType}>`;
                        case 'table':
                            if (block.data && block.data.content && Array.isArray(block.data.content)) {
                                return `<table style="font-size: 16px; border-collapse: collapse; width: 100%;">
                                    ${block.data.content.map((row, rowIndex) =>
                                    `<tr>${Array.isArray(row) ? row.map(cell =>
                                        `<td style="font-size: 16px; padding: 8px; border: 1px solid #ddd; ${rowIndex === 0 ? 'background-color: #f5f5f5;' : ''}">${cell}</td>`
                                    ).join('') : ''
                                    }</tr>`
                                ).join('')}</table>`;
                            }
                            return '';
                        default:
                            return '';
                    }
                }).join('');
            } else {
                // Fallback if the format is not as expected
                content = JSON.stringify(formattedContent);
            }

            let final_content = `<div style="font-size: 12px;">${content}</div>`;
            formattedContent = final_content;
        } else if (userData?.plan_name === 'External' || isOld) {
            if (Array.isArray(summary)) {
                formattedContent = summary.map(section => `
                    <div>
                        <h2 style="color: #026277; margin: 0; padding: 0;">${section.header2}</h2>
                        <div style="margin: 0; padding: 0;">${section.content}</div>
                    </div>
                `).join('');
            } else {
                formattedContent = `
                    <div>
                        <h2 style="color: #026277; margin: 0; padding: 0;">${summary.header2 || ''}</h2>
                        <div style="margin: 0; padding: 0;">${summary.content || ''}</div>
                    </div>
                `;
            }
        } else if (typeof summary === 'string') {
            try {
                const parsedSummary = JSON.parse(summary);
                if (Array.isArray(parsedSummary)) {
                    formattedContent = parsedSummary.map(section => `
                        <div>
                            <h2 style="color: #026277; margin: 0; padding: 0;">${section.header2}</h2>
                            <div style="margin: 0; padding: 0;">${section.content}</div>
                        </div>
                    `).join('');
                } else {
                    formattedContent = summary;
                }
            } catch (e) {

                alert("Ha habido un error en la descarga. Contacta con nosotros.")
            }


        }

        const htmlContent = `
                ${formattedContent}
        `;

        const content = htmlToPdfmake(htmlContent);

        const watermarkText = userData?.plan_name === 'External' ? 'UGT' : 'Typedai';
        const watermarkColor = userData?.plan_name === 'External' ? '#E52330' : '#6BBDBD';

        const documentDefinition = {
            content: content,
            watermark: { text: watermarkText, color: watermarkColor, opacity: 0.3, bold: true, fontSize: 70 },
            pageMargins: [40, 60, 40, 60],
            header: userData?.plan_name === 'External' ? {
                columns: [
                    { width: '*', text: '' },
                    {
                        width: 100,
                        image: logoBase64,
                        fit: [100, 100],
                        margin: [-30, 30, 0, 0]
                    }
                ]
            } : null,
            footer: function (currentPage, pageCount) {
                return {
                    columns: [
                        {
                            text: 'Resumen hecho con typedai.com',
                            alignment: 'left',
                            color: '#6BBDBD',
                        },
                        {
                            text: currentPage + ' de ' + pageCount,
                            alignment: 'right',
                        }
                    ],
                    margin: [40, 0]
                };
            }
        };

        pdfMake.createPdf(documentDefinition).download(`${docInfo.title}_resumen.pdf`);
    };
    const handleGenerateSummary = async (exists) => {
        // if (docInfo?.point_id.length === 0 || docInfo?.point_id === null || !docInfo) {
        //     alert("Aún no se ha subido el documento. Contacta con nosotros si tienes alguna duda.")
        //     return
        // }
        if (credits === -100 || credits >= creditsNeeded) {
            setNewGeneratingSummary(true)
            setIsGeneratingSummary(true)
            setSummary(null)

            fetchGenerateSummary(token, "POST", document_id, null, user)
              
        } else {
            setShowUpgrade(true)
        }
    };
    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpandedSections({
            ...expandedSections,
            [panel]: isExpanded,
        });
    };

    const handleGenerateDiagram = (text) => {
        console.log("Generating diagram");
    };

    const handleOpenSeeDiagrams = (index) => {
        const dummyDiagrams = Array(index + 1).fill().map((_, i) => ({
            name: `Esquema ${i + 1}`,
            text: `graph TD\nA[${summary[index].title}] --> B[Subtema ${i + 1}]`
        }));
        setSelectedSectionDiagrams(dummyDiagrams);
        setSelectedSectionTitle(summary[index].title);
        setOpenSeeDiagramsPopup(true);
    };

    useEffect(() => {
        if (Array.isArray(summary)) {
            const initialExpandedState = {};
            summary.forEach((_, index) => {
                initialExpandedState[`header1-${index}`] = true;
                initialExpandedState[`panel${index}-${index}`] = false;
            });
            setExpandedSections(initialExpandedState);
        }
    }, [summary]);


    const handleSaveSummary = async (content) => {
        try {
            // You'll implement this API call
            const contentString = JSON.stringify(content);

            await updateDocumentSummary(token, document_id, contentString, user);
            setSnackbarMessage('Resumen guardado correctamente');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            console.log("Summary saved successfully");

        } catch (error) {
            console.error("Error saving summary:", error);
            setSnackbarMessage('Error al guardar el resumen');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleRefresh = () => {
        setIsSpinning(true);
        if (canFetch) {
            console.log("Refreshing summary...");
            fetchGenerateSummary(token, "GET", document_id, null, user)
                .then(response => {
                    if (response.summary['summary'] === null) {
                        setNotification('El resumen aún no está listo');
                        setTimeout(() => {
                            setNotification(''); // Clear the notification after 12 seconds
                        }, 12000); // 12000 milliseconds = 12 seconds
                    } else {
                        setSummary(response.summary['summary']);
                        setIsGeneratingSummary(false);
                        docInfo.summary_active = true;
                        docInfo.version = 2;
                        setNotification(''); // Clear notification if summary is ready
                    }
                })
                .catch(error => {
                    console.error("Error refreshing summary:", error);
                });

            setCanFetch(false);
            setTimeout(() => {
                setCanFetch(true);
            }, 20000); // 20 seconds
        }

        setTimeout(() => {
            setIsSpinning(false);
        }, 2000); // Adjust the duration as needed
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDownloadWord = async () => {
        if (!summary || !docInfo) {
            return;
        }

        let formattedContent;
        if (docInfo?.version === 2) {
            formattedContent = Object.values(summary)[0];
            if (typeof formattedContent === 'string') {
                try {
                    formattedContent = JSON.parse(formattedContent);
                } catch (e) {
                    formattedContent = convertHtmlToEditorJs(formattedContent);
                }
            }
            if (formattedContent && formattedContent.blocks && Array.isArray(formattedContent.blocks)) {
                formattedContent = formattedContent.blocks.map(block => {
                    switch (block.type) {
                        case 'header':
                            return `<h${block.data.level} style="font-size: 20px; margin-top: 20px;">${block.data.text}</h${block.data.level}>`;
                        case 'paragraph':
                            return `<p style="font-size: 16px;">${block.data.text}</p>`;
                        case 'list':
                        case 'numbered-list':
                            const listType = block.type === 'list' ? 'ul' : 'ol';
                            return `<${listType} style="font-size: 16px;">${block.data.items.map(item =>
                                `<li style="font-size: 16px;">${item}</li>`).join('')}</${listType}>`;
                        case 'table':
                            if (block.data && block.data.content && Array.isArray(block.data.content)) {
                                return `<table style="font-size: 16px; border-collapse: collapse; width: 100%;">
                                    ${block.data.content.map((row, rowIndex) =>
                                    `<tr>${Array.isArray(row) ? row.map(cell =>
                                        `<td style="font-size: 16px; padding: 8px; border: 1px solid #ddd; ${rowIndex === 0 ? 'background-color: #f5f5f5;' : ''}">${cell}</td>`
                                    ).join('') : ''
                                    }</tr>`
                                ).join('')}</table>`;
                            }
                            return '';
                        default:
                            return '';
                    }
                }).join('');
            } else {
                // Fallback if the format is not as expected
                formattedContent = JSON.stringify(formattedContent);
            }

        } else if (userData?.plan_name === 'External') {
            if (Array.isArray(summary)) {
                formattedContent = summary.map(section => `
                    <div style="font-family: Arial, sans-serif;">
                        <h2 style="color: #026277; font-family: Arial, sans-serif;">${section.header2}</h2>
                        <div style="font-family: Arial, sans-serif;">${section.content}</div>
                    </div>
                `).join('');
            } else {
                formattedContent = `
                    <div style="font-family: Arial, sans-serif;">
                        <h2 style="color: #026277; font-family: Arial, sans-serif;">${summary.header2 || ''}</h2>
                        <div style="font-family: Arial, sans-serif;">${summary.content || ''}</div>
                    </div>
                `;
            }
        } else {
            formattedContent = summary;
        }

        const htmlContent = `
            <html>
                <head>
                    <meta charset="UTF-8">
                    <style>
                        body {
                            font-family: Arial, sans-serif;
                            line-height: 1.6;
                            color: #333333;
                        }
                        h1 {
                            font-family: Arial, sans-serif;
                            color: #026277;
                            font-size: 24px;
                            margin-bottom: 20px;
                        }
                        h2 {
                            font-family: Arial, sans-serif;
                            color: #026277;
                            font-size: 18px;
                            margin-top: 16px;
                            margin-bottom: 12px;
                        }
                        p {
                            font-family: Arial, sans-serif;
                            margin-bottom: 10px;
                        }
                        .footer {
                            font-family: Arial, sans-serif;
                            margin-top: 20px;
                            color: #6BBDBD;
                            font-size: 12px;
                        }
                    </style>
                </head>
                <body>
                    <h1>${docInfo.title}</h1>
                    ${formattedContent}
                    <div class="footer">Resumen hecho con typedai.com</div>
                </body>
            </html>
        `;
        const blob = new Blob([htmlContent], { type: 'application/msword' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${docInfo.title}_resumen.doc`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        handleMenuClose();
    };

    return (
        <>
            {showUpgrade && <NeedCredits initialOpen={true} onClose={() => setShowUpgrade(false)} />}
            <PopupDiagram
                open={openDiagramPopup}
                onClose={() => setOpenDiagramPopup(false)}
                onGenerate={handleGenerateDiagram}
                sectionTitle={currentSectionTitle}
            />
            <PopUpSeeDiagrams
                open={openSeeDiagramsPopup}
                onClose={() => setOpenSeeDiagramsPopup(false)}
                diagrams={selectedSectionDiagrams}
                title={selectedSectionTitle}
            />
            {summary !== null && docInfo?.summary_active && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '2px' }}>
                    {docInfo?.version === 1 && userData.plan_name !== 'External' && (
                        <Button
                            variant="contained"
                            startIcon={<DownloadIcon />}
                            style={{
                                backgroundColor: '#6BBDBD',
                                color: 'white',
                                fontSize: '14px',
                                fontFamily: "'Fira Sans', sans-serif",
                                borderRadius: '20px',
                                textTransform: 'none',
                                boxShadow: 'none',
                            }}
                            onClick={() => { handleDownloadSummary(true); }}
                        >
                            Descargar resumen antiguo
                        </Button>
                    )}
                    {(docInfo?.version === 2) ? (

                        <Button
                            variant="contained"
                            startIcon={<DownloadIcon />}
                            style={{
                                backgroundColor: '#6BBDBD',
                                color: 'white',
                                fontSize: '14px',
                                fontFamily: "'Fira Sans', sans-serif",
                                borderRadius: '20px',
                                textTransform: 'none',
                                boxShadow: 'none',
                            }}
                            onClick={handleMenuClick}
                        >
                            Descargar resumen
                        </Button>

                    ) : (userData.plan_name === "External" && docInfo?.version === 1) && (
                        <Button

                        variant="contained"
                        startIcon={<DownloadIcon />}
                        style={{
                            backgroundColor: '#6BBDBD',
                            color: 'white',
                            fontSize: '14px',
                            fontFamily: "'Fira Sans', sans-serif",
                            borderRadius: '20px',
                            textTransform: 'none',
                            boxShadow: 'none',
                        }}
                            onClick={()=>{handleDownloadSummary();}}
                        >
                            Descargar resumen
                        </Button>
                    )}
                    <StyledMenu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        elevation={0}
                    >
                        <StyledMenuItem onClick={() => { handleDownloadSummary(); handleMenuClose(); }}>
                            <PictureAsPdfIcon />
                            Descargar en PDF
                        </StyledMenuItem>
                        <StyledMenuItem onClick={handleDownloadWord}>
                            <DescriptionIcon />
                            Descargar en Word
                        </StyledMenuItem>
                    </StyledMenu>
                </Box>
            )}

            {notification && (
                <div style={{
                    backgroundColor: 'rgba(255, 183, 3, 0.5)', // Light yellowish-orange with 20% opacity
                    color: '#ffb703', // Darker text for better readability
                    padding: '10px',
                    borderRadius: '5px',
                    margin: '10px 0',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontSize: '14px',
                }}>
                    {notification}
                </div>
            )}

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    sx={{
                        width: '100%',
                        fontFamily: "'Fira Sans', sans-serif",
                        backgroundColor: snackbarSeverity === 'success' ? '#6BBDBD' : '#ff6b6b',
                        color: 'white',
                        '& .MuiAlert-icon': {
                            color: 'white'
                        }
                    }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            <CustomContainer >

                {newGeneratingSummary && !summary ? (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#F8FFFF',
                        minHeight: '20vh',
                        textAlign: 'center',
                        border: "4px solid #6BBDBD",
                        borderRadius: "8px",
                        padding: "16px",
                        backgroundColor: "white",
                        position: 'relative'
                    }}>
                        <Button onClick={handleRefresh} className="refresh-button" style={{ position: 'absolute', top: '10px', right: '10px' }}>
                            <RefreshIcon className={isSpinning ? 'spinner' : ''} style={{ color: "#026277" }} />
                            <span className="spinner" />
                        </Button>
                        <CircularProgress style={{ color: "#026277" }} />

                        <Typography style={{ fontSize: "18px", color: "#026277", fontWeight: 'bold' }}>
                            Estamos generando tu resumen
                        </Typography>
                        <Typography style={{ fontSize: "14px", color: "gray", paddingTop: "15px" }}>
                            Este proceso puede tardar entre 2 y 15 minutos, dependiendo de la cantidad de páginas
                        </Typography>
                        <Typography style={{ fontSize: "14px", color: "gray", marginBottom: "15px" }}>
                            Recibirás un correo electrónico tan pronto como esté listo
                        </Typography>
                    </Box>
                ) : summary !== null ? (
                    <div>
                        {(docInfo?.version === 2 && docInfo?.summary_active && userData?.plan_name !== 'External' && !isGeneratingSummary) ? (
                            <div style={{
                                marginLeft: {
                                    xs: '55px',
                                    sm: '55px',
                                    md: '40px',
                                    lg: '20px'
                                }
                            }}>

                                {editorValue && (
                                    <EditorText
                                        value={editorValue}
                                        onChange={setEditorValue}
                                        readOnly={false}
                                        onSave={handleSaveSummary}
                                    />
                                )}
                            </div>
                        ) : (docInfo?.summary_active && !isGeneratingSummary && userData?.plan_name === 'External' && docInfo?.version === 1) ? (
                            <div >
                                {Array.isArray(summary) ? (
                                    <Box >
                                        {Object.entries(
                                            summary.reduce((acc, section) => {
                                                if (!acc[section.header1]) {
                                                    acc[section.header1] = [];
                                                }
                                                acc[section.header1].push(section);
                                                return acc;
                                            }, {})
                                        ).map(([header1, sections], groupIndex) => (
                                            <Header1Group key={groupIndex}>
                                                <NoSeparatorAccordion
                                                    expanded={expandedSections[`header1-${groupIndex}`] || false}
                                                    onChange={handleAccordionChange(`header1-${groupIndex}`)}
                                                    className={classes.accordionPreguntas}
                                                    style={{ backgroundColor: 'rgb(248, 255, 255)' }}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls={`header1-${groupIndex}-content`}
                                                        id={`header1-${groupIndex}-header`}
                                                        className={`${classes.accordionSummaryPreguntas} ${customClasses.accordionSummary}`}
                                                        style={{ backgroundColor: 'rgb(248, 255, 255)' }}
                                                    >
                                                        <Typography className="header1-title">
                                                            {header1}
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <div className="nested-accordion">
                                                            {sections.map((section, index) => (
                                                                <NoSeparatorAccordion
                                                                    key={index}
                                                                    expanded={expandedSections[`panel${groupIndex}-${index}`] || false}
                                                                    onChange={handleAccordionChange(`panel${groupIndex}-${index}`)}
                                                                    className={classes.accordionPreguntas}
                                                                    style={{ backgroundColor: 'rgb(248, 255, 255)' }}
                                                                >
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls={`panel${groupIndex}-${index}bh-content`}
                                                                        id={`panel${groupIndex}-${index}bh-header`}
                                                                        className={`${classes.accordionSummaryPreguntas} ${customClasses.accordionSummary}`}
                                                                        style={{ backgroundColor: 'rgb(248, 255, 255)' }}
                                                                    >
                                                                        <Typography className={classes.headerPreguntas}>
                                                                            {section.header2}
                                                                        </Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>

                                                                        <ContentWrapper
                                                                            className="notranslate"
                                                                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formatContent(section.content)) }}
                                                                        />
                                                                    </AccordionDetails>
                                                                </NoSeparatorAccordion>
                                                            ))}
                                                        </div>
                                                    </AccordionDetails>
                                                </NoSeparatorAccordion>
                                            </Header1Group>
                                        ))}
                                    </Box>
                                ) : (
                                    <ContentWrapper
                                        className="notranslate"
                                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(summary) }}
                                    />
                                )}
                            </div>
                        ) : (docInfo?.version === 1 && docInfo?.summary_active && userData?.plan_name !== 'External') ? (
                            <Box
                                sx={{
                                    border: '3px solid #6BBDBD',
                                    borderRadius: '8px',
                                    padding: '16px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    backgroundColor: 'white'
                                }}
                            >
                                <Typography style={{ fontSize: "16px", color: "#026277", fontWeight: 'bold', marginBottom: "5px" }}>
                                    Hemos mejorado el resumen
                                </Typography>
                                <Typography style={{ fontSize: "14px", color: "#026277", fontWeight: 'normal', marginBottom: "30px" }}>
                                    Haz click en el botón para generar la nueva versión
                                </Typography>
                                <Button className={classes.generateSummary} onClick={() => handleGenerateSummary(false)}>
                                    Regenerar resumen
                                </Button>
                            </Box>
                        ) : (isGeneratingSummary) ? (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#F8FFFF',
                                minHeight: '20vh',
                                textAlign: 'center',
                                border: "4px solid #6BBDBD",
                                borderRadius: "8px",
                                padding: "16px",
                                backgroundColor: "white",
                                position: 'relative'
                            }}>
                                <Button onClick={handleRefresh} className="refresh-button" style={{ position: 'absolute', top: '10px', right: '10px' }}>
                                    <RefreshIcon className={isSpinning ? 'spinner' : ''} style={{ color: "#026277" }} />
                                    <span className="spinner" />
                                </Button>
                                <CircularProgress style={{ color: "#026277" }} />

                                <Typography style={{ fontSize: "18px", color: "#026277", fontWeight: 'bold' }}>
                                    Estamos generando tu resumen
                                </Typography>
                                <Typography style={{ fontSize: "14px", color: "gray", paddingTop: "15px" }}>
                                    Este proceso puede tardar entre 2 y 15 minutos, dependiendo de la cantidad de páginas
                                </Typography>
                                <Typography style={{ fontSize: "14px", color: "gray", marginBottom: "15px" }}>
                                    Recibirás un correo electrónico tan pronto como esté listo
                                </Typography>
                            </Box>
                        ) : (
                            <Typography>No hay resumen. Contáctanos para obtener ayuda</Typography>
                        )}
                    </div>
                ) : (
                    // Initial state - show generate summary button
                    <div style={{ textAlign: 'center', marginTop: "50px" }}>
                        {isGeneratingSummary ? (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#F8FFFF',
                                minHeight: '20vh',
                                textAlign: 'center',
                                border: "4px solid #6BBDBD",
                                borderRadius: "8px",
                                padding: "16px",
                                backgroundColor: "white",
                                position: 'relative'
                            }}>
                                <Button onClick={handleRefresh} className="refresh-button" style={{ position: 'absolute', top: '10px', right: '10px' }}>
                                    <RefreshIcon className={isSpinning ? 'spinner' : ''} style={{ color: "#026277" }} />
                                    <span className="spinner" />
                                </Button>
                                <CircularProgress style={{ color: "#026277" }} />

                                <Typography style={{ fontSize: "18px", color: "#026277", fontWeight: 'bold' }}>
                                    Estamos generando tu resumen
                                </Typography>
                                <Typography style={{ fontSize: "14px", color: "gray", paddingTop: "15px" }}>
                                    Este proceso puede tardar entre 2 y 15 minutos, dependiendo de la cantidad de páginas
                                </Typography>
                                <Typography style={{ fontSize: "14px", color: "gray", marginBottom: "15px" }}>
                                    Recibirás un correo electrónico tan pronto como esté listo
                                </Typography>
                            </Box>
                        ) : (

                            <>
                                <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/generatingSummary.svg" alt="Generating summary" />

                                <Button className={classes.generateSummary} onClick={() => handleGenerateSummary(false)}>
                                    {t("generate_summary")}
                                </Button>
                                {docInfo &&
                                    <Typography style={{ fontSize: "12px", color: "gray", marginTop: "10px" }}>
                                        {t("cost_action_credits", { number_credits: creditsNeeded })}
                                    </Typography>
                                }
                            </>
                        )}
                    </div>
                )}
            </CustomContainer>
        </>
    );
}

const formatContent = (content) => {
    // Return empty string if content is undefined/null
    if (!content) return '';

    // Convert dash-prefixed lines into list items
    return content.replace(/^-\s*(.*?)$/gm, '<li>$1</li>')
        .replace(/((?:<li>.*?<\/li>\n?)+)/g, '<ul>$1</ul>');
};

export default Summary;
