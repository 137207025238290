import React, { useEffect, useState, useRef } from 'react';
import { Excalidraw } from '@excalidraw/excalidraw';
import { Typography, Box, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAuth } from "../Auth/Authhandle";
import { fetchSchemaById, updateSchema } from '../Api/SchemasApi';
import { convertToExcalidrawElements } from '@excalidraw/excalidraw';
import { parseMermaidToExcalidraw } from '@excalidraw/mermaid-to-excalidraw';
import GetAppIcon from '@mui/icons-material/GetApp';
import { exportToSvg } from "@excalidraw/excalidraw";

const DEFAULT_FONT_SIZE = 16;

const MermaidChart = () => {
  const [excalidrawData, setExcalidrawData] = useState(null);
  const [schema, setSchema] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { schema_id } = useParams();
  const { token, user, isAuthenticated } = useAuth();
  const previousElements = useRef(null);
  const saveTimeoutRef = useRef(null);

  useEffect(() => {
    const loadSchema = async () => {
      if (token && user && schema_id) {
        try {
          setIsLoading(true);
          const schemaData = await fetchSchemaById(token, user, schema_id);

          // If excalidraw data exists, use it directly
          if (schemaData.excalidraw) {
            setExcalidrawData({
              elements: schemaData.excalidraw,
              appState: {
                viewModeEnabled: false,
                zenModeEnabled: false,
                gridSize: null,
                scrollToContent: false,
              },
              opts: {
                fitToViewport: true,
                viewportZoomFactor: 0.3
              }
            });
          } else {
            // Convert from mermaid if no excalidraw data exists
            const { elements, files } = await parseMermaidToExcalidraw(
              schemaData.elements, {
              fontSize: DEFAULT_FONT_SIZE,
            }
            );

            const excalidrawElements = convertToExcalidrawElements(elements);

            setExcalidrawData({
              elements: excalidrawElements,
              appState: {
                viewModeEnabled: false,
                zenModeEnabled: false,
                gridSize: null,
                scrollToContent: false,
              },
              opts: {
                fitToViewport: true,
                viewportZoomFactor: 0.3
              },
              files,
            });
          }

          setSchema(schemaData);
        } catch (error) {
          console.error('Error loading schema:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadSchema();
  }, [token, user, schema_id]);


  const handleChange = async (elements) => {
    if (!schema) return;

    // Skip if this is the first load or elements haven't changed
    if (!previousElements.current) {
      previousElements.current = elements;
      return;
    }

    // Compare current and previous elements
    const hasChanged = JSON.stringify(elements) !== JSON.stringify(previousElements.current);
    if (!hasChanged) return;

    // Update the reference
    previousElements.current = elements;

    if (saveTimeoutRef.current) {
      clearTimeout(saveTimeoutRef.current);
    }

    saveTimeoutRef.current = setTimeout(async () => {
      try {
        const updatedSchema = {
          ...schema,
          elements: elements // Save the excalidraw elements directly
        };

        await updateSchema(token, user, schema_id, updatedSchema);
        setSchema(updatedSchema);
      } catch (error) {
        console.error('Error saving changes:', error);
      }
    }, 1000);
  };

  // Clean up timeout on component unmount
  useEffect(() => {
    return () => {
      if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
      }
    };
  }, []);

  const handleDownload = async () => {
    try {
      // Create a temporary Excalidraw element
      const excalidrawElement = document.createElement("div");
      excalidrawElement.style.position = "fixed";
      excalidrawElement.style.opacity = "0";
      excalidrawElement.style.pointerEvents = "none";
      document.body.appendChild(excalidrawElement);

      // Generate SVG using excalidrawData
      const svg = await exportToSvg({
        elements: excalidrawData.elements,
        appState: {
          viewBackgroundColor: '#f5f5f5',
          exportWithDarkMode: false,
          exportScale: 2,
          gridSize: null,
        },
        files: excalidrawData.files,
      });

      // Create download link
      const svgBlob = new Blob([svg.outerHTML], { type: "image/svg+xml" });
      const downloadUrl = URL.createObjectURL(svgBlob);
      const downloadLink = document.createElement("a");
      downloadLink.href = downloadUrl;
      downloadLink.download = `${schema.title || 'schema'}.svg`;

      // Trigger download
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Cleanup
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(excalidrawElement);
    } catch (error) {
      console.error("Error downloading schema:", error);
    }
  };

  if (isLoading) {
    return <Typography>Loading schema...</Typography>;
  }

  if (!schema) {
    return <Typography>Schema not found</Typography>;
  }

  return (
    <Box sx={{ height: '90vh', backgroundColor: 'white' }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        marginTop: '20px'
      }}>
        <Typography
          style={{
            fontFamily: 'Inter',
            fontSize: '30px',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {schema.title}
        </Typography>
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            handleDownload(schema);
          }}
          style={{
            color: '#6BBDBD',
            position: 'absolute',
            right: '20px'
          }}
        >
          <GetAppIcon />
        </IconButton>
      </Box>
      <div style={{ width: '100%', height: 'calc(100% - 10px)' }}>
        {excalidrawData ? (
          <Excalidraw
            initialData={{
              ...excalidrawData,
              appState: {
                ...excalidrawData.appState,
                zoom: { value: 0.5 },
                activeTool: { type: "hand" }

              }
            }}
            onChange={(elements) => handleChange(elements)}
            UIOptions={{
              canvasActions: {
                export: false,
                loadScene: false,
                saveAsImage: false,
                saveScene: false,
                clearCanvas: false,
                changeViewBackgroundColor: false,
              },
            }}
            viewModeEnabled={false}
            zenModeEnabled={false}
            gridModeEnabled={false}
          />
        ) : (
          <Typography>Loading diagram...</Typography>
        )}
      </div>
    </Box>
  );
};

export default MermaidChart;
