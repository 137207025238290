import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import {
    FormControl,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    Rating,
    Typography,
    Box,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
    CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
const CancellationForm = ({ open, onClose, onSubmit, token, user }) => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        cancelReason: null,
        otherReason: '',
        meetExpectations: '',
        satisfaction: 0,
        materialFeedback: '',
        improvementFeedback: '',
        recommendationFeedback: '',
        additionalComments: ''
    });

    const [isFormValid, setIsFormValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const cancelOptions = [
        { value: 'finished', label: 'He terminado mis exámenes/estudios' },
        { value: 'not-helpful', label: 'El material generado no fue lo suficientemente útil' },
        { value: 'features', label: 'No encontré las funciones útiles para mis necesidades de estudio' },
        { value: 'expensive', label: 'Era demasiado caro' },
        { value: 'other', label: 'Otro' }
    ];

    const validateForm = (data) => {
        const isValid =
            data.cancelReason !== null && // Check if reason is selected
            (data.cancelReason?.value !== 'other' || data.otherReason.trim() !== '') && // If 'other' is selected, check otherReason
            data.satisfaction > 0 && // Check if rating is given
            data.improvementFeedback.trim() !== ''; // Check if improvement feedback is provided

        setIsFormValid(isValid);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        const newFormData = {
            ...formData,
            [name]: value
        };
        setFormData(newFormData);
        validateForm(newFormData);
    };

    const handleSelectChange = (selectedOption) => {
        const newFormData = {
            ...formData,
            cancelReason: selectedOption
        };
        setFormData(newFormData);
        validateForm(newFormData);
    };

    const handleRatingChange = (event, newValue) => {
        const newFormData = {
            ...formData,
            satisfaction: newValue
        };
        setFormData(newFormData);
        validateForm(newFormData);
    };

    const customSelectStyles = {
        control: (provided, state) => ({
            ...provided,
            fontFamily: "'Fira Sans', sans-serif",
            fontSize: '14px',
            borderColor: state.isFocused ? '#6BBDBD' : '#e0e0e0',
            boxShadow: state.isFocused ? '0 0 0 1px #6BBDBD' : null,
            '&:hover': {
                borderColor: '#6BBDBD'
            },
            marginBottom: '8px'
        }),
        option: (provided, state) => ({
            ...provided,
            fontFamily: "'Fira Sans', sans-serif",
            fontSize: '14px',
            backgroundColor: state.isSelected ? '#6BBDBD' :
                state.isFocused ? '#E2F1F1' : 'white',
            color: state.isSelected ? 'white' : '#2D2D2D',
            '&:active': {
                backgroundColor: '#6BBDBD'
            }
        }),
        menu: (provided) => ({
            ...provided,
            fontFamily: "'Fira Sans', sans-serif",
            zIndex: 9999
        }),
        placeholder: (provided) => ({
            ...provided,
            fontFamily: "'Fira Sans', sans-serif",
            fontSize: '14px',
            color: '#666666'
        })
    };

    const sendCancellationEmail = async (formData) => {
        const user_id = user.sub
        try {
            const response = await fetch(`${BACKEND_URL}/api_v1/cancel_plan/?user_id=${user_id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    reason: formData.cancelReason?.value === 'other' ? formData.otherReason : formData.cancelReason?.label,
                    satisfaction: formData.satisfaction,
                    improvement_feedback: formData.improvementFeedback
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to cancel plan');
            }

            const data = await response.json();
            console.log('Plan cancelled successfully:', data);
            return true;
        } catch (error) {
            console.error('Error cancelling plan:', error);
            return false;
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        const success = await sendCancellationEmail(formData);
        onSubmit(formData);
        setLoading(false);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    px: 1,
                    py: 2
                }
            }}
        >
            <DialogTitle sx={{
                fontFamily: "'Fira Sans', sans-serif",
                fontSize: '20px',
                fontWeight: 600,
                color: '#2D2D2D',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                Cancelar Suscripción
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        color: '#666666',
                        '&:hover': {
                            color: '#AA161D',
                        },
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 1 }}>
                    <FormControl fullWidth>
                        <Typography variant="subtitle1" sx={{
                            fontSize: '16px',
                            fontWeight: 500,
                            fontFamily: "'Fira Sans', sans-serif",
                            color: '#2D2D2D',
                            mb: 1
                        }}>
                            ¿Cuál es la razón principal para cancelar?
                        </Typography>
                        <Select
                            value={formData.cancelReason}
                            onChange={handleSelectChange}
                            options={cancelOptions}
                            styles={customSelectStyles}
                            placeholder="Selecciona una razón"
                            isSearchable={false}
                        />
                        {formData.cancelReason?.value === 'other' && (
                            <TextField
                                name="otherReason"
                                value={formData.otherReason}
                                onChange={handleChange}
                                placeholder="Por favor, especifica..."
                                multiline
                                rows={2}
                                sx={{
                                    fontFamily: "'Fira Sans', sans-serif",
                                    fontSize: '14px',
                                    '& .MuiInputBase-input': {
                                        fontFamily: "'Fira Sans', sans-serif",
                                        fontSize: '14px',
                                    }
                                }}
                            />
                        )}
                    </FormControl>

                    <Box>
                        <Typography variant="subtitle1" sx={{
                            fontSize: '16px',
                            fontWeight: 500,
                            fontFamily: "'Fira Sans', sans-serif",
                            color: '#2D2D2D',
                            mb: 1
                        }}>
                            ¿Qué tan satisfecho estuviste con Typed AI en general?
                        </Typography>
                        <Rating
                            name="satisfaction"
                            value={formData.satisfaction}
                            onChange={handleRatingChange}
                            size="large"
                        />
                    </Box>

                    <FormControl>
                        <Typography variant="subtitle1" sx={{
                            fontSize: '16px',
                            fontWeight: 500,
                            fontFamily: "'Fira Sans', sans-serif",
                            color: '#2D2D2D',
                            mb: 1
                        }}>
                            ¿Qué podríamos mejorar para hacer Typed AI más útil?
                        </Typography>
                        <TextField
                            name="improvementFeedback"
                            value={formData.improvementFeedback}
                            onChange={handleChange}
                            multiline
                            rows={4}
                            placeholder="Tus comentarios nos ayudan a mejorar..."
                            sx={{
                                fontFamily: "'Fira Sans', sans-serif",
                                fontSize: '14px',
                                '& .MuiInputBase-input': {
                                    fontFamily: "'Fira Sans', sans-serif",
                                    fontSize: '14px',
                                }
                            }}
                        />
                    </FormControl>
                </Box>
            </DialogContent>

            <DialogActions sx={{ p: 2, gap: 2 }}>
                <Button
                    onClick={onClose}
                    sx={{
                        color: '#666666',
                        fontFamily: "'Fira Sans', sans-serif",
                        textTransform: 'none'
                    }}
                >
                    Volver
                </Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    disabled={!isFormValid || loading}
                    sx={{
                        backgroundColor: '#AA161D',
                        '&:hover': {
                            backgroundColor: '#AA161D'
                        },
                        '&:disabled': {
                            backgroundColor: '#cccccc'
                        },
                        fontFamily: "'Fira Sans', sans-serif",
                        textTransform: 'none'
                    }}
                >
                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Cancelar Suscripción'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CancellationForm; 