import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  LinearProgress,
  Paper,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  IconButton,
  Snackbar,
  Alert,
  Checkbox
} from '@mui/material';
import { styled } from '@mui/styles';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import StopIcon from '@mui/icons-material/Stop';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import Flashcards from './Flashcards';
import QuestionFlashcard from './QuestionFlashcards';
import { motion, AnimatePresence } from 'framer-motion';
import FlashcardSummary from './FlashcardSummary';
import { useLocation } from 'react-router-dom';
import { updateFlashcardAppearances, updateSetFlashcardCompletion, deleteFlashcard } from '../Api/FlashcardsApi';
import { useAuth } from '../Auth/Authhandle';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ExplicacionFlashcard from './ExplicacionFlashcard';
import { streamJustification } from '../Exam/QdrantJustification';
import { useSubscription } from "../Context/SubscriptionContext";

import Chip from '@mui/material/Chip';
import ReplayIcon from '@mui/icons-material/Replay';
import FlagIcon from '@mui/icons-material/Flag';
import PopupTutorialFlashcard from '../AllModalsActions/PopupTutorialFlashcard';
import Tour from 'reactour';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditFlashcardPopup from './EditFlashcardPopup';
import { updateFlashcard } from '../Api/FlashcardApi';

const FlashcardContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: "800px",
  margin: '0 auto',
  padding: theme.spacing(2),
}));

const QuestionBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginBottom: "20px",
  backgroundColor: "white",
  borderRadius: "20px",
  minHeight: "200px",
  width: "100%",
  maxWidth: "800px",
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  border: '1px solid #E2F1F1',
  boxShadow: 'none',
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  padding: '8px 16px',
  textTransform: 'none',
  fontFamily: "'Fira Sans', sans-serif",
  fontSize: '14px',
  fontWeight: 600,
  backgroundColor: 'white',
  color: '#6BBDBD',
  border: '1px solid #6BBDBD',
  '&:hover': {
    backgroundColor: 'rgba(107, 189, 189, 0.1)',
  },
  '&.primary': {
    backgroundColor: '#6BBDBD',
    color: 'white',
    '&:hover': {
      backgroundColor: '#5AACAC',
    },
  }
}));

const ConfettiPiece = styled(motion.div)({
  position: 'absolute',
  width: '10px',
  height: '10px',
  borderRadius: '50%',
});

const HintButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  padding: '8px 16px',
  textTransform: 'none',
  fontFamily: "'Fira Sans', sans-serif",
  fontSize: '14px',
  fontWeight: 500,
  backgroundColor: 'transparent',
  color: '#666666',
  border: 'none',
  '&:hover': {
    backgroundColor: 'rgba(102, 102, 102, 0.08)',
  },
  '&.Mui-disabled': {
    color: 'rgba(102, 102, 102, 0.5)',
  }
}));

const calculateDifficulty = (card) => {
  if (card.usedReveal) {
    return 'muy_dificil';
  }

  if (card.answeredCorrectly?.length !== card.answers.length) {
    return 'sin_responder';
  }

  switch (card.appearances) {
    case 1:
      return 'facil';
    case 2:
      return 'normal';
    case 3:
      return 'dificil';
    case 4:
    default:
      return 'muy_dificil';
  }
};

// Add this constant before the FlashcardPage component

const SuccessAnimation = () => {
  const colors = ['#00B564', '#6BBDBD', '#E2F1F1', '#FFD700'];
  const confettiPieces = Array.from({ length: 50 });

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: 'none',
        zIndex: 1000,
      }}
    >
      {confettiPieces.map((_, i) => (
        <ConfettiPiece
          key={i}
          initial={{
            x: window.innerWidth / 2,
            y: window.innerHeight / 2,
            scale: 0,
          }}
          animate={{
            x: window.innerWidth * Math.random(),
            y: window.innerHeight * Math.random(),
            scale: Math.random() * 2 + 1,
            opacity: 0,
          }}
          transition={{
            duration: 1.5,
            ease: "easeOut",
            delay: Math.random() * 0.2,
          }}
          style={{
            backgroundColor: colors[Math.floor(Math.random() * colors.length)],
          }}
        />
      ))}
    </Box>
  );
};

// Define the ExplanationButton styled component
const ExplanationButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  padding: '6px 16px',
  textTransform: 'none',
  fontFamily: "'Fira Sans', sans-serif",
  fontSize: '13px',
  fontWeight: 500,
  backgroundColor: '#F8F9FA',
  color: '#666666',
  border: '1px solid #E0E0E0',
  '&:hover': {
    backgroundColor: '#EAECEF',
  },
}));

// Add this styled component after the other styled components
const FinishButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  padding: '6px 16px',
  textTransform: 'none',
  fontFamily: "'Fira Sans', sans-serif",
  fontSize: '13px',
  fontWeight: 500,
  backgroundColor: 'white',
  color: '#666666',
  border: '1px solid #E0E0E0',
  '&:hover': {
    backgroundColor: '#F8F9FA',
    borderColor: '#666666',
  },
}));

// Add these color constants at the top of the file
const difficultyColors = {
  muy_dificil: { bg: '#FFEBEE', text: '#F44336', hover: '#FFCDD2' },
  dificil: { bg: '#FFF3E0', text: '#FF9800', hover: '#FFE0B2' },
  normal: { bg: '#E3F2FD', text: '#2196F3', hover: '#BBDEFB' },
  facil: { bg: '#E8F5E9', text: '#4CAF50', hover: '#C8E6C9' }
};

// Add this styled component with the other styled components
const RepeatChip = styled(Chip)(({ theme }) => ({
  backgroundColor: '#FFEBEE',
  color: 'red',
  fontFamily: "'Fira Sans', sans-serif",
  fontSize: '12px',
  fontWeight: 500,
  height: '30px',
  padding: '0px 10px',
  '& .MuiChip-icon': {
    color: 'red',
    fontSize: '16px'
  }
}));

const FlagDialog = ({ open, onClose, onFlag }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '12px',
          maxWidth: '400px',
          padding: '8px'
        }
      }}
    >
      <DialogTitle sx={{
        fontFamily: "'Fira Sans', sans-serif",
        fontSize: '20px',
        fontWeight: 500,
        color: '#2D2D2D',
        pb: 1
      }}>
        Eliminar flashcard
      </DialogTitle>
      <DialogContent>
        <Typography sx={{
          color: '#666666',
          fontFamily: "'Fira Sans', sans-serif",
          fontSize: '14px'
        }}>
          ¿Quieres eliminar esta tarjeta y que no aparezca en tus flashcards?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        <Button
          onClick={onClose}
          sx={{
            color: '#666666',
            textTransform: 'none',
            fontFamily: "'Fira Sans', sans-serif",
            fontSize: '14px',
            fontWeight: 500,
            '&:hover': {
              backgroundColor: 'rgba(102, 102, 102, 0.08)',
            },
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={onFlag}
          variant="contained"
          sx={{
            backgroundColor: '#FF4444',
            color: 'white',
            textTransform: 'none',
            boxShadow: 'none',
            fontFamily: "'Fira Sans', sans-serif",
            fontSize: '14px',
            fontWeight: 500,
            borderRadius: '20px',
            padding: '6px 16px',
            '&:hover': {
              backgroundColor: '#FF4444',
            },
          }}
        >
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ThirdAppearanceDialog = ({ open, onClose, onEdit, onRemove, onContinue, documentId }) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const handleAction = (action) => {
    if (dontShowAgain) {
      // Store the preference in localStorage with document ID
      localStorage.setItem(`hideThirdAppearanceDialog_${documentId}`, 'true');
    }
    action();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '12px',
          maxWidth: '400px',
          padding: '8px'
        }
      }}
    >
      <DialogTitle sx={{
        fontFamily: "'Fira Sans', sans-serif",
        fontSize: '20px',
        fontWeight: 500,
        color: '#2D2D2D',
        pb: 1
      }}>
        Tercer intento
      </DialogTitle>
      <DialogContent>
        <Typography sx={{
          color: '#666666',
          fontFamily: "'Fira Sans', sans-serif",
          fontSize: '14px'
        }}>
          Esta es la tercera vez que intentas responder esta tarjeta. ¿Qué te gustaría hacer?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ 
        padding: '16px 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: 1
      }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 1,
          width: '100%',
          mb: 1
        }}>
          <Checkbox
            checked={dontShowAgain}
            onChange={(e) => setDontShowAgain(e.target.checked)}
            sx={{
              color: '#6BBDBD',
              '&.Mui-checked': {
                color: '#6BBDBD',
              },
            }}
          />
          <Typography sx={{
            color: '#666666',
            fontFamily: "'Fira Sans', sans-serif",
            fontSize: '14px'
          }}>
            No volver a mostrar más
          </Typography>
        </Box>
        <Button
          onClick={() => handleAction(onEdit)}
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: '#6BBDBD',
            color: 'white',
            textTransform: 'none',
            boxShadow: 'none',
            fontFamily: "'Fira Sans', sans-serif",
            fontSize: '14px',
            fontWeight: 500,
            borderRadius: '20px',
            padding: '10px 16px',
            '&:hover': {
              backgroundColor: '#5AACAC',
            },
          }}
        >
          Editar tarjeta
        </Button>
        <Button
          onClick={() => handleAction(onRemove)}
          fullWidth
          sx={{
            backgroundColor: '#FF4444',
            color: 'white',
            textTransform: 'none',
            boxShadow: 'none',
            fontFamily: "'Fira Sans', sans-serif",
            fontSize: '14px',
            fontWeight: 500,
            borderRadius: '20px',
            padding: '10px 16px',
            '&:hover': {
              backgroundColor: '#E03131',
            },
          }}
        >
          Eliminar tarjeta
        </Button>
        <Button
          onClick={() => handleAction(onContinue)}
          fullWidth
          sx={{
            color: '#666666',
            textTransform: 'none',
            fontFamily: "'Fira Sans', sans-serif",
            fontSize: '14px',
            fontWeight: 500,
            '&:hover': {
              backgroundColor: 'rgba(102, 102, 102, 0.08)',
            },
          }}
        >
          Continuar respondiendo
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Add this styled component with the other styled components
const DeleteButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#FF4444',
  color: 'white',
  textTransform: 'none',
  fontFamily: "'Fira Sans', sans-serif",
  fontSize: '14px',
  fontWeight: 500,
  borderRadius: '20px',
  padding: '6px 16px',
  '&:hover': {
    backgroundColor: '#E03131',  // Slightly darker red on hover
  },
}));

// Add this helper function near the top of the file, with other utility functions
const removeAccents = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

// Create a separate style element for the keyframes animation
const pulseAnimation = `
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0 rgba(107, 189, 189, 0.4);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(107, 189, 189, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(107, 189, 189, 0);
    }
  }
`;

// Create a style element to inject the keyframes
const injectPulseAnimation = () => {
  const style = document.createElement('style');
  style.type = 'text/css';
  style.innerHTML = pulseAnimation;
  document.head.appendChild(style);
  return () => {
    document.head.removeChild(style);
  };
};

// Add this styled component for the speaker icon button
const SpeakerButton = styled(IconButton)(({ theme }) => ({
  color: '#6BBDBD',
  backgroundColor: 'rgba(226, 241, 241, 0.5)',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(226, 241, 241, 0.8)',
    transform: 'scale(1.05)',
  },
  '&.speaking': {
    backgroundColor: 'rgba(107, 189, 189, 0.2)',
    animation: 'pulse-animation 1.5s infinite',
  }
}));

const FlashcardPage = () => {
  const location = useLocation();
  const studyMode = location.state?.studyMode || 'typing';
  const { userData } = useSubscription();
  const receivedFlashcards = location.state?.flashcardsSet?.flashcards || [];
  const documentTitle = location.state?.customization?.documentTitle || 'Untitled Document';
  const folderColor = location.state?.customization?.folderColor || '#6BBDBD';
  const documentId = location.state?.flashcardsSet?.set_flashcard?.document;
  const language = location.state?.language || 'español';
  const [initialCardCount, setInitialCardCount] = useState(receivedFlashcards.length);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('warning');
  const [showAnswerConfirm, setShowAnswerConfirm] = useState(false);
  const [dontShowAnswerConfirm, setDontShowAnswerConfirm] = useState(false);
  let flashcards = receivedFlashcards || [];
  // Transform received flashcards to match the expected format
  const transformFlashcards = (flashcardsData) => {
    return flashcardsData.map(card => ({
      id: card.guid,
      type: card.type,
      question: card.question,
      answer: card.answer,
      // Split each answer into individual words and trim whitespace
      answers: card.answers?.map(answer => answer.split(/\s+/).filter(word => word.length > 0)) || [],
      text: card.text,
      currentClozeIndex: 0,
      attempts: 0,
      appearances: 1,
      answeredCorrectly: [],
      revealed: false,
      difficulty: 'sin_responder'
    }));
  };

  // Replace the hardcoded flashcards array with transformed received flashcards
  const [cards, setCards] = useState(transformFlashcards(receivedFlashcards));

  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [hintCount, setHintCount] = useState(0);
  const [state, setState] = useState({
    isCorrect: false,
    isAttempted: false,
    userInput: '',
    wrongAnswers: [],
    answeredBlanks: {},
    currentInput: ''
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [startTime] = useState(Date.now());
  const [endTime, setEndTime] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [showDifficultyOptions, setShowDifficultyOptions] = useState(false);
  const { token, user, isAuthenticated } = useAuth();
  const currentCard = cards[currentCardIndex];
  const currentAnswer = currentCard.answers[currentCard.currentClozeIndex];
  const progress = ((currentCardIndex + 1) / cards.length) * 100;
  const [isHandleFinish, setIsHandleFinish] = useState(false);
  const [isExplanationOpen, setIsExplanationOpen] = useState(false);
  const [explanationText, setExplanationText] = useState("");
  const [isLoadingExplanation, setIsLoadingExplanation] = useState(false);
  const [isFlagDialogOpen, setIsFlagDialogOpen] = useState(false);
  const [showTutorial, setShowTutorial] = useState(false);
  const [showProductTour, setShowProductTour] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [showThirdAppearanceDialog, setShowThirdAppearanceDialog] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  
  useEffect(() => {
    if (userData) {
      setShowProductTour(!userData.product_tour_done);
    }
  }, [userData]);

  // Inject the pulse animation CSS on component mount
  useEffect(() => {
    const cleanup = injectPulseAnimation();
    return () => {
      cleanup();
    };
  }, []);

  // Move tourSteps inside the FlashcardPage component
  const tourSteps = [
    {
      selector: '.flashcard-content',
      content: 'Los guiones indican el número de palabras de ese hueco',
      style: {
        backgroundColor: 'white',
        borderRadius: '12px',
        padding: '20px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
      }
    },
    {
      selector: '.answer-input',
      content: 'Escribe la respuesta de cualquier hueco y pulsa Enter para validar',
      style: {
        backgroundColor: 'white',
        borderRadius: '12px',
        padding: '20px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
      }
    },
    {
      selector: '.show-answer-btn',
      content: 'Si no te sabes la respuesta pulsa Ver respuesta',
      style: {
        backgroundColor: 'white',
        borderRadius: '12px',
        padding: '20px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
      }
    },
    {
      selector: '', // Empty selector for a modal-style final step
      content: () => (
        <div style={{ textAlign: 'center' }}>
          <Typography
            sx={{
              fontFamily: "'Fira Sans', sans-serif",
              fontSize: '16px',
              color: '#666666',
              marginBottom: '20px'
            }}
          >
            ¡Ya estás listo para empezar!
          </Typography>
          <Button
            onClick={() => setShowProductTour(false)}
            sx={{
              backgroundColor: '#6BBDBD',
              color: 'white',
              textTransform: 'none',
              fontFamily: "'Fira Sans', sans-serif",
              fontSize: '14px',
              fontWeight: 500,
              borderRadius: '20px',
              padding: '8px 24px',
              '&:hover': {
                backgroundColor: '#5AACAC',
              },
            }}
          >
            Finalizar
          </Button>
        </div>
      ),
      style: {
        backgroundColor: 'white',
        borderRadius: '12px',
        padding: '20px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
      }
    }
  ];

  const tourConfig = {
    rounded: 8,
    accentColor: '#6BBDBD',
    showCloseButton: false,
    showNavigation: false,
    showNavigationNumber: false,
    disableKeyboardNavigation: true,
    disableInteraction: true,
    maskSpace: 10,
  };

  const validateInput = (input) => {
    if (!input.trim()) {
      setAlertMessage('Por favor, introduce una respuesta');
      setAlertSeverity('warning');
      setShowAlert(true);
      return false;
    }

    // Check for multiple spaces
    if (/\s{2,}/.test(input.trim())) {
      setAlertMessage('La respuesta no debe contener espacios múltiples');
      setAlertSeverity('warning');
      setShowAlert(true);
      return false;
    }

    return true;
  };

  const checkAnswer = () => {
    if (!state.currentInput.trim()) return;

    // Validate input before checking answer
    if (!validateInput(state.currentInput)) {
      return;
    }

    const userAnswers = state.currentInput.trim().toLowerCase().split(/\s+/);
    let foundMatches = 0;
    let updatedAnsweredBlanks = { ...(state.answeredBlanks || {}) };
    
    // For each word in the user's input
    userAnswers.forEach(userAnswer => {
      // Check against all available blanks
      currentCard.answers.forEach((answerWords, blankIndex) => {
        // Skip already fully answered blanks
        if (updatedAnsweredBlanks[blankIndex]) return;
        
        // Find the first unanswered word in this blank
        let firstUnansweredIndex = -1;
        for (let i = 0; i < answerWords.length; i++) {
          if (!updatedAnsweredBlanks[`${blankIndex}-${i}`]) {
            firstUnansweredIndex = i;
            break;
          }
        }

        // If we found an unanswered word and it matches the user's input
        if (firstUnansweredIndex !== -1 && 
            removeAccents(userAnswer) === removeAccents(answerWords[firstUnansweredIndex].toLowerCase())) {
          foundMatches++;
          // Store the word-specific answer
          updatedAnsweredBlanks[`${blankIndex}-${firstUnansweredIndex}`] = answerWords[firstUnansweredIndex];
          
          // Check if all words in this blank are answered
          const allWordsAnswered = answerWords.every((_, idx) => 
            updatedAnsweredBlanks[`${blankIndex}-${idx}`]
          );
          
          if (allWordsAnswered) {
            updatedAnsweredBlanks[blankIndex] = answerWords.join(' ');
            
            // Update cards state
            setCards(prevCards => {
              const newCards = [...prevCards];
              const currentAnsweredCorrectly = [...(newCards[currentCardIndex].answeredCorrectly || [])];
              if (!currentAnsweredCorrectly.includes(blankIndex)) {
                currentAnsweredCorrectly.push(blankIndex);
              }
              
              const updatedCard = {
                ...newCards[currentCardIndex],
                answeredCorrectly: currentAnsweredCorrectly
              };
              
              // Check if all blanks are answered
              if (currentAnsweredCorrectly.length === currentCard.answers.length) {
                updatedCard.difficulty = calculateDifficulty(updatedCard);
              }
              
              newCards[currentCardIndex] = updatedCard;
              return newCards;
            });
          }
        }
      });
    });

    if (foundMatches > 0) {
      setShowSuccess(true);
      setHintCount(0);
      
      setState(prev => ({
        ...prev,
        answeredBlanks: updatedAnsweredBlanks,
        currentInput: '',
        isAttempted: false
      }));

      // Check if all blanks are completely answered
      const allBlanksAnswered = currentCard.answers.every((answerWords, blankIndex) => 
        answerWords.every((_, wordIndex) => updatedAnsweredBlanks[`${blankIndex}-${wordIndex}`])
      );

      if (allBlanksAnswered) {
        setTimeout(async () => {
          setShowSuccess(false);
          if (currentCardIndex < cards.length - 1) {
            setCurrentCardIndex(prev => prev + 1);
            setState({
              isCorrect: false,
              isAttempted: false,
              currentInput: '',
              wrongAnswers: [],
              answeredBlanks: {},
            });
          } else {
            setEndTime(Date.now());
            setIsCompleted(true);
            await updateBackendOnCompletion();
          }
        }, 2000);
      } else {
        setTimeout(() => {
          setShowSuccess(false);
        }, 2000);
      }
    } else {
      setCards(prevCards => {
        const newCards = [...prevCards];
        const updatedCard = {
          ...newCards[currentCardIndex],
          attempts: newCards[currentCardIndex].attempts + 1
        };

        if (updatedCard.attempts > 4 && studyMode === 'typing' && !updatedCard.scheduledForRepetition) {
          const cardToMoveToEnd = {
            ...newCards[currentCardIndex],
            attempts: 0,
            currentClozeIndex: 0,
            answeredCorrectly: [],
            revealed: false,
            appearances: (newCards[currentCardIndex].appearances || 1) + 1
          };
          newCards.push(cardToMoveToEnd);
          updatedCard.scheduledForRepetition = true;
        }

        newCards[currentCardIndex] = updatedCard;
        return newCards;
      });

      setState(prev => ({
        ...prev,
        isCorrect: false,
        isAttempted: true,
        currentInput: '',
        wrongAnswers: [...new Set([...prev.wrongAnswers, prev.currentInput.trim()])]
      }));
    }
  };

  const handleNext = async () => {
    // Stop any ongoing speech when navigating to next card
    if (isSpeaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
    }
    
    setHintCount(0);
    if (currentCardIndex < cards.length - 1) {
      setCurrentCardIndex(prev => prev + 1);
      setState({
        isCorrect: false,
        isAttempted: false,
        currentInput: '',
        wrongAnswers: [],
        answeredBlanks: {}
      });
      setCards(prevCards => {
        const newCards = [...prevCards];
        newCards[currentCardIndex] = {
          ...newCards[currentCardIndex],
          revealed: false
        };
        return newCards;
      });
    } else {
      setEndTime(Date.now());
      setIsCompleted(true);
      await updateBackendOnCompletion();
    }
  };

  const handlePrevious = () => {
    // Stop any ongoing speech when navigating to previous card
    if (isSpeaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
    }
    
    if (currentCardIndex > 0) {
      setCurrentCardIndex(prev => prev - 1);
      setState({
        isCorrect: false,
        isAttempted: false,
        userInput: ''
      });
    }
  };
  
  // Add cleanup for speech synthesis when component unmounts
  useEffect(() => {
    return () => {
      window.speechSynthesis.cancel();
    };
  }, []);
  
  const handleSkip = () => {
    // Stop any ongoing speech when skipping
    if (isSpeaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
    }

    // Create a copy of the current card to add at the end
    const skippedCard = {
      ...currentCard,
      attempts: 0,
      currentClozeIndex: 0,
      answeredCorrectly: [],
      revealed: false,
      appearances: currentCard.appearances
    };

    setCards(prevCards => {
      const newCards = [...prevCards];
      // Remove the current card from its position
      newCards.splice(currentCardIndex, 1);
      // Add it to the end
      newCards.push(skippedCard);
      return newCards;
    });

    // Reset the state for the next card
    setState({
      isCorrect: false,
      isAttempted: false,
      userInput: '',
      wrongAnswers: [],
      answeredBlanks: {},
      currentInput: ''
    });

    // Since we removed the current card, we don't need to increment the index
    // The next card will automatically move into the current position
    if (currentCardIndex >= cards.length - 1) {
      setEndTime(Date.now());
      setIsCompleted(true);
      updateBackendOnCompletion();
    }
  };

  const handleShowHint = () => {
    if (hintCount >= 5) return;

    // Find the next unanswered word position
    let targetWord = null;
    let targetBlankIndex = -1;
    let targetWordIndex = -1;

    // Find the first unanswered word
    for (let blankIndex = 0; blankIndex < currentCard.answers.length; blankIndex++) {
      const answerWords = currentCard.answers[blankIndex];
      for (let wordIndex = 0; wordIndex < answerWords.length; wordIndex++) {
        if (!state.answeredBlanks[`${blankIndex}-${wordIndex}`]) {
          targetWord = answerWords[wordIndex];
          targetBlankIndex = blankIndex;
          targetWordIndex = wordIndex;
          break;
        }
      }
      if (targetWord) break;
    }

    if (!targetWord) return; // No words left to hint

    // Get the current hint progress for this specific word
    const currentWordHintKey = `hint-${targetBlankIndex}-${targetWordIndex}`;
    const currentWordHint = state.answeredBlanks[currentWordHintKey] || '';
    
    // Add the next letter to the current hint
    const newHint = targetWord.substring(0, currentWordHint.length + 1);
    
    setHintCount(prev => prev + 1);
    setCards(prevCards => {
      const newCards = [...prevCards];
      newCards[currentCardIndex] = {
        ...newCards[currentCardIndex],
        usedHint: true
      };
      return newCards;
    });
    
    setState(prev => ({
      ...prev,
      currentInput: newHint,
      answeredBlanks: {
        ...prev.answeredBlanks,
        [currentWordHintKey]: newHint
      },
      isAttempted: false
    }));
  };

  const handleShowAnswer = () => {
    if (currentCard.attempts === 0 && !dontShowAnswerConfirm) {
      setShowAnswerConfirm(true);
      return;
    }

    revealAnswer();
  };

  const revealAnswer = () => {
    setCards(prevCards => {
      const newCards = [...prevCards];
      const currentCard = newCards[currentCardIndex];
      
      // Only add to end if in typing mode and not already scheduled for repetition
      if (studyMode === 'typing' && !currentCard.scheduledForRepetition) {
        const cardToMoveToEnd = {
          ...currentCard,
          attempts: 0,
          currentClozeIndex: 0,
          answeredCorrectly: [],
          revealed: false,
          appearances: (currentCard.appearances || 1) + 1
        };
        newCards.push(cardToMoveToEnd);
      }

      // Update the current card
      newCards[currentCardIndex] = {
        ...currentCard,
        revealed: true,
        answeredCorrectly: currentCard.answers.map((_, index) => index),
        attempts: 5,
        usedReveal: true,
        difficulty: 'dificil',
        scheduledForRepetition: studyMode === 'typing'
      };

      return newCards;
    });

    setState(prev => ({
      ...prev,
      isAttempted: true,
      currentInput: '',
      isCorrect: false,
      answeredBlanks: currentCard.answers.reduce((acc, answer, index) => {
        acc[index] = answer;
        return acc;
      }, {})
    }));
    
    setShowDifficultyOptions(true);
  };

  const handleConfirmShowAnswer = (dontShowAgain) => {
    if (dontShowAgain) {
      setDontShowAnswerConfirm(true);
    }
    setShowAnswerConfirm(false);
    revealAnswer();
  };

  const handleSelectDifficulty = async (difficulty) => {
    setCards(prevCards => {
      const newCards = [...prevCards];
      const currentCard = newCards[currentCardIndex];

      if (difficulty === 'dificil') {
        // Clone the card to move to the end
        const cardToMoveToEnd = {
          ...currentCard,
          attempts: 0,
          currentClozeIndex: 0,
          answeredCorrectly: [],
          revealed: false,
          appearances: (currentCard.appearances || 1) + 1
        };
        newCards.push(cardToMoveToEnd);
      }

      // Update current card
      newCards[currentCardIndex] = {
        ...currentCard,
        selectedDifficulty: difficulty,
        difficulty: difficulty,
        scheduledForRepetition: difficulty === 'dificil',
        answeredCorrectly: difficulty === 'facil'
          ? Array.from({ length: currentCard.answers.length }, (_, i) => i)
          : currentCard.answeredCorrectly || []
      };

      // Handle the navigation here, with access to newCards
      if (currentCardIndex < newCards.length - 1) {
        setCurrentCardIndex(currentCardIndex + 1);
      } else {
        setEndTime(Date.now());
        setIsCompleted(true);
        updateBackendOnCompletion();
      }

      return newCards;
    });
    setShowDifficultyOptions(false);
  };

  // Update the keyboard handler
  React.useEffect(() => {
    const handleKeyDown = async (e) => {
      if (currentCard.revealed || state.isCorrect) {
        if (currentCardIndex < cards.length - 1) {
          setCurrentCardIndex(prev => prev + 1);
          setState({
            isCorrect: false,
            isAttempted: false,
            userInput: '',
            wrongAnswers: []
          });
          setCards(prevCards => {
            const newCards = [...prevCards];
            newCards[currentCardIndex] = {
              ...newCards[currentCardIndex],
              revealed: false
            };
            return newCards;
          });
        } else {
          // Handle last card the same way as "Continuar" button
          setEndTime(Date.now());
          setIsCompleted(true);
          await updateBackendOnCompletion();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [currentCard.revealed, state.isCorrect, currentCardIndex, cards.length]);

  // Add explain function
  const handleExplain = async () => {
    setIsExplanationOpen(true);
    setIsLoadingExplanation(true);
    setExplanationText("");
    const document_guid = location.state?.flashcardsSet?.set_flashcard?.document;


    try {
      await streamJustification(
        {
          answer: currentCard.answer,
          question: currentCard.question,
          text: currentCard.text,
          type: currentCard.type,
          documentId: document_guid,
          userId: user['id'],
          flashcardId: currentCard.id,
          typeJustification: "flashcard",
        },
        (word) => {
          setExplanationText(prev => prev + word);
        },
        user,
        token
      );

    } catch (error) {
      console.error("Error fetching explanation:", error);
      setExplanationText("Lo sentimos, no pudimos generar la explicación en este momento.");
    } finally {
      setIsLoadingExplanation(false);
    }
  };

  // Add a utility function to get statistics
  const getFlashcardStats = () => {
    const stats = {
      totalCards: cards.length,
      averageAttempts: cards.reduce((acc, card) => acc + card.attempts, 0) / cards.length,
      timeSpent: endTime ? Math.floor((endTime - startTime) / 1000) : 0,
      difficulty: {
        facil: 0,
        normal: 0,
        dificil: 0,
        muy_dificil: 0,
        sin_responder: 0
      }
    };

    cards.forEach(card => {
      const difficulty = studyMode === 'flashcard' ?
        card.selectedDifficulty || 'sin_responder' :
        calculateDifficulty(card);

      if (difficulty in stats.difficulty) {
        stats.difficulty[difficulty]++;
      }
    });

    stats.masteryGrade = ((stats.difficulty.facil + stats.difficulty.normal) / stats.totalCards) * 100;

    return stats;
  };

  // Add text-to-speech function
  const speakText = (text) => {
    // Cancel any ongoing speech
    window.speechSynthesis.cancel();
    
    if (!text) return;
    
    // Create a new speech synthesis utterance
    const utterance = new SpeechSynthesisUtterance(text);
    
    // Set language to Spanish (can be configurable)
    if (language.toLowerCase() === 'español') {
      utterance.lang = 'es-ES';
    } else if (language.toLowerCase() === 'catalán' || language.toLowerCase() === 'catalan') {
      utterance.lang = 'ca-ES';
    } else if (language.toLowerCase() === 'inglés' || language.toLowerCase() === 'english') {
      utterance.lang = 'en-US';
    }else{
      utterance.lang = 'es-ES';
    }
    
    // Set voice properties
    utterance.volume = 1; // 0 to 1
    utterance.rate = 1.0; // 0.1 to 10
    utterance.pitch = 1.0; // 0 to 2
    
    // Event handlers
    utterance.onstart = () => {
      setIsSpeaking(true);
    };
    
    utterance.onend = () => {
      setIsSpeaking(false);
    };
    
    // Add error handler but don't show alerts
    utterance.onerror = () => {
      setIsSpeaking(false);
      // Don't show any error message
    };
    
    // Start speaking
    window.speechSynthesis.speak(utterance);
  };

  // Get the text to speak based on current card type
  const getTextToSpeak = () => {
    if (currentCard.type === 'question') {
      return currentCard.revealed ? 
        `${currentCard.question} La respuesta es: ${currentCard.answer}` : 
        currentCard.question;
    }
    
    // For cloze type, get the text with blanks for speaking
    let text = currentCard.text;
    
    // If answers are revealed or completed, speak the full text with answers
    if (currentCard.revealed || 
        (currentCard.answeredCorrectly && 
         currentCard.answeredCorrectly.length === currentCard.answers.length)) {
      
      // Replace placeholders with actual answers for speech
      currentCard.answers.forEach((answer, index) => {
        const answerText = Array.isArray(answer) ? answer.join(' ') : answer;
        // Use a regex to replace the placeholder with the answer
        const placeholder = new RegExp(`\\[\\s*_+\\s*\\]`, 'g');
        text = text.replace(placeholder, answerText);
      });
    }
    
    return text;
  };
  
  // Handle speaker button click
  const handleSpeakerClick = () => {
    if (isSpeaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
    } else {
      speakText(getTextToSpeak());
    }
  };

  const renderFlashcard = () => {
    if (currentCard.type === 'question') {
      return (
        <div className="flashcard-content">
          <Box sx={{ position: 'relative' }}>
            <QuestionFlashcard 
              currentCard={currentCard} 
              state={state} 
              studyMode={studyMode}
              answeredBlanks={state.answeredBlanks}
            />
          </Box>
        </div>
      );
    }
    return (
      <div className="flashcard-content">
        <Box sx={{ position: 'relative' }}>
          <Flashcards 
            currentCard={currentCard} 
            state={state} 
            studyMode={studyMode}
            answeredBlanks={state.answeredBlanks}
          />
        </Box>
      </div>
    );
  };

  const handleRestart = () => {
    setIsCompleted(false);
    setCurrentCardIndex(0);
    setCards(flashcards.map(card => ({
      ...card,
      currentClozeIndex: 0,
      attempts: 0,
      answeredCorrectly: [],
      revealed: false,
      difficulty: 'sin_responder'
    })));
    setState({
      isCorrect: false,
      isAttempted: false,
      userInput: '',
      wrongAnswers: [],
      answeredBlanks: {},
      currentInput: ''
    });
  };

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleFinish = async () => {
    setIsHandleFinish(true);
    setEndTime(Date.now());
    setIsCompleted(true);
    setOpenConfirmDialog(false);
    await updateBackendOnFinalizar();
  };

  const getUniqueCardsWithFinalStateOnFinalClick = (cards) => {
    // Create a map to store the latest state of each unique card
    const uniqueCardsMap = cards.reduce((acc, card) => {
      // If this card ID exists in the map
      if (acc.has(card.id)) {
        const existingCard = acc.get(card.id);
        // Update only if this instance is more recent (higher index in array)
        const cardIndex = cards.indexOf(card);
        const existingIndex = cards.indexOf(existingCard);

        if (cardIndex > existingIndex) {
          acc.set(card.id, card);
        }
      } else {
        // If this is the first instance of this card ID, add it
        acc.set(card.id, card);
      }
      return acc;
    }, new Map());

    // Convert map back to array and update appearances
    return Array.from(uniqueCardsMap.values()).map(card => ({
      ...card,
      // Set appearances to 5 if no correct answers, otherwise keep original count
      appearances: (!card.answeredCorrectly || card.answeredCorrectly.length === 0) ? 5 : (card.appearances || 1)
    }));
  };

  const getUniqueCardsWithFinalState = (cards) => {
    // Create a map to store the latest state of each unique card

    const uniqueCardsMap = cards.reduce((acc, card) => {
      // If this card ID exists, update it only if this instance has more appearances
      if (acc.has(card.id)) {
        const existingCard = acc.get(card.id);
        if ((card.appearances || 1) > (existingCard.appearances || 1)) {
          acc.set(card.id, card);
        }
      } else {
        // If this is the first instance of this card ID, add it
        acc.set(card.id, card);
      }
      return acc;
    }, new Map());

    // Convert map back to array
    return Array.from(uniqueCardsMap.values());
  };

  const updateBackendOnCompletion = async () => {
    const timeSpent = Math.floor((endTime - startTime) / 1000);
    const setFlashcardId = location.state?.flashcardsSet?.set_flashcard?.guid;
    const documentId = location.state?.flashcardsSet?.set_flashcard?.document;

    // Create appearances update object
    const flashcardUpdates = cards.reduce((acc, card) => {
      acc[card.id] = card.appearances;

      return acc;
    }, {});

    // Update flashcards appearances
    if (Object.keys(flashcardUpdates).length > 0) {
      try {
        await updateFlashcardAppearances(token, documentId, flashcardUpdates, user);
      } catch (error) {
        console.error('Error updating flashcard appearances:', error);
      }
    }

    //Update set flashcard completion status and time
    if (setFlashcardId) {
      try {
        await updateSetFlashcardCompletion(token, setFlashcardId, timeSpent, user);
      } catch (error) {
        console.error('Error updating set flashcard:', error);
      }
    }
  };

  const updateBackendOnFinalizar = async () => {
    const timeSpent = Math.floor((endTime - startTime) / 1000);
    const setFlashcardId = location.state?.flashcardsSet?.set_flashcard?.guid;
    const documentId = location.state?.flashcardsSet?.set_flashcard?.document;

    const flashcardUpdates = cards.reduce((acc, card) => {
      if (studyMode === "typing") {
        // If card hasn't been attempted at all, set to 4 attempts (most difficult)
        if (card.attempts === 0 && card.answeredCorrectly?.length === 0) {
          acc[card.id] = 4;
        } else {
          acc[card.id] = card.attempts === 0 ? 1 :
            card.attempts <= 2 ? 2 :
              card.attempts <= 4 ? 3 : 4;
        }
      } else {
        // For flashcard mode, set appearances based on difficulty
        acc[card.id] = card.difficulty === 'dificil' ? 3 : 1;
      }
      return acc;
    }, {});

    // Update flashcards appearances
    if (Object.keys(flashcardUpdates).length > 0) {
      try {
        await updateFlashcardAppearances(token, documentId, flashcardUpdates, user);
      } catch (error) {
        console.error('Error updating flashcard appearances:', error);
      }
    }

    // Update set flashcard completion status and time
    if (setFlashcardId) {
      try {
        await updateSetFlashcardCompletion(token, setFlashcardId, timeSpent, user);
      } catch (error) {
        console.error('Error updating set flashcard:', error);
      }
    }
  };

  const handleFlagClick = () => {
    setIsFlagDialogOpen(true);
  };

  const handleFlagConfirm = async () => {
    try {
      await deleteFlashcard(token, currentCard.id, user);
      // Update local state to remove the card
      setCards(prevCards => {
        const newCards = prevCards.filter(card => card.id !== currentCard.id);
        return newCards;
      });

      // Update initialCardCount when a card is deleted
      setInitialCardCount(prev => prev - 1);

      // Move to next card if available
      if (currentCardIndex < cards.length - 1) {
        setCurrentCardIndex(prev => prev + 1);
      } else if (currentCardIndex > 0) {
        setCurrentCardIndex(prev => prev - 1);
      }

      setIsFlagDialogOpen(false);
    } catch (error) {
      console.error('Error deleting flashcard:', error);
      // Optionally add error handling UI feedback here
    }
  };

  const handleEditClick = () => {
    setEditDialogOpen(true);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
  };

  const handleEditSave = async (editedData) => {
    // Update the cards state with the edited flashcard
    setCards(prevCards => {
      const newCards = [...prevCards];
      const cardIndex = newCards.findIndex(card => card.id === editedData.updatedCard.id);
      
      if (cardIndex !== -1) {
        // Update the existing card with the edited version
        newCards[cardIndex] = editedData.updatedCard;
        
        // Add the duplicate card to the end of the array
        newCards.push(editedData.cardToAddAtEnd);
      }
      
      return newCards;
    });
    const document_guid = location.state?.flashcardsSet?.set_flashcard?.document;

    await updateFlashcard(token, document_guid, editedData.updatedCard.id, editedData.updatedCard, user)
    // Move to next card if available
    if (currentCardIndex < cards.length - 1) {
      setCurrentCardIndex(prev => prev + 1);
    } else {
      setEndTime(Date.now());
      setIsCompleted(true);
      updateBackendOnCompletion();
    }

    setEditDialogOpen(false);
  };

  // Update the areAllBlanksAnswered function
  const areAllBlanksAnswered = () => {
    if (!state.answeredBlanks || !currentCard.answers) return false;
    
    return currentCard.answers.every((answer, blankIndex) => {
      // If answer is a string, split it into words
      const answerWords = Array.isArray(answer) ? answer : answer.split(' ');
      return answerWords.every((_, wordIndex) => 
        state.answeredBlanks[`${blankIndex}-${wordIndex}`]
      );
    });
  };

  const handleThirdAppearanceEdit = () => {
    setShowThirdAppearanceDialog(false);
    handleEditClick();
  };

  const handleThirdAppearanceRemove = () => {
    setShowThirdAppearanceDialog(false);
    handleFlagClick();
  };

  const handleThirdAppearanceContinue = () => {
    setShowThirdAppearanceDialog(false);
    handleNext();
  };

  return (
    <FlashcardContainer>
      <Tour
        steps={tourSteps}
        isOpen={showProductTour}
        onRequestClose={() => { }}
        {...tourConfig}
      />
      <PopupTutorialFlashcard
        open={showTutorial}
        onClose={() => setShowTutorial(false)}
      />

      <Snackbar
        open={showAlert}
        autoHideDuration={4000}
        onClose={() => setShowAlert(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        TransitionProps={{
          direction: 'up'
        }}
      >
        <Alert 
          onClose={() => setShowAlert(false)} 
          severity={alertSeverity}
          variant="filled"
          sx={{ 
            width: '100%',
            fontFamily: "'Fira Sans', sans-serif",
            borderRadius: '12px',
            '& .MuiAlert-icon': {
              color: 'white'
            },
            backgroundColor: alertSeverity === 'warning' ? '#ff9800' : '#6BBDBD',
            color: 'white'
          }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>

      {isCompleted ? (
        <FlashcardSummary
          onRestart={handleRestart}
          stats={getFlashcardStats()}
          cards={isHandleFinish ?
            getUniqueCardsWithFinalStateOnFinalClick(cards) :
            getUniqueCardsWithFinalState(cards)}
          studyMode={studyMode}
          user={user}
          token={token}
          documentId={ location.state?.flashcardsSet?.set_flashcard?.document}
        />
      ) : (
        <>
          <AnimatePresence>
            {showSuccess && <SuccessAnimation />}
          </AnimatePresence>

          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 6
          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}>
              <Box sx={{
                width: '24px',
                height: '24px',
                backgroundColor: folderColor,
                borderRadius: '6px'
              }} />
              <Typography sx={{
                fontFamily: "'Fira Sans', sans-serif",
                fontWeight: 600,
                fontSize: '24px',
                color: '#2D2D2D'
              }}>
                {documentTitle}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                onClick={() => setShowTutorial(true)}
                sx={{
                  borderRadius: '20px',
                  padding: '6px 16px',
                  textTransform: 'none',
                  fontFamily: "'Fira Sans', sans-serif",
                  fontSize: '13px',
                  fontWeight: 500,
                  backgroundColor: 'transparent',
                  color: '#666666',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: '#333333',
                  },
                }}
              >
                Tutorial
              </Button>
              <FinishButton onClick={handleOpenConfirmDialog}>
                Finalizar
              </FinishButton>
            </Box>
          </Box>

          <Box sx={{ marginBottom: "20px" }}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 1
            }}>
              <Typography sx={{
                fontFamily: "'Fira Sans', sans-serif",
                fontSize: '18px',
                fontWeight: 600,
                color: '#6BBDBD'
              }}>
                {studyMode === 'flashcard' ?
                  cards.filter(card =>
                    card.selectedDifficulty === 'facil'
                  ).length
                  :
                  cards.filter(card =>
                    card.answeredCorrectly?.length === card.answers.length &&
                    card.attempts <= 4 &&
                    !card.usedReveal
                  ).length
                }
              </Typography>
              <Typography sx={{
                fontFamily: "'Fira Sans', sans-serif",
                fontSize: '18px',
                color: '#666666'
              }}>
                de
              </Typography>
              <Typography sx={{
                fontFamily: "'Fira Sans', sans-serif",
                fontSize: '18px',
                fontWeight: 600,
                color: '#6BBDBD'
              }}>
                {initialCardCount}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography sx={{
                  fontFamily: "'Fira Sans', sans-serif",
                  fontSize: '18px',
                  color: '#666666'
                }}>
                  tarjetas completadas
                </Typography>
                <Tooltip
                  title="Tarjeta resuelta en menos de 5 intentos"
                  placement="top"
                  arrow
                >
                  <InfoOutlinedIcon
                    sx={{
                      fontSize: 16,
                      color: '#666666',
                      cursor: 'help'
                    }}
                  />
                </Tooltip>
              </Box>
            </Box>
          </Box>

          <motion.div
            animate={{
              scale: showSuccess ? 1.02 : 1
            }}
            transition={{
              duration: 0.3
            }}
          >

            {currentCard.appearances > 1 && (
              <Box sx={{ mb: 1, display: 'flex', justifyContent: 'center' }}>
                <RepeatChip
                  icon={<ReplayIcon />}
                  label="Error anterior"
                />
              </Box>
            )}

            <Box sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: 2,
              gap: 1
            }}>
              {isSpeaking ? (
                <Tooltip title="Detener audio">
                  <IconButton
                    onClick={() => {
                      window.speechSynthesis.cancel();
                      setIsSpeaking(false);
                    }}
                    size="small"
                    sx={{
                      color: '#FF4444',
                      backgroundColor: 'rgba(255, 68, 68, 0.1)',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 68, 68, 0.2)'
                      }
                    }}
                  >
                    <StopIcon style={{ fontSize: 20 }} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Leer en voz alta">
                  <SpeakerButton 
                    onClick={handleSpeakerClick}
                    className={isSpeaking ? 'speaking' : ''}
                    aria-label="Text to speech"
                    size="small"
                    sx={{
                      color: '#757575',
                      backgroundColor: 'transparent',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)'
                      }
                    }}
                  >
                    <VolumeUpIcon style={{ fontSize: 20 }} />
                  </SpeakerButton>
                </Tooltip>
              )}
              <Tooltip title="Editar tarjeta">
                <IconButton
                  onClick={handleEditClick}
                  size="small"
                  sx={{
                    color: '#757575',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                  }}
                >
                  <EditOutlinedIcon style={{ fontSize: 20 }} />
                </IconButton>
              </Tooltip>
              <DeleteButton
                onClick={handleFlagClick}
                size="small"
              >
                Reportar
              </DeleteButton>
            </Box>

            <QuestionBox>

              {renderFlashcard()}
            </QuestionBox>

          </motion.div>

          <Box sx={{
            width: '100%',
            maxWidth: '650px',
            margin: '0 auto',
            marginTop: '40px'
          }}>
            {studyMode === 'typing' && (
              <Box sx={{ position: 'relative', mb: 3 }}>
                {state.wrongAnswers.length > 0 && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '-20px',
                      right: '12px',
                      display: 'flex',
                      gap: '8px',
                      flexDirection: 'row-reverse'
                    }}
                  >
                    {state.wrongAnswers.map((answer, index) => (
                      <Typography
                        key={index}
                        sx={{
                          color: '#FF4444',
                          fontSize: '14px',
                          fontFamily: "'Inter', sans-serif",
                          fontWeight: 500
                        }}
                      >
                        {answer}
                      </Typography>
                    ))}
                  </Box>
                )}
                <TextField
                  className="answer-input"
                  fullWidth
                  autoFocus
                  autoComplete="off"
                  value={state.currentInput}
                  onChange={(e) => setState(prev => ({
                    ...prev,
                    currentInput: e.target.value
                  }))}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !areAllBlanksAnswered()) {
                      checkAnswer();
                    }
                  }}
                  placeholder={areAllBlanksAnswered() ? "" : "Escribe tu respuesta..."}
                  disabled={areAllBlanksAnswered()}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      backgroundColor: 'white',
                      '& fieldset': {
                        borderColor: state.isAttempted && !state.isCorrect ? '#FF4444' : '#E2F1F1',
                      },
                      '&:hover fieldset': {
                        borderColor: state.isAttempted && !state.isCorrect ? '#FF4444' : '#6BBDBD',
                      },
                    }
                  }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'transparent', width: '100%', height: '100%' }}>
                  <Typography
                    sx={{
                      marginTop: '8px',
                      color: '#666666',
                      fontSize: '14px',
                      fontFamily: "'Inter', sans-serif"
                    }}
                  >
                    {areAllBlanksAnswered() ? "" : "Pulsa enter para validar tu respuesta"}
                  </Typography>
                </Box>
              </Box>
            )}

            {studyMode === 'flashcard' && !state.isCorrect && !currentCard.revealed && (
              <Box sx={{ textAlign: 'center', mb: 3 }}>
                <Typography sx={{
                  color: '#666666',
                  fontFamily: "'Inter', sans-serif",
                  fontSize: '14px'
                }}>
                  Di la respuesta en voz alta y comprueba si es correcta
                </Typography>
              </Box>
            )}



            {(!state.isCorrect && !currentCard.revealed) ? (
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 2,
                mt: 4
              }}>
                <HintButton
                  onClick={handleShowHint}
                  disabled={state.isCorrect || hintCount >= 5}
                >
                  {`Pista (${5 - hintCount})`}
                </HintButton>
                <ActionButton
                  className="show-answer-btn"
                  onClick={handleShowAnswer}
                  disabled={state.isCorrect}
                  sx={{
                    backgroundColor: 'white',
                    color: '#FF4444',
                    border: '1px solid #FF4444',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 68, 68, 0.1)',
                    }
                  }}
                >
                  Solución
                </ActionButton>
                <IconButton
                  onClick={handleSkip}
                  size="small"
                  sx={{
                    color: '#666666',
                    borderRadius: '50%',
                    padding: '4px'
                  }}
                >
                  <SkipNextIcon fontSize="small" />
                </IconButton>
              </Box>
            ) : (
              <>
                {(!showDifficultyOptions || studyMode !== 'flashcard') && (
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                    mt: 4
                  }}>
                    <Typography
                      sx={{
                        color: '#666',
                        fontFamily: "'Inter', sans-serif",
                        fontSize: '14px'
                      }}
                    >
                      Pulsa cualquier tecla para continuar
                    </Typography>
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: 2
                    }}>
                      <ExplanationButton
                        onClick={handleExplain}
                      >
                        Explícame
                      </ExplanationButton>
                      <ActionButton
                        onClick={handleNext}
                        className="primary"
                      >
                        Continuar
                      </ActionButton>
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Box>

          <Dialog
            open={openConfirmDialog}
            onClose={() => setOpenConfirmDialog(false)}
            PaperProps={{
              sx: {
                borderRadius: '12px',
                maxWidth: '400px',
                padding: '8px'
              }
            }}
          >
            <DialogTitle sx={{
              fontFamily: "'Fira Sans', sans-serif",
              fontSize: '20px',
              fontWeight: 500,
              color: '#2D2D2D',
              pb: 1
            }}>
              ¿Finalizar sesión?
            </DialogTitle>
            <DialogContent>
              <Typography sx={{
                color: '#666666',
                fontFamily: "'Fira Sans', sans-serif",
                fontSize: '14px'
              }}>
                ¿Estás seguro de que quieres finalizar la sesión de estudio? Tu progreso se guardará hasta este punto.
              </Typography>
            </DialogContent>
            <DialogActions sx={{ padding: '16px 24px' }}>
              <Button
                onClick={() => setOpenConfirmDialog(false)}
                sx={{
                  color: '#666666',
                  textTransform: 'none',
                  fontFamily: "'Fira Sans', sans-serif",
                  fontSize: '14px',
                  fontWeight: 500,
                  '&:hover': {
                    backgroundColor: 'rgba(102, 102, 102, 0.08)',
                  },
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={handleFinish}
                variant="contained"
                sx={{
                  backgroundColor: '#6BBDBD',
                  color: 'white',
                  textTransform: 'none',
                  boxShadow: 'none',
                  fontFamily: "'Fira Sans', sans-serif",
                  fontSize: '14px',
                  fontWeight: 500,
                  borderRadius: '20px',
                  padding: '6px 16px',
                  '&:hover': {
                    backgroundColor: '#5AACAC',
                  },
                }}
              >
                Finalizar
              </Button>
            </DialogActions>
          </Dialog>

          {showDifficultyOptions && studyMode === 'flashcard' && (
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 4 }}>
              {[
                { level: 'dificil', label: 'Difícil' },
                { level: 'facil', label: 'Fácil' }
              ].map(({ level, label }) => (
                <Button
                  key={level}
                  onClick={() => handleSelectDifficulty(level)}
                  sx={{
                    borderRadius: '20px',
                    padding: '12px 24px',
                    textTransform: 'none',
                    fontFamily: "'Fira Sans', sans-serif",
                    fontSize: '16px',
                    fontWeight: 600,
                    backgroundColor: difficultyColors[level].bg,
                    color: difficultyColors[level].text,
                    border: `2px solid transparent`,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      backgroundColor: difficultyColors[level].hover,
                      borderColor: difficultyColors[level].text,
                      transform: 'translateY(-2px)',
                      boxShadow: `0 4px 8px rgba(0, 0, 0, 0.1)`
                    },
                    '&:active': {
                      transform: 'translateY(0)',
                      boxShadow: 'none'
                    }
                  }}
                >
                  {label}
                </Button>
              ))}
            </Box>
          )}

          <ExplicacionFlashcard
            open={isExplanationOpen}
            onClose={() => setIsExplanationOpen(false)}
            question={currentCard.question}
            correctAnswer={currentCard.answers[currentCard.currentClozeIndex]}
            userAnswer={state.userInput}
            explanationText={explanationText}
            isLoading={isLoadingExplanation}
          />

          <FlagDialog
            open={isFlagDialogOpen}
            onClose={() => setIsFlagDialogOpen(false)}
            onFlag={handleFlagConfirm}
          />

          <ThirdAppearanceDialog
            open={showThirdAppearanceDialog}
            onClose={() => setShowThirdAppearanceDialog(false)}
            onEdit={handleThirdAppearanceEdit}
            onRemove={handleThirdAppearanceRemove}
            onContinue={handleThirdAppearanceContinue}
            documentId={documentId}
          />

          <EditFlashcardPopup
            open={editDialogOpen}
            onClose={handleEditClose}
            flashcard={currentCard}
            onSave={handleEditSave}
          />

          <Dialog
            open={showAnswerConfirm}
            onClose={() => setShowAnswerConfirm(false)}
            PaperProps={{
              sx: {
                borderRadius: '12px',
                maxWidth: '600px',
                width: '90%',
                padding: '8px'
              }
            }}
          >
            <DialogTitle sx={{
              fontFamily: "'Fira Sans', sans-serif",
              fontSize: '20px',
              fontWeight: 500,
              color: '#2D2D2D',
              pb: 1
            }}>
              ¿Estás seguro?
            </DialogTitle>
            <DialogContent>
              <Typography sx={{
                color: '#666666',
                fontFamily: "'Fira Sans', sans-serif",
                fontSize: '14px',
                mb: 2
              }}>
                ¿Estás seguro que quieres ver la respuesta? Intenta primero responder la tarjeta para mejorar la retención.
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Checkbox
                  onChange={(e) => setDontShowAnswerConfirm(e.target.checked)}
                  sx={{
                    color: '#6BBDBD',
                    '&.Mui-checked': {
                      color: '#6BBDBD',
                    },
                  }}
                />
                <Typography sx={{
                  color: '#666666',
                  fontFamily: "'Fira Sans', sans-serif",
                  fontSize: '14px'
                }}>
                  No volver a mostrar
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions sx={{ padding: '16px 24px' }}>
              <Button
                onClick={() => setShowAnswerConfirm(false)}
                sx={{
                  color: '#666666',
                  textTransform: 'none',
                  fontFamily: "'Fira Sans', sans-serif",
                  fontSize: '14px',
                  fontWeight: 500,
                  '&:hover': {
                    backgroundColor: 'rgba(102, 102, 102, 0.08)',
                  },
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={() => handleConfirmShowAnswer(dontShowAnswerConfirm)}
                variant="contained"
                sx={{
                  backgroundColor: '#FF4444',
                  color: 'white',
                  textTransform: 'none',
                  boxShadow: 'none',
                  fontFamily: "'Fira Sans', sans-serif",
                  fontSize: '14px',
                  fontWeight: 500,
                  borderRadius: '20px',
                  padding: '6px 16px',
                  '&:hover': {
                    backgroundColor: '#E03131',
                  },
                }}
              >
                Ver respuesta
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </FlashcardContainer>
  );
};

export default FlashcardPage;
