import React, { useState, useEffect, useContext } from 'react';
import { Button, TextField, IconButton, Typography, CircularProgress, Box, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { updateSchema } from '../Api/SchemasApi';
import SearchIcon from '@mui/icons-material/Search';
import { WebSocketContext } from '../WebSocketProvider';
import './Styles/style.css';
import NeedCredits from '../NoCreditsModal/NeedCredits';
import { useCredits } from '../Context/CreditsContext';
import { createSchema } from '../Api/SchemasApi';
import PopupGenerarEsquema from '../MentalMap/PopupGenerarEsquema';
import DeleteSchemaModal from './DeleteSchemaModal';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const SchemasList = ({
    documentId,
    token,
    user,
    isCreatingSchemas,
    setIsCreatingSchemas,
    schemas,
    setSchemas,
    isLoading,
    error,
    summary,
    setShowUpgrade,
    docInfo,
}) => {
    const navigate = useNavigate();
    const [editingId, setEditingId] = useState(null);
    const [editingName, setEditingName] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const { messageCallback } = useContext(WebSocketContext);
    const [executingSchemas, setExecutingSchemas] = useState(false);
    const [showNeedCredits, setShowNeedCredits] = useState(false);
    const { updateCredits, credits } = useCredits();
    const [openGeneratePopup, setOpenGeneratePopup] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [schemaToDelete, setSchemaToDelete] = useState(null);
    
    // Effect to check generating state on mount and trigger parent refetch
    useEffect(() => {
        // Check if we were generating schemas before navigation
        const wasGenerating = localStorage.getItem(`generating_schemas_${documentId}`);
        if (wasGenerating === 'true') {
            setIsCreatingSchemas(true);
            setExecutingSchemas(true);
        }
    }, [documentId]);

    // Update localStorage when generating state changes
    useEffect(() => {
        if (isCreatingSchemas) {
            localStorage.setItem(`generating_schemas_${documentId}`, 'true');
        } else {
            localStorage.removeItem(`generating_schemas_${documentId}`);
        }
    }, [isCreatingSchemas, documentId]);

    useEffect(() => {
        if (messageCallback) {
            switch (messageCallback.type) {
                case "schema":
                    if (!messageCallback.executing) {
                        setIsCreatingSchemas(false);
                        setExecutingSchemas(false);
                        localStorage.removeItem(`generating_schemas_${documentId}`);
                    }

                    setSchemas(prevSchemas => {
                        const exists = prevSchemas.some(schema => schema.guid === messageCallback.schema.guid);
                        if (!exists) {
                            return [...prevSchemas, {
                                guid: messageCallback.schema.guid,
                                name: messageCallback.schema.name,
                                description: messageCallback.schema.description,
                                is_locked: false
                            }];
                        }
                        return prevSchemas;
                    });
                    break;
            }
        }
    }, [messageCallback, documentId]);

    const handleCloseNeedCredits = () => {
        setShowNeedCredits(false);
    };

    const handleOpenGeneratePopup = () => {
        setOpenGeneratePopup(true);
    };

    const handleCloseGeneratePopup = () => {
        setOpenGeneratePopup(false);
    };

    const handleSchemaCreated = (newSchema) => {
        navigate(`/schema/${newSchema}`, {
            state: { docInfo , schema_list: schemas}
        });
    };

    const handleUnlock = async (schemaId) => {
        try {
            if (credits === -100 || credits < 20) {
                setShowNeedCredits(true);
                return;
            }
            const schemaData = {
                is_locked: false
            };

            await updateSchema(token, user, schemaId, schemaData);

            // Update the local state to reflect the change
            setSchemas(schemas.map(schema =>
                schema.guid === schemaId ? { ...schema, is_locked: false } : schema
            ));
            updateCredits();
            navigate(`/schema/${schemaId}`, {
                state: { docInfo , schema_list: schemas}
            });
        } catch (error) {
            console.error('Error unlocking schema:', error);
        }
    };  
    const handleView = (schemaId) => {
        navigate(`/schema/${schemaId}`, {
            state: { docInfo , schema_list: schemas}
        });
    };

    const handleEditClick = (schema) => {
        setEditingId(schema.guid);
        setEditingName(schema.name);
    };

    const handleEditCancel = () => {
        setEditingId(null);
        setEditingName('');
    };

    const handleEditSave = async (schemaId) => {
        try {
            const schemaData = {
                name: editingName
            };

            await updateSchema(token, user, schemaId, schemaData);

            // Update the local state to reflect the change
            setSchemas(schemas.map(schema =>
                schema.guid === schemaId ? { ...schema, name: editingName } : schema
            ));
            setEditingId(null);
        } catch (error) {
            console.error('Error updating schema name:', error);
        }
    };

    const handleDeleteClick = (schema) => {
        setSchemaToDelete(schema);
        setDeleteModalOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (!schemaToDelete) return;

        try {
            const response = await fetch(`${BACKEND_URL}/api_v1/documents/${documentId}/schemas/${schemaToDelete.guid}/?user_id=${user['id']}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                  
                }
            });

            if (response.ok) {
                setSchemas(schemas.filter(schema => schema.guid !== schemaToDelete.guid));
                setDeleteModalOpen(false);
                setSchemaToDelete(null);
            } else {
                console.error('Error deleting schema');
            }
        } catch (error) {
            console.error('Error deleting schema:', error);
        }
    };

    const handleDeleteCancel = () => {
        setDeleteModalOpen(false);
        setSchemaToDelete(null);
    };

    const filteredSchemas = schemas.filter(schema =>
        schema?.name?.toLowerCase().includes(searchTerm?.toLowerCase()) || false
    );
    if (isCreatingSchemas) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                padding: '32px',
                width: '100%'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '16px',
                    marginBottom: '32px'
                }}>
                    <CircularProgress style={{ color: '#6BBDBD' }} />
                    <Typography>Generando esquemas sobre tu resumen</Typography>
                </div>

                {/* Show schemas as they are generated */}
                <div className="schemas-list custom-scrollbar" style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    maxHeight: 'calc(-100px + 75vh)',
                    overflowY: 'auto',
                    marginBottom: '26px'
                }}>
                    {schemas.map((schema, index) => (
                        <React.Fragment key={schema.guid}>
                            <div className="schema-item" style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '12px 0'
                            }}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px', flex: 1 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', flex: 1 }}>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                            <Typography style={{ fontWeight: 'bold', fontFamily: "'Fira Sans', sans-serif" }}>{schema.name}</Typography>
                                        </div>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            sx={{
                                                fontSize: '14px',
                                                color: '#666',
                                                width: '100%',
                                                wordWrap: 'break-word',
                                                whiteSpace: 'pre-wrap'
                                            }}
                                        >
                                            {schema.description}
                                        </Typography>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                    <Tooltip 
                                        title="Deben generarse todos los esquemas antes"
                                        arrow
                                    >
                                        <span>
                                            <Button
                                                variant="contained"
                                                disabled={true}
                                                style={{
                                                    textTransform: 'none',
                                                    fontSize: '14px',
                                                    fontWeight: '600',
                                                    fontFamily: "'Inter', sans-serif",
                                                    backgroundColor: '#E0E0E0',
                                                    padding: '4px 16px',
                                                    borderRadius: '24px',
                                                    boxShadow: 'none',
                                                    border: '1px solid #E0E0E0'
                                                }}
                                            >
                                                Ver
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>
                            {index < schemas.length - 1 && (
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#E0E0E0',
                                    width: '100%'
                                }} />
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (schemas.length === 0 && !isLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '24px',
                    padding: '48px',
                    height: '100%',
                    minHeight: '400px',
                    textAlign: 'center'
                }}
            >
                <Box
                    sx={{
                        backgroundColor: '#F5F9F9',
                        borderRadius: '50%',
                        padding: '24px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <AccountTreeIcon
                        sx={{
                            fontSize: '48px',
                            color: '#6BBDBD'
                        }}
                    />
                </Box>
                <Box sx={{ maxWidth: '400px' }}>
                    <Typography
                        variant="h6"
                        sx={{
                            fontFamily: "'Inter', sans-serif",
                            fontWeight: '600',
                            color: '#2C2C2C',
                            marginBottom: '12px'
                        }}
                    >
                        ¡Comienza a crear esquemas!
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: "'Inter', sans-serif",
                            color: '#666666',
                            fontSize: '14px',
                            marginBottom: '24px'
                        }}
                    >
                        Para generar los esquemas primero debes generar el resumen.
                    </Typography>
                    {!docInfo?.schema_summary && (
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="contained"
                                onClick={async ()=>{
                                    if (!docInfo?.summary_active) {
                                        alert("Debes generar un resumen para poder generar un esquema resumen")
                                        return;
                                    }
                                    let payload={
                                        "schema_summary": true
                                    }
                                    setIsGenerating(true);
                                    setIsCreatingSchemas(true);
                                    setExecutingSchemas(true);
                                    try {
                                        await createSchema(token, user, documentId, payload);
                                        // Note: We don't navigate or update state here
                                        // We'll wait for the WebSocket notifications
                                    } catch (error) {
                                        console.error("Error creating schema:", error);
                                        setIsGenerating(false);
                                        setIsCreatingSchemas(false);
                                        setExecutingSchemas(false);
                                        localStorage.removeItem(`generating_schemas_${documentId}`);
                                    }
                                }}
                                disabled={isGenerating || !docInfo?.summary_active}
                                sx={{
                                    backgroundColor: '#6BBDBD',
                                    color: 'white',
                                    opacity: docInfo?.summary_active ? 1 : 0.5,
                                    pointerEvents: docInfo?.summary_active ? 'auto' : 'none',
                                    height: '40px',
                                    display: docInfo?.summary_active ? 'block' : 'none'
                                }}
                            >
                                {isGenerating ? 'Generando esquemas...' : 'Generar esquemas'}
                            </Button>
                        </Box>
                    )}
                </Box>
                <PopupGenerarEsquema
                    open={openGeneratePopup}
                    onClose={handleCloseGeneratePopup}
                    onSchemaCreated={handleSchemaCreated}
                    documentId={documentId}
                    docInfo={docInfo}
                />
            </Box>
        );
    }

    if (isLoading) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                padding: '16px',
                width: '100%',
            }}>
                {[1, 2, 3].map((item) => (
                    <div key={item} style={{
                        padding: '20px',
                        backgroundColor: '#f5f5f5',
                        borderRadius: '8px',
                        animation: 'pulse 1.5s infinite',
                    }}>
                        <div style={{
                            height: '24px',
                            width: '200px',
                            backgroundColor: '#e0e0e0',
                            marginBottom: '8px',
                            borderRadius: '4px',
                        }} />
                        <div style={{
                            height: '16px',
                            width: '300px',
                            backgroundColor: '#e0e0e0',
                            borderRadius: '4px',
                        }} />
                    </div>
                ))}
            </div>
        );
    }

    return (
        <>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    padding: '16px',
                    width: '100%',
                }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '16px',
                            width: '100%'
                        }}
                    >
                        <TextField
                            sx={{
                                width: '500px',
                                backgroundColor: 'white',
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: 'white',
                                    '& fieldset': {
                                        borderColor: '#E0E0E0',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#6BBDBD',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#6BBDBD',
                                    },
                                },
                            }}
                            variant="outlined"
                            placeholder="Buscar esquemas..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            size="small"
                            InputProps={{
                                startAdornment: <SearchIcon sx={{ color: 'rgba(0, 0, 0, 0.38)' }} />,
                            }}
                        />

                            <Button
                                variant="contained"
                                    onClick={()=>{
                                    if (!docInfo?.summary_active) {
                                        alert("Debes generar un resumen para poder generar un esquema resumen")
                                        return;
                                    }
                                    let payload={
                                        "schema_summary": true
                                    }
                                        createSchema(token, user, documentId, payload)}}
                                sx={{
                                    backgroundColor: '#6BBDBD',
                                    color: 'white',
                                    opacity: docInfo?.summary_active ? 1 : 0.5,
                                    pointerEvents: docInfo?.summary_active ? 'auto' : 'none',
                                    height: '40px',
                                    display: docInfo?.summary_active ? 'none' : 'block'
                                }}
                            >
                                Generar esquemas
                            </Button>

                     
                        {/* <Tooltip title="Generar nuevo esquema" arrow>
                            <IconButton
                                onClick={handleOpenGeneratePopup}
                                sx={{
                                    backgroundColor: '#6BBDBD',
                                    color: 'white',
                                    width: '40px',
                                    height: '40px',
                                    '&:hover': {
                                        backgroundColor: '#5CACAC',
                                    },
                                    '& .MuiSvgIcon-root': {
                                        fontSize: '24px',
                                    }
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </Tooltip> */}
                    </Box>
                    {executingSchemas && (
                        <Typography
                            className="loading-text"
                            style={{
                                textAlign: 'center',
                                color: '#666',
                                marginBottom: '8px'
                            }}
                        >
                            Generando esquemas
                        </Typography>
                    )}
                    <div className="schemas-list custom-scrollbar" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        maxHeight: 'calc(-100px + 75vh)',
                        overflowY: 'auto',
                        marginBottom: '26px'
                    }}>
                        {filteredSchemas.map((schema, index) => (
                            <React.Fragment key={schema.guid}>
                                <div className="schema-item" style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '12px 0'
                                }}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', flex: 1 }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', flex: 1 }}>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                <Typography style={{ fontWeight: 'bold', fontFamily: "'Fira Sans', sans-serif" }}>{schema.name}</Typography>
                                            </div>
                                            <Typography
                                                variant="body2"
                                                color="text.secondary"
                                                sx={{
                                                    fontSize: '14px',
                                                    color: '#666',
                                                    width: '100%',  // Take full width
                                                    wordWrap: 'break-word',  // Allow word wrapping
                                                    whiteSpace: 'pre-wrap'  // Preserve line breaks and wrapping
                                                }}
                                            >
                                                {schema.description}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                        <Tooltip 
                                            title={executingSchemas ? "Deben generarse todos los esquemas antes" : ""}
                                            arrow
                                        >
                                            <span>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => schema.is_locked ? handleUnlock(schema.guid) : handleView(schema.guid)}
                                                    style={{
                                                        textTransform: 'none',
                                                        fontSize: '14px',
                                                        fontWeight: '600',
                                                        fontFamily: "'Inter', sans-serif",
                                                        color: !schema.is_locked ? 'white' : '#6BBDBD',
                                                        backgroundColor: executingSchemas ? '#E0E0E0' : (!schema.is_locked ? '#6BBDBD' : 'white'),
                                                        padding: '4px 16px',
                                                        borderRadius: '24px',
                                                        boxShadow: 'none',
                                                        border: '1px solid ' + (executingSchemas ? '#E0E0E0' : '#6BBDBD')
                                                    }}
                                                    disabled={executingSchemas}
                                                >
                                                    {schema.is_locked ? 'Desbloquear' : 'Ver'}
                                                </Button>
                                            </span>
                                        </Tooltip>
                                        {!executingSchemas && (
                                            <Tooltip title="Eliminar esquema" arrow>
                                                <IconButton
                                                    onClick={() => handleDeleteClick(schema)}
                                                    size="small"
                                                    sx={{
                                                        color: '#666666',
                                                        '&:hover': {
                                                            color: '#dc3545',
                                                            backgroundColor: 'rgba(220, 53, 69, 0.1)'
                                                        }
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </div>
                                </div>
                                {index < filteredSchemas.length - 1 && (
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: '#E0E0E0',
                                        width: '100%'
                                    }} />
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
            <PopupGenerarEsquema
                open={openGeneratePopup}
                onClose={handleCloseGeneratePopup}
                onSchemaCreated={handleSchemaCreated}
                documentId={documentId}
                docInfo={docInfo}
            />
            <DeleteSchemaModal
                open={deleteModalOpen}
                onClose={handleDeleteCancel}
                onConfirm={handleDeleteConfirm}
                schemaName={schemaToDelete?.name || ''}
            />
        </>
    );
};

export default SchemasList;
