import React, { createContext, useRef, useEffect, useState } from 'react';
import { useSession, useSupabaseClient } from '@supabase/auth-helpers-react';
import { useAuth } from './Context/AuthContext';

const WebSocketContext = createContext();

const WebSocketProvider = ({ children, url, urlchat }) => {
    const ws = useRef(null);
    const wsChat = useRef(null);
    const { token, user, isAuthenticated } = useAuth();
    const [isConnecting, setIsConnecting] = useState(false);
    const [messageCallback, setMessageCallback] = useState(null);
    const [chatMessageCallback, setChatMessageCallback] = useState(null);
    const reconnectTimeoutRef = useRef(null);
    const [isConnected, setIsConnected] = useState(false);

    const connectWebSocket = async () => {
        if (isConnecting || !user?.sub) return;
        setIsConnecting(true);
        console.log("Connecting to WebSocket with user:", user);
        try {
            const websocketUrl = `${url}?token=${token}&user_id=${user.sub}`;
            const websocketUrlChat = `${urlchat}?token=${token}&user_id=${user.sub}`;
            // Close existing connections first
            if (ws.current && ws.current.readyState !== WebSocket.CLOSED) {
                ws.current.close();
            }
            if (wsChat.current && wsChat.current.readyState !== WebSocket.CLOSED) {
                wsChat.current.close();
            }

            // Create new main WebSocket connection with enhanced handling
            ws.current = new WebSocket(websocketUrl);
            
            // Add ping/pong mechanism for main WebSocket
            const pingInterval = setInterval(() => {
                if (ws.current?.readyState === WebSocket.OPEN) {
                    ws.current.send(JSON.stringify({ type: 'ping' }));
                }
            }, 30000); // Send ping every 30 seconds

            ws.current.onopen = () => {
                setIsConnecting(false);
                setIsConnected(true);
                console.log('Main WebSocket connected');
            };

            ws.current.onmessage = event => {
                const message = JSON.parse(event.data);
                setMessageCallback(message);
            };

            ws.current.onerror = error => {
                console.error("WebSocket Error:", error);
                setIsConnected(false);
            };

            ws.current.onclose = e => {
                clearInterval(pingInterval); // Clear ping interval on close
                setIsConnected(false);
                if (e.code !== 1000 && !reconnectTimeoutRef.current) {
                    reconnectTimeoutRef.current = setTimeout(() => {
                        reconnectTimeoutRef.current = null;
                        connectWebSocket();
                    }, 5000);
                }
            };

            // Keep original chat WebSocket configuration
            wsChat.current = new WebSocket(websocketUrlChat);
            wsChat.current.onmessage = event => {
                const message = JSON.parse(event.data);
                if (message.event === "Error") {
                    console.error("Chat error: No credits remaining");
                }
                setChatMessageCallback(message);
            };

            wsChat.current.onerror = error => {
                console.error("Chat WebSocket Error:", error);
            };

            wsChat.current.onclose = e => {
                if (e.code !== 1000 && !reconnectTimeoutRef.current) {
                    reconnectTimeoutRef.current = setTimeout(() => {
                        reconnectTimeoutRef.current = null;
                        connectWebSocket();
                    }, 5000);
                }
            };
        } catch (error) {
            console.error('Connection error:', error);
            setIsConnected(false);
        } finally {
            setIsConnecting(false);
        }
    };

    // Add connection status monitoring for main WebSocket
    useEffect(() => {
        const checkConnection = setInterval(() => {
            if (ws.current?.readyState !== WebSocket.OPEN) {
                setIsConnected(false);
                connectWebSocket();
            }
        }, 5000);

        return () => clearInterval(checkConnection);
    }, []);

    useEffect(() => {
        if (user?.sub && token) {
            connectWebSocket();
        }

        return () => {
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
            }
            if (ws.current) {
                ws.current.close(1000);
            }
            if (wsChat.current) {
                wsChat.current.close(1000);
            }
        };
    }, [user?.sub, token]);

    return (
        <WebSocketContext.Provider value={{ 
            ws, 
            wsChat, 
            messageCallback, 
            setMessageCallback, 
            chatMessageCallback,
            isConnected 
        }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export { WebSocketProvider, WebSocketContext };