import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    IconButton,
    FormControl,
    TableContainer,
    Paper,
    TableHead,

    Typography,
    FormHelperText,
} from '@mui/material';
import { ClipLoader } from "react-spinners";

import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import { fetchUserInfo, fetchUserSettings, fetchUserFolder } from '../Api/FolderApi'; // Import the fetchUserInfo function
import { useAuth } from "../Auth/Authhandle";
import { useTranslation } from 'react-i18next';
import useModalFoldersStyles from "./Styles/useModalFoldersStyles";
import "./Styles/modal.css";

import * as email from "./handleEmail";


function ModalFolders({
    open,
    title,
    description,
    sendButton,
    placeholdMessage,
    type,
    onClose,
    folder_id

}) {
    // OnClose -> state if dialog is close
    // open -> state if dialog is open
    // title -> title of the modal
    // description -> descrption of the modal
    // sendButton -> name of the button
    // placeholdMessage -> input placeholder message
    // type -> type invite | upload | else

    const classes = useModalFoldersStyles();
    const { t } = useTranslation();

    // Invitar Adding email
    const [items, setItems] = useState([]);
    const [value, setValue] = useState("");
    const [error, setError] = useState(null);
    // Upload File states
    const [openUploadedSpinner, setOpenUploadSpinner] = useState(false);
    const [openSpinner, setOpenSpinner] = useState(false);
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    //shared folder
    const [sharedUsers, setSharedUsers] = useState([]);
    const [manager, setManager] = useState([]);

    const { token, user, isAuthenticated } = useAuth(); // Use the hook at the top level

    //Error to delete
    const [modalError, setModalError] = useState(false); // Add this state for error message

    useEffect(() => {

        async function fetchData() {
            try {
                if (token && user) {
                    const userData = await fetchUserFolder(token, "GET", folder_id, null, user); // Call the fetchUserInfo function
                    setSharedUsers(userData.shared_folder)
                    setManager(userData.manager)
                }

            } catch (error) {
                console.error(error)
            }
        }

        fetchData(); // Call the fetchData function

    }, [token, user])


    useEffect(() => {
        const handleEsc = (e) => {
            if (e.key === "Escape") onClose();
        };

        window.addEventListener("keydown", handleEsc);

        return () => {
            window.removeEventListener("keydown", handleEsc);
        };
    }, [onClose]);

    // Upload File Effect
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (openSpinner || openUploadedSpinner) {
                event.preventDefault();
                event.returnValue =
                    t("file_still_uploading"); // Custom message
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [openSpinner, openUploadedSpinner]);
    // Handle confirmation when the user tries to leave the page

    window.onbeforeunload = (event) => {
        if (openSpinner || openUploadedSpinner) {
            return t("file_still_uploading"); // Custom message
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle className={classes.dialogTitle}>
                {title}
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={(event) => {
                        event.stopPropagation();
                        onClose();
                    }}
                    aria-label="close"
                    style={{ position: "absolute", top: 8, right: 13 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <div onClick={(event) => event.stopPropagation()}>

                <DialogContent style={{}}>
                    <Typography
                        style={{
                            whiteSpace: "pre-line",
                            fontSize: "14px",
                            fontWeight: 400,
                        }}
                    >
                        {description}
                    </Typography>


                    <div style={{ marginTop: "20px" }}>
                        {items.map((item) => (
                            <div className="tag-item" key={item}>
                                {item}
                                <button
                                    type="button"
                                    className="button"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        email.handleDelete(item, items, setItems)
                                    }}
                                >
                                    &times;
                                </button>
                            </div>
                        ))}
                    </div>
                    {type != "eliminar" ? (
                        <TableContainer component={Paper} className={classes.fileListContainerStyle}>

                            <Table style={{ width: "100%" }}>
                                <TableHead className={classes.stickyHeader}>

                                    <TableRow>
                                        <TableCell className={classes.header}>{t("name")}</TableCell>
                                        <TableCell className={classes.header}>{t("surname")}</TableCell>
                                        <TableCell className={classes.header}>{t("email")}</TableCell>
                                    </TableRow>

                                </TableHead>



                                {sharedUsers.map((user, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.tableCell}>{user.name}</TableCell>
                                        <TableCell className={classes.tableCell}>{user.surname}</TableCell>
                                        <TableCell className={classes.tableCell}>{user.email}</TableCell>
                                        {/* <TableCell style={{ textAlign: "right" }}>
                                <Button
                                    variant="outlined"
                                    style={{ color: "red", border: "1px solid red" }}
                                // onClick={() => handleDeleteUser(user.guid)} // Implement this function to handle user removal
                                >
                                    Remove
                                </Button>
                            </TableCell> */}
                                    </TableRow>
                                ))}
                            </Table>
                        </TableContainer>) : (<></>)}
                    <div
                        style={{
                            display: "flex",
                            marginTop: "20px",
                            borderRadius: "8px",
                            border: "1px solid #D5D4DC",
                        }}
                    >
                        {type == "eliminar" ? (

                            <TextField
                                fullWidth
                                variant="standard"
                                placeholder={placeholdMessage}
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                style={{ padding: "10px" }}
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                            />
                        ) : (
                            <TextField
                                fullWidth
                                variant="standard"
                                placeholder={placeholdMessage}
                                InputProps={{
                                    // startAdornment: <AccountCircle />, // <== adjusted this
                                    disableUnderline: true, // <== added this
                                }}
                                style={{ padding: "10px" }}
                                value={value}
                                onKeyDown={(evt) => {
                                    email.handleKeyDown(
                                        evt,
                                        value,
                                        setItems,
                                        setValue,
                                        items,
                                        setError
                                    );
                                }}
                                onChange={(value) => {
                                    email.handleChange(value, setValue, setError);
                                }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                            //   onPaste={email.handlePaste}
                            />
                        )}</div>

                </DialogContent>

                <DialogActions>
                    {type == "eliminar" ? (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={async (event) => {
                                event.stopPropagation();

                                setIsLoading(true); // Start loading

                                if (token && user) {

                                    await email.handleButtonClick(token, folder_id, items, type, onClose, user)
                                    setIsLoading(false); // End loading

                                }
                            }

                            }

                            disabled={value.toLowerCase() !== 'eliminar' && value.toLowerCase() !== 'delete' || isLoading == true} // Button is disabled unless 'delete' is typed
                            style={{ textTransform: "none", fontWeight: "bold" }}

                        >
                            {isLoading ? <ClipLoader size={12} color={"#026277"} /> : sendButton}
                        </Button>
                    ) : (<Button
                        variant="contained"
                        color="primary"
                        onClick={async (event) => {
                            event.stopPropagation();

                            setIsLoading(true); // Start loading

                            if (token && user) {

                                await email.handleButtonClick(token, folder_id, items, type, onClose, user)
                                setIsLoading(false); // End loading

                            }
                        }}
                        style={{ textTransform: "none", fontWeight: "bold" }}
                    >
                        {isLoading ? <ClipLoader size={12} color={"white"} /> : sendButton}
                    </Button>

                    )}


                </DialogActions>
            </div>

        </Dialog >
    );
}

export default ModalFolders;
