import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Select, MenuItem, FormControl, InputLabel, Typography, TextField, Autocomplete } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { fetchUserFolders } from "../../Api/FolderApi";
import { useAuth } from "../../Auth/Authhandle";
import { useTranslation } from 'react-i18next';

// const BACKEND_URL = "localhost";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 400, // Adjust the width as needed
    },
    selectEmpty: (props) => ({
        marginTop: theme.spacing(2),
        border: '1px solid gray',
        borderRadius: '6px',
        '&:before': {
            borderBottom: 'none',
        },
        '&:after': {
            borderBottom: 'none',
        },
        '&:hover:not(.Mui-disabled):before': {
            borderBottom: 'none', // Remove underline on hover
        },
        '& .MuiSelect-selectMenu': {
            color: props.value === "" ? '#747D8C' : 'inherit', // Conditional color
            marginLeft: 10
        },
    }),
    placeholder: {
        color: '#747D8C',
        marginLeft: '3px !important'
    },
    formControl: {
        minWidth: 120,
        width: '100%',
        marginBottom: "0px",
        marginTop: "10px",
    },
    title: {
        fontSize: '12px',
        fontWeight: '600',
        lineHeight: '26px'
    },
    menuItem: (props) => ({
        fontSize: "14px",
        borderRadius: '8px',
        fontFamily: "Inter"
    }),
    select: (props) => ({
        height: '35px',
        '& .MuiSelect-selectMenu': {
            fontSize: "14px",
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '8px',
        },
        '& .MuiInputLabel-root': {  // Added this style
            fontSize: "14px"
        },
        '& .MuiSelect-select:empty:not(.Mui-focused)::before': {
            content: '"Selecciona una carpeta"',
            fontSize: "14px",
            color: '#747D8C'
        }
    }),
    inputLabel: (props) => ({
        fontSize: "14px",  // Changed from props.fontSizeItems to "14px"
        transform: 'translate(10px, 10px) scale(1)',
    }),
    shrinkLabel: {
        transform: 'translate(10px, -6px) scale(0.75)', // Position of the label when shrunk
        // width: "50px"
    },
    textFieldFolder: {
        backgroundColor: '#f5f5f5', // Light background color
        border: '1px solid #ddd', // Border to make it more visible
        borderRadius: '4px', // Rounded corners
        padding: '5px', // Padding inside the input field
        transition: 'all 0.3s ease', // Smooth transition for hover effect
        '&:hover': {
            backgroundColor: '#e0e0e0', // Darker background on hover
        },
        '&:focus-within': {
            backgroundColor: '#fff', // White background when focused
            borderColor: '#026277', // Change border color when focused
        },
        fontSize: "12px",
        width: "290px"
    },

}));

export default function ShowFolders({ value, setValue, handleNewFolder, sizeTextFolder = 12, fontSizeItems = '10px', onFoldersFetched, fontWeightNormal = false }) {
    const [folders, setFolders] = useState([]);
    const { token, user, isAuthenticated } = useAuth(); // Use the hook at the top level
    const { t } = useTranslation();
    const [newFolderName, setNewFolderName] = useState("")
    const [openEditor, setOpenEditor] = useState(false)
    const [showSelect, setShowSelect] = useState(true); // Add this new state

    const handleChange = (event) => {
        setValue(event.target.value);
        setNewFolderName("")
    };

    const handleNewFolderNameChange = (event) => {
        setNewFolderName(event.target.value);

    };

    const classes = useStyles({ value, fontSizeItems });

    async function fetchDataAndUpdate() {
        if (token && user) {
            try {
                const fetchFolders = await fetchUserFolders(token, "GET", null, user);
                setFolders(fetchFolders);
                if (onFoldersFetched) {
                    onFoldersFetched(fetchFolders); // Pass folders data to parent
                }
            } catch (error) {
                console.error("Error fetching folders: ", error);
            }
        }
    }

    useEffect(() => {
        fetchDataAndUpdate();
    }, [token, user]);

    useEffect(() => {
        if (newFolderName) {
            handleNewFolder(newFolderName);
            setValue("");
        }
        // Add resetStates to the component's API through handleNewFolder
        handleNewFolder.reset = resetStates;
    }, [newFolderName, handleNewFolder]);

    // Add this new function to reset all states
    const resetStates = () => {
        setNewFolderName("");
        setValue("");
        setShowSelect(true);
        setOpenEditor(false);
    };

    const handleKeyDown = (event) => {
        if (event.key === '/') {
            event.preventDefault();
        }
    };
    return (
        <>
            {showSelect ? (
                // Show Select UI
                <>
                    <Typography style={{
                        color: "black",
                        fontSize: "14px",
                        fontFamily: "Inter",
                        fontWeight: fontWeightNormal ? "normal" : 600,
                        lineHeight: "normal",
                    }}>Guardar en</Typography>
                    <FormControl className={classes.formControl} variant="outlined">
                        <InputLabel
                            className={classes.inputLabel}
                            classes={{ shrink: classes.shrinkLabel }}
                            id="first-dropdown-label"
                        >
                            Selecciona una carpeta
                        </InputLabel>
                        <Select
                            labelId="first-dropdown-label"
                            id="first-dropdown"
                            value={value}
                            onChange={handleChange}
                            className={classes.select}
                            label="Selecciona una carpeta"
                        >

                            {folders.map((item, i) => (
                                <MenuItem className={classes.menuItem} value={item} key={i}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div style={{ cursor: 'pointer', display: 'flex', flexDirection: "column", marginTop: "5px", alignItems: 'flex-end' }} >
                        <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', marginTop: "10px" }}>
                            <Typography 
                                style={{ fontSize: "14px", color: "#6BBDBD", fontFamily: "Inter", fontWeight: 600 }} 
                                onClick={() => {
                                    setShowSelect(false);
                                    setOpenEditor(true);
                                    setValue(null); // Add this line to clear the select value
                                }}
                            >
                                {"Crear carpeta"}
                            </Typography>
                        </div>
                    </div>
                </>
            ) : (
                // Show TextField UI
                <>
                <Typography style={{
                    color: "black",
                    fontSize: "14px",
                    fontFamily: "Inter",
                    fontWeight: fontWeightNormal ? "normal" : 600,
                    lineHeight: "normal",
                    marginBottom: "10px"
                }}>Nombre de la carpeta</Typography>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <TextField
                        fullWidth
                        variant="standard"
                        placeholder={"Nueva carpeta"}
                        style={{ padding: "3px" }}
                        value={newFolderName}
                        onChange={handleNewFolderNameChange}
                        onKeyDown={handleKeyDown}
                        autoFocus
                        InputProps={{
                            style: {
                                fontSize: "14px",
                                fontFamily: "Inter"
                            }
                        }}
                    />
                    <Typography 
                        style={{ 
                            fontSize: "14px", 
                            color: "#6BBDBD", 
                            fontFamily: "Inter", 
                            fontWeight: 600,
                            cursor: 'pointer',
                            textAlign: 'right',
                            width: '100%' // Added to ensure full width for alignment
                        }}
                        onClick={() => {
                            setShowSelect(true);
                            setOpenEditor(false);
                        }}
                    >
                        Seleccionar carpeta
                    </Typography>
                    </div>
                </>
            )}
        </>
    );
}
