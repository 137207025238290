import i18n from './i18n'; // Assuming i18n.js is in your src directory

import { Route, BrowserRouter as Router, Routes, Navigate } from "react-router-dom";

import Layout from "./Main/Layout";

import { default as React, useEffect, useRef } from "react";
import Home from "./Home/Home";

import MainStructure from "./Main/MainStructure";
import MySchemas from "./MentalMap/MySchemas";
import Excalidraw2 from "./MentalMap/Excalidraw";
import MindMapFlow from "./MentalMap/components/MindMapFlow";

import NewOnboarding from "./NewOnboarding/NewOnboarding";
import SubirExamen from './Documents/SubirExamen';
import { SubscriptionProvider } from "./Context/SubscriptionContext";
import { useNavigate } from 'react-router-dom'; // Import useNavigate for programmatically navigating
import Editar from "./Documents/Editar";
import AdminUsers from "./Configuration/Admin";
import Config from "./Configuration/Config";
import ExamListView from "./Exam/ExamListView";
import ExamView from "./Exam/ExamView";
import LogIn from "./Login/Login";
import LoginSuccess from "./Login/LoginSuccess";
import Logout from "./Login/Logout";  // Import the new Logout component
import UpdatePassword from "./Login/UpdatePassword";  // Import the UpdatePassword component
import Welcome from "./Welcome/WelcomePage";
import NewExam from "./AllModalsActions/NewExamPage";
import { green } from "@mui/material/colors";
import { createTheme, ThemeProvider as MuiThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { GenerateQuestionsProvider } from "./Context/GenerateQuestionsContext";
import "./App.css";
import ActiveSubscriptionProvider from "./Context/ActiveSubscriptionContext";
import EmailVerifyCard from "./Error/EmailVerifyCard";
import SubscriptionInactiveCard from "./Error/SubscriptionInactiveCard";
import ProtectedRoute from "./ProtectedRoute";
import CheckoutFormStripe from './Stripe/CheckoutFormStripe';
import PlanSelection from './Stripe/PlanSelection';
import ReturnStripe from './Stripe/ReturnStripe';
import { WebSocketProvider } from './WebSocketProvider';
import { NavigationProvider } from './NavigationContext';
import DocumentStructure from './Main/DocumentStructure';
import AIChatView from './AIChat/AIChatView';
import MemoriseView from './Memorise/MemoriseView';
import WelcomeOposicion from './Welcome/WelcomeOposicion';
import { CreditsProvider } from './Context/CreditsContext';
import LayoutProgress from './Progress/LayoutProgress';
import PricingView from './Stripe/PricingView';
import { useLocation } from 'react-router-dom';
import { PointsProvider } from './Context/PointsContext';
import MermaidChart from './MentalMap/MermaidChart';
import PomodoroPage from './Pomodoro/PomodoroPage';
import { createGlobalStyle } from 'styled-components';
import Flashcards from './Flashcards/Flashcards';
import QuestionFlashcard from './Flashcards/QuestionFlashcards';
import FlashcardPage from './Flashcards/FlashcardPage';
import NewFlashcards from './AllModalsActions/NewFlashcardsPage';

import Dashboard from './Dashboard/Dashboard';

import posthog from 'posthog-js';
import PricingPage from './Stripe/PricingComponents/PricingPage';
import PDFUploader from './PDFUploader/PDFUploader';
import { useSession } from '@supabase/auth-helpers-react';
import AuthCallback from './Login/AuthCallback';
import { AuthProvider } from './Context/AuthContext';
import { UserProvider } from './Context/UserContext';
import { BuildRounded } from '@mui/icons-material';
import SimulacroConfiguration from './Simulacro/SimulacroConfiguration';
import SimulacroPage from './Simulacro/SimulacroPage';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Fira Sans', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #F8FFFF;
  }
`;
const POSTHOG = process.env.REACT_APP_POSTHOG;
// TODO move posthog inside app
posthog.init(POSTHOG, { api_host: 'https://eu.i.posthog.com' })
// Create a new component for scroll restoration
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// Just change this constant to true/false to enable/disable maintenance mode
const MAINTENANCE_MODE = false;

const MaintenancePage = () => {
  return (
    <div style={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '20px',
      backgroundColor: '#F8FFFF',
      gap: '1.5rem'
    }}>
      <BuildRounded
        sx={{
          fontSize: '4rem',
          color: '#6BBDBD',
          animation: 'spin 3s linear infinite',
          '@keyframes spin': {
            '0%': { transform: 'rotate(0deg)' },
            '100%': { transform: 'rotate(360deg)' }
          }
        }}
      />
      <h1 style={{
        color: '#6BBDBD',
        fontSize: '2.5rem',
        margin: 0,
        fontWeight: 600
      }}>
        ¡Volveremos pronto!
      </h1>
      <div style={{
        maxWidth: '500px',
        lineHeight: '1.6',
        color: '#4A4A4A'
      }}>
        <p style={{
          fontSize: '1.2rem',
          margin: '0.5rem 0'
        }}>
          Disculpe las molestias. Estamos realizando tareas de mantenimiento en este momento.
        </p>
        <p style={{
          fontSize: '1.1rem',
          margin: '0.5rem 0',
          color: '#666'
        }}>
          Por favor, vuelva más tarde.
        </p>
      </div>
    </div>
  );
};

function App() {
  const session = useSession();
  const idleTimerRef = useRef(null);

  // Function to handle the user activity
  const onActivity = () => {
    // Clear the existing timer when activity is detected
    clearTimeout(idleTimerRef.current);
    // Set/Reset the timer
    idleTimerRef.current = setTimeout(() => {
      window.location.href = 'https://typedai.com'; // Redirect after inactivity
    }, 5400000); // 1.5 hours of inactivity
  };

  // Effect for local storage cleanup
  useEffect(() => {
    // Function to remove the item from local storage
    const cleanupLocalStorage = () => {
      localStorage.removeItem('chat_responses');
    };

    // Add the event listener for the beforeunload event
    window.addEventListener('beforeunload', cleanupLocalStorage);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', cleanupLocalStorage);
    };
  }, []);

  // Effect for idle timer
  useEffect(() => {
    // Set the initial timer
    idleTimerRef.current = setTimeout(() => {
      window.location.href = 'https://typedai.com';
    }, 5400000);

    // Event listeners for various user activities
    window.addEventListener('mousemove', onActivity);
    window.addEventListener('keydown', onActivity);
    window.addEventListener('scroll', onActivity);
    window.addEventListener('click', onActivity);

    // Cleanup function to remove listeners and clear timer
    return () => {
      clearTimeout(idleTimerRef.current);
      window.removeEventListener('mousemove', onActivity);
      window.removeEventListener('keydown', onActivity);
      window.removeEventListener('scroll', onActivity);
      window.removeEventListener('click', onActivity);
    };
  }, []); // Empty dependency array ensures this runs once on mount

  // Simple maintenance mode check after all hooks
  if (MAINTENANCE_MODE) {
    return <MaintenancePage />;
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: "#6BBDBD",
        light: "#E2F1F1",
        dark: "#5AACAC",
      },
      secondary: {
        main: green[500],
      },
      background: {
        default: "#F8FFFF",
        paper: "#FFFFFF",
      },
    },
    typography: {
      fontFamily: "'Fira Sans', sans-serif",
      button: {
        textTransform: 'none',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '24px',
            textTransform: 'none',
            fontWeight: 500,
            '&.MuiButton-contained': {
              backgroundColor: '#6BBDBD',
              color: 'white',
              '&:hover': {
                backgroundColor: '#5AACAC',
              },
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: '20px',
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
        phone: 500
      },
    },
  });

  let backendHost = BACKEND_URL.replace(/^https?:\/\//, '');
  // const backendHost = "127.0.0.1:8000"

  const protocol = process.env.REACT_APP_ENV === 'local' ? 'ws' : 'wss';
  backendHost = process.env.REACT_APP_ENV === 'local' ? '127.0.0.1:8000' : backendHost;
  // const protocol = "wss"


  const websocketUrlChat = `${protocol}://${backendHost}/ws/chat/`;
  const websocketUrl = `${protocol}://${backendHost}/ws/socket-server/`;


  // useEffect(() => {
  //   // Set a timer to redirect after 3600000 milliseconds (1 hour)
  //   const timer = setTimeout(() => {
  //     window.location.href = 'https://typedai.com'; // Redirect to typedai.com
  //   }, 60000);

  //   // Cleanup function to clear the timer if the component unmounts earlier
  //   return () => clearTimeout(timer);
  // }, []); // Empty dependency array means this effect runs once on mount





  // Create a new component for scroll restoration


  // Crisp will be displayed

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          <GlobalStyle />
          <ActiveSubscriptionProvider>
            <>
              <Router>
                <NavigationProvider>
                  <AuthProvider>
                    <UserProvider token={session?.access_token} user={session?.user}>
                      <Routes>
                        <Route
                          exact
                          path="/dashboard"
                          element={
                            <ProtectedRoute>
                              <SubscriptionProvider>
                                <Dashboard />
                              </SubscriptionProvider>
                            </ProtectedRoute>
                          }
                        />




                        <Route
                          element={
                            <ProtectedRoute>
                              <SubscriptionProvider>
                                <CreditsProvider>
                                  <WebSocketProvider url={websocketUrl} urlchat={websocketUrlChat}>
                                    <Layout />
                                  </WebSocketProvider>
                                </CreditsProvider>
                              </SubscriptionProvider>
                            </ProtectedRoute>
                          }
                        >
                          <Route
                            exact
                            path="/edit/:document_id"
                            element={
                              <ProtectedRoute>
                                <SubscriptionProvider>
                                  <Editar />
                                </SubscriptionProvider>
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            exact
                            path="/mindmap/:document_id"
                            element={
                              <ProtectedRoute>
                                <SubscriptionProvider>
                                  <MindMapFlow />
                                </SubscriptionProvider>
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            exact
                            path="/:folder_id/:document_id"
                            element={
                              <ProtectedRoute>
                                <PointsProvider>
                                  <GenerateQuestionsProvider>
                                    <DocumentStructure active_folder={false} />
                                  </GenerateQuestionsProvider>
                                </PointsProvider>
                              </ProtectedRoute>
                            }
                          />
                          {/* <Route
                    exact
                    path="/:folder_id/:document_id/:chapter_id"
                    element={
                      <ProtectedRoute>
                        <MainStructure active_folder={false} />
                      </ProtectedRoute>
                    }
                  /> */}
                          <Route
                            exact
                            path="/subir-examen"
                            element={
                              <ProtectedRoute>
                                <SubscriptionProvider>
                                  <SubirExamen />
                                </SubscriptionProvider>
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            exact
                            path="/:folder_id/exam/:exam_id"
                            element={
                              <ProtectedRoute>
                                <ExamView key={window.location.pathname} />
                              </ProtectedRoute>
                            }
                          />
                          <Route path="/schemas" element={<MySchemas />} />
                          <Route path="/excalidraw" element={<Excalidraw2 />} />

                          <Route
                            exact
                            path="/exam/:exam_id"
                            element={
                              <ProtectedRoute>
                                <ExamView />
                              </ProtectedRoute>
                            }
                          />

                          <Route
                            exact
                            path="/flashcards"
                            element={
                              <ProtectedRoute>
                                <FlashcardPage />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            exact
                            path="/question-flashcards"
                            element={
                              <ProtectedRoute>
                                <QuestionFlashcard />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            exact
                            path="exams"
                            element={
                              <ProtectedRoute>
                                <ExamListView />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            exact
                            path="aichat"
                            element={
                              <ProtectedRoute>
                                <AIChatView />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            exact
                            path="memorise/*"
                            element={
                              <ProtectedRoute>

                                <MemoriseView />

                              </ProtectedRoute>
                            }
                          />

                          <Route
                            exact
                            path="/schema/:schema_id"
                            element={
                              <SubscriptionProvider>
                                <ProtectedRoute>
                                  <MindMapFlow />
                                </ProtectedRoute>
                              </SubscriptionProvider>
                            }
                          />

                          <Route
                            exact
                            path="/admin"
                            element={
                              <ProtectedRoute>
                                <AdminUsers active_folder={false} />
                              </ProtectedRoute>
                            }
                          />
                          <Route path="/plans" element={
                            <ProtectedRoute>
                              <PricingPage />
                            </ProtectedRoute>
                          } />
                          (
                          <Route
                            exact
                            path="/myaccount"
                            element={
                              <ProtectedRoute>
                                <Config />
                              </ProtectedRoute>
                            }

                          />
                          )
                          <Route
                            exact
                            path="/"
                            element={
                              <ProtectedRoute>
                                <PointsProvider>
                                  <LayoutProgress />
                                </PointsProvider>
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            exact
                            path="/folders"
                            element={
                              <ProtectedRoute>
                                <PointsProvider>
                                  <Home />
                                </PointsProvider>
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            exact
                            path="/new-exam"
                            element={
                              <ProtectedRoute>
                                <NewExam />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            exact
                            path="/new-flashcards"
                            element={
                              <ProtectedRoute>
                                <NewFlashcards />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            exact
                            path="/simulacro-config"
                            element={
                              <ProtectedRoute>
                                <SimulacroConfiguration />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            exact
                            path="/simulacro/:exam_id"
                            element={
                              <ProtectedRoute>
                                <SimulacroPage />
                              </ProtectedRoute>
                            }
                          />
                          {/* <Route
                            exact
                            path="/pomodoro"
                            element={
                              <ProtectedRoute>
                                <PomodoroPage />
                              </ProtectedRoute>
                            }
                          /> */}


                          (
                          <Route
                            exact
                            path="/:folder_id"
                            element={
                              <ProtectedRoute>
                                <GenerateQuestionsProvider>
                                  <MainStructure active_folder={true} />
                                </GenerateQuestionsProvider>
                              </ProtectedRoute>
                            }
                          />
                          )
                        </Route>
                        (
                        <Route
                          exact
                          path="/welcome"
                          element={
                            <SubscriptionProvider>
                              <ProtectedRoute>
                                <NewOnboarding />
                              </ProtectedRoute>
                            </SubscriptionProvider>
                          }
                        />




                        <Route path="/checkout" element={<SubscriptionProvider>
                          <ProtectedRoute>
                            <CheckoutFormStripe />
                          </ProtectedRoute>
                        </SubscriptionProvider>} />
                        <Route path="/success/" element={<ReturnStripe />} />

                        ){/* <Route exact path="/test" element={<Typeform />} /> */}
                        {/* <Route exact path="/intro" element={<VideoIntro />} /> */}
                        <Route
                          exact
                          path="/innactive"
                          element={<SubscriptionInactiveCard />}
                        />
                        <Route exact path="/verify" element={<EmailVerifyCard />} />
                        <Route exact path="/login" element={<LogIn />} />
                        <Route exact path="/logout" element={<Logout />} />
                        <Route exact path="/login-success" element={<LoginSuccess />} />
                        <Route exact path="/auth/callback" element={<AuthCallback />} />
                        <Route exact path="/update-password" element={<UpdatePassword />} />
                        <Route exact path="/" element={<AuthCallback />} />

                        <Route
                          exact
                          path="/pdf-uploader"
                          element={
                            <ProtectedRoute>
                              <SubscriptionProvider>
                                <PDFUploader />
                              </SubscriptionProvider>
                            </ProtectedRoute>
                          }
                        />

                      </Routes>
                    </UserProvider>
                  </AuthProvider>
                </NavigationProvider>
              </Router>
              {/* <Route
                  exact
                  path="/loginSuccessMicrosoft"
                  element={<RecallSuccessOutlook />}
                />
            </Routes> */}
            </>
          </ActiveSubscriptionProvider >
        </StyledThemeProvider>
      </MuiThemeProvider >
    </StyledEngineProvider >
  );
}

export default App;
