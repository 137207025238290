import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Tooltip, Grid, Box } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TimerIcon from '@mui/icons-material/Timer';
import HelpIcon from '@mui/icons-material/Help';
import WhatshotIcon from '@mui/icons-material/Whatshot';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 24px',
        flexGrow: 1,
    },
    contentBox: {
        backgroundColor: '#FFFFFF',
        borderRadius: 12,
        boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.08)',
        border: '1px solid #E7E7E7',
        padding: '24px',
        marginBottom: '50px'
    },
    heatmapWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflow: 'hidden' // Prevent scrolling
    },
    heatmapContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '2px', // Smaller gap for better fit
        width: '100%',
        minWidth: 0, // Allow container to shrink
    },
    weekContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 2, // Smaller gap
        flex: '1 1 0', // Allow equal flex growth and shrink
        minWidth: 0, // Allow container to shrink
    },
    dayCell: {
        width: 'auto', // Let the cell size be determined by container
        paddingBottom: '100%', // Make it square
        borderRadius: 2,
        cursor: 'pointer',
        transition: 'transform 0.2s',
        position: 'relative',
        '&:hover': {
            transform: 'scale(1.2)',
        },
    },
    dayCellContent: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    monthLabels: {
        display: 'flex',
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1),
        color: theme.palette.text.secondary,
        fontSize: '0.75rem',
        width: '100%',
        '& span': {
            flex: '1 1 0',
            textAlign: 'start',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    weekLabels: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginRight: theme.spacing(1),
        height: 'auto',
        '& span': {
            fontSize: '0.75rem',
            color: theme.palette.text.secondary,
            height: 12,
            lineHeight: '12px',
        },
    },
    statsContainer: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(2),
        backgroundColor: 'none',
        borderRadius: 12,
        maxWidth: 800,
        margin: '24px auto 0',
    },
    statItem: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        padding: theme.spacing(1),
    },
    statIcon: {
        color: theme.palette.primary.main,
        fontSize: '1.2rem',
    },
    statContent: {
        flex: 1,
    },
    statValue: {
        fontWeight: 'bold',
        fontSize: '1rem',
        color: theme.palette.text.primary,
    },
    statLabel: {
        color: theme.palette.text.secondary,
        fontSize: '0.75rem',
    }
}));

// Generate dummy data for exactly 12 months
const generateDummyData = () => {
    const data = {};
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth() - 11, 1); // Start from first day of 12 months ago
    const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Last day of current month

    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
        const dateStr = currentDate.toISOString().split('T')[0];
        data[dateStr] = Math.floor(Math.random() * 5);
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return data;
};

const getColorForValue = (value) => {
    const colors = [
        '#ebedf0',
        '#9be9a8',
        '#40c463',
        '#30a14e',
        '#216e39',
    ];
    return colors[value] || colors[0];
};

const calculateStats = (data) => {
    const values = Object.values(data);
    let currentStreak = 0;
    let longestStreak = 0;
    let totalContributions = 0;

    values.forEach(value => {
        if (value > 0) {
            currentStreak++;
            longestStreak = Math.max(longestStreak, currentStreak);
            totalContributions += value;
        } else {
            currentStreak = 0;
        }
    });

    return {
        totalContributions,
        longestStreak,
        questionsAnswered: Math.floor(totalContributions * 1.5), // Dummy calculation
        timeStudied: Math.floor(totalContributions * 45) // Dummy calculation (in minutes)
    };
};

const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
};

const Heatmap = () => {
    const classes = useStyles();
    const data = generateDummyData();
    const stats = calculateStats(data);

    // Calculate date range for exactly 12 months
    const today = new Date();
    const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Last day of current month
    const startDate = new Date(today.getFullYear(), today.getMonth() - 11, 1); // First day of 12 months ago

    // Get first Monday of the start date
    const firstMonday = new Date(startDate);
    while (firstMonday.getDay() !== 1) {
        firstMonday.setDate(firstMonday.getDate() - 1);
    }

    // Generate weeks
    const weeks = [];
    let currentDate = new Date(firstMonday);
    
    while (currentDate <= endDate) {
        let week = [];
        for (let i = 0; i < 7; i++) {
            const dateStr = currentDate.toISOString().split('T')[0];
            const isWithinRange = currentDate >= startDate && currentDate <= endDate;
            
            week.push(isWithinRange ? {
                date: dateStr,
                value: data[dateStr] || 0
            } : null);
            
            currentDate.setDate(currentDate.getDate() + 1);
        }
        weeks.push(week);
    }

    // Generate month labels for exactly 12 months
    const monthLabels = [];
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
    let currentMonth = new Date(startDate);
    const monthSet = new Set();

    while (currentMonth <= endDate) {
        const monthKey = `${currentMonth.getFullYear()}-${currentMonth.getMonth()}`;
        if (!monthSet.has(monthKey) && monthSet.size < 12) { // Ensure only 12 months are added
            monthLabels.push(monthNames[currentMonth.getMonth()]);
            monthSet.add(monthKey);
        }
        currentMonth.setMonth(currentMonth.getMonth() + 1);
    }

    return (
        <div className={classes.root}>
            <Box className={classes.contentBox}>
                <div style={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'flex-start',
                    marginBottom: '24px'
                }}>
                    <div>
                        <Typography style={{ 
                            color: '#000000',
                            fontSize: '24px',
                            fontStyle: 'normal',
                            fontFamily: 'Fira Sans',
                            fontWeight: "bold",
                            letterSpacing: '-0.225px',
                            marginBottom: '4px'
                        }}>
                            Actividad Reciente
                        </Typography>
                        <Typography style={{
                            color: '#666666',
                            fontSize: '14px',
                            fontFamily: 'Fira Sans',
                        }}>
                            Seguimiento de tu progreso diario
                        </Typography>
                    </div>
                    <Typography style={{
                        color: '#2196F3',
                        fontSize: '14px',
                        fontFamily: 'Fira Sans',
                        cursor: 'pointer',
                    }}>
                        Ver Estadísticas
                    </Typography>
                </div>
                <div className={classes.heatmapWrapper}>
                    <div className={classes.monthLabels}>
                        {monthLabels.map((month, i) => (
                            <span key={i}>{month}</span>
                        ))}
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div className={classes.weekLabels}>
                            <span>Mon</span>
                            <span>Wed</span>
                            <span>Fri</span>
                        </div>
                        <div className={classes.heatmapContainer}>
                            {weeks.map((week, weekIndex) => (
                                <div key={weekIndex} className={classes.weekContainer}>
                                    {week.map((day, dayIndex) => (
                                        <div key={dayIndex} className={classes.dayCell}>
                                            {day ? (
                                                <Tooltip
                                                    title={`${day.value} contributions on ${day.date}`}
                                                    placement="top"
                                                >
                                                    <div
                                                        className={classes.dayCellContent}
                                                        style={{
                                                            backgroundColor: getColorForValue(day.value),
                                                        }}
                                                    />
                                                </Tooltip>
                                            ) : (
                                                <div
                                                    className={classes.dayCellContent}
                                                    style={{ backgroundColor: 'transparent' }}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Stats section */}
                <div className={classes.statsContainer}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={3}>
                            <div className={classes.statItem}>
                                <TrendingUpIcon className={classes.statIcon} />
                                <div className={classes.statContent}>
                                    <div className={classes.statValue}>{stats.totalContributions}</div>
                                    <div className={classes.statLabel}>Total Contributions</div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <div className={classes.statItem}>
                                <WhatshotIcon className={classes.statIcon} />
                                <div className={classes.statContent}>
                                    <div className={classes.statValue}>{stats.longestStreak} days</div>
                                    <div className={classes.statLabel}>Longest Streak</div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <div className={classes.statItem}>
                                <HelpIcon className={classes.statIcon} />
                                <div className={classes.statContent}>
                                    <div className={classes.statValue}>{stats.questionsAnswered}</div>
                                    <div className={classes.statLabel}>Questions Answered</div>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <div className={classes.statItem}>
                                <TimerIcon className={classes.statIcon} />
                                <div className={classes.statContent}>
                                    <div className={classes.statValue}>{formatTime(stats.timeStudied)}</div>
                                    <div className={classes.statLabel}>Time Studied</div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Box>
        </div>
    );
};

export default Heatmap;
