import React, { useState, useEffect, useRef } from "react";

import * as handle from "./handleLayout"
import {
    Paper, List, ListItem, ListItemText, Typography, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tooltip
} from '@mui/material';
import css from '../Icons/svg_icon_css.svg';
import pdf from '../Icons/svg_icon_pdf.svg';
import png from '../Icons/svg_icon_png.svg';
import jpg from '../Icons/svg_icon_jpg.svg';
import mp4 from '../Icons/svg_icon_mp4.svg';
import mp3 from '../Icons/svg_icon_mp3.svg';
import xls from '../Icons/svg_icon_xls.svg';
import doc from '../Icons/svg_icon_doc.svg';
import { PDFDocument } from 'pdf-lib';
import { useSubscription } from "../Context/SubscriptionContext";
import { useTranslation, Trans } from 'react-i18next';
import useUploadFilesWelcome from "./Styles/useStylesUploadWelcome";
import upload_notes from '../NewComponents/Upload/SVG/upload_notes.svg';
import trash_icon from '../NewComponents/Upload/SVG/trash_icon.svg';
import UploadIcon from '@mui/icons-material/CloudUpload'; // Add this import
import Button from '@mui/material/Button'; // Add this import
import CircularProgress from '@mui/material/CircularProgress'; // Add this import




const DragAndDrop = ({ setFiles, is_multiple, is_free, is_onboarding }) => {
    const { t } = useTranslation();
    const classes = useUploadFilesWelcome();

    const [openSpinner, setOpenSpinner] = useState(false);
    const [openUploadedSpinner, setOpenUploadSpinner] = useState(false);
    const fileInputRef = useRef(null); // Create a ref for the file input element
    const [selectedFiles, setSelectedFiles] = useState([])
    const [maxPagesMessage, setMaxPagesMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setFiles(selectedFiles);
    }, [selectedFiles]); // Depend on `selectedFiles` to re-run the effect when it changes

    useEffect(() => {

        const handleBeforeUnloadListener = (event) => {
            // Call the imported function with the event and the state variables
            handle.handleBeforeUnload(event, openSpinner, openUploadedSpinner);
        };
        window.addEventListener("beforeunload", handleBeforeUnloadListener);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnloadListener);
        };
    }, [openSpinner, openUploadedSpinner]);

    // Handle confirmation when the user tries to leave the page
    window.onbeforeunload = (event) => {
        if (openSpinner || openUploadedSpinner) {
            return t("file_still_uploading"); // Custom message
        }
    };

    const openFileInput = () => {
        handle.openFileInput(fileInputRef); // Call the function from handle.js
    };

    // Function to handle drag and drop events
    const handleDragOver = (event) => {
        handle.handleDragOver(event); // Call the function from handle.js
    };
    const handleDeleteFile = (index) => {
        setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    };
    // Function to trigger file input click
    const MAX_FILE_SIZE = 300 * 1024 * 1024; // 100 MB in bytes

    const validateDuration = (file, onSuccess, onError) => {
        // Create a URL for the file
        const url = URL.createObjectURL(file);

        // Create a temporary audio or video element (depending on the file type)
        const media = file.type.includes("audio") ? new Audio(url) : document.createElement("video");
        media.src = url;

        // Event listener to fetch the duration
        media.onloadedmetadata = () => {
            URL.revokeObjectURL(url); // Clean up the URL

            const durationInHours = media.duration / 3600;
            if (durationInHours > 2) {
                onError(t("over_limit_hours"));
            } else {
                onSuccess(file);
            }
        };

        // Error handling
        media.onerror = () => {
            URL.revokeObjectURL(url);
            onError("There was an error loading the file.");
        };
    };


    // Function to validate and process files, now including page count for PDFs
    let totalPdfPages = 0; // To accumulate total pages of PDFs
    let validFiles = [];
    let invalidFiles = [];
    let filesProcessed = 0;
    let filePageCounts = []; // New array to keep track of pages for each file

    const handleFileSelection = (files) => {
        setIsLoading(true);
        // Reset everything before processing new files when in onboarding
        if (is_onboarding) {
            validFiles = [];
            invalidFiles = [];
            filesProcessed = 0;
            totalPdfPages = 0;
            filePageCounts = [];
        }
        
        Array.from(files).forEach(file => {
            if (file.size > MAX_FILE_SIZE) {
                const alertMessage = t('file_too_large', { fileName: file.name, maxFileSize: MAX_FILE_SIZE });
                alert(alertMessage);
                invalidFiles.push(file);
                checkAllFilesProcessed(files.length);
            } else if (file.type.includes("audio") || file.type.includes("video")) {
                validateDuration(
                    file,
                    (validFile) => {
                        validFiles.push({ file: validFile, size: validFile.size });
                        checkAllFilesProcessed(files.length);
                    },
                    (errorMsg) => {
                        alert(`File ${file.name}: ${errorMsg}`);
                        invalidFiles.push(file);
                        checkAllFilesProcessed(files.length);
                    }
                );
            } else if (file.type === "application/pdf") {
                processPDF(file).then(() => checkAllFilesProcessed(files.length));
            } else {
                validFiles.push({ file, size: file.size });
                checkAllFilesProcessed(files.length);
            }
        });
    };

    const checkAllFilesProcessed = (totalFiles) => {
        filesProcessed++;
        if (filesProcessed === totalFiles) {
            // Calculate the total pages of the existing selected files
            const existingSelectedFilesPages = selectedFiles.reduce((acc, fileInfo) => acc + fileInfo.pages, 0);

            // Calculate the total pages of just the newly processed PDF files
            const processedTotalPages = filePageCounts.reduce((acc, pages) => acc + pages, 0);

            // Validate combined total of existing selected files pages, existing user pages, and new pages
            const totalCombinedPages = existingSelectedFilesPages + processedTotalPages;

            // Update totalPdfPages if you decide to go ahead and add the files, reflecting the new total.
            totalPdfPages += processedTotalPages; // This line seems to be correctly placed based on your logic.
            processFiles(validFiles);

            // Reset for the next batch of files, if necessary
            filePageCounts = [];
            // Consider resetting totalPdfPages here if it's supposed to be reset after each check
            setIsLoading(false); // Stop loading when done
        }
    };

    const processPDF = async (file) => {
        try {
            const pdfDoc = await PDFDocument.load(await file.arrayBuffer());
            let numberOfPages = pdfDoc.getPageCount();

            if (numberOfPages > 40) {
                // Create a new PDF with only the first 40 pages
                const newPdfDoc = await PDFDocument.create();
                const copiedPages = await newPdfDoc.copyPages(pdfDoc, Array.from(Array(40).keys()));
                copiedPages.forEach(page => newPdfDoc.addPage(page));

                // Convert the new PDF to a Blob
                const pdfBytes = await newPdfDoc.save();

                // Create a new file name
                const fileNameParts = file.name.split('.');
                const fileExtension = fileNameParts.pop();
                const fileName = fileNameParts.join('.');
                const newFileName = `${fileName}_1-40.${fileExtension}`;

                // Create a new File object with the modified name
                file = new File([new Blob([pdfBytes], { type: 'application/pdf' })], newFileName, { type: 'application/pdf' });
                numberOfPages = 40;

                // Inform the user that the document was cut
                setMaxPagesMessage("Generaremos el material de estudio sobre las primeras 40 páginas");
            }

            validFiles.push({ file, size: file.size, pages: numberOfPages });
            filePageCounts.push(numberOfPages);
        } catch (error) {
            console.error("Error processing PDF file:", error);
            invalidFiles.push(file);
        }
    };

    const processFiles = (validFiles) => {
        // When is_onboarding is true, only keep the latest file
        if (is_onboarding) {
            const latestFile = validFiles[validFiles.length - 1];
            if (latestFile) {
                const { file, size, pages } = latestFile;
                let formattedSize = size < 1024 * 1024 ? `${(size / 1024).toFixed(2)} KB` : `${(size / 1024 / 1024).toFixed(2)} MB`;
                setSelectedFiles([{
                    file: file,
                    size: formattedSize,
                    pages: pages,
                }]);
            }
        } else {
            // Original behavior for non-onboarding
            const newFilesArray = validFiles.map(fileInfo => {
                const { file, size, pages } = fileInfo;
                let formattedSize = size < 1024 * 1024 ? `${(size / 1024).toFixed(2)} KB` : `${(size / 1024 / 1024).toFixed(2)} MB`;
                return {
                    file: file,
                    size: formattedSize,
                    pages: pages,
                };
            });
            setSelectedFiles(prevFiles => [...prevFiles, ...newFilesArray]);
        }
    };




    return (
        <>
            <div
                className={classes.container}
                onClick={openFileInput}
                onDragOver={handleDragOver}
                onDrop={(event) => {
                    event.preventDefault();
                    const files = event.dataTransfer.files;
                    if (files.length > 0) {
                        handleFileSelection(files);
                    }
                }}
            >
                <div>
                    <UploadIcon style={{ fontSize: 30, color: '#6BBDBD' }} />
                </div>
                <div className={classes.text}>
                    <Typography style={{ fontSize: "14px", fontFamily: "Inter", fontWeight: 500, color: "#2F384F" }}>
                        Haz clic para subir o arrastra el PDF aquí
                    </Typography>
                </div>

                <input
                    type="file"
                    ref={fileInputRef}
                    accept=".pdf"
                    style={{ display: "none" }}
                    multiple={false}
                    onChange={(event) => {
                        const files = event.target.files;
                        if (files.length > 0) {
                            handleFileSelection(files);
                        }
                    }}
                />
            </div>

            {isLoading && (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '20px'
                }}>
                    <CircularProgress style={{ color: '#6BBDBD' }} />
                </div>
            )}

            {selectedFiles.length > 0 && (
                <>
                    {maxPagesMessage &&
                        <div style={{ color: 'red', fontSize: '14px', marginTop: '10px' }}>
                            <Typography style={{ fontWeight: 600, color: "gray", fontSize: "12px" }}>{maxPagesMessage}</Typography>
                        </div>}
                    <Typography style={{ marginTop: '20px', color: "#2F384F", fontSize: "14px", fontWeight: 600 }}>
                        {t("files_selected")}
                    </Typography>
                    <List component={Paper} className={classes.fileListContainerStyle}>
                        {(is_onboarding ? selectedFiles.slice(-1) : selectedFiles).map((item, index) => (
                            <ListItem key={index} className={classes.listItem}>
                                <ListItemText
                                    primary={
                                        <Tooltip title={item.file.name} placement="top">
                                            <span style={{ fontSize: "14px", fontFamily: "Inter", fontWeight: 400 }}>
                                                {item.file.name.length > 20 ? item.file.name.substring(0, 20) + '...' : item.file.name}
                                            </span>
                                        </Tooltip>
                                    }
                                />
                                <img 
                                    src={trash_icon} 
                                    onClick={() => handleDeleteFile(index)}
                                    style={{ cursor: 'pointer' }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </>
            )}


        </>
    );
};

export default DragAndDrop;
