import React, { useState, useMemo } from 'react';
import { Box, Typography, CircularProgress, Tooltip, Skeleton, Button, Grid, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { TextField, InputAdornment } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BookIcon from '@mui/icons-material/Book';
import { useSubscription } from "../Context/SubscriptionContext";
import LockIcon from '@mui/icons-material/Lock';
import QuizIcon from '@mui/icons-material/Quiz';
import StyleIcon from '@mui/icons-material/Style';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDoc from '../PopUpsAlerts/DeleteDoc/DeleteDoc';
import Select from 'react-select';
import * as handleDoc from "./handleDoc";

import { useNavigate } from 'react-router-dom';
import { keyframes } from '@mui/system';
import { getCsrfToken } from "../Api/TokenApi";
import { sortOptions, getSortPreference, saveSortPreference, STORAGE_KEYS } from '../utils/sortPreferences';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const DocumentSkeleton = () => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '32px',

            minHeight: '120px',
        }}
    >
        <Skeleton variant="circular" width={80} height={80} sx={{ marginRight: '20px' }} />
        <Box sx={{ flexGrow: 1 }}>
            <Skeleton variant="text" width="60%" height={24} sx={{ marginBottom: '8px' }} />
            <Skeleton variant="text" width="40%" height={20} />
        </Box>
    </Box>
);
// Add this keyframes definition outside of the component
const colorChange = keyframes`
  0% { color: #4ECDC4; }  // Light teal
  33% { color: #45B7A0; } // Seafoam green
  66% { color: #2AB7CA; } // Sky blue
  100% { color: #4ECDC4; } // Back to light teal
`;
const DocumentList = ({
    listDocuments,
    isLoading,
    selectedDocument,
    onDocumentSelect,
    selectable = true,
    box = true,
    setShowBlockedDocument,
    token,
    onDeleteDocument,
    user,
}) => {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [selectedSort, setSelectedSort] = useState(
        getSortPreference(STORAGE_KEYS.DOCUMENT_SORT)
    );
    const [visibleDocuments, setVisibleDocuments] = useState(5);
    const [showDeleteDoc, setShowDeleteDoc] = useState(false);
    const [documentToDelete, setDocumentToDelete] = useState(null);

    const handleSortChange = (option) => {
        setSelectedSort(option);
        saveSortPreference(STORAGE_KEYS.DOCUMENT_SORT, option);
    };

    // Get unique folders from documents
    const folderOptions = useMemo(() => {
        if (!listDocuments) return [{ value: '', label: 'Todas las carpetas' }];

        // Create a Map to store unique folders by their id
        const uniqueFoldersMap = new Map();

        // Add each folder to the map, using the id as key to ensure uniqueness
        listDocuments.forEach(doc => {
            if (doc.folder && !uniqueFoldersMap.has(doc.folder)) {
                uniqueFoldersMap.set(doc.folder, {
                    id: doc.folder,
                    name: doc.folder_name || doc.folder
                });
            }
        });

        // Convert the Map values to an array and sort by name
        const uniqueFolders = Array.from(uniqueFoldersMap.values())
            .sort((a, b) => a.name.localeCompare(b.name));

        return [
            { value: '', label: 'Todas las carpetas' },
            ...uniqueFolders.map(folder => ({
                value: folder.id,
                label: folder.name
            }))
        ];
    }, [listDocuments]);

    const filteredAndSortedDocuments = useMemo(() => {
        let docs = listDocuments?.filter(doc =>
            doc.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
            (!selectedFolder?.value || doc.folder === selectedFolder.value)
        ) || [];

        // Sort documents based on selected option
        return docs.sort((a, b) => {
            switch (selectedSort.value) {
                case 'newest':
                    return new Date(b.date || 0) - new Date(a.date || 0);
                case 'oldest':
                    return new Date(a.date || 0) - new Date(b.date || 0);
                case 'name_asc':
                    return a.title.localeCompare(b.title);
                case 'name_desc':
                    return b.title.localeCompare(a.title);
                case 'progress':
                    return getProgress(b) - getProgress(a);
                default:
                    return 0;
            }
        });
    }, [listDocuments, searchQuery, selectedFolder, selectedSort]);

    const { userData } = useSubscription();



    const itemColors = [
        { image: "https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/greenCard.svg", color: '#00B564', label: 'Preguntas Memorizadas' },
        { image: "https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/yellowCard.svg", color: '#FFA726', label: 'Preguntas Aprendiendo' },
        { image: "https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/redCar.svg", color: '#AA161D', label: 'Preguntas Incorrectas' },
        { image: "https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/grayCard.svg", color: '#D9D9D9', label: 'Preguntas Sin Empezar' },
        { image: "", color: '#2196F3', label: 'Preguntas Bloqueadas' }
    ];

    const getMemorizedChaptersText = (document) => {
        const totalPoints = document.main_topics.reduce((sum, topic) => sum + topic.points, 0);
        const totalMaxPoints = document.main_topics.reduce((sum, topic) => sum + topic.TotalPossiblePoints, 0);
        return `${totalPoints} de ${totalMaxPoints} puntos de memoria🧠`;
    };

    const getProgress = (document) => {
        let total;
        if (userData?.plan_name !== "Free" && !document.is_constitution)
            total = document.status.memorized + document.status.learning +
                document.status.notKnown + document.status.notStarted + document.status.blocked
        else
            total = document.status.memorized + document.status.learning +
                document.status.notKnown + document.status.notStarted;
        const percentageMemorized = total > 0 ?
            (document.status.memorized / total) * 100 : 0;
        const result = percentageMemorized.toFixed(0);
        return isNaN(result) || result === 'null' ? '0' : result;
    };

    const getProgressTopic = (document) => {
        if (userData?.plan_name !== "Free" && !document.is_constitution)
            return { memorized: document.status.memorized, learning: document.status.learning, notKnown: document.status.notKnown, notStarted: document.status.notStarted, blocked: 0 };

        // For Basic users, calculate blocked questions
        const totalQuestions = document.pages * 12;
        const availableQuestions = document.status.memorized + document.status.learning + document.status.notKnown + document.status.notStarted;
        const blockedQuestions = totalQuestions - availableQuestions;

        return {
            memorized: document.status.memorized,
            learning: document.status.learning,
            notKnown: document.status.notKnown,
            notStarted: document.status.notStarted,
            blocked: blockedQuestions
        };
    };

    const handleBoxClick = (document, event) => {
        if (!box) {
            event.stopPropagation();
            if (!document.is_blocked) {
                navigate(`/${document.folder}/${document.guid}`);
            } else {
                setShowBlockedDocument(true)
            }
        } else if (selectable) {
            onDocumentSelect(document);
        }
    };


    const handleShowMoreDocuments = () => {
        setVisibleDocuments(prev => prev + 5);
    };

    const handleDelete = (event, document) => {
        event.stopPropagation();
        setDocumentToDelete(document);
        setShowDeleteDoc(true);
    };

    const handleDeleteConfirm = async () => {
        if (documentToDelete) {
            try {

                await handleDoc.deleteDocument(
                    token,
                    documentToDelete.guid,
                    documentToDelete.folder,
                    user
                );

                if (onDeleteDocument) {

                    onDeleteDocument(documentToDelete.guid);

                }
                // Update the UI by filtering out the deleted document

            } catch (error) {
                console.error('Error deleting document:', error);
            }
        } else {
            console.error('No document selected for deletion');
        }
        setShowDeleteDoc(false);
        setDocumentToDelete(null);
    };

    const content = (
        <>
            <DeleteDoc
                open={showDeleteDoc}
                onClose={() => setShowDeleteDoc(false)}
                onDelete={handleDeleteConfirm}
                documentName={documentToDelete?.title}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: '0 32px' }}>
                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    position: 'relative',
                    zIndex: 1100  // Higher than other content
                }}>
                    <Select
                        value={selectedFolder}
                        onChange={setSelectedFolder}
                        options={folderOptions}
                        placeholder="Seleccionar carpeta"
                        styles={{
                            control: (base) => ({
                                ...base,
                                height: '45px',
                                backgroundColor: 'white',
                                borderColor: 'rgba(0, 0, 0, 0.38)',
                                '&:hover': {
                                    borderColor: 'rgba(0, 0, 0, 0.38)'
                                }
                            }),
                            container: (base) => ({
                                ...base,
                                minWidth: '250px',
                                flex: '0 0 250px',
                                position: 'relative'
                            }),
                            menu: (base) => ({
                                ...base,
                                zIndex: 1200,  // Even higher than container
                                position: 'absolute',
                                width: '100%',
                                marginTop: '4px',
                                backgroundColor: 'white',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                            })
                        }}
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Buscar documentos..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        sx={{
                            flex: 1,
                            '& .MuiOutlinedInput-root': {
                                height: '45px',
                                backgroundColor: 'white',
                                '& fieldset': {
                                    borderColor: 'rgba(0, 0, 0, 0.38)',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'rgba(0, 0, 0, 0.38)',
                                },
                            },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: 'rgba(0, 0, 0, 0.38)' }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    gap: 1,
                    marginBottom: 1
                }}>
                    {sortOptions.map((option) => (
                        <Button
                            key={option.value}
                            onClick={() => handleSortChange(option)}
                            sx={{
                                textTransform: 'none',
                                color: selectedSort.value === option.value ? 'white' : '#666',
                                backgroundColor: selectedSort.value === option.value ? '#6BBDBD' : 'transparent',
                                border: '1px solid #E0E0E0',
                                borderRadius: '20px',
                                padding: '6px 16px',
                                fontSize: '14px',
                                fontFamily: 'Inter',
                                '&:hover': {
                                    backgroundColor: selectedSort.value === option.value ? '#6BBDBD' : '#f5f5f5',
                                }
                            }}
                        >
                            {option.label}
                        </Button>
                    ))}
                </Box>
            </Box>

            {isLoading ? (
                Array.from({ length: 3 }).map((_, index) => (
                    <DocumentSkeleton key={index} />
                ))
            ) : filteredAndSortedDocuments.length === 0 ? (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '32px',
                    minHeight: '10px'
                }}>
                    <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/Vacio.svg" alt="No documents" width="120" height="120" />
                    <Typography sx={{ mt: 2, color: '#666', fontSize: '16px' }}>
                        {searchQuery ? 'No se encontraron documentos' : 'Debes subir primero un documento'}
                    </Typography>
                </Box>
            ) : (
                <Grid container spacing={3} sx={{ padding: "32px" }}>
                    {filteredAndSortedDocuments.slice(0, visibleDocuments).map((document, index) => (
                        <Grid item xs={12} sm={6} md={4} key={document.guid} >
                            <Box
                                onClick={(event) => handleBoxClick(document, event)}
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding: '24px',
                                    borderRadius: '24px',
                                    border: '1px solid #E0E0E0',
                                    backgroundColor: selectedDocument === document.guid ? '#E2F1F1' : 'white',
                                    cursor: box ? (selectable ? 'pointer' : 'default') : 'pointer',
                                    transition: 'background-color 0.3s, transform 0.2s',
                                    boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.08)',
                                    '&:hover': {
                                        backgroundColor: selectedDocument === document.guid ? '#E2F1F1' : '#E2F1F1',
                                        transform: 'translateY(-4px)',
                                    }
                                }}
                            >
                                <Box sx={{ mb: 2 }}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        gap: 2,
                                        mb: 2
                                    }}>
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                width: '40px',
                                                height: '40px',
                                                flexShrink: 0
                                            }}
                                        >
                                            <CircularProgress
                                                variant="determinate"
                                                value={getProgress(document)}
                                                size={40}
                                                thickness={4}
                                                sx={{
                                                    color: '#6BBDBD',
                                                    '& .MuiCircularProgress-circle': {
                                                        strokeLinecap: 'round',
                                                    },
                                                    '& .MuiCircularProgress-circleBackground': {
                                                        stroke: '#E2F1F1',
                                                    },
                                                }}
                                            />
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    width: '30px',
                                                    height: '30px',
                                                    borderRadius: '50%',
                                                    backgroundColor: 'white',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: '#6BBDBD',
                                                        fontWeight: 'bold',
                                                        fontSize: '12px',
                                                    }}
                                                >
                                                    {getProgress(document)}%
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ flex: 1 }}>
                                            <Typography
                                                sx={{
                                                    fontWeight: 'bold',
                                                    fontSize: '14px',
                                                    fontFamily: 'Fira Sans',
                                                    color: '#333',
                                                    marginBottom: 1
                                                }}
                                            >
                                                {document.title.length > 30 ? (
                                                    <Tooltip title={document.title}>
                                                        <span>{document.title.substring(0, 30)}...</span>
                                                    </Tooltip>
                                                ) : (
                                                    document.title
                                                )}
                                            </Typography>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '8px',
                                                color: '#666'
                                            }}>
                                                <Box
                                                    sx={{
                                                        width: '12px',
                                                        height: '12px',
                                                        backgroundColor: document.folder_color || '#6BBDBD',
                                                        borderRadius: '3px',
                                                        flexShrink: 0
                                                    }}
                                                />
                                                <Typography sx={{
                                                    fontSize: '14px',
                                                    fontFamily: 'Inter'
                                                }}>
                                                    {document.folder_name || document.folder}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>


                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '8px'
                                    }}>
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                fontFamily: 'Inter',
                                                color: '#666',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '4px'
                                            }}
                                        >
                                            <QuizIcon sx={{
                                                fontSize: 16,
                                                color: '#6BBDBD'
                                            }} />
                                            {document.status.memorized} de {
                                                userData?.plan_name === "Free" && !document.is_constitution
                                                    ? document.pages * 12
                                                    : Object.values(document.status).reduce((sum, curr) => sum + curr, 0)
                                            } preguntas correctas
                                        </Typography>

                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                fontFamily: 'Inter',
                                                color: '#666',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '4px'
                                            }}
                                        >
                                            <StyleIcon sx={{
                                                fontSize: 16,
                                                color: '#00B564'
                                            }} />
                                            {document.status_flashcards?.easy + document.status_flashcards?.normal || 0} de {
                                                Object.values(document.status_flashcards || {}).reduce((sum, curr) => sum + curr, 0)
                                            } flashcards memorizadas
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box sx={{ mt: 'auto' }}>
                                    <Box sx={{ position: 'relative' }}>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '100%'
                                        }}>
                                            {document.is_blocked ? (
                                                <Button
                                                    variant="contained"
                                                    fullWidth
                                                    sx={{
                                                        backgroundColor: '#AA161D !important',
                                                        color: 'white',
                                                        textTransform: 'none',
                                                        fontWeight: '600',
                                                        fontFamily: "'Fira Sans', sans-serif",
                                                        borderRadius: '24px',
                                                        padding: '8px 24px',
                                                        fontSize: '14px',
                                                        boxShadow: 'none',
                                                        '&:hover': {
                                                            backgroundColor: '#8B1217 !important',
                                                        }
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setShowBlockedDocument(true)
                                                    }}
                                                >
                                                    Desbloquear
                                                </Button>
                                            ) : document.is_error_generating ? (
                                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#666', textAlign: 'center' }}>
                                                    Error generando preguntas
                                                </Typography>
                                            ) : document.is_generating ? (
                                                <Typography sx={{ fontWeight: 'bold', animation: `${colorChange} 2s ease-in-out infinite`, textAlign: 'center' }}>
                                                    {'Generando preguntas...'.split('').map((char, index) => (
                                                        <span
                                                            key={index}
                                                            sx={{
                                                                display: 'inline-block !important',
                                                                animation: `${colorChange} 2s ease-in-out infinite !important`,
                                                                animationDelay: `${index * 0.1}s !important`,
                                                            }}
                                                        >
                                                            {char}
                                                        </span>
                                                    ))}
                                                </Typography>
                                            ) : null}
                                        </Box>
                                        <IconButton
                                            onClick={(e) => handleDelete(e, document)}
                                            size="small"
                                            sx={{
                                                color: document.is_blocked ? 'white' : '#9E9E9E',
                                                padding: '4px',
                                                position: 'absolute',
                                                right: 0,
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                '&:hover': {
                                                    color: document.is_blocked ? '#AA161D' : '#9E9E9E',
                                                    backgroundColor: 'rgba(170, 22, 29, 0.04)'
                                                }
                                            }}
                                        >
                                            <DeleteIcon sx={{ fontSize: '18px' }} />
                                        </IconButton>
                                    </Box>
                                    {/* {!box && !document.is_blocked && !document.is_generating && document.pending_tasks && (
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            gap: '4px',
                                            mt: 1
                                        }}>
                                            <WarningAmberIcon
                                                sx={{
                                                    color: '#FF6B6B',
                                                    fontSize: '20px'
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    textDecoration: 'underline',
                                                    fontSize: '14px',
                                                    fontFamily: "'Inter', sans-serif",
                                                    color: '#FF6B6B',
                                                    fontWeight: '500'
                                                }}
                                            >
                                                Tareas pendientes
                                            </Typography>
                                        </Box>
                                    )} */}
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            )}

            {
                visibleDocuments < filteredAndSortedDocuments.length && (
                    <Button
                        onClick={handleShowMoreDocuments}
                        style={{
                            textTransform: 'none',
                            fontSize: '14px',
                            fontWeight: '600',
                            fontFamily: "'Fira Sans', sans-serif",
                            color: 'white',
                            backgroundColor: '#6BBDBD',
                            padding: '8px 16px',
                            borderRadius: '24px',
                            boxShadow: 'none',
                            marginTop: '16px',
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                        Mostrar más
                    </Button>
                )
            }
        </>
    );

    return box ? (
        <Box sx={{
            marginTop: '30px',
            marginBottom: '40px',
            backgroundColor: 'white',
            position: 'relative'  // Add this
        }}>
            <Box className="custom-scrollbar" sx={{
                maxHeight: '500px',
                overflowY: 'auto',
                '& > *': {  // Target direct children
                    position: 'relative'
                }
            }}>
                {content}
            </Box>
        </Box>
    ) : content;
};

export default DocumentList;
