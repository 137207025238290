import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Paper, Typography, Box, Card, CardContent, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Skeleton from '@mui/material/Skeleton';
import TableFolders from "../Folders/TableFolders";
import { useTranslation } from 'react-i18next';
import cardsStyles from '../cardsStyles';
import CardComponent from '../NewComponents/CardComponent';
import GettingStarted from '../Onboard/GettingStarted';
import RectanglesHome from './RectanglesHome';
import SearchIcon from '@mui/icons-material/Search';
import { TextField, InputAdornment, Button as MuiButton } from '@mui/material';
import Select from 'react-select';
import UploadOptionsModal from '../AllModalsActions/UploadOptionsModal';
import { sortOptions, getSortPreference, saveSortPreference, STORAGE_KEYS } from '../utils/sortPreferences';

const useStyles = makeStyles((theme) => ({
    paperLeft: {
        display: "flex",
        flexDirection: "column",
        boxShadow: "none",
        flexGrow: 1,
        height: "100%",  // Ensure it takes the full height of its parent
        overflowY: "auto",  // Enable vertical scrolling
        background: "red",
        width: "100%"  // Add this to ensure full width
    },
    containerParent: {
        display: "flex",
        flexDirection: "column",
        background: "#F9FFFF",
        gap: "10px",
        width: "100%"  // Add this to ensure full width
    },
    container: {
        display: "flex",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridGap: "20px",
        alignItems: "start",
        justifyContent: "center"


    },
    container2: {
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gridGap: "20px",
        alignItems: "start",

        backgroundColor: "none",
        padding: "20px"
    },
    fullWidth: {
        gridColumn: "span 3",
    },
    card: {
        borderRadius: 20,
        backgroundColor: '#E2E8F0',
        boxShadow: 'none',

    },
    media: {
        height: 100,
        backgroundColor: '#CBD5E0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '8px 8px 0 0',
    },
    icon: {
        width: '60%',
        height: '60%',
    },
    content: {
        background: '#F9FFFF',
        padding: '8px 14px !important',
    },
    title: {
        fontWeight: 600,
        fontSize: '14px',
    },
    description: {
        color: '#4A5568',
        fontSize: '10px',
    },
    boxImg: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        marginTop: "100px",
        '@media (max-height: 800px)': {
            marginTop: "7px",
        },
    },
    bottomContainer: {
        display: 'flex', flexDirection: 'row', justifyContent: "space-between",
        marginTop: "20px"
    },
    bordersContainer: {
        borderRadius: "20px",
        padding: "20px 0",
        marginTop: "20px",
        background: "#F9FFFF",
        width: "100%",
        boxSizing: "border-box"
    }
}));

const FolderList = ({
    isLoading,
    initialFolders,
    handleCreateFolderClick,
    fetchDataAndUpdate,
    token,
    user,
    transcripts,
    handleUploadFile,
    handleUploadExam,
    handleClickOpenNotReady,
    userData,
    allPointsData
}) => {
    const classes = useStyles();
    const classesCards = cardsStyles();
    const { t } = useTranslation();
    const [folders, setFolders] = useState(initialFolders);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedSort, setSelectedSort] = useState(
        getSortPreference(STORAGE_KEYS.FOLDER_SORT)
    );
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

    const handleSortChange = (option) => {
        setSelectedSort(option);
        saveSortPreference(STORAGE_KEYS.FOLDER_SORT, option);
    };

    useEffect(() => {
        setFolders([...initialFolders]);
    }, [initialFolders]);

    const filteredAndSortedFolders = useMemo(() => {
        let docs = [...(folders || [])].filter(folder =>
            folder?.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            folder?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            ''
        );

        // Sort folders based on selected option
        return docs.sort((a, b) => {
            switch (selectedSort.value) {
                case 'newest':
                    return new Date(b.date || 0) - new Date(a.date || 0);
                case 'oldest':
                    return new Date(a.date || 0) - new Date(b.date || 0);
                case 'name_asc':
                    return (a.title || a.name || '').localeCompare(b.title || b.name || '');
                case 'name_desc':
                    return (b.title || b.name || '').localeCompare(a.title || a.name || '');

                default:
                    return 0;
            }
        });
    }, [folders, searchQuery, selectedSort]);

    const handleFolderUpdate = async () => {
        await fetchDataAndUpdate();
        setFolders(initialFolders);
    };

    return (
        <>


            <Grid item style={{ width: "100%" }}>
                <Paper elevation={0} >

                    <div className={classes.bordersContainer}>
                        <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>                                       <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                            paddingTop="30px">

                            <Typography
                                style={{
                                    color: 'black',
                                    fontSize: '24px',
                                    fontFamily: "Fira Sans",
                                    fontWeight: "bold",
                                    letterSpacing: '-0.225px',
                                }}
                            >
                                Tus carpetas
                            </Typography>
                            <Box sx={{ display: 'flex', gap: '10px' }}>
                                {userData?.plan_name !== "External" && (
                                    <>
                                        <Button
                                            onClick={() => setIsUploadModalOpen(true)}
                                            style={{
                                                textTransform: "none",
                                                backgroundColor: "white",
                                                color: "#6BBDBD",
                                                fontFamily: "'Fira Sans', sans-serif",
                                                borderRadius: "20px",
                                                padding: "6px 22px",
                                                fontWeight: "bold",
                                                fontSize: "14px",
                                                border: "1px solid #6BBDBD"
                                            }}
                                        >
                                            Añadir
                                        </Button>
                                        <Button
                                            onClick={handleCreateFolderClick}
                                            style={{
                                                textTransform: "none",
                                                backgroundColor: "#6BBDBD",
                                                color: "white",
                                                fontFamily: "'Fira Sans', sans-serif",
                                                borderRadius: "20px",
                                                padding: "6px 22px",
                                                fontWeight: "bold",
                                                fontSize: "14px"
                                            }}
                                        >
                                            Crear carpeta
                                        </Button>
                                        <UploadOptionsModal
                                            open={isUploadModalOpen}
                                            onClose={() => setIsUploadModalOpen(false)}
                                            onUploadDocument={handleUploadFile}
                                        />
                                    </>
                                )}
                            </Box>



                        </Box>
                            <Box><Typography style={{ fontSize: "14px", color: "#A0A0A0", fontStyle: "Fira Sans", fontWeight: "normal" }}>Organiza tus apuntes de la mejor manera</Typography></Box></Box>

                        {/* Search and Filter Section */}
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: "20px" }}>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Buscar carpetas..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    sx={{
                                        flex: 1,
                                        marginBottom: "16px",
                                        '& .MuiOutlinedInput-root': {
                                            height: '45px',
                                            backgroundColor: 'white',
                                            '& fieldset': {
                                                borderColor: 'rgba(0, 0, 0, 0.38)',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'rgba(0, 0, 0, 0.38)',
                                            },
                                        },
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon sx={{ color: 'rgba(0, 0, 0, 0.38)' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                gap: 1,
                                marginBottom: 1,
                                marginTop: "-8px"
                            }}>
                                {sortOptions.map((option) => (
                                    <Button
                                        key={option.value}
                                        onClick={() => handleSortChange(option)}
                                        sx={{
                                            textTransform: 'none',
                                            color: selectedSort.value === option.value ? 'white' : '#666',
                                            backgroundColor: selectedSort.value === option.value ? '#6BBDBD' : '#F9FFFF',
                                            border: '1px solid #E0E0E0',
                                            borderRadius: '20px',
                                            padding: '6px 16px',
                                            fontSize: '14px',
                                            fontFamily: 'Inter',
                                            '&:hover': {
                                                backgroundColor: selectedSort.value === option.value ? '#6BBDBD' : '#f5f5f5',
                                            }
                                        }}
                                    >
                                        {option.label}
                                    </Button>
                                ))}
                            </Box>
                        </Box>

                        <div className={classes.bottomContainer}>


                        </div>

                        {isLoading ? (
                            <div className={classes.container2}>
                                {[...Array(6)].map((_, index) => (
                                    <div key={index} style={{ width: "100%" }}>
                                        <Skeleton
                                            variant="rect"
                                            width="100%"
                                            height={120}
                                            style={{
                                                borderRadius: "20px",
                                                marginBottom: "10px"
                                            }}
                                        />
                                        <Skeleton
                                            variant="text"
                                            width="60%"
                                            height={20}
                                            style={{
                                                marginBottom: "5px"
                                            }}
                                        />
                                        <Skeleton
                                            variant="text"
                                            width="40%"
                                            height={15}
                                        />
                                    </div>
                                ))}
                            </div>
                        ) : filteredAndSortedFolders.length > 0 ? (
                            <div style={{ marginTop: '5px', overflowX: 'hidden', overflowY: "auto", paddingBottom: "3px" }}>

                                <TableFolders
                                    initialFolders={filteredAndSortedFolders}
                                    initialTranscripts={transcripts}
                                    fetchDataAndUpdate={handleFolderUpdate}
                                    token={token}
                                    user={user}
                                    showList={true}
                                    handleUploadFile={handleUploadFile}
                                    handleCreateFolderClick={handleCreateFolderClick}
                                />
                            </div>
                        ) : (
                            <Box className={classes.boxImg}>
                                <div style={{ textAlign: 'center' }}>
                                    <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/noContent.svg" alt="No Documents" className={classes.imgEmpty} />
                                </div>
                                <Typography style={{ fontSize: "14px" }}>Crear una carpeta para organizar tus apuntes</Typography>
                                <Button
                                    onClick={handleCreateFolderClick}
                                    style={{
                                        alignSelf: 'center',
                                        textTransform: 'none',
                                        display: 'flex',
                                        fontFamily: "'Fira Sans', sans-serif",
                                        marginTop: '10px',
                                        borderRadius: "8px",
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '4px',
                                        color: "#026277",
                                        backgroundColor: "#E2F1F1",
                                        fontSize: "14px",
                                        paddingRight: "12px"
                                    }}
                                    className="home-step"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" style={{ marginRight: '8px' }} // Add margin here
                                    >
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10 3.5C10.5523 3.5 11 3.94772 11 4.5V9.5H16C16.5523 9.5 17 9.94772 17 10.5C17 11.0523 16.5523 11.5 16 11.5H11V16.5C11 17.0523 10.5523 17.5 10 17.5C9.44772 17.5 9 17.0523 9 16.5V11.5H4C3.44772 11.5 3 11.0523 3 10.5C3 9.94771 3.44772 9.5 4 9.5L9 9.5V4.5C9 3.94772 9.44772 3.5 10 3.5Z" fill="#025365" />
                                    </svg>
                                    {t('nothing_desc')}
                                </Button>
                            </Box>
                        )}
                    </div>
                </Paper>
            </Grid>
        </>
    );
};

export default FolderList;
