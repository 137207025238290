import { useState, useEffect } from 'react';
import { useSession, useSupabaseClient } from '@supabase/auth-helpers-react';
import jwtDecode from "jwt-decode";

export const useAuth = () => {
    const session = useSession();
    const supabase = useSupabaseClient();
    const [token, setAccessToken] = useState(null);
    const [sessionExpired, setSessionExpired] = useState(false);

    // Function to check if the token is expiring soon
    const isTokenExpiring = (token) => {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // current time in seconds
        const expiryTime = decodedToken.exp;
        const timeToExpiry = expiryTime - currentTime;
        return timeToExpiry < 60 * 5; // refresh if less than 5 minutes to expiry
    };

    // Function to handle logout
    const handleLogout = async () => {
        await supabase.auth.signOut();
        setSessionExpired(true);
        window.location.href = "https://www.google.com";
    };

    // Effect to handle session and token management
    useEffect(() => {
        if (session?.access_token) {
            setAccessToken(session.access_token);
            
            // Check if token is expiring soon
            if (isTokenExpiring(session.access_token)) {
                // Supabase handles token refresh automatically, but we can force a refresh if needed
                supabase.auth.refreshSession().catch((error) => {
                    console.error("Error refreshing session:", error);
                    handleLogout();
                });
            }
        } else {
            setAccessToken(null);
        }
    }, [session]);

    return {
        token,
        user: session?.user,
        isLoading: !session && !sessionExpired,
        sessionExpired,
        setSessionExpired,
        handleLogout
    };
};
