import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  Typography,
  Box,
  Container,
  Paper,
  Divider,
  Grid
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { fetchUserFolders } from '../Api/FolderApi'; // Import the fetchUserInfo function
import { useAuth } from "../Auth/Authhandle";
import { Skeleton } from '@mui/lab'; // Import the Skeleton component
import { useTranslation } from 'react-i18next';
import SmallAlert from "../NewComponents/Modal/smallAlert";
import useHomeStyles from "../Home/useHomeStyles";
import NeedCredits from "../NoCreditsModal/NeedCredits";
import posthog from 'posthog-js'
import ModalParent from "../AllModalsActions/ParentModal";
import { useNavigation } from "../NavigationContext";
import ModalNotReady from "../NewComponents/ModalNotReady";
import { WebSocketContext } from '../WebSocketProvider';
import FullScreenLoader from "../NewComponents/LoadingExam";
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useSubscription } from "../Context/SubscriptionContext";
import Premium from "../PopUpsAlerts/Premium/Premium";
import Progress from "./Progress";
import { useLocation } from 'react-router-dom';
import NewPopupOnboarding from '../NewOnboarding/NewPopupOnboarding';
import Objetivos from '../Home/Components/Objetivos';
import StatsHome from '../Home/StatsHome';
import SimpleCalendar from '../Home/Components/SimpleCalendar';
import HomePopupOnboarding from '../Home/Components/HomePopupOnboarding';

// // import PreguntasListas from "../PopUpsAlerts/QuestionsReady/PreguntasListas";
import { usePoints } from '../Context/PointsContext';
import { fetchDailyStats } from '../Api/DailyApi';
import '../Main/style.css'
import DocumentLocked from "../Documents/DocumentLocked";
import PremiumPopup from '../Stripe/PricingComponents/PremiumPopup';
import WelcomePopup from '../AllModalsActions/WelcomePopup';
import WelcomeBanner from '../Stripe/PricingComponents/WelcomeBanner';
import UploadOptionsModal from '../AllModalsActions/UploadOptionsModal';

const REACT_FRONT_END = process.env.REACT_APP_FRONT_END;

const styles = {
  folder: {
    position: "relative",
    width: "33.33%",
    boxSizing: "border-box",
    padding: "10px",
    textAlign: "center",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
};

function LayoutProgress() {
  const { t } = useTranslation();
  const classes = useHomeStyles();

  // Modal parameters
  const [modalOpen, setModalOpen] = useState(false);
  const { userData, fetchData } = useSubscription();

  const [titleOption, setTitle] = useState("");
  const [descriptionOption, setDescription] = useState("");
  const [buttonOption, setButton] = useState("");
  const [placeholderMessage, setPlaceholderMessage] = useState("");
  const [type, setType] = useState("");
  const [activateFunction, setActivateFunction] = useState(null);
  const [folders, setFolders] = useState([]);
  const { token, user, isAuthenticated } = useAuth(); // Use the hook at the top level
  const [isLoading, setIsLoading] = useState(true); // Add this state
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [typeAlert, setTypeAlert] = useState("");
  const [linkinMessage, setLinkinMessage] = useState("");
  const [widthModal, setWidthModal] = useState("");
  const [disableClose, setDisableClose] = useState(false);
  const theme = useTheme();
  const { previousPath, currentPath } = useNavigation();

  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [showLimitPopup] = useState(true); // Set showLimitPopup to true by default
  const { allPointsData, fetchAllPointsData } = usePoints();
  const [statsData, setStatsData] = useState(null);

  const [showPremiumPopup, setShowPremiumPopup] = useState(false); // Initialize as true to show by default

  const [refetchDocsCallback, setRefetchDocsCallback] = useState(null);

  const [listDocuments, setListDocuments] = useState([]);

  const [uploadOptionsOpen, setUploadOptionsOpen] = useState(false);

  async function fetchDataAndUpdateStats() {
    if (token && user) {
      try {
        const stats = await fetchDailyStats(token, "GET", null, user);
        setStatsData(stats);
      } catch (error) {
        console.error("Error fetching daily stats: ", error);
      }
    }
  }

  async function fetchDataAndUpdate() {
    if (token && user) {
      fetchUserFolders(token, "GET", null, user).then(fetchFolders => {
        setFolders(fetchFolders);
        setIsLoading(false);
      }).catch(error => {
        console.error("Error fetching folders: ", error);
      });
    }
  }

  useEffect(() => {
    fetchDataAndUpdate();
    fetchDataAndUpdateStats();
    if (!allPointsData) {
      fetchAllPointsData(); // Fetch allPointsData when the component mounts
    }
    // Set loading to false once data is fetched
  }, [token, user]);

  const handleFolderCreated = () => {
    fetchDataAndUpdate();
  };

  const handleUploadFile = () => {
    setUploadOptionsOpen(true);
  };

  const handleUploadDocument = () => {
    setModalOpen(true);
    setTitle("📄 " + t("upload_file_sidebar"));
    setDescription(t("sube_material"));
    setButton(t("upload_button"));
    setPlaceholderMessage("");
    setType("upload");
    setWidthModal("5500px");
  };

  const [showSpinner, setShowSpinner] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]); // Move messages state up

  const [showAlert, setShowAlert] = useState(false);
  const [progressBar, setProgressBar] = useState(false);
  const [openNotReady, setOpenNotReady] = useState(false);
  const [folderSelectedGuid, setFolderSelectedGuid] = useState("");
  const [documentSelectedGuid, setDocumentSelectedGuid] = useState("");
  const [isPreparingExam, setIsPreparingExam] = useState(false);

  const [examRedirectId, setExamRedirectId] = useState("");

  const navigate = useNavigate();

  const handleClickOpenNotReady = () => {
    setOpenNotReady(true);
  };

  const handleCloseNotReady = () => {
    setOpenNotReady(false);
  };

  useEffect(() => {
    if (isPreparingExam) {
      const timer = setTimeout(() => {
        setIsPreparingExam(false);
      }, 4000);
      return () => clearTimeout(timer); // Cleanup the timer if the component unmounts or isPreparingExam changes
    }
  }, [isPreparingExam]);

  // Early return to show WelcomeOposicion
  const { messageCallback, setMessageCallback } = useContext(WebSocketContext);

  useEffect(() => {
    if (messageCallback) {
      switch (messageCallback.type) {

        case "documentsUpdate":
          setProgressBar(false)
          setShowAlert(false)
          break;
        case "Error":
          setShowSpinner(false);
          setShowAlert(false)
          break;
        case "popups_getting_started":
          fetchData()
          break;

        default:
          console.log("Unhandled message type:", messageCallback.document);
      }
      setMessageCallback(null);

    }
  }, [messageCallback, refetchDocsCallback, listDocuments]);

  const [showPremium, setShowPremium] = useState(false);
  const [premiumTitleText, setPremiumTitleText] = useState('');

  const handleOpenPremium = (titleText) => {
    setPremiumTitleText(titleText);
    setShowPremium(true);
  };

  const handleClosePremium = () => {
    setShowPremium(false);
  };

  const [showGettingStartedPopup, setShowGettingStartedPopup] = useState(true);

  const handleCloseGettingStartedPopup = () => {
    setShowGettingStartedPopup(false);
  };

  const [showBlockedDocument, setShowBlockedDocument] = useState(false);

  useEffect(() => {
    // Add custom scrollbar class to the body
    document.body.classList.add('custom-scrollbar');

    // Force the body to be scrollable
    document.body.style.height = '101vh';
    document.body.style.overflowY = 'scroll';

    // Clean up function
    return () => {
      document.body.classList.remove('custom-scrollbar');
      document.body.style.height = '';
      document.body.style.overflowY = '';
    };
  }, []);

  // State to control the visibility of the NewPopupOnboarding
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Check previousPath
  useEffect(() => {
    if (previousPath === "/welcome") {
      setIsPopupOpen(true);
    }
  }, [previousPath]);
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const [showWelcomePopup, setShowWelcomePopup] = useState(false);
  const [showWelcomeBanner, setShowWelcomeBanner] = useState(false);

  useEffect(() => {
    if (userData && userData.start_date && userData.plan_name === "Free") {
      const startDate = new Date(userData.start_date);
      const currentDate = new Date();
      const timeDifference = currentDate - startDate;
      const daysDifference = timeDifference / (1000 * 3600 * 24);

      if (daysDifference > 3) {
        setShowWelcomeBanner(false);
      } else {
        setShowWelcomeBanner(true);
      }
    }
  }, [userData]);

  return (
    <>
      {showWelcomeBanner && (
        <Container maxWidth="xl" style={{ marginTop: "20px" }}>
          <WelcomeBanner userData={userData} onClose={() => setShowWelcomeBanner(false)} />
        </Container>
      )}

      {/* <WelcomePopup
        open={showWelcomePopup}
        onClose={() => setShowWelcomePopup(false)}
      /> */}
      {/* 
      <PremiumPopup
        open={showPremiumPopup}
        onClose={() => setShowPremiumPopup(false)}
      /> */}

      {/* <PopupsGettingStarted 
        open={showGettingStartedPopup} 
        onClose={handleCloseGettingStartedPopup} 
        action = "upload"
      /> */}
      {showAlert &&
        <SmallAlert
          message={message}
          onClose={() => setShowAlert(false)}
          progressBar={true}
          linkinMessage={linkinMessage}
          typeAlert={typeAlert}
        />
      }
      {
        <NeedCredits open={showUpgrade} message={message}
          onClose={() => setShowUpgrade(false)} />
      }

      {modalOpen && (
        <ModalParent
          open={modalOpen}
          title={titleOption}
          description={descriptionOption}
          sendButton={buttonOption}
          placeholdMessage={placeholderMessage}
          setShowSpinner={setShowSpinner}
          setMessage={setMessage}
          setShowAlert={setShowAlert}
          type={type}
          onClose={() => {
            setModalOpen(false);
            fetchDataAndUpdate(); // Refresh data when modal closes
          }}
          callFunction={activateFunction}
          setProgressBar={setProgressBar}
          setShowUpgrade={setShowUpgrade}
          width={widthModal}
          onFolderCreated={handleFolderCreated}
          disableClose={disableClose}
          setIsPreparingExam={setIsPreparingExam}
          setFolderSelectedGuid={setFolderSelectedGuid}
          setDocumentSelectedGuid={setDocumentSelectedGuid}
        />
      )}

      {openNotReady && <ModalNotReady handleClose={handleCloseNotReady} />}

      {isPreparingExam && <FullScreenLoader
        imageUrl="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/ExamGettingReady.svg"
        title={t('fullScreenLoader.examPreparingTitle')}
        message={t('fullScreenLoader.examPreparingMessage')}
        buttonText={t('fullScreenLoader.examPreparingButton')}
        onClose={() => { setIsPreparingExam(false); }}
        onButtonClick={() => { setIsPreparingExam(false); if (folderSelectedGuid && documentSelectedGuid) { navigate(`/${folderSelectedGuid}/${documentSelectedGuid}`) } }}
      />}

      <UploadOptionsModal 
        open={uploadOptionsOpen}
        onClose={() => setUploadOptionsOpen(false)}
        onUploadDocument={handleUploadDocument}
      />

      <Container style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#F8FFFF",
        maxWidth: "1400px",
        width: "90%",
        position: 'relative',
        zIndex: 1,
        overflowY: "hidden",
        marginTop: "0px", // Added this line

      }}>

        <Grid container spacing={3}>
          {/* Left Column - Progress */}
          <Grid item xs={12} md={12}>

            <Progress
              handleUploadFile={handleUploadFile}
              setShowBlockedDocument={setShowBlockedDocument}
              listDocuments={listDocuments}
              setListDocuments={setListDocuments}
              statsData={statsData}
            />

          </Grid>

          {/* Right Column */}
          {/* Commenting out right column temporarily
          <Grid item xs={12} md={0}>
            <Objetivos token={token} userData={userData} allPointsData={allPointsData} user={user} />
            <StatsHome statsData={statsData} />
            <SimpleCalendar user_calendar={userData.login_history} />
          </Grid>
          */}
        </Grid>

        {showBlockedDocument && (<DocumentLocked open={showBlockedDocument} onClose={() => setShowBlockedDocument(false)} />)}
        {showPremium && (
          <Premium
            onClose={handleClosePremium}
            titleText={premiumTitleText}
          />
        )}
      </Container>
      {/* Render NewPopupOnboarding if the current path is /welcome and the popup is open */}
      {isPopupOpen && (
        <HomePopupOnboarding open={isPopupOpen} onClose={handleClosePopup} />
      )}
    </>
  );
}

export default LayoutProgress;
