import {
    Grid,
    Paper,
    Typography,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    useMediaQuery,
    useTheme,
    Button,
    IconButton
} from '@mui/material';
import { useSubscription } from "../Context/SubscriptionContext";
import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import ModalFolder from "../Folders/ModalFolders";
import NeedCredits from "../NoCreditsModal/NeedCredits";
import PotatoIcon from "@mui/icons-material/EmojiNature"; // Use a potato icon
import { useNavigate } from 'react-router-dom';
import { Tabs, Tab, Box } from '@mui/material';
import ProgresoLateral from "../Documents/ProgresoLateral";
import SchemaIcon from '@mui/icons-material/AccountTree'; // Import a suitable icon
import QuizIcon from '@mui/icons-material/Quiz';  // Add this import
import PsychologyIcon from '@mui/icons-material/Psychology';  // Add this import
import Divider from '@mui/material/Divider';  // Add this import
import TestOptionsModal from '../Documents/TestOptionsModal';

import { useParams, useLocation } from "react-router-dom";
import { fetchUserFile } from '../Api/FilesApi'; // Import the fetchUserInfo function
import Document from "../Documents/Document";
import FolderContent from "../Folders/FolderContent"; // Adjust the path based on your folder structure
import BreadCrumb from "../NewComponents/BreadCrumb"; // Adjust the path based on your folder structure
import Chat from "../NewComponents/Chat";
import * as handle from "./handle";
import { Container } from '@mui/material'
import { useAuth } from "../Auth/Authhandle";
import SmallAlert from "../NewComponents/Modal/smallAlert";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { useTranslation } from 'react-i18next';
import Summary from "../Documents/SummaryChapters";
import { WebSocketContext } from '../WebSocketProvider';
import CustomList from "./CustomList";
import useDocumentStyles from "./Styles/useDocumentStyles";
import UploadButton from "../NewComponents/SubirApuntesButton";
import ModalParent from "../AllModalsActions/ParentModal";
import { fetchExamsbyDocumentId, fetchFolderExams } from "../Api/ExamsApi";
import FullScreenLoader from "../NewComponents/LoadingExam";
import DropdownMenu from "../NewComponents/DropDownComponent";
import { fetchQuestions } from "../Api/ChaptersApi";
import { usePoints } from '../Context/PointsContext';
import Premium from '../PopUpsAlerts/Premium/Premium';
import LockIcon from '@mui/icons-material/Lock';
import { useGenerateQuestions } from "../Context/GenerateQuestionsContext";
import AddIcon from '@mui/icons-material/Add';  // Add this import
import GetAppIcon from '@mui/icons-material/GetApp';
import ChatIcon from '@mui/icons-material/Chat';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import html2pdf from 'html2pdf.js';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfFonts.vfs;

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function DocumentStructure({ active_folder }) {
    const { userData } = useSubscription();
    const location = useLocation();

    const { state } = location;
    const isBasicPlan = userData?.plan_name?.startsWith("Basic"); // Check if the plan is Basic
    const shouldCreateExam = state?.shouldCreateExam || false;
    const shouldCreateSummary = state?.shouldCreateSummary || false;

    const { token, user, isAuthenticated } = useAuth(); // Use the hook at the top level
    const [listDocuments, setListDocuments] = useState([]);
    const [listExams, setListExams] = useState([]); // Define setListExams state

    const [messages, setMessages] = useState([]); // Move messages state up
    const [isVideo, setIsVideo] = useState(true);
    const [isOpenVideo, setIsOpenVideo] = useState(false);
    const [valueVideo, setValueVideo] = useState("Ver video");
    const [videoUrl, setVideoUrl] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [titleOption, setTitle] = useState("");
    const [descriptionOption, setDescription] = useState("");
    const [buttonOption, setButton] = useState("");
    const [placeholderMessage, setPlaceholderMessage] = useState("");
    const [typeModal, setTypeModal] = useState("");
    const [openMenu, setOpenMenu] = useState(false);
    // Folder info 
    const [folder, setFolder] = useState(null)
    // Single Doc info
    const [docInfo, setDocInfo] = useState(null)

    //Alert
    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState("");

    const { t } = useTranslation();

    const { chapter_id, selectedChapter } = useParams();

    const { document_id, selectedDocument } = useParams();
    const initialIsDocument = document_id ? true : false;

    const { folder_id, selectedFolder } = useParams();
    const [summary, setSummary] = useState("")
    const { needGenerateQuestions, setNeedGenerateQuestions } = useGenerateQuestions();
    const [isGeneratingFlashcards, setIsGeneratingFlashcards] = useState(false);

    // For upload file pop up
    // Assuming modalOpenUpload is a boolean to control the visibility of a modal
    const [modalOpenUpload, setModalOpenUpload] = useState(false);
    const [titleOptionUpload, setTitleOptionUpload] = useState('');
    const [descriptionOptionUpload, setDescriptionOptionUpload] = useState('');
    const [buttonOptionUpload, setButtonOptionUpload] = useState('');
    const [placeholderMessageUpload, setPlaceholderMessageUpload] = useState('');
    const [typeUpload, setTypeUpload] = useState('');
    const [showSpinnerUpload, setShowSpinnerUpload] = useState(false);
    const [showAlertUpload, setShowAlertUpload] = useState(false);
    const [progressBar, setProgressBar] = useState(false);
    const [showUpgrade, setShowUpgrade] = useState(false);
    const [messageUpload, setMessageUpload] = useState('');
    const [chaptersTitle, setChaptersTitle] = useState([]);
    const [chapterTitle, setChapterTitle] = useState([]);
    const [loadingStates, setLoadingStates] = useState({});
    const [width, setWidthModal] = useState("");
    const [isDocument, setIsDocument] = useState(true);
    const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);

    const classes = useDocumentStyles(isDocument);

    const [questions, setQuestions] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [loadedChapters, setLoadedChapters] = useState([]);
    const [currentChapterIndex, setCurrentChapterIndex] = useState(0);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery('(max-width:1100px)');

    const [showPremium, setShowPremium] = useState(false);

    const [premiumTitleText, setPremiumTitleText] = useState("");
    const [anchorElCollapsed, setAnchorElCollapsed] = useState(null);
    const [isLoadingSummary, setIsLoadingSummary] = useState(true); // Add loading state for summary
    const [isExamDocument, setIsExamDocument] = useState(false)
    const [regeneratingQuestions, setRegeneratingQuestions] = useState(false);
    const [testModalOpen, setTestModalOpen] = useState(false);  // Add this state
    const [isChatVisible, setIsChatVisible] = useState(true);

    const handleUploadFile = () => {
        handle.handleUploadFile(
            t,
            setModalOpenUpload,
            setTitleOptionUpload,
            setDescriptionOptionUpload,
            setButtonOptionUpload,
            setPlaceholderMessageUpload,
            setTypeUpload,
            setWidthModal
        );
    };
    const handleCreateExam = () => {
        navigate(`/new-exam?document=${document_id}`)
    };
    useEffect(() => {
        async function fetchData() {
            if (token && user) {
                if (!document_id) {
                    handle.fetchData(token, setFolder, folder_id, user); // Call the fetchData function
                    handle.fetchDocuments(token, setListDocuments, folder_id, user);
                }
            }
        }


        fetchData();
    }, [token, user]);
    useEffect(() => {
        fetchExams()
    }, [token, user, folder])


    // const generateAllSummaries = async () => {
    //   setAllChaptersLoading();  // Set all to loading before starting

    //   for (const chapter of chaptersTitle) {
    //     await generateSummary(chapter.guid);
    //   }
    // };

    useEffect(() => {

        if (shouldCreateExam) {
            handleCreateExam()
        }
    }, [shouldCreateExam])


    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchQuestions(token, document_id, user);
            setQuestions(data)
        };
        if (token && document_id && user) {
            fetchData();
        }
    }, [token, needGenerateQuestions, document_id, user]);


    useEffect(() => {
        const fetchDecryptedVideoUrl = async (url_stored) => {
            try {
                const response = await fetch(`${BACKEND_URL}/api_v1/decrypt-pdf/`, {
                    method: 'POST',
                    body: JSON.stringify({ fileUrl: url_stored }),
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
                });
                if (response.ok) {
                    const blob = await response.blob(); // Handle binary data for video
                    const url = URL.createObjectURL(blob); // Create a URL for the blob object
                    setVideoUrl(url);
                } else {
                    console.error('Failed to fetch video URL');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };
        const fetchData = async () => {
            if (token && user) {
                let docSeri; // Declare docSeri outside the try block
                try {
                    setIsLoadingSummary(true); // Start loading
                    docSeri = await fetchUserFile(token, "GET", folder_id, document_id, null, user);
                    setDocInfo(docSeri);
                    setIsExamDocument(docSeri.is_exam)
                    setSummary(docSeri.summary); // Set summary
                    setIsGeneratingSummary(docSeri.is_creating_summary);
                    setIsGeneratingFlashcards(docSeri.is_creating_flashcards);
                    setIsLoadingSummary(false); // Set loading to false only if summary is available

                } catch (error) {
                    console.error(error);
                }
            }
        };

        if (document_id) {

            fetchData(); // Call the fetchData function
        }

    }, [document_id, token, user, needGenerateQuestions])

    const fetchExams = async () => {
        if (token && user && folder) {
            try {
                const listExamsFetched = await fetchExamsbyDocumentId(token, "GET", folder.guid, document_id, user,);

                // const listExamsFetched = await fetchFolderExams(token, "GET", folder.guid, user);
                setListExams(listExamsFetched);
            } catch (error) {
                console.error(error);
            }
        }
    };
    const updateListDocuments = async () => {
        handle.fetchDocuments(token, setListDocuments, folder_id, user);

    };


    // const shouldShowGenerateButton = useMemo(() => {
    //   // Check if any chapter does not have a summary generated
    //   return chaptersTitle.some(chapter => !chapter.is_active);
    // }, [chaptersTitle]);  // Recompute when chaptersTitle changes

    // const setAllChaptersLoading = () => {
    //   const newLoadingStates = chaptersTitle.reduce((acc, chapter) => {
    //     acc[chapter.guid] = true;  // Set loading to true for each chapter
    //     return acc;
    //   }, {});
    //   setLoadingStates(newLoadingStates);
    // };


    const [typeAlert, setTypeAlert] = useState("");
    const [linkinMessage, setLinkinMessage] = useState("");
    const [isPreparingExam, setIsPreparingExam] = useState(false);

    const backendHost = BACKEND_URL.replace(/^https?:\/\//, '');
    const url = "";
    const { messageCallback } = useContext(WebSocketContext);
    const [errorGeneratingQuestions, setErrorGeneratingQuestions] = useState(false);
    const [stepValue, setStepValue] = useState("");
    const [messageStep, setMessageStep] = useState("");
    const [topics, setTopics] = useState([]);
    const [chapters_generating, setChaptersGenerating] = useState(true);
    const [newGeneratingSummary, setNewGeneratingSummary] = useState(false);
    useEffect(() => {
        if (messageCallback) {
            switch (messageCallback.type) {
                case "generating_flashcards":
                    setIsGeneratingFlashcards(true);
                    break;
                // case "Summary":
                //     console.log("Summary in DocumentStructure.js ", messageCallback)
                //     if (messageCallback.document_id === document_id) {
                //         setSummary(messageCallback.summary);
                //         docInfo.summary_active = true;
                //     }

                //     break;
                case "documentsUpdate":
                    setShowAlert(false)
                    setListDocuments(prevDocuments => [...prevDocuments, messageCallback.document]);
                    break;
                case "Exam":

                    fetchExams()
                    break;
                case "ErrorGenerating":
                    setErrorGeneratingQuestions(true)
                    break;
                case "generating_questions":
                    setNeedGenerateQuestions(true)
                    break;
                case "generating_questions_plus":
                    setNeedGenerateQuestions(true);
                    setRegeneratingQuestions(true);
                    break;
                case "generating_questions_done":
                    setNeedGenerateQuestions(false)
                    setRegeneratingQuestions(false);
                    // fetchQuestionsData();
                    setQuestions(prevQuestions => ({
                        ...prevQuestions,
                        question_generated: true
                    }));

                    break;
                case "new_generating_summary":
                    setNewGeneratingSummary(true)
                    break;
                case "steps_generating_questions":
                    setStepValue(messageCallback.step)
                    setMessageStep(messageCallback.messageStep)
                    break;


                case "topic_saved":
                    if (messageCallback.document_id !== document_id) {
                        break;
                    }
                    setChaptersGenerating(false)
                    if (messageCallback.chapters_generating) {

                        break;
                    }

                    setQuestions(prevQuestions => {
                        const newTopics = [...(prevQuestions.topics || [])];
                        const existingTopicIndex = newTopics.findIndex(t => t.guid === messageCallback.topic.guid);
                        if (existingTopicIndex !== -1) {
                            newTopics[existingTopicIndex] = messageCallback.topic;
                        } else {
                            newTopics.push(messageCallback.topic);
                        }

                        // Sort the topics in reverse order
                        const sortedTopics = newTopics.sort((a, b) => b.order - a.order);

                        return {
                            ...prevQuestions,
                            topics: sortedTopics,
                        };
                    });
                    break;

                default:
                    console.log("Unhandled message type:", messageCallback.document);
            }
        }
    }, [messageCallback]);
    const fetchQuestionsData = async () => {
        try {
            const data = await fetchQuestions(token, document_id, user);
            setQuestions(data);
        } catch (error) {
            console.error("Error fetching questions:", error);
            // Handle the error appropriately
        }
    };
    // const handleWebSocketMessage = useCallback((message) => {
    //   console.log('Received chapters:', message);
    //   switch (message.type) {
    //     case "chapterUpdate":
    //       setChaptersTitle(message.chapters);
    //       break;

    //     default:
    //       console.log("Unhandled message type:", message);
    //   }
    // }, []);
    // const [sendMessage] = useWebSocketComponent(url, handleWebSocketMessage);
    const handleExamCreated = () => {
        fetchExams();
    };
    const truncateText = (text, length) => {
        if (text.length <= length) {
            return text;
        }
        return text.substring(0, length) + '...';
    };

    useEffect(() => {
        if (isPreparingExam) {
            const timer = setTimeout(() => {
                setIsPreparingExam(false);
            }, 4000);
            return () => clearTimeout(timer); // Cleanup the timer if the component unmounts or isPreparingExam changes
        }
    }, [isPreparingExam]);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (option) => {
        setAnchorEl(null);
        if (option) {
            alert(`You selected ${option}`);
        }
    };
    const [folderSelectedGuid, setFolderSelectedGuid] = useState("");
    const [documentSelectedGuid, setDocumentSelectedGuid] = useState("");
    const navigate = useNavigate();

    const [tabValue, setTabValue] = useState(0); // Add this line to define tabValue state
    const [pieData, setPieData] = useState([]);
    const
        calculatePieData = (questions, userData) => {
            let correct = 0;
            let incorrect = 0;
            let learning = 0;
            let notAnswered = 0;
            let blocked = 0;
            if (questions.topics.length > 0 && questions.topics) {
                questions.topics.forEach(topic => {
                    topic.questions.forEach(question => {
                        if (question.points >= 5) {
                            correct++;
                        } else if (question.points >= 1 && question.points <= 4) {
                            learning++;
                        } else if (question.points < 1 && question.question_answered) {
                            incorrect++;
                        } else if (!question.questionactive && userData.plan_name === "Free") {
                            blocked++;
                        } else {
                            notAnswered++;
                        }
                    });
                });
            }

            return [
                { name: 'Correctas ✅', value: correct },
                { name: 'Incorrectas ❌', value: incorrect },
                { name: 'Aprendiendo 🧠', value: learning },
                { name: 'Sin responder 👩‍🏫', value: notAnswered },
                { name: 'Bloqueadas 🔒', value: blocked },
            ];
        };
    useEffect(() => {
        if (questions && userData && questions.topics && questions.topics.length > 0) {
            const newPieData = calculatePieData(questions, userData);
            setPieData(newPieData);
        }

    }, [document_id, questions]);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue); // Add this function to handle tab changes
    };

    const handleDesbloquear = () => {
        setShowPremium(true);
    };

    // Add this console log just before the return statement in your component

    const isWideScreen = useMediaQuery('(min-width:1400px)');
    return (
        <>
            <TestOptionsModal 
                open={testModalOpen}
                onClose={() => setTestModalOpen(false)}
                document_id={document_id}
            />
            {showAlert &&
                <SmallAlert
                    message={message}
                    progressBar={false}
                    onClose={() => setShowAlert(false)}
                    linkinMessage={linkinMessage}
                    typeAlert={typeAlert}
                />
            }
            {showAlertUpload &&
                <SmallAlert
                    message={messageUpload}
                    progressBar={progressBar}
                    onClose={() => setShowAlertUpload(false)}
                />
            }

            {showUpgrade &&
                <NeedCredits initialOpen={true} message={message}

                    onClose={() => setShowUpgrade(false)} />
            }

            {isPreparingExam && <FullScreenLoader
                imageUrl="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/ExamGettingReady.svg"
                title={t('fullScreenLoader.examPreparingTitle')}
                message={t('fullScreenLoader.examPreparingMessage')}
                buttonText={t('fullScreenLoader.examPreparingButton')}
                onClose={() => { setIsPreparingExam(false); }}
                onButtonClick={() => { setIsPreparingExam(false); if (folderSelectedGuid && documentSelectedGuid) { navigate(`/${folderSelectedGuid}/${documentSelectedGuid}`) } }}
            />}


            {/* <Grid

                spacing={3}
                className={classes.gridContainer}
            > */}
            <Container style={{
                display: "flex",
                flexDirection: "row",
                gap: "0px",
                backgroundColor: "#F8FFFF",
                maxWidth: "none",
                justifyContent: isChatVisible ? "flex-start" : "center",
                position: 'relative'
            }}>
                <Grid
                    container
                    spacing={0}
                    className={classes.leftPart}
                    style={{
                        maxWidth: isChatVisible ? undefined : '1000px',
                        margin: isChatVisible ? undefined : '0 auto'
                    }}
                >
                    <Paper elevation={3} className={classes.paperLeft}>
                        <div style={{
                            position: 'relative',
                            justifyContent: "space-between",
                            display: "flex",
                            alignItems: "center"
                        }}>
                            {/* <BreadCrumb className={classes.breadcrumbContainer} root={t("home")} docInfo={docInfo} folderInfo={folder} token={token} chapterTitle={chapterTitle} /> */}
                            {!docInfo ? (
                                <Typography style={{
                                    color: "#026277",
                                    fontSize: "20px",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    letterSpacing: "-0.225px",
                                    paddingLeft: 2
                                }}>Document</Typography>
                            ) : (
                                <div className={classes.fullWidthContainer}>
                                    <div>
                                        <Tooltip title={docInfo.title} arrow placement="top">
                                            <Typography
                                                style={{
                                                    color: "black",
                                                    fontFamily: "Fira Sans",
                                                    fontSize: "22px",
                                                    fontStyle: "normal",
                                                    fontWeight: 600,
                                                    letterSpacing: "-0.225px",
                                                    paddingLeft: 2,
                                                }}
                                            >
                                                {truncateText(docInfo.title, isSmallScreen ? 15 : 30)}
                                            </Typography>
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <Box style={{ display: 'flex', flexDirection: 'row', gap: '16px' }}>
                                            {docInfo && (
                                                <Tooltip title="Editar flashcards y preguntas" arrow>
                                                    <Button
                                                        onClick={() => navigate(`/edit/${document_id}`, {
                                                            state: {
                                                                document_id: document_id,
                                                                docInfo: docInfo,
                                                                questions: questions?.topics || [],
                                                                flashcards: docInfo?.flashcards || []
                                                            }
                                                        })}
                                                        sx={{
                                                            backgroundColor: 'white',
                                                            color: '#6BBDBD',
                                                            border: '1px solid #6BBDBD',
                                                            textTransform: 'none',
                                                            fontWeight: '500',
                                                            fontFamily: "'Fira Sans', sans-serif",
                                                            borderRadius: '24px',
                                                            padding: '8px 24px',
                                                            fontSize: '14px',
                                                            minWidth: '150px',
                                                            whiteSpace: 'nowrap',
                                                            '&:hover': {
                                                                backgroundColor: 'white',
                                                                borderColor: '#5aa9a9',
                                                                color: '#5aa9a9',
                                                            },
                                                        }}
                                                    >
                                                        Añadir más preguntas
                                                    </Button>
                                                </Tooltip>
                                            )}
                                            {userData.plan_name === "Free" && (
                                                <Tooltip title="Desbloquear todas las preguntas" arrow>
                                                    <LockIcon
                                                        style={{
                                                            color: '#6BBDBD',
                                                            width: '24px',
                                                            height: '24px',
                                                            cursor: 'pointer',
                                                            margin: "auto"
                                                        }}
                                                        onClick={handleDesbloquear}
                                                    />
                                                </Tooltip>
                                            )}
                                            {questions && questions.topics && questions.topics.length > 0 && (
                                                <>
                                                    <Button
                                                        sx={{
                                                            backgroundColor: '#6BBDBD',
                                                            color: 'white',
                                                            textTransform: 'none',
                                                            fontWeight: '600',
                                                            fontFamily: "'Fira Sans', sans-serif",
                                                            borderRadius: '24px',
                                                            padding: '8px 24px',
                                                            fontSize: '14px',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            gap: '8px',
                                                            boxShadow: 'none',
                                                            '&:hover': {
                                                                backgroundColor: '#5aa9a9',
                                                            },
                                                            width: '130px',
                                                        }}
                                                        aria-controls="study-menu"
                                                        aria-haspopup="true"
                                                        onClick={(event) => setAnchorEl(event.currentTarget)}
                                                    >
                                                        Estudiar
                                                        <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M5 7.5L10 12.5L15 7.5"
                                                                stroke="currentColor"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </Button>
                                                    <Menu
                                                        id="study-menu"
                                                        anchorEl={anchorEl}
                                                        keepMounted
                                                        open={Boolean(anchorEl)}
                                                        onClose={() => setAnchorEl(null)}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                        PaperProps={{
                                                            style: {
                                                                marginTop: '8px',
                                                                borderRadius: '12px',
                                                                minWidth: '200px',
                                                                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                                                            },
                                                        }}
                                                        style={{
                                                            marginTop: '8px'
                                                        }}
                                                    >
                                                        <MenuItem
                                                            onClick={() => {
                                                                setTestModalOpen(true);
                                                                setAnchorEl(null);
                                                            }}
                                                            style={{
                                                                padding: '12px 16px',
                                                                fontSize: '14px',
                                                            }}
                                                        >
                                                            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                                                <QuizIcon style={{ color: '#666' }} />
                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    <span style={{ fontWeight: 500, fontFamily: "Fira Sans" }}>Hacer test</span>
                                                                    <span style={{ color: '#666', fontSize: '12px', fontFamily: "Fira Sans" }}>Ponte a prueba con un test</span>
                                                                </div>
                                                            </div>
                                                        </MenuItem>
                                                        <Divider style={{ margin: '4px 0' }} />
                                                        <Tooltip title={isBasicPlan ? "Funcionalidad solo para premium" : ""}>
                                                            <div>
                                                                <MenuItem
                                                                    onClick={() => {
                                                                        if (!isBasicPlan) {
                                                                            navigate('/new-flashcards?document=' + document_id, { state: { reset: true }, replace: true });
                                                                        }
                                                                        setAnchorElCollapsed(null);
                                                                    }}
                                                                    style={{
                                                                        padding: '12px 16px',
                                                                        fontSize: '14px',
                                                                        cursor: isBasicPlan ? 'default' : 'pointer', // Disable click for Basic plans
                                                                        backgroundColor: isBasicPlan ? '#f0f0f0' : 'inherit', // Light gray background for Basic plans
                                                                        position: 'relative', // Position relative for lock icon placement
                                                                    }}
                                                                    disabled={isBasicPlan} // Disable the MenuItem for Basic plans
                                                                >
                                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                                                        <PsychologyIcon style={{ color: '#666' }} />
                                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                            <span style={{ fontWeight: 500, fontFamily: "Fira Sans" }}>Tarjetas de memoria</span>
                                                                            <span style={{ color: '#666', fontSize: '12px', fontFamily: "Fira Sans" }}>Responde a flashcards</span>
                                                                        </div>
                                                                        {isBasicPlan && (
                                                                            <LockIcon style={{
                                                                                color: '#666',
                                                                                position: 'absolute',
                                                                                top: '8px',
                                                                                right: '8px',
                                                                                fontSize: '16px' // Smaller size for the lock icon
                                                                            }} />
                                                                        )}
                                                                    </div>
                                                                </MenuItem>
                                                            </div>
                                                        </Tooltip>
                                                    </Menu>
                                                </>
                                            )}


                                        </Box>
                                        {/* <DropdownMenu document_id={document_id} /> */}
                                    </div>
                                </div>

                            )}
                        </div>
                        {docInfo && <Document
                            chapters_generating={chapters_generating}
                            stepValue={stepValue}
                            messageStep={messageStep}
                            setQuestions={setQuestions}
                            questions={questions}
                            loading={loading}
                            error={error}
                            isGeneratingSummary={isGeneratingSummary}
                            setIsGeneratingSummary={setIsGeneratingSummary}
                            docInfo={docInfo}
                            summary={summary}
                            setSummary={setSummary}
                            token={token}
                            user={user}
                            setMessage={setMessage}
                            setShowAlert={setShowAlert}
                            handleCreateExam={handleCreateExam}
                            listExams={listExams}
                            setListExams={setListExams}
                            shouldCreateSummary={shouldCreateSummary}
                            setNeedGenerateQuestions={setNeedGenerateQuestions}
                            needGenerateQuestions={needGenerateQuestions}
                            errorGeneratingQuestions={errorGeneratingQuestions}
                            newGeneratingSummary={newGeneratingSummary}
                            setNewGeneratingSummary={setNewGeneratingSummary}
                            regeneratingQuestions={regeneratingQuestions}
                            setRegeneratingQuestions={setRegeneratingQuestions}
                            setShowUpgrade={setShowUpgrade}
                            userData={userData}
                            isGeneratingFlashcards={isGeneratingFlashcards}
                            setIsGeneratingFlashcards={setIsGeneratingFlashcards}
                            isLoadingSummary={isLoadingSummary}
                            isExamDocument={isExamDocument}
                        />}
                    </Paper>
                </Grid>

                {isChatVisible && (
                    <Grid item className={classes.rightPart} style={{
                        position: 'relative',
                        marginLeft: 0,
                        width: '33.333333%' // Explicitly set the width
                    }}>
                        {!isSmallScreen && (
                            <IconButton
                                onClick={() => setIsChatVisible(!isChatVisible)}
                                style={{
                                    position: 'absolute',
                                    left: '-20px', // Always on the left of the chat
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    backgroundColor: 'rgba(107, 189, 189, 0.7)',
                                    color: 'white',
                                    width: '20px',
                                    height: '50px',
                                    minWidth: '20px',
                                    borderRadius: '4px',
                                    zIndex: 1000,
                                    padding: 0,
                                    transition: 'all 0.2s ease-in-out',
                                    boxShadow: '-1px 0 2px rgba(0, 0, 0, 0.05)',
                                    border: '1px solid rgba(255, 255, 255, 0.1)',
                                    backdropFilter: 'blur(2px)',
                                    '&:hover': {
                                        backgroundColor: 'rgba(107, 189, 189, 0.9)',
                                        width: '22px',
                                        boxShadow: '-2px 0 4px rgba(0, 0, 0, 0.1)'
                                    },
                                }}
                            >
                                <ChevronRightIcon
                                    style={{
                                        fontSize: '18px',
                                    }}
                                />
                            </IconButton>
                        )}
                        <Chat
                            height={"100%"}
                            messages={messages}
                            setMessages={setMessages}
                            token={token}
                            user={user}
                            isPdf={true}
                        />
                    </Grid>
                )}

                {/* Add the button for when chat is not visible */}
                {!isChatVisible && !isSmallScreen && (
                    <IconButton
                        onClick={() => setIsChatVisible(true)}
                        style={{
                            position: 'fixed',
                            right: '0',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            backgroundColor: 'rgba(107, 189, 189, 0.7)',
                            color: 'white',
                            width: '20px',
                            height: '50px',
                            minWidth: '20px',
                            borderRadius: '4px',
                            zIndex: 1000,
                            padding: 0,
                            transition: 'all 0.2s ease-in-out',
                            boxShadow: '1px 0 2px rgba(0, 0, 0, 0.05)',
                            border: '1px solid rgba(255, 255, 255, 0.1)',
                            backdropFilter: 'blur(2px)',
                            '&:hover': {
                                backgroundColor: 'rgba(107, 189, 189, 0.9)',
                                width: '22px',
                                boxShadow: '2px 0 4px rgba(0, 0, 0, 0.1)'
                            },
                        }}
                    >
                        <ChevronLeftIcon
                            style={{
                                fontSize: '18px',
                            }}
                        />
                    </IconButton>
                )}
            </Container>


            {modalOpen && (
                <ModalFolder
                    open={true}
                    title={titleOption}
                    description={descriptionOption}
                    sendButton={buttonOption}
                    placeholdMessage={placeholderMessage}
                    type={typeModal}
                    onClose={() => {
                        setModalOpen(false); setOpenMenu(false); // Refresh folders list
                    }}
                    folder_id={folder.guid}
                />
            )
            }
            {
                modalOpenUpload && (
                    <ModalParent
                        open={modalOpenUpload}
                        title={titleOptionUpload}
                        description={descriptionOptionUpload}
                        sendButton={buttonOptionUpload}
                        placeholdMessage={placeholderMessageUpload}
                        type={typeUpload}
                        onClose={() => setModalOpenUpload(false)}
                        setShowSpinner={setShowSpinnerUpload}
                        setShowAlert={setShowAlertUpload}
                        setMessage={setMessageUpload}
                        setProgressBar={setProgressBar}
                        setShowUpgrade={setShowUpgrade}
                        width={width}
                        onExamCreated={handleExamCreated} // Pass the callback to handle exam creation
                        updateListDocuments={updateListDocuments}
                        setIsPreparingExam={setIsPreparingExam}
                        setFolderSelectedGuid={setFolderSelectedGuid}
                        setDocumentSelectedGuid={setDocumentSelectedGuid}


                    />
                )
            }

            {
                showPremium && (
                    <Premium
                        onClose={() => setShowPremium(false)}
                        titleText={premiumTitleText}
                    />
                )
            }
        </>
    );
}

export default DocumentStructure;