import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Typography,
  Box,
  Paper,
  Snackbar,
  Alert
} from '@mui/material';
import { Close } from '@mui/icons-material';

const EditFlashcardPopup = ({ open, onClose, flashcard, onSave }) => {
  const [editedFlashcard, setEditedFlashcard] = useState({
    question: '',
    answer: '',
    id: null,
    type: 'question',
    appearances: 1,
    difficulty: 'normal'
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  useEffect(() => {
    if (flashcard) {
      // If the flashcard has an 'answer' property, use it directly
      // Otherwise, reconstruct it from the text property using the answers array
      let answerText = flashcard.answer;
      if (!answerText && flashcard.text && flashcard.answers) {
        answerText = flashcard.text;
        // Replace each answer with its bracketed version
        flashcard.answers.forEach(answer => {
          answerText = answerText.replace(answer, `[${answer}]`);
        });
      }

      setEditedFlashcard({
        question: flashcard.question || '',
        answer: answerText || '',
        id: flashcard.id || flashcard.guid,
        type: flashcard.type || 'question',
        appearances: flashcard.appearances || 1,
        difficulty: flashcard.difficulty || 'normal'
      });
    }
  }, [flashcard]);

  const hasSquareBrackets = (text) => /\[.*?\]/.test(text);
  const isFormValid = editedFlashcard.question.trim() !== '' && 
                     editedFlashcard.answer.trim() !== '' && 
                     hasSquareBrackets(editedFlashcard.answer);

  const handleSave = () => {
    if (editedFlashcard.id && isFormValid) {
      // Extract answers from the edited text (words in square brackets)
      const answers = [];
      const regex = /\[(.*?)\]/g;
      let match;
      
      while ((match = regex.exec(editedFlashcard.answer)) !== null) {
        answers.push(match[1]);
      }

      // Create a clone of the card with reset state
      const updatedFlashcard = {
        ...editedFlashcard,
        answers,
        appearances: 1, // Reset appearances to 1
        currentClozeIndex: 0,
        answeredCorrectly: [],
        revealed: false,
        attempts: 0, // Reset attempts
        difficulty: 'sin_responder', // Set as unanswered
        scheduledForRepetition: false // Not scheduled for repetition
      };

      // Create a duplicate card for the end of the array with the same reset state
      const cardToMoveToEnd = {
        ...updatedFlashcard,
        attempts: 0,
        currentClozeIndex: 0,
        answeredCorrectly: [],
        revealed: false,
        appearances: 1
      };

      // Pass both the updated card and the duplicate to be added at the end
      onSave({
        updatedCard: updatedFlashcard,
        cardToAddAtEnd: cardToMoveToEnd
      });

      setSnackbarOpen(true);
      setTimeout(() => {
        onClose();
      }, 1000);
    }
  };

  const handleClose = () => {
    // Reset the editedFlashcard to match the original flashcard data
    if (flashcard) {
      let answerText = flashcard.answer;
      if (!answerText && flashcard.text && flashcard.answers) {
        answerText = flashcard.text;
        flashcard.answers.forEach(answer => {
          answerText = answerText.replace(answer, `[${answer}]`);
        });
      }

      setEditedFlashcard({
        question: flashcard.question || '',
        answer: answerText || '',
        id: flashcard.id || flashcard.guid,
        type: flashcard.type || 'question',
        appearances: flashcard.appearances || 1,
        difficulty: flashcard.difficulty || 'normal'
      });
    }
    onClose();
  };

  const renderPreview = () => {
    if (!editedFlashcard.answer) return null;

    const parts = editedFlashcard.answer.split(/(\[.*?\])/g);
    return (
      <Box sx={{ mt: 3 }}>
        <Typography
          variant="subtitle2"
          sx={{
            color: '#666',
            fontFamily: 'Fira Sans',
            mb: 1
          }}
        >
          Vista previa:
        </Typography>
        <Paper
          elevation={0}
          sx={{
            p: 2,
            backgroundColor: '#F8F9FA',
            borderRadius: '12px',
            border: '1px solid #E0E0E0'
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              fontFamily: "'Inter', sans-serif",
              fontSize: '18px',
              lineHeight: 1.6,
              color: '#2D2D2D'
            }}
          >
            {parts.map((part, index) => {
              if (part.startsWith('[') && part.endsWith(']')) {
                // Get the content without brackets and split into words
                const content = part.slice(1, -1);
                const words = content.split(' ');
                
                return (
                  <React.Fragment key={index}>
                    {words.map((word, wordIndex) => (
                      <React.Fragment key={`${index}-${wordIndex}`}>
                        <Typography
                          component="span"
                          sx={{
                            backgroundColor: '#E2F1F1',
                            padding: '0 8px',
                            borderRadius: '4px'
                          }}
                        >
                          {'_'.repeat(word.length)}
                        </Typography>
                        {wordIndex < words.length - 1 && ' '}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                );
              }
              return part;
            })}
          </Typography>
        </Paper>
      </Box>
    );
  };

  return (
    <>
      <Dialog 
        open={open} 
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: '24px',
            width: '100%',
            maxWidth: '600px',
            m: 2,
            height: 'auto',
            maxHeight: '90vh',
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <DialogTitle sx={{ 
          p: 2, 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center'
        }}>
          <Typography variant="h6" sx={{ 
            fontSize: '18px',
            fontWeight: 600,
            fontFamily: 'Fira Sans'
          }}>
            Editar tarjeta
          </Typography>
          <IconButton onClick={handleClose} size="small">
            <Close fontSize="small" />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ 
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          flex: 1
        }}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            marginTop: '10px',
            gap: 3,
            width: '100%'
          }}>
            <TextField
              fullWidth
              label="Pregunta"
              value={editedFlashcard.question}
              onChange={(e) => setEditedFlashcard({ ...editedFlashcard, question: e.target.value })}
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  fontFamily: 'Fira Sans',
                },
                '& .MuiInputLabel-root': {
                  fontFamily: 'Fira Sans',
                }
              }}
            />
            <TextField
              fullWidth
              label="Respuesta"
              value={editedFlashcard.answer}
              onChange={(e) => setEditedFlashcard({ ...editedFlashcard, answer: e.target.value })}
              variant="outlined"
              multiline
              rows={4}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  fontFamily: 'Fira Sans',
                },
                '& .MuiInputLabel-root': {
                  fontFamily: 'Fira Sans',
                }
              }}
            />
            <Typography
              variant="caption"
              sx={{
                color: '#666',
                fontFamily: 'Fira Sans',
                mt: -2
              }}
            >
              Para ocultar una palabra/s debes ponerlas entre []. Debe haber al menos una palabra oculta.
            </Typography>

            {renderPreview()}
          </Box>
        </DialogContent>

        <DialogActions sx={{ paddingBottom: 2, paddingRight: 1, paddingLeft: 1 }}>
          <Button 
            onClick={handleClose}
            sx={{
              color: '#666',
              textTransform: 'none',
              fontFamily: 'Fira Sans',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#333'
              }
            }}
          >
            Cancelar
          </Button>
          <Button 
            onClick={handleSave}
            variant="contained"
            disabled={!isFormValid}
            sx={{
              bgcolor: '#6BBDBD',
              color: 'white',
              textTransform: 'none',
              fontFamily: 'Fira Sans',
              borderRadius: '20px',
              px: 3,
              '&:hover': {
                bgcolor: '#5AACAC'
              },
              '&.Mui-disabled': {
                bgcolor: '#E0E0E0',
                color: '#A0A0A0'
              }
            }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbarOpen(false)} 
          severity="success"
          sx={{ 
            width: '100%',
            fontFamily: "'Fira Sans', sans-serif",
            backgroundColor: '#6BBDBD',
            color: 'white',
            '& .MuiAlert-icon': {
              color: 'white'
            }
          }}
        >
          Flashcard actualizada
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditFlashcardPopup; 