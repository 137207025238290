import React, { useState, useEffect } from 'react';
import { 
  Box, 
  TextField, 
  Button, 
  Typography, 
  CircularProgress, 
  Checkbox, 
  FormControlLabel, 
  Tooltip, 
  Tabs, 
  Tab, 
  Paper, 
  Divider,
  Card,
  CardContent,
  IconButton,
  alpha
} from '@mui/material';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { fetchUserFolders } from '../Api/FolderApi';
import { fetchSimulacroQuestions, fetchSimulacroExams } from '../Api/SimulacroApi';
import { useAuth } from "../Auth/Authhandle";

const SimulacroConfiguration = () => {
  const { token, user, isAuthenticated } = useAuth();
  const [examConfig, setExamConfig] = useState({
    correctPoints: 1,
    incorrectPoints: -0.25,
    questionCount: 10,
    timeLimit: 30,
    unansweredDeducts: false
  });
  const navigate = useNavigate();
  const [showHowItWorks, setShowHowItWorks] = useState(true);
  const [selectFolders, setSelectFolders] = useState(false);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [evaluationMode, setEvaluationMode] = useState('test');
  const [availableTests, setAvailableTests] = useState([]);
  const [selectedTest, setSelectedTest] = useState('');
  const [loadingTests, setLoadingTests] = useState(false);
  const [folders, setFolders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);

  // Theme colors
  const themeColor = '#6BBDBD';
  const themeLightColor = alpha('#6BBDBD', 0.1);
  const themeDarkColor = '#5AACAC';
  const neutralColor = '#666666';
  const neutralLightColor = '#f9f9f9';
  const neutralDarkColor = '#333333';

  useEffect(() => {
    const fetchFolders = async () => {
      if (token && user) {
        try {
          const fetchedFolders = await fetchUserFolders(token, "GET", null, user);
          setFolders(fetchedFolders);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching folders:", error);
          setIsLoading(false);
        }
      }
    };

    fetchFolders();
  }, [token, user]);

  useEffect(() => {
    const fetchTests = async () => {
      if (evaluationMode === 'test' && token && user) {
        try {
          setLoadingTests(true);
          // Mock data for now

          const fetchedTests = await fetchSimulacroExams(token, user);
          setAvailableTests(fetchedTests);
          setLoadingTests(false);
        } catch (error) {
          console.error("Error fetching tests:", error);
          setLoadingTests(false);
        }
      }
    };

    fetchTests();
  }, [evaluationMode, token, user]);

  const handleFolderSelect = (folderId) => {
    if (folderId === 'Todas') {
      setSelectAll(!selectAll);
      setSelectedFolders(selectAll ? [] : ['Todas']);
    } else {
      setSelectAll(false);
      setSelectedFolders(prev => {
        if (prev.includes('Todas')) {
          return [folderId];
        }
        if (prev.includes(folderId)) {
          return prev.filter(f => f !== folderId);
        }
        return [...prev, folderId];
      });
    }
  };

  const handleEvaluationModeChange = (event, newValue) => {
    setEvaluationMode(newValue);
    if (newValue === 'folders') {
      setSelectedTest('');
    } else {
      setSelectedFolders([]);
      setSelectAll(false);
    }
  };

  const handleTestSelect = (testId) => {
    setSelectedTest(prevSelected => prevSelected === testId ? prevSelected : testId);
  };

  const handleChange = (field) => (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setExamConfig(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const validateConfig = () => {
    const commonValidation = 
      examConfig.correctPoints > 0 &&
      examConfig.incorrectPoints <= 0 &&
      examConfig.questionCount > 0 &&
      examConfig.timeLimit > 0;
    
    if (evaluationMode === 'folders') {
      return commonValidation && (selectedFolders.length > 0 || selectAll);
    } else {
      return commonValidation && selectedTest !== '';
    }
  };

  const textFieldStyle = {
    '& .MuiOutlinedInput-root': {
      fontFamily: "'Inter', sans-serif",
      fontSize: '14px',
      backgroundColor: '#fff',
      '& fieldset': {
        borderColor: '#E0E0E0',
      },
      '&:hover fieldset': {
        borderColor: neutralDarkColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: themeColor,
      },
    },
    '& .MuiInputLabel-root': {
      fontFamily: "'Inter', sans-serif",
      fontSize: '14px',
      color: neutralColor,
      '&.Mui-focused': {
        color: themeColor,
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '12px 14px',
    },
  };

  const handleStartExam = async () => {
    if (validateConfig()) {
      try {
        setIsLoadingQuestions(true);
        
        let requestBody = {
          num_questions: examConfig.questionCount,
          correct_points: examConfig.correctPoints,
          incorrect_points: examConfig.incorrectPoints,
          time_limit: examConfig.timeLimit,
          unanswered_deducts: examConfig.unansweredDeducts,
          evaluation_mode: evaluationMode
        };
        
        let selectedFolderData = [];
        
        if (evaluationMode === 'folders') {
          selectedFolderData = selectAll 
            ? folders 
            : folders.filter(folder => selectedFolders.includes(folder.guid));
          
          requestBody.selected_folders = selectAll 
            ? folders.map(f => f.guid) 
            : selectedFolders;
        } else {
          requestBody.exam_chosen = selectedTest;
        }
        
        const response = await fetchSimulacroQuestions(token, requestBody, user);
        
        navigate(`/simulacro/${response.exam_guid}`, { 
          state: { 
            examConfig, 
            questions: response.questions,
            selectedFolders: selectedFolderData,
            evaluationMode,
            selectedTest: evaluationMode === 'test' ? selectedTest : null,
            exam_id: response.exam_guid
          } 
        });
      } catch (error) {
        console.error('Error fetching questions:', error);
      } finally {
        setIsLoadingQuestions(false);
      }
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      maxWidth: '800px',
      margin: '0 auto',
      padding: { xs: '16px', sm: '24px 32px' },
      fontFamily: "'Inter', sans-serif",
    }}>
      <Box sx={{ textAlign: 'center', mb: 1 }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
            color: neutralDarkColor,
            mb: 1,
            fontFamily: "'Fira Sans', sans-serif",
          }}
        >
          Simulacro de Examen
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: neutralColor,
            fontFamily: "'Inter', sans-serif",
            fontWeight: 400
          }}
        >
          Ponte a prueba en condiciones de examen
        </Typography>
      </Box>

      {showHowItWorks && (
        <Box sx={{
          backgroundColor: themeLightColor,
          borderRadius: '10px',
          padding: '16px',
          border: `1px solid ${alpha(themeColor, 0.3)}`,
          mb: 2
        }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 1
          }}>
            <Typography
              variant="subtitle1"
              sx={{
                color: neutralDarkColor,
                fontSize: '15px',
                fontFamily: "'Inter', sans-serif",
                fontWeight: 600,
              }}
            >
              ¿Cómo funciona?
            </Typography>
            <IconButton 
              onClick={() => setShowHowItWorks(false)}
              size="small"
              sx={{ color: neutralColor }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <Typography
            variant="body2"
            sx={{
              color: neutralDarkColor,
              fontSize: '14px',
              fontFamily: "'Inter', sans-serif",
              lineHeight: 1.5
            }}
          >
            Typed selecciona aleatoriamente preguntas de los documentos que has subido a la plataforma para generar tu simulacro.
          </Typography>
        </Box>
      )}

      <Tabs 
        value={evaluationMode} 
        onChange={handleEvaluationModeChange}
        variant="fullWidth"
        sx={{ 
          mb: 3, 
          '.MuiTab-root': { 
            textTransform: 'none',
            fontFamily: "'Inter', sans-serif",
            fontSize: '15px',
            fontWeight: 500,
            minHeight: '56px',
            py: 0.5,
          },
          '.Mui-selected': {
            color: themeColor,
          },
          '.MuiTabs-indicator': {
            backgroundColor: themeColor,
            height: '3px',
            borderRadius: '3px 3px 0 0',
          }
        }}
      >
        <Tab 
          icon={<AssignmentOutlinedIcon />} 
          iconPosition="start"
          label={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography>Evaluar test</Typography>
              <Tooltip title="Evalúa un test específico subido anteriormente" arrow placement="top">
                <InfoOutlinedIcon 
                  fontSize="small" 
                  sx={{ ml: 0.5, color: 'inherit', fontSize: '16px' }} 
                />
              </Tooltip>
            </Box>
          } 
          value="test" 
        />
        <Tab 
          icon={<FolderOutlinedIcon />} 
          iconPosition="start"
          label={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography>Evaluar carpetas</Typography>
              <Tooltip title="Genera un simulacro con preguntas aleatorias de las carpetas que selecciones" arrow placement="top">
                <InfoOutlinedIcon 
                  fontSize="small" 
                  sx={{ ml: 0.5, color: 'inherit', fontSize: '16px' }} 
                />
              </Tooltip>
            </Box>
          } 
          value="folders" 
        />
      </Tabs>

      <Box sx={{ px: { xs: 0, sm: 2 } }}>
        {evaluationMode === 'folders' ? (
          <Box>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "'Inter', sans-serif",
                fontSize: '16px',
                color: neutralDarkColor,
                fontWeight: 600,
                mb: 2,
              }}
            >
              Elige las carpetas a evaluar
            </Typography>
            
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAll}
                  onChange={() => handleFolderSelect('Todas')}
                  sx={{
                    color: neutralColor,
                    '&.Mui-checked': {
                      color: themeColor,
                    },
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    fontFamily: "'Inter', sans-serif",
                    fontSize: '15px',
                    fontWeight: 500,
                    color: neutralDarkColor,
                  }}
                >
                  Todas las carpetas
                </Typography>
              }
            />
            
            {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                <CircularProgress size={24} sx={{ color: themeColor }} />
              </Box>
            ) : (
              <Box sx={{ 
                display: 'grid',
                gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: 'repeat(3, 1fr)' },
                gap: '8px',
                pl: 1,
                mt: 1
              }}>
                {folders.length > 0 ? (
                  folders.map((folder) => (
                    <FormControlLabel
                      key={folder.guid}
                      control={
                        <Checkbox
                          checked={selectedFolders.includes(folder.guid)}
                          onChange={() => handleFolderSelect(folder.guid)}
                          disabled={selectAll}
                          size="small"
                          sx={{
                            color: neutralColor,
                            '&.Mui-checked': {
                              color: themeColor,
                            },
                          }}
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            fontFamily: "'Inter', sans-serif",
                            fontSize: '14px',
                            color: selectAll ? alpha(neutralColor, 0.7) : neutralColor,
                            fontWeight: selectedFolders.includes(folder.guid) ? 500 : 400,
                          }}
                        >
                          {folder.name}
                        </Typography>
                      }
                    />
                  ))
                ) : (
                  <Box sx={{ 
                    gridColumn: '1 / -1', 
                    backgroundColor: neutralLightColor,
                    borderRadius: '8px',
                    p: 3,
                    textAlign: 'center',
                    border: '1px dashed #E0E0E0',
                    ml: -1
                  }}>
                    <Typography
                      sx={{
                        fontFamily: "'Inter', sans-serif",
                        fontSize: '15px',
                        color: neutralDarkColor,
                        fontWeight: 500,
                        mb: 1
                      }}
                    >
                      No hay carpetas disponibles
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "'Inter', sans-serif",
                        fontSize: '14px',
                        color: neutralColor,
                      }}
                    >
                      No se han encontrado carpetas para evaluar. Por favor, crea alguna carpeta con documentos antes de continuar.
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        ) : (
          <Box>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "'Inter', sans-serif",
                fontSize: '16px',
                color: neutralDarkColor,
                fontWeight: 600,
                mb: 2,
              }}
            >
              Elige un test para evaluar
            </Typography>
            
            {loadingTests ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                <CircularProgress size={24} sx={{ color: themeColor }} />
              </Box>
            ) : (
              <Box sx={{ 
                display: 'grid', 
                gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' }, 
                gap: 2 
              }}>
                {availableTests.length > 0 ? (
                  availableTests.map((test) => (
                    <Box
                      key={test.id}
                      onClick={() => handleTestSelect(test.id)}
                      sx={{
                        p: 2,
                        borderRadius: '8px',
                        cursor: 'pointer',
                        transition: 'all 0.2s',
                        border: `1px solid ${selectedTest === test.id ? themeColor : 'transparent'}`,
                        backgroundColor: selectedTest === test.id ? themeLightColor : neutralLightColor,
                        '&:hover': {
                          backgroundColor: selectedTest === test.id ? themeLightColor : alpha(neutralLightColor, 0.7),
                          boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                        },
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <Checkbox 
                        checked={selectedTest === test.id}
                        sx={{
                          p: 0.5,
                          color: neutralColor,
                          '&.Mui-checked': {
                            color: themeColor,
                          },
                        }}
                      />
                      <Typography
                        sx={{
                          fontFamily: "'Inter', sans-serif",
                          fontSize: '14px',
                          color: selectedTest === test.id ? neutralDarkColor : neutralColor,
                          fontWeight: selectedTest === test.id ? 500 : 400,
                        }}
                      >
                        {test.name}
                      </Typography>
                    </Box>
                  ))
                ) : (
                  <Box sx={{ 
                    gridColumn: '1 / -1', 
                    backgroundColor: neutralLightColor,
                    borderRadius: '8px',
                    p: 3,
                    textAlign: 'center',
                    border: '1px dashed #E0E0E0'
                  }}>
                    <Typography
                      sx={{
                        fontFamily: "'Inter', sans-serif",
                        fontSize: '15px',
                        color: neutralDarkColor,
                        fontWeight: 500,
                        mb: 1
                      }}
                    >
                      No hay tests disponibles
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "'Inter', sans-serif",
                        fontSize: '14px',
                        color: neutralColor,
                      }}
                    >
                      No se han encontrado tests para evaluar. Por favor, sube algún test o selecciona el modo de evaluación por carpetas.
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>

      <Divider sx={{ my: 2 }} />

      <Box>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "'Inter', sans-serif",
            fontSize: '16px',
            color: neutralDarkColor,
            fontWeight: 600,
            mb: 3,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <SettingsOutlinedIcon sx={{ color: themeColor, fontSize: '20px' }} />
          Configura tu simulacro
        </Typography>

        <Box sx={{ 
          display: 'grid', 
          gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)' },
          gap: '20px',
          width: '100%',
          mb: 3
        }}>
          {/* <TextField
            label="Puntos por respuesta correcta"
            type="number"
            value={examConfig.correctPoints}
            onChange={handleChange('correctPoints')}
            inputProps={{ 
              step: '0.01',
              min: 0,
              max: 10
            }}
            sx={textFieldStyle}
            fullWidth
          /> */}
          
          <TextField
            label="Puntos por respuesta incorrecta"
            type="number"
            value={examConfig.incorrectPoints}
            onChange={handleChange('incorrectPoints')}
            inputProps={{ 
              step: '0.01',
              min: -10,
              max: 0
            }}
            sx={textFieldStyle}
            fullWidth
          />
          
          <TextField
            label="Número de preguntas"
            type="number"
            value={examConfig.questionCount}
            onChange={handleChange('questionCount')}
            inputProps={{ 
              min: 1,
              max: 50
            }}
            sx={textFieldStyle}
            fullWidth
            disabled={evaluationMode != 'folders'}
          />
          
          <TextField
            label="Tiempo límite (minutos)"
            type="number"
            value={examConfig.timeLimit}
            onChange={handleChange('timeLimit')}
            InputProps={{
              startAdornment: (
                <TimerOutlinedIcon sx={{ color: neutralColor, mr: 1 }} />
              ),
            }}
            inputProps={{ 
              min: 1,
              max: 180
            }}
            sx={textFieldStyle}
            fullWidth
          />
        </Box>

        <FormControlLabel
          control={
            <Checkbox
              checked={examConfig.unansweredDeducts}
              onChange={handleChange('unansweredDeducts')}
              sx={{
                color: neutralColor,
                '&.Mui-checked': {
                  color: themeColor,
                },
              }}
            />
          }
          label={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
              <Typography
                sx={{
                  fontFamily: "'Inter', sans-serif",
                  fontSize: '14px',
                  color: neutralColor,
                }}
              >
                Preguntas sin responder restan
              </Typography>
              <Tooltip title="Si está activado, las preguntas sin responder restarán puntos igual que las incorrectas" arrow placement="top">
                <InfoOutlinedIcon 
                  sx={{ 
                    color: themeColor,
                    fontSize: '15px',
                    cursor: 'help'
                  }} 
                />
              </Tooltip>
            </Box>
          }
        />
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Button
          variant="contained"
          onClick={handleStartExam}
          disabled={!validateConfig() || isLoadingQuestions}
          sx={{
            backgroundColor: themeColor,
            color: 'white',
            borderRadius: '28px',
            textTransform: 'none',
            padding: '10px 32px',
            fontFamily: "'Inter', sans-serif",
            fontWeight: 600,
            fontSize: '15px',
            minWidth: '200px',
            maxWidth: '300px',
            width: '100%',
            boxShadow: `0 4px 12px ${alpha(themeColor, 0.3)}`,
            '&:hover': {
              backgroundColor: themeDarkColor,
              boxShadow: `0 6px 16px ${alpha(themeColor, 0.4)}`,
            },
            '&:disabled': {
              backgroundColor: '#E0E0E0',
              color: '#FFFFFF',
            }
          }}
        >
          {isLoadingQuestions ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CircularProgress size={20} sx={{ color: '#FFFFFF' }} />
              <span>Preparando examen...</span>
            </Box>
          ) : (
            'Empezar Simulacro'
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default SimulacroConfiguration; 