import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  Container,
  Alert,
  CircularProgress
} from '@mui/material';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { MailOutline, CheckCircleOutline, Refresh } from '@mui/icons-material';

export default function EmailVerifyCard() {
  const supabase = useSupabaseClient();
  const [resendLoading, setResendLoading] = useState(false);
  const [resendSuccess, setResendSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleResendEmail = async () => {
    setResendLoading(true);
    setError(null);
    try {
      const { error } = await supabase.auth.resend({
        type: 'signup'
      });
      if (error) throw error;
      setResendSuccess(true);
    } catch (error) {
      setError(error.message);
    } finally {
      setResendLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Paper elevation={3} sx={{
        p: 4,
        width: '100%',
        maxWidth: 500,
        textAlign: 'center',
        borderRadius: 2,
        backgroundColor: '#ffffff'
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 3
        }}>
          {/* Icon */}
          <Box sx={{
            width: 80,
            height: 80,
            borderRadius: '50%',
            backgroundColor: '#e3f2fd',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 2
          }}>
            <MailOutline sx={{ fontSize: 40, color: '#026277' }} />
          </Box>

          {/* Title */}
          <Typography variant="h4" component="h1" sx={{ 
            color: '#026277',
            fontWeight: 600,
            mb: 1
          }}>
            Thank You for Registering!
          </Typography>

          {/* Subtitle */}
          <Typography variant="h6" sx={{ 
            color: '#666',
            mb: 3,
            maxWidth: '80%',
            lineHeight: 1.6
          }}>
            Please check your email to verify your account and start using TypedAI
          </Typography>

          {/* Success or Error Messages */}
          {resendSuccess && (
            <Alert 
              icon={<CheckCircleOutline />}
              severity="success"
              sx={{ width: '100%', mb: 2 }}
            >
              Verification email has been resent successfully!
            </Alert>
          )}
          
          {error && (
            <Alert 
              severity="error"
              sx={{ width: '100%', mb: 2 }}
            >
              {error}
            </Alert>
          )}

          {/* Resend Button */}
          <Button
            variant="outlined"
            startIcon={resendLoading ? <CircularProgress size={20} /> : <Refresh />}
            onClick={handleResendEmail}
            disabled={resendLoading || resendSuccess}
            sx={{
              mt: 2,
              py: 1.5,
              px: 4,
              borderColor: '#026277',
              color: '#026277',
              '&:hover': {
                borderColor: '#015666',
                backgroundColor: 'rgba(2, 98, 119, 0.04)'
              }
            }}
          >
            {resendLoading ? 'Sending...' : 'Resend Verification Email'}
          </Button>

          {/* Additional Info */}
          <Typography variant="body2" sx={{ 
            color: '#666',
            mt: 3
          }}>
            Didn't receive the email? Check your spam folder or click the button above to resend.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
}
