import React, { useState, useEffect } from "react";
import { Button, TextField, Typography, Box, IconButton } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ClipLoader } from "react-spinners";
import { useNavigate } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import DragAndDrop from "../../NewComponents/Upload/UploadFileFolder";
import { fetchUserFolder, fetchUserFolders } from "../../Api/FolderApi";
import { fetchUserFiles } from "../../Api/FilesApi";

const useStyles = makeStyles((theme) => ({

    buttonUpload: {
        backgroundColor: "#026277",
        color: "white",
        textTransform: "none",
        borderRadius: "6px",
        fontSize: "10px",
        display: "flex",
        alignItems: "flex-end",
        gap: "2px",
        width: "80px",

        padding: "6px 12px", // Added padding for better appearance
        '&:disabled': {
            backgroundColor: '#B0B0B0', // Gray color for the disabled state
            color: '#FFFFFF'           // White text for the disabled state
        },
        '&:hover': {
            backgroundColor: '#026277', // Gray color for the disabled state
            color: "white",


        }

    },
    textField: {
        marginRight: '10px',
        width: '45%',
        '& .MuiOutlinedInput-root': {
            '& .MuiInputBase-input': {
                padding: '8px 10px',
                fontSize: '10px',
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.23)',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0, 0, 0, 0.87)',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.main,
            },
        },
        '& .MuiFormLabel-root': {
            fontSize: '10px',
        },
    },

    text: {
        fontSize: "10px",
    },
    textTitle: {
        fontSize: "12px",
        fontWeight: "bold",
        width: '45%',

    },
    addSubject: {
        backgroundColor: "white",
        color: "black",
        textTransform: "none",
        borderRadius: "6px",
        fontSize: "10px",
        display: "flex",
        alignItems: "flex-end",
        gap: "2px",
        width: "120px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        padding: "6px 12px", // Added padding for better appearance
    },
    eliminarSubject: {
        backgroundColor: "red",
        color: "white",
        textTransform: "none",
        borderRadius: "6px",
        fontSize: "10px",
        display: "flex",
        alignItems: "flex-end",
        gap: "2px",
        width: "120px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        padding: "6px 12px", // Added padding for better appearance
        '&:hover': {
            backgroundColor: "white",
            color: "red",
        }
    },
    datePicker: {
        "& .css-i4bv87-MuiSvgIcon-root": {
            color: "#026277"
        },
        marginRight: "10px"

    },
    subjectsContainer: {
        maxHeight: '200px', // Adjust the height as needed
        overflowY: 'auto',
    },
    subjectsContainer2: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        overflow: 'hidden',
        width: '100%',
    },
    subjectButton: {
        border: '1px solid #D9D9D9',
        borderRadius: '12px',
        backgroundColor: '#FFFDFD',
        padding: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),

        minWidth: '100px',
        textAlign: 'center',
        margin: theme.spacing(1),
        '&:hover': {
            borderColor: theme.palette.primary.main,
            cursor: 'pointer',
        },
        fontSize: "10px"
    },
    selectedSubjectButton: {
        borderColor: '#026277',
        backgroundColor: '#E2F1F1',
    },
    arrowButton: {
        padding: theme.spacing(1),
    },
    arrowIcon: {
        fontSize: '1.0rem', // Adjust the size of the arrow icons
    },
    deleteIcon: {
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.4)', // Scale the icon on hover
            transition: 'transform 0.2s ease-in-out', // Smooth transition
            borderRadius: '50%',
        },
    },
}));

function WelcomeTyped({ onClose, token, user, step, setStep, onFolderCreated, setCredits }) {
    const classes = useStyles();
    const [subjects, setSubjects] = useState([{ name: '', examDate: null }]);
    const [selectedSubject, setSelectedSubject] = useState({});
    const [files, setFiles] = useState([]);
    const [visibleIndex, setVisibleIndex] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true)
    const [dbSubjects, setDbSubjects] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingFolders, setIsFetchingFolders] = useState(false);
    const [pages, setPages] = useState("")
    const navigate = useNavigate();
    useEffect(() => {
        let creditsNeeded = Math.max(1, Math.round(pages * 0.375));

        setCredits(creditsNeeded);
    }, [pages]);
    const { t } = useTranslation();
    const isValidName = (name) => {
        const regex = /^(?=(?:.*[a-zA-Z]){2}).*$/;
        return regex.test(name.trim());
    };


    const handleAddSubject = () => {
        setSubjects([{ name: '', examDate: null }, ...subjects]);
    };

    const handleSubjectChange = (index, field, value) => {
        const newSubjects = [...subjects];
        newSubjects[index][field] = value;
        setSubjects(newSubjects);
    };
    const handleNext = async () => {
        setIsLoading(true); // Start loading

        // Filter subjects to only include those with non-empty names
        const filteredSubjects = subjects.filter(subject => subject.name.trim() !== "");

        try {
            // Create all folders
            setIsFetchingFolders(false); // Start fetching folders

            const dbSubjectsResponse = await fetchUserFolders(token, "POST", {
                list_folders: filteredSubjects, // Pass the filtered list here
            }, user);


            setDbSubjects(dbSubjectsResponse); // Save the response in the state
            setIsLoading(false); // Start loading
            setIsFetchingFolders(false); // Finish fetching folders
            if (onFolderCreated) {
                onFolderCreated(); // Invoke the callback to refresh the list of exams
            }
            let folder_navigate = dbSubjectsResponse[0].guid
            navigate(`/${folder_navigate}`)
            onClose()
        } catch (error) {
            console.error('Error fetching folders:', error);
        }
    };
    const handleEnd = async () => {
        // Filter subjects to only include those with non-empty names
        setStep(3); // Transition to step 3 before starting the async operation

        try {
            // Create all folders
            // setIsLoading(true); // Start loading

            const formData = new FormData();
            let filesToUpload;
            if (files && files.length > 0) {

                filesToUpload = files.map((fileItem) => fileItem.file); // Extracting File objects
                // setMessage("El archivo se está procesando. Te avisaremos cuando esté");

                // Function to load metadata for a single file and append to FormData
                const loadMetadata = (file, index) => {
                    return new Promise((resolve) => {
                        formData.append(`file_${index}`, file);
                        formData.append(`file_${index}_size`, file.size);
                        formData.append(`file_${index}_duration`, 0);

                        resolve();
                        // }
                    });
                };


                // Wait for all files to have their metadata loaded
                const metadataPromises = filesToUpload.map((file, index) => loadMetadata(file, index));
                await Promise.all(metadataPromises);
                const fetchFolders = await fetchUserFiles(token, "POST", selectedSubject.guid, formData, user);
                navigate(`/${selectedSubject.guid}/${fetchFolders.documents[0].document_id}`)
            } else {
                navigate(`/${selectedSubject.guid}`)
            }
            // setIsLoading(false); // Start loading
            onClose();
        } catch (error) {
            console.error('Error fetching folders:', error);
        }
    };

    const handleSubjectClick = (subject) => {
        setSelectedSubject(subject);
    };

    useEffect(() => {
        if (dbSubjects.length > 0) {
            setSelectedSubject(dbSubjects[0]);
        }
    }, [dbSubjects]);

    const handlePrevClick = () => {
        setVisibleIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    };

    const handleNextClick = () => {
        setVisibleIndex((prevIndex) => Math.min(prevIndex + 1, subjects.length - 4));
    };
    const handleDeleteSubject = (idx) => {
        if (subjects.length > 1) {
            const newSubjects = subjects.filter((_, i) => i !== idx);
            setSubjects(newSubjects);
        }
    };
    useEffect(() => {
        const areAllNamesEmpty = subjects.every(subject => !isValidName(subject.name));
        setIsButtonDisabled(areAllNamesEmpty);
    }, [subjects]);


    return (
        <>
            {step === 1 && (
                <div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Box>

                            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                                <Typography className={classes.textTitle} style={{ width: '90%', textAlign: 'left' }}>
                                    {t("name")}
                                </Typography>

                                <Typography className={classes.textTitle} style={{ width: '10%', textAlign: 'left' }}>
                                </Typography>
                            </Box>
                            <Box className={`${classes.subjectsContainer} `}>
                                {subjects.map((subject, index) => (
                                    <Box key={index} display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                                        <TextField
                                            value={subject.name}
                                            onChange={(e) => handleSubjectChange(index, 'name', e.target.value)}
                                            variant="outlined"
                                            placeholder={t("name")}
                                            style={{ marginRight: '10px', width: '100%' }}
                                            InputProps={{
                                                disableUnderline: true,
                                                style: { fontSize: "10px" }
                                            }}
                                            InputLabelProps={{
                                                style: { fontSize: "10px" }
                                            }}
                                            className={classes.textField}
                                        />


                                        <div style={{ width: '10%', display: 'flex', justifyContent: 'center' }}>
                                            {subjects.length > 1 && (
                                                <svg
                                                    className={classes.deleteIcon}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    onClick={() => handleDeleteSubject(index)}
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M9 2C8.62123 2 8.27497 2.214 8.10557 2.55279L7.38197 4H4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6L4 16C4 17.1046 4.89543 18 6 18H14C15.1046 18 16 17.1046 16 16V6C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4H12.618L11.8944 2.55279C11.725 2.214 11.3788 2 11 2H9ZM7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8V14C9 14.5523 8.55228 15 8 15C7.44772 15 7 14.5523 7 14V8ZM12 7C11.4477 7 11 7.44772 11 8V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V8C13 7.44772 12.5523 7 12 7Z"
                                                        fill="#111827"
                                                    />
                                                </svg>
                                            )}
                                        </div>
                                    </Box>

                                ))}
                                <Typography style={{ fontSize: 10, color: "gray" }}>{t("max_characters")}</Typography>
                            </Box>
                            {subjects.length < 10 && (

                                <Box display="flex" justifyContent="center" marginTop="16px" style={{ gap: "10px" }}>

                                    <Button onClick={handleAddSubject} className={classes.addSubject}>
                                        {t("add_subject")}
                                    </Button>


                                </Box>
                            )}

                        </Box>
                    </LocalizationProvider>
                    <Box display="flex" justifyContent="flex-end" marginTop="10px">
                        <Button disabled={isButtonDisabled || isLoading}
                            className={classes.buttonUpload} onClick={handleNext}> {isLoading ? <ClipLoader size={12} color={"#026277"} /> : t("!start!")}</Button>
                    </Box>
                </div>
            )}
            {step === 2 && (
                <div>


                    <Typography className={classes.textTitle} style={{ marginTop: "10px" }}>{t("choose_subject")}</Typography>
                    <Box className={classes.subjectsContainer2}>
                        {visibleIndex > 0 && (
                            <IconButton className={classes.arrowButton} onClick={handlePrevClick}>
                                <ArrowBackIosIcon className={classes.arrowIcon} />
                            </IconButton>
                        )}
                        {isFetchingFolders ? (
                            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                <Typography style={{ fontSize: "12px", marginRight: "10px" }}>{t("creating_folders")}</Typography><ClipLoader size={20} color={"#026277"} />
                            </Box>
                        ) : (
                            <Box display="flex" justifyContent="center" alignItems="center" overflow="hidden" flex="1">
                                {dbSubjects.slice(visibleIndex, visibleIndex + 4).map((subject) => (
                                    <Box
                                        key={subject.name}
                                        className={`${classes.subjectButton} ${selectedSubject === subject ? classes.selectedSubjectButton : ''}`}
                                        onClick={() => handleSubjectClick(subject)}
                                    >
                                        {subject.name}
                                    </Box>
                                ))}
                            </Box>)}
                        {visibleIndex < subjects.length - 4 && (
                            <IconButton className={classes.arrowButton} onClick={handleNextClick}>
                                <ArrowForwardIosIcon className={classes.arrowIcon} />
                            </IconButton>
                        )}
                    </Box>
                    <Typography className={classes.textTitle} style={{ marginBottom: "10px", marginTop: "10px", width: "100%" }}>{t("load_your_notes_desc")}</Typography>
                    <div style={{ width: "50%", margin: "auto" }}>
                        <DragAndDrop files={files} setFiles={setFiles} is_multiple={true} setPages={setPages} />
                    </div>
                    <Box display="flex" justifyContent="center" marginTop="10px">
                        <Button disabled={isLoading} className={classes.buttonUpload} onClick={handleEnd}>            {isLoading ? <ClipLoader size={12} color={"#026277"} /> : t("!start!")}
                        </Button>
                    </Box>
                </div >
            )
            }

        </>
    );
}

export default WelcomeTyped;
