import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSession, useSupabaseClient } from '@supabase/auth-helpers-react';
import { getCurrentUser } from '../Api/SupabaseAuth';

const AuthContext = createContext();

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const session = useSession();
    const supabase = useSupabaseClient();

    useEffect(() => {
        const initializeAuth = async () => {
            if (session?.access_token) {
                setToken(session.access_token);
                const currentUser = await getCurrentUser();
                setUser(currentUser);
            } else {
                setToken(null);
                setUser(null);
            }
            setLoading(false);
        };

        initializeAuth();
    }, [session]);

    const value = {
        token,
        user,
        loading,
        isAuthenticated: !!token && !!user,
    };

    if (loading) {
        return null; // or a loading spinner
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}; 