import React, { useState, useEffect, useRef } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  TextField, 
  Button, 
  Typography,
  Snackbar,
  Alert,
  IconButton,
  Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ReactMarkdown from 'react-markdown';
import FormatBoldIcon from '@mui/icons-material/FormatBold';

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '24px',
  paddingTop: '12px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  flex: 1,
  overflow: 'auto',
}));

const RichTextArea = styled('div')(({ theme }) => ({
  fontFamily: '"Fira Sans", sans-serif',
  fontSize: '1.1rem',
  color: '#2c3e50',
  lineHeight: '1.6',
  padding: '0',
  minHeight: '300px',
  outline: 'none',
  '& strong, & b': {
    fontWeight: 600,
    color: '#2c3e50',
  },
  '& p': {
    margin: 0,
  }
}));

const EditNodeDialog = ({ open, onClose, onSubmit, nodeData, error }) => {
  const [formData, setFormData] = useState({
    label: '',
    description: ''
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const descriptionRef = useRef(null);
  const [internalContent, setInternalContent] = useState('');

  useEffect(() => {
    if (nodeData) {
      setFormData({
        label: nodeData.label,
        description: nodeData.description
      });
      setInternalContent(nodeData.description);
    }
  }, [nodeData]);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    if (name === 'label') {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleContentChange = (event) => {
    const content = event.target.innerHTML;
    // Convert HTML bold to markdown
    const markdownContent = content
      .replace(/<(strong|b)>/g, '**')
      .replace(/<\/(strong|b)>/g, '**')
      .replace(/<div>/g, '\n')
      .replace(/<\/div>/g, '')
      .replace(/<br>/g, '\n')
      .replace(/&nbsp;/g, ' ');
    
    setFormData(prev => ({
      ...prev,
      description: markdownContent
    }));
    setInternalContent(content);
  };

  const handleBoldClick = () => {
    document.execCommand('bold', false, null);
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const content = range.commonAncestorContainer.parentElement;
      if (content && content.contains(descriptionRef.current)) {
        handleContentChange({ target: { innerHTML: descriptionRef.current.innerHTML } });
      }
    }
  };

  const handleSubmit = async () => {
    try {
      if (formData.label.trim() === '' || formData.description.trim() === '') {
        throw new Error('Title and description cannot be empty');
      }
      await onSubmit(formData);
      setOpenSnackbar(true);
    } catch (err) {
      console.error('Error submitting form:', err);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const convertMarkdownToHtml = (markdown) => {
    return markdown.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            minHeight: '80vh',
            maxHeight: '80vh',
            width: '800px',
            margin: '20px',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '16px',
          }
        }}
      >
        <DialogTitle style={{
          padding: '24px',
          paddingBottom: '12px',
          fontFamily: '"Fira Sans", sans-serif',
        }}>
          <Typography style={{
            fontFamily: '"Fira Sans", sans-serif',
            fontWeight: 700,
            fontSize: '1.5rem',
            color: '#2c3e50',
          }}>
            Editar nodo
          </Typography>
        </DialogTitle>
        <StyledDialogContent>
          <TextField
            autoFocus
            name="label"
            type="text"
            fullWidth
            value={formData.label}
            onChange={handleFormChange}
            variant="standard"
            placeholder="Título"
            InputProps={{
              disableUnderline: true,
              style: {
                fontFamily: '"Fira Sans", sans-serif',
                fontSize: '1.5rem',
                color: '#2c3e50',
                padding: '0',
              }
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', flex: 1 }}>
            <div style={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: '8px',
              borderBottom: '1px solid #e2e8f0',
              paddingBottom: '8px'
            }}>
              <Tooltip title="Texto en negrita (selecciona texto primero)">
                <IconButton 
                  onClick={handleBoldClick}
                  size="small"
                  style={{
                    color: '#64748b',
                    padding: '4px',
                  }}
                >
                  <FormatBoldIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
            <RichTextArea
              ref={descriptionRef}
              contentEditable
              onInput={handleContentChange}
              dangerouslySetInnerHTML={{ __html: convertMarkdownToHtml(formData.description) }}
              style={{
                flex: 1,
                overflow: 'auto',
              }}
            />
          </div>
        </StyledDialogContent>
        <DialogActions style={{
          padding: '24px',
          justifyContent: 'flex-end',
          gap: '12px',
        }}>
          <Button 
            onClick={onClose}
            style={{
              textTransform: 'none',
              fontFamily: '"Fira Sans", sans-serif',
              color: '#64748b',
              padding: '8px 24px',
              fontSize: '1rem',
            }}
          >
            Cancelar
          </Button>
          <Button 
            onClick={handleSubmit}
            variant="contained" 
            disabled={!formData.label.trim() || !formData.description.trim()}
            style={{
              backgroundColor: !formData.label.trim() || !formData.description.trim() ? '#E0E0E0' : '#6BBDBD',
              color: !formData.label.trim() || !formData.description.trim() ? '#9E9E9E' : 'white',
              textTransform: 'none',
              fontFamily: '"Fira Sans", sans-serif',
              padding: '8px 24px',
              fontSize: '1rem',
              '&:hover': {
                backgroundColor: !formData.label.trim() || !formData.description.trim() ? '#E0E0E0' : '#5aa9a9',
              }
            }}
          >
            Guardar
          </Button>
        </DialogActions>
        {(!formData.label.trim() || !formData.description.trim()) && (
          <Typography 
            sx={{ 
              p: 2, 
              color: '#666',
              textAlign: 'center',
              fontFamily: '"Fira Sans", sans-serif',
            }}
          >
            El nodo no puede estar vacío
          </Typography>
        )}
        {error && (
          <Typography color="error" sx={{ p: 2 }}>
            {error}
          </Typography>
        )}
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity="success" 
          sx={{ 
            width: '100%',
            fontFamily: '"Fira Sans", sans-serif',
            backgroundColor: '#6BBDBD',
            color: 'white',
            '& .MuiAlert-icon': {
              color: 'white'
            }
          }}
        >
          Nodo actualizado
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditNodeDialog; 