import React from 'react';
import { Box, Button, Typography, Paper, Backdrop, Card, CardContent } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider, styled } from '@mui/styles';
import UploadButton from '../NewComponents/SubirApuntesButton';
import { useTranslation } from 'react-i18next';

const theme = createTheme();
const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#6BBDBD",
    color: "white",
    textTransform: "none",
    borderRadius: "10px",
    fontSize: "14px",
    display: "flex",
    alignItems: "flex-end",
    gap: "2px",
    '&:disabled': {
        backgroundColor: '#6BBDBD',
        color: '#FFFFFF'
    },
    '&:hover': {
        backgroundColor: '#6BBDBD',
        color: "white",
    }
}));
const MemorizationCard = ({ onClose, grade, expectedGrade }) => {
    const { t } = useTranslation();
    const getColor = (value) => {
        if (value < 50) {
            return 'red';
        } else if (value < 70) {
            return 'orange';
        } else {
            return '#00C608';
        }
    };
    return (
        <ThemeProvider theme={theme}>
            <Backdrop open={true} sx={{ zIndex: theme.zIndex.drawer + 1 }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '100vh',
                        backdropFilter: 'blur(5px)',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    }}
                >
                    <Card sx={{ borderRadius: '20px', padding: '5px', textAlign: 'center', maxWidth: 400, }}>
                        <CardContent style={{ paddingBottom: "16px" }}>
                            <Typography style={{ fontSize: 16, textAlign: "left", fontWeight: 'bold' }} gutterBottom>
                                {t("you_memorised__")}
                            </Typography>
                            <Typography style={{ fontSize: 50, textAlign: "center", fontWeight: 'bold', color: getColor(grade) }} color="error" gutterBottom>
                                {grade}%
                            </Typography>
                            <Typography style={{ fontSize: 14, textAlign: "left", fontWeight: 'normal' }} gutterBottom>
                                {t("you_expected_")} <b>{expectedGrade}%</b>.
                                <br />
                                {grade >= 50 && grade < 70 ? (
                                    "¡Estás cerca! Un poco más de estudio y lo tienes."
                                ) : grade < 50 ? (
                                    "No te desanimes, sigue memorizando para mejorar."
                                ) : grade >= 70 ? (
                                    "¡Muy bien! Continúa así y aprobarás seguro."
                                ) : null}
                            </Typography>

                            <div style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
                                < StyledButton onClick={onClose} >{t("check_feedback")}</StyledButton>
                            </div>
                        </CardContent>
                    </Card>
                </Box>
            </Backdrop>
        </ThemeProvider>
    );
};

export default MemorizationCard;
