import React from 'react';
import { Card, CardContent, Typography, Button, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        padding: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        zIndex: theme.zIndex.modal + 1,
        top: 0,
        width: '100vw',
        position: 'fixed',
        left: 0
    },
    cardContent: {
        padding: 0,
        width: '330px',
        position: 'relative',
    },
    cardImage: {
        maxWidth: '100%',
        marginBottom: theme.spacing(2),
        borderTopLeftRadius: '30px',
        borderTopRightRadius: '30px',
    },
    textContent: {
        textAlign: 'left',
        padding: '10px 10px 0px 20px', // Add top padding for no-image case
    },
    textContentNoImage: {
        textAlign: 'left',
        padding: '20px 10px 0px 20px', // Increase top padding when no image
    },
    buttonUpload: {
        backgroundColor: "#6BBDBD",
        color: "white",
        textTransform: "none",
        borderRadius: "6px",
        fontSize: "12px",
        display: "flex",
        alignItems: "flex-end",
        gap: "2px",
        '&:disabled': {
            backgroundColor: '#B0B0B0', // Gray color for the disabled state
            color: '#FFFFFF'           // White text for the disabled state
        },
        '&:hover': {
            backgroundColor: '#6BBDBD', // Keep the same color on hover
            color: "white",
        },
        padding: "4px 11px !important"
    },
    closeButton: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        color: theme.palette.grey[500],
    }
}));

const FullScreenLoader = ({ imageUrl, title, message, buttonText, onButtonClick, onClose }) => {
    const { t } = useTranslation();
    const classes = useStyles();

    // Calculate dynamic height based on image presence
    const cardHeight = imageUrl ? '240px' : '130px'; // Adjust heights as needed

    return (
        <div className={classes.cardContainer}>
            <Card style={{ borderRadius: '30px', height: cardHeight }}>
                <CardContent className={classes.cardContent}>
                    <IconButton className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                    {imageUrl && <img src={imageUrl} className={classes.cardImage} alt="Loading" />}
                    <div className={imageUrl ? classes.textContent : classes.textContentNoImage}>
                        <Typography style={{ fontWeight: 'bold', fontSize: '14px', color: 'black' }}>
                            {t(title)}
                        </Typography>
                        <Typography style={{ marginTop: '5px', fontSize: 14, lineHeight: '20px' }}>
                            {t(message)}
                        </Typography>
                    </div>
                    <div style={{ justifyContent: "center", display: "flex", marginTop: "10px" }}>
                        <Button className={classes.buttonUpload} onClick={onButtonClick}>
                            {t(buttonText)}
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default FullScreenLoader;
