import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  TextField
} from '@mui/material';
import { Close, Edit, Save } from '@mui/icons-material';

const QuestionsDialog = ({
  open,
  onClose,
  questions,
  selectedQuestions,
  onQuestionSelect,
  editingQuestionIndex,
  setEditingQuestionIndex,
  editedQuestion,
  setEditedQuestion,
  onSaveEdit,
  onAddQuestions
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "20px",
        }
      }}
    >
      <DialogTitle sx={{
        padding: "16px 24px 0",
        fontFamily: 'Fira Sans',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
      }}>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 600, fontFamily: 'Fira Sans' }}>
            Preguntas Generadas
          </Typography>
          <Typography 
            variant="subtitle1" 
            sx={{ 
              color: '#666',
              fontSize: '14px',
              marginTop: '4px',
              marginBottom: '8px',
              fontFamily: 'Fira Sans'
            }}
          >
            Selecciona las preguntas que quieras añadir
          </Typography>
        </Box>
        <IconButton
          onClick={onClose}
          sx={{
            marginRight: -1,
            marginTop: -1,
            color: '#666666'
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "16px 24px" }}>
        {questions.map((question, index) => (
          <Card key={index} sx={{ 
            marginBottom: '12px',
            border: '1px solid #E2F1F1',
            borderRadius: '8px',
            boxShadow: 'none',
            '&:last-child': {
              marginBottom: 0
            }
          }}>
            <CardContent sx={{ padding: '16px !important' }}>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1.5 }}>
                <Checkbox
                  checked={selectedQuestions.includes(index)}
                  onChange={() => onQuestionSelect(index)}
                  sx={{
                    color: '#6BBDBD',
                    '&.Mui-checked': {
                      color: '#6BBDBD',
                    },
                    padding: '4px',
                  }}
                />
                <Box sx={{ flex: 1 }}>
                  {editingQuestionIndex === index ? (
                    <EditingQuestionForm
                      editedQuestion={editedQuestion}
                      setEditedQuestion={setEditedQuestion}
                      onCancel={() => {
                        setEditingQuestionIndex(null);
                        setEditedQuestion(null);
                      }}
                      onSave={() => onSaveEdit(index)}
                    />
                  ) : (
                    <DisplayQuestion
                      question={question}
                      onEdit={() => {
                        setEditingQuestionIndex(index);
                        setEditedQuestion({ ...question });
                      }}
                    />
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        ))}
      </DialogContent>
      <DialogActions sx={{ padding: "12px 24px 16px", gap: 2 }}>
        <Button
          onClick={onClose}
          sx={{
            color: '#666',
            textTransform: 'none',
            fontFamily: 'Fira Sans'
          }}
        >
          Cerrar
        </Button>
        <Button
          variant="contained"
          onClick={onAddQuestions}
          disabled={selectedQuestions.length === 0}
          sx={{
            backgroundColor: '#6BBDBD',
            color: 'white',
            borderRadius: '24px',
            textTransform: 'none',
            fontFamily: 'Fira Sans',
            '&:hover': {
              backgroundColor: '#5AACAC'
            }
          }}
        >
          Añadir preguntas
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Sub-components for better organization
const EditingQuestionForm = ({ editedQuestion, setEditedQuestion, onCancel, onSave }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
    <TextField
      fullWidth
      value={editedQuestion.question}
      onChange={(e) => setEditedQuestion({
        ...editedQuestion,
        question: e.target.value
      })}
      variant="standard"
      sx={{
        '& .MuiInput-underline:before': { borderBottom: 'none' },
        '& .MuiInput-underline:hover:before': { borderBottom: 'none' },
        '& .MuiInput-underline:after': { borderBottomColor: '#E2F1F1' },
        fontFamily: 'Fira Sans',
      }}
    />
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      {editedQuestion.options.map((option, optIndex) => (
        <Box
          key={optIndex}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Checkbox
            checked={optIndex === editedQuestion.correctAnswer}
            onChange={() => setEditedQuestion({
              ...editedQuestion,
              correctAnswer: optIndex
            })}
            sx={{
              color: '#6BBDBD',
              '&.Mui-checked': {
                color: '#6BBDBD',
              },
            }}
          />
          <TextField
            fullWidth
            value={option}
            onChange={(e) => {
              const newOptions = [...editedQuestion.options];
              newOptions[optIndex] = e.target.value;
              setEditedQuestion({
                ...editedQuestion,
                options: newOptions
              });
            }}
            variant="standard"
            placeholder={`Opción ${optIndex + 1}`}
            sx={{
              '& .MuiInput-underline:before': { borderBottom: 'none' },
              '& .MuiInput-underline:hover:before': { borderBottom: 'none' },
              '& .MuiInput-underline:after': { borderBottomColor: '#E2F1F1' },
              fontFamily: 'Fira Sans',
            }}
          />
        </Box>
      ))}
    </Box>
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 1 }}>
      <IconButton size="small" onClick={onCancel} sx={{ color: '#666' }}>
        <Close fontSize="small" />
      </IconButton>
      <IconButton size="small" onClick={onSave} sx={{ color: '#66BB6A' }}>
        <Save fontSize="small" />
      </IconButton>
    </Box>
  </Box>
);

const DisplayQuestion = ({ question, onEdit }) => (
  console.log("this is the question ", question),
  <>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
      <Typography sx={{ 
        fontFamily: 'Fira Sans',
        fontWeight: 500,
        marginBottom: '12px',
        flex: 1
      }}>
        {question.question}
      </Typography>
      <IconButton
        size="small"
        onClick={onEdit}
        sx={{ 
          color: '#666',
          '&:hover': { color: '#6BBDBD' },
          padding: '4px',
          ml: 1
        }}
      >
        <Edit fontSize="small" />
      </IconButton>
    </Box>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
      {question.options.map((option, optIndex) => (
        <Box
          key={optIndex}
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: optIndex === question.correctAnswer ? '#66BB6A' : '#666'
          }}
        >
          <Checkbox
            checked={optIndex === question.correctAnswer}
            disabled
            sx={{
              color: 'inherit',
              '&.Mui-checked': {
                color: 'inherit',
              },
              padding: '4px',
            }}
          />
          <Typography sx={{ fontFamily: 'Fira Sans' }}>
            {String.fromCharCode(65 + optIndex)}. {option}
          </Typography>
        </Box>
      ))}
    </Box>
  </>
);

export default QuestionsDialog; 