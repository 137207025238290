import { makeStyles } from '@mui/styles';

const useModalFoldersStyles = makeStyles((theme) => ({
    tableCell: {
        borderBottom: "none",
        color: "#060D01",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%", // 24px
        cursor: "pointer", // Change cursor on hover to pointer
    },
    fileListContainerStyle: {
        marginTop: '20px',
        backgroundColor: '#FAFAFA',
        boxShadow: 'None',
        borderRadius: '4px',
        maxHeight: '300px', // Set the maximum height (adjust as needed)
        overflowY: 'auto', // Enable vertical scrolling
    },
    header: {
        color: "#A0A0A0",
        borderBottom: "none",
        position: "sticky", // Make the header sticky
        zIndex: 1, // Ensure it's above the table content
        top: 0, // Stick to the top
        backgroundColor: "#FAFAFA",
    },
    stickyHeader: {
        position: "sticky !important",
        top: 0,
        zIndex: 1,
        backgroundColor: "white",
    },
    noMargin: {
        margin: "0px !important",
    },
    tableRow: {
        '&:hover': {
            backgroundColor: 'rgba(2, 98, 119, 0.20)', // Set the hover color
        },
    },
    dialogTitle: {
        '& h2': {
            fontWeight: "600 !important",
            paddingBottom: 0,
        }
    },
}));

export default useModalFoldersStyles;
