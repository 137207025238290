import React from 'react';
import { ReactFlowProvider } from 'react-flow-renderer';
import { useLocation } from 'react-router-dom';
import Flow from './Flow';

function MindMapFlow() {
  const location = useLocation();
  const docInfo = location.state?.docInfo;
  const schema_list = location.state?.schema_list;
  return (
    <ReactFlowProvider>
      <Flow docInfo={docInfo} schema_list={schema_list} />
    </ReactFlowProvider>
  );
}

export default MindMapFlow; 