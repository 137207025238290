import { Box } from '@mui/material';
import { Skeleton } from '@mui/lab';
import React from "react";
import DocumentListFolder from "../Documents/AllListDocumentsFolder";

function FolderContent({
    listDocuments,
    folder,
    updateListDocuments,
    token,
    user,
    setShowPremium,
    isLoading
}) {
    const LoadingSkeleton = () => (
        <Box p={2}>
            {[...Array(5)].map((_, index) => (
                <Box key={index} mb={2} display="flex" alignItems="center">
                    <Box mr={2}>
                        <Skeleton variant="circle" width={40} height={40} animation="wave" />
                    </Box>
                    <Box width="100%">
                        <Box mb={1}>
                            <Skeleton variant="text" width="60%" height={24} animation="wave" />
                        </Box>
                        <Skeleton variant="text" width="40%" height={20} animation="wave" />
                    </Box>
                </Box>
            ))}
        </Box>
    );

    return (
        <Box style={{
            marginTop: '20px',
            marginBottom: '80px',
            backgroundColor: '#F8FFFF',
            borderRadius: '20px',
            paddingRight: '16px'
        }}>
            {isLoading ? (
                <LoadingSkeleton />
            ) : (
                <DocumentListFolder
                    listDocuments={listDocuments}
                    isLoading={isLoading}
                    selectedDocument={null}
                    onDocumentSelect={() => { }}
                    selectable={false}
                    box={true}
                    token={token}
                    user={user}
                    updateListDocuments={updateListDocuments}
                    setShowPremium={setShowPremium}
                    folder={folder}
                />
            )}
        </Box>
    );
}

export default FolderContent;
