// src/Context/CreditsContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from '../Auth/Authhandle';
import { useUserInfo } from './UserContext';

const CreditsContext = createContext();

export const useCredits = () => {
    return useContext(CreditsContext);
};

export const CreditsProvider = ({ children }) => {
    const [credits, setCredits] = useState(0);
    const { token, user, isAuthenticated } = useAuth();
    const { userInfo } = useUserInfo();

    useEffect(() => {
        const updateCreditsFromUserInfo = () => {
            if (userInfo) {
                const totalCredits = userInfo.credits_plan + userInfo.credits_bought;
                setCredits(totalCredits);
            }
        };

        updateCreditsFromUserInfo();
    }, [userInfo]);

    const updateCredits = () => {
        if (userInfo) {
            const totalCredits = userInfo.credits_plan + userInfo.credits_bought;
            setCredits(totalCredits);
        }
    };

    return (
        <CreditsContext.Provider value={{ credits, setCredits, updateCredits }}>
            {children}
        </CreditsContext.Provider>
    );
};
