import { styled } from '@mui/material/styles';
import { Button, IconButton, Fab, DialogTitle, DialogContent, ListItem, Typography, MenuItem, Dialog } from '@mui/material';

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: theme.spacing(1),
  '& .MuiTypography-root': {
    fontFamily: '"Fira Sans", sans-serif',
    fontWeight: 700,
    fontSize: '1.2rem',
    color: '#2c3e50',
  },
}));

export const GlobalStyles = styled('div')({
  '@import': "url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;600&display=swap')",
  '.react-flow__node-custom': {
    border: '1px solid #eee',
    backgroundColor: 'white',
    '&.selected': {
      backgroundColor: '#E2F1F1',
      border: '2px solid #6BBDBD',
    },
  },
});

export const Root = styled('div')({
  height: '100vh',
  width: '100vw',
  position: 'relative',
  fontFamily: '"Fira Sans", sans-serif',
});

export const ContentWrapper = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

export const Sidebar = styled('div')(({ theme, hidden }) => ({
  position: 'absolute',
  top: '50%',
  left: theme.spacing(2),
  transform: hidden ? 'translateY(-50%) translateX(-120%)' : 'translateY(-50%)',
  width: '300px',
  zIndex: 1000,
  transition: 'all 0.3s ease-in-out',
  backgroundColor: 'white',
  borderRadius: '16px',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
  overflow: 'hidden',
}));

export const FlowWrapper = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
  backgroundColor: '#ffffff',
});

export const AddButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 10,
  borderRadius: '20px',
  padding: '8px 24px',
  textTransform: 'none',
  fontFamily: '"Fira Sans", sans-serif',
  fontWeight: 600,
  backgroundColor: '#6BBDBD',
  color: 'white',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: '#5aa9a9',
    boxShadow: 'none',
  },
}));

export const MinimizeButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
  '& svg': {
    fontSize: '1.2rem',
    color: '#666666',
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}));

export const RestoreButton = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: theme.spacing(3),
  transform: 'translateY(-50%)',
  zIndex: 1000,
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
  backgroundColor: '#6BBDBD',
  color: 'white',
  '&:hover': {
    backgroundColor: '#5aa9a9',
  },
}));

export const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: '#f8f9fa',
  borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
}));

export const Title = styled(Typography)({
  fontFamily: '"Fira Sans", sans-serif',
  fontWeight: 500,
  fontSize: '1.1rem',
  color: '#2c3e50',
});

export const StyledContent = styled(DialogContent)(({ theme }) => ({
  padding: 0,
  maxHeight: '60vh',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '3px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#c1c1c1',
    borderRadius: '3px',
    '&:hover': {
      background: '#a8a8a8',
    },
  },
}));

export const ElementItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
  '&:last-child': {
    borderBottom: 'none',
  },
}));

export const ElementHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(1),
}));

export const ElementTitle = styled(Typography)({
  fontFamily: '"Fira Sans", sans-serif',
  fontWeight: 500,
  fontSize: '0.95rem',
  color: '#2c3e50',
});

export const ElementDescription = styled(Typography)({
  color: '#64748b',
  fontSize: '0.85rem',
  fontWeight: 400,
  lineHeight: 1.4,
});

export const MenuButton = styled(IconButton)(({ theme }) => ({
  padding: 4,
  marginTop: -4,
  marginRight: -4,
  '& svg': {
    fontSize: '1.1rem',
    color: '#666666',
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}));

export const StyledMenuItem = styled(MenuItem)({
  fontSize: '0.875rem',
  fontFamily: '"Fira Sans", sans-serif',
  minHeight: '36px',
  '& svg': {
    fontSize: '1.1rem',
    marginRight: '8px',
    color: '#666666',
  },
});

export const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    borderRadius: '16px',
    padding: '8px',
  },
}); 