import { getCsrfToken } from './TokenApi';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const createFlashcardCall = async (token, documentId, flashcardData, user) => {
  const user_id = user['id'];
  try {
    const csrfToken = getCsrfToken();
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };

    if (csrfToken) {
      headers['X-CSRFToken'] = csrfToken;
    }
    // Extract answers from the answer text (words in square brackets)
    const answers = (flashcardData.answer.match(/\[(.*?)\]/g) || [])
      .map(match => match.slice(1, -1));

    const response = await fetch(`${BACKEND_URL}/api_v1/create-flashcard/${documentId}/?user_id=${user_id}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        type: 'question',
        question: flashcardData.question,
        answer: flashcardData.answer,
        answers: answers,
        active: true
      }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    console.error('Error creating flashcard:', error);
    throw error;
  }
};

export const updateFlashcard = async (token, documentId, flashcardId, flashcardData, user) => {
  const user_id = user['id'];
  try {
    const csrfToken = getCsrfToken();
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };

    if (csrfToken) {
      headers['X-CSRFToken'] = csrfToken;
    }

    // Extract answers from the answer text (words in square brackets)
    const answers = (flashcardData.answer.match(/\[(.*?)\]/g) || [])
      .map(match => match.slice(1, -1));

    const response = await fetch(`${BACKEND_URL}/api_v1/flashcard/${documentId}/${flashcardId}/?user_id=${user_id}`, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify({
        type: 'question',
        question: flashcardData.question,
        answer: flashcardData.answer,
        answers: answers,
        active: flashcardData.active
      }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating flashcard:', error);
    throw error;
  }
};

export const deleteFlashcard = async (token, flashcardId, user) => {
  const user_id = user['id'];
  try {
    const csrfToken = getCsrfToken();
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };

    if (csrfToken) {
      headers['X-CSRFToken'] = csrfToken;
    }

    const response = await fetch(`${BACKEND_URL}/api_v1/flashcard-delete/${flashcardId}/?user_id=${user_id}`, {
      method: 'DELETE',
      headers: headers,
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return true;
  } catch (error) {
    console.error('Error deleting flashcard:', error);
    throw error;
  }
}; 