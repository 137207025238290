import { makeStyles } from '@mui/styles';

const useUploadFilesWelcome = makeStyles((theme) => ({
    container: {
        borderRadius: "15px",
        height: "84px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        // width: "100%",
        backgroundColor: "white",
        height: "300px",
        border: "2px dashed #6BBDBD"
    },
    tableCell: {
        borderBottom: "none",
        color: "#060D01",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "100%",
        cursor: "pointer",
    },
    fileListContainerStyle: {
        marginTop: '0px',
        backgroundColor: 'white',
        boxShadow: 'None',
        // maxHeight: '100px',
        width: "100% !important",
        maxHeight: "150px !important",
        overflowY: "scroll",
        '& .MuiPaper-root': {
            borderRadius: "0px !important",

        }


    },
    icon: {
        backgroundColor: "rgba(2, 98, 119, 0.50)",
        borderRadius: "50%",
        padding: "10px",
        color: "white",
        marginBottom: "10px",
    },
    text: {
        marginBottom: "5px",
        color: "black",
        fontSize: "12px",
        fontStyle: "normal",
        lineHeight: "150%",
        fontWeight: "500",
    },
    subText: {
        color: "#A0A0A0",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "700",
        lineHeight: "150%",
    },
    listItem: {
        border: "2px solid #6BBDBD",
        marginBottom: "15px",
        borderRadius: "6px",

        paddingTop: "0px", // Directly apply padding here
        paddingBottom: "0px", // Directly apply padding here
    },
    header: {
        color: "#A0A0A0",
        borderBottom: "none",
        position: "sticky",
        zIndex: 1,
        top: 0,
        backgroundColor: "#FAFAFA",
    },
    stickyHeader: {
        position: "sticky !important",
        top: 0,
        zIndex: 1,
        backgroundColor: "white",
    },
    tableRow: {
        '&:hover': {
            backgroundColor: 'rgba(2, 98, 119, 0.20)',
        },
        border: "3px solid #026277",

    },
    deleteIcon: {
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.4)',
            transition: 'transform 0.2s ease-in-out',
            borderRadius: '50%',
        },
    },
}));
export default useUploadFilesWelcome