import React, { useState, useEffect, useContext } from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import { ArrowBack, Add, QuestionMark, Style, Psychology, Quiz, Edit, Delete, Save, Close, ZoomIn, ZoomOut, AddCircleOutline } from "@mui/icons-material";
import EditCard from './EditCard';
import CrearCard from './CrearCard';
import { WebSocketContext } from '../WebSocketProvider';
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  Paper,
  Divider,
  Menu,
  MenuItem,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Snackbar,
  Alert,
  Slider
} from "@mui/material";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchFlashcard } from '../Api/FlashcardsApi';
import { useAuth } from "../Auth/Authhandle";
import { fetchQuestions } from "../Api/ChaptersApi";
import { fetchGenerateQuestionsCount, fetchGenerateFlashcardsCount } from "../Api/FilesApi";
import { createQuestion, updateQuestion, deleteQuestion } from '../Api/QuestionApi';
import { createFlashcardCall, updateFlashcard, deleteFlashcard } from '../Api/FlashcardApi';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './pdf-viewer.css';
import { getCsrfToken } from '../Api/TokenApi';
import TextSelectionDialog from './dialogs/TextSelectionDialog';
import LoadingDialog from './dialogs/LoadingDialog';
import DeleteQuestionDialog from './dialogs/DeleteQuestionDialog';
import FlashcardsDialog from './dialogs/FlashcardsDialog';
import QuestionsDialog from './dialogs/QuestionsDialog';
import { generateQuestionsFromText } from "../Api/QuestionsApi";
import { useSubscription } from "../Context/SubscriptionContext";
import Premium from '../PopUpsAlerts/Premium/Premium';
import { Add as AddIcon } from "@mui/icons-material";
import GenerateQuestionsDialog from './dialogs/GenerateQuestionsDialog';
import GenerateFlashcardsDialog from './dialogs/GenerateFlashcardsDialog';
import CreateQuestionDialog from './dialogs/CreateQuestionDialog';
// Set up the worker for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const options = {
  cMapUrl: 'cmaps/',
  standardFontDataUrl: 'standard_fonts/',
};

const SolutionText = styled('span')({
  color: '#66BB6A',
  fontWeight: 500,
});

const formatTextWithSolutions = (text) => {
  if (!text) return '';

  const parts = text.split(/(\[.*?\])/g);
  return parts.map((part, index) => {
    if (part.startsWith('[') && part.endsWith(']')) {
      return (
        <SolutionText key={index}>
          {part.slice(1, -1)}
        </SolutionText>
      );
    }
    return part;
  });
};

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const Editar = () => {
  const { userData } = useSubscription();
  const [cards, setCards] = useState([]);
  const { messageCallback, setMessageCallback } = useContext(WebSocketContext);
  const [isGeneratingQuestions, setIsGeneratingQuestions] = useState(false);
  const [generatingQuestionsMessage, setGeneratingQuestionsMessage] = useState('');
  const [testQuestions, setTestQuestions] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [cardToDelete, setCardToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [cardToEdit, setCardToEdit] = useState(null);
  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [createFlashcard, setCreateFlashcard] = useState(false);
  const [isCreatingQuestion, setIsCreatingQuestion] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { token, user, isAuthenticated } = useAuth();
  const documentId = location.state?.document_id;
  const docInfo = location.state?.docInfo;
  const [formErrors, setFormErrors] = useState({
    question: false,
    options: [false, false, false, false]
  });

  const [newQuestion, setNewQuestion] = useState({
    question: '',
    options: ['', '', '', ''],
    correctAnswer: 0
  });

  const [questionToDelete, setQuestionToDelete] = useState(null);
  const [deleteQuestionDialogOpen, setDeleteQuestionDialogOpen] = useState(false);

  // PDF viewer states
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [scale, setScale] = useState(1);
  const [selectedText, setSelectedText] = useState('');
  const [isTextSelectionModalOpen, setIsTextSelectionModalOpen] = useState(false);
  const [isTextTruncated, setIsTextTruncated] = useState(false);
  const [isGeneratingContent, setIsGeneratingContent] = useState(false);
  const [showQuestionsDialog, setShowQuestionsDialog] = useState(false);
  const [showFlashcardsDialog, setShowFlashcardsDialog] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [selectedFlashcards, setSelectedFlashcards] = useState([]);

  const [pdfUrl, setPdfUrl] = useState(null);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Add formattedQuestions state
  const [formattedQuestions, setFormattedQuestions] = useState([]);
  const [formattedCards, setFormattedCards] = useState([]);
  const [showPremium, setShowPremium] = useState(false);
  const [premiumTitleText, setPremiumTitleText] = useState('');
  // Add handleQuestionSelect function
  const handleQuestionSelect = (index) => {
    setSelectedQuestions(prev => {
      if (prev.includes(index)) {
        return prev.filter(i => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  // Add dummyFlashcards array


  const handleFlashcardSelect = (index) => {
    setSelectedFlashcards(prev => {
      if (prev.includes(index)) {
        return prev.filter(i => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  // Add this state near the other state declarations
  const [isAddingQuestions, setIsAddingQuestions] = useState(false);

  // Add this function near the other handlers
  const handleAddQuestion = () => {
    if (userData?.plan_name?.startsWith("Basic") || userData?.credits_plan !== -100) {
      setShowPremium(true);
      setPremiumTitleText('questions');
    } else {
      setIsCreatingQuestion(true);
    }
  };

  // Add this new state for the generate modal
  const [openGenerateModal, setOpenGenerateModal] = useState(false);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);

  // Add these handlers
  const handleOpenGenerateModal = () => {
    setOpenGenerateModal(true);
  };

  const handleCloseGenerateModal = () => {
    setOpenGenerateModal(false);
    setNumberOfQuestions(0);
  };

  // Add these new states near your other state declarations
  const [openGenerateFlashcardsModal, setOpenGenerateFlashcardsModal] = useState(false);
  const [numberOfFlashcards, setNumberOfFlashcards] = useState(0);

  // Add these handlers near your other handlers
  const handleOpenGenerateFlashcardsModal = () => {
    setOpenGenerateFlashcardsModal(true);
  };

  const handleCloseGenerateFlashcardsModal = () => {
    setOpenGenerateFlashcardsModal(false);
    setNumberOfFlashcards(0);
  };

  const [isGenerating, setIsGenerating] = useState(false);
  const [isGeneratingFlashcards, setIsGeneratingFlashcards] = useState(false);
  const [generatingFlashcardsMessage, setGeneratingFlashcardsMessage] = useState('');

  const handleGenerateMore = async () => {
    setIsGenerating(true);
    try {
      await fetchGenerateQuestionsCount(token, documentId, numberOfQuestions, user);


      setSnackbar({
        open: true,
        message: `Se están generando ${numberOfQuestions} preguntas`,
        severity: 'success'
      });
    } catch (error) {
      console.error('Error generating questions:', error);
      setSnackbar({
        open: true,
        message: 'Error al generar preguntas',
        severity: 'error'
      });
    } finally {
      setIsGenerating(false);
      handleCloseGenerateModal();
    }
  };

  const handleGenerateMoreFlashcards = async () => {
    setIsGeneratingFlashcards(true);
    setGeneratingFlashcardsMessage(`Generando tarjetas...`);
    try {
      // Replace with your actual API call for generating flashcards
      await fetchGenerateFlashcardsCount(token, documentId, numberOfFlashcards, user).then(response => {
        setSnackbar({
          open: true,
          message: `Generando ${numberOfFlashcards} tarjetas...`,
          severity: 'success'
        });
      });

      // Fetch updated flashcards


    } catch (error) {
      console.error('Error generating flashcards:', error);
      setSnackbar({
        open: true,
        message: 'Error al generar tarjetas',
        severity: 'error'
      });
    } finally {
      handleCloseGenerateFlashcardsModal();
    }
  };

  const [hasLoadedData, setHasLoadedData] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      if (!documentId || !token || !user) return;
      if (hasLoadedData) return; // Skip if data is already loaded

      setLoading(true);
      try {
        // Load flashcards
        const flashcardsData = await fetchFlashcard(token, documentId, user);
        setCards(flashcardsData.map(card => ({
          id: card.guid,
          question: card.type === 'cloze' ? card.text : card.question,
          answer: card.answer
        })));

        // Load questions
        const questionsData = await fetchQuestions(token, documentId, user);
        if (questionsData.topics) {
          const allQuestions = questionsData.topics.flatMap(topic =>
            topic.questions.map(q => ({
              id: q.id,
              question: q.question_text || q.text,
              options: q.question_options.map(opt => opt.text),
              correctAnswer: q.question_options.findIndex(opt => opt.is_correct),
              order: q.order
            }))
          ).sort((a, b) => b.order - a.order);
          setTestQuestions(allQuestions);
        }

        // Load PDF
        if (docInfo?.url_stored) {
          try {
            const csrfToken = getCsrfToken();
            const response = await fetch(`${BACKEND_URL}/api_v1/decrypt-pdf/`, {
              method: 'POST',
              body: JSON.stringify({ fileUrl: docInfo.url_stored }),
              headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                'Authorization': `Bearer ${token}`
              }
            });

            if (!response.ok) {
              setError('Error mostrando el documento, contáctanos para solucionarlo');
              throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.blob();
            const pdfBlobUrl = URL.createObjectURL(data);
            setPdfUrl(pdfBlobUrl);
          } catch (pdfError) {
            console.error('Error loading PDF:', pdfError);
            setPdfUrl(null); // Clear any existing PDF URL
            setError('Error mostrando el documento, contáctanos para solucionarlo' + "\n" + pdfError);
          }
        } else {
          setPdfUrl(null);
          setError('Este documento no tiene un PDF asociado');
        }

        setHasLoadedData(true); // Mark data as loaded
      } catch (err) {
        setError("Error cargando datos: " + err.message);
        console.error('Error loading data:', err);
      } finally {
        setLoading(false);
      }
    };

    loadData();

    // Cleanup function to revoke object URL
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [documentId, token, user, docInfo?.url_stored, hasLoadedData]);

  useEffect(() => {
    if (messageCallback) {
      switch (messageCallback.type) {
        case "generating_questions_plus":
          setIsGeneratingQuestions(true);
          setGeneratingQuestionsMessage('Generando preguntas...');
          break;
        case "generating_flashcards_plus":
          setIsGeneratingFlashcards(true);
          setGeneratingFlashcardsMessage('Generando tarjetas...');
          break;
        case "generating_questions_done":
          setIsGenerating(false);
          setGeneratingQuestionsMessage('');
          // Fetch updated questions
          const fetchUpdatedQuestions = async () => {
            try {
              const questionsData = await fetchQuestions(token, documentId, user);
              if (questionsData.topics) {
                const allQuestions = questionsData.topics.flatMap(topic =>
                  topic.questions.map(q => ({
                    id: q.id,
                    question: q.question_text || q.text,
                    options: q.question_options.map(opt => opt.text),
                    correctAnswer: q.question_options.findIndex(opt => opt.is_correct),
                    order: q.order
                  }))
                ).sort((a, b) => b.order - a.order);
                setTestQuestions(allQuestions);
              }
            } catch (error) {
              console.error('Error fetching updated questions:', error);
            }
          };
          fetchUpdatedQuestions();
          setIsGeneratingQuestions(false);
          setGeneratingQuestionsMessage('');
          break;
        case "flashcards_updated_done":

          const fetchUpdatedFlashcards = async () => {
            const flashcardsData = await fetchFlashcard(token, documentId, user);
            setCards(flashcardsData.map(card => ({
              id: card.guid,
              question: card.question,
              answer: card.answer
            })));
          };
          fetchUpdatedFlashcards();
          setIsGeneratingFlashcards(false);
          setGeneratingFlashcardsMessage('');
          break;
        default:
          break;
      }
      setMessageCallback(null);
    }
  }, [messageCallback, token, documentId, user]);

  const handleDelete = (id) => {
    setCards(cards.filter((card) => card.id !== id));
    deleteFlashcard(token, id, user);

  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDeleteClick = (e, card) => {
    setCardToDelete(card);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = () => {
    setIsDeleting(true);
    handleDelete(cardToDelete.id);
    setIsDeleting(false);
    setDeleteDialogOpen(false);
    setCardToDelete(null);
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setCardToDelete(null);
  };

  const handleEditClick = (e, card) => {
    e.stopPropagation();
    setCardToEdit(card);
    setEditDialogOpen(true);
  };

  const handleEditSave = async (questionId) => {
    try {

      const questionToUpdate = testQuestions.find(q => q.id === questionId);
      if (!questionToUpdate) return;
      // Call the API to update the question
      await updateQuestion(
        token,
        questionId,
        {
          question: questionToUpdate.question,
          options: questionToUpdate.options,
          correctAnswer: questionToUpdate.correctAnswer,
          explanation: questionToUpdate.explanation || ''
        },
        user
      );

      // Update the UI
      setTestQuestions(prev => prev.map(q =>
        q.id === questionId ? questionToUpdate : q
      ));
      setEditingQuestionId(null);
    } catch (error) {
      console.error('Error updating question:', error);
      // Handle error (maybe show a snackbar or error message)
    }
  };

  const handleCreateSave = (newCard) => {
    const newId = Math.max(...cards.map(card => card.id)) + 1;
    setCards(prevCards => [{ ...newCard, id: newId }, ...prevCards]);
  };

  const handleCreateTestQuestion = async (questionData) => {
    try {
      setIsAddingQuestions(true);
      // Call the API to create the question
      const result = await createQuestion(
        token,
        documentId,
        {
          question: questionData.question,
          options: questionData.options,
          correctAnswer: questionData.correctAnswer,
          explanation: '' // Add explanation if needed
        },
        user
      );

      // If successful, update the UI
      const newId = result.question_id;
      setTestQuestions(prev => [{
        id: newId,
        question: questionData.question,
        options: questionData.options,
        correctAnswer: questionData.correctAnswer
      }, ...prev]);

      setIsCreatingQuestion(false);
      setNewQuestion({
        question: '',
        options: ['', '', '', ''],
        correctAnswer: 0
      });

      return true;
    } catch (error) {
      console.error('Error creating question:', error);
      setSnackbar({
        open: true,
        message: 'Error al crear la pregunta',
        severity: 'error'
      });
      return false;
    } finally {
      setIsAddingQuestions(false);
    }
  };

  const handleCreateEditFlashcard = async (card) => {
    const flashcardData = {
      question: card.question,
      answer: card.answer,
      active: true
    };


    try {
      if (card.id) {
        // Update UI immediately for better UX
        setCards(prevCards =>
          prevCards.map(c =>
            c.id === card.id ? { ...c, ...flashcardData } : c
          )
        );

        // Close dialogs immediately
        setEditDialogOpen(false);
        setCreateFlashcard(false);

        // Make API call in the background
        updateFlashcard(
          token,
          documentId,
          card.id,
          flashcardData,
          user
        ).then(result => {
          console.log('Flashcard updated');
        }).catch(error => {
          console.error('Error updating flashcard:', error);
          // Optionally revert the UI change if the API call fails
          setCards(prevCards =>
            prevCards.map(c =>
              c.id === card.id ? card : c
            )
          );
          // Show error message to user
        });

      } else {
        // Generate a temporary ID for immediate UI update
        const tempId = `temp-${Date.now()}`;
        const newCard = {
          id: tempId,
          ...flashcardData
        };

        // Update UI immediately
        setCards(prevCards => [newCard, ...prevCards]);

        // Close dialogs immediately for better UX
        setEditDialogOpen(false);
        setCreateFlashcard(false);

        // Make API call in the background
        createFlashcardCall(
          token,
          documentId,
          flashcardData,
          user
        ).then(result => {
          // Update the temporary ID with the real one from the server
          setCards(prevCards => prevCards.map(c =>
            c.id === tempId ? { ...c, id: result.guid } : c
          ));
        }).catch(error => {
          console.error('Error creating flashcard:', error);
          // Remove the temporary card if the API call fails
          setCards(prevCards => prevCards.filter(c => c.id !== tempId));
          // Optionally show an error message to the user
        });
      }
    } catch (error) {
      console.error('Error handling flashcard:', error);
      // Show error message to user
    }
  };

  const handleDeleteQuestionClick = (question) => {
    setQuestionToDelete(question);
    setDeleteQuestionDialogOpen(true);
  };

  const handleDeleteQuestionConfirm = async () => {
    try {
      setTestQuestions(prev => prev.filter(q => q.id !== questionToDelete.id));
      await deleteQuestion(token, questionToDelete.id, user);
      // Update UI by removing the deleted question
    } catch (error) {
      console.error('Error deleting question:', error);
      // Optionally show error message to user
    }
    setDeleteQuestionDialogOpen(false);
    setQuestionToDelete(null);
  };

  const handleDeleteQuestionCancel = () => {
    setDeleteQuestionDialogOpen(false);
    setQuestionToDelete(null);
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setCurrentPage(1);
    setLoading(false);
  };

  const handleDocumentLoadError = (error) => {
    console.error('Error loading PDF:', error);
    setError('Error loading PDF. Please try again.');
    setLoading(false);
  };

  const handleTextSelection = (event) => {
    if (!userData) {
      setSnackbar({
        open: true,
        message: 'Error al cargar datos del usuario',
        severity: 'error'
      });
      return;
    }

    if (userData.credits_plan < 20 && userData.credits_plan !== -100) {
      setShowPremium(true);
      setPremiumTitleText('questions');
      return;
    }

    event.preventDefault();
    const selection = window.getSelection();
    let selectedText = selection.toString().trim();

    if (selectedText) {
      setIsTextTruncated(selectedText.length > 10000);
      if (selectedText.length > 10000) {
        selectedText = selectedText.substring(0, 10000);
      }

      setSelectedText(selectedText);
      setIsTextSelectionModalOpen(true);
      selection.removeAllRanges();
    }
  };

  const handleGenerateQuestions = () => {
    setIsTextSelectionModalOpen(false);
    setIsGeneratingContent(true);

    generateQuestionsFromText(token, documentId, user, selectedText, "question")
      .then(response => {
        // Transform the API response data to match our expected format
        const newFormattedQuestions = response.result.map(q => ({
          question: q.question_statement,
          options: q.question_options.map(opt => opt.text),
          correctAnswer: q.question_options.findIndex(opt => opt.text === q.question_correct_answer)
        }));

        // Update the formatted questions state for the dialog
        setFormattedQuestions(newFormattedQuestions);
        setIsGeneratingContent(false);
        setShowQuestionsDialog(true);
      })
      .catch(error => {
        console.error('Error generating questions:', error);
        setSnackbar({
          open: true,
          message: 'Error al generar preguntas',
          severity: 'error'
        });
        setIsGeneratingContent(false);
      });
  };
  const handleGenerateCards = () => {
    setIsTextSelectionModalOpen(false);
    setIsGeneratingContent(true);

    generateQuestionsFromText(token, documentId, user, selectedText, "flashcards")
      .then(response => {
        // Transform the API response data to match the expected format
        const newFormattedCards = response.result.map(card => ({
          question: card.question,
          answer: card.answer,
          answers: card.answers,
          type: card.type
        }));

        setFormattedCards(newFormattedCards);
        setIsGeneratingContent(false);
        setShowFlashcardsDialog(true);
      })
      .catch(error => {
        console.error('Error generating flashcards:', error);
        setSnackbar({
          open: true,
          message: 'Error al generar flashcards',
          severity: 'error'
        });
        setIsGeneratingContent(false);
      });
  };

  const zoomIn = () => {
    setScale(prevScale => Math.min(prevScale + 0.1, 2.0));
  };

  const zoomOut = () => {
    setScale(prevScale => Math.max(prevScale - 0.1, 0.5));
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const [editingFlashcardIndex, setEditingFlashcardIndex] = useState(null);
  const [editingGeneratedQuestionIndex, setEditingGeneratedQuestionIndex] = useState(null);

  // Add these new state variables for editing
  const [editedFlashcard, setEditedFlashcard] = useState(null);
  const [editedQuestion, setEditedQuestion] = useState(null);

  const [addButtonAnchorEl, setAddButtonAnchorEl] = useState(null);

  const handleAddButtonClick = (event) => {
    setAddButtonAnchorEl(event.currentTarget);
  };

  const handleAddButtonClose = () => {
    setAddButtonAnchorEl(null);
  };

  const handleAddWithAI = () => {
    handleAddButtonClose();
    if (userData.plan_name === 'Free') {
      setPremiumTitleText(tabValue === 0 ? 'flashcards' : 'questions');
      setShowPremium(true);
      return;
    }
    if (tabValue === 0) {
      handleOpenGenerateFlashcardsModal();
    } else {
      handleOpenGenerateModal();
    }
  };

  const [isCreateQuestionDialogOpen, setIsCreateQuestionDialogOpen] = useState(false);
const handleCreateQuestion = async (newQuestion) => {
  try {
    // Transform the question data to match the API structure
    const questionData = {
      question: newQuestion.question_text,
      options: newQuestion.options,
      correctAnswer: newQuestion.correctAnswer,
      explanation: '' // Optional explanation field

    };
    console.log("this is the question data", questionData)
    // Call the API to create the question in the backend
    const result = await createQuestion(
      token,
      documentId,
      {
        question: questionData.question,
        options: questionData.options,
        correctAnswer: questionData.correctAnswer,
        explanation: questionData.explanation
      },
      user
    );
    

    // Add the question to local state with the ID from the backend
    const questionToAdd = {
      id: result.question_id,
      question: questionData.question,
      options: questionData.options,
      correctAnswer: questionData.correctAnswer
    };
    setTestQuestions(prev => [questionToAdd, ...prev]);

    // Show success message
    setSnackbar({
      open: true,
      message: 'Pregunta creada correctamente',
      severity: 'success'
    });

    // Close the dialog
    setIsCreateQuestionDialogOpen(false);
  } catch (error) {
    console.error('Error creating question:', error);
    // Show error message
    setSnackbar({
      open: true,
      message: 'Error al crear la pregunta',
      severity: 'error'
    });
  }
};
// ... existing code ...
  // Modify handleAddManually
  const handleAddManually = () => {
    handleAddButtonClose();
    if (tabValue === 0) {
      setCreateFlashcard(true);
    } else {
      setIsCreateQuestionDialogOpen(true);
    }
  };

  return (
    <>
      {showPremium && (
        <Premium
          onClose={() => setShowPremium(false)}
          titleText={premiumTitleText}
        />
      )}
      <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', bgcolor: '#F8FFFF' }}>
        <AppBar position="static" elevation={0} sx={{ bgcolor: 'white', borderBottom: '1px solid #E2F1F1' }}>
          <Container>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '64px', gap: 2, justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <IconButton onClick={() => navigate(`/${docInfo.folder}/${documentId}`)}>
                  <ArrowBack />
                </IconButton>
                <Typography variant="body2" sx={{ color: '#666', fontFamily: 'Fira Sans' }}>
                  Editar contenido
                </Typography>
              </Box>
              <Box>
                <Button
                  sx={{
                    backgroundColor: '#6BBDBD',
                    color: 'white',
                    textTransform: 'none',
                    fontWeight: '600',
                    fontFamily: "'Fira Sans', sans-serif",
                    borderRadius: '24px',
                    padding: '8px 24px',
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: '#6BBDBD',
                    },
                    minWidth: '140px'
                  }}
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                  Estudiar
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 7.5L10 12.5L15 7.5"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  PaperProps={{
                    style: {
                      marginTop: '8px',
                      borderRadius: '12px',
                      minWidth: '200px',
                      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      navigate('/new-exam');
                      setAnchorEl(null);
                    }}
                    style={{
                      padding: '12px 16px',
                      fontSize: '14px',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                      <Quiz style={{ color: '#666' }} />
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ fontWeight: 500, fontFamily: "Fira Sans" }}>Hacer test</span>
                        <span style={{ color: '#666', fontSize: '12px', fontFamily: "Fira Sans" }}>Ponte a prueba con un test</span>
                      </div>
                    </div>
                  </MenuItem>
                  <Divider style={{ margin: '4px 0' }} />
                  <MenuItem
                    onClick={() => {
                      navigate('/new-flashcards');
                      setAnchorEl(null);
                    }}
                    style={{
                      padding: '12px 16px',
                      fontSize: '14px',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                      <Psychology style={{ color: '#666' }} />
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ fontWeight: 500, fontFamily: "Fira Sans" }}>Tarjetas de memoria</span>
                        <span style={{ color: '#666', fontSize: '12px', fontFamily: "Fira Sans" }}>Responde a flashcards</span>
                      </div>
                    </div>
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </Container>
        </AppBar>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{
            display: 'flex',
            flex: 1,
            gap: 3,
            p: 3,
            height: 'calc(100vh - 64px)',
            overflow: 'hidden',
            '& ::-webkit-scrollbar': {
              width: '6px',
            },
            '& ::-webkit-scrollbar-track': {
              background: '#f1f1f1',
              borderRadius: '3px',
            },
            '& ::-webkit-scrollbar-thumb': {
              background: 'rgba(0, 0, 0, 0.38)',
              borderRadius: '3px',
            }
          }}>
            {/* Left Grid - PDF Viewer */}
            <Box sx={{
              flex: 1,
              bgcolor: 'white',
              borderRadius: 2,
              border: '1px solid #E2F1F1',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              height: '100%'
            }}>
              {error ? (
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  p: 3,
                  textAlign: 'center'
                }}>
                  <Typography
                    sx={{
                      color: '#666',
                      fontFamily: 'Fira Sans',
                      mb: 2
                    }}
                  >
                    {error}
                  </Typography>
                </Box>
              ) : (
                <div className="pdf-viewer-container" onMouseUp={handleTextSelection}>
                  <div className="pdf-content">
                    <div className="controls-container">
                      <div className="zoom-controls">
                        <IconButton onClick={zoomOut} size="small">
                          <ZoomOut />
                        </IconButton>
                        <span>{Math.round(scale * 100)}%</span>
                        <IconButton onClick={zoomIn} size="small">
                          <ZoomIn />
                        </IconButton>
                      </div>
                    </div>
                    <Document
                      file={pdfUrl}
                      onLoadSuccess={handleDocumentLoadSuccess}
                      onLoadError={handleDocumentLoadError}
                      loading={<CircularProgress />}
                      className="pdf-document"
                      options={options}
                    >
                      {Array.from(new Array(numPages), (el, index) => (
                        <Page
                          key={`page_${index + 1}_${scale}`}
                          pageNumber={index + 1}
                          scale={scale}
                          className="pdf-page"
                          renderTextLayer={true}
                          renderAnnotationLayer={true}
                          onLoadSuccess={() => {
                            const textLayer = document.querySelector(`.react-pdf__Page__textContent[data-page-number="${index + 1}"]`);
                            if (textLayer) {
                              // Make text invisible but selectable
                              textLayer.style.color = 'transparent';
                              textLayer.style.userSelect = 'text';
                              // Prevent text layer from capturing all clicks
                              textLayer.style.pointerEvents = 'none';
                              // Add specific styling to improve selection behavior
                              textLayer.style.position = 'absolute';
                              textLayer.style.top = '0';
                              textLayer.style.left = '0';
                              textLayer.style.transform = 'none';
                              // Add event listener
                              textLayer.addEventListener('mouseup', handleTextSelection);
                            }
                          }}
                          onUnloadSuccess={() => {
                            const textLayer = document.querySelector(`.react-pdf__Page__textContent[data-page-number="${index + 1}"]`);
                            if (textLayer) {
                              textLayer.removeEventListener('mouseup', handleTextSelection);
                            }
                          }}
                        />
                      ))}
                    </Document>
                  </div>
                </div>
              )}
            </Box>

            {/* Right Grid - Flashcards */}
            <Box sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              overflow: 'hidden'
            }}>
              <Box sx={{
                borderBottom: '1px solid #E0E0E0',
                marginBottom: '8px',
                paddingBottom: '8px'
              }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  sx={{
                    '& .MuiTabs-indicator': {
                      display: 'none'
                    },
                    '& .MuiTab-root': {
                      textTransform: 'none',
                      fontSize: '14px',
                      fontFamily: 'Inter',
                      color: '#666666',
                      minWidth: 'unset',
                      padding: '4px 12px',
                      marginRight: '8px',
                      borderRadius: '8px',
                      height: '40px',
                      minHeight: 'unset',
                      '&.Mui-selected': {
                        color: 'white',
                        backgroundColor: '#6BBDBD',
                        fontWeight: 500
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: '16px',
                        marginRight: '4px',
                        marginBottom: '0px',
                        color: 'inherit'
                      }
                    },
                    '& .MuiTabs-flexContainer': {
                      gap: '4px',
                      minHeight: 'unset'
                    },
                    width: '100%',
                    paddingLeft: '0px',
                  }}
                >
                  <Tab
                    icon={<QuestionMark />}
                    iconPosition="start"
                    label="Tarjetas de memoria"
                    disableRipple
                  />
                  <Tab
                    icon={<Style />}
                    iconPosition="start"
                    label="Test"
                    disableRipple
                  />
                </Tabs>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                <Paper
                  elevation={0}
                  sx={{
                    backgroundColor: '#F8F8F8',
                    padding: '16px',
                    borderRadius: '8px',
                    flex: 1
                  }}
                >
                  <Typography sx={{ color: '#666666', fontFamily: 'Fira Sans' }}>
                    Subraya una sección del PDF para crear más preguntas y tarjetas o utiliza el siguiente botón para crearlas con IA.
                  </Typography>
                </Paper>
                <Button
                  onClick={handleAddButtonClick}
                  endIcon={
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        transition: 'transform 0.2s ease',
                        transform: Boolean(addButtonAnchorEl) ? 'rotate(180deg)' : 'rotate(0deg)'
                      }}
                    >
                      <path
                        d="M5 7.5L10 12.5L15 7.5"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                  startIcon={<AddIcon sx={{ fontSize: 20 }} />}
                  sx={{
                    backgroundColor: '#6BBDBD',
                    border: '1px solid #6BBDBD',
                    color: 'white',
                    borderRadius: '20px',
                    padding: '8px 20px',
                    textTransform: 'none',
                    fontFamily: 'Fira Sans',
                    fontSize: '14px',
                    fontWeight: 500,
                    minWidth: '140px',
                    transition: 'all 0.2s ease',
                    boxShadow: '0 2px 4px rgba(107, 189, 189, 0.2)',
                    '&:hover': {
                      backgroundColor: '#5aa9a9',
                      borderColor: '#5aa9a9',
                      transform: 'translateY(-1px)',
                      boxShadow: '0 4px 8px rgba(107, 189, 189, 0.3)'
                    },
                    '&:active': {
                      transform: 'translateY(0)',
                      boxShadow: '0 2px 4px rgba(107, 189, 189, 0.2)'
                    },
                    '& .MuiButton-startIcon': {
                      marginRight: '4px'
                    },
                    '& .MuiButton-endIcon': {
                      marginLeft: '2px'
                    },
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px'
                  }}
                >
                  Añadir
                </Button>
                <Menu
                  anchorEl={addButtonAnchorEl}
                  open={Boolean(addButtonAnchorEl)}
                  onClose={handleAddButtonClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  PaperProps={{
                    elevation: 3,
                    style: {
                      marginTop: '8px',
                      borderRadius: '16px',
                      minWidth: '240px',
                      border: '1px solid #E2F1F1',
                      boxShadow: '0 4px 20px rgba(107, 189, 189, 0.15)',
                      animation: 'fadeIn 0.2s ease',
                    },
                  }}
                  sx={{
                    '& .MuiList-root': {
                      padding: '8px',
                    }
                  }}
                >
                  <MenuItem
                    onClick={handleAddWithAI}
                    sx={{
                      padding: '12px',
                      borderRadius: '12px',
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        backgroundColor: '#F8FFFF',
                      }
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '12px', width: '100%' }}>
                      <div style={{
                        backgroundColor: '#E2F1F1',
                        borderRadius: '8px',
                        padding: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <AddCircleOutline style={{ color: '#6BBDBD' }} />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <span style={{ fontWeight: 500, fontFamily: "Fira Sans", color: '#2D2D2D' }}>
                          Añadir con IA
                        </span>
                        <span style={{ color: '#666', fontSize: '12px', fontFamily: "Fira Sans" }}>
                          Generar contenido automáticamente
                        </span>
                      </div>
                    </div>
                  </MenuItem>
                  <Divider style={{ margin: '8px 0', backgroundColor: '#E2F1F1' }} />
                  <MenuItem
                    onClick={handleAddManually}
                    sx={{
                      padding: '12px',
                      borderRadius: '12px',
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        backgroundColor: '#F8FFFF',
                      }
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '12px', width: '100%' }}>
                      <div style={{
                        backgroundColor: '#E2F1F1',
                        borderRadius: '8px',
                        padding: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}>
                        <Add style={{ color: '#6BBDBD' }} />
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <span style={{ fontWeight: 500, fontFamily: "Fira Sans", color: '#2D2D2D' }}>
                          Añadir manualmente
                        </span>
                        <span style={{ color: '#666', fontSize: '12px', fontFamily: "Fira Sans" }}>
                          Crear contenido personalizado
                        </span>
                      </div>
                    </div>
                  </MenuItem>
                </Menu>
              </Box>

              {/* Loading indicators for both tabs */}
              {(isGeneratingQuestions || isGeneratingFlashcards) && (
                <Box sx={{
                  mb: 2,
                  p: 2,
                  backgroundColor: '#E2F1F1',
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2
                }}>
                  <CircularProgress size={20} sx={{ color: '#6BBDBD' }} />
                  <Typography sx={{
                    fontFamily: 'Fira Sans',
                    color: '#666666',
                    fontSize: '14px'
                  }}>
                    {tabValue === 0 ? generatingFlashcardsMessage : generatingQuestionsMessage}
                  </Typography>
                </Box>
              )}

              {tabValue === 0 ? (
                <Box sx={{
                  overflowY: 'auto',
                  flex: 1,
                  maxHeight: 'calc(100vh - 180px)',
                  paddingRight: '16px',
                  '& ::-webkit-scrollbar': {
                    width: '6px',
                  },
                  '& ::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                    borderRadius: '3px',
                  },
                  '& ::-webkit-scrollbar-thumb': {
                    background: 'rgba(0, 0, 0, 0.38)',
                    borderRadius: '3px',
                  }
                }}>
                  {cards.length === 0 ? (
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      color: '#666',
                      textAlign: 'center',
                      p: 3
                    }}>
                      <Psychology sx={{ fontSize: 48, color: '#E2F1F1', mb: 2 }} />
                      <Typography sx={{ fontFamily: 'Fira Sans', fontWeight: 500, mb: 1 }}>
                        No hay tarjetas
                      </Typography>
                      <Typography sx={{ fontFamily: 'Fira Sans', color: '#666', fontSize: '14px' }}>
                        Crea una nueva tarjeta para empezar a estudiar
                      </Typography>
                    </Box>
                  ) : (
                    cards.map((card, index) => (
                      <Card
                        key={card.id}
                        sx={{
                          mb: 2,
                          border: '1px solid #E2F1F1',
                          borderRadius: 1,
                          boxShadow: 'none',
                          '&:last-child': {
                            mb: 0
                          },
                          position: 'relative'
                        }}
                      >
                        <Box sx={{
                          position: 'absolute',
                          top: 16,
                          right: 16,
                          display: 'flex',
                          gap: 1
                        }}>
                          <IconButton
                            size="small"
                            sx={{
                              color: '#666',
                              '&:hover': { color: '#6BBDBD' },
                              padding: '4px',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditClick(e, card);
                            }}
                          >
                            <Edit sx={{ fontSize: '16px' }} />
                          </IconButton>
                          <IconButton
                            size="small"
                            sx={{
                              color: '#666',
                              '&:hover': { color: '#f44336' },
                              padding: '4px',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteClick(e, card);
                            }}
                          >
                            <Delete sx={{ fontSize: '16px' }} />
                          </IconButton>
                        </Box>
                        <CardContent sx={{ p: 4 }}>
                          <Typography sx={{ fontFamily: 'Fira Sans', fontWeight: 500, color: '#2D2D2D', mb: 2 }}>
                            {formatTextWithSolutions(card.question)}
                          </Typography>
                          <Divider sx={{ my: 2 }} />
                          <Typography sx={{ fontFamily: 'Fira Sans', color: '#666' }}>
                            {formatTextWithSolutions(card.answer)}
                          </Typography>
                        </CardContent>
                      </Card>
                    ))
                  )}
                </Box>
              ) : (
                <Box sx={{
                  overflowY: 'auto',
                  flex: 1,
                  maxHeight: 'calc(100vh - 180px)',
                  paddingRight: '16px',
                  '& ::-webkit-scrollbar': {
                    width: '6px',
                  },
                  '& ::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                    borderRadius: '3px',
                  },
                  '& ::-webkit-scrollbar-thumb': {
                    background: 'rgba(0, 0, 0, 0.38)',
                    borderRadius: '3px',
                  }
                }}>
                  {testQuestions.length === 0 ? (
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      color: '#666',
                      textAlign: 'center',
                      p: 3
                    }}>
                      <Quiz sx={{ fontSize: 48, color: '#E2F1F1', mb: 2 }} />
                      <Typography sx={{ fontFamily: 'Fira Sans', fontWeight: 500, mb: 1 }}>
                        No hay preguntas de test
                      </Typography>
                      <Typography sx={{ fontFamily: 'Fira Sans', color: '#666', fontSize: '14px' }}>
                        Crea una nueva pregunta para empezar a estudiar
                      </Typography>
                    </Box>
                  ) : (
                    testQuestions.map((question, index) => (
                      <Card
                        key={question.id}
                        sx={{
                          mb: 2,
                          border: '1px solid #E2F1F1',
                          borderRadius: 1,
                          boxShadow: 'none',
                          '&:last-child': {
                            mb: 0
                          },
                          position: 'relative'
                        }}
                      >
                        <Box sx={{ 
                          position: 'absolute',
                          top: 16,
                          right: 16,
                          display: 'flex',
                          gap: 1
                        }}>
                          {editingQuestionId === question.id ? (
                            <IconButton 
                              size="small"
                              sx={{ 
                                color: '#66BB6A',
                                '&:hover': { color: '#45a049' },
                                padding: '4px',
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setEditingQuestionId(null);
                                handleEditSave(question.id);
                              }}
                            >
                              <Save sx={{ fontSize: '20px' }} />
                            </IconButton>
                          ) : (
                            <IconButton 
                              size="small"
                              sx={{ 
                                color: '#666',
                                '&:hover': { color: '#6BBDBD' },
                                padding: '4px',
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setEditingQuestionId(question.id);
                              }}
                            >
                              <Edit sx={{ fontSize: '16px' }} />
                            </IconButton>
                          )}
                          <IconButton 
                            size="small"
                            sx={{ 
                              color: '#666',
                              '&:hover': { color: '#f44336' },
                              padding: '4px',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteQuestionClick(question);
                            }}
                          >
                            <Delete sx={{ fontSize: '16px' }} />
                          </IconButton>
                        </Box>
                        <CardContent sx={{ p: 4 }}>
                          {editingQuestionId === question.id ? (
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                              <TextField
                                fullWidth
                                value={question.question}
                                onChange={(e) => {
                                  setTestQuestions(prev => prev.map(q => 
                                    q.id === question.id 
                                      ? { ...q, question: e.target.value }
                                      : q
                                  ));
                                }}
                                variant="standard"
                                sx={{
                                  '& .MuiInput-underline:before': {
                                    borderBottom: 'none'
                                  },
                                  '& .MuiInput-underline:hover:before': {
                                    borderBottom: 'none'
                                  },
                                  '& .MuiInput-underline:after': {
                                    borderBottomColor: '#E2F1F1'
                                  },
                                  fontFamily: 'Fira Sans',
                                }}
                              />
                              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                {question.options.map((option, optIndex) => (
                                  <Box
                                    key={optIndex}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 1,
                                      mb: 1
                                    }}
                                  >
                                    <Checkbox
                                      checked={optIndex === question.correctAnswer}
                                      onChange={(e) => {
                                        setTestQuestions(prev => prev.map(q => 
                                          q.id === question.id 
                                            ? { ...q, correctAnswer: e.target.checked ? optIndex : q.correctAnswer }
                                            : q
                                        ));
                                      }}
                                      sx={{
                                        color: '#6BBDBD',
                                        '&.Mui-checked': {
                                          color: '#6BBDBD'
                                        }
                                      }}
                                    />
                                    <TextField
                                      fullWidth
                                      value={option}
                                      onChange={(e) => {
                                        const newOptions = [...question.options];
                                        newOptions[optIndex] = e.target.value;
                                        setTestQuestions(prev => prev.map(q => 
                                          q.id === question.id 
                                            ? { ...q, options: newOptions }
                                            : q
                                        ));
                                      }}
                                      variant="standard"
                                      placeholder={`Opción ${optIndex + 1}`}
                                      sx={{
                                        '& .MuiInput-underline:before': {
                                          borderBottom: 'none'
                                        },
                                        '& .MuiInput-underline:hover:before': {
                                          borderBottom: 'none'
                                        },
                                        '& .MuiInput-underline:after': {
                                          borderBottomColor: '#E2F1F1'
                                        },
                                        fontFamily: 'Fira Sans',
                                      }}
                                    />
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          ) : (
                            <>
                              <Typography sx={{ 
                                fontFamily: 'Fira Sans', 
                                fontWeight: 500, 
                                color: '#2D2D2D', 
                                mb: 2 
                              }}>
                                {question.question}
                              </Typography>
                              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                {question.options.map((option, optIndex) => (
                                  <Typography 
                                    key={optIndex}
                                    sx={{ 
                                      fontFamily: 'Fira Sans', 
                                      color: optIndex === question.correctAnswer ? '#66BB6A' : '#666',
                                      fontWeight: optIndex === question.correctAnswer ? 500 : 400,
                                      pl: 2,
                                      '&::before': {
                                        content: `"${String.fromCharCode(65 + optIndex)}."`,
                                        marginRight: '8px',
                                        fontWeight: 500
                                      }
                                    }}
                                  >
                                    {option}
                                  </Typography>
                                ))}
                              </Box>
                            </>
                          )}
                        </CardContent>
                      </Card>
                    ))
                  )}
                </Box>
              )}
            </Box>
          </Box>
        )}
      </Box>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            padding: "24px",
            maxWidth: "400px",
            width: "100%"
          }
        }}
      >
        <DialogTitle sx={{
          padding: "0",
          fontFamily: 'Fira Sans',
        }}>
          <Typography sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: 'Fira Sans',
            color: '#000',
            mb: 1
          }}>
            Confirmar eliminación
          </Typography>
          <Typography sx={{
            fontSize: '14px',
            color: '#A0A0A0',
            fontFamily: 'Fira Sans',
            mb: 2
          }}>
            ¿Estás seguro de que quieres eliminar esta flashcard?
          </Typography>
        </DialogTitle>
        <DialogActions sx={{
          padding: "16px 0 0 0",
          marginTop: 'auto'
        }}>
          <Button
            onClick={handleDeleteCancel}
            disabled={isDeleting}
            sx={{
              color: '#A0A0A0',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#666'
              }
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            disabled={isDeleting}
            sx={{
              backgroundColor: '#AA161D',
              color: 'white',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              borderRadius: '20px',
              padding: '6px 16px',
              '&:hover': {
                backgroundColor: '#8A1219'
              },
              minWidth: '100px'
            }}
          >
            {isDeleting ? (
              <CircularProgress size={20} sx={{ color: 'white' }} />
            ) : (
              'Eliminar'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <EditCard
        open={editDialogOpen}
        onClose={() => {
          setEditDialogOpen(false);
          setCardToEdit(null);
        }}
        card={cardToEdit}
        onSave={handleCreateEditFlashcard}
      />

      <CrearCard
        open={createFlashcard}
        onClose={() => setCreateFlashcard(false)}
        onSave={handleCreateEditFlashcard}
      />

      <DeleteQuestionDialog
        open={deleteQuestionDialogOpen}
        onClose={handleDeleteQuestionCancel}
        onConfirm={handleDeleteQuestionConfirm}
      />

      <TextSelectionDialog
        open={isTextSelectionModalOpen}
        onClose={() => setIsTextSelectionModalOpen(false)}
        selectedText={selectedText}
        isTextTruncated={isTextTruncated}
        onGenerateQuestions={handleGenerateQuestions}
        onGenerateCards={handleGenerateCards}
      />

      <LoadingDialog open={isGeneratingContent} />

      <FlashcardsDialog
        open={showFlashcardsDialog}
        onClose={() => {
          setShowFlashcardsDialog(false);
          setSelectedFlashcards([]);
        }}
        flashcards={formattedCards}
        selectedFlashcards={selectedFlashcards}
        onFlashcardSelect={handleFlashcardSelect}
        editingIndex={editingFlashcardIndex}
        editedFlashcard={editedFlashcard}
        onEditStart={(index, flashcard) => {
          setEditingFlashcardIndex(index);
          setEditedFlashcard({ ...flashcard });
        }}
        onEditCancel={() => {
          setEditingFlashcardIndex(null);
          setEditedFlashcard(null);
        }}
        onEditSave={(index) => {
          const updatedFlashcards = [...formattedCards];
          updatedFlashcards[index] = editedFlashcard;
          setFormattedCards(updatedFlashcards);
          setEditingFlashcardIndex(null);
          setEditedFlashcard(null);
        }}
        onEditChange={setEditedFlashcard}
        formatTextWithSolutions={formatTextWithSolutions}
        onAdd={async () => {
          const selectedFormattedFlashcards = selectedFlashcards.map(index => formattedCards[index]);

          try {
            // Create all selected flashcards
            for (const flashcard of selectedFormattedFlashcards) {
              await handleCreateEditFlashcard({
                question: flashcard.question,
                answer: flashcard.answer,
                answers: flashcard.answers,
                type: 'flashcard'
              });
            }

            setShowFlashcardsDialog(false);
            setSelectedFlashcards([]);
            setSnackbar({
              open: true,
              message: 'Tarjetas añadidas correctamente',
              severity: 'success'
            });
          } catch (error) {
            console.error('Error creating flashcards:', error);
            setSnackbar({
              open: true,
              message: 'Error al añadir las tarjetas',
              severity: 'error'
            });
          }
        }}
      />

      <QuestionsDialog
        open={showQuestionsDialog}
        onClose={() => {
          setShowQuestionsDialog(false);
          setSelectedQuestions([]);
        }}
        questions={formattedQuestions}
        selectedQuestions={selectedQuestions}
        onQuestionSelect={handleQuestionSelect}
        editingQuestionIndex={editingGeneratedQuestionIndex}
        setEditingQuestionIndex={setEditingGeneratedQuestionIndex}
        editedQuestion={editedQuestion}
        setEditedQuestion={setEditedQuestion}
        onSaveEdit={(index) => {
          const updatedQuestions = [...formattedQuestions];
          updatedQuestions[index] = editedQuestion;
          setFormattedQuestions(updatedQuestions);
          setEditingGeneratedQuestionIndex(null);
          setEditedQuestion(null);
        }}
        onAddQuestions={() => {
          const selectedFormattedQuestions = selectedQuestions.map(index => formattedQuestions[index]);

          // Create all selected questions
          Promise.all(selectedFormattedQuestions.map(async (question) => {
            return await handleCreateTestQuestion({
              question: question.question,
              options: question.options,
              correctAnswer: question.correctAnswer
            });
          }))
            .then((results) => {
              const successCount = results.filter(Boolean).length;
              const failureCount = results.length - successCount;

              let message = `${successCount} preguntas añadidas`;
              if (failureCount > 0) {
                message += `, ${failureCount} fallidas`;
              }

              setSnackbar({
                open: true,
                message: message,
                severity: failureCount > 0 ? 'warning' : 'success'
              });
            })
            .catch((error) => {
              console.error('Error adding questions:', error);
              setSnackbar({
                open: true,
                message: 'Error al añadir preguntas',
                severity: 'error'
              });
            });

          setShowQuestionsDialog(false);
          setSelectedQuestions([]);
        }}
      />

      <GenerateQuestionsDialog
        open={openGenerateModal}
        onClose={handleCloseGenerateModal}
        numberOfQuestions={numberOfQuestions}
        setNumberOfQuestions={setNumberOfQuestions}
        onGenerate={handleGenerateMore}
        isGenerating={isGenerating}
      />

      <GenerateFlashcardsDialog
        open={openGenerateFlashcardsModal}
        onClose={handleCloseGenerateFlashcardsModal}
        numberOfFlashcards={numberOfFlashcards}
        setNumberOfFlashcards={setNumberOfFlashcards}
        onGenerate={handleGenerateMoreFlashcards}
        isGenerating={isGeneratingFlashcards}
      />

      <CreateQuestionDialog
        open={isCreateQuestionDialogOpen}
        onClose={() => setIsCreateQuestionDialogOpen(false)}
        onSave={handleCreateQuestion}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{
            width: '100%',
            fontFamily: 'Fira Sans',
            backgroundColor: '#6BBDBD',
            color: 'white',
            '& .MuiAlert-message': {
              fontFamily: 'Fira Sans',
              color: 'white'
            },
            '& .MuiAlert-icon': {
              color: 'white'
            },
            '& .MuiAlert-action': {
              color: 'white',
              '& .MuiSvgIcon-root': {
                color: 'white'
              }
            }
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Editar;
