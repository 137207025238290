import React, { useState, useEffect } from "react";
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    TextField,
    Typography,
    Paper,
    Divider,
    IconButton,
    InputAdornment,
    Alert,
    Container,
    Grid,
    SvgIcon
} from '@mui/material';
import { Google as GoogleIcon, Visibility, VisibilityOff } from '@mui/icons-material';
import Spinner from "../NewComponents/Spinner";

// Senja Widget Component
const SenjaWidget = () => {
    useEffect(() => {
        // Create and load the script
        const script = document.createElement('script');
        script.src = 'https://widget.senja.io/widget/12f3c1ab-d517-401d-b266-87a5956c7a3f/platform.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            // Cleanup on unmount
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div
            className="senja-embed"
            data-id="12f3c1ab-d517-401d-b266-87a5956c7a3f"
            data-mode="shadow"
            data-lazyload="false"
            style={{
                display: 'block',
                width: '100%',
                height: '400px', // Fixed height for better control
                maxWidth: '500px', // Maximum width to maintain proportions
                margin: '0 auto', // Center horizontally
            }}
        />
    );
};
// Add the GoogleColoredIcon component
const GoogleColoredIcon = () => (
    <SvgIcon sx={{ width: 20, height: 20 }}>
        <svg viewBox="0 0 24 24">
            <path
                fill="#4285F4"
                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
            />
            <path
                fill="#34A853"
                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
            />
            <path
                fill="#FBBC05"
                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
            />
            <path
                fill="#EA4335"
                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
            />
        </svg>
    </SvgIcon>
);

const Login = () => {
    const supabase = useSupabaseClient();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isResetMode, setIsResetMode] = useState(false);
    const [isMagicLinkMode, setIsMagicLinkMode] = useState(false);
    const [mode, setMode] = useState('login'); // 'login' or 'signup'
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');

    useEffect(() => {
        const checkSession = async () => {
            const { data: { session } } = await supabase.auth.getSession();
            if (session) {
                navigate('/login-success');
            }
        };

        checkSession();
    }, [supabase.auth, navigate]);

    const handleGoogleLogin = async () => {
        setLoading(true);
        try {
            const { data, error } = await supabase.auth.signInWithOAuth({
                provider: 'google',
                options: {
                    redirectTo: `${window.location.origin}/auth/callback`,
                    queryParams: {
                        access_type: 'offline',
                        prompt: 'consent',
                    }
                }
            });

            if (error) {
                console.error('Login error:', error.message);
                setError(error.message);
                throw error;
            }

            console.log('Login successful:', data);
        } catch (error) {
            console.error('Error logging in:', error.message);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleEmailAuth = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            let result;
            if (mode === 'login') {
                // Remove the profiles check and directly attempt login
                result = await supabase.auth.signInWithPassword({
                    email,
                    password,
                });

                if (result.error) {
                    if (result.error.message === 'Invalid login credentials') {
                        throw new Error('La contraseña es incorrecta para esta cuenta.');
                    }
                    throw result.error;
                }

                // Check if user was created in the last two days
                const user = result.data.user;
                const createdAt = new Date(user.created_at);
                const twoDaysAgo = new Date();
                twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

                if (createdAt < twoDaysAgo) {
                    setError("This account was created more than two days ago. Please contact support.");
                    return;
                }

                // Redirect to login-success after successful login
                navigate('/login-success');
            } else {
                // Handle signup
                const siteUrl = process.env.REACT_APP_FRONT_END
                    ? `https://${process.env.REACT_APP_FRONT_END}`
                    : window.location.origin;
                const redirectTo = `${siteUrl}/auth/callback`;

                result = await supabase.auth.signUp({
                    email,
                    password,
                    options: {
                        emailRedirectTo: redirectTo,
                        data: {
                            name,
                            surname
                        }
                    }
                });

                if (result.error) throw result.error;

                if (result.data?.user) {
                    // Redirect to verify page after successful signup
                    navigate('/verify');
                    return;
                }
            }
        } catch (error) {
            console.error('Auth error:', error.message);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleMagicLinkLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const { error } = await supabase.auth.signInWithOtp({
                email,
                options: {
                    emailRedirectTo: `${window.location.origin}/auth/callback`
                }
            });

            if (error) throw error;

            setSuccess('¡Te hemos enviado un enlace mágico! Por favor, revisa tu email.');
        } catch (error) {
            console.error('Error sending magic link:', error.message);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const { error } = await supabase.auth.resetPasswordForEmail(email, {
                redirectTo: `${window.location.origin}/update-password`,
            });

            if (error) throw error;

            setSuccess('Te hemos enviado un email para restablecer tu contraseña.');
        } catch (error) {
            console.error('Error resetting password:', error.message);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Spinner />;
    }

    const renderForm = () => {
        if (isMagicLinkMode) {
            return (
                <form onSubmit={handleMagicLinkLogin}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                fullWidth
                                sx={textFieldStyle}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                sx={{
                                    py: 1.5,
                                    backgroundColor: '#026277',
                                    '&:hover': {
                                        backgroundColor: '#015666'
                                    }
                                }}
                            >
                                Enviar enlace mágico
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                onClick={() => {
                                    setIsMagicLinkMode(false);
                                    setError(null);
                                    setSuccess(null);
                                }}
                                fullWidth
                                sx={{
                                    textTransform: 'none',
                                    color: '#666'
                                }}
                            >
                                Volver al login normal
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            );
        }

        if (isResetMode) {
            return (
                <form onSubmit={handlePasswordReset}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                fullWidth
                                sx={textFieldStyle}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                sx={{
                                    py: 1.5,
                                    backgroundColor: '#026277',
                                    '&:hover': {
                                        backgroundColor: '#015666'
                                    }
                                }}
                            >
                                Restablecer contraseña
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                onClick={() => {
                                    setIsResetMode(false);
                                    setError(null);
                                    setSuccess(null);
                                }}
                                fullWidth
                                sx={{
                                    textTransform: 'none',
                                    color: '#666'
                                }}
                            >
                                Volver al login normal
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            );
        }

        return (
            <>
                {/* Google Button */}
                <Button
                    fullWidth
                    startIcon={<GoogleColoredIcon />}
                    onClick={handleGoogleLogin}
                    sx={{
                        mb: 3,
                        py: 1.5,
                        textTransform: 'none',
                        backgroundColor: '#fff',
                        color: '#2c2c2c',
                        borderColor: '#e0e0e0',
                        fontFamily: "'Fira Sans', sans-serif",
                        fontWeight: 500,
                        fontSize: '14px',
                        borderRadius: '12px',
                        border: '1.5px solid #e0e0e0',
                        '&:hover': {
                            borderColor: '#e0e0e0',
                            backgroundColor: '#f8f8f8',
                        },
                    }}
                >
                    Continuar con Google
                </Button>

                <Divider sx={{ my: 3 }}>o</Divider>

                {/* Form */}
                <form onSubmit={handleEmailAuth}>
                    <Grid container spacing={2}>
                        {mode === 'signup' && (
                            <>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Nombre"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                        fullWidth
                                        sx={textFieldStyle}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Apellido"
                                        value={surname}
                                        onChange={(e) => setSurname(e.target.value)}
                                        required
                                        fullWidth
                                        sx={textFieldStyle}
                                    />
                                </Grid>
                            </>
                        )}

                        <Grid item xs={12}>
                            <TextField
                                label="Email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                fullWidth
                                sx={textFieldStyle}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Contraseña"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={textFieldStyle}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                sx={{
                                    py: 1.5,
                                    backgroundColor: '#026277',
                                    '&:hover': {
                                        backgroundColor: '#015666'
                                    }
                                }}
                            >
                                {mode === 'login' ? 'Iniciar sesión' : 'Crear cuenta'}
                            </Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                <Button
                                    onClick={() => {
                                        setIsMagicLinkMode(true);
                                        setError(null);
                                        setSuccess(null);
                                    }}
                                    sx={{
                                        textTransform: 'none',
                                        color: '#666'
                                    }}
                                >
                                    Entrar con enlace
                                </Button>
                                <Button
                                    onClick={() => {
                                        setIsResetMode(true);
                                        setError(null);
                                        setSuccess(null);
                                    }}
                                    sx={{
                                        textTransform: 'none',
                                        color: '#666'
                                    }}
                                >
                                    ¿Olvidaste tu contraseña?
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>

                <Typography align="center" sx={{ mt: 2, color: 'text.secondary' }}>
                    {mode === 'login' ? "¿No tienes una cuenta? " : "¿Ya tienes una cuenta? "}
                    <Button
                        onClick={() => setMode(mode === 'login' ? 'signup' : 'login')}
                        sx={{
                            color: '#026277',
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor: 'transparent',
                                textDecoration: 'underline'
                            }
                        }}
                    >
                        {mode === 'login' ? 'Crear cuenta' : 'Iniciar sesión'}
                    </Button>
                </Typography>
            </>
        );
    };

    return (
        <Box sx={{
            height: '100vh',
            display: 'flex',
            p: 3,
            backgroundColor: '#f5f5f5',
        }}>
            {/* Left side - Can be replaced with your own content */}
            <Box
                sx={{
                    flex: 1,
                    position: 'relative',
                    overflow: 'hidden',
                    display: { xs: 'none', md: 'flex' },
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: `linear-gradient(140deg, 
                        #83D4D4 0%,
                        #6BBDBD 45%,
                        #5aa9a9 75%,
                        #499b9b 100%
                    )`,
                    borderRadius: '24px',
                    mr: 3,
                    p: 6,
                }}
            >
                <Box
                    sx={{
                        position: 'relative',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <SenjaWidget />
                </Box>
            </Box>

            {/* Right side - login form */}
            <Box
                sx={{
                    flex: 1.4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#ffffff',
                    borderRadius: '24px',
                    position: 'relative',
                    boxShadow: '0 4px 24px rgba(0, 0, 0, 0.04)',
                }}
            >
                <Paper
                    elevation={0}
                    sx={{
                        p: { xs: 3, md: 4 },
                        width: '100%',
                        maxWidth: 400,
                        backgroundColor: 'transparent',
                    }}
                >
                    <Typography
                        variant="h4"
                        component="h1"
                        gutterBottom
                        align="center"
                        sx={{
                            mb: 4,
                            fontWeight: 600,
                            color: '#1a1a1a',
                            fontSize: '26px',
                            fontFamily: "'Fira Sans', sans-serif",
                            letterSpacing: '-0.5px',
                        }}
                    >
                        {isResetMode ? 'Restablecer contraseña' :
                            isMagicLinkMode ? 'Login sin contraseña' :
                                'Entra a Typed'}
                    </Typography>

                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}

                    {success && (
                        <Alert severity="success" sx={{ mb: 2 }}>
                            {success}
                        </Alert>
                    )}

                    {renderForm()}
                </Paper>
            </Box>
        </Box>
    );
};

// Add this constant for TextField styling
const textFieldStyle = {
    '& .MuiOutlinedInput-root': {
        borderRadius: '12px',
        '& fieldset': {
            borderColor: '#e0e0e0',
            borderWidth: '1.5px',
        },
        '&:hover fieldset': {
            borderColor: '#6BBDBD',
            borderWidth: '1.5px',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#6BBDBD',
            borderWidth: '1.5px',
        },
    },
    '& .MuiInputLabel-root': {
        color: '#666',
        '&.Mui-focused': {
            color: '#6BBDBD',
        },
    },
    '& .MuiInputBase-input': {
        fontSize: '14px',
    },
};

export default Login;