import React, { useState, useEffect } from "react";
import {
    Container,
    Grid,
    Typography,
    Box,
    Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import NewPreguntasDocument from "./NewPreguntasDocument";
import WaitingQuestions from "./WaitingQuestions";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useStylesPreguntasDocument from './Styles/useStylePreguntasDocument';
import preguntasbloquedas from './preguntasbloquedas.svg';
import MySchemasList from './SchemasList'
import {

    Accordion, AccordionSummary, AccordionDetails, Skeleton
} from '@mui/material';
import { useParams } from "react-router-dom";
import Spinner from "../NewComponents/Spinner";
import Transcript from "./Transcript";
import PDFViewerComponent from "./Viewers/PdfViewer";
import CSVViewerFile from "./Viewers/CSVViewer";
import { fetchExamId, fetchExamsbyDocumentId, fetchFolderExams } from "../Api/ExamsApi";
import ExamList from "../NewComponents/ListExams";
import Summary from "./Summary";
import { fetchQuestions } from "../Api/ChaptersApi";
import GenerantPreguntes from "./GenerantPreguntes";
import DesbloquejarDocuments from "./DesbloquejarDocuments";
import ErrorPreguntesDoc from "./ErrorPreguntesDoc";
import { getCsrfToken } from "../Api/TokenApi";
import FlashcardDoc from '../Flashcards/FlashcardDoc';
import LockIcon from '@mui/icons-material/Lock';
import Tooltip from '@mui/material/Tooltip';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import StyleIcon from '@mui/icons-material/Style';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DescriptionIcon from '@mui/icons-material/Description';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import Select from 'react-select';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const useStyles = makeStyles((theme) => ({
    csvViewerContainer: {
        maxWidth: "800px", /* Maximum width */
        marginLeft: "auto", /* Auto margin for center alignment */
        marginRight: "auto", /* Auto margin for center alignment */
        /* Add any other styles you need */
    },
    containerSelectors: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f5f7fa',
        borderRadius: '10px',
        padding: '3px',
        gap: "3px",
        width: "fit-content",
        marginBottom: '15px',
        marginTop: '40px',
        paddingRight: "5px",
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    button: {
        borderRadius: "10px",
        fontSize: "16px",
        textTransform: "none",
        // '&:first-of-type': {
        //     borderTopLeftRadius: '5px',
        //     borderBottomLeftRadius: '5px',
        // },
        // '&:last-of-type': {
        //     borderTopRightRadius: '5px',
        //     borderBottomRightRadius: '5px',
        // },
    },
    selectedButton: {
        backgroundColor: 'white',
        color: '#000',
        border: 'none',
    },
    unselectedButton: {
        backgroundColor: 'transparent',
        color: '#000',
        border: 'none',
    },
    buttonUpload: {
        backgroundColor: "#6BBDBD",
        color: "white",
        textTransform: "none",
        borderRadius: "6px",
        fontSize: "12px",
        display: "flex",
        alignItems: "flex-end",
        gap: "2px",

        '&:disabled': {
            backgroundColor: '#B0B0B0', // Gray color for the disabled state
            color: '#FFFFFF'           // White text for the disabled state
        },
        '&:hover': {
            backgroundColor: '#6BBDBD', // Gray color for the disabled state
            color: "white",
            // padding: "8px 14px", // Added padding for better appearance

        },
        padding: "4px 11px !important"
    },
    grid: {
        flex: 1,
    },
}));
const SkeletonLoader = () => {
    const classes = useStylesPreguntasDocument();

    return (
        <Container className={classes.scrollableContainer}>
            {[1, 2, 3].map((_, index) => (
                <Accordion key={index} className={classes.accordionPreguntas}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}a-content`}
                        id={`panel${index}a-header`}
                        className={classes.accordionSummaryPreguntas}
                    >
                        <Skeleton variant="text" width="80%" height={30} />
                    </AccordionSummary>
                    <AccordionDetails>
                        <Skeleton variant="text" width="60%" />
                        <Skeleton variant="text" width="40%" />
                    </AccordionDetails>
                </Accordion>
            ))}
        </Container>
    );
}
function Document({ stepValue, messageStep, setQuestions, questions, loading, error,
    docInfo, setSummary, token, user, setMessage, setShowAlert, summary, handleCreateExam,
    listExams, setListExams, isGeneratingSummary, setIsGeneratingSummary, shouldCreateSummary,
    setNeedGenerateQuestions, needGenerateQuestions, errorGeneratingQuestions, chapters_generating, newGeneratingSummary, setNewGeneratingSummary, regeneratingQuestions, setRegeneratingQuestions,
    setShowUpgrade, userData, isGeneratingFlashcards, setIsGeneratingFlashcards, isLoadingSummary, isExamDocument }) {
    const { document_id, selectedTranscript } = useParams();
    const { folder_id, selectedFolder } = useParams();
    const [isCreatingSchemas, setIsCreatingSchemas] = useState(docInfo?.is_creating_schema || false);
    // const [listExams, setListExams] = useState([]);
    const classes = useStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation(); // Use useLocation to get the current location
    const [numberOfFlashcards, setNumberOfFlashcards] = useState(0);
    const [selected, setSelected] = useState(questions.is_blocked ? null : (shouldCreateSummary ? 'Summary' : 'Progreso'));
    const [totalFlashcards, setTotalFlashcards] = useState(docInfo?.total_flashcards || 0);
    const [anchorEl, setAnchorEl] = useState(null);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery('(max-width:800px)'); // Adjust breakpoint as needed

    const handleButtonClick = (value) => {
        setSelected(value);
    };
    const renderContentBasedOnType = () => {
        if (!docInfo) {
            return (<div> <Spinner left="40%" /></div>); // Or any other placeholder
        }
        const type = docInfo.type_dc;
        if (type === ".pdf") {
            return <PDFViewerComponent docInfo={docInfo} token={token} />;
        } else if (type === ".png" || type === ".jpg") {
            return <div>Image</div>;
        } else if (type === ".csv" || type === ".xlsx") {
            return <CSVViewerFile docInfo={docInfo} />;
        } else if (type === ".mp4" || type === ".mp3") {
            return <Transcript docInfo={docInfo} token={token} user={user} />;
        } else {
            return <div>Unknown File Type</div>;
        }
    };
    useEffect(() => {
        if (questions.is_blocked) {
            setSelected("");
        }
    }, [questions.is_blocked]);

    const handleGenerateQuestions = async () => {
        try {
            setNeedGenerateQuestions(true);
            const response = await fetch(`${BACKEND_URL}/api_v1/generation_questions/${docInfo.guid}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-CSRFToken': getCsrfToken()
                },
                body: JSON.stringify({
                    "needGenerateQuestions": true
                })
            });

            if (response.ok) {
                const data = await response.json();
                setMessage("Generando preguntas...");
                setShowAlert(true);
                // You might want to update the questions state here or trigger a re-fetch
                // For example: setQuestions({ ...questions, is_generating: true });
            } else {
                throw new Error('Failed to start question generation');
            }
        } catch (error) {
            console.error("Error generating questions:", error);
            setMessage(t("error_generating_questions"));
            setShowAlert(true);
            setNeedGenerateQuestions(false);
        }
    };
    const handleItemClick = (guid, isActive) => {
        if (!isActive) {
            // You can add any action here if needed when the item is not active/loading
            setMessage(t("exam_creating"))
            setShowAlert(true)
            return; // Early return to prevent action
        }
        // Redirect if the item is active
        // navigate(`exam/${guid}`);
        const basePath = location.pathname.split('/')[1]; // This gets the first segment after the root
        const newPath = `/${basePath}/exam/${guid}`;

        // Redirect if the item is active
        navigate(newPath);
    };
    const handleRemoveExam = async (exam_id) => {
        // Immediately filter out the exam optimistically

        try {
            setListExams(currentExams => {
                // Convert the object to an array of entries, filter out the exam, and convert back to an object
                const filteredEntries = Object.entries(currentExams).filter(([guid, examDetails]) => guid !== exam_id);
                return Object.fromEntries(filteredEntries);
            });      // Perform the deletion request
            await fetchExamId(token, "DELETE", exam_id, user);
            // No need to do anything here on success, as we've already updated the UI

        } catch (error) {
            console.error("this error", error);
            // Handle error - e.g., show an alert or undo the optimistic update
            setMessage("Error removing exam. Please try again.");
            setShowAlert(true);
            // Optionally, fetch the exams again or revert the change in the state
        }
    };
    const [schemas, setSchemas] = useState([]);
    const [schemasError, setSchemasError] = useState(null);
    const [schemasLoading, setSchemasLoading] = useState(true);

    // Add schemas fetch effect
    useEffect(() => {
        const fetchSchemas = async () => {
            setSchemasLoading(true);
            try {
                const url = `${BACKEND_URL}/api_v1/documents/${docInfo.guid}/schemas/?user_id=${user.id}`;
               
                
                const response = await fetch(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch schemas');
                }
                const data = await response.json();
               
                // Sort schemas by date_creation in descending order (newest first)
                const sortedSchemas = data.sort((a, b) =>
                    new Date(b.date_creation) - new Date(a.date_creation)
                );
                setSchemas(sortedSchemas);
            } catch (error) {
                setSchemasError(error.message);
                console.error('Error fetching schemas:', error);
            } finally {
                setSchemasLoading(false);
            }
        };

        if (docInfo?.guid && user?.id && token) {
            fetchSchemas();
        }
    }, [docInfo?.guid, user?.id, token]);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (value) => {
        if (value) {
            setSelected(value);
        }
        setAnchorEl(null);
    };
    const renderContent = () => {
        switch (selected) {
            case 'Document':
                return (
                    <Grid container className={classes.grid}>
                        <Grid item xs={12}>
                            {renderContentBasedOnType()}
                        </Grid>
                    </Grid>
                );
            case 'Summary':
                return (

                    <>
                        {isLoadingSummary || !userData ? (
                            <Skeleton variant="rect" width="100%" height={300} sx={{ borderRadius: '10px', bgcolor: '#e0e0e0' }} />
                        ) : (
                            token && <Summary userData={userData} setSummary={setSummary} docInfo={docInfo} token={token} user={user} summary={summary} isGeneratingSummary={isGeneratingSummary} newGeneratingSummary={newGeneratingSummary} setNewGeneratingSummary={setNewGeneratingSummary} setIsGeneratingSummary={setIsGeneratingSummary} />
                        )}

                    </>
                );
            case 'Schemas':
                return token && <MySchemasList
                    documentId={docInfo.guid}
                    token={token}
                    user={user}
                    isCreatingSchemas={isCreatingSchemas}
                    setIsCreatingSchemas={setIsCreatingSchemas}
                    schemas={schemas}
                    setSchemas={setSchemas}
                    isLoading={schemasLoading}
                    error={schemasError}
                    summary={summary}
                    setShowUpgrade={setShowUpgrade}
                    docInfo={docInfo}
                />
            case 'Progreso':

                return (
                    <Grid container className={classes.grid}>

                        <Grid item xs={12}>
                            {Object.keys(questions).length > 0 ? (
                                errorGeneratingQuestions || questions.is_generating_error ? (
                                    <ErrorPreguntesDoc />
                                ) : !questions.topics ? (
                                    <SkeletonLoader />
                                ) : questions.topics.length === 0 && questions.question_generated ? (
                                    <SkeletonLoader />
                                ) : !questions.question_generated ? (
                                    needGenerateQuestions || questions.is_generating ? (
                                        <NewPreguntasDocument
                                            saving_questions={true}
                                            chapters_generating={chapters_generating}
                                            max_pages={docInfo?.info_doc?.pages || 0}
                                            loading={loading}
                                            chapters={questions.topics || []}
                                            setQuestions={setQuestions}
                                            user={user}
                                            token={token}
                                            docInfo={docInfo}
                                            regeneratingQuestions={regeneratingQuestions}
                                            setRegeneratingQuestions={setRegeneratingQuestions}
                                        />
                                    ) : (
                                        <WaitingQuestions handleGenerateQuestions={handleGenerateQuestions} />
                                    )
                                ) : (
                                    <NewPreguntasDocument
                                        saving_questions={false}
                                        chapters_generating={false}
                                        loading={loading}
                                        chapters={questions.topics || []}
                                        setQuestions={setQuestions}
                                        user={user}
                                        max_pages={docInfo?.info_doc?.pages || 0}
                                        token={token}
                                        docInfo={docInfo}
                                        regeneratingQuestions={regeneratingQuestions}
                                        setRegeneratingQuestions={setRegeneratingQuestions}
                                    />
                                )
                            ) : (
                                <SkeletonLoader />
                            )}

                        </Grid>
                    </Grid>
                );
            case 'Exams':
                return (
                    <Grid container className={classes.grid}>
                        <Grid item xs={12}>
                            {/* {listExams && (

                                <ExamList listExams={listExams} handleItemClick={handleItemClick} handleRemoveExam={handleRemoveExam} />
                            )} */}

                            {
                                listExams && Object.keys(listExams).length > 0 ? (

                                    <ExamList listExams={listExams} handleItemClick={handleItemClick} handleRemoveExam={handleRemoveExam} />


                                ) : (<>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginTop: "50px",
                                        height: '100%',
                                    }}>
                                        <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/emptyExams.svg" alt="Preview Exam" />
                                        <Typography style={{ fontSize: "12px", marginBottom: "10px" }}>{t("exams_dont_exist")}</Typography>
                                        <Button className={classes.buttonUpload} onClick={handleCreateExam}>{t("create_exam")}</Button>

                                    </div>
                                </>
                                )
                            }

                        </Grid>
                    </Grid>
                );
            case 'Flashcards':

                return <FlashcardDoc
                    documentId={document_id}
                    isGeneratingFlashcards={isGeneratingFlashcards}
                    setIsGeneratingFlashcards={setIsGeneratingFlashcards}
                    setNumberOfFlashcards={setNumberOfFlashcards}
                    numberOfFlashcards={numberOfFlashcards}
                    setTotalFlashcards={setTotalFlashcards}
                    docInfo={docInfo}
                />;
            default:
                return null;
        }
    };

    // async function fetchExams() {
    //     if (token && user) {

    //         try {

    //             const listExamsFetched = await fetchExamsbyDocumentId(token, "GET", docInfo.folder, docInfo.guid, user,);
    //             setListExams(listExamsFetched)
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     }

    // }
    // useEffect(() => {
    //     fetchExams()
    // }, [docInfo]);

    const options = [
        {
            value: 'Progreso',
            label: (
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <QuestionMarkIcon sx={{ fontSize: '16px' }} />
                    <span>{`Preguntas (${questions.topics ? questions.topics.reduce((total, topic) => total + topic.questions.length, 0) : 0})`}</span>
                </div>
            )
        },
        {
            value: 'Document',
            label: (
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <DescriptionIcon sx={{ fontSize: '16px' }} />
                    <span>Documento</span>
                </div>
            )
        },
        // Only include other options if not an exam document
        ...(!isExamDocument ? [
            {
                value: 'Flashcards',
                label: (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <StyleIcon sx={{ fontSize: '16px' }} />
                        <span>{`Flashcards (${totalFlashcards})`}</span>
                    </div>
                )
            },
            {
                value: 'Summary',
                label: (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <SummarizeIcon sx={{ fontSize: '16px' }} />
                        <span>Resumen</span>
                    </div>
                )
            },
            {
                value: 'Schemas',
                label: (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <AccountTreeIcon sx={{ fontSize: '16px' }} />
                        <span>{`Esquemas (${schemas.length})`}</span>
                    </div>
                )
            },

        ] : [])
    ];

    return (
        <>
            <Grid
                container
                style={{ display: "flex", flexDirection: "column", height: "90vh", backgroundColor: "#F8FFFF", width: "100%", flexWrap: "unset", }}
            >

                {questions.is_blocked ? (<Typography>Documento Bloqueado</Typography>) : (
                    <Box sx={{
                        borderBottom: '1px solid #E0E0E0',
                        marginBottom: '8px',
                        marginTop: '16px',
                        paddingBottom: '8px'
                    }}>
                        {isSmallScreen ? (
                            <Select
                                value={options.find(option => option.value === selected)}
                                onChange={(option) => handleButtonClick(option.value)}
                                options={options}
                                isSearchable={false}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: 'white',
                                        borderColor: '#E0E0E0',
                                        borderRadius: '8px',
                                        minHeight: '40px',
                                        boxShadow: 'none',
                                        '&:hover': {
                                            borderColor: '#6BBDBD'
                                        }
                                    }),
                                    option: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isSelected ? '#6BBDBD' : 'white',
                                        color: state.isSelected ? 'white' : '#666666',
                                        '&:hover': {
                                            backgroundColor: state.isSelected ? '#6BBDBD' : '#F5F5F5'
                                        },
                                        padding: '8px 12px',
                                        cursor: 'pointer'
                                    }),
                                    singleValue: (baseStyles) => ({
                                        ...baseStyles,
                                        color: '#666666'
                                    }),
                                    dropdownIndicator: (baseStyles) => ({
                                        ...baseStyles,
                                        color: '#666666',
                                        '&:hover': {
                                            color: '#6BBDBD'
                                        }
                                    }),
                                    menu: (baseStyles) => ({
                                        ...baseStyles,
                                        borderRadius: '8px',
                                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                    }),
                                    menuList: (baseStyles) => ({
                                        ...baseStyles,
                                        padding: '4px'
                                    })
                                }}
                                placeholder="Seleccionar vista"
                            />
                        ) : (
                            <Tabs
                                value={selected}
                                onChange={(event, newValue) => handleButtonClick(newValue)}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                sx={{
                                    '& .MuiTabs-indicator': {
                                        backgroundColor: '#6BBDBD',
                                        height: '2px'
                                    },
                                    '& .MuiTabs-scrollButtons': {
                                        color: '#666666',
                                        '&.Mui-disabled': {
                                            opacity: 0.3,
                                        }
                                    },
                                    '& .MuiTab-root': {
                                        textTransform: 'none',
                                        fontSize: '14px',
                                        fontFamily: 'Inter',
                                        color: '#666666',
                                        minWidth: 'unset',
                                        padding: '4px 12px',
                                        marginRight: '8px',
                                        borderRadius: '8px 8px 0 0',
                                        height: '40px',
                                        minHeight: 'unset',
                                        '&.Mui-selected': {
                                            color: '#6BBDBD',
                                            backgroundColor: 'transparent',
                                            fontWeight: 700
                                        },
                                        '&.Mui-disabled': {
                                            color: '#666666',
                                            opacity: 1,  // Make sure disabled tabs are fully visible
                                        },
                                        '& .MuiSvgIcon-root': {
                                            fontSize: '16px',
                                            marginRight: '4px',
                                            marginBottom: '0px',
                                            color: 'inherit'
                                        }
                                    },
                                    '& .MuiTabs-flexContainer': {
                                        gap: '4px',
                                        minHeight: 'unset'
                                    },
                                    width: '100%',
                                    paddingLeft: '0px',
                                }}
                            >
                                <Tab
                                    icon={<QuestionMarkIcon />}
                                    iconPosition="start"
                                    label={`Preguntas (${questions.topics ? questions.topics.reduce((total, topic) => total + topic.questions.length, 0) : 0})`}
                                    value="Progreso"
                                    disableRipple
                                />
                                <Tab
                                    icon={<StyleIcon />}
                                    iconPosition="start"
                                    label={`Flashcards (${totalFlashcards})`}
                                    value="Flashcards"
                                    disableRipple
                                    disabled={isExamDocument}
                                    sx={{ display: isExamDocument ? 'none' : 'flex' }}
                                />
                                <Tab
                                    icon={<SummarizeIcon />}
                                    iconPosition="start"
                                    label="Resumen"
                                    value="Summary"
                                    disableRipple
                                    disabled={isExamDocument}
                                    sx={{ display: isExamDocument ? 'none' : 'flex' }}
                                />
                                <Tab
                                    icon={<AccountTreeIcon />}
                                    iconPosition="start"
                                    label={`Esquemas (${schemas.length})`}
                                    value="Schemas"
                                    disableRipple
                                    disabled={isExamDocument}


                                    sx={{ display: isExamDocument ? 'none' : 'flex' }}
                                />
                                <Tab
                                    icon={<DescriptionIcon />}
                                    iconPosition="start"
                                    label={`Documento`}
                                    value="Document"
                                    disableRipple
                                    disabled={isExamDocument && docInfo.url_stored === ""}
                                    sx={{ display: isExamDocument && docInfo.url_stored === "" ? 'none' : 'flex' }}
                                />
                            </Tabs>
                        )}
                    </Box>
                )}
                {/* {userData?.plan_name === "External" && (
                    <div style={{
                        marginTop: "20px",
                        padding: "20px",
                        backgroundColor: "#F5F5F5",
                        borderRadius: "20px",
                        boxShadow: "none",
                        marginBottom: "15px"

                    }}>
                        <Typography style={{
                            color: "#000B0D",
                            fontSize: "16px",
                            fontFamily: "Fira Sans",
                            fontWeight: 600,
                            marginBottom: "15px"
                        }}>
                            Recursos Adicionales
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="#6BBDBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <Typography style={{ color: '#6BBDBD', fontSize: '14px', fontFamily: 'Fira Sans' }}>
                                    Guía de estudio
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="#6BBDBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <Typography style={{ color: '#6BBDBD', fontSize: '14px', fontFamily: 'Fira Sans' }}>
                                    Plantillas de resumen
                                </Typography>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="#6BBDBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <Typography style={{ color: '#6BBDBD', fontSize: '14px', fontFamily: 'Fira Sans' }}>
                                    Tutoriales
                                </Typography>
                            </div>
                        </div>
                    </div>
                )} */}


                {renderContent()}
            </Grid >
        </>
    );
}

export default Document;
