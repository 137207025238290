import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const PopupTutorialFlashcard = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          p: 2,
        },
      }}
    >
      <DialogTitle sx={{ p: 0, mb: 1 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" sx={{ fontFamily: 'Fira Sans' }}>Tutorial Tarjetas de Memoria</Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: 'grey.500',
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <Typography 
          variant="body1" 
          color="text.secondary" 
          gutterBottom 
          sx={{ fontFamily: 'Fira Sans' }}
        >
          Mira este breve vídeo para entender como funcionan las tarjetas de memoria.
        </Typography>
        
        <Box sx={{ 
          width: '100%', 
          borderRadius: 1, 
          overflow: 'hidden',
          my: 2 
        }}>
          <div style={{
            position: 'relative',
            paddingBottom: '62.5%',
            height: 0
          }}>
            <iframe 
              src="https://www.loom.com/embed/1ae9e650632a4b8bac8626c845888ae9?sid=f0da45af-499b-4231-9ed7-2f867c06e28c"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%'
              }}
            />
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PopupTutorialFlashcard;