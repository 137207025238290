import React, { useState } from 'react';
import {
  Button,
  Typography,
  Box,
  Slider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
  Tooltip,
  TextField,
  Paper,
} from '@mui/material';
import Premium from '../PopUpsAlerts/Premium/Premium';
import { useSubscription } from "../Context/SubscriptionContext";
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const CustomizeExamModal = ({ onClose, onSave, documentStats, documentName, selectedDocuments, examCustomization, onCustomizationChange }) => {
  const { userData } = useSubscription();
  const isPremium = userData?.plan_name !== "Free";

  const [showPremium, setShowPremium] = useState(false);
  const [premiumTrigger, setPremiumTrigger] = useState('');
  const [showInfoBox, setShowInfoBox] = useState(true);

  const handleQuestionCountChange = (event, newValue) => {
    if (!isPremium && newValue > 15) {
      setShowPremium(true);
      setPremiumTrigger('questions');
      return;
    }
    onCustomizationChange({
      ...examCustomization,
      questionCount: newValue
    });
  };

  const handleTypeChange = (type) => {
    if (!isPremium && type !== 'mix') {
      setShowPremium(true);
      setPremiumTrigger('topics');
      return;
    }

    let newTypes;
    if (type === 'mix') {
      newTypes = {
        memorized: !examCustomization.questionTypes.mix,
        learning: !examCustomization.questionTypes.mix,
        incorrect: !examCustomization.questionTypes.mix,
        notStarted: !examCustomization.questionTypes.mix,
        mix: !examCustomization.questionTypes.mix
      };
    } else {
      newTypes = {
        ...examCustomization.questionTypes,
        mix: false,
        [type]: !examCustomization.questionTypes[type]
      };
    }

    onCustomizationChange({
      ...examCustomization,
      questionTypes: newTypes
    });
  };

  const isSelectionValid = () => {
    if (examCustomization.questionTypes.mix) return true;

    return Object.entries(examCustomization.questionTypes).some(([type, isSelected]) => {
      if (!isSelected) return false;

      const count = type === 'memorized' ? totalStats?.memorized :
        type === 'learning' ? totalStats?.learning :
          type === 'incorrect' ? totalStats?.notKnown :
            type === 'notStarted' ? totalStats?.notStarted : 0;

      return count > 0;
    });
  };

  const handleStartExam = () => {
    if (!isSelectionValid()) return;

    // Save scoring settings to localStorage with a temporary key
    // The actual exam_id will be assigned when the exam is created
    localStorage.setItem('temp_exam_scoring', JSON.stringify({
      incorrectPoints: examCustomization.incorrectPoints || 0,
      unansweredDeducts: examCustomization.unansweredDeducts || false
    }));

    onSave({
      questionCount: examCustomization.questionCount,
      questionTypes: examCustomization.questionTypes,
      incorrectPoints: examCustomization.incorrectPoints || 0,
      unansweredDeducts: examCustomization.unansweredDeducts || false
    });
  };

  const calculateTotalStats = () => {
    if (!selectedDocuments) return null;

    const totals = {
      memorized: 0,
      learning: 0,
      notKnown: 0,
      notStarted: 0
    };

    selectedDocuments.forEach(doc => {
      totals.memorized += doc.status?.memorized || 0;
      totals.learning += doc.status?.learning || 0;
      totals.notKnown += doc.status?.notKnown || 0;
      totals.notStarted += doc.status?.notStarted || 0;
    });

    return totals;
  };

  const totalStats = calculateTotalStats();

  return (
    <Box sx={{ 
      p: 3
    }}>
      {showPremium && (
        <Premium
          onClose={() => setShowPremium(false)}
          titleText={premiumTrigger}
        />
      )}

      <Typography
        variant="h6"
        sx={{
          fontWeight: 600,
          fontFamily: 'Fira Sans, sans-serif',
          color: 'black',
          textAlign: 'center',
          fontSize: '20px',
          marginBottom: '24px'
        }}
      >
        Personalizar test
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Typography
          sx={{
            fontSize: '14px',
            color: '#666',
            mb: 1,
            fontFamily: 'Inter, sans-serif'
          }}
        >
          {selectedDocuments?.length > 1 ?
            `${selectedDocuments.length} documentos seleccionados:` :
            'Documento seleccionado:'}
        </Typography>
        <Box sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          backgroundColor: '#F8F9FA',
          borderRadius: '8px',
          p: 2
        }}>
          {selectedDocuments?.map((doc) => (
            <Box
              key={doc.guid}
              sx={{
                backgroundColor: '#E2F1F1',
                borderRadius: '6px',
                padding: '4px 12px',
                fontSize: '14px',
                color: '#333',
                fontFamily: 'Inter, sans-serif'
              }}
            >
              {doc.title}
            </Box>
          ))}
        </Box>
      </Box>

      {/* Step 1 */}
      <Paper 
        elevation={0} 
        sx={{ 
          mb: 3, 
          p: 3, 
          borderRadius: '12px',
          border: '1px solid #e0e0e0'
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            fontFamily: 'Fira Sans, sans-serif',
            textAlign: 'left',
            marginBottom: '20px'
          }}
        >
          1. Elige el número de preguntas ({examCustomization.questionCount})
        </Typography>
        <Slider
          value={examCustomization.questionCount}
          onChange={handleQuestionCountChange}
          min={1}
          max={200}
          valueLabelDisplay="auto"
          sx={{
            color: '#6BBDBD',
            '& .MuiSlider-thumb': {
              backgroundColor: '#fff',
              border: '2px solid #6BBDBD',
            },
          }}
        />
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{
            mt: 1,
            fontFamily: 'Inter, sans-serif'
          }}
        >
          {isPremium ?
            'Selecciona entre 1 y 200 preguntas' :
            'Selecciona entre 1 y 15 preguntas (Premium hasta 200)'}
        </Typography>
      </Paper>

      {/* Step 2 */}
      <Paper 
        elevation={0} 
        sx={{ 
          mb: 3, 
          p: 3, 
          borderRadius: '12px',
          border: '1px solid #e0e0e0'
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            fontFamily: 'Fira Sans, sans-serif',
            textAlign: 'left',
            marginBottom: '20px'
          }}
        >
          2. Elige el tipo de preguntas {!isPremium && '(Premium)'}
        </Typography>

        {showInfoBox && (
          <Box sx={{ 
            backgroundColor: '#F8F9FA', 
            borderRadius: '8px', 
            p: 2, 
            mb: 2,
            border: '1px solid #E0E0E0',
            position: 'relative'
          }}>
            <Typography sx={{ 
              fontFamily: 'Inter, sans-serif', 
              fontSize: '14px',
              color: '#666'
            }}>
              Las preguntas se clasifican según los intentos necesarios para responderlas correctamente
            </Typography>
            <CloseIcon 
              onClick={() => setShowInfoBox(false)}
              sx={{
                position: 'absolute',
                top: '8px',
                right: '8px',
                fontSize: '16px',
                color: '#666',
                cursor: 'pointer'
              }}
            />
          </Box>
        )}

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={examCustomization.questionTypes.mix}
                onChange={() => handleTypeChange('mix')}
                sx={{
                  color: '#6BBDBD',
                  '&.Mui-checked': {
                    color: '#6BBDBD',
                  },
                }}
              />
            }
            label={
              <Typography sx={{ fontFamily: 'Inter, sans-serif', fontSize: '14px' }}>
                Mezcla de todos los tipos
              </Typography>
            }
          />
          {['memorized', 'learning', 'incorrect', 'notStarted'].map((type) => {
            const availableQuestions = type === 'memorized' ? totalStats?.memorized :
              type === 'learning' ? totalStats?.learning :
                type === 'incorrect' ? totalStats?.notKnown :
                  type === 'notStarted' ? totalStats?.notStarted : 0;

            return (
              <Box
                key={type}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  opacity: (!isPremium || availableQuestions === 0) ? 0.7 : 1
                }}
              >
                <FormControlLabel
                  disabled={!isPremium || availableQuestions === 0}
                  control={
                    <Checkbox
                      checked={examCustomization.questionTypes[type]}
                      onChange={() => handleTypeChange(type)}
                      sx={{
                        color: type === 'memorized' ? '#00B564' :
                          type === 'learning' ? '#FFA726' :
                            type === 'incorrect' ? '#AA161D' :
                              '#666666',
                        '&.Mui-checked': {
                          color: type === 'memorized' ? '#00B564' :
                            type === 'learning' ? '#FFA726' :
                              type === 'incorrect' ? '#AA161D' :
                                '#666666',
                        },
                      }}
                    />
                  }
                  label={
                    <Typography sx={{
                      fontFamily: 'Inter, sans-serif',
                      fontSize: '14px',
                      color: availableQuestions === 0 ? '#999' : 'inherit'
                    }}>
                      {type === 'memorized' ? `Correctas (${totalStats?.memorized || 0})` :
                        type === 'learning' ? `Aprendiendo (${totalStats?.learning || 0})` :
                          type === 'incorrect' ? `Incorrectas (${totalStats?.notKnown || 0})` :
                            `Sin empezar (${totalStats?.notStarted || 0})`}
                    </Typography>
                  }
                  sx={{ flex: 1 }}
                />
                {availableQuestions === 0 && (
                  <Tooltip 
                    title="No tienes preguntas de esta categoria"
                    arrow
                    placement="top"
                  >
                    <Box
                      sx={{
                        width: 16,
                        height: 16,
                        ml: 1,
                        mr: 2,
                        borderRadius: '50%',
                        backgroundColor: '#e0e0e0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '10px',
                        color: '#666',
                        cursor: 'help'
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </Box>
                  </Tooltip>
                )}
              </Box>
            );
          })}
        </FormGroup>
      </Paper>

      {/* Step 3 */}
      <Paper 
        elevation={0} 
        sx={{ 
          mb: 3, 
          p: 3, 
          borderRadius: '12px',
          border: '1px solid #e0e0e0'
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            fontFamily: 'Fira Sans, sans-serif',
            textAlign: 'left',
            marginBottom: '20px'
          }}
        >
          3. Configura la puntuación
        </Typography>

        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          gap: 2,
          mb: 2
        }}>
          <TextField
            label="Puntos por respuesta incorrecta"
            type="number"
            value={examCustomization.incorrectPoints || 0}
            onChange={(event) => onCustomizationChange({
              ...examCustomization,
              incorrectPoints: Number(event.target.value)
            })}
            inputProps={{ 
              step: '0.01',
              min: -10,
              max: 0
            }}
            sx={{
              maxWidth: '300px',
              '& .MuiOutlinedInput-root': {
                fontFamily: 'Inter, sans-serif',
                fontSize: '14px',
                backgroundColor: '#fff',
                '& fieldset': {
                  borderColor: '#E0E0E0',
                },
                '&:hover fieldset': {
                  borderColor: '#666',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#6BBDBD',
                },
              },
              '& .MuiInputLabel-root': {
                fontFamily: 'Inter, sans-serif',
                fontSize: '14px',
                color: '#666',
                '&.Mui-focused': {
                  color: '#6BBDBD',
                },
              },
              '& .MuiOutlinedInput-input': {
                padding: '12px 14px',
              },
            }}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={examCustomization.unansweredDeducts || false}
                onChange={(event) => onCustomizationChange({
                  ...examCustomization,
                  unansweredDeducts: event.target.checked
                })}
                sx={{
                  color: '#6BBDBD',
                  '&.Mui-checked': {
                    color: '#6BBDBD',
                  },
                }}
              />
            }
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <Typography sx={{ fontFamily: 'Inter, sans-serif', fontSize: '14px' }}>
                  Preguntas sin responder restan
                </Typography>
                <Tooltip title="Si está activado, las preguntas sin responder restarán puntos igual que las incorrectas" arrow placement="top">
                  <InfoOutlinedIcon 
                    sx={{ 
                      color: '#6BBDBD',
                      fontSize: '15px',
                      cursor: 'help'
                    }} 
                  />
                </Tooltip>
              </Box>
            }
          />
        </Box>
      </Paper>

      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        mt: 3 
      }}>
        <Button
          variant="contained"
          onClick={handleStartExam}
          disabled={!isSelectionValid()}
          sx={{
            backgroundColor: '#6BBDBD',
            color: 'white',
            borderRadius: '28px',
            textTransform: 'none',
            padding: '10px 32px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            fontSize: '15px',
            minWidth: '200px',
            maxWidth: '300px',
            width: '100%',
            boxShadow: '0 4px 12px rgba(107, 189, 189, 0.3)',
            '&:hover': {
              backgroundColor: '#5AACAC',
              boxShadow: '0 6px 16px rgba(107, 189, 189, 0.4)',
            },
            '&:disabled': {
              backgroundColor: '#E0E0E0',
              color: '#FFFFFF',
            }
          }}
        >
          Empezar Test
        </Button>
      </Box>
    </Box>
  );
};

export default CustomizeExamModal; 