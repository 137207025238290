import React, { createContext, useState, useContext, useEffect } from 'react';
import { fetchUserSubscription } from '../Api/UserApi';
import posthog from 'posthog-js';
import { useAuth } from './AuthContext';
import { useUserInfo } from './UserContext';

const SubscriptionContext = createContext();

export const useSubscription = () => useContext(SubscriptionContext);

export const SubscriptionProvider = ({ children }) => {
    const [hasSubscription, setHasSubscription] = useState(false);
    const [userData, setUserData] = useState(false);
    const { token, user, isAuthenticated } = useAuth();
    const { userInfo } = useUserInfo();

    const fetchData = async () => {
        if (!isAuthenticated || !userInfo) {
            console.log("Waiting for authentication and user info...");
            return;
        }

        try {
            let new_user = {...user, id: user.sub}
            const subResult = await fetchUserSubscription(token, new_user);

            if (!subResult) {
                throw new Error('Failed to fetch subscription');
            }

            setHasSubscription(subResult.manager === userInfo.guid && subResult.plan.name === 'Pro');
            const updatedUserData = {
                ...userInfo,
                maxPages: subResult.plan?.pages || userInfo.maxPages
            };
            
            setUserData(updatedUserData);
            posthog.identify(
                userInfo.guid,
                { email: userInfo.email, name: userInfo.name }
            );
        } catch (error) {
            console.error("Error fetching subscription data:", error);
            if (error.message.includes('401') || error.message.includes('auth')) {
                window.location.replace("/login");
            } else {
                window.location.replace("/innactive");
            }
        }
    };

    useEffect(() => {
        if (isAuthenticated && userInfo) {
            fetchData();
        }
    }, [isAuthenticated, userInfo, token, user]);

    if (!userInfo) {
        return null;
    }

    return (
        <SubscriptionContext.Provider value={{ hasSubscription, setHasSubscription, userData, fetchData }}>
            {children}
        </SubscriptionContext.Provider>
    );
};
