// Constants for storage keys
const STORAGE_KEYS = {
    FOLDER_SORT: 'folder_sort_preference',
    DOCUMENT_SORT: 'document_sort_preference',
    FOLDER_DOCUMENT_SORT: 'folder_document_sort_preference'
};

// Default sort option
const DEFAULT_SORT = { value: 'newest', label: 'Más recientes' };

// Sort options that can be reused across components
export const sortOptions = [
    { value: 'newest', label: 'Más recientes' },
    { value: 'oldest', label: 'Más antiguos' },
    { value: 'name_asc', label: 'Nombre A-Z' },
    { value: 'name_desc', label: 'Nombre Z-A' },
    // { value: 'progress', label: 'Mayor progreso' }
];

// Get sort preference for a specific view
export const getSortPreference = (key) => {
    try {
        const stored = localStorage.getItem(key);
        if (!stored) return DEFAULT_SORT;
        return JSON.parse(stored);
    } catch (error) {
        console.error('Error reading sort preference:', error);
        return DEFAULT_SORT;
    }
};

// Save sort preference for a specific view
export const saveSortPreference = (key, value) => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.error('Error saving sort preference:', error);
    }
};

export { STORAGE_KEYS }; 