import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import { Typography } from '@mui/material';
// import InlineMath from 'path-to-your-inline-math-component';
// import BlockMath from 'path-to-your-block-math-component';

const MarkdownRender = ({ response }) => {
    return (
        <ReactMarkdown
            style={{
                color: "#333",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "20px",
            }}
            children={response.message}
            remarkPlugins={[remarkMath]}
            rehypePlugins={[rehypeKatex, rehypeRaw]} // Add rehypeRaw to handle raw HTML
            components={{
                // inlineMath: ({ value }) => <InlineMath math={value} />,
                // math: ({ value }) => <BlockMath math={value} />,
                p: ({ node, ...props }) => (
                    <Typography
                        variant="body1"
                        style={{ color: "#333", fontSize: "14px", fontFamily: "Fira Sans", fontStyle: "normal", fontWeight: 400, lineHeight: "20px" }}
                        {...props}
                    />

                ),
                text: ({ node, ...props }) => <Typography {...props} />,
                img: ({ node, ...props }) => (
                    <img
                        style={{ maxWidth: '100%', height: 'auto' }} // Ensure images are responsive
                        {...props}
                    />

                ),

                ul: ({ node, ...props }) => (
                    <ul
                        style={{
                            color: "#333",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "20px",
                            paddingLeft: '20px',
                        }}
                        {...props}
                    />
                ),
                ol: ({ node, ...props }) => (
                    <ol
                        style={{
                            color: "#333",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "20px",
                            paddingLeft: '20px',
                        }}
                        {...props}
                    />
                ),
                li: ({ node, ...props }) => (
                    <li
                        style={{
                            color: "#333",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "20px",
                        }}
                        {...props}
                    />
                ),
                h1: ({ node, ...props }) => (
                    <Typography
                        variant="h6"
                        style={{ color: "#333", fontSize: "14px", fontWeight: 600, marginTop: "20px", marginBottom: "10px" }}
                        {...props}
                    />
                ),
                h2: ({ node, ...props }) => (
                    <Typography
                        variant="h6"
                        style={{ color: "#333", fontSize: "14px", fontWeight: 600, marginTop: "20px", marginBottom: "10px" }}
                        {...props}
                    />
                ),
                h3: ({ node, ...props }) => (
                    <Typography
                        variant="h6"
                        style={{ color: "#333", fontSize: "14px", fontWeight: 600, marginTop: "20px", marginBottom: "10px" }}
                        {...props}
                    />
                ),
                h4: ({ node, ...props }) => (
                    <Typography
                        variant="h6"
                        style={{ color: "#333", fontSize: "14px", fontWeight: 600, marginTop: "20px", marginBottom: "10px" }}
                        {...props}
                    />
                ),
                h5: ({ node, ...props }) => (
                    <Typography
                        variant="h6"
                        style={{ color: "#333", fontSize: "14px", fontWeight: 600, marginTop: "20px", marginBottom: "10px" }}
                        {...props}
                    />
                ),
                h6: ({ node, ...props }) => (
                    <Typography
                        variant="h6"
                        style={{ color: "#333", fontSize: "14px", fontWeight: 600, marginTop: "20px", marginBottom: "10px" }}
                        {...props}
                    />
                ),
            }}
        />
    );
};

export default MarkdownRender;
