import React, { useState, useEffect, useRef, useContext } from 'react';
import { Modal, Box, Typography, Button, CircularProgress, IconButton, TextField, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DOMPurify from 'dompurify';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { WebSocketContext } from "../WebSocketProvider";
import ReactMarkdown from 'react-markdown';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: '12px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        padding: '32px',
        width: '100%',
        maxWidth: '1000px',
        margin: '20px',
        outline: 'none',
        maxHeight: '650px',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    content: {
        fontFamily: 'Inter, sans-serif',
        color: 'black',
        fontSize: '14px',
        whiteSpace: 'pre-wrap',
        overflowY: 'auto',
        flex: 1,
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
        },
    },
    button: {
        marginTop: theme.spacing(2),
        backgroundColor: '#6BBDBD',
        color: 'white',
        textTransform: 'none',
        fontFamily: 'Inter, sans-serif',
        boxShadow: 'none',
        fontWeight: '600',
        fontSize: '14px',
        borderRadius: '20px',
        padding: '8px 24px',
        '&:hover': {
            backgroundColor: '#5AA9A9',
        },
    },
    header: {
        flexShrink: 0,
    },
    footer: {
        flexShrink: 0,
    },
    loader: {
        color: '#6BBDBD',
        marginBottom: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: '16px',
        top: '16px',
        color: '#666',
        '&:hover': {
            color: '#333',
        },
    },
    messageBox: {
        padding: '12px 16px',
        borderRadius: '8px',
        marginBottom: '16px',
        backgroundColor: '#F5F8FF',
        border: '1px solid #E0E0E0',
        width: '100%',
        '& p': {
            marginBottom: '1em',
            '&:last-child': {
                marginBottom: 0,
            }
        },
        '& ul, & ol': {
            marginLeft: '1em',
            marginBottom: '1em',
        },
    },
    copyButton: {
        color: '#666',
        padding: '4px',
        '&:hover': {
            color: '#333',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },
}));

// Add CopyButton component
const CopyButton = ({ text }) => {
    const classes = useStyles();
    const [copied, setCopied] = useState(false);

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(text);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            console.error('Failed to copy text:', err);
        }
    };

    return (
        <Tooltip title={copied ? "¡Copiado!" : "Copiar"}>
            <IconButton 
                onClick={handleCopy} 
                className={classes.copyButton}
                size="small"
            >
                <ContentCopyIcon fontSize="small" />
            </IconButton>
        </Tooltip>
    );
};

function JustificationPopup({ open, onClose, question, correctAnswer, justificationText, userAnswer, isLoading, user, document_id, token, questionAnswerId }) {
    const classes = useStyles();
    const [displayText, setDisplayText] = useState('');
    const [userInput, setUserInput] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const lastChunkRef = useRef('');
    const messagesEndRef = useRef(null);
    const [isLoadingResponse, setIsLoadingResponse] = useState(false);
    const { wsChat, chatMessageCallback } = useContext(WebSocketContext);
    const ongoingStream = useRef(null);
    const abortControllerRef = useRef(null);
    const [messageQueue, setMessageQueue] = useState([]);
    const [isWsConnected, setIsWsConnected] = useState(false);

    // Add this configuration for DOMPurify
    const sanitizeConfig = {
        ALLOWED_TAGS: ['br', 'strong'], // Allow <br> and <strong> tags
        ALLOWED_ATTR: [], // No attributes needed for these tags
    };

    // Reset when modal closes
    useEffect(() => {
        if (!open) {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
                abortControllerRef.current = null;
            }
            setDisplayText('');
            lastChunkRef.current = '';
            setChatHistory([]); // Clear chat history
            if (wsChat?.current?.readyState === WebSocket.OPEN && user?.sub) {
                wsChat.current.send(JSON.stringify({
                    event: 'disconnect',
                    user_id: user.id,
                    document_id: document_id
                }));
            }
        }
    }, [open, user.id, document_id]);

    // Handle new text chunks
    useEffect(() => {
        
        if (justificationText && justificationText !== '[DONE]' && open) {
            // Get only the new part of the text
            const newText = justificationText.replace(lastChunkRef.current, '');
            lastChunkRef.current = justificationText;

            // Convert <br> to newline if present
            const formattedText = newText.replace(/<br>/g, '\n \n');

            setDisplayText(prev => prev + formattedText);
        }
        if(!open){
            setDisplayText('');
        }

    }, [justificationText, open]);

    // Handle WebSocket messages
    useEffect(() => {
        if (chatMessageCallback) {
            setIsLoadingResponse(false);
            let sender = chatMessageCallback.name;

            switch (chatMessageCallback.event) {
                case 'on_parser_start':
                    ongoingStream.current = { id: chatMessageCallback.run_id, content: '' };
                    setChatHistory(prevHistory => [
                        ...prevHistory,
                        { sender: 'Machine', message: '', id: chatMessageCallback.run_id }
                    ]);
                    break;

                case 'on_parser_stream':
                    if (ongoingStream.current && chatMessageCallback.run_id === ongoingStream.current.id) {
                        ongoingStream.current.content += chatMessageCallback.data.chunk;
                        setChatHistory(prevHistory => {
                            const newHistory = [...prevHistory];
                            const index = newHistory.findIndex(msg => msg.id === chatMessageCallback.run_id);
                            if (index !== -1) {
                                newHistory[index] = {
                                    ...newHistory[index],
                                    message: ongoingStream.current.content
                                };
                            }
                            return newHistory;
                        });
                    }
                    break;

                case 'Error':
                    setChatHistory(prevHistory => {
                        const newHistory = prevHistory.map(msg =>
                            msg.id === chatMessageCallback.run_id
                                ? { sender: "Machine", message: "Lo siento, ha ocurrido un error." }
                                : msg
                        );
                        return newHistory;
                    });
                    break;
            }
        }
    }, [chatMessageCallback]);

    // Update the createMarkup function
    const createMarkup = (html) => {
        return { __html: DOMPurify.sanitize(html, sanitizeConfig) };
    };

    // Modify the renderMessage function to only show messages without copy button
    const renderMessage = (message, index) => {
        return (
            <div key={index}>
                <div style={{
                    marginBottom: "5px",
                    marginTop: "15px"
                }}>
                    <Typography style={{ fontWeight: "bold", fontSize: "14px", marginBottom: '8px' }}>
                        {message.sender === 'Machine' ? 'Profesor' : 'Tú'}
                    </Typography>
                </div>
                <div className={classes.messageBox}>
                    <ReactMarkdown>
                        {message.message}
                    </ReactMarkdown>
                </div>
            </div>
        );
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    // Modify the WebSocket connection status listener
    useEffect(() => {
        const currentWsChat = wsChat?.current;  // Store reference to avoid dependency issues
        
        if (currentWsChat) {
            const checkConnection = () => {
                if (currentWsChat.readyState === WebSocket.OPEN) {
                    setIsWsConnected(true);
                    // Send any queued messages
                    messageQueue.forEach(msg => {
                        currentWsChat.send(JSON.stringify(msg));
                    });
                    setMessageQueue([]);
                } else {
                    setIsWsConnected(false);
                }
            };

            currentWsChat.addEventListener('open', checkConnection);
            currentWsChat.addEventListener('close', () => setIsWsConnected(false));

            // Initial check
            checkConnection();

            return () => {
                currentWsChat.removeEventListener('open', checkConnection);
                currentWsChat.removeEventListener('close', () => setIsWsConnected(false));
            };
        }
    }, [wsChat?.current]); // Remove this dependency and rely on the stored reference

    // Modify the send message function
    const safeSendMessage = (message) => {
        if (!wsChat?.current || wsChat.current.readyState !== WebSocket.OPEN) {
            // Queue the message if WebSocket isn't ready
            setMessageQueue(prev => [...prev, message]);
            return;
        }
        wsChat.current.send(JSON.stringify(message));
    };

    // Update handleSubmit to use safeSendMessage
    const handleSubmit = (e) => {
        if (e) e.preventDefault();

        if (userInput.trim()) {
            setIsLoadingResponse(true);

            const userMessage = { sender: "You", message: userInput };
            setChatHistory(prev => [...prev, userMessage]);

            if (chatHistory.length === 0) {
                // Initial message with context
                safeSendMessage({
                    message: userInput,
                    user_id: user.id,
                    question: question,
                    userAnswer: userAnswer,
                    correctAnswer: correctAnswer,
                    justification: displayText,
                    document_id: document_id
                });
            } else {
                // Regular message
                safeSendMessage({
                    message: userInput,
                    user_id: user.id,
                    document_id: document_id
                });
            }

            setUserInput('');
        }
    };

    // Auto-scroll to bottom when new messages arrive
    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [chatHistory]);

    // Add typing animation component
    const TypingAnimation = () => (
        <div style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "10px",
        }}>
            <Typography style={{
                padding: "6px",
                maxWidth: "60%",
                borderRadius: "15px",
                backgroundColor: "#026277",
                color: "white",
                display: "flex",
                alignItems: "center",
            }}>
                <span className="dot dot1"></span>
                <span className="dot dot2"></span>
                <span className="dot dot3"></span>
            </Typography>
        </div>
    );

    const formatText = (text) => {
        return text
            // Replace \n\n with actual line breaks
            .replace(/\\n\\n/g, '\n\n')
            // Replace \n with single line break
            .replace(/\\n/g, '\n')
            // Fix markdown bold syntax if needed
            .replace(/\*\*/g, '**');
    };

    const stopJustification = async () => {
        try {
            await fetch(`${BACKEND_URL}/api_v1/justification/`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    questionAnswerId: questionAnswerId
                })
            });
        } catch (error) {
            console.error('Error stopping justification:', error);
        }
    };

    // Update disconnect handling in useEffect and Modal
    useEffect(() => {
        if (!open) {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
                abortControllerRef.current = null;
            }
            if (isWsConnected && wsChat?.current && user?.sub) {
                safeSendMessage({
                    event: 'disconnect',
                    user_id: user.id,
                    document_id: document_id
                });
            }
            setDisplayText('');
            lastChunkRef.current = '';
            setChatHistory([]);
            setUserInput('');
        }
    }, [open, isWsConnected]);

    return (
        <Modal 
            open={open} 
            onClose={() => {
                if (abortControllerRef.current) {
                    abortControllerRef.current.abort();
                    abortControllerRef.current = null;
                }
                if (isWsConnected && wsChat?.current && user?.sub) {
                    safeSendMessage({
                        event: 'disconnect',
                        user_id: user.id,
                        document_id: document_id
                    });
                }
                onClose();
            }} 
            className={classes.modal}
        >
            <Box className={classes.paper}>
                <IconButton
                    onClick={onClose}
                    className={classes.closeButton}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>

                <Typography
                    variant="h6"
                    className={classes.header}
                    style={{
                        fontSize: "20px",
                        fontFamily: "Fira Sans",
                        color: "black",
                        fontWeight: "bold",
                        marginBottom: '24px'
                    }}
                >
                    Justificación
                </Typography>

                <div style={{
                    flexGrow: 1,
                    overflowY: "auto",
                    maxHeight: "calc(100% - 180px)",
                    padding: "16px",
                }}>
                    {displayText ? (
                        <>
                            <div className={classes.messageBox}>
                                <ReactMarkdown>
                                    {formatText(displayText)}
                                </ReactMarkdown>
                            </div>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, mb: 2 }}>
                                <CopyButton text={formatText(displayText)} />
                            </Box>
                        </>
                    ) : (
                        <div className={classes.messageBox}>
                            <Typography>Generando justificación...</Typography>
                        </div>
                    )}

                    {chatHistory.filter(msg => msg.id !== 'initial-justification').map((message, index) => renderMessage(message, index))}
                    {isLoadingResponse && <TypingAnimation />}
                    <div ref={messagesEndRef} />
                </div>

                {displayText && (
                    <div style={{
                        display: "flex",
                        marginBottom: 10,
                        marginTop: 10,
                        borderRadius: "8px",
                        border: "1px solid #D5D4DC",
                        background: "#F5F8FF",
                        marginLeft: "1em",
                        marginRight: "1em",
                    }}>
                        <TextField
                            fullWidth
                            value={userInput}
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                style: { padding: "5px", fontSize: "12px" },
                            }}
                            multiline
                            disabled={isLoadingResponse}
                            style={{ padding: "10px", fontSize: "14px" }}
                            placeholder="Escribe aquí tu pregunta"
                            onChange={(e) => setUserInput(e.target.value)}
                            onKeyPress={handleKeyPress}
                        />
                        <Button
                            color="primary"
                            onClick={handleSubmit}
                            endIcon={<SendIcon />}
                            style={{ marginLeft: "10px" }}
                        />
                    </div>
                )}
            </Box>
        </Modal>
    );
}

export default JustificationPopup; 