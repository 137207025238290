import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import Spinner from "../NewComponents/Spinner";

export default function Logout() {
    const navigate = useNavigate();
    const supabase = useSupabaseClient();

    useEffect(() => {
        const handleLogout = async () => {
            try {
                // Sign out from Supabase
                const { error } = await supabase.auth.signOut();
                if (error) throw error;

                // Clear all stored tokens and data
                localStorage.clear();
                sessionStorage.clear();

                // Clear all cookies
                document.cookie.split(";").forEach((c) => {
                    document.cookie = c
                        .replace(/^ +/, "")
                        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
                });

                // Redirect to login page
                navigate('/login');
            } catch (error) {
                console.error('Error during logout:', error);
                // Still redirect to login even if there's an error
                navigate('/login');
            }
        };

        handleLogout();
    }, [navigate, supabase.auth]);

    // Show loading spinner while logging out
    return <Spinner />;
} 