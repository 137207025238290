import React, { useState, useEffect, useContext } from 'react';
import {
  Card, CardContent, Typography, Box, Grid, Container,
  Button, Skeleton, Tooltip, Chip, Divider, CircularProgress, IconButton,
  Select, MenuItem, FormControl
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useSubscription } from "../Context/SubscriptionContext";
import Premium from '../PopUpsAlerts/Premium/Premium';
import useStylesPreguntasDocument from '../Documents/Styles/useStylePreguntasDocument';
import LockIcon from '@mui/icons-material/Lock';
import PopupFlashcard from './PopupFlashcards';
import CircleIcon from '@mui/icons-material/Circle';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import styled from '@emotion/styled';
import { fetchFlashcard } from '../Api/FlashcardsApi';
import { useAuth } from "../Auth/Authhandle";
import { WebSocketContext } from '../WebSocketProvider';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { fetchRegenerateFlashcards } from '../Api/FilesApi';
import PremiumPopup from '../Stripe/PricingComponents/PremiumPopup';
import { Link } from 'react-router-dom';
import { deleteFlashcard } from '../Api/FlashcardsApi';
import WelcomePopup from '../AllModalsActions/WelcomePopup';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import EditCard from '../Documents/EditCard';
import { updateFlashcard } from '../Api/FlashcardApi';
import AddIcon from '@mui/icons-material/Add';
import Slider from '@mui/material/Slider';
const SolutionText = styled('span')({
  color: '#66BB6A',
  fontWeight: 500,
});

const formatTextWithSolutions = (text) => {
  if (!text) return '';

  const parts = text.split(/(\[.*?\])/g);
  return parts.map((part, index) => {
    if (part.startsWith('[') && part.endsWith(']')) {
      return (
        <SolutionText key={index}>
          {part.slice(1, -1)}
        </SolutionText>
      );
    }
    return part;
  });
};

const FlashcardDoc = ({ documentId, isGeneratingFlashcards, setIsGeneratingFlashcards, setNumberOfFlashcards, numberOfFlashcards, setTotalFlashcards, docInfo }) => {
  const classes = useStylesPreguntasDocument();
  const { userData } = useSubscription();
  const [open, setOpen] = useState(false);
  const [selectedFlashcard, setSelectedFlashcard] = useState(null);
  const [showPremium, setShowPremium] = useState(false);
  const [premiumTitleText, setPremiumTitleText] = useState('');
  const [showFlashcard, setShowFlashcard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedDifficulty, setSelectedDifficulty] = useState('Sin empezar');
  const [visibleFlashcards, setVisibleFlashcards] = useState(5);
  const [flashcards, setFlashcards] = useState([]);
  const { token, user, isAuthenticated } = useAuth();
  const { messageCallback } = useContext(WebSocketContext);
  const [openRegenerateFlashcardsModal, setOpenRegenerateFlashcardsModal] = useState(false);
  const [showPremiumPopup, setShowPremiumPopup] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [flashcardToDelete, setFlashcardToDelete] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [flashcardToEdit, setFlashcardToEdit] = useState(null);

  const [isShowingFlashcards, setIsShowingFlashcards] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');

  // Add state for generate dialog
  const [openGenerateModal, setOpenGenerateModal] = useState(false);
  const [numberOfFlashcardsToGenerate, setNumberOfFlashcardsToGenerate] = useState(10);
  const [isGenerating, setIsGenerating] = useState(false);
  useEffect(() => {
    const loadFlashcards = async () => {

      try {
        setLoading(true);
        const data = await fetchFlashcard(token, documentId, user);
        setFlashcards(data);
        setError(null);
      } catch (err) {
        setError('Error loading flashcards: ' + err.message);
        console.error('Error loading flashcards:', err);
      } finally {
        setLoading(false);
      }
    };

    if (token && user) {
      loadFlashcards();
    }
  }, [documentId, token, user]);
  useEffect(() => {
    if (userData?.plan_name?.startsWith("Basic")) {
      setShowPremium(true);
    }
  }, [userData]);

  useEffect(() => {
    if (messageCallback) {
      if (messageCallback.type === 'flashcards_saved') {
        setIsGeneratingFlashcards(false);
        setIsShowingFlashcards(true);
        const data = messageCallback;
        if (data.document_id === documentId) {

          setFlashcards(prevFlashcards => {
            const newFlashcards = data.flashcards.map(flashcard => ({
              id: flashcard.guid,
              type: flashcard.type,
              text: flashcard.type === 'cloze' ? flashcard.text : undefined,
              question: flashcard.type === 'question' ? flashcard.text : undefined,
              answer: flashcard.answer,
              active: true,
              attempts: 0
            }));

            const existingIds = new Set(prevFlashcards.map(f => f.id));
            const uniqueNewFlashcards = newFlashcards.filter(f => !existingIds.has(f.id));
            setNumberOfFlashcards(numberOfFlashcards + uniqueNewFlashcards.length);
            setTotalFlashcards(numberOfFlashcards + uniqueNewFlashcards.length);

            return [...prevFlashcards, ...uniqueNewFlashcards];
          });
        }
      }
      if (messageCallback.type === 'flashcards_updated_done') {
        setIsShowingFlashcards(false);
      }
    }
  }, [messageCallback, documentId]);

  const handleOpen = (flashcard) => {
    setOpen(true);
    setSelectedFlashcard(flashcard);
    setShowFlashcard(true);
  };

  const handleClose = () => {
    setOpen(false);
    setShowFlashcard(false);
  };

  const handleShowPremium = (titleText) => {
    setShowPremium(true);
    // setPremiumTitleText(titleText);
  };
  const handleOpenRegenerateFlashcardsModal = () => {
    setOpenRegenerateFlashcardsModal(true);
  };
  const handleCloseRegenerateFlashcardsModal = () => {
    setOpenRegenerateFlashcardsModal(false);
  };

  // Add handlers for generate dialog
  const handleOpenGenerateModal = () => {
    setOpenGenerateModal(true);
  };

  const handleCloseGenerateModal = () => {
    setOpenGenerateModal(false);
    setNumberOfFlashcardsToGenerate(10);
  };

  const handleGenerateMore = async () => {
    setIsGenerating(true);
    setOpenGenerateModal(false);

    setIsShowingFlashcards(true);
  
    try {
      await fetchFlashcard(token, documentId, user, 'POST', numberOfFlashcards).then(() => {
        setIsShowingFlashcards(false);
        handleCloseGenerateModal();
      });
    } catch (error) {
      console.error('Error generating flashcards:', error);
    } 
  };

  const getFilterCounts = () => {
    const counts = {
      'Sin empezar': 0,
      'Fácil': 0,
      'Normal': 0,
      'Difícil': 0,
      'Muy difícil': 0
    };

    flashcards.forEach(card => {
      if (card.attempts === 0) {
        counts['Sin empezar']++;
      } else if (card.attempts < 1.5) {
        counts['Fácil']++;
      } else if (card.attempts < 2.5) {
        counts['Normal']++;
      } else if (card.attempts < 3.5) {
        counts['Difícil']++;
      } else if (card.attempts >= 3.5) {
        counts['Muy difícil']++;
      }
    });

    return counts;
  };

  const filters = [
    { value: 'Sin empezar', label: `Sin empezar (${getFilterCounts()['Sin empezar']})`, color: '#A0A0A0' },
    { value: 'Fácil', label: `Fácil (${getFilterCounts()['Fácil']})`, color: '#6BBDBD' },
    { value: 'Normal', label: `Normal (${getFilterCounts()['Normal']})`, color: '#6BBDBD' },
    { value: 'Difícil', label: `Difícil (${getFilterCounts()['Difícil']})`, color: '#FCAB32' },
    { value: 'Muy difícil', label: `Muy difícil (${getFilterCounts()['Muy difícil']})`, color: '#AA161D' }
  ];

  const SkeletonLoader = () => (
    <Container className={classes.scrollableContainer}>
      {[1, 2, 3].map((_, index) => (
        <Box key={index} mb={2}>
          <Skeleton variant="rectangular" height={80} />
        </Box>
      ))}
    </Container>
  );



  const handleShowMore = () => {
    setVisibleFlashcards(prev => prev + 5);
  };

  const getFilteredFlashcards = () => {
    return flashcards.filter(card => {
      const matchesDifficulty = (() => {
        switch (selectedDifficulty) {
          case 'Sin empezar':
            return card.attempts === 0;
          case 'Fácil':
            return card.attempts < 1.5 && card.attempts > 0;
          case 'Normal':
            return card.attempts < 2.5 && card.attempts > 1.5;
          case 'Difícil':
            return card.attempts < 3.5 && card.attempts > 2.5;
          case 'Muy difícil':
            return card.attempts >= 3.5;
          default:
            return true;
        }
      })();

      const matchesSearch = searchQuery === '' ||
        (card.type === 'cloze' ?
          card.text?.toLowerCase().includes(searchQuery.toLowerCase()) :
          card.question?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          card.answer?.toLowerCase().includes(searchQuery.toLowerCase())
        );

      return matchesDifficulty && matchesSearch;
    });
  };

  const handleDeleteClick = (e, flashcard) => {
    e.stopPropagation(); // Prevent card from opening
    setFlashcardToDelete(flashcard);
    setDeleteDialogOpen(true);

  };
  const handleRegenerateFlashcards = () => {
    setIsGeneratingFlashcards(true);
    // Clear existing flashcards and reset counts
    setFlashcards([]);
    setNumberOfFlashcards(0);
    setTotalFlashcards(0);
    fetchRegenerateFlashcards(token, documentId, user)
      .then(() => {
        handleCloseRegenerateFlashcardsModal();
      })
      .catch((error) => {
        console.error("Error regenerating flashcards:", error);
      })
      
  };

  const handleDeleteConfirm = async () => {
    if (flashcardToDelete) {
      setIsDeleting(true);
      try {
        await deleteFlashcard(token, flashcardToDelete.guid, user);
        setFlashcards(prevFlashcards =>
          prevFlashcards.filter(card => card.guid !== flashcardToDelete.guid)
        );
        setNumberOfFlashcards(prev => Math.max(0, prev - 1));
        setTotalFlashcards(prev => Math.max(0, prev - 1));

      } catch (error) {
        console.error('Error deleting flashcard:', error);
        // Optionally add error handling here
      } finally {
        setIsDeleting(false);
        setDeleteDialogOpen(false);
        setFlashcardToDelete(null);
      }
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setFlashcardToDelete(null);
  };

  const handleEditClick = (e, flashcard) => {
    e.stopPropagation();
    setFlashcardToEdit(flashcard);
    setEditDialogOpen(true);
  };

  const handleEditSave = async (editedFlashcard) => {
    setFlashcards(prevFlashcards =>
      prevFlashcards.map(card =>
        card.guid === editedFlashcard.guid
          ? { ...card, ...editedFlashcard }
          : card
      )
    );
    setEditDialogOpen(false);
    await updateFlashcard(token, documentId, editedFlashcard.guid, editedFlashcard, user)
    setFlashcardToEdit(null);

  };

  if (loading) return <SkeletonLoader />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (flashcards.length === 0) {
    const handleGenerateFlashcards = async () => {
      if (userData?.plan_name?.startsWith("Basic")) {
        setShowPremium(true);
        return;
      }
      setIsGeneratingFlashcards(true);
      try {
        await fetchFlashcard(token, documentId, user, 'POST');
      } catch (err) {
        setError('Error generating flashcards: ' + err.message);
        console.error('Error generating flashcards:', err);
      } finally {
        setIsGeneratingFlashcards(false);
      }
    };

    return (


      <Container className={classes.scrollableContainer}>

        {(userData?.plan_name?.startsWith("Basic")) && (
          <Typography
            sx={{
              fontFamily: "'Inter', sans-serif",
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: '16px',
              color: '#666',
              marginBottom: '16px'
            }}
          >
            Sólo puedes usar flashcards con el
            <Link
              to="/plans"
              style={{
                textDecoration: 'underline',
                color: '#6BBDBD',
                fontWeight: 'bold',
                marginLeft: '4px',
                cursor: 'pointer'
              }}
            >
              plan Premium
            </Link>
          </Typography>
        )}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="200px"
        >

          {isGeneratingFlashcards ? (
            <>
              <CircularProgress size={24} sx={{ mb: 2, color: '#6BBDBD' }} />
              <Typography
                sx={{
                  fontFamily: "'Fira Sans', sans-serif",
                  fontSize: '16px',
                  color: '#666',
                  textAlign: 'center',
                  marginBottom: '16px'
                }}
              >
                Generando flashcards...
              </Typography>
            </>
          ) : (
            <>
              <Typography
                sx={{
                  fontFamily: "'Fira Sans', sans-serif",
                  fontSize: '16px',
                  color: '#666',
                  textAlign: 'center',
                  marginBottom: '16px'
                }}
              >
                Haz click para generar tus flashcards
              </Typography>
              <Button
                onClick={() => handleGenerateFlashcards()}
                disabled={isGeneratingFlashcards}
                sx={{
                  textTransform: 'none',
                  fontSize: '14px',
                  fontWeight: '600',
                  fontFamily: "'Fira Sans', sans-serif",
                  color: 'white',
                  backgroundColor: '#6BBDBD',
                  padding: '8px 16px',
                  borderRadius: '24px',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: '#5AACAC'
                  }
                }}
              >
                Generar flashcards
              </Button>
            </>
          )}
        </Box>
      </Container>
    );
  }
  return (
    <>
      {showPremium && (
        <PremiumPopup
          open={true}
          onClose={() => setShowPremium(false)}
        />
      )}

      {showFlashcard && selectedFlashcard && (
        <PopupFlashcard
          onClose={handleClose}
          front={selectedFlashcard.front}
          back={selectedFlashcard.back}
        />
      )}


      <Container className={classes.scrollableContainer}>
        {(userData?.plan_name?.startsWith("Basic")) && (
          <Typography
            sx={{
              fontFamily: "'Inter', sans-serif",
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: '16px',
              color: '#666',
              marginBottom: '16px'
            }}
          >
            Sólo puedes usar flashcards con el
            <Link
              to="/plans"
              style={{
                textDecoration: 'underline',
                color: '#6BBDBD',
                fontWeight: 'bold',
                marginLeft: '4px',
                cursor: 'pointer'
              }}
            >
              plan Premium
            </Link>
          </Typography>
        )}
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 2,
          paddingRight: '25px',
          paddingLeft: '25px',
          marginBottom: '16px'
        }}>
          {!docInfo.regenerate_flashcards_done && (
            <Button
              onClick={handleOpenRegenerateFlashcardsModal}
              startIcon={<RefreshIcon />}
              sx={{
                backgroundColor: '#6BBDBD',
                color: 'white',
                borderRadius: '20px',
                padding: '6px 16px',
                textTransform: 'none',
                fontFamily: 'Fira Sans',
                fontWeight: 600,
                fontSize: '14px',
                '&:hover': {
                  backgroundColor: '#5aa9a9',
                },
              }}
            >
              Regenerar Flashcards
            </Button>
          )}
        </Box>
        {userData.plan_name === "Free" && (
          <Typography
            sx={{
              fontFamily: "'Fira Sans', sans-serif",
              fontSize: '14px',
              color: '#AA161D',
              textAlign: 'left',
              marginBottom: '16px',
              paddingLeft: '16px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px'
            }}
          >
            <LockIcon sx={{ fontSize: 16 }} />
            <Link to="/pricing" style={{ textDecoration: 'underline', color: '#AA161D', cursor: 'pointer' }}>
              Tienes flashcards bloqueadas. Suscríbete para desbloquearlas.
            </Link>
          </Typography>
        )}

        {isShowingFlashcards && (
          <>
            <Typography
              sx={{
                fontFamily: "'Fira Sans', sans-serif",
                fontSize: '16px',
                color: '#666',
                textAlign: 'center',
                marginBottom: '16px'

              }}
            >
              Se estan generando flashcards...
            </Typography>
          </>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: "10px" }}>
          <Box sx={{
            display: 'flex',
            gap: 2,
            paddingLeft: '25px',
            paddingRight: '25px',
            width: '100%',
            alignItems: 'center'
          }}>
            <TextField
              placeholder="Buscar en las flashcards..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              variant="outlined"
              sx={{
                flex: 1,
                '& .MuiOutlinedInput-root': {
                  '& input': {
                    padding: '8px 12px',
                    fontSize: '14px',
                    fontFamily: 'Inter, sans-serif',
                  }
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: '#666' }} />
                  </InputAdornment>
                ),
                sx: {
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  '& fieldset': {
                    borderColor: '#E2F1F1',
                  },
                  '&:hover fieldset': {
                    borderColor: '#6BBDBD !important',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#6BBDBD !important',
                  },
                  height: '40px',
                }
              }}
            />
            <FormControl sx={{ width: 180, flexShrink: 0 }}>
              <Select
                value={selectedDifficulty}
                onChange={(e) => setSelectedDifficulty(e.target.value)}
                displayEmpty
                renderValue={(value) => {
                  const filter = filters.find(f => f.value === value);
                  return (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: 8,
                          height: 8,
                          borderRadius: '50%',
                          backgroundColor: filter ? filter.color : '#A0A0A0'
                        }}
                      />
                      <Typography sx={{ 
                        fontSize: '14px',
                        fontFamily: 'Inter, sans-serif',
                        fontWeight: 600,
                        color: '#2D2D2D'
                      }}>
                        {filter ? filter.label : 'Filtrar flashcards'}
                      </Typography>
                    </Box>
                  );
                }}
                sx={{
                  height: '40px',
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E2F1F1',
                    borderRadius: '8px'
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#6BBDBD',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#6BBDBD',
                  },
                  '& .MuiSelect-select': {
                    paddingY: '8px',
                    display: 'flex',
                    alignItems: 'center',
                  },
                  '& .MuiSvgIcon-root': {
                    color: '#6BBDBD',
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      mt: 1,
                      borderRadius: '8px',
                      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                      '& .MuiMenuItem-root': {
                        padding: '12px 16px',
                        '&:hover': {
                          backgroundColor: '#F7FAFA',
                        },
                        '&.Mui-selected': {
                          backgroundColor: '#E2F1F1',
                          '&:hover': {
                            backgroundColor: '#E2F1F1',
                          },
                        },
                      },
                    },
                  },
                }}
              >
                {filters.map((filter) => (
                  <MenuItem 
                    key={filter.value} 
                    value={filter.value}
                    sx={{
                      fontFamily: 'Inter, sans-serif',
                      fontWeight: 600,
                      fontSize: '14px',
                      color: '#2D2D2D',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: 8,
                          height: 8,
                          borderRadius: '50%',
                          backgroundColor: filter.color,
                        }}
                      />
                      {filter.label}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box style={{ 
          height: "100%", 
          overflowY: "auto", 
          overflowX: "hidden", 
          display: 'flex', 
          flexDirection: 'column', 
          marginBottom: "20px" 
        }} className='custom-scrollbar'>
          <Box style={{ flexGrow: 1, marginLeft: '16px', marginRight: '16px', width: 'calc(100% - 32px)' }}>
            {getFilteredFlashcards().length === 0 ? (
              <Typography
                sx={{
                  fontFamily: "'Fira Sans', sans-serif",
                  fontSize: '16px',
                  color: '#666',
                  textAlign: 'center',
                  marginTop: '32px'
                }}
              >
                No tienes flashcards {selectedDifficulty.toLowerCase()}
              </Typography>
            ) : (
              <>
                {getFilteredFlashcards().slice(0, visibleFlashcards).map((flashcard) => (
                  <Card
                    variant="outlined"
                    key={flashcard.id}
                    style={{
                      marginBottom: '16px',
                      border: '1px solid #E2F1F1',
                      borderRadius: '8px',
                      boxShadow: 'none',
                      position: 'relative',
                      backgroundColor: 'white',
                      filter: !flashcard.active && userData.plan_name === "Free" ? 'blur(3px)' : 'none',
                      transition: 'filter 0.3s ease-in-out'
                    }}
                  >
                    <CardContent sx={{
                      p: 4,
                      '&:last-child': {
                        pb: 4
                      }
                    }}>
                      <Typography sx={{ fontFamily: 'Fira Sans', fontWeight: 500, color: '#2D2D2D', mb: 2 }}>
                        {formatTextWithSolutions(flashcard.type === 'cloze' ? flashcard.text : flashcard.question)}
                      </Typography>
                      {flashcard.type !== 'cloze' && (
                        <>
                          <Divider sx={{ my: 2 }} />
                          <Typography sx={{
                            fontFamily: 'Fira Sans',
                            color: '#666'
                          }}>
                            {formatTextWithSolutions(flashcard.answer)}
                          </Typography>
                        </>
                      )}
                      {/* <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mt: 2
                      }}>
                        <Button
                          component={Link}
                          to={`/edit/${documentId}`}
                          state={{ documentId: documentId, folderId: docInfo.folder, docInfo: docInfo }}
                          sx={{
                            textTransform: 'none',
                            fontSize: '14px',
                            fontFamily: 'Fira Sans',
                            color: '#6BBDBD',
                            padding: '0',
                            minWidth: 'auto',
                            '&:hover': {
                              backgroundColor: 'transparent',
                              textDecoration: 'underline'
                            }
                          }}
                        >
                          Ver fuente
                        </Button>
                      </Box> */}
                      <Box sx={{
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        display: 'flex',
                        gap: 1
                      }}>
                        <IconButton
                          size="small"
                          sx={{
                            color: '#666',
                            '&:hover': { color: '#6BBDBD' },
                            padding: '4px',
                          }}
                          onClick={(e) => handleEditClick(e, flashcard)}
                        >
                          <EditIcon sx={{ fontSize: '16px' }} />
                        </IconButton>
                        <IconButton
                          size="small"
                          sx={{
                            color: '#666',
                            '&:hover': { color: '#AA161D' },
                            padding: '4px',
                          }}
                          onClick={(e) => handleDeleteClick(e, flashcard)}
                        >
                          <DeleteIcon sx={{ fontSize: '16px' }} />
                        </IconButton>
                      </Box>
                    </CardContent>
                    {userData.plan_name === "Free" && !flashcard.active && (
                      <Box
                        position="absolute"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        zIndex="1"
                      >
                        <Button
                          onClick={() => handleShowPremium('flashcards')}
                          style={{
                            textTransform: 'none',
                            fontSize: '14px',
                            fontWeight: '600',
                            fontFamily: "'Inter', sans-serif",
                            color: 'white',
                            backgroundColor: '#AA161D',
                            padding: '4px 16px',
                            borderRadius: '24px',
                            boxShadow: 'none',
                            width: 'auto'
                          }}
                        >
                          <LockIcon sx={{ color: 'white', width: 20, height: 20, marginRight: '8px' }} />
                          Desbloquear
                        </Button>
                      </Box>
                    )}
                  </Card>
                ))}
              </>
            )}

            {visibleFlashcards < getFilteredFlashcards().length && (
              <Button
                onClick={handleShowMore}
                style={{
                  textTransform: 'none',
                  fontSize: '14px',
                  fontWeight: '600',
                  fontFamily: "'Inter', sans-serif",
                  color: 'white',
                  backgroundColor: '#6BBDBD',
                  padding: '8px 16px',
                  borderRadius: '24px',
                  boxShadow: 'none',
                  marginTop: '16px',
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}
              >
                Mostrar más
              </Button>
            )}
          </Box>
        </Box>
      </Container>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            padding: "24px",
            maxWidth: "400px",
            width: "100%"
          }
        }}
      >
        <DialogTitle sx={{
          padding: "0",
          fontFamily: 'Fira Sans',
        }}>
          <Typography sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: 'Fira Sans',
            color: '#000',
            mb: 1
          }}>
            Confirmar eliminación
          </Typography>
          <Typography sx={{
            fontSize: '14px',
            color: '#A0A0A0',
            fontFamily: 'Fira Sans',
            mb: 2
          }}>
            ¿Estás seguro de que quieres eliminar esta flashcard?
          </Typography>
        </DialogTitle>
        <DialogActions sx={{
          padding: "16px 0 0 0",
          marginTop: 'auto'
        }}>
          <Button
            onClick={handleDeleteCancel}
            disabled={isDeleting}
            sx={{
              color: '#A0A0A0',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#666'
              }
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            disabled={isDeleting}
            sx={{
              backgroundColor: '#AA161D',
              color: 'white',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              borderRadius: '20px',
              padding: '6px 16px',
              '&:hover': {
                backgroundColor: '#8A1219'
              },
              minWidth: '100px'
            }}
          >
            {isDeleting ? (
              <CircularProgress size={20} sx={{ color: 'white' }} />
            ) : (
              'Eliminar'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openRegenerateFlashcardsModal}
        onClose={handleCloseRegenerateFlashcardsModal}
        PaperProps={{
          sx: {
            borderRadius: '20px',
            padding: '24px',
            maxWidth: '500px'
          }
        }}
      >
        <DialogTitle sx={{
          padding: "0",
          fontFamily: 'Fira Sans',
        }}>
          <Typography sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: 'Fira Sans',
            color: '#000',
            mb: 1
          }}>
            Regenerar preguntas
          </Typography>
          <Typography sx={{
            fontSize: '14px',
            color: '#666666',
            fontFamily: 'Fira Sans',
            mb: 2
          }}>
            ¿Estás seguro que quieres regenerar las preguntas? Las preguntas actuales se perderán.
          </Typography>
        </DialogTitle>

        <DialogActions sx={{
          padding: "16px 0 0 0",
          marginTop: 'auto'
        }}>
          <Button
            onClick={handleCloseRegenerateFlashcardsModal}
            sx={{
              color: '#A0A0A0',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#666'
              }
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleRegenerateFlashcards}
            disabled={isGeneratingFlashcards}
            sx={{
              backgroundColor: '#FFBF60',
              color: 'white',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              borderRadius: '20px',
              padding: '6px 16px',
              '&:hover': {
                backgroundColor: '#FFBF60'
              }
            }}
          >
            {isGeneratingFlashcards ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              'Regenerar'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <EditCard
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        card={flashcardToEdit}
        onSave={handleEditSave}
      />

      <Dialog
        open={openGenerateModal}
        onClose={handleCloseGenerateModal}
        PaperProps={{
          sx: {
            borderRadius: '20px',
            padding: '24px',
            maxWidth: '500px'
          }
        }}
      >
        <DialogTitle sx={{
          padding: "0",
          fontFamily: 'Fira Sans',
        }}>
          <Typography sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: 'Fira Sans',
            color: '#000',
            mb: 1
          }}>
            Generar más tarjetas
          </Typography>
          <Typography sx={{
            fontSize: '14px',
            color: '#A0A0A0',
            fontFamily: 'Fira Sans',
            mb: 2
          }}>
            Selecciona el número de tarjetas adicionales que quieres generar
          </Typography>
        </DialogTitle>

        <DialogContent sx={{
          padding: "0",
          '&.MuiDialogContent-root': {
            paddingTop: '10px !important'
          }
        }}>
          <Box sx={{
            width: '100%',
            paddingRight: '8px'
          }}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 1
            }}>
              <Typography sx={{
                fontSize: '14px',
                fontFamily: 'Fira Sans',
                color: '#A0A0A0'
              }}>
                Número de tarjetas: {numberOfFlashcardsToGenerate}
              </Typography>
            </Box>
            <Slider
              value={numberOfFlashcardsToGenerate}
              onChange={(_, newValue) => setNumberOfFlashcardsToGenerate(newValue)}
              min={20}
              max={60}
              sx={{
                color: '#6BBDBD',
                '& .MuiSlider-thumb': {
                  height: 24,
                  width: 24,
                  backgroundColor: '#fff',
                  border: '2px solid #6BBDBD',
                  '&:hover, &.Mui-focusVisible': {
                    boxShadow: '0 0 0 8px rgba(107, 189, 189, 0.16)',
                  },
                },
                '& .MuiSlider-rail': {
                  backgroundColor: '#F0F4F4',
                  opacity: 1,
                },
                '& .MuiSlider-track': {
                  height: 4,
                },
                '& .MuiSlider-mark': {
                  backgroundColor: '#F0F4F4',
                  height: 8,
                  width: 1,
                  '&.MuiSlider-markActive': {
                    backgroundColor: '#6BBDBD',
                  },
                },
              }}
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{
          padding: "16px 0 0 0",
          marginTop: 'auto'
        }}>
          <Button
            onClick={handleCloseGenerateModal}
            sx={{
              color: '#A0A0A0',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#666'
              }
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleGenerateMore}
            disabled={isGenerating}
            sx={{
              backgroundColor: '#6BBDBD',
              color: 'white',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              borderRadius: '20px',
              padding: '6px 16px',
              '&:hover': {
                backgroundColor: '#5aa9a9'
              }
            }}
          >
            {isGenerating ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              'Generar'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FlashcardDoc;