const BASE_URL = process.env.REACT_APP_BACKEND_URL


export const fetchSchemaById = async (token, user, schemaId) => {
  try {
    const user_id = user?.id;
    if (!token || !user_id || !schemaId) {
      throw new Error('No authentication token, user ID, or schema ID available');
    }

    const response = await fetch(`${BASE_URL}/api_v1/mental-map/${schemaId}?user_id=${user_id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    
    const data = await response.json();
    
    // No need to add handlers to nodes
    return data;
  } catch (error) {
    console.error('Error fetching schema data:', error);
    throw error;
  }
};

// Helper function to handle node deletion
const handleBorrar = async (token, user, schemaId, node) => {
  try {
    await deleteNode(token, user, schemaId, node.id);
  } catch (error) {
    console.error('Error deleting node:', error);
    throw error;
  }
};

export const updateNode = async (token, user, schemaId, nodeId, data) => {
  try {
    const user_id = user['id'];
    const response = await fetch(`${BASE_URL}/api_v1/mental-map/${schemaId}/node/${nodeId}/?user_id=${user_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    
    const updatedNode = await response.json();
    
    // Return the updated node with handlers
    return {
      ...updatedNode,
      data: {
        ...updatedNode.data,
        onEdit: data.node.data.onEdit,
        onDelete: data.node.data.onDelete
      }
    };
  } catch (error) {
    console.error('Error updating node:', error);
    throw error;
  }
};

export const deleteNode = async (token, user, schemaId, nodeId) => {
  try {
    const user_id = user['id'];
    const response = await fetch(`${BASE_URL}/api_v1/mental-map/${schemaId}/node/${nodeId}/?user_id=${user_id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error deleting node:', error);
    throw error;
  }
};

export const editNode = async (token, user, schemaId, nodeId, nodeData) => {
  try {
    const response = await fetch(`${BASE_URL}/api_v1/mental-map/${schemaId}/edit-node/${nodeId}/?user_id=${user?.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        node: {
          id: nodeId,
          data: {
            label: nodeData.label,
            description: nodeData.description
          }
        },
        user_id: user?.id
      })
    });

    if (!response.ok) {
      throw new Error('Failed to edit node');
    }

    return await response.json();
  } catch (error) {
    console.error('Error editing node:', error);
    throw error;
  }
};

export const editNodeContent = async (token, user, schemaId, nodeId, formData) => {
  try {
    const response = await fetch(`${BASE_URL}/api_v1/mental-map/${schemaId}/edit-node/${nodeId}/?user_id=${user?.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        label: formData.label,
        description: formData.description,
        user_id: user?.id
      })
    });

    if (!response.ok) {
      throw new Error('Failed to edit node');
    }

    const updatedNode = await response.json();
    return updatedNode;
  } catch (error) {
    console.error('Error editing node content:', error);
    throw error;
  }
};

