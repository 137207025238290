import {
  Grid,
  Paper,
  Typography, Container, Box
} from '@mui/material';
import { Button } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import ModalFolder from "../Folders/ModalFolders";
import NeedCredits from "../NoCreditsModal/NeedCredits";
import SchoolIcon from '@mui/icons-material/School';
import { useSubscription } from '../Context/SubscriptionContext';
import TestOptionsModal from '../Documents/TestOptionsModal';

import { useParams } from "react-router-dom";
import { fetchUserFile } from '../Api/FilesApi'; // Import the fetchUserInfo function
import Document from "../Documents/Document";
import FolderContent from "../Folders/FolderContent"; // Adjust the path based on your folder structure
import BreadCrumb from "../NewComponents/BreadCrumb"; // Adjust the path based on your folder structure
import Chat from "../NewComponents/Chat";
import * as handle from "./handle";
import { useNavigate } from 'react-router-dom';
import Premium from "../PopUpsAlerts/Premium/Premium";
import { useAuth } from "../Auth/Authhandle";
import SmallAlert from "../NewComponents/Modal/smallAlert";
import LockIcon from '@mui/icons-material/Lock'; // Import LockIcon from MUI
import Tooltip from '@mui/material/Tooltip'; // Import Tooltip from MUI

import { useTranslation } from 'react-i18next';
import Summary from "../Documents/SummaryChapters";
import { WebSocketContext } from '../WebSocketProvider';
import CustomList from "./CustomList";
import useMainStructureStyles from "./Styles/useMainStructureStyles";
import UploadOptionsModal from '../AllModalsActions/UploadOptionsModal';
import ModalParent from "../AllModalsActions/ParentModal";
import { fetchFolderExams } from "../Api/ExamsApi";
import ModalNotReady from "../NewComponents/ModalNotReady";
import FullScreenLoader from "../NewComponents/LoadingExam";
import { useGenerateQuestions } from "../Context/GenerateQuestionsContext";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import QuizIcon from '@mui/icons-material/Quiz';
import PsychologyIcon from '@mui/icons-material/Psychology';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;



function MainStructure({ active_folder }) {

  const { token, user, isAuthenticated } = useAuth(); // Use the hook at the top level
  const [listDocuments, setListDocuments] = useState([]);
  const [listMemorise, setListMemorise] = useState([]);
  const [listExams, setListExams] = useState([]); // Define setListExams state

  const [messages, setMessages] = useState([]); // Move messages state up
  const [isVideo, setIsVideo] = useState(true);
  const [isOpenVideo, setIsOpenVideo] = useState(false);
  const [valueVideo, setValueVideo] = useState("Ver video");
  const [videoUrl, setVideoUrl] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [titleOption, setTitle] = useState("");
  const [descriptionOption, setDescription] = useState("");
  const [buttonOption, setButton] = useState("");
  const [placeholderMessage, setPlaceholderMessage] = useState("");
  const [typeModal, setTypeModal] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [listFolders, setListFolders] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  // Folder info 
  const [folder, setFolder] = useState(null)
  // Single Doc info
  const [docInfo, setDocInfo] = useState(null)

  //Alert
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");

  const { t } = useTranslation();



  // async function fetchAllFolders() {
  //   if (token && user) {
  //     try {
  //       const fetchFolders = await fetchUserFolders(token, "GET", null, user);
  //       setListFolders(fetchFolders);


  //     } catch (error) {
  //       console.error("Error fetching folders: ", error);
  //     }
  //   }
  // }
  // useEffect(() => {
  //   fetchAllFolders();
  // }, [token, user]);
  const { folder_id, selectedFolder } = useParams();
  const [summary, setSummary] = useState("")
  // For upload file pop up
  // Assuming modalOpenUpload is a boolean to control the visibility of a modal
  const [modalOpenUpload, setModalOpenUpload] = useState(false);
  const [titleOptionUpload, setTitleOptionUpload] = useState('');
  const [descriptionOptionUpload, setDescriptionOptionUpload] = useState('');
  const [buttonOptionUpload, setButtonOptionUpload] = useState('');
  const [placeholderMessageUpload, setPlaceholderMessageUpload] = useState('');
  const [typeUpload, setTypeUpload] = useState('');
  const [showSpinnerUpload, setShowSpinnerUpload] = useState(false);
  const [showAlertUpload, setShowAlertUpload] = useState(false);
  const [progressBar, setProgressBar] = useState(false);
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [messageUpload, setMessageUpload] = useState('');
  const { setNeedGenerateQuestions } = useGenerateQuestions();

  const [loadingStates, setLoadingStates] = useState({});
  const [width, setWidthModal] = useState("");
  const [isDocument, setIsDocument] = useState(false);
  const [isPreparingExam, setIsPreparingExam] = useState(false);
  const [showPremium, setShowPremium] = useState(false);
  const [premiumTitleText, setPremiumTitleText] = useState('');
  const [anchorElCollapsed, setAnchorElCollapsed] = useState(null);

  const classes = useMainStructureStyles(isDocument);


  const handleUploadFile = () => {
    handle.handleUploadFile(
      t,
      setModalOpenUpload,
      setTitleOptionUpload,
      setDescriptionOptionUpload,
      setButtonOptionUpload,
      setPlaceholderMessageUpload,
      setTypeUpload,
      setWidthModal
    );
  };
  const handleCreateExam = () => {
    handle.handleCreateExam(
      t,
      setModalOpenUpload,
      setTitleOptionUpload,
      setDescriptionOptionUpload,
      setButtonOptionUpload,
      setPlaceholderMessageUpload,
      setTypeUpload,
      setWidthModal,

    );
  };
  useEffect(() => {
    async function fetchData() {
      if (token && user) {
        setIsLoading(true);
        try {
          // Use Promise.all to fetch data in parallel
          await Promise.all([
            handle.fetchData(token, setFolder, folder_id, user),
            handle.fetchDocuments(token, setListDocuments, folder_id, user)
          ]);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchData();
  }, [token, user, folder_id]);






  const fetchExams = async () => {

    if (token && user && folder) {
      try {
        const listExamsFetched = await fetchFolderExams(token, "GET", folder.guid, user, null, null);
        setListExams(listExamsFetched);
      } catch (error) {
        console.error(error);
      }
    }
  };
  // const updateListDocuments = async (document_id) => {
  //   console.log("DID WE GET HERE???")
  //   // setListDocuments(currentDocuments => {
  //   //   const filteredEntries = currentDocuments.filter(([guid]) => guid !== document_id);
  //   //   return filteredEntries
  //   // });
  //   setListDocuments([])
  //   handle.fetchDocuments(token, setListDocuments, folder_id, user);
  // };

  const updateListDocuments = (document_id, updatedDoc) => {
    if (updatedDoc) {
      // Update operation
      setListDocuments(currentDocuments =>
        currentDocuments.map(doc =>
          doc.guid === document_id ? { ...doc, ...updatedDoc } : doc
        )
      );
    } else {
      // Delete operation
      setListDocuments(currentDocuments =>
        currentDocuments.filter(doc => doc.guid !== document_id)
      );
    }
  };





  // const shouldShowGenerateButton = useMemo(() => {
  //   // Check if any chapter does not have a summary generated
  //   return chaptersTitle.some(chapter => !chapter.is_active);
  // }, [chaptersTitle]);  // Recompute when chaptersTitle changes

  // const setAllChaptersLoading = () => {
  //   const newLoadingStates = chaptersTitle.reduce((acc, chapter) => {
  //     acc[chapter.guid] = true;  // Set loading to true for each chapter
  //     return acc;
  //   }, {});
  //   setLoadingStates(newLoadingStates);
  // };
  const navigate = useNavigate();

  const { userData } = useSubscription();
  const [typeAlert, setTypeAlert] = useState("");
  const [linkinMessage, setLinkinMessage] = useState("");
  const [examRedirectId, setExamRedirectId] = useState("");
  const [folderSelectedGuid, setFolderSelectedGuid] = useState("");
  const [documentSelectedGuid, setDocumentSelectedGuid] = useState("");
  const isBasicPlan = userData?.plan_name?.startsWith("Basic"); // Check if the plan is Basic
  const [stepValue, setStepValue] = useState("");
  const backendHost = BACKEND_URL.replace(/^https?:\/\//, '');
  const url = "";
  const { messageCallback } = useContext(WebSocketContext);
  useEffect(() => {
    if (messageCallback) {
      switch (messageCallback.type) {
        // case "summary":
        //   if (messageCallback.document_id === documentSelectedGuid) {
        //     setSummary(messageCallback.summary);
        //   }
        //   break;
        case "documentsUpdate":
          // console.log("callback ", messageCallback.document)
          // let folderId_callback = messageCallback.folderId
          // let documentId_callback = messageCallback.documentId
          // setShowAlert(false);
          // setShowAlertUpload(false)
          // setShowSpinnerUpload(false)
          // navigate(`/${folderId_callback}/${documentId_callback}`)
          setProgressBar(false)
          setShowAlert(false)
          setListDocuments(prevDocuments => [...prevDocuments, messageCallback.document]);
          break;
        case "Exam":
          fetchExams()
          break;
        case "Error":
          alert(messageCallback.messageAlert)
          setShowAlert(false);
          setShowAlertUpload(false)
          setShowSpinnerUpload(false)
          break;
        case "generating_questions":
          setNeedGenerateQuestions(true)
          break;

        case "generating_questions_done":
          setNeedGenerateQuestions(false)
          break;

        default:
          console.log("Unhandled message type:", messageCallback.document);
      }
    }
  }, [messageCallback]);
  // const handleWebSocketMessage = useCallback((message) => {
  //   console.log('Received chapters:', message);
  //   switch (message.type) {
  //     case "chapterUpdate":
  //       setChaptersTitle(message.chapters);
  //       break;

  //     default:
  //       console.log("Unhandled message type:", message);
  //   }
  // }, []);
  // const [sendMessage] = useWebSocketComponent(url, handleWebSocketMessage);
  const handleExamCreated = () => {
    fetchExams();
  };
  const [openNotReady, setOpenNotReady] = useState(false);
  const handleClickOpenNotReady = () => {
    setOpenNotReady(true);
  };

  const handleCloseNotReady = () => {
    setOpenNotReady(false);
  };

  useEffect(() => {

    if (isPreparingExam) {
      const timer = setTimeout(() => {
        setIsPreparingExam(false);
      }, 4000);
      return () => clearTimeout(timer); // Cleanup the timer if the component unmounts or isPreparingExam changes
    }
  }, [isPreparingExam]);

  const [anchorEl, setAnchorEl] = useState(null);

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isTestOptionsModalOpen, setIsTestOptionsModalOpen] = useState(false);

  return (
    <>
      {showAlert &&
        <SmallAlert
          message={message}
          progressBar={false}
          onClose={() => setShowAlert(false)}
          linkinMessage={linkinMessage}
          typeAlert={typeAlert}
        />
      }
      {showAlertUpload &&
        <SmallAlert
          message={messageUpload}
          progressBar={progressBar}
          onClose={() => setShowAlertUpload(false)}
        />
      }
      {showPremium && (
        <Premium
          onClose={() => setShowPremium(false)}
          titleText={premiumTitleText}
        />
      )}
      {
        <NeedCredits open={showUpgrade} message={message}

          onClose={() => setShowUpgrade(false)} />
      }

      {openNotReady && <ModalNotReady handleClose={handleCloseNotReady} />}

      {isPreparingExam && <FullScreenLoader
        imageUrl="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/ExamGettingReady.svg"
        title={t('fullScreenLoader.examPreparingTitle')}
        message={t('fullScreenLoader.examPreparingMessage')}
        buttonText={t('fullScreenLoader.examPreparingButton')}
        onClose={() => { setIsPreparingExam(false); }}
        onButtonClick={() => { setIsPreparingExam(false); if (folderSelectedGuid && documentSelectedGuid) { navigate(`/${folderSelectedGuid}/${documentSelectedGuid}`) } }}

      />}
      <Container

        spacing={3}
        className={classes.gridContainer}
      >
        <>
          <Grid
            item
            className={classes.leftPart}
            style={{ width: "100%" }}
          >
            <Paper elevation={3} className={classes.paper} style={{ height: "95vh" }}>
              <div style={{
                position: 'relative',
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
                marginBottom: 5,
                marginTop: "30px",
                borderBottom: "1px solid #E7E7E7",
                paddingBottom: "20px"
              }}>
                {!docInfo ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Box style={{
                      backgroundColor: folder?.color || "#6BBDBD",
                      width: "24px",
                      height: "24px",
                      borderRadius: "4px",
                      marginRight: "10px"
                    }} />
                    <Typography style={{
                      color: "black",
                      fontSize: "22px",
                      fontFamily: "Fira Sans",
                      fontStyle: "normal",
                      fontWeight: 600,
                      letterSpacing: "-0.225px",
                    }}>{folder ? folder.name : "Folder"}</Typography>
                  </div>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Box style={{
                      backgroundColor: "#E2F1F1",
                      width: "24px",
                      height: "24px",
                      borderRadius: "4px",
                      marginRight: "10px"
                    }} />
                    <Typography style={{
                      color: "black",
                      fontSize: "22px",
                      fontFamily: "Fira Sans",
                      fontStyle: "normal",
                      fontWeight: 600,
                      letterSpacing: "-0.225px",
                    }}>{docInfo.title}</Typography>
                  </div>
                )}
                {folder !== null && active_folder && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {userData?.plan_name !== "External" && (
                      <>
                        <Button
                          onClick={() => setIsUploadModalOpen(true)}
                          style={{
                            textTransform: "none",
                            backgroundColor: "white",
                            color: "#6BBDBD",
                            fontFamily: "'Fira Sans', sans-serif",
                            borderRadius: "20px",
                            padding: "6px 22px",
                            fontWeight: "bold",
                            fontSize: "14px",
                            border: "1px solid #6BBDBD"
                          }}
                        >
                          Añadir
                        </Button>
                        <UploadOptionsModal 
                          open={isUploadModalOpen}
                          onClose={() => setIsUploadModalOpen(false)}
                          onUploadDocument={handleUploadFile}
                        />
                      </>
                    )}
                    <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                      <Button
                        startIcon={<SchoolIcon />}
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                        aria-controls="study-menu"
                        aria-haspopup="true"
                        sx={{
                          textTransform: "none",
                          paddingLeft: "20px",
                          paddingRight: "20px",
                          backgroundColor: "#6BBDBD",
                          color: "white",
                          fontFamily: "'Fira Sans', sans-serif",
                          fontWeight: "bold",
                          fontSize: "14px",
                          borderRadius: "20px",
                          '&:hover': {
                            backgroundColor: '#6BBDBD',
                          },
                        }}
                        endIcon={
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5 7.5L10 12.5L15 7.5"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        }
                      >
                        Estudiar
                      </Button>

                      <Menu
                        id="study-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        PaperProps={{
                          style: {
                            marginTop: '8px',
                            borderRadius: '12px',
                            minWidth: '200px',
                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                          },
                        }}
                        style={{
                          marginTop: '8px'
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            setIsTestOptionsModalOpen(true);
                            setAnchorEl(null);
                          }}
                          style={{
                            padding: '12px 16px',
                            fontSize: '14px',
                          }}
                        >
                          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                            <QuizIcon style={{ color: '#666' }} />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <span style={{ fontWeight: 500, fontFamily: "Fira Sans" }}>Hacer test</span>
                              <span style={{ color: '#666', fontSize: '12px', fontFamily: "Fira Sans" }}>Ponte a prueba con un test</span>
                            </div>
                          </div>
                        </MenuItem>
                        <Divider style={{ margin: '4px 0' }} />
                        <Tooltip title={isBasicPlan ? "Funcionalidad solo para premium" : ""}>
                          <div>
                            <MenuItem
                              onClick={() => {
                                if (!isBasicPlan) {
                                  navigate('/new-flashcards', { state: { reset: true }, replace: true });
                                }
                                setAnchorElCollapsed(null);
                              }}
                              style={{
                                padding: '12px 16px',
                                fontSize: '14px',
                                cursor: isBasicPlan ? 'default' : 'pointer', // Disable click for Basic plans
                                backgroundColor: isBasicPlan ? '#f0f0f0' : 'inherit', // Light gray background for Basic plans
                                position: 'relative', // Position relative for lock icon placement
                              }}
                              disabled={isBasicPlan} // Disable the MenuItem for Basic plans
                            >
                              <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                <PsychologyIcon style={{ color: '#666' }} />
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <span style={{ fontWeight: 500, fontFamily: "Fira Sans" }}>Tarjetas de memoria</span>
                                  <span style={{ color: '#666', fontSize: '12px', fontFamily: "Fira Sans" }}>Responde a flashcards</span>
                                </div>
                                {isBasicPlan && (
                                  <LockIcon style={{
                                    color: '#666',
                                    position: 'absolute',
                                    top: '8px',
                                    right: '8px',
                                    fontSize: '16px' // Smaller size for the lock icon
                                  }} />
                                )}
                              </div>
                            </MenuItem>
                          </div>
                        </Tooltip>
                      </Menu>
                    </Box>
                  </div>
                )}




              </div>



              {active_folder ? (
                <div style={{ overflowY: "scroll" }}>
                  <FolderContent
                    listDocuments={listDocuments}
                    listMemorise={listMemorise}
                    setListMemorise={setListMemorise}
                    folder={folder}
                    updateListDocuments={updateListDocuments} // Pass the function as a prop
                    setIsDocument={false}
                    // setMessage={setMessage}
                    // setShowAlert={setShowAlert}
                    token={token}
                    user={user}
                    setTitle={setTitleOptionUpload}
                    setDescription={setDescription}
                    setButton={setButton}
                    setPlaceholderMessage={setPlaceholderMessage}
                    setModalOpen={setModalOpenUpload}
                    setType={setTypeUpload}
                    handleUploadFile={handleUploadFile} // Pass the function as a prop
                    handleCreateExam={handleCreateExam}
                    setListExams={setListExams}
                    listExams={listExams}
                    handleClickOpenNotReady={handleClickOpenNotReady}
                    setShowPremium={setShowPremium}
                    isLoading={isLoading}

                  />
                </div>
              ) : (
                null
                // <Document docInfo={docInfo} summary={summary} token={token} user={user} setMessage={setMessage} setShowAlert={setShowAlert} handleCreateExam={handleCreateExam} />





              )}

            </Paper>
          </Grid>
          {!active_folder && (
            <Grid
              item
              className={classes.rightPart}


            >
              <Paper elevation={3} className={classes.paperright}


              // style={isDocument ? { marginRight: "10px", height: "calc(100% - 60px)" } : null}

              >




                <Chat
                  height={"100%"}
                  messages={messages}
                  setMessages={setMessages}
                  token={token} // Passing token as a prop
                  user={user}
                  isPdf={true}


                />


              </Paper>
            </Grid>
          )}
        </>
      </Container >

      {/* 
      {modalOpen && (
        <ModalFolder
          open={true}
          title={titleOption}
          description={descriptionOption}
          sendButton={buttonOption}
          placeholdMessage={placeholderMessage}
          type={typeModal}
          onClose={() => {
            setModalOpen(false); setOpenMenu(false); // Refresh folders list
          }}
          folder_id={folder.guid}
        />
      )
      } */}
      {
        modalOpenUpload && (
          <ModalParent
            open={modalOpenUpload}
            title={titleOptionUpload}
            description={descriptionOptionUpload}
            sendButton={buttonOptionUpload}
            placeholdMessage={placeholderMessageUpload}
            type={typeUpload}
            onClose={() => setModalOpenUpload(false)}
            setShowSpinner={setShowSpinnerUpload}
            setShowAlert={setShowAlertUpload}
            setMessage={setMessageUpload}
            setProgressBar={setProgressBar}
            setShowUpgrade={setShowUpgrade}
            width={width}
            onExamCreated={handleExamCreated} // Pass the callback to handle exam creation
            updateListDocuments={updateListDocuments}
            setIsPreparingExam={setIsPreparingExam}
            setFolderSelectedGuid={setFolderSelectedGuid}
            setDocumentSelectedGuid={setDocumentSelectedGuid}

          />
        )
      }

      <TestOptionsModal 
        open={isTestOptionsModalOpen}
        onClose={() => setIsTestOptionsModalOpen(false)}
      />

    </>
  );
}

export default MainStructure;