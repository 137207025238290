import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Box,
  IconButton,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { fetchQuestionAnswerId } from '../Api/QuestionsApi';

const EditQuestionPopup = ({ open, onClose, question, onSave, token, user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [formData, setFormData] = useState({
    text: question?.question_statement || question?.text || '',
    options: question?.question_options?.map(opt => opt.text) || ['', '', '', ''],
    correctOption: question?.question_options?.findIndex(opt => opt.is_correct) || 0
  });

  const handleTextChange = (e) => {
    setFormData(prev => ({
      ...prev,
      text: e.target.value
    }));
  };

  const handleOptionChange = (index, value) => {
    setFormData(prev => ({
      ...prev,
      options: prev.options.map((opt, i) => i === index ? value : opt)
    }));
  };

  const handleCorrectOptionChange = (e) => {
    setFormData(prev => ({
      ...prev,
      correctOption: Number(e.target.value)
    }));
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const updatedQuestion = {
        id: question.id || question.guid,
        question: formData.text,
        options: formData.options,
        correctAnswer: formData.correctOption,
        explanation: question?.explanation || ''
      };
      
      await onSave(updatedQuestion);
      setSnackbarOpen(true);
      setTimeout(() => {
        onClose();
      }, 1000);
    } catch (error) {
      console.error('Error saving question:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const isFormValid = () => {
    return formData.text.trim() !== '' && 
           formData.options.every(opt => opt.trim() !== '') &&
           formData.correctOption >= 0;
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '20px',
            padding: '24px'
          }
        }}
      >
        <DialogTitle sx={{ p: 0, mb: 0 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" sx={{ 
              fontFamily: 'Fira Sans',
              fontWeight: 'bold',
              fontSize: '18px'
            }}>
              Editar Pregunta
            </Typography>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent sx={{ p: 0 }}>
          <TextField
            fullWidth
            multiline
            rows={2}
            label="Pregunta"
            value={formData.text}
            onChange={handleTextChange}
            sx={{
              mt: 4,
              mb: 3,
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#E2F1F1',
                },
                '&:hover fieldset': {
                  borderColor: '#6BBDBD',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#6BBDBD',
                },
              },
              '& .MuiInputBase-root': {
                minHeight: '80px',
                maxHeight: '150px',
                overflow: 'auto',
                '& textarea': {
                  maxHeight: '150px',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                    borderRadius: '4px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: '#6BBDBD',
                    borderRadius: '4px',
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    background: '#5aa9a9',
                  },
                },
              }
            }}
          />

          <Typography 
            variant="subtitle1" 
            sx={{ 
              mb: 2,
              fontFamily: 'Fira Sans',
              fontWeight: '600'
            }}
          >
            Opciones de respuesta
          </Typography>

          <RadioGroup
            value={formData.correctOption}
            onChange={handleCorrectOptionChange}
          >
            {formData.options.map((option, index) => (
              <Box 
                key={index} 
                sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2
                }}
              >
                <FormControlLabel
                  value={index}
                  control={
                    <Radio 
                      sx={{
                        color: '#6BBDBD',
                        '&.Mui-checked': {
                          color: '#6BBDBD',
                        },
                      }}
                    />
                  }
                  label=""
                />
                <TextField
                  fullWidth
                  value={option}
                  onChange={(e) => handleOptionChange(index, e.target.value)}
                  placeholder={`Opción ${String.fromCharCode(65 + index)}`}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#E2F1F1',
                      },
                      '&:hover fieldset': {
                        borderColor: '#6BBDBD',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#6BBDBD',
                      },
                    },
                  }}
                />
              </Box>
            ))}
          </RadioGroup>
        </DialogContent>

        <DialogActions sx={{ p: 0, mt: 3 }}>
          <Button
            onClick={onClose}
            sx={{
              color: '#666',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                color: '#333'
              }
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleSave}
            disabled={!isFormValid() || isLoading}
            sx={{
              backgroundColor: '#6BBDBD',
              color: 'white',
              fontFamily: 'Fira Sans',
              textTransform: 'none',
              borderRadius: '20px',
              padding: '6px 16px',
              '&:hover': {
                backgroundColor: '#5aa9a9'
              },
              '&.Mui-disabled': {
                backgroundColor: '#E0E0E0'
              }
            }}
          >
            {isLoading ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              'Guardar'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbarOpen(false)} 
          severity="success"
          sx={{ 
            width: '100%',
            fontFamily: "'Fira Sans', sans-serif",
            backgroundColor: '#6BBDBD',
            color: 'white',
            '& .MuiAlert-icon': {
              color: 'white'
            }
          }}
        >
          Pregunta actualizada correctamente
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditQuestionPopup; 