import { getCsrfToken } from './TokenApi'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export async function fetchSimulacroQuestions(token, requestBody, user) {
    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/simulacro/?user_id=${user_id}`;
    
    try {
        const csrfToken = getCsrfToken();
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };

        if (csrfToken) {
            headers['X-CSRFToken'] = csrfToken;
        }

        const requestOptions = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(requestBody)
        };

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (error) {
        console.error('Error fetching simulacro questions:', error);
        throw error;
    }
}

export async function fetchSimulacroExams(token, user) {
    const user_id = user['id'];
    const apiUrl = `${BACKEND_URL}/api_v1/simulacro/exams/?user_id=${user_id}`;
    
    try {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        };  

        const requestOptions = {
            method: 'GET',
            headers: headers,
        };  

        const response = await fetch(apiUrl, requestOptions);

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }   

        return await response.json();
    } catch (error) {
        console.error('Error fetching simulacro exams:', error);
        throw error;
    }       
        
        
        
        
}
