import React from 'react';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const BILLING = process.env.REACT_APP_BILLING;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        minHeight: '100vh',
        overflow: 'hidden',
    },
    imageContainer: {
        flex: 1,
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
    },
    contentContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '40px',
    },
    title: {
        textAlign: 'center',
        color: "#000",
        fontSize: "40px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "50px",
        letterSpacing: "-0.3px",
        maxWidth: "500px",
        marginBottom: '20px',
    },
    subtitle: {
        textAlign: 'center',
        color: "#000",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "30px",
        letterSpacing: "-0.3px",
        maxWidth: "450px",
        marginBottom: '30px',
    }
}));

const SubscriptionInactiveCard = ({ onReactivate }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Card className={classes.root}>
            <Box className={classes.imageContainer}>
                <img
                    src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/error4.png"
                    alt="Error"
                />
            </Box>
            <Box className={classes.contentContainer}>
                <Typography className={classes.title}>
                    {t("innactive_subscription")}
                </Typography>
                <Typography className={classes.subtitle}>
                    Contáctanos en jordi@typedai.com por cualquier duda.
                </Typography>
                <Button
                    variant="contained"
                    onClick={() => { window.location.href = "https://www.typedai.com" }}
                    sx={{
                        width: "184px",
                        height: "66px",
                        padding: "8px 16px",
                        borderRadius: "8px",
                        textTransform: 'none',
                        fontSize: '16px',
                        fontWeight: 600,
                        bgcolor: '#6BBDBD',
                        '&:hover': {
                            bgcolor: '#5aa9a9',
                        }
                    }}
                >
                    Entrar
                </Button>
            </Box>
        </Card>
    );
};

export default SubscriptionInactiveCard;

