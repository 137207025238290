import { getCsrfToken } from './TokenApi'
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const postKeyConcepts = async (token, user, text) => {
  const user_id = user['id'];
  try {
    const response = await fetch(`${BACKEND_URL}/api_v1/key_concepts/?user_id=${user_id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-CSRFToken': getCsrfToken()
      },
      body: JSON.stringify({ "node_text": text })
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error posting key concepts:', error);
    throw error;
  }
};

export const editNode = async (token, user, schemaId, nodeId, nodeData) => {
  try {
    console.log('Sending edit request with data:', nodeData);
    
    // Construct the node object with the correct structure
    const node = {
      id: nodeId,
      data: {
        label: nodeData.label,
        description: nodeData.description
      },
      type: 'custom',
      position: nodeData.position || { x: 400, y: 50 }, // preserve position if available
      selected: false,
      draggable: true,
      connectable: true,
      selectable: true
    };

    const requestData = {
      node: node,
      parent_node: nodeId,  // Include parent node ID as required
      edges: nodeData.edges || [], // Include edges if available
      user_id: user?.id
    };

    console.log('Sending formatted request:', requestData);

    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api_v1/mental-map/${schemaId}/edit-node/${nodeId}/?user_id=${user?.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(requestData)
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to edit node');
    }

    const updatedNode = await response.json();
    console.log('Node updated successfully:', updatedNode);
    return updatedNode;
  } catch (error) {
    console.error('Error editing node:', error);
    throw error;
  }
};
