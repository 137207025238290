import React, { useState, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  Checkbox,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';

const CreateQuestionDialog = ({ open, onClose, onSave }) => {
  const [question, setQuestion] = useState({
    question_text: '',
    options: ['', '', '', ''],
    correctAnswer: 0
  });

  const [formErrors, setFormErrors] = useState({
    question_text: false,
    options: [false, false, false, false]
  });

  const isFormValid = useMemo(() => {
    return question.question_text.trim() !== '' && 
           question.options.every(option => option.trim() !== '');
  }, [question.question_text, question.options]);

  const handleSave = () => {
    const newErrors = {
      question_text: question.question_text.trim() === '',
      options: question.options.map(opt => opt.trim() === '')
    };
    setFormErrors(newErrors);
    
    if (!newErrors.question_text && !newErrors.options.some(error => error)) {
      onSave(question);
      handleClose();
    }
  };

  const handleClose = () => {
    setQuestion({
      question_text: '',
      options: ['', '', '', ''],
      correctAnswer: 0
    });
    setFormErrors({
      question_text: false,
      options: [false, false, false, false]
    });
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '20px',
          padding: '24px'
        }
      }}
    >
      <DialogTitle sx={{ p: 0, mb: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold', fontFamily: 'Fira Sans', color: '#2D2D2D' }}>
            Crear pregunta
          </Typography>
          <IconButton onClick={handleClose} size="small">
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box>
            <TextField
              fullWidth
              value={question.question_text}
              onChange={(e) => {
                setQuestion({ ...question, question_text: e.target.value });
                if (formErrors.question_text) {
                  setFormErrors(prev => ({ ...prev, question_text: false }));
                }
              }}
              variant="outlined"
              placeholder="Escribe la pregunta"
              error={formErrors.question_text}
              helperText={formErrors.question_text ? 'La pregunta no puede estar vacía' : ''}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  backgroundColor: '#F8FFFF'
                }
              }}
            />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            {question.options.map((option, index) => (
              <Box key={index} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Checkbox
                    checked={index === question.correctAnswer}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setQuestion({ ...question, correctAnswer: index });
                      }
                    }}
                    sx={{
                      color: '#6BBDBD',
                      '&.Mui-checked': {
                        color: '#6BBDBD',
                      },
                    }}
                  />
                  <TextField
                    fullWidth
                    value={option}
                    onChange={(e) => {
                      const newOptions = [...question.options];
                      newOptions[index] = e.target.value;
                      setQuestion({ ...question, options: newOptions });
                      if (formErrors.options[index]) {
                        const newOptionErrors = [...formErrors.options];
                        newOptionErrors[index] = false;
                        setFormErrors(prev => ({ ...prev, options: newOptionErrors }));
                      }
                    }}
                    variant="outlined"
                    placeholder={`Opción ${index + 1}`}
                    error={formErrors.options[index]}
                    helperText={formErrors.options[index] ? 'La opción no puede estar vacía' : ''}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        backgroundColor: '#F8FFFF'
                      }
                    }}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{ p: 0, mt: 3 }}>
        <Button
          onClick={handleClose}
          sx={{
            color: '#A0A0A0',
            fontFamily: 'Fira Sans',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: 'transparent',
              color: '#666'
            }
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleSave}
          disabled={!isFormValid}
          sx={{
            backgroundColor: '#6BBDBD',
            color: 'white',
            fontFamily: 'Fira Sans',
            textTransform: 'none',
            borderRadius: '20px',
            padding: '6px 24px',
            '&:hover': {
              backgroundColor: '#5AA9A9'
            },
            '&.Mui-disabled': {
              backgroundColor: '#E0E0E0',
              color: '#A0A0A0'
            }
          }}
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateQuestionDialog; 