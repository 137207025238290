import { makeStyles } from '@mui/styles';

const useDocumentStyles = makeStyles((theme) => ({
    gridContainer: {
        flexWrap: "nowrap",
        backgroundColor: "#F8FFFF",
        display: "flex",
        overflow: "hidden",
        justifyContent: "flex-start",
        maxWidth: "none",
        margin: "0px",
        height: "100vh", // Ensure the container takes full viewport height
    },

    leftPart: {
        position: 'sticky', // Make the left part sticky
        top: 0, // Stick to the top of the viewport

        display: "flex",
        flexDirection: "column",
        flex: 2,
        minHeight: '100%',
        height: "95vh", // Make the left part fill the viewport height
        overflowY: 'auto', // Allow scrolling if content overflows
        backgroundColor: "white",
    },

    paperLeft: {
        display: "flex",
        flexDirection: "column",
        boxShadow: "none",
        flexGrow: 1,
        height: "100%",  // Ensure it takes the full height of its parent
        overflowY: "hidden",  // Enable vertical scrolling
        backgroundColor: "#F8FFFF",
        width: "100%",
        // Remove margin to match the width of leftPart
        margin: "0px",
        padding: "20px",

    },

    rightPart: {
        boxShadow: "none !important",
        marginBottom: "40px",
        flexBasis: '28vw !important',
        [theme.breakpoints.up('2000')]: {
            flexBasis: '28vw !important',
        },
        [theme.breakpoints.down('1500')]: {
            flexBasis: '34vw',
        },
        [theme.breakpoints.down('1200')]: {
            flexBasis: '15vw',
        },
        [theme.breakpoints.down('1000')]: {
            display: "none",
        },
    },

    paperright: {
        marginRight: "10px",
        height: '100%',  // Make Paper take full height of its container
        display: 'flex',
        flexDirection: 'column',  // Ensures the children (like Chat) also stretch properly
        backgroundColor: "#F8FFFF",
        boxShadow: "none"
    },
    paper: {
        margin: "20px 50px 20px 50px",

        display: "flex",
        flexDirection: "column",
        boxShadow: "none",

    },
    chatFixed: {
        bottom: "10px", // Adjust as needed
        right: "10px", // Adjust as needed
        width: "25vw", // Adjust as needed
        maxHeight: "100%", // Adjust as needed
        overflowY: "auto", // To allow internal scrolling of the chat
        zIndex: 1000, // Make sure the chat is above other elements
        // ... other necessary styles
    },
    importantPart: {
        paddingRight: "30px",
        width: '-webkit-fill-available',
        zIndex: 10,
        height: '-webkit-fill-available', // Full height of its container
        display: 'flex', // Add flex display
        flexDirection: 'column', // Stack children vertically
    },
    breadcrumbContainer: {
        position: 'sticky',
        top: 0, // Stick to the top of the viewport
        zIndex: 1100, // Higher index to ensure it's above other content
        padding: theme.spacing(2), // Uniform padding
        width: '100%', // Full width
    },
    summaryContainer: {
        overflowY: "auto", // Only the summary content will scroll
        height: "100%", // You might need to adjust this depending on your layout
    },
    titleAndButtonContainer: {
        justifyContent: 'space-between',
        display: "flex",
        marginBottom: 10,
        marginTop: 15,
        flexShrink: 0,
    },

    // Existing styles...
    chatContainer: {
        flex: 1, // This will allow the chat to grow and fill the space
        display: 'flex', // Ensure it is a flex container
        flexDirection: 'column', // Stack children vertically
        paddingBottom: "20px"
    },
    buttonUpload: {
        backgroundColor: "#6BBDBD",
        color: "white",
        textTransform: "none",
        borderRadius: "6px",
        fontSize: "12px",
        display: "flex",
        alignItems: "flex-end",
        gap: "2px",

        '&:disabled': {
            backgroundColor: '#B0B0B0', // Gray color for the disabled state
            color: '#FFFFFF'           // White text for the disabled state
        },
        '&:hover': {
            backgroundColor: '#6BBDBD', // Gray color for the disabled state
            color: "white",
            // padding: "8px 14px", // Added padding for better appearance

        },
        padding: "4px 11px !important"
    },

    fullWidthContainer: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        width: "100%",
        flexGrow: 1, // Ensures it takes the full width of its parent
    },
}));

export default useDocumentStyles;
