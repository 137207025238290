import { getCsrfToken } from './TokenApi';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const createQuestion = async (token, documentId, questionData, user) => {
  const user_id = user['id'];
  try {
    const csrfToken = getCsrfToken();
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };

    if (csrfToken) {
      headers['X-CSRFToken'] = csrfToken;
    }

    const response = await fetch(`${BACKEND_URL}/api_v1/question-create/?user_id=${user_id}`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        document_id: documentId,
        question_statement: questionData.question,
        question_options: questionData.options,
        correct_option: questionData.options[questionData.correctAnswer],
        question_explanation: questionData.explanation || ''
      }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    console.error('Error creating question:', error);
    throw error;
  }
};

export const updateQuestion = async (token, questionId, questionData, user) => {
  const user_id = user['id'];
  try {
    const csrfToken = getCsrfToken();
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };

    if (csrfToken) {
      headers['X-CSRFToken'] = csrfToken;
    }

    const response = await fetch(`${BACKEND_URL}/api_v1/question-info/${questionId}/?user_id=${user_id}`, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify({
        question_statement: questionData.question,
        question_options: questionData.options,
        correct_option: questionData.options[questionData.correctAnswer],
        question_explanation: questionData.explanation || ''
      }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating question:', error);
    throw error;
  }
};

export const deleteQuestion = async (token, questionId, user) => {
  const user_id = user['id'];
  try {
    const csrfToken = getCsrfToken();
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };

    if (csrfToken) {
      headers['X-CSRFToken'] = csrfToken;
    }

    const response = await fetch(`${BACKEND_URL}/api_v1/question-info/${questionId}/?user_id=${user_id}`, {
      method: 'DELETE',
      headers: headers,
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return true;
  } catch (error) {
    console.error('Error deleting question:', error);
    throw error;
  }
};

export const flagQuestion = async (token, questionId, reason, user) => {
  const user_id = user['id'];
  try {
    const csrfToken = getCsrfToken();
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    };

    if (csrfToken) {
      headers['X-CSRFToken'] = csrfToken;
    }

    const response = await fetch(`${BACKEND_URL}/api_v1/questions/${questionId}/?user_id=${user_id}`, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify({
        flag: true,
        error_reason: reason
      }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    console.error('Error flagging question:', error);
    throw error;
  }
}; 