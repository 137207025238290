import React from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  CircularProgress
} from '@mui/material';

const LoadingDialog = ({ open }) => {
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "20px",
        }
      }}
    >
      <DialogContent sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        padding: '32px'
      }}>
        <CircularProgress size={40} sx={{ color: '#6BBDBD' }} />
        <Typography sx={{ 
          fontFamily: 'Fira Sans',
          color: '#666',
          fontSize: '14px'
        }}>
          Generando contenido...
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingDialog; 