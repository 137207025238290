import React, { useState, useMemo } from 'react';
import { Box, Typography, CircularProgress, Tooltip, Skeleton, Button, TextField, Menu, MenuItem, Chip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteDoc from '../PopUpsAlerts/DeleteDoc/DeleteDoc';
import { fetchUserFile } from '../Api/FilesApi';
import * as handleDoc from "./handleDoc";
import { useSubscription } from "../Context/SubscriptionContext";

import { keyframes } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';
import QuizIcon from '@mui/icons-material/Quiz';
import StyleIcon from '@mui/icons-material/Style';
import { fetchUserFolders, fetchUserFolder } from '../Api/FolderApi';
import { sortOptions, getSortPreference, saveSortPreference, STORAGE_KEYS } from '../utils/sortPreferences';

const DocumentSkeleton = () => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '32px',
            borderBottom: '1px solid #E0E0E0',
            minHeight: '120px',
        }}
    >
        <Skeleton variant="circular" width={80} height={80} sx={{ marginRight: '20px' }} />
        <Box sx={{ flexGrow: 1 }}>
            <Skeleton variant="text" width="60%" height={24} sx={{ marginBottom: '8px' }} />
            <Skeleton variant="text" width="40%" height={20} />
        </Box>
    </Box>
);
// Add this keyframes definition outside of the component
const colorChange = keyframes`
  0% { color: #4ECDC4; }  // Light teal
  33% { color: #45B7A0; } // Seafoam green
  66% { color: #2AB7CA; } // Sky blue
  100% { color: #4ECDC4; } // Back to light teal
`;
const DocumentListFolder = ({
    listDocuments,
    isLoading,
    selectedDocument,
    onDocumentSelect,
    selectable = true,
    box = true,
    token,
    user,
    updateListDocuments,
    setShowPremium,
    folder
}) => {
    const navigate = useNavigate();
    const { userData } = useSubscription();
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedSort, setSelectedSort] = useState(
        getSortPreference(STORAGE_KEYS.FOLDER_DOCUMENT_SORT)
    );

    const handleSortChange = (option) => {
        setSelectedSort(option);
        saveSortPreference(STORAGE_KEYS.FOLDER_DOCUMENT_SORT, option);
    };

    const filteredAndSortedDocuments = useMemo(() => {
        let docs = listDocuments?.filter(doc =>
            doc.title.toLowerCase().includes(searchQuery.toLowerCase())
        ) || [];

        // Sort documents based on selected option
        return docs.sort((a, b) => {
            switch (selectedSort.value) {
                case 'newest':
                    return new Date(b.date || 0) - new Date(a.date || 0);
                case 'oldest':
                    return new Date(a.date || 0) - new Date(b.date || 0);
                case 'name_asc':
                    return a.title.localeCompare(b.title);
                case 'name_desc':
                    return b.title.localeCompare(a.title);
                case 'progress':
                    return getProgress(b) - getProgress(a);
                default:
                    return 0;
            }
        });
    }, [listDocuments, searchQuery, selectedSort]);

    const itemColors = [
        { image: "https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/greenCard.svg", color: '#00B564', label: 'Preguntas Memorizadas' },
        { image: "https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/yellowCard.svg", color: '#FFA726', label: 'Preguntas Aprendiendo' },
        { image: "https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/redCar.svg", color: '#AA161D', label: 'Preguntas Incorrectas' },
        { image: "https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/grayCard.svg", color: '#BDBDBD', label: 'Preguntas Sin Empezar' },
        { image: "", color: '#2196F3', label: 'Preguntas Bloqueadas' },

    ];

    const getMemorizedChaptersText = (document) => {
        const memorizedChapters = document.main_topics.filter(topic =>
            (topic.points / topic.TotalPossiblePoints * 100) >= 80
        ).length;
        const totalChapters = document.main_topics.length;
        const totalPoints = document.main_topics.reduce((sum, topic) => sum + topic.points, 0);
        const totalMaxPoints = document.main_topics.reduce((sum, topic) => sum + topic.TotalPossiblePoints, 0);
        return `${totalPoints} de ${totalMaxPoints} puntos de memoria🧠`;
    };

    const getProgress = (document) => {
        let total;
        if (userData?.plan_name !== "Free" && !document.is_constitution)
            total = document.status.memorized + document.status.learning +
                document.status.notKnown + document.status.notStarted + document.status.blocked
        else
            total = document.status.memorized + document.status.learning +
                document.status.notKnown + document.status.notStarted;
        const percentageMemorized = total > 0 ?
            (document.status.memorized / total) * 100 : 0;
        const result = percentageMemorized.toFixed(0);
        return isNaN(result) || result === 'null' ? '0' : result;
    };
    const getProgressTopic = (document) => {
        if (userData?.plan_name !== "Free" && !document.is_constitution)
            return { memorized: document.status.memorized, learning: document.status.learning, notKnown: document.status.notKnown, notStarted: document.status.notStarted, blocked: 0 };

        // For Basic users, calculate blocked questions
        const totalQuestions = document.pages * 12;
        const availableQuestions = document.status.memorized + document.status.learning + document.status.notKnown + document.status.notStarted;
        const blockedQuestions = totalQuestions - availableQuestions;

        return {
            memorized: document.status.memorized,
            learning: document.status.learning,
            notKnown: document.status.notKnown,
            notStarted: document.status.notStarted,
            blocked: blockedQuestions
        };
    };

    const handleBoxClick = (document, event) => {
        event.stopPropagation();
        if (!document.is_blocked) {
            navigate(`/${document.folder}/${document.guid}`);
        } else {
            setShowPremium(true)
        }
    };

    const [editingDocId, setEditingDocId] = useState(null);
    const [editableTitle, setEditableTitle] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [showDeleteDoc, setShowDeleteDoc] = useState(false);
    const [documentToDelete, setDocumentToDelete] = useState(null);

    const handleEditClick = (event, document) => {
        event.stopPropagation();
        setEditingDocId(document.guid);
        setEditableTitle(document.title);
    };

    const handleTitleBlur = async (document) => {
        setEditingDocId(null);
        try {
            await fetchUserFile(token, "PUT", document.folder, document.guid, { "docName": editableTitle }, user);
            // Update the document title in the list
            updateListDocuments(document.guid, { ...document, title: editableTitle });
        } catch (error) {
            console.error(error);
        }
    };

    const handleTitleChange = (event) => {
        setEditableTitle(event.target.value);
    };

    const handleClickMenu = (event, document) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setDocumentToDelete(document);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleDeleteDocument = async () => {
        try {
            await handleDoc.deleteDocument(token, documentToDelete.guid, documentToDelete.folder, user);
            updateListDocuments(documentToDelete.guid);
        } catch (error) {
            console.error(error);
        }
        setShowDeleteDoc(false);
        handleCloseMenu();
    };

    const handleStudyClick = (document) => {
        navigate(`/new-exam?document=${document.guid}`);
        handleCloseMenu();
    };

    const [folderAnchorEl, setFolderAnchorEl] = useState(null);
    const [folders, setFolders] = useState([]);

    const handleMoveClick = async (event) => {
        event.stopPropagation();
        setFolderAnchorEl(anchorEl);
        handleCloseMenu();

        try {
            // Fetch folders when menu opens
            const foldersData = await fetchUserFolders(token, "GET", null, user);
            setFolders(foldersData);
        } catch (error) {
            console.error('Error fetching folders:', error);
            // Optionally show error message to user
        }
    };

    const handleFolderMenuClose = () => {
        setFolderAnchorEl(null);
    };

    const handleFolderSelect = async (folderId) => {
        if (documentToDelete) {
            try {
                // Move document to selected folder using the correct API call
                await fetchUserFile(
                    token,
                    "PUT",
                    documentToDelete.folder, // current folder ID
                    documentToDelete.guid,   // document ID
                    { folder_id: folderId }, // new folder ID
                    user
                );

                // Update the local state or trigger a refresh
                updateListDocuments(documentToDelete.guid);
            } catch (error) {
                console.error('Error moving document:', error);
            }
        }
        handleFolderMenuClose();
    };

    const content = (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: '0px', backgroundColor: '#F8FFFF' }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Buscar documentos..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        sx={{
                            flex: 1,
                            '& .MuiOutlinedInput-root': {
                                height: '45px',
                                backgroundColor: 'white',
                                '& fieldset': {
                                    borderColor: 'rgba(0, 0, 0, 0.38)',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'rgba(0, 0, 0, 0.38)',
                                },
                            },
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: 'rgba(0, 0, 0, 0.38)' }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    gap: 1,
                    marginBottom: 3
                }}>
                    {sortOptions.map((option) => (
                        <Button
                            key={option.value}
                            onClick={() => handleSortChange(option)}
                            sx={{
                                textTransform: 'none',
                                color: selectedSort.value === option.value ? 'white' : '#666',
                                backgroundColor: selectedSort.value === option.value ? '#6BBDBD' : 'transparent',
                                border: '1px solid #E0E0E0',
                                borderRadius: '20px',
                                padding: '6px 16px',
                                fontSize: '14px',
                                fontFamily: 'Inter',
                                '&:hover': {
                                    backgroundColor: selectedSort.value === option.value ? '#6BBDBD' : '#f5f5f5',
                                }
                            }}
                        >
                            {option.label}
                        </Button>
                    ))}
                </Box>
            </Box>

            <DeleteDoc
                open={showDeleteDoc}
                onClose={() => setShowDeleteDoc(false)}
                onDelete={handleDeleteDocument}
                documentName={documentToDelete?.title}
            />
            <Box sx={{
                backgroundColor: '#F8FFFF',
                padding: '0px',
                borderRadius: '12px',

            }}>
                {
                    filteredAndSortedDocuments.length === 0 && !isLoading ? (
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '32px',
                            minHeight: '300px',
                            marginTop: '24px',
                            backgroundColor: '#F8FFFF'
                        }}>
                            <img src="https://sourceimagestyped.s3.eu-west-2.amazonaws.com/Fotos/Vacio.svg" alt="No documents" width="120" height="120" />
                            <Typography sx={{ mt: 2, color: '#666', fontSize: '16px' }}>
                                Debes subir primero un documento
                            </Typography>
                        </Box>
                    ) : (
                        filteredAndSortedDocuments.map((document, index) => (
                            <Box
                                key={document.guid}
                                onClick={(event) => handleBoxClick(document, event)}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '32px',
                                    borderRadius: '12px',
                                    minHeight: '120px',
                                    backgroundColor: selectedDocument === document.guid ? '#E2F1F1' : 'white',
                                    cursor: 'pointer',
                                    transition: 'all 0.2s ease-in-out',
                                    marginBottom: '16px',
                                    border: '1px solid #E7E7E7',
                                    boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.08)',
                                    '&:hover': {
                                        backgroundColor: selectedDocument === document.guid ? '#E2F1F1' : '#E2F1F1',
                                        transform: 'translateY(-2px)',
                                        boxShadow: '0px 8px 25px 0px rgba(0, 0, 0, 0.1)'
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '50px',
                                        height: '50px',
                                        borderRadius: '50%',
                                        backgroundColor: '#E2F1F1',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginRight: '20px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            width: '80px',
                                            height: '80px',
                                        }}
                                    >
                                        <CircularProgress
                                            variant="determinate"
                                            value={getProgress(document)}
                                            size={80}
                                            thickness={4}
                                            sx={{
                                                color: '#6BBDBD',
                                                '& .MuiCircularProgress-circle': {
                                                    strokeLinecap: 'round',
                                                },
                                                '& .MuiCircularProgress-circleBackground': {
                                                    stroke: '#E2F1F1',
                                                },
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: '60px',
                                                height: '60px',
                                                borderRadius: '50%',
                                                backgroundColor: 'white',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: '#6BBDBD',
                                                    fontWeight: 'bold',
                                                    fontSize: '16px',
                                                }}
                                            >
                                                {getProgress(document)}%
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={{ flexGrow: 1, textAlign: 'left', marginLeft: '16px' }}>
                                    {editingDocId === document.guid ? (
                                        <Box sx={{ display: 'flex', width: '100%' }}>
                                            <TextField
                                                value={editableTitle}
                                                onChange={handleTitleChange}
                                                variant="standard"
                                                onClick={(event) => event.stopPropagation()}
                                                autoFocus
                                                inputProps={{
                                                    maxLength: 120,
                                                }}
                                                sx={{
                                                    flexGrow: 1,
                                                    '& .MuiInputBase-input': {
                                                        fontSize: '16px',
                                                        fontWeight: 'bold',
                                                        color: '#333',
                                                    }
                                                }}
                                            />
                                            <CheckIcon
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleTitleBlur(document);
                                                }}
                                                sx={{
                                                    ml: 1,
                                                    cursor: 'pointer',
                                                    color: '#6BBDBD',
                                                    '&:hover': {
                                                        color: '#026277'
                                                    }
                                                }}
                                            />
                                        </Box>
                                    ) : (
                                        <Box display="flex" alignItems="center">
                                            <Typography
                                                sx={{
                                                    fontWeight: 'bold',
                                                    fontSize: '14px',
                                                    fontFamily: 'Fira Sans',
                                                    color: '#333',
                                                    marginBottom: '8px',
                                                }}
                                            >
                                                {document.title.length > 60 ? (
                                                    <Tooltip title={document.title}>
                                                        <span>{document.title.substring(0, 60)}...</span>
                                                    </Tooltip>
                                                ) : (
                                                    document.title
                                                )}
                                            </Typography>
                                            <EditIcon
                                                onClick={(event) => handleEditClick(event, document)}
                                                style={{ marginLeft: '8px', marginBottom: '8px', cursor: 'pointer', fontSize: '16px', color: '#C2C2C2' }}
                                            />
                                        </Box>
                                    )}

                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '8px'
                                    }}>
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                fontFamily: 'Inter',
                                                color: '#666',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '4px'
                                            }}
                                        >
                                            <QuizIcon sx={{
                                                fontSize: 16,
                                                color: '#6BBDBD'
                                            }} />
                                            {userData?.plan_name === "Free" && !document.is_constitution ? (
                                                <>
                                                    {document.status.memorized} de {document.pages * 12} preguntas correctas
                                                </>
                                            ) : (
                                                <>
                                                    {document.status.memorized} de {Object.values(document.status).reduce((sum, curr) => sum + curr, 0)} preguntas correctas
                                                </>
                                            )}
                                        </Typography>

                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                fontFamily: 'Inter',
                                                color: '#666',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '4px'
                                            }}
                                        >
                                            <StyleIcon sx={{
                                                fontSize: 16,
                                                color: '#00B564'
                                            }} />
                                            {document.status_flashcards?.easy + document.status_flashcards?.normal || 0} de {
                                                Object.values(document.status_flashcards || {}).reduce((sum, curr) => sum + curr, 0)
                                            } flashcards memorizadas
                                        </Typography>
                                    </Box>
                                </Box>
                                {
                                    document.is_blocked ? (
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: '#AA161D',
                                                color: 'white',
                                                textTransform: 'none',
                                                fontWeight: '600',
                                                fontFamily: "'Inter', sans-serif",
                                                borderRadius: '24px',
                                                padding: '8px 24px',
                                                fontSize: '14px',
                                                boxShadow: 'none',
                                                '&:hover': {
                                                    backgroundColor: '#8B1217',
                                                }
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setShowPremium(true)
                                            }}
                                        >
                                            Desbloquear
                                        </Button>
                                    ) : document.is_error_generating ? (
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            flexGrow: 1,
                                            gap: '32px',
                                        }}>
                                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#666' }}>
                                                Error generando preguntas
                                            </Typography>
                                        </Box>
                                    ) : document.is_generating ? (
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            flexGrow: 1,
                                            gap: '32px',
                                        }}>
                                            <Typography sx={{ fontWeight: 'bold', animation: `${colorChange} 2s ease-in-out infinite` }}>
                                                {'Generando preguntas...'.split('').map((char, index) => (
                                                    <span
                                                        key={index}
                                                        sx={{
                                                            display: 'inline-block !important',
                                                            animation: `${colorChange} 2s ease-in-out infinite !important`,
                                                            animationDelay: `${index * 0.1}s !important`,
                                                        }}
                                                    >
                                                        {char}
                                                    </span>
                                                ))}
                                            </Typography>
                                        </Box>
                                    ) : !document.questions_generated ? (
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            flexGrow: 1,
                                            gap: '32px',
                                        }}>
                                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold', color: '#666' }}>
                                                No tienes preguntas generadas
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            flexGrow: 1,
                                            gap: '32px',
                                        }}>
                                        </Box>
                                    )
                                }
                                {document.is_exam && (
                                    <Chip
                                        label="Test"
                                        sx={{
                                            backgroundColor: '#E2F1F1',
                                            color: '#026277',
                                            fontFamily: 'Inter',
                                            fontWeight: 600,
                                            fontSize: '12px',
                                            height: '24px',
                                            marginRight: '8px'
                                        }}
                                    />
                                )}

                                <MoreVertIcon
                                    onClick={(event) => handleClickMenu(event, document)}
                                    style={{ color: "#026277", cursor: 'pointer', marginLeft: '8px' }}
                                />
                            </Box>
                        ))
                    )
                }
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: '12px',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.08)',
                        border: '1px solid #E7E7E7',
                    }
                }}
            >
                {documentToDelete && !documentToDelete.is_blocked && !documentToDelete.is_generating && documentToDelete.questions_generated && (
                    <MenuItem
                        onClick={() => handleStudyClick(documentToDelete)}
                        sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            color: '#333',
                            padding: '8px 24px',
                            '&:hover': {
                                backgroundColor: '#E2F1F1'
                            }
                        }}
                    >
                        Estudiar
                    </MenuItem>
                )}
                <MenuItem
                    onClick={handleMoveClick}
                    sx={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        color: '#333',
                        padding: '8px 24px',
                        '&:hover': {
                            backgroundColor: '#E2F1F1'
                        }
                    }}
                >
                    Mover
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setShowDeleteDoc(true);
                        handleCloseMenu();
                    }}
                    sx={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        color: '#AA161D',
                        padding: '8px 24px',
                        '&:hover': {
                            backgroundColor: '#FFEBEE'
                        }
                    }}
                >
                    Delete
                </MenuItem>
            </Menu>

            <Menu
                anchorEl={folderAnchorEl}
                open={Boolean(folderAnchorEl)}
                onClose={handleFolderMenuClose}
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: '12px',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.08)',
                        border: '1px solid #E7E7E7',
                    }
                }}
            >
                <MenuItem
                    disabled
                    sx={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 600,
                        color: '#333',
                        padding: '8px 24px',
                        '&.Mui-disabled': {
                            opacity: 1,
                        }
                    }}
                >
                    Tus carpetas
                </MenuItem>
                {folders.length === 0 ? (
                    <MenuItem
                        disabled
                        sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            color: '#666',
                            padding: '8px 24px',
                        }}
                    >
                        No hay carpetas disponibles
                    </MenuItem>
                ) : (
                    folders.map((foldeSelected) => (
                        <MenuItem
                            key={foldeSelected.id}
                            onClick={() => handleFolderSelect(foldeSelected.guid)}
                            disabled={foldeSelected.guid === folder.guid}
                            sx={{
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                color: foldeSelected.guid === folder.guid ? '#666' : '#333',
                                padding: '8px 24px',
                                '&:hover': {
                                    backgroundColor: folder.guid === folder.guid ? 'transparent' : '#E2F1F1'
                                }
                            }}
                        >
                            {foldeSelected.name}
                        </MenuItem>
                    ))
                )}
            </Menu>
        </>
    );

    return box ? (
        <Box sx={{
            maxHeight: '75vh',
            overflowY: 'auto',
            marginTop: '20px',
            marginBottom: '40px',
            backgroundColor: '#F8FFFF',
            overflowX: 'hidden',
            scrollbarWidth: 'thin',
            scrollbarColor: '#026277 #E2F1F1',
            '&::-webkit-scrollbar': {
                width: '8px',
                backgroundColor: '#F8FFFF',
                display: 'block'
            },
            '&::-webkit-scrollbar-button': {
                display: 'none'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#6BBDBD',
                borderRadius: '4px',
                minHeight: '40px',
                '&:hover': {
                    backgroundColor: '#026277'
                }
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#E2F1F1',
                borderRadius: '4px',
                margin: '4px'
            },
            '& *': {
                scrollbarWidth: 'thin',
                scrollbarColor: '#6BBDBD #E2F1F1',
            }
        }}>
            <Box sx={{
                height: '100%',
                paddingRight: '16px'
            }}>
                {content}
            </Box>
        </Box>
    ) : content;
};

export default DocumentListFolder;
