import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { fetchExamId, fetchFolderExams } from '../Api/ExamsApi';
import { useAuth } from "../Auth/Authhandle";
import useExamCorrectViewStyles from "./Styles/useExamCorrectInfoStyles";
import { useNavigate } from "react-router-dom";
import { Box, Typography, List, ListItem, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import classNames from 'classnames';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import CircularProgress from '@mui/material/CircularProgress';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import "./Styles/style.css";
function CorrectInfo({ listScores, final_score, items, isLoading }) {
    const { t } = useTranslation();
    const classes = useExamCorrectViewStyles();
    const { token, user, isAuthenticated } = useAuth();

    let highScoresCount = 0
    let lowScoresCount = 0
    if (listScores.length > 0) {

        highScoresCount = listScores.filter(score => score >= 5).length;

        // Count scores < 5
        lowScoresCount = listScores.filter(score => score < 5).length;
    }

    return (
        <Box className={classes.resultsContainer}>
            <Box className={classes.resultBox}>
                <Box className={classes.resultHeader}>
                    <Typography className={classes.headerTitle} style={{ display: 'flex', alignItems: 'center' }}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 15L11 17L15 13M13 3.00087C12.9045 3 12.7973 3 12.6747 3H8.2002C7.08009 3 6.51962 3 6.0918 3.21799C5.71547 3.40973 5.40973 3.71547 5.21799 4.0918C5 4.51962 5 5.08009 5 6.2002V17.8002C5 18.9203 5 19.4801 5.21799 19.9079C5.40973 20.2842 5.71547 20.5905 6.0918 20.7822C6.51921 21 7.079 21 8.19694 21L15.8031 21C16.921 21 17.48 21 17.9074 20.7822C18.2837 20.5905 18.5905 20.2842 18.7822 19.9079C19 19.4805 19 18.9215 19 17.8036V9.32568C19 9.20296 19 9.09561 18.9991 9M13 3.00087C13.2856 3.00347 13.4663 3.01385 13.6388 3.05526C13.8429 3.10425 14.0379 3.18526 14.2168 3.29492C14.4186 3.41857 14.5918 3.59182 14.9375 3.9375L18.063 7.06298C18.4089 7.40889 18.5809 7.58136 18.7046 7.78319C18.8142 7.96214 18.8953 8.15726 18.9443 8.36133C18.9857 8.53376 18.9963 8.71451 18.9991 9M13 3.00087V5.8C13 6.9201 13 7.47977 13.218 7.90759C13.4097 8.28392 13.7155 8.59048 14.0918 8.78223C14.5192 9 15.079 9 16.1969 9H18.9991M18.9991 9H19.0002" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        &nbsp;{t("resultado")}
                    </Typography>
                </Box>
                <Box className={classes.resultContent}>
                    <div className={classes.score}>
                        <CircularProgressbar value={final_score} text={`${final_score}%`}
                            styles={buildStyles({
                                // Rotation of path and trail, in number of turns (0-1)

                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                strokeLinecap: 'round',

                                // Text size
                                textSize: '16px',

                                // How long animation takes to go from one percentage to another, in seconds
                                pathTransitionDuration: 0.5,

                                // Can specify path transition in more detail, or remove it entirely
                                // pathTransition: 'none',

                                // Colors
                                pathColor: "#6BBDBD",
                                textColor: '#6BBDBD',
                                trailColor: '#d6d6d6',
                                backgroundColor: '#3e98c7',
                            })} />
                    </div>
                    <Box className={classes.details}>
                        <Box className={classNames(classes.detailItem, classes.correct)}>
                            <Typography style={{ fontSize: "20px" }}>{highScoresCount}</Typography>

                            <CheckCircleIcon className={classes.correctIcon} />
                        </Box>
                        <Box className={classNames(classes.detailItem, classes.incorrect)}>
                            <Typography style={{ fontSize: "20px" }}>{lowScoresCount}</Typography>
                            <CancelIcon className={classes.incorrectIcon} />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.conceptsBox}>
                <Box className={classes.conceptsHeader}>
                    <Typography className={classes.headerTitle} style={{ display: 'flex', alignItems: 'center' }}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 11.0002V6.2002C6 5.08009 6 4.51962 6.21799 4.0918C6.40973 3.71547 6.71547 3.40973 7.0918 3.21799C7.51962 3 8.08009 3 9.2002 3H13.6747C13.7973 3 13.9045 3 14 3.00087M19.9991 9C20 9.09561 20 9.20296 20 9.32568V17.8036C20 18.9215 20 19.4805 19.7822 19.9079C19.5905 20.2842 19.2839 20.5905 18.9076 20.7822C18.4802 21 17.921 21 16.8031 21L13 21M19.9991 9C19.9963 8.71451 19.9857 8.53376 19.9443 8.36133C19.8953 8.15726 19.8142 7.96214 19.7046 7.7832C19.5809 7.58136 19.4089 7.40889 19.063 7.06298L15.9375 3.9375C15.5918 3.59182 15.4186 3.41857 15.2168 3.29492C15.0379 3.18526 14.8429 3.10425 14.6388 3.05526C14.4663 3.01385 14.2856 3.00347 14 3.00087M19.9991 9H20.0002M19.9991 9H17.1969C16.079 9 15.5192 9 15.0918 8.78223C14.7155 8.59048 14.4097 8.28392 14.218 7.90759C14 7.47977 14 6.9201 14 5.8V3.00087M9 14L11 16M4 21V18.5L11.5 11L14 13.5L6.5 21H4Z" stroke="white" stroke-width="2" stroke-linecap="round" strokeLinejoin="round" />
                    </svg>
                        &nbsp;{t("conceptos_mejorar")}</Typography>
                </Box>
                <Box className={`${classes.conceptsContent} custom-scrollbar-yellow`}>
                    {isLoading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircularProgress size={24} sx={{ color: '#6BBDBD' }} />
                        </Box>
                    ) : Array.isArray(items) && items.length > 0 ? (
                        <List sx={{
                            listStyleType: 'disc', marginLeft: "15px"
                        }}>
                            {items.map((item, index) => (
                                <ListItem sx={{ display: 'list-item', fontSize: "14px" }} key={index}>{item}</ListItem>
                            ))}
                        </List>
                    ) : (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Typography sx={{ color: '#888', fontStyle: 'italic' }}>
                                No hay conceptos para mejorar en este momento.
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

export default CorrectInfo;
