import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, CircularProgress } from '@mui/material';
import { useSubscription } from "../../Context/SubscriptionContext";

const useStylesDeleteDoc = makeStyles((theme) => ({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
    dialog: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    content: {
        borderRadius: '20px',
        fontFamily: 'Inter, sans-serif',
    },
    actions: {
        justifyContent: 'space-between',
        fontFamily: 'Inter, sans-serif',
        marginTop: '15px',
        marginBottom: '10px',

    },
    dialogTitle: {
        '& .MuiTypography-root': {
            fontFamily: 'Inter, sans-serif',
            fontSize: '18px',
            fontWeight: 600,
            color: '#333',
        },
    },
    deleteIcon: {

        fontSize: '1.2rem',
    },
    moreDocumentsButton: {
        backgroundColor: '#6BBDBD',
        color: 'white',
        fontFamily: 'Inter, sans-serif',
        fontSize: '16px',
        fontWeight: 600,
        textTransform: 'none',
        borderRadius: '20px',
        padding: '6px 16px',
        '&:hover': {
            backgroundColor: '#6BBDBD', // Darker red on hover
        },
    },
    deleteButton: {
        backgroundColor: '#D32F2F', // Red color
        color: 'white',
        fontFamily: 'Inter, sans-serif',
        fontSize: '16px',
        fontWeight: 600,
        textTransform: 'none',
        borderRadius: '20px',
        padding: '6px 16px',
        minWidth: '100px', // Ensure button doesn't resize when showing loader
        '&:hover': {
            backgroundColor: '#B71C1C', // Darker red on hover
        },
        '&:disabled': {
            backgroundColor: '#D32F2F',
            opacity: 0.7,
        },
    },
}));

const DeleteCarpeta = ({ open, onClose, onDelete, carpetaName }) => {

    const classes = useStylesDeleteDoc();
    const navigate = useNavigate();
    const { userData } = useSubscription();
    const [isDeleting, setIsDeleting] = useState(false);
    const handleMoreDocuments = () => {
        onClose();
        navigate('/plans');
    };

    const isBasicPlan = userData?.plan_name === 'Basic';

    const handleDelete = async () => {
        setIsDeleting(true);
        await onDelete();
        setIsDeleting(false);
    };

    return (

        <Dialog
            open={open}
            onClose={onClose}
            classes={{
                root: classes.dialog,
                paper: classes.content,
            }}
            BackdropProps={{
                classes: {
                    root: classes.backdrop,
                },
            }}
        >
            <DialogTitle className={classes.dialogTitle}>

                🗑Eliminar carpeta
            </DialogTitle>
            <DialogContent>
                <Typography style={{ marginBottom: '10px' }}>
                    ¿Estás seguro que quieres eliminar "<b>{carpetaName}</b>"?
                </Typography>

            </DialogContent>
            <DialogActions className={classes.actions}>
                <div style={{ display: 'flex', justifyContent: 'center', gap: '40px', width: '100%' }}>
                    <Button
                        onClick={handleDelete}
                        className={classes.deleteButton}
                        disabled={isDeleting}
                    >
                        {isDeleting ? (
                            <CircularProgress size={24} style={{ color: 'white' }} />
                        ) : (
                            'Eliminar'
                        )}
                    </Button>

                    <Button
                        onClick={onClose}
                        className={classes.moreDocumentsButton}
                    >
                        No eliminar
                    </Button>


                </div>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteCarpeta;
