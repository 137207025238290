import React, { useState, useRef } from 'react';
import { Box, Typography, Button, Paper, Grid, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Cell, LabelList, Tooltip as RechartsTooltip } from 'recharts';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import QuizIcon from '@mui/icons-material/Quiz';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import { useNavigate } from 'react-router-dom';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { streamJustification } from '../Exam/QdrantJustification';

const SummaryContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: "800px",
  margin: '0 auto',
  padding: theme.spacing(2),
  fontFamily: "'Fira Sans', sans-serif",
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  padding: '6px 22px',
  textTransform: 'none',
  fontFamily: "'Fira Sans', sans-serif",
  fontSize: '14px',
  fontWeight: 600,
  backgroundColor: '#6BBDBD',
  color: 'white',
  '&:hover': {
    backgroundColor: '#5AACAC',
  },
}));

const ScoreBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: '#6BBDBD',
  borderRadius: '12px',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  fontFamily: "'Fira Sans', sans-serif",
}));

const ResultCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: '8px',
  border: '1px solid #E2F1F1',
  boxShadow: 'none',
}));

const AnswerStatus = styled(Box)(({ correct }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  padding: '4px 12px',
  borderRadius: '16px',
  backgroundColor: correct ? '#E8F5E9' : '#FFEBEE',
  color: correct ? '#4CAF50' : '#F44336',
  fontSize: '14px',
  fontWeight: 500,
  fontFamily: "'Fira Sans', sans-serif",
}));

const OptionBox = styled(Box)(({ isSelected, isCorrect }) => ({
  padding: '12px 16px',
  borderRadius: '8px',
  marginBottom: '8px',
  border: '1px solid #E2F1F1',
  backgroundColor: isCorrect ? '#E8F5E9' :
    isSelected && !isCorrect ? '#FFEBEE' :
      'transparent',
  color: isCorrect ? '#4CAF50' :
    isSelected && !isCorrect ? '#F44336' :
      '#666666',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontFamily: "'Inter', sans-serif",
  fontSize: '14px',
}));

const ExplanationButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  padding: '6px 16px',
  textTransform: 'none',
  fontFamily: "'Fira Sans', sans-serif",
  fontSize: '13px',
  fontWeight: 500,
  backgroundColor: '#F8F9FA',
  color: '#666666',
  border: '1px solid #E0E0E0',
  '&:hover': {
    backgroundColor: '#EAECEF',
  },
}));

const NumberChip = styled(Box)({
  width: '32px',
  height: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px',
  fontSize: '14px',
  fontWeight: 500,
  fontFamily: "'Fira Sans', sans-serif",
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  '&:hover': {
    transform: 'scale(1.05)',
  },
});

const SimulacroSummary = ({ examResults, user, token }) => {
  const navigate = useNavigate();
  const [isJustificationOpen, setIsJustificationOpen] = useState(false);
  const [currentQuestionForJustification, setCurrentQuestionForJustification] = useState(null);
  const [justificationText, setJustificationText] = useState("");
  const [isLoadingJustification, setIsLoadingJustification] = useState(false);
  const scoringBreakdownRef = useRef(null);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // Calculate score based on configuration
  const calculateScore = () => {
    const { questions, examConfig } = examResults;
    const { correctPoints, incorrectPoints, unansweredDeducts } = examConfig;
    
    const correctAnswers = questions.filter(q => q.isCorrect).length;
    const incorrectAnswers = questions.filter(q => {
        if (unansweredDeducts) {
            // Count both incorrect answers and unanswered questions when unansweredDeducts is true
            return !q.isCorrect;
        } else {
            // Only count incorrect answers (excluding unanswered questions) when unansweredDeducts is false
            return !q.isCorrect && q.userAnswer !== 'Sin respuesta';
        }
    }).length;

    // Calculate total points
    const pointsFromCorrect = correctAnswers * correctPoints;
    const pointsFromIncorrect = incorrectAnswers * incorrectPoints;
    const totalPoints = pointsFromCorrect + pointsFromIncorrect;

    // Convert to scale of 10
    const maxPossiblePoints = questions.length * correctPoints;
    const scoreOutOf10 = (totalPoints / maxPossiblePoints) * 10;

    return Math.max(0, Math.min(10, scoreOutOf10)).toFixed(2);
  };

  const score = examResults.final_score !== undefined && examResults.final_score !== null
    ? examResults.final_score
    : calculateScore();
  const totalQuestions = examResults.questions.length;
  const correctAnswers = examResults.questions.filter(q => q.isCorrect).length;
  
  // Calculate unanswered questions separately
  const unansweredQuestions = examResults.questions.filter(
    q => !q.userAnswer || q.userAnswer === 'Sin respuesta'
  );
  const unansweredCount = unansweredQuestions.length;
  
  // Correct calculation of incorrect answers by excluding unanswered
  const incorrectAnswers = totalQuestions - correctAnswers - unansweredCount;

  const chartData = [
    { name: 'Correctas', value: correctAnswers, color: '#4CAF50' },
    { name: 'Incorrectas', value: incorrectAnswers, color: '#F44336' },
    { name: 'Sin responder', value: unansweredCount, color: '#9E9E9E' }
  ];

  // const handleJustificationOpen = async (question) => {
  //   setCurrentQuestionForJustification(question);
  //   setIsJustificationOpen(true);

  //   setIsLoadingJustification(true);
  //   setJustificationText("");

  //   try {
  //     await streamJustification(
  //       {
  //         question: question.text,
  //         correctAnswer: question.correctAnswer,
  //         userAnswer: question.userAnswer,
  //         isCorrect: question.isCorrect,
  //         documentId: question.document_title, // or appropriate document ID
  //         userId: user['id'],
  //         questionAnswerId: question.guid, // or appropriate ID
  //         typeJustification: "simulacro",
  //       },
  //       (word) => {
  //         setJustificationText(prev => prev + word);
  //       },
  //       user,
  //       token
  //     );
  //   } catch (error) {
  //     console.error("Error fetching justification:", error);
  //     setJustificationText("Lo sentimos, no pudimos generar la justificación en este momento.");
  //   } finally {
  //     setIsLoadingJustification(false);
  //   }
  // };

  const handleJustificationClose = () => {
    setIsJustificationOpen(false);
    setCurrentQuestionForJustification(null);
  };

  // Add handler for scrolling to scoring section
  const handleScrollToScoring = () => {
    scoringBreakdownRef.current?.scrollIntoView({ 
      behavior: 'smooth',
      block: 'start'
    });
  };

  return (
    <SummaryContainer>
      <Box sx={{
        mb: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 2
      }}>
        <Typography sx={{
          fontWeight: 600,
          fontSize: '24px',
          fontFamily: "'Fira Sans', sans-serif",
        }}>
          Resultado del Simulacro
        </Typography>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <ScoreBox>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 0 }}>
              <Box component="span" sx={{
                mr: 1,
                fontSize: '1.5rem'
              }}>
                📝
              </Box>
              <Typography sx={{
                fontSize: '20px',
                fontWeight: 500,
                fontFamily: "'Fira Sans', sans-serif",
              }}>
                Puntuación Final
              </Typography>
            </Box>
            <Typography sx={{
              fontSize: '48px',
              fontWeight: 'bold',
              mb: 1,
              fontFamily: "'Fira Sans', sans-serif",
            }}>
              {score}
            </Typography>
            <Typography sx={{
              fontFamily: "'Fira Sans', sans-serif",
              fontSize: '16px',
              mb: 2,
            }}>
              {correctAnswers} de {totalQuestions} respuestas correctas
            </Typography>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '1rem',
              fontFamily: "'Fira Sans', sans-serif",
              mt: 'auto'
            }}>
              <AccessTimeIcon sx={{ fontSize: '1.2rem', mr: 0.5 }} />
              {formatTime(examResults.timeSpent)}
            </Box>
          </ScoreBox>
        </Grid>

        <Grid item xs={12} md={7}>
          <Box sx={{ height: 250 }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={chartData}
                margin={{
                  top: 30,
                  right: 10,
                  left: 10,
                }}
                barGap={0}
              >
                <XAxis
                  dataKey="name"
                  axisLine={false}
                  tickLine={false}
                  tick={{
                    fill: '#666',
                    fontSize: 14,
                    fontFamily: "'Fira Sans', sans-serif",
                  }}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  tick={{
                    fill: '#666',
                    fontSize: 14,
                    fontFamily: "'Fira Sans', sans-serif",
                  }}
                />
                <RechartsTooltip 
                  formatter={(value) => [`${value} preguntas`, '']}
                  labelStyle={{ color: '#333', fontFamily: "'Fira Sans', sans-serif" }}
                />
                <Bar
                  dataKey="value"
                  radius={[4, 4, 4, 4]}
                  barSize={30}
                  barGap={8}
                >
                  {chartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry.color}
                    />
                  ))}
                  <LabelList
                    dataKey="value"
                    position="top"
                    content={({ x, y, width, value }) => (
                      <text
                        x={x + width / 2}
                        y={y - 10}
                        fill="#666"
                        textAnchor="middle"
                        fontSize={16}
                        fontWeight={500}
                        fontFamily="'Fira Sans', sans-serif"
                      >
                        {value}
                      </text>
                    )}
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ mt: 4 }}>
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 2 
        }}>
          <Typography sx={{
            fontSize: '20px',
            fontWeight: 500,
            fontFamily: "'Fira Sans', sans-serif",
          }}>
            Revisión de Preguntas
          </Typography>
          
          <Button
            startIcon={<CalculateOutlinedIcon />}
            onClick={handleScrollToScoring}
            sx={{
              backgroundColor: '#F8F9FA',
              color: '#666666',
              border: '1px solid #E0E0E0',
              borderRadius: '20px',
              textTransform: 'none',
              fontFamily: "'Fira Sans', sans-serif",
              fontSize: '14px',
              fontWeight: 500,
              '&:hover': {
                backgroundColor: '#EAECEF',
              },
            }}
          >
            Cálculo de puntuación
          </Button>
        </Box>

        <Box sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          mb: 2,
          px: 1,
          py: 1,
          borderRadius: '12px',
        }}>
          {examResults.questions.map((question, index) => {
            const getAnswerStatus = (question) => {
              if (!question.userAnswer || question.userAnswer === 'Sin respuesta') {
                return { bg: '#F5F5F5', text: '#9E9E9E' };
              }
              return question.isCorrect
                ? { bg: '#E8F5E9', text: '#4CAF50' }
                : { bg: '#FFEBEE', text: '#F44336' };
            };

            const colors = getAnswerStatus(question);

            return (
              <NumberChip
                key={index}
                sx={{
                  backgroundColor: colors.bg,
                  color: colors.text,
                  border: `1px solid ${colors.text}20`,
                }}
                onClick={() => {
                  document.getElementById(`question-${index}`).scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                  });
                }}
              >
                {index + 1}
              </NumberChip>
            );
          })}
        </Box>

        {examResults.questions.map((question, index) => (
          <ResultCard
            key={index}
            id={`question-${index}`}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Typography sx={{
                  fontFamily: "'Fira Sans', sans-serif",
                  fontWeight: 500,
                  fontSize: '16px',
                  color: '#2D2D2D',
                  flex: 1,
                }}>
                  {index + 1}. {question.text}
                </Typography>
                <AnswerStatus correct={question.isCorrect}>
                  {question.isCorrect ? 'Correcto' : 'Incorrecto'}
                </AnswerStatus>
              </Box>

              <Box sx={{ pl: 2 }}>
                {question.options.map((option, optIndex) => (
                  <OptionBox
                    key={optIndex}
                    isSelected={option === question.userAnswer}
                    isCorrect={option === question.correctAnswer}
                  >
                    {option === question.userAnswer && option !== question.correctAnswer && (
                      <Box component="span" sx={{ color: '#F44336', mr: 1 }}>✗</Box>
                    )}
                    {option === question.correctAnswer && (
                      <Box component="span" sx={{ color: '#4CAF50', mr: 1 }}>✓</Box>
                    )}
                    {option}
                  </OptionBox>
                ))}

                {/* <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <ExplanationButton
                    onClick={() => handleJustificationOpen(question)}
                    startIcon={<DescriptionOutlinedIcon />}
                  >
                    Explicación
                  </ExplanationButton>
                </Box> */}
              </Box>
            </Box>
          </ResultCard>
        ))}
      </Box>

      {/* Scoring rules and breakdown section */}
      <Box 
        ref={scoringBreakdownRef}
        sx={{ 
          mt: 4, 
          p: 2, 
          backgroundColor: '#F8F9FA',
          borderRadius: '8px',
          border: '1px solid #E0E0E0',
          scrollMarginTop: '20px'
        }}
      >
        <Typography sx={{
          fontWeight: 600,
          fontSize: '16px',
          fontFamily: "'Fira Sans', sans-serif",
          mb: 1
        }}>
          Reglas de puntuación:
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <Typography sx={{ fontFamily: "'Inter', sans-serif", fontSize: '14px' }}>
            • Respuesta correcta: +1 punto
          </Typography>
          <Typography sx={{ fontFamily: "'Inter', sans-serif", fontSize: '14px' }}>
            • Respuesta incorrecta: {examResults.examConfig.incorrectPoints} puntos
          </Typography>
          <Typography sx={{ fontFamily: "'Inter', sans-serif", fontSize: '14px' }}>
            • Sin responder: {examResults.examConfig.unansweredDeducts ? 
              `${examResults.examConfig.incorrectPoints} puntos` : 
              '0 puntos'}
          </Typography>
        </Box>
      </Box>
      
      <Box sx={{ mt: 3, p: 2, backgroundColor: '#F8F9FA', borderRadius: '8px', border: '1px solid #E0E0E0' }}>
        <Typography sx={{
          fontWeight: 600,
          fontSize: '16px',
          fontFamily: "'Fira Sans', sans-serif",
          mb: 1
        }}>
          Desglose de puntuación:
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <Typography sx={{ fontFamily: "'Inter', sans-serif", fontSize: '14px' }}>
            • Correctas: {correctAnswers} × 1 = {correctAnswers} puntos
          </Typography>
          <Typography sx={{ fontFamily: "'Inter', sans-serif", fontSize: '14px' }}>
            • Incorrectas: {incorrectAnswers} × {examResults.examConfig.incorrectPoints} = {((incorrectAnswers) * examResults.examConfig.incorrectPoints).toFixed(2)} puntos
          </Typography>
          <Typography sx={{ 
            fontFamily: "'Inter', sans-serif", 
            fontSize: '14px',
            color: examResults.examConfig.unansweredDeducts ? 'inherit' : '#757575'
          }}>
            • Sin responder: {unansweredCount} × {examResults.examConfig.unansweredDeducts ? 
              examResults.examConfig.incorrectPoints : 0} = {examResults.examConfig.unansweredDeducts ? 
              (unansweredCount * examResults.examConfig.incorrectPoints).toFixed(2) : '0'} puntos
          </Typography>
          <Box sx={{ my: 1, height: '1px', backgroundColor: '#E0E0E0' }} />
          <Typography sx={{ fontFamily: "'Inter', sans-serif", fontSize: '14px', fontWeight: 500 }}>
            Total: {(correctAnswers + (incorrectAnswers * examResults.examConfig.incorrectPoints) + (examResults.examConfig.unansweredDeducts ? unansweredCount * examResults.examConfig.incorrectPoints : 0)).toFixed(2)} / {totalQuestions} = {score} / 10
          </Typography>
        </Box>
      </Box>

      <Dialog
        open={isJustificationOpen}
        onClose={handleJustificationClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '12px',
            padding: '16px'
          }
        }}
      >
        <DialogTitle sx={{
          fontFamily: "'Fira Sans', sans-serif",
          fontSize: '20px',
          fontWeight: 500
        }}>
          Explicación de la Respuesta
        </DialogTitle>
        <DialogContent>
          {isLoadingJustification ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <Typography sx={{
              fontFamily: "'Inter', sans-serif",
              fontSize: '14px',
              lineHeight: 1.6,
              whiteSpace: 'pre-wrap'
            }}>
              {justificationText}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleJustificationClose}
            sx={{
              color: '#666666',
              textTransform: 'none',
              fontFamily: "'Inter', sans-serif"
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </SummaryContainer>
  );
};

export default SimulacroSummary; 