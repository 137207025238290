import React, { useState, useEffect } from 'react';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    TextField,
    Typography,
    Paper,
    Alert,
    Grid,
    IconButton,
    InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Spinner from "../NewComponents/Spinner";

const UpdatePassword = () => {
    const supabase = useSupabaseClient();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [success, setSuccess] = useState(null);

    // Check if we have an access token
    useEffect(() => {
        const checkSession = async () => {
            const { data: { session } } = await supabase.auth.getSession();
            
            // If no session, redirect to login
            if (!session) {
                navigate('/login');
            }
        };
        
        checkSession();
    }, [supabase.auth, navigate]);

    const handlePasswordUpdate = async (e) => {
        e.preventDefault();
        
        if (password !== confirmPassword) {
            setError('Las contraseñas no coinciden');
            return;
        }

        if (password.length < 6) {
            setError('La contraseña debe tener al menos 6 caracteres');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const { error } = await supabase.auth.updateUser({
                password: password
            });

            if (error) throw error;

            // Show success message and redirect after a delay
            setSuccess('Contraseña actualizada correctamente. Redirigiendo...');
            setTimeout(() => {
                navigate('/login');
            }, 2000);
        } catch (error) {
            console.error('Error updating password:', error.message);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <Box sx={{ 
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 3,
            backgroundColor: '#f5f5f5',
        }}>
            <Paper
                elevation={0}
                sx={{
                    p: { xs: 3, md: 4 },
                    width: '100%',
                    maxWidth: 400,
                    backgroundColor: '#ffffff',
                    borderRadius: '24px',
                    boxShadow: '0 4px 24px rgba(0, 0, 0, 0.04)',
                }}
            >
                <Typography 
                    variant="h4" 
                    component="h1" 
                    gutterBottom 
                    align="center"
                    sx={{ 
                        mb: 4,
                        fontWeight: 600,
                        color: '#1a1a1a',
                        fontSize: '26px',
                        fontFamily: "'Fira Sans', sans-serif",
                        letterSpacing: '-0.5px',
                    }}
                >
                    Actualizar contraseña
                </Typography>

                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}

                {success && (
                    <Alert severity="success" sx={{ mb: 2 }}>
                        {success}
                    </Alert>
                )}

                <form onSubmit={handlePasswordUpdate}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Nueva contraseña"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={textFieldStyle}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Confirmar contraseña"
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={textFieldStyle}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                fullWidth
                                sx={{
                                    py: 1.5,
                                    backgroundColor: '#026277',
                                    '&:hover': {
                                        backgroundColor: '#015666'
                                    }
                                }}
                            >
                                Actualizar contraseña
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Box>
    );
};

const textFieldStyle = {
    '& .MuiOutlinedInput-root': {
        borderRadius: '12px',
        '& fieldset': {
            borderColor: '#e0e0e0',
            borderWidth: '1.5px',
        },
        '&:hover fieldset': {
            borderColor: '#6BBDBD',
            borderWidth: '1.5px',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#6BBDBD',
            borderWidth: '1.5px',
        },
    },
    '& .MuiInputLabel-root': {
        color: '#666',
        '&.Mui-focused': {
            color: '#6BBDBD',
        },
    },
    '& .MuiInputBase-input': {
        fontSize: '14px',
    },
};

export default UpdatePassword; 